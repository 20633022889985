import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';

import moment from 'moment';
import { GET_TIMESHEET_CONFIGURATIONS_SUMMARY } from '../../../graphql/queries/timesheet-configurations';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';

import { PaperAirplaneIcon, PencilSquareIcon } from '@heroicons/react/24/solid';
import { Button } from '../../../components/Buttons/Button';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';
import NoTimesheets from '../../../components/NoData/NoTimesheets';

interface TimesheetListProps {
  children?: React.ReactElement;
  showNewItemButton?: boolean;
  filterIds?: string[];
  onClickRow?: (id: string, results: any[]) => void;
}

const headerMapping = {
  name: 'Name',
  configure: 'Options',
  updatedAt: 'Updated At',
} as Record<string, any>;

const TimesheetsTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
  filterText,
  setFilterText = () => {},
  selectedFilterDimension,
  setSelectedFilterDimension = () => {},
  NoData,
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    columnsToFilter={[
      'firstName',
      'lastName',
      'id',
      'status',
      'permittedGroups',
    ]}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    filterDimensions={[{ id: 'name', name: 'Name' }]}
    showFilterOptions
    filterText={filterText}
    setFilterText={setFilterText}
    selectedFilterDimension={selectedFilterDimension}
    setSelectedFilterDimension={setSelectedFilterDimension}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
    NoData={NoData}
  />
);

export const TimesheetListPage = ({
  children,
  showNewItemButton,
  filterIds,
}: TimesheetListProps) => {
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [filterText, setFilterText] = useState<string>();
  const [selectedFilterDimension, setSelectedFilterDimension] =
    useState<string>('name');

  const { limit, offset, setOffset } = usePagination();

  const [debouncedFilterText, setDebouncedSearchTerm] = useState(filterText);

  const filter = debouncedFilterText
    ? {
        filters: [
          {
            field: selectedFilterDimension,
            stringFilters: [{ ilike: debouncedFilterText }],
          },
        ],
      }
    : null;

  const [fetch, { data, loading }] = useLazyQuery(
    GET_TIMESHEET_CONFIGURATIONS_SUMMARY,
    {
      variables: { input: { limit, offset } },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    fetch({
      variables: { input: { limit, offset, ...(filter ? { filter } : {}) } },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, debouncedFilterText]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setOffset(0);
      setDebouncedSearchTerm(filterText);
    }, 600);
    return () => {
      clearTimeout(handler);
    };
  }, [filterText, setOffset]);

  // TODO: Swap for query of timesheets filtered for this user's role/group
  const timesheetResults = data?.getTimesheetConfigurations;

  const totalPages = Math.ceil(timesheetResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const { userProfile } = useContext(UserProfileContext);

  return (
    <div>
      <>
        {showNewItemButton ? (
          <div className="w-full flex justify-between items-center py-2">
            <h1 className="text-xl font-bold py-4">Timesheets</h1>
          </div>
        ) : null}
        <TimesheetsTable
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          totalResults={timesheetResults?.count || 0}
          filterText={filterText}
          setFilterText={setFilterText}
          selectedFilterDimension={selectedFilterDimension}
          setSelectedFilterDimension={setSelectedFilterDimension}
          NoData={() => (
            <NoTimesheets loading={loading} filterText={debouncedFilterText} />
          )}
          data={timesheetResults?.results
            ?.filter((result: any) => !filterIds?.includes(result.id))
            .map((result: any) => ({
              name: `${result.lastName}, ${result.firstName}`,
              ...result,
              configure: (
                <div className="flex gap-x-2">
                  {userProfile?.groups?.some((group) =>
                    result?.permittedGroups?.includes(group)
                  ) ? (
                    <Button
                      text={
                        <div className="flex flex-row items-center justify-center gap-x-2 ">
                          <PencilSquareIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                          Complete
                        </div>
                      }
                      onClick={() => {
                        navigate(`/time/timesheets/${result?.id}`);
                      }}
                    />
                  ) : null}
                  {userProfile?.permissions?.includes(
                    Permission.ViewTimesheetSubmissions
                  ) ? (
                    <Button
                      text={
                        <div className="flex flex-row items-center justify-center gap-x-2 ">
                          <PaperAirplaneIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                          Submissions
                        </div>
                      }
                      style={{ background: 'black' }}
                      onClick={() => {
                        navigate(
                          `/time/timesheets/submissions/configuration/${result?.id}`
                        );
                      }}
                    />
                  ) : null}
                </div>
              ),
            }))}
          onPageChange={handlePageChange}
        />
      </>
      {children}
    </div>
  );
};

export const TimesheetList = ({
  onClickRow,
  showNewItemButton = true,
  filterIds = [],
}: TimesheetListProps) => (
  <PaginationProvider>
    <TimesheetListPage
      showNewItemButton={showNewItemButton}
      onClickRow={onClickRow}
      filterIds={filterIds}
    />
  </PaginationProvider>
);
