import { gql } from '@apollo/client';

export const GET_USERS_SUMMARY = gql`
  query getUsers($input: GenericFindOptions, $activeOrganisation: String!) {
    getUsers(input: $input, activeOrganisation: $activeOrganisation) {
      count
      results {
        id
        lastActivityTime
        labourResource {
          id
          firstName
          lastName
          type
        }
        user_groups {
          name
        }
        email
        hasUserLogin
        enabled
        updatedAt
      }
    }
  }
`;

export const GET_USERS = gql`
  query getUsers($input: GenericFindOptions, $activeOrganisation: String!) {
    getUsers(input: $input, activeOrganisation: $activeOrganisation) {
      count
      results {
        id
        email
        lastActivityTime
        hasUserLogin
        labourResource {
          id
          firstName
          lastName
          type
        }
        projectFilter
        user_groups {
          id
          name
        }
        enabled
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_GROUPS_SUMMARY = gql`
  query getGroups($input: GenericFindOptions, $activeOrganisation: String!) {
    getGroups(input: $input, activeOrganisation: $activeOrganisation) {
      count
      results {
        id
        name
        enabled
        updatedAt
      }
    }
  }
`;

export const GET_GROUPS = gql`
  query getGroups($input: GenericFindOptions, $activeOrganisation: String!) {
    getGroups(input: $input, activeOrganisation: $activeOrganisation) {
      count
      results {
        id
        name
        permissions
        enabled
        createdAt
        updatedAt
      }
    }
  }
`;

export const SEARCH_GROUPS = gql`
  query searchGroups(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $searchTerm: String!
  ) {
    searchGroups(
      input: $input
      activeOrganisation: $activeOrganisation
      searchTerm: $searchTerm
    ) {
      count
      results {
        id
        name
        permissions
        enabled
        createdAt
        updatedAt
      }
    }
  }
`;

export const SEARCH_USERS = gql`
  query searchUsers(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $searchTerm: String!
  ) {
    searchUsers(
      input: $input
      activeOrganisation: $activeOrganisation
      searchTerm: $searchTerm
    ) {
      count
      results {
        id
      }
    }
  }
`;

export const GET_USERS_FOR_EXPORT = gql`
  query getUserGroupsExport($activeOrganisation: String!) {
    getUserGroupsExport(activeOrganisation: $activeOrganisation) {
      count
      results {
        email
        enabled
        user_groups {
          id
          name
          permissions
        }
      }
    }
  }
`;
