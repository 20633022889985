import { ApiHookMeta } from './types';
import useLabourResources from './useLabourResources';

export default function useEmployees(props: {
  id?: string;
  searchTerm?: string;
  limit?: number;
  offset?: number;
}): [any, ApiHookMeta] {
  return useLabourResources({ ...props, labourType: 'employees' });
}

export function useEmployee(id?: string) {
  const [employees, meta] = useEmployees({ id });

  const [employee] = employees;

  return [employee, meta];
}
