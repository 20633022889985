import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';

import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import moment from 'moment';
import { Button } from '../../../components/Buttons/Button';
import { GET_ACTIVITY_CODES_SUMMARY } from '../../../graphql/queries/activity-codes';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';
import NoData from '../../../components/NoData/NoData';
import LinkButton from '../../../components/Buttons/LinkButton';

interface ActivityCodeListProps {
  children?: React.ReactElement;
}

const headerMapping = {
  name: 'Name',
  color: 'Colour',
  code: 'Code',
  updatedAt: 'Updated At',
} as Record<string, any>;

const ActivityCodesTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
  filterText,
  setFilterText = () => {},
  selectedFilterDimension,
  setSelectedFilterDimension = () => {},
  NoData,
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    filterDimensions={[{ id: 'name', name: 'Name' }]}
    showFilterOptions
    filterText={filterText}
    setFilterText={setFilterText}
    selectedFilterDimension={selectedFilterDimension}
    setSelectedFilterDimension={setSelectedFilterDimension}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
    NoData={NoData}
  />
);

export const ActivityCodeListPage = ({ children }: ActivityCodeListProps) => {
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [filterText, setFilterText] = useState<string>();
  const [selectedFilterDimension, setSelectedFilterDimension] =
    useState<string>('name');

  const { limit, offset, setOffset } = usePagination();

  const [debouncedFilterText, setDebouncedSearchTerm] = useState(filterText);

  const filter = debouncedFilterText
    ? {
        filters: [
          {
            field: selectedFilterDimension,
            stringFilters: [{ ilike: debouncedFilterText }],
          },
        ],
      }
    : null;
  const [fetch, { data, loading }] = useLazyQuery(GET_ACTIVITY_CODES_SUMMARY, {
    variables: { input: { limit, offset } },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetch({
      variables: { input: { limit, offset, ...(filter ? { filter } : {}) } },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, debouncedFilterText]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setOffset(0);
      setDebouncedSearchTerm(filterText);
    }, 600);
    return () => {
      clearTimeout(handler);
    };
  }, [filterText, setOffset]);

  const activityCodeResults = data?.getActivityCodes;

  const totalPages = Math.ceil(activityCodeResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const { userProfile } = useContext(UserProfileContext);

  return (
    <div>
      <>
        <div className="w-full flex justify-between items-center py-2">
          <h1 className="text-xl font-bold py-4">Activity Codes</h1>
          {userProfile?.permissions.includes(Permission.UpdateActivityCodes) ? (
            <Button
              text="New Activity Code"
              onClick={() => navigate(`/finance/codes/new`)}
            />
          ) : null}
        </div>
        <p className="text-sm py-2 text-gray-600">
          Create activity codes for use in timesheets.
        </p>
        <ActivityCodesTable
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          totalResults={activityCodeResults?.count || 0}
          onClickRow={(id: any) => navigate(`/finance/codes/${id}`)}
          filterText={filterText}
          setFilterText={setFilterText}
          selectedFilterDimension={selectedFilterDimension}
          setSelectedFilterDimension={setSelectedFilterDimension}
          NoData={() => (
            <NoData loading={loading}>
              {debouncedFilterText
                ? 'It looks like there are no activity codes that match your query!'
                : 'It looks like there are no activity codes configured for this organisation!'}
              {userProfile?.permissions?.includes(
                Permission.UpdateActivityCodes
              ) ? (
                <LinkButton to={`/finance/codes/new`} target="_blank">
                  Add an Activity Code
                </LinkButton>
              ) : null}
            </NoData>
          )}
          data={activityCodeResults?.results.map((result: any) => ({
            ...result,
            color: (
              <div
                className="w-4 h-4"
                style={{ backgroundColor: result.color }}
              ></div>
            ),
          }))}
          onPageChange={handlePageChange}
        />
      </>
      {children}
    </div>
  );
};

export const ActivityCodeList = () => (
  <PaginationProvider>
    <ActivityCodeListPage />
  </PaginationProvider>
);
