import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GET_ACTIVITY_CODES } from '../../../graphql/queries/activity-codes';
import { GET_CUSTOM_OPTIONS } from '../../../graphql/queries/custom-data';
import { GET_TIMESHEET_CONFIGURATIONS } from '../../../graphql/queries/timesheet-configurations';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { PaginatedTimesheetForm } from './form';
import moment from 'moment';
import { GET_TIMESHEET_SUBMISSION } from '../../../graphql/queries/timesheet-submissions';

export const ReviewTimesheet: React.FC = () => {
  let { submissionId } = useParams();

  const { useLazyQuery } = useOrganisationAwareApollo();

  const [fetchTimesheetSubmission, { data: timesheetSubmission }] =
    useLazyQuery(GET_TIMESHEET_SUBMISSION, {
      variables: { input: { id: submissionId } },
      fetchPolicy: 'network-only',
    });

  const [fetchTimesheetConfigurations, { data }] = useLazyQuery(
    GET_TIMESHEET_CONFIGURATIONS,
    {
      variables: { submissionId },
      fetchPolicy: 'network-only',
    }
  );

  const [fetchActivityCodes, { data: activityCodes }] = useLazyQuery(
    GET_ACTIVITY_CODES,
    {
      variables: {},
      fetchPolicy: 'network-only',
    }
  );

  const [fetchCustomOptions, { data: customOptions }] = useLazyQuery(
    GET_CUSTOM_OPTIONS,
    {
      variables: {},
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    fetchTimesheetSubmission({ variables: { submissionId } });
    fetchTimesheetConfigurations({ variables: { submissionId } });
    fetchActivityCodes();
    fetchCustomOptions();
  }, [submissionId]);

  const [thisSubmission] =
    timesheetSubmission?.getTimesheetSubmissions?.results || [];
  const activityCodeResults = activityCodes?.getActivityCodes?.results || [];
  const customOptionsResults =
    customOptions?.getCustomOptionSets?.results || [];

  const [timesheetConfig] = data?.getTimesheetConfigurations?.results || [];

  const filteredActivityCodes = activityCodeResults?.filter(
    (code: any) => code.enabled
  );

  console.log(thisSubmission);

  return (
    <div>
      <PaginatedTimesheetForm
        configurationId={timesheetConfig?.id || ''}
        submissionId={submissionId}
        setSelectedDate={() => {}}
        selectedDate={
          thisSubmission?.weekCommencingDate
            ? moment(Number(thisSubmission?.weekCommencingDate)).toDate()
            : null
        }
        weekId={thisSubmission?.weekId}
        labourResource={thisSubmission?.lrs}
        selectedProject={thisSubmission?.project}
        setSelectedProject={() => {}}
        timesheetConfig={{
          name: timesheetConfig?.name,
          description: timesheetConfig?.description,
          granularity: timesheetConfig?.granularity,
          trackOwnTime: timesheetConfig?.type === 'SELF_REPORTED',
          activityCodes: timesheetConfig?.activityCodes
            ? [
                ...timesheetConfig?.activityCodes?.map((code: string) => {
                  const matchingCode = filteredActivityCodes?.find(
                    (activityCode: any) => activityCode.id === code
                  );
                  return {
                    id: matchingCode?.id,
                    value: matchingCode?.code,
                    label: matchingCode?.name,
                    color: matchingCode?.color,
                  };
                }),
              ]
            : [],
        }}
        refetchSubmission={async () =>
          await fetchTimesheetSubmission({ variables: { submissionId } })
        }
        timesheetApproval={thisSubmission}
        multiProject={timesheetConfig?.type === 'SELF_REPORTED'}
        customDataOptions={timesheetConfig?.customDataToApply?.map(
          (customDataId: string) => {
            const matchingCustomOption = customOptionsResults?.find(
              (customData: any) => customData.id === customDataId
            );
            return {
              id: matchingCustomOption?.id,
              name: matchingCustomOption?.name,
              options: matchingCustomOption?.custom_options ?? [],
            };
          }
        )}
      />
    </div>
  );
};
