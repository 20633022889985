import { useContext } from 'react';
import { UserProfileContext } from '../../context/UserProfileContext';
import NoData from './NoData';
import LinkButton from '../Buttons/LinkButton';
import { Permission } from '../../types/Permissions';

export default function NoAssessments({
  filterText,
  loading,
}: {
  filterText?: string;
  loading?: boolean;
}) {
  const { userProfile } = useContext(UserProfileContext);

  return (
    <NoData loading={loading}>
      {filterText
        ? 'It looks like there are no assessments that match your query!'
        : 'It looks like there are no assessments configured for this organisation!'}
      {userProfile?.permissions.includes(Permission.UpdateLabourProfiles) ? (
        <LinkButton to="/labour/assessments/new" target="_blank">
          Add an Assessment
        </LinkButton>
      ) : null}
    </NoData>
  );
}
