import ComboBox from '../../../../components/Inputs/ComboBox';

export const ProjectSelection = ({
  projectSearchResults,
  setSearchTerm,
  setSelectedProject,
  selectedProject,
  placeholder = 'Search by project name',
  description,
}: any) => {
  return (
    <div className="w-full md:w-1/3 flex flex-col gap-y-2">
      <ComboBox
        testId="project-selection"
        placeholder={placeholder}
        question={{
          id: 'Project Search',
          options: projectSearchResults?.map((project: any) => ({
            id: project?.id,
            name: project?.name,
            detail: project?.internalId,
          })),
          onChange: (value) => setSearchTerm(value),
        }}
        handleChange={(id) => {
          setSelectedProject(
            projectSearchResults?.find((project: any) => project?.id === id)
          );
        }}
        value={selectedProject?.name}
        validation={undefined}
      />
      {description}
    </div>
  );
};

export const EntryLevelProjectSelection = ({
  projectSearchResults,
  setSearchTerm,
  setFieldValue,
  entryIndex,
  values,
}: any) => {
  return (
    <div className="w-full flex justify-end items-center gap-x-2">
      <label
        htmlFor="project"
        className="block text-sm font-medium text-gray-700"
      >
        Select Project
      </label>
      <div data-testid="project-selection" className="w-2/3">
        <ComboBox
          placeholder="Search by project name"
          testId="project-selection"
          question={{
            id: 'Project Search',
            options: projectSearchResults.map((project: any) => ({
              id: project.id,
              name: project.name,
              detail: project.internalId,
            })),
            onChange: setSearchTerm,
          }}
          handleChange={(selectedValue) => {
            setFieldValue(
              `entries.${entryIndex}.projectName`,
              projectSearchResults.find(
                (project: any) => project.id === selectedValue
              )?.name
            );
            setFieldValue(`entries.${entryIndex}.projectId`, selectedValue);
          }}
          value={values.entries?.[entryIndex].projectId}
          validation={undefined}
        />
      </div>
    </div>
  );
};
