import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';
import { GET_CUSTOM_OPTIONS_SUMMARY } from '../../../graphql/queries/custom-data';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import moment from 'moment';
import { Button } from '../../../components/Buttons/Button';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';

interface CustomOptionListProps {
  children?: React.ReactElement;
}

const headerMapping = {
  name: 'Custom Option Name',
  internalId: 'Internal ID',
  updatedAt: 'Updated At',
} as Record<string, any>;

const CustomOptionsTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
  filterText,
  setFilterText = () => {},
  selectedFilterDimension,
  setSelectedFilterDimension = () => {},
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    filterDimensions={[{ id: 'name', name: 'Name' }]}
    showFilterOptions
    filterText={filterText}
    setFilterText={setFilterText}
    selectedFilterDimension={selectedFilterDimension}
    setSelectedFilterDimension={setSelectedFilterDimension}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
  />
);

export const CustomOptionListPage = ({ children }: CustomOptionListProps) => {
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [filterText, setFilterText] = useState<string>();
  const [selectedFilterDimension, setSelectedFilterDimension] =
    useState<string>('name');

  const { limit, offset, setOffset } = usePagination();

  const [debouncedFilterText, setDebouncedSearchTerm] = useState(filterText);

  const filter = debouncedFilterText
    ? {
        filters: [
          {
            field: selectedFilterDimension,
            stringFilters: [{ ilike: debouncedFilterText }],
          },
        ],
      }
    : null;
  const [fetch, { data }] = useLazyQuery(GET_CUSTOM_OPTIONS_SUMMARY, {
    variables: { input: { limit, offset } },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetch({
      variables: { input: { limit, offset, ...(filter ? { filter } : {}) } },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, debouncedFilterText]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setOffset(0);
      setDebouncedSearchTerm(filterText);
    }, 600);
    return () => {
      clearTimeout(handler);
    };
  }, [filterText]);

  const customOptionResults = data?.getCustomOptionSets;

  const totalPages = Math.ceil(customOptionResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const { userProfile } = useContext(UserProfileContext);
  return (
    <div>
      <>
        <div className="w-full flex justify-between items-center py-2">
          <h1 className="text-xl font-bold py-4">Custom Options</h1>
          {userProfile?.permissions?.includes(Permission.UpdateCustomData) ? (
            <Button
              text="New Custom Option Set"
              onClick={() => navigate(`/custom/options/new`)}
            />
          ) : null}
        </div>
        <CustomOptionsTable
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          totalResults={customOptionResults?.count || 0}
          onClickRow={(id: any) => navigate(`/custom/options/${id}`)}
          filterText={filterText}
          setFilterText={setFilterText}
          selectedFilterDimension={selectedFilterDimension}
          setSelectedFilterDimension={setSelectedFilterDimension}
          data={
            customOptionResults?.results.length > 0
              ? customOptionResults?.results
              : [{}]
          }
          onPageChange={handlePageChange}
        />
      </>
      {children}
    </div>
  );
};

export const CustomOptionList = () => (
  <PaginationProvider>
    <CustomOptionListPage />
  </PaginationProvider>
);
