import * as Yup from 'yup';
import {
  PaginationProvider,
  usePagination,
} from '../../context/PaginationContext';
import FormCard from '../FormCard/FormCard';
import { useOrganisationAwareApollo } from '../../hooks/useOrganisationAwareApollo';
import { GET_ASSESSMENT_ATTACHMENT_URL } from '../../graphql/queries/attachments';
import { GET_ASSESSMENT_ATTACHMENTS } from '../../graphql/queries/assessments';
import { useEffect } from 'react';
import { Table } from '../Tables/tables/Table';
import moment from 'moment';
import { Button } from '../Buttons/Button';
import { FormConfig } from '../../types/Form';

const headerMapping = {
  name: 'File Name',
  id: 'ID',
  download: 'Download',
  updatedAt: 'Updated At',
} as Record<string, any>;

type AssessmentAttachmentsListProps = {
  assessmentSubmission: any;
  onSaveAttachment: any;
};

function PaginatedAssessmentAttachmentsList({
  assessmentSubmission,
  onSaveAttachment,
}: AssessmentAttachmentsListProps) {
  const { useLazyQuery } = useOrganisationAwareApollo();
  const { limit, offset, setOffset } = usePagination();
  const [getAssessmentAttachmentURL] = useLazyQuery(
    GET_ASSESSMENT_ATTACHMENT_URL
  );
  const [fetchAttachmentsForThisAssessment, { data: attachmentsData }] =
    useLazyQuery(GET_ASSESSMENT_ATTACHMENTS);

  const totalPages = Math.ceil(
    attachmentsData?.getAssessmentAttachments?.count / limit
  );

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  useEffect(() => {
    if (assessmentSubmission) {
      fetchAttachmentsForThisAssessment({
        variables: {
          assessmentId: assessmentSubmission.id,
          input: { limit, offset },
        },
        fetchPolicy: 'network-only',
      });
    }
  }, [assessmentSubmission, fetchAttachmentsForThisAssessment, limit, offset]);

  const downloadAttachment = async (id: string, fileName: string) => {
    const { data } = await getAssessmentAttachmentURL({
      variables: { input: { id } },
    });
    if (data.getAssessmentAttachmentURL.presignedUrl) {
      // Create a new link
      const anchor = document.createElement('a');
      anchor.href = data.getAssessmentAttachmentURL.presignedUrl;
      anchor.download = fileName;
      anchor.style.display = 'none';

      // Append to the DOM
      document.body.appendChild(anchor);

      // Trigger `click` event
      anchor.click();

      // Remove element from DOM
      document.body.removeChild(anchor);

      // Revoke the Blob URL to free up resources
      URL.revokeObjectURL(data.getAssessmentAttachmentURL.presignedUrl);
    }
  };

  const attachmentConfig = {
    formSections: [
      {
        title: 'Attachments',
        components: [
          <Table
            onClickRow={() => {}}
            currentPage={Math.floor(offset / limit) + 1 || 0}
            totalPages={totalPages}
            totalResults={attachmentsData?.getAssessmentAttachments?.count || 0}
            itemsPerPage={limit}
            columnsToFilter={[]}
            columnValueMapper={{
              updatedAt: (text: string) =>
                moment.unix(parseInt(text) / 1000).fromNow(),
            }}
            headerMapper={(text: string) => headerMapping[text]}
            onPageChange={handlePageChange}
            data={
              attachmentsData?.getAssessmentAttachments?.results.length > 0
                ? attachmentsData?.getAssessmentAttachments?.results?.map(
                    (result: any) => ({
                      id: result?.id,
                      name: result?.fileName,
                      updatedAt: result?.updatedAt,
                      download: (
                        <Button
                          text={'Download'}
                          onClick={async () =>
                            await downloadAttachment(
                              result.id,
                              result?.fileName
                            )
                          }
                        />
                      ),
                    })
                  )
                : []
            }
          />,
        ],
        fields: [
          {
            title: 'Upload Attachment',
            id: 'file',
            type: 'file',
            limits: 'application/msword, application/pdf',
            description: 'PDF or DOCX',
          },
        ],
      },
    ],
  } as unknown as FormConfig;

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void,
    setFieldValue?: (field: any, values: any) => void
  ) => {
    await onSaveAttachment(values, setSubmitting, setFieldValue);
    await fetchAttachmentsForThisAssessment({
      variables: {
        assessmentId: assessmentSubmission.id,
        input: { limit, offset },
      },
      fetchPolicy: 'network-only',
    });
  };

  return (
    <FormCard
      key={`${attachmentConfig?.title}`}
      config={attachmentConfig}
      validationSchema={Yup.object().shape({})}
      initialValues={{ id: assessmentSubmission?.id }}
      submitText="Upload"
      onSubmit={onSubmit}
    />
  );
}

export function AssessmentAttachmentsList(
  props: AssessmentAttachmentsListProps
) {
  return (
    <PaginationProvider>
      <PaginatedAssessmentAttachmentsList {...props} />
    </PaginationProvider>
  );
}
