import { gql } from '@apollo/client';

export const GET_EXPORT_CONFIGURATIONS_SUMMARY = gql`
  query getExportConfigurations(
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    getExportConfigurations(
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        name
        enabled
        groups
        updatedAt
      }
    }
  }
`;

export const GET_EXPORT_CONFIGURATIONS = gql`
  query getExportConfigurations(
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    getExportConfigurations(
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        name
        type
        description
        enabled
        groups
        includeIds
        columnRules {
          name
          rules {
            conditionExpression
            sourceField
            transformations {
              type
              value
              sourceField
            }
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;
