import { useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';

interface CardProps {
  heading?: string | number;
  description?: string;
  children?: React.ReactElement;
  colour?: string;
  hoverColour?: string;
  IconComponent?: any;
  isExpandable?: boolean;
  isActive?: boolean;
  classNames?: string;
  headingClassNames?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void | Promise<void>;
}

export const ColourSummaryCard = ({
  heading,
  headingClassNames,
  IconComponent = () => <div />,
  isExpandable,
  isActive,
  description,
  children,
  colour,
  hoverColour,
  classNames,
  onClick,
}: CardProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      role={onClick ? 'button' : 'generic'}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        backgroundColor: isHovered
          ? `${hoverColour || colour}`
          : colour || undefined,
      }}
      className={twMerge(
        `w-full rounded-lg py-2 px-4 transition-all duration-300 ease-in-out`,
        classNames
      )}
    >
      <div className="flex items-center gap-y-1 gap-x-4">
        <IconComponent
          style={{ background: 'rgba(255,255,255,0.7)' }}
          className="h-8 w-8 flex-shrink-0 text-black rounded-full p-2"
          aria-hidden="true"
        />
        <div className="flex flex-col flex-grow">
          {(heading || heading === 0) && (
            <h3
              className={twMerge(
                'text-xl font-bold leading-6 text-gray-900',
                headingClassNames
              )}
            >
              {heading}
            </h3>
          )}
          {description && (
            <p className="text-xs tracking-tight text-gray-600">
              {description}
            </p>
          )}
        </div>
        {isExpandable ? (
          <ChevronDownIcon
            style={{
              background: 'rgba(255,255,255,0.7)',
            }}
            className={`h-8 w-8 flex-shrink-0 text-black rounded-full p-2 transition-all ${
              isActive ? 'rotate-180' : ''
            }`}
            aria-hidden="true"
          />
        ) : null}
      </div>
      {children}
    </div>
  );
};
