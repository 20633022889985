import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';

import moment from 'moment';
import { SEARCH_PROGRESS_ACTIVITY_TYPES } from '../../../graphql/queries/progress-activity-submissions';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { Button } from '../../../components/Buttons/Button';
import { LightBlueBadge } from '../../../components/Badges/Badges';
import NoData from '../../../components/NoData/NoData';
import LinkButton from '../../../components/Buttons/LinkButton';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';

interface ProjectActivityListProps {
  children?: React.ReactElement;
  projectId?: string;
  assigneeId?: string;
}

const headerMapping = {} as Record<string, any>;

const ProjectActivitiesTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
  filterText,
  filterDimensions = [],
  setFilterText = () => {},
  selectedFilterDimension,
  setSelectedFilterDimension = () => {},
  NoData,
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    filterDimensions={filterDimensions}
    showFilterOptions
    filterText={filterText}
    setFilterText={setFilterText}
    selectedFilterDimension={selectedFilterDimension}
    setSelectedFilterDimension={setSelectedFilterDimension}
    RowComponent={({ id, rowData }) => (
      <div
        key={id}
        onClick={() => onClickRow(id)}
        role="button"
        className={`border-b flex flex-col w-full justify-around items-center lg:flex-row border-gray-200 hover:bg-gray-100`}
      >
        {rowData?.name ? (
          <div key={id} className="py-3 px-6 text-sm text-left w-full">
            <div className="flex flex-col flex-wrap gap-y-2 gap-x-2 mt-2">
              <p>
                {rowData?.name}{' '}
                {rowData?.group ? (
                  <LightBlueBadge text={rowData?.group} />
                ) : null}
              </p>
            </div>
          </div>
        ) : null}
        <div
          key={id}
          className="py-3 px-6 text-sm flex items-center justify-end  gap-x-2 text-black w-full"
        ></div>
      </div>
    )}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
    NoData={NoData}
  />
);

export const ProjectActivityListPage = ({
  children,
  projectId,
  assigneeId,
}: ProjectActivityListProps) => {
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [filterText, setFilterText] = useState<string>();
  const [selectedFilterDimension, setSelectedFilterDimension] =
    useState<string>('name');

  const { limit, offset, setOffset } = usePagination();

  const [debouncedFilterText, setDebouncedSearchTerm] = useState(filterText);

  const filter = debouncedFilterText
    ? {
        filters: [
          {
            field: selectedFilterDimension,
            stringFilters: [{ ilike: debouncedFilterText }],
          },
        ],
      }
    : null;

  const [fetch, { data, loading }] = useLazyQuery(
    SEARCH_PROGRESS_ACTIVITY_TYPES,
    {
      variables: {
        projectId,
        searchTerm: !filterText ? '' : filterText,
        input: { limit, offset },
      },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    fetch({
      variables: {
        projectId,
        assigneeId,
        input: { limit, offset, ...(filter ? { filter } : {}) },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, projectId, assigneeId, debouncedFilterText]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setOffset(0);
      setDebouncedSearchTerm(filterText);
    }, 600);
    return () => {
      clearTimeout(handler);
    };
  }, [filterText, setOffset]);

  const projectActivityResults = data?.searchProgressActivityTypes;

  const totalPages = Math.ceil(projectActivityResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const { userProfile } = useContext(UserProfileContext);

  return (
    <div>
      <>
        {userProfile?.permissions?.includes(Permission.UpdateProject) ? (
          <div className="w-full flex justify-end px-4 py-2 gap-x-2">
            <Button
              onClick={() => navigate(`/projects/${projectId}/activities/bulk`)}
              text={'Bulk Edit'}
            />
            <Button
              onClick={() => navigate(`/projects/${projectId}/activities/new`)}
              type="submit"
              text="New Activity"
            />
          </div>
        ) : null}
        <p className="text-sm px-4 text-gray-600">
          Activities here can be used in daily progress reporting.
        </p>
        <ProjectActivitiesTable
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          totalResults={projectActivityResults?.count || 0}
          onClickRow={(id: any) => {
            if (userProfile?.permissions?.includes(Permission.UpdateProject)) {
              navigate(`/projects/${projectId}/activities/${id}`);
            }
          }}
          filterDimensions={[{ id: 'name', name: 'Name' }]}
          NoData={() => (
            <NoData loading={loading}>
              {debouncedFilterText
                ? 'It looks like there are no activities that match that filter!'
                : 'It looks like there are no activities for this project.'}
              <LinkButton
                to={`/projects/${projectId}/activities/new`}
                target="_blank"
              >
                Add an Activity
              </LinkButton>
            </NoData>
          )}
          data={projectActivityResults?.results?.map((activity: any) => ({
            id: activity.id,
            name: activity.name,
            group: activity?.pag?.name,
          }))}
          filterText={filterText}
          setFilterText={setFilterText}
          selectedFilterDimension={selectedFilterDimension}
          setSelectedFilterDimension={setSelectedFilterDimension}
          onPageChange={handlePageChange}
        />
      </>
      {children}
    </div>
  );
};

export const ProjectActivityList = ({
  projectId,
  assigneeId,
}: ProjectActivityListProps) => (
  <PaginationProvider>
    <ProjectActivityListPage projectId={projectId} assigneeId={assigneeId} />
  </PaginationProvider>
);
