import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import MultiColumnProgress from '../../../../components/MultiColumnProgress/MultiColumnProgress';
import {
  GET_PROJECT_LOCATION_ASSETS,
  GET_PROJECT_OUTCOMES,
} from '../../../../graphql/queries/projects';
import { useOrganisationAwareApollo } from '../../../../hooks/useOrganisationAwareApollo';
import { NetworkContext } from '../../../../context/NetworkContext';

export const OutcomeStatus = ({
  data,
  setData = () => {},
  setHasChanged = () => {},
  locked,
  isExpanded,
}: any) => {
  let { outcomeId, id } = useParams();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const { isOffline } = useContext(NetworkContext);

  const [fetch, { data: outcomeResult }] = useLazyQuery(GET_PROJECT_OUTCOMES, {
    variables: { input: { id: outcomeId }, projectId: id },
    fetchPolicy: 'network-only',
  });

  const [fetchAssets, { data: projectLocAssets }] = useLazyQuery(
    GET_PROJECT_LOCATION_ASSETS,
    {
      variables: { projectId: id },
      fetchPolicy: 'network-only',
    }
  );

  const assets = projectLocAssets?.getProjectLocationAssets?.results || [];

  useEffect(() => {
    if (outcomeId && id && !isOffline) {
      fetch();
      fetchAssets();
    }
  }, [outcomeId, id, isOffline]);

  const [outcome] = outcomeResult?.getProjectOutcomes?.results || [];

  return isOffline ? (
    <div className="px-4 py-2 bg-white text-sm">
      Not available in offline mode
    </div>
  ) : (
    <MultiColumnProgress
      isExpanded={isExpanded}
      outcome={outcome}
      setData={setData}
      isDisabled={locked}
      data={data}
      assets={assets}
      setHasChanged={setHasChanged}
    />
  );
};
