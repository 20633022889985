import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';
import { Table } from '../../../components/Tables/tables/Table';
import {
  ActiveBadge,
  BlueBadge,
  YellowBadge,
} from '../../../components/Badges/Badges';
import NoData from '../../../components/NoData/NoData';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';
import LinkButton from '../../../components/Buttons/LinkButton';
import useProjectCompanies from '../../../hooks/api/companies/useProjectCompanies';
import NotificationIcon from '../../../components/Notification/NotificationIcon';

type ProjectCompaniesListProps = {
  projectId: string;
};

const headerMapping = {
  companyName: 'Company Name',
  owner: 'Company Owner',
  companyEmail: 'Email',
  complianceIssues: 'Compliance Warning',
  companyRegNumber: 'Reg Number',
  enabled: 'Approved',
} as Record<string, any>;

function PaginatedProjectCompaniesList({
  projectId,
}: ProjectCompaniesListProps) {
  const { userProfile } = useContext(UserProfileContext);
  const navigate = useNavigate();

  const { limit, offset, setOffset } = usePagination();

  const [projectCompanies, { count, loading }] = useProjectCompanies({
    projectId,
    limit,
    offset,
  });

  return (
    <Table
      currentPage={Math.floor(offset / limit) + 1}
      totalPages={Math.ceil(count / limit)}
      itemsPerPage={limit}
      totalResults={count || 0}
      filterDimensions={[
        { id: 'companyName', name: 'Company Name' },
        { id: 'companyRegNumber', name: 'Reg Number' },
      ]}
      columnsToFilter={['id']}
      headerMapper={(header: string) => headerMapping[header] || header}
      onClickRow={(id: any) => {
        const company = projectCompanies.find(
          (company: any) => company.id === id
        )?.company;

        navigate(`/projects/${projectId}/companies/${company?.id}`);
      }}
      onPageChange={(pageNumber: number) => {
        setOffset((pageNumber - 1) * limit);
      }}
      data={projectCompanies?.map((result: any) => ({
        id: result.id,
        companyName: result.company?.companyName,
        companyRegNumber: result.company?.companyRegNumber,
        companyEmail: result.company?.companyEmail,
        complianceIssues:
          result?.company?.missingOrInvalidCertifications?.length > 0 ? (
            <NotificationIcon />
          ) : (
            ''
          ),
        owner: result.company?.profileId ? (
          <BlueBadge text="Has Owner" />
        ) : (
          <YellowBadge text="No Owner" />
        ),
        enabled: result?.enabled ? (
          <ActiveBadge text="Approved" />
        ) : (
          <YellowBadge text="Unapproved" />
        ),
      }))}
      NoData={() => (
        <NoData loading={loading}>
          It looks like there are no companies assigned to this project!
          {userProfile?.permissions.includes(Permission.UpdateProject) ? (
            <LinkButton to={`/projects/${projectId}/companies/assign`}>
              Assign Companies
            </LinkButton>
          ) : null}
        </NoData>
      )}
    />
  );
}

export function ProjectCompaniesList(props: ProjectCompaniesListProps) {
  return (
    <PaginationProvider>
      <PaginatedProjectCompaniesList {...props} />
    </PaginationProvider>
  );
}
