import { gql } from '@apollo/client';

export const SAVE_SKILL_TYPE = gql`
  mutation saveSkillType(
    $id: ID
    $input: SaveSkillTypeInput!
    $activeOrganisation: String!
  ) {
    saveSkillType(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const SAVE_SKILL = gql`
  mutation saveSkill(
    $id: ID
    $input: SaveSkillInput!
    $activeOrganisation: String!
  ) {
    saveSkill(id: $id, input: $input, activeOrganisation: $activeOrganisation) {
      success
      message
    }
  }
`;
