export const validateCodingsTotal = (entries: any, granularity: any) => {
  const dayTotals = {};

  entries?.forEach((entry: any) => {
    entry?.days?.forEach((day: any) => {
      if (day.codings && !entry.delete) {
        Object.values(day.codings)?.forEach((value) => {
          // @ts-ignore
          dayTotals[day.day] = (dayTotals[day.day] || 0) + value;
        });
      }
    });
  });

  const maxLimit =
    granularity.toLowerCase() === 'Hourly'.toLowerCase() ? 24 : 1;
  for (const day in dayTotals) {
    // @ts-ignore
    if (dayTotals[day] > maxLimit) {
      return `Total coding for ${day} exceeds the maximum limit of ${maxLimit} ${
        granularity === 'Hourly' ? 'hours' : ''
      }`;
    }
  }

  return null;
};

// @ts-ignore
export const validateCodingsPerEntry = (entry: any) => {
  const dayTotals = {};

  entry.days.forEach((day: any) => {
    if (day.codings && !entry.delete) {
      Object.values(day.codings).forEach((value) => {
        // @ts-ignore
        dayTotals[day.day] = (dayTotals[day.day] || 0) + value;
      });
    }
  });

  for (const day in dayTotals) {
    // @ts-ignore
    if (dayTotals[day] > 24) {
      return `Total coding for an entry in ${day} exceeds the maximum limit of 24 hours`;
    }
  }

  return null;
};
