import { useContext, useEffect, useMemo, useState } from 'react';
import { Table } from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';
import moment from 'moment';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Button } from '../../../components/Buttons/Button';
import { Permission } from '../../../types/Permissions';
import { useNavigate } from 'react-router-dom';
import {
  GreenBadge,
  RedBadge,
  YellowBadge,
} from '../../../components/Badges/Badges';
import MissingCertifications from '../../../components/MissingCertifications';
import useCertifications from '../../../hooks/api/certifications/useCertifications';

const headerMapping = {
  name: 'Certification Name',
  expiryDate: 'Expiry',
  expiry: 'Expiry Date',
  updatedAt: 'Updated At',
} as Record<string, any>;

const getRelativeExpiry = (text: string) => {
  if (!text) {
    return <YellowBadge text={'No date provided'} />;
  }
  const date = moment.unix(parseInt(text) / 1000);
  const now = moment();
  const tenWeeksFromNow = moment().add(10, 'weeks');

  const formattedDate = moment.unix(parseInt(text) / 1000).format('LL');

  if (date.isBefore(now)) {
    return <RedBadge text={`Expired on ${formattedDate}`} />;
  } else if (date.isBefore(tenWeeksFromNow)) {
    return (
      <YellowBadge text={`Expires ${date.fromNow()}, on ${formattedDate}`} />
    );
  } else {
    return (
      <GreenBadge text={`Expires ${date.fromNow()}, on ${formattedDate}`} />
    );
  }
};

export const CertificationDetailPage = ({
  labourResourceId,
  labourPath,
  labour,
}: any) => {
  const [filterText, setFilterText] = useState<string>();
  const [selectedFilterDimension, setSelectedFilterDimension] =
    useState<string>('name');

  const { limit, offset, setOffset } = usePagination();

  const [debouncedFilterText, setDebouncedSearchTerm] = useState(filterText);

  const filter = useMemo(
    () =>
      debouncedFilterText
        ? {
            filters: [
              {
                field: selectedFilterDimension,
                stringFilters: [{ ilike: debouncedFilterText }],
              },
            ],
          }
        : null,
    [debouncedFilterText, selectedFilterDimension]
  );

  const [certifications, { count, error, loading }, fetch] = useCertifications({
    labourResourceId,
    filter,
  });

  const totalPages = Math.ceil(count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setOffset(0);
      setDebouncedSearchTerm(filterText);
    }, 600);
    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText]);

  const { userProfile } = useContext(UserProfileContext);

  const navigate = useNavigate();

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <MissingCertifications
        className="m-4"
        missingOrInvalidCertifications={labour?.missingOrInvalidCertifications}
      >
        {labour?.firstName} has missing, incomplete, expired or soon to be
        expiring certifications required for their role, please add / update
        these certifications:
      </MissingCertifications>
      {userProfile?.permissions.includes(Permission.UpdateLabourProfiles) ? (
        <div className="flex w-full py-2 px-4 justify-end">
          <Button
            onClick={() =>
              navigate(
                `/labour/${labourPath}/${labourResourceId}/certifications/new`
              )
            }
            type="submit"
            text="Add Certification"
          />
        </div>
      ) : null}
      {loading && (
        <div className="h-64 flex items-center justify-center">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
        </div>
      )}
      {error && (
        <p className="px-4 py-5 sm:p-6 text-red-500">
          Error loading data. Please try again.
        </p>
      )}
      {certifications ? (
        <div className="border-t py-2 border-gray-200">
          <Table
            currentPage={Math.floor(offset / limit) + 1}
            totalPages={totalPages}
            totalResults={count || 0}
            itemsPerPage={limit}
            onClickRow={(id) => {
              navigate(
                `/labour/${labourPath}/${labourResourceId}/certifications/${id}/edit`
              );
            }}
            filterDimensions={[{ id: 'name', name: 'Name' }]}
            showFilterOptions
            filterText={filterText}
            setFilterText={setFilterText}
            selectedFilterDimension={selectedFilterDimension}
            setSelectedFilterDimension={setSelectedFilterDimension}
            columnsToFilter={['firstName', 'lastName', 'id', 'status']}
            headerMapper={(text: string) => headerMapping[text]}
            onPageChange={handlePageChange}
            data={
              certifications?.length > 0
                ? certifications?.map((result: any) => {
                    return {
                      id: result?.id,
                      name: result?.certificationType?.name,
                      expiryDate: result?.expiryDate
                        ? getRelativeExpiry(result?.expiryDate)
                        : null,
                      updatedAt: `${moment
                        .unix(parseInt(result?.updatedAt) / 1000)
                        .fromNow()}${
                        result?.lastUpdatedBy
                          ? ` by ${result?.lastUpdatedBy}`
                          : ''
                      }`,
                    };
                  })
                : []
            }
            fetchPageOfDataForExport={async (
              limit: number,
              pageOffset: number
            ) => {
              const { data } = await fetch({
                variables: {
                  input: {
                    limit,
                    offset: pageOffset,
                    ...(filter ? { filter } : {}),
                  },
                },
              });
              setOffset(pageOffset);
              return (
                data?.getCertifications?.results?.map((certification: any) => ({
                  id: certification?.id,
                  name: certification?.certificationType?.name,
                  expiryDate: certification?.expiryDate
                    ? moment
                        .unix(certification?.expiryDate / 1000)
                        .toISOString()
                    : null,
                  issueDate: certification?.issueDate
                    ? moment.unix(certification?.issueDate / 1000).toISOString()
                    : null,
                  createdAt: certification?.createdAt
                    ? moment.unix(certification?.createdAt / 1000).toISOString()
                    : null,
                  updatedAt: certification?.updatedAt
                    ? moment.unix(certification?.updatedAt / 1000).toISOString()
                    : null,
                  lastUpdatedBy: certification?.lastUpdatedBy,
                })) ?? []
              );
            }}
          />
        </div>
      ) : (
        <p className="px-4 py-5 sm:p-6 ">No certification available</p>
      )}
    </div>
  );
};

export const CertificationDetail = ({
  labour,
  labourResourceId,
  labourPath,
}: any) => (
  <PaginationProvider>
    <CertificationDetailPage
      labour={labour}
      labourResourceId={labourResourceId}
      labourPath={labourPath}
    />
  </PaginationProvider>
);
