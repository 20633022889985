import { Button } from '../Buttons/Button';

export function Tabs({
  activeTab,
  tabs,
  setActiveTab,
}: {
  activeTab: string;
  tabs: string[];
  setActiveTab: (tab: string) => void;
}) {
  return (
    <div className="w-full px-2 bg-gray-800 py-2 flex flex-col md:flex-row">
      {tabs.map((tab) => (
        <div
          className={`${
            activeTab === tab ? 'bg-black' : ''
          } justify-center flex items-center`}
        >
          <Button
            style={{ borderRadius: 0, backgroundColor: 'transparent' }}
            onClick={() => {
              setActiveTab(tab);
            }}
          >
            {tab}
          </Button>
        </div>
      ))}
    </div>
  );
}
