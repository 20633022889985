import { useNavigate, useParams } from 'react-router-dom';
import { useToast } from '../../../context/ToastContext';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { ASSIGN_LABOUR_TO_COMPANY } from '../../../graphql/mutations/company-details';
import { useState } from 'react';
import { SubcontractorList } from '../../Labour/subcontractors/list';
import { useCompany } from '../../../hooks/api/companies/useCompany';
import { Button } from '../../../components/Buttons/Button';
import { Table } from '../../../components/Tables/tables/Table';
import moment from 'moment';
import { AssignableSubcontractorList } from './AssignableSubcontractorList';
import Avatar from 'react-avatar';
import { BlueBadge, YellowBadge } from '../../../components/Badges/Badges';

const headerMapping = {
  name: 'Name',
  internalId: 'Internal ID',
  email: 'Email',
  company: 'Company',
  updatedAt: 'Updated At',
} as Record<string, string>;

export function AssignPeopleToCompanyPage() {
  let { id } = useParams();
  const navigate = useNavigate();
  const { useMutation } = useOrganisationAwareApollo();

  const { addToast } = useToast();

  const [saveAssignments, { loading: isSaving }] = useMutation(
    ASSIGN_LABOUR_TO_COMPANY
  );

  const [company, { error, loading }] = useCompany(id!);

  const [assignments, setAssignments] = useState<any[]>([]);

  const assignLabourToCompany = async () => {
    const labourResourceIds = assignments.map(({ id }) => id);

    const { data } = await saveAssignments({
      variables: { companyId: company.id, labourResourceIds },
    });

    if (data.assignLabourToCompany?.success) {
      navigate(`/companies/${company.id}?tab=Labour`);
      addToast('Assignments saved successfully', 'success');
    } else {
      addToast('Failed to save assignments', 'error');
    }
  };

  return (
    <div>
      <div className="flex w-full border-b mb-2">
        <Button
          style={{
            backgroundColor: 'transparent',
            color: 'black',
            boxShadow: 'none',
            borderRadius: 0,
          }}
          onClick={() => navigate(-1)}
          type="submit"
          text={`< Go Back`}
        />
      </div>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="flex flex-col md:items-center md:flex-row w-full md:justify-between px-4 py-5 sm:px-6 flex-wrap">
          <div className="flex flex-col">
            <h1 className="text-3xl font-extrabold text-gray-900">
              Assign Subcontractors to {company?.companyName}
            </h1>
          </div>
        </div>

        {loading && (
          <div className="h-64 flex items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
          </div>
        )}
        {error && (
          <p className="px-4 py-5 sm:p-6 text-red-500">
            Error loading data. Please try again.
          </p>
        )}
        {assignments.length > 0 ? (
          <div className="mb-2">
            <h4 className="my-2 px-4 text-sm text-gray-600">
              Assigning {assignments.length} Subcontractor
              {assignments.length === 1 ? '' : 's'}
            </h4>
            <Table
              headerMapper={(text) => headerMapping[text]}
              onClickRow={(id: any) => {
                setAssignments(
                  assignments.filter((assignment: any) => assignment.id !== id)
                );
              }}
              data={
                assignments.length > 0
                  ? assignments?.map((result: any) => ({
                      id: result?.id,
                      name: (
                        <div className="flex gap-x-2 items-center">
                          <Avatar
                            name={`${result?.firstName} ${result?.lastName}`}
                            size="30"
                            round
                          />
                          {`${result.lastName}, ${result.firstName}`}
                        </div>
                      ),
                      email: result?.email || 'Not Configured',
                      internalId: result?.internalId ? (
                        <BlueBadge text={result?.internalId} />
                      ) : (
                        <YellowBadge text={'Not Configured'} />
                      ),
                      company: result.company?.companyName,
                      updatedAt: moment
                        .unix(parseInt(result.updatedAt) / 1000)
                        .fromNow(),
                      status: undefined,
                    }))
                  : [{}]
              }
            />{' '}
            <div className="mt-4 mx-2 w-full mb-4">
              <Button
                isLoading={isSaving}
                text="Save Assignments"
                onClick={async () => {
                  await assignLabourToCompany();
                }}
              />
            </div>
          </div>
        ) : null}

        <div className="border-t border-gray-200 py-2 px-4">
          <p className="text-sm px-4 text-gray-600">
            Select subcontractors from the list below to assign as employees to
            this company.
          </p>
          {company ? (
            <AssignableSubcontractorList
              companyId={company.id}
              filterIds={assignments.map((assignment: any) => assignment.id)}
              onClickRow={(id, results) => {
                if (
                  !assignments.find((assignment: any) => assignment.id === id)
                ) {
                  setAssignments((assignments: any) => [
                    ...assignments,
                    results?.find((result) => result.id === id),
                  ]);
                }
              }}
            />
          ) : (
            <p className="px-4 py-4">No company data available</p>
          )}
        </div>
      </div>
    </div>
  );
}
