import { gql } from '@apollo/client';

export const GET_EVENT_TYPES = gql`
  query GetEventTypes($activeOrganisation: String!, $eventTypeName: String) {
    getEventTypes(
      activeOrganisation: $activeOrganisation
      eventTypeName: $eventTypeName
    ) {
      count
      results {
        name
        enabled
        config {
          emails
          sendToProjectManagers
          sendToAssignee
          sendToCompanyOwner
        }
      }
    }
  }
`;
