import moment from 'moment';
import { useEffect, useState } from 'react';
import { Table } from '../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../context/PaginationContext';

import { LoadingSpinner } from '../../components/Loading/LoadingSpinner';

import { useOrganisationAwareApollo } from '../../hooks/useOrganisationAwareApollo';

import RadioGroup from '../../components/Inputs/RadioGroup';

import {
  GreenBadge,
  RedBadge,
  YellowBadge,
} from '../../components/Badges/Badges';
import MultiSelectComboBox from '../../components/Inputs/MultiSelectComboBox';
import RatingChips from '../../components/Rating/RatingChips';
import { SEARCH_CERTIFICATION_TYPES } from '../../graphql/queries/certifications';
import { SEARCH_LABOUR_RESOURCES } from '../../graphql/queries/labour';
import { SEARCH_SKILL_TYPES } from '../../graphql/queries/skills';
import { useNavigate } from 'react-router-dom';

interface LabourSearchProps {
  children?: React.ReactElement;
}

const headerMapping = {
  name: 'Name',
  expiryDate: 'Expiry',
  skills: 'Skills',
  type: 'Type',
} as Record<string, any>;

const LaboursTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  showPagination,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
  filterText,
  showFilterOptions,
  setFilterText = () => {},
  selectedFilterDimension,
  setSelectedFilterDimension = () => {},
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    showPagination={showPagination}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    filterDimensions={[
      { id: 'firstName', name: 'First Name' },
      { id: 'lastName', name: 'Last Name' },
    ]}
    showFilterOptions={showFilterOptions}
    filterText={filterText}
    setFilterText={setFilterText}
    selectedFilterDimension={selectedFilterDimension}
    setSelectedFilterDimension={setSelectedFilterDimension}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
  />
);

export const LabourSearchPage = ({ children }: LabourSearchProps) => {
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [isSearching, setIsSearching] = useState(false);

  const { limit, offset, setOffset } = usePagination();
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  const [filterText, setFilterText] = useState<string>();
  const [selectedFilterDimension, setSelectedFilterDimension] =
    useState<string>('lastName');

  const [debouncedFilterText, setDebouncedFilterText] = useState(filterText);

  const [searchType, setSearchType] = useState('Certifications');

  const filter = debouncedFilterText
    ? {
        filters: [
          {
            field: selectedFilterDimension,
            stringFilters: [{ ilike: debouncedFilterText }],
          },
        ],
      }
    : null;

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 600); // 300ms debounce time
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setOffset(0);
      setDebouncedFilterText(filterText);
    }, 600);
    return () => {
      clearTimeout(handler);
    };
  }, [filterText]);

  useEffect(() => {
    performSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, searchType]);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const navigate = useNavigate();

  const [searchCertificationTypes, { data: certificationtypesData }] =
    useLazyQuery(SEARCH_CERTIFICATION_TYPES);

  const [searchSkillTypes, { data: skillTypesData }] =
    useLazyQuery(SEARCH_SKILL_TYPES);

  const [searchLabourResources, { data: labourResourceData }] = useLazyQuery(
    SEARCH_LABOUR_RESOURCES
  );

  const performSearch = async () => {
    try {
      if (searchType === 'Certifications') {
        await searchCertificationTypes({
          variables: {
            searchTerm: debouncedSearchTerm,
          },
          fetchPolicy: 'network-only',
        });
      } else {
        await searchSkillTypes({
          variables: {
            searchTerm: debouncedSearchTerm,
          },
          fetchPolicy: 'network-only',
        });
      }
    } catch (err) {
      // do nothing
    } finally {
    }
  };

  useEffect(() => {
    setSelectedSearchEntity([]);
  }, [searchType]);

  const [selectedSearchEntity, setSelectedSearchEntity] = useState<string[]>(
    []
  );

  const totalPages = Math.ceil(
    labourResourceData?.searchLabourResources?.count / limit
  );

  const now = moment();
  const tenWeeksFromNow = moment().add(10, 'weeks');

  useEffect(() => {
    const variables = {
      ...(searchType === 'Certifications'
        ? {
            certificationTypeIds: selectedSearchEntity.map(
              (entity: any) => entity.id
            ),
          }
        : {
            skillTypeIds: selectedSearchEntity.map((entity: any) => entity.id),
          }),
      input: {
        limit,
        offset,
        ...(filter ? { filter } : {}),
      },
    };

    searchLabourResources({
      variables,
      fetchPolicy: 'network-only',
    });
  }, [
    JSON.stringify(selectedSearchEntity),
    searchType,
    limit,
    offset,
    debouncedFilterText,
  ]);

  return (
    <div>
      <>
        <div className="w-full flex justify-between items-center py-2">
          <h1 className="text-xl font-bold py-4">Search Labour Pool</h1>
        </div>
        <RadioGroup
          value={searchType}
          question={{
            title: 'Search Type',
            id: 'search-type',
            options: [
              {
                id: 'Certifications',
                name: 'Certifications',
                value: 'Certifications',
              },
              { id: 'Skills', name: 'Skills', value: 'Skills' },
            ],
          }}
          handleChange={setSearchType}
        />

        <div className="items-center w-2/3 my-2">
          <MultiSelectComboBox
            testId="assessment-selection"
            placeholder={`Search by ${searchType}`}
            question={{
              id: 'Search',
              options:
                searchType === 'Certifications'
                  ? certificationtypesData?.searchCertificationTypes?.results.map(
                      (assessment: any) => ({
                        id: assessment.id,
                        name: assessment.name,
                        detail: assessment.internalId,
                      })
                    )
                  : skillTypesData?.searchSkillTypes?.results.map(
                      (assessment: any) => ({
                        id: assessment.id,
                        name: assessment.name,
                        detail: assessment.internalId,
                      })
                    ),
              onChange: (value: any) => setSearchTerm(value),
            }}
            handleChange={(id: any) => {
              setSelectedSearchEntity(id);
            }}
            value={selectedSearchEntity}
            validation={undefined}
          />
          {isSearching && (
            <div className="absolute right-0 mr-5">
              <LoadingSpinner spinnerStyle={{ borderColor: 'gray' }} />
            </div>
          )}
        </div>
        {!selectedSearchEntity || selectedSearchEntity.length === 0 ? (
          <p className="text-sm mb-2">
            You must select some criteria to search by. Start typing in the box
            above and select an option.
          </p>
        ) : null}
        <div className="py-2">
          {!isSearching ? (
            <LaboursTable
              currentPage={Math.floor(offset / limit) + 1}
              totalPages={totalPages}
              itemsPerPage={limit}
              showPagination={
                labourResourceData?.searchLabourResources?.count > 0
              }
              totalResults={
                labourResourceData?.searchLabourResources?.count || 0
              }
              columnsToFilter={['id']}
              onClickRow={(id: string) => {
                const lrsType =
                  labourResourceData?.searchLabourResources?.results.find(
                    (lrs: any) => lrs.id === id
                  )?.type;
                if (lrsType === 'EMPLOYEE') {
                  navigate(`/labour/employees/${id}`);
                } else {
                  navigate(`/labour/subcontractors/${id}`);
                }
              }}
              showFilterOptions
              filterText={filterText}
              setFilterText={setFilterText}
              selectedFilterDimension={selectedFilterDimension}
              setSelectedFilterDimension={setSelectedFilterDimension}
              data={
                labourResourceData?.searchLabourResources?.results.length > 0
                  ? labourResourceData?.searchLabourResources?.results.map(
                      ({ id, firstName, lastName, type, userProfile }: any) => {
                        return {
                          id,
                          name: `${lastName}, ${firstName}`,
                          type:
                            type === 'EMPLOYEE' ? 'Employee' : 'Subcontractor',
                          ...(searchType === 'Skills'
                            ? {
                                skills: userProfile?.skills?.map(
                                  (skill: any) => (
                                    <div className="flex gap-x-2 items-center justify-end">
                                      <p className="text-sm">
                                        {skill?.skillType?.name}:
                                      </p>
                                      <RatingChips rating={skill.rating} />
                                    </div>
                                  )
                                ),
                              }
                            : {}),
                          ...(searchType === 'Certifications'
                            ? {
                                expiryDate: (
                                  <div className="flex flex-col items-start gap-y-2">
                                    {userProfile?.certifications?.map(
                                      (certification: any) => {
                                        let Badge = <div />;

                                        const date = moment.unix(
                                          parseInt(certification.expiryDate) /
                                            1000
                                        );

                                        if (date.isBefore(now)) {
                                          Badge = <RedBadge text="Expired" />;
                                        } else if (
                                          date.isBefore(tenWeeksFromNow)
                                        ) {
                                          Badge = (
                                            <YellowBadge
                                              text={`Expires ${date.fromNow()}`}
                                            />
                                          );
                                        } else {
                                          Badge = (
                                            <GreenBadge
                                              text={`Expires ${date.fromNow()}`}
                                            />
                                          );
                                        }

                                        if (!certification.expiryDate) {
                                          Badge = (
                                            <YellowBadge
                                              text={'No date provided'}
                                            />
                                          );
                                        }

                                        return (
                                          <div className="flex items-start gap-x-2 gap-y-2">
                                            <p className="text-sm">
                                              {
                                                certification?.certificationType
                                                  ?.name
                                              }
                                              :
                                            </p>
                                            {Badge}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                ),
                              }
                            : {}),
                        };
                      }
                    )
                  : [{}]
              }
              onPageChange={handlePageChange}
            />
          ) : null}
          {searchTerm && !isSearching && false && <p>No Results Found.</p>}
        </div>
      </>
      {children}
    </div>
  );
};

export const LabourSearch = () => (
  <PaginationProvider>
    <LabourSearchPage />
  </PaginationProvider>
);
