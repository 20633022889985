import { gql } from '@apollo/client';

export const GET_CONTRACTS_SUMMARY = gql`
  query getContracts($input: GenericFindOptions, $activeOrganisation: String!) {
    getContracts(input: $input, activeOrganisation: $activeOrganisation) {
      count
      results {
        id
        name
        enabled
        updatedAt
      }
    }
  }
`;

export const GET_CONTRACTS = gql`
  query getContracts($input: GenericFindOptions, $activeOrganisation: String!) {
    getContracts(input: $input, activeOrganisation: $activeOrganisation) {
      count
      results {
        id
        name
        enabled
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_CONTRACTS_COUNT = gql`
  query getContracts($input: GenericFindOptions, $activeOrganisation: String!) {
    getContracts(input: $input, activeOrganisation: $activeOrganisation) {
      count
    }
  }
`;

export const GET_CONTRACT_FIELD_PREVIEW = gql`
  query getContractFieldPreview(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $assignmentRoleId: String!
  ) {
    getContractFieldPreview(
      input: $input
      activeOrganisation: $activeOrganisation
      assignmentRoleId: $assignmentRoleId
    ) {
      count
      results {
        field
        value
      }
    }
  }
`;

export const GET_DOCUSEAL_TOKEN_FOR_BUILDER = gql`
  query getDocusealToken($id: ID!, $activeOrganisation: String!) {
    getDocusealToken(id: $id, activeOrganisation: $activeOrganisation) {
      token
    }
  }
`;

export const SEARCH_CONTRACTS = gql`
  query searchContracts(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $searchTerm: String!
  ) {
    searchContracts(
      input: $input
      activeOrganisation: $activeOrganisation
      searchTerm: $searchTerm
    ) {
      count
      results {
        id
        name
        enabled
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_CONTRACT_SUBMISSIONS = gql`
  query getContractSubmissions(
    $projectId: String
    $assignmentRoleId: String
    $labourResourceId: String
    $contractId: String
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    getContractSubmissions(
      input: $input
      assignmentRoleId: $assignmentRoleId
      labourResourceId: $labourResourceId
      contractId: $contractId
      projectId: $projectId
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        status
        signerEmail
        signerSlug
        organisationSignerEmail
        organisationSignerName
        completedDocumentURLs {
          name
          url
        }
        assignmentRole {
          role {
            id
            name
          }
        }
        contract {
          id
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_MY_CONTRACT_SUBMISSIONS = gql`
  query getMyContractSubmissions(
    $projectId: String
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    getMyContractSubmissions(
      input: $input
      projectId: $projectId
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        status
        signerEmail
        signerSlug
        organisationSignerEmail
        organisationSignerName
        completedDocumentURLs {
          name
          url
        }
        assignmentRole {
          role {
            id
            name
          }
          assignment {
            project {
              name
            }
          }
        }
        contract {
          id
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`;
