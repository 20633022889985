import { useEffect } from 'react';
import { useOrganisationAwareApollo } from '../useOrganisationAwareApollo';
import { ApiHookMeta } from './types';
import { GET_EXPIRING_CERTIFICATIONS } from '../../graphql/queries/certifications';

export default function useLabourWithExpiringCertifications({
  certificationTypeId,
  projectId,
  dateFilter,
  filter,
  limit,
  offset,
}: {
  certificationTypeId?: string;
  projectId?: string;
  dateFilter?: string;
  filter?: any;
  limit?: number;
  offset?: number;
}): [any, ApiHookMeta, any?] {
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [fetch, { data, loading, error, ...result }] = useLazyQuery(
    GET_EXPIRING_CERTIFICATIONS,
    {
      variables: {
        input: { limit, offset, filter },
        dateFilter,
        ...(certificationTypeId ? { certificationTypeId } : {}),
        ...(projectId ? { projectId } : {}),
      },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  const expiringCertifications = data?.getExpiringCertifications?.results || [];
  const count = data?.getExpiringCertifications?.count || 0;

  return [
    expiringCertifications,
    {
      count,
      loading,
      error,
      ...result,
    },
    async (variables: any) => await fetch(variables),
  ];
}
