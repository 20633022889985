import { useEffect } from 'react';
import { SEARCH_PROJECTS } from '../../../graphql/queries/projects';
import { useOrganisationAwareApollo } from '../../useOrganisationAwareApollo';
import { ApiHookMeta } from '../types';

export default function useProjects({
  id,
  searchTerm,
  limit,
  offset,
}: {
  id?: string;
  searchTerm?: string;
  limit?: number;
  offset?: number;
}): [any, ApiHookMeta] {
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [fetchProject, { data, loading, error, ...result }] = useLazyQuery(
    SEARCH_PROJECTS,
    {
      variables: { searchTerm, input: { id, limit, offset } },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    fetchProject({ variables: { input: { id } } });
  }, [fetchProject, id]);

  const projects = data?.searchProjects?.results || [];
  const count = data?.searchProjects?.count || 0;

  return [
    projects,
    {
      count,
      loading,
      error,
      ...result,
    },
  ];
}

export function useProject(id?: string) {
  const [projects, meta] = useProjects({ id });

  const [project] = projects;

  return [project, meta];
}
