import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useEffect } from 'react';

import FormCard from '../../../components/FormCard/FormCard';

import {
  SAVE_PROGRESS_ACTIVITY_CATEGORY,
  SAVE_PROGRESS_CONDITION_TYPE,
} from '../../../graphql/mutations/progress-activity-submissions';
import { GET_PROGRESS_CONDITION_TYPES } from '../../../graphql/queries/progress-activity-submissions';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { FormConfig } from '../../../types/Form';
import { useToast } from '../../../context/ToastContext';

export const SaveProgressConditionType = () => {
  let { id, projectId } = useParams();
  const navigate = useNavigate();
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const { addToast } = useToast();

  const isUpdate = !!id;

  const [fetchProgressConditionTypes, { data }] = useLazyQuery(
    GET_PROGRESS_CONDITION_TYPES,
    {
      variables: { input: { id } },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (isUpdate) {
      fetchProgressConditionTypes({ variables: { input: { id }, projectId } });
    }
  }, [id, isUpdate]);

  const [ProgressConditionType] =
    data?.getProgressConditionTypes?.results || [];

  const [saveProgressConditionType] = useMutation(SAVE_PROGRESS_CONDITION_TYPE);

  const initialValues = id
    ? {
        id,
        name: ProgressConditionType?.name,
        unit: ProgressConditionType?.unit,
        dataType: ProgressConditionType?.dataType,
        options: ProgressConditionType?.options,
      }
    : { name: undefined, description: undefined };

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      await saveProgressConditionType({
        variables: {
          id: values?.id,
          input: {
            ...values,
            id: undefined,
            updatedAt: undefined,
            createdAt: undefined,
          },
        },
      });
      addToast('Progress condition saved', 'success');
      if (setSubmitting) {
        setSubmitting(false);
      }
      navigate(`/progress/conditions`);
    } catch (error) {
      console.error(error);
      addToast('Error saving progress condition', 'error');
    }
  };

  const ProgressConditionTypeConfig = {
    formSections: [
      {
        title: 'Condition Type',
        description: 'Basic details about the condition type',
        fields: [
          {
            id: 'name',
            type: 'input',
            title: 'Progress Condition Type',
            placeholder: 'Some progress condition type',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'unit',
            type: 'input',
            title: 'Units',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'options',
            type: 'array',
            title: 'Options',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'dataType',
            type: 'dropdown',
            title: 'Data Type',
            options: [
              {
                id: 'DECIMAL',
                name: 'DECIMAL',
              },
              {
                id: 'OPTIONS',
                name: 'OPTIONS',
              },
            ],
            inputTransformer: (text: string) => text,
          },
        ],
      },
    ],
  } as FormConfig;

  const ProgressConditionTypeCreationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required(),
    unit: Yup.string().required(),
    dataType: Yup.string().required(),
  });

  return (
    <FormCard
      key={`${ProgressConditionTypeConfig?.title}`}
      config={ProgressConditionTypeConfig}
      validationSchema={ProgressConditionTypeCreationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
};
