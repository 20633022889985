import {
  DocumentNode,
  LazyQueryHookOptions,
  MutationHookOptions,
  QueryHookOptions,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { useContext } from 'react';
import { ActiveOrganisationContext } from '../context/ActiveOrganisationContext';

export const useOrganisationAwareApollo = () => {
  const { activeOrganisation } = useContext(ActiveOrganisationContext);

  return {
    useLazyQuery: (query: DocumentNode, options?: LazyQueryHookOptions) =>
      useLazyQuery(query, {
        ...options,
        variables: {
          ...options?.variables,
          activeOrganisation: activeOrganisation?.id,
        },
      }),
    useMutation: (mutation: DocumentNode, options?: MutationHookOptions) =>
      useMutation(mutation, {
        ...options,
        variables: {
          ...options?.variables,
          activeOrganisation: activeOrganisation?.id,
        },
      }),
    useQuery: (query: DocumentNode, options?: QueryHookOptions) =>
      useQuery(query, {
        ...options,
        variables: {
          ...options?.variables,
          activeOrganisation: activeOrganisation?.id,
        },
      }),
  };
};
