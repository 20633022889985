import { AuthenticatedUserProvider } from './AuthenticatedUserContext';
import { NetworkProvider } from './NetworkContext';
import { ToastProvider } from './ToastContext';

interface ContextWrapperProps {
  children: React.ReactElement;
}

export const ContextWrapper = ({ children }: ContextWrapperProps) => {
  return (
    <ToastProvider>
      <AuthenticatedUserProvider>
        <NetworkProvider>{children}</NetworkProvider>
      </AuthenticatedUserProvider>
    </ToastProvider>
  );
};
