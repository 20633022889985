import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useContext, useEffect, useState } from 'react';

import FormCard from '../../../components/FormCard/FormCard';

import moment from 'moment';
import { Button } from '../../../components/Buttons/Button';
import QuestionBuilder from '../../../components/QuestionConfiguration/QuestionBuilder';
import { Table } from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { SAVE_ASSESSMENT_TYPE } from '../../../graphql/mutations/assessments';
import {
  DELETE_ASSESSMENT_ATTACHMENT,
  SAVE_ATTACHMENT,
} from '../../../graphql/mutations/attachments';
import {
  GET_ASSESSMENT_ATTACHMENTS,
  GET_ASSESSMENT_TYPES,
} from '../../../graphql/queries/assessments';
import { GET_ASSESSMENT_ATTACHMENT_URL } from '../../../graphql/queries/attachments';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { FormConfig } from '../../../types/Form';
import { Permission } from '../../../types/Permissions';
import { QuestionBuilderQuestion } from '../../../types/QuestionTypes';
import { saveFileToS3 } from '../../../utils/utils';
import { DeletionDialogue } from '../../../components/Deletion/DeletionDialogue';
import { SEARCH_ROLES } from '../../../graphql/queries/roles';
import { useToast } from '../../../context/ToastContext';
import useDebounce from '../../../hooks/useDebounce';

const AssessmentDefaultAttachments = ({ id }: any) => {
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const [getAssessmentAttachmentUrl] = useLazyQuery(
    GET_ASSESSMENT_ATTACHMENT_URL
  );

  const { userProfile } = useContext(UserProfileContext);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletionFocus, setDeletionFocus] = useState('');

  const [fetchAttachmentsForThisAssessment, { data: attachmentsData }] =
    useLazyQuery(GET_ASSESSMENT_ATTACHMENTS);
  const [saveAttachment] = useMutation(SAVE_ATTACHMENT);

  const [deleteAssessmentAttachment] = useMutation(
    DELETE_ASSESSMENT_ATTACHMENT
  );

  const [saveAssessmentType] = useMutation(SAVE_ASSESSMENT_TYPE);

  const headerMapping = {
    name: 'File Name',
    id: 'ID',
    download: 'Options',

    updatedAt: 'Updated At',
  } as Record<string, any>;

  const attachmentsConfig = {
    title: '',
    description: '',
    formSections: [
      {
        title: 'Upload Attachment',
        fields: [
          {
            id: 'file',
            type: 'file',
            limits: 'application/msword, application/pdf',
            description: 'PDF or DOCX',
          },
        ],
      },
    ],
  };

  const onSaveAttachment = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void,
    setFieldValue?: (field: any, values: any) => void
  ) => {
    let newAttachmentId;
    if (values?.file?.name) {
      const { data } = await saveAttachment({
        variables: {
          input: {
            name: values.importName,
            id: null,
            fileName: values.file.name,
          },
        },
      });

      const presignedUrl = data?.saveAttachment?.presignedUrl;

      await saveFileToS3(values.file, presignedUrl);
      newAttachmentId = data?.saveAttachment?.id;
      await saveAssessmentType({
        variables: {
          id,
          input: {
            newAttachmentId,
          },
        },
      });
    }
    setSubmitting(false);
    if (setFieldValue) {
      setFieldValue('file', undefined);
    }
    await fetchAttachmentsForThisAssessment({
      variables: { assessmentTypeId: id, input: { limit, offset } },
      fetchPolicy: 'network-only',
    });
  };

  const { limit, offset, setOffset } = usePagination();

  const downloadAttachment = async (id: string, fileName: string) => {
    const { data } = await getAssessmentAttachmentUrl({
      variables: { input: { id } },
    });
    if (data.getAssessmentAttachmentURL.presignedUrl) {
      // Create a new link
      const anchor = document.createElement('a');
      anchor.href = data.getAssessmentAttachmentURL.presignedUrl;
      anchor.download = fileName;
      anchor.style.display = 'none';

      // Append to the DOM
      document.body.appendChild(anchor);

      // Trigger `click` event
      anchor.click();

      // Remove element from DOM
      document.body.removeChild(anchor);

      // Revoke the Blob URL to free up resources
      URL.revokeObjectURL(data.getAssessmentAttachmentURL.presignedUrl);
    }
  };

  useEffect(() => {
    if (id) {
      fetchAttachmentsForThisAssessment({
        variables: {
          assessmentTypeId: id,
          input: { limit, offset },
        },
        fetchPolicy: 'network-only',
      });
    }
  }, [fetchAttachmentsForThisAssessment, id, limit, offset]);

  const totalPages = Math.ceil(
    attachmentsData?.getAssessmentAttachments?.count / limit
  );

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  return id ? (
    <div>
      <h1 className="my-2">Attachments</h1>
      <Table
        currentPage={Math.floor(offset / limit) + 1}
        totalPages={totalPages}
        totalResults={attachmentsData?.getAssessmentAttachments?.count || 0}
        itemsPerPage={limit}
        onClickRow={(id) => {}}
        columnsToFilter={[]}
        columnValueMapper={{
          updatedAt: (text: string) =>
            moment.unix(parseInt(text) / 1000).fromNow(),
        }}
        headerMapper={(text: string) => headerMapping[text]}
        onPageChange={handlePageChange}
        data={
          attachmentsData?.getAssessmentAttachments?.results.length > 0
            ? attachmentsData?.getAssessmentAttachments?.results?.map(
                (result: any) => ({
                  id: result?.id,
                  name: result?.fileName,
                  updatedAt: result?.updatedAt,
                  download: (
                    <div className="flex flex-row gap-x-2">
                      <Button
                        text={'Download'}
                        onClick={async () =>
                          await downloadAttachment(result.id, result?.fileName)
                        }
                      />
                      {userProfile?.permissions?.includes(
                        Permission.DeleteAssessmentAttachments
                      ) ? (
                        <Button
                          text={'Delete'}
                          style={{ backgroundColor: '#b3063c' }}
                          onClick={() => {
                            setDeletionFocus(result.id);
                            setShowDeleteModal(true);
                          }}
                        />
                      ) : null}
                    </div>
                  ),
                })
              )
            : []
        }
      />
      <DeletionDialogue
        deleteTitle={'Delete Attachment'}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        onDelete={async () => {
          await deleteAssessmentAttachment({
            variables: { id: deletionFocus },
          });
          await fetchAttachmentsForThisAssessment({
            variables: { assessmentTypeId: id, input: { limit, offset } },
            fetchPolicy: 'network-only',
          });
          setShowDeleteModal(false);
        }}
        deletionConfirmationText="Are you sure you want to delete this attachment? You will not be able to restore it once deleted."
      />
      <FormCard
        config={attachmentsConfig as FormConfig}
        validationSchema={Yup.object().shape({})}
        initialValues={{}}
        submitText="Upload"
        onSubmit={onSaveAttachment}
      />
    </div>
  ) : null;
};

export const SaveAssessmentType = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { useLazyQuery, useQuery, useMutation } = useOrganisationAwareApollo();

  const { addToast } = useToast();

  const [fetch, { data }] = useLazyQuery(GET_ASSESSMENT_TYPES, {
    variables: { input: { id } },
    fetchPolicy: 'network-only',
  });

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 600);

  const { data: searchRolesData } = useQuery(SEARCH_ROLES, {
    variables: {
      searchTerm: debouncedSearchTerm,
      input: { limit: 1000, offset: 0 },
    },
    fetchPolicy: 'network-only',
  });

  const [saveAttachment] = useMutation(SAVE_ATTACHMENT);

  const [assessmentType] = data?.getAssessmentTypes?.results || [];
  const roleResults = searchRolesData?.searchRoles?.results || [];

  const [questions, setQuestions] = useState<QuestionBuilderQuestion[]>(
    assessmentType?.questions || []
  );

  useEffect(() => {
    if (assessmentType?.questions) {
      setQuestions(assessmentType.questions);
    }
  }, [assessmentType]);

  const [saveAssessmentType] = useMutation(SAVE_ASSESSMENT_TYPE);
  const formConfig = {
    formSections: [
      {
        title: 'Assessment Details',
        fields: [
          {
            title: 'Name',
            id: 'name',
            type: 'input',
          },
          {
            title: 'Notifier email',
            id: 'notifierEmail',
            required: true,
            description:
              'Who will be notified in relation to this Assessment, e.g. for expiry',
            type: 'email',
          },
          {
            title: 'Assessment Information',
            id: 'assessmentInformation',
            description:
              'This will be included in all email communications about this assessment',
            type: 'richtextinput',
          },
          {
            id: 'requiredForRoles',
            type: 'multiselecttable',
            title: 'Required For Roles',
            description:
              'Note: If no roles are specified and "Required For Subcontractors" or "Required For Employees" are switched on, this assessment will apply to all roles.',
            options: roleResults?.map((role: any) => ({
              name: role.name,
              value: role.id,
            })),
            searchTerm,
            setSearchTerm,
          },
          {
            title: 'Required For Companies',
            id: 'requiredForCompanies',
            type: 'switch',
          },
          {
            title: 'Required For Subcontractors',
            id: 'requiredForSubcontractors',
            type: 'switch',
          },
          {
            title: 'Required For Employees',
            id: 'requiredForEmployees',
            type: 'switch',
          },
          {
            title: 'Enabled',
            id: 'enabled',
            type: 'switch',
          },
        ],
      },
      {
        title: 'Questions',
        components: [
          <QuestionBuilder questions={questions} setQuestions={setQuestions} />,
        ],
      },
      {
        title: 'Default Attachments',
        description: assessmentType?.id
          ? ''
          : 'Note: You will only be able to upload one attachment when initially creating this assessment, however you will be able to upload more once it is created.',
        fields: !assessmentType?.id
          ? [
              {
                id: 'file',
                type: 'file',
                limits: 'application/msword, application/pdf',
                description: 'PDF or DOCX',
              },
            ]
          : [],
        components: assessmentType?.id
          ? [
              <PaginationProvider>
                <AssessmentDefaultAttachments id={assessmentType?.id} />
              </PaginationProvider>,
            ]
          : [],
      },
      {
        title: 'Assessment Outcomes',
        fields: [
          {
            title: 'What possible outcomes can this assessment have?',
            description: 'This could be as simple as "PASS" or "FAIL".',
            id: 'outcomes',
            type: 'inputlist',
          },
        ],
      },
    ],
  } as FormConfig;

  const initialValues = id
    ? {
        assessmentInformation: '',
        ...assessmentType,
        outcomes:
          assessmentType?.outcomes?.map((outcome: any) => ({
            id: outcome,
            value: outcome,
          })) || [],
      }
    : { assessmentInformation: '', outcomes: [] };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    notifierEmail: Yup.string().email().required('Notifier email is required'),
  });

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      let newAttachmentId;
      if (values?.file?.name) {
        const { data } = await saveAttachment({
          variables: {
            input: {
              name: values.importName,
              id: null,
              fileName: values.file.name,
            },
          },
        });

        const presignedUrl = data?.saveAttachment?.presignedUrl;

        await saveFileToS3(values.file, presignedUrl);
        newAttachmentId = data?.saveAttachment?.id;
      }
      await saveAssessmentType({
        variables: {
          id: values?.id,
          input: {
            ...values,
            questions,
            file: undefined,
            newAttachmentId,
            outcomes: values?.outcomes?.map((outcome: any) => outcome.value),
            id: undefined,
            updatedAt: undefined,
            createdAt: undefined,
          },
        },
      });
      addToast('Assessment type saved', 'success');
      setSubmitting(false);
      navigate(`/labour/assessments`);
    } catch (error) {
      console.error(error);
      addToast('Error saving assessment type', 'error');
    }
  };

  return (
    <FormCard
      key={`${formConfig?.title}_${data?.getAssessmentTypes?.results.length}`}
      config={formConfig}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
};
