import { useContext, useState } from 'react';
import { CvExportTable } from '../../../../Labour/cv/CvExportTable';
import { UserProfileContext } from '../../../../../context/UserProfileContext';
import { useOrganisationAwareApollo } from '../../../../../hooks/useOrganisationAwareApollo';
import { GET_CV_EXPORTS_FOR_LABOUR_RESOURCE } from '../../../../../graphql/queries/exports';
import { LoadingSpinner } from '../../../../../components/Loading/LoadingSpinner';
import { Button } from '../../../../../components/Buttons/Button';
import { Export } from '../../../../Labour/cv/CvExportList';
import { Permission } from '../../../../../types/Permissions';
import { CvExportModal } from '../../../../Labour/cv/CvExportModal';
import { exponentialBackoff } from '../../../../../utils/utils';
import { Link } from 'react-router-dom';
import { YellowBadge } from '../../../../../components/Badges/Badges';

type CvExportSectionProps = {
  assignmentRole: {
    id: string;
    assignment: {
      lrs: {
        id: string;
        firstName: string;
        lastName: string;
        type: string;
      };
    };
    role: {
      name: string;
      description?: string;
    };
  };
};

export function CvExportSection({ assignmentRole }: CvExportSectionProps) {
  const { userProfile } = useContext(UserProfileContext);
  const { useQuery } = useOrganisationAwareApollo();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showExportModal, setShowExportModal] = useState<boolean>(false);

  const { data, loading, refetch } = useQuery(
    GET_CV_EXPORTS_FOR_LABOUR_RESOURCE,
    {
      variables: {
        labourResourceId: assignmentRole.assignment.lrs.id,
        assignmentRoleId: assignmentRole.id,
        input: { limit: 1, offset: 0 },
      },
      fetchPolicy: 'network-only',
    }
  );

  const count = data?.getCvExportsForLabourResource?.count;
  const exports: Export[] = data?.getCvExportsForLabourResource?.results;

  const exportIsProcessing = exports?.some(
    (entity) => entity.status === 'PENDING'
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  const labourResource = assignmentRole.assignment.lrs;

  return (
    <>
      <CvExportModal
        labour={assignmentRole.assignment.lrs}
        assignmentRole={assignmentRole}
        showModal={showExportModal}
        setShowModal={setShowExportModal}
        setIsSubmitting={setIsSubmitting}
        onConfirm={() => {
          setIsSubmitting(false);
          // Check for 4 minutes with a backoff
          exponentialBackoff(() => refetch(), 240000);
        }}
      />
      <div className="flex flex-col gap-y-4 py-4">
        {userProfile?.permissions.includes(Permission.UpdateLabourProfiles) ? (
          <div className="w-full flex flex-col lg:flex-row justify-between items-center gap-y-4 py-4 px-6">
            <p>
              You can view all CV's for {labourResource.firstName}{' '}
              {labourResource.lastName} on their{' '}
              <Link
                to={`/labour/${
                  labourResource.type === 'SUBCONTRACTOR'
                    ? `subcontractors/${labourResource.id}`
                    : `employees/${labourResource.id}`
                }?tab=CV`}
                className="font-bold underline"
              >
                labour profile.
              </Link>{' '}
              Please note, this capability is <YellowBadge text="Beta" /> and is
              available for feedback purposes.
            </p>
            <Button
              disabled={loading || isSubmitting || exportIsProcessing}
              onClick={() => setShowExportModal(true)}
            >
              New Export
            </Button>
          </div>
        ) : null}
        <CvExportTable
          labour={assignmentRole.assignment.lrs}
          count={count}
          exports={exports}
          offset={0}
          setOffset={() => {}}
          limit={1}
          loading={loading}
          showPagination={false}
          noDataMessage="It looks like there are no CV exports for this assignment role."
        />
      </div>
    </>
  );
}
