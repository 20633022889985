import { DocusealBuilder } from '@docuseal/react';
import { useEffect } from 'react';
import { useOrganisationAwareApollo } from '../../hooks/useOrganisationAwareApollo';

import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../components/Buttons/Button';
import {
  DocusealFieldMapping,
  DocusealRoles,
} from '../../constants/DocusealFields';
import { GET_ACTIVITY_CODES } from '../../graphql/queries/activity-codes';
import { GET_DOCUSEAL_TOKEN_FOR_BUILDER } from '../../graphql/queries/contracts';

interface ContractsBuilderProps {
  contractId?: string;
  showBackButton?: boolean;
  preview?: boolean;
}

export const ContractsBuilder = ({
  contractId,
  showBackButton = true,
  preview = false,
}: ContractsBuilderProps) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [fetchDocusealToken, { data: docusealTokenData }] = useLazyQuery(
    GET_DOCUSEAL_TOKEN_FOR_BUILDER,
    {
      variables: { id: contractId ?? id },
      fetchPolicy: 'network-only',
    }
  );

  const [fetchActivityCodes, { data: activityCodesData }] = useLazyQuery(
    GET_ACTIVITY_CODES,
    {
      variables: {},
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    fetchActivityCodes();
  }, [fetchActivityCodes]);

  useEffect(() => {
    if (id || contractId) {
      fetchDocusealToken({ variables: { id: contractId ?? id } });
    }
  }, [id, contractId, fetchDocusealToken]);

  const activityCodes = activityCodesData?.getActivityCodes?.results || [];

  const { token } = docusealTokenData?.getDocusealToken || {};

  const subcontractorFields = DocusealFieldMapping[DocusealRoles.SUBCONTRACTOR];

  const preDefinedFields = [
    ...subcontractorFields.map((subcontractorField) => ({
      name: subcontractorField.field,
      role: DocusealRoles.SUBCONTRACTOR,
    })),
    ...activityCodes.map((activityCode: any) => ({
      name: `Rate: (${activityCode.code}) ${activityCode.name}`,
      role: DocusealRoles.SUBCONTRACTOR,
    })),
  ];

  return (
    <>
      {showBackButton ? (
        <Button
          style={{
            backgroundColor: 'transparent',
            color: 'black',
            boxShadow: 'none',
            borderRadius: 0,
          }}
          onClick={() => navigate(`/labour/contracts`)}
          type="submit"
          text={`< Back to Contracts`}
        />
      ) : null}
      {!preview ? (
        <p className="px-4 py-4 text-xs text-gray-600">
          Note: there is no need to save - changes to this contract template are
          autosaved!
        </p>
      ) : null}
      {token ? (
        <DocusealBuilder
          preview={preview}
          roles={[DocusealRoles.SUBCONTRACTOR, DocusealRoles.ORGANISATION]}
          fields={preDefinedFields}
          withRecipientsButton={false}
          withSignYourselfButton={false}
          token={token}
        />
      ) : null}
    </>
  );
};
