import { Dialog, Transition } from '@headlessui/react';
import { DocumentCheckIcon } from '@heroicons/react/24/outline';
import { Fragment, useContext } from 'react';
import { SAVE_MY_PERSONAL_DETAILS } from '../../graphql/mutations/personal';
import { useOrganisationAwareApollo } from '../../hooks/useOrganisationAwareApollo';
import { UserProfileContext } from '../../context/UserProfileContext';

interface EULAModalProps {
  open?: boolean;
  setOpen: (open: boolean) => void;
}

const EULA_VERSION = '1.0.0';

export default function EULAModal({ open, setOpen }: EULAModalProps) {
  const { useMutation } = useOrganisationAwareApollo();

  const { refreshUserProfile } = useContext(UserProfileContext);

  const [saveMyPersonalDetails] = useMutation(SAVE_MY_PERSONAL_DETAILS);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl  md:h-auto transition-all sm:my-8 w-full md:max-w-[60%] sm:p-6">
                <div>
                  <div className="w-full flex items-center justify-between">
                    <img
                      className="h-10 w-auto"
                      src="/logo.svg"
                      alt="EngGenius Technology"
                    />
                    <div className="flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <DocumentCheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                  <div className="mt-3 text-center flex flex-col items-center justify-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-sm font-semibold leading-6 text-gray-900"
                    >
                      End User License Agreement
                    </Dialog.Title>
                    <div className="mt-2 w-full">
                      <p className="text-sm text-gray-500 ">
                        <iframe
                          height={'100%'}
                          width={'100%'}
                          className="w-full"
                          src="/eula.html"
                        />
                      </p>
                      <hr className="mt-2" />
                      <p className="text-xs mt-4 mb-4">
                        Version: {EULA_VERSION}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-3 sm:mt-2">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                    onClick={async () => {
                      await saveMyPersonalDetails({
                        variables: {
                          input: { signedEULAVersion: EULA_VERSION },
                        },
                      });
                      await refreshUserProfile();
                      setOpen(false);
                    }}
                  >
                    Accept and Proceed
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
