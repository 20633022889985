import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useEffect } from 'react';

import FormCard from '../../../components/FormCard/FormCard';

import {
  SAVE_PROGRESS_ACTIVITY_CATEGORY,
  SAVE_PROGRESS_INCIDENT_TYPE,
} from '../../../graphql/mutations/progress-activity-submissions';
import { GET_PROGRESS_INCIDENT_TYPES } from '../../../graphql/queries/progress-activity-submissions';
import { GET_PROJECT_ACTIVITY_TYPES } from '../../../graphql/queries/projects';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { FormConfig } from '../../../types/Form';
import { useToast } from '../../../context/ToastContext';

export const SaveProgressIncidentType = () => {
  let { id, projectId } = useParams();
  const navigate = useNavigate();
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const { addToast } = useToast();

  const isUpdate = !!id;

  const [fetchProgressIncidentTypes, { data }] = useLazyQuery(
    GET_PROGRESS_INCIDENT_TYPES,
    {
      variables: { input: { id } },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (isUpdate) {
      fetchProgressIncidentTypes({ variables: { input: { id }, projectId } });
    }
  }, [id, isUpdate]);

  const [ProgressIncidentType] = data?.getProgressIncidentTypes?.results || [];

  const [saveProgressIncidentType] = useMutation(SAVE_PROGRESS_INCIDENT_TYPE);

  const initialValues = id
    ? {
        id,
        name: ProgressIncidentType?.name,
      }
    : { name: undefined, description: undefined };

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      await saveProgressIncidentType({
        variables: {
          id: values?.id,
          input: {
            ...values,
            id: undefined,
            updatedAt: undefined,
            createdAt: undefined,
          },
        },
      });
      addToast('Incident type saved successfully', 'success');
      if (setSubmitting) {
        setSubmitting(false);
      }
      navigate(`/progress/hseq`);
    } catch (error) {
      console.error(error);
      addToast('Error saving incident type', 'error');
    }
  };

  const ProgressIncidentTypeConfig = {
    formSections: [
      {
        title: 'Incident Type',
        description: 'Basic details about the incident type',
        fields: [
          {
            id: 'name',
            type: 'input',
            title: 'Incident Type Name',
            placeholder: 'Some incident type name',
            inputTransformer: (text: string) => text,
          },
        ],
      },
    ],
  } as FormConfig;

  const ProgressIncidentTypeCreationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required(),
  });

  return (
    <FormCard
      key={`${ProgressIncidentTypeConfig?.title}`}
      config={ProgressIncidentTypeConfig}
      validationSchema={ProgressIncidentTypeCreationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
};
