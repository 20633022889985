import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Buttons/Button';
import { Table } from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';
import { UserProfileContext } from '../../../context/UserProfileContext';

import { BlueBadge } from '../../../components/Badges/Badges';
import { GET_CERTIFICATION_TYPES } from '../../../graphql/queries/certifications';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { Permission } from '../../../types/Permissions';

interface CertificationTypeListProps {
  showNewItemButton?: boolean;
  children?: React.ReactElement;
  filterIds?: string[];
  onClickRow?: (id: string, results: any[]) => void;
}

const headerMapping = {
  name: 'Name',
  email: 'Email',
  required: 'Required For',
  updatedAt: 'Updated At',
  dashboard: 'Dashboard',
} as Record<string, any>;

const CertificationTypesTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
  filterText,
  setFilterText = () => {},
  selectedFilterDimension,
  fetchPageOfDataForExport,
  setSelectedFilterDimension = () => {},
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    columnsToFilter={['firstName', 'lastName', 'id', 'status']}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    filterDimensions={[{ id: 'name', name: 'Name' }]}
    showFilterOptions
    filterText={filterText}
    setFilterText={setFilterText}
    selectedFilterDimension={selectedFilterDimension}
    setSelectedFilterDimension={setSelectedFilterDimension}
    fetchPageOfDataForExport={fetchPageOfDataForExport}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
  />
);

export const CertificationTypeListPage = ({
  children,
  showNewItemButton,
  onClickRow,
  filterIds = [],
}: CertificationTypeListProps) => {
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [filterText, setFilterText] = useState<string>();
  const [selectedFilterDimension, setSelectedFilterDimension] =
    useState<string>('name');

  const { limit, offset, setOffset } = usePagination();

  const [debouncedFilterText, setDebouncedSearchTerm] = useState(filterText);

  const filter = debouncedFilterText
    ? {
        filters: [
          {
            field: selectedFilterDimension,
            stringFilters: [{ ilike: debouncedFilterText }],
          },
        ],
      }
    : null;
  const [fetch, { data }] = useLazyQuery(GET_CERTIFICATION_TYPES, {
    variables: { input: { limit, offset } },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetch({
      variables: { input: { limit, offset, ...(filter ? { filter } : {}) } },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, debouncedFilterText]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setOffset(0);
      setDebouncedSearchTerm(filterText);
    }, 600);
    return () => {
      clearTimeout(handler);
    };
  }, [filterText, setOffset]);

  const certificationtypeResults = data?.getCertificationTypes;

  const totalPages = Math.ceil(certificationtypeResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const { userProfile } = useContext(UserProfileContext);

  return (
    <div>
      <>
        <div className="my-2 border-b pb-4">
          <p className="text-sm mb-1 text-gray-600">
            Use the button below to view members of the labour pool whose
            certifications have expired / are expiring soon:
          </p>
          <Button
            text="View Expiring Certifications"
            onClick={() => navigate(`/labour/certifications/expiring`)}
          />
        </div>
        {showNewItemButton ? (
          <div className="w-full flex justify-between items-center py-2">
            <h1 className="text-xl font-bold py-4">Certification Types</h1>
            {userProfile?.permissions.includes(
              Permission.UpdateLabourProfiles
            ) ? (
              <Button
                text="New Certification Type"
                onClick={() => navigate(`/labour/certifications/new`)}
              />
            ) : null}
          </div>
        ) : null}

        <CertificationTypesTable
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          totalResults={certificationtypeResults?.count || 0}
          onClickRow={(id: any) => {
            if (onClickRow) {
              onClickRow(id, certificationtypeResults?.results);
            } else {
              navigate(`/labour/certifications/${id}`);
            }
          }}
          filterText={filterText}
          setFilterText={setFilterText}
          selectedFilterDimension={selectedFilterDimension}
          setSelectedFilterDimension={setSelectedFilterDimension}
          data={
            certificationtypeResults?.results.length > 0
              ? certificationtypeResults?.results
                  .filter((result: any) => !filterIds?.includes(result.id))
                  .map((result: any) => ({
                    id: result.id,
                    name: result?.name,
                    required: (
                      <div className="flex flex-row flex-wrap gap-x-2 items-center justify-left">
                        {result?.requiredForCompanies ? (
                          <BlueBadge text="Companies" />
                        ) : null}
                        {result?.requiredForEmployees ? (
                          <BlueBadge text="Employees" />
                        ) : null}
                        {result?.requiredForSubcontractors ? (
                          <BlueBadge text="Subcontractors" />
                        ) : null}
                        {result?.requiredForRoles ? (
                          <BlueBadge
                            text={`Roles: ${result?.requiredForRoles?.length}`}
                          />
                        ) : null}
                      </div>
                    ),
                    updatedAt: result?.updatedAt,
                    dashboard: (
                      <Button
                        style={{ background: 'black' }}
                        text="View Status"
                        aria-label={`View Status for ${result.name}`}
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          e.stopPropagation();
                          navigate(
                            `/labour/certifications/${result.id}/dashboard`
                          );
                        }}
                      />
                    ),
                  }))
              : [{}]
          }
          fetchPageOfDataForExport={async (
            limit: number,
            pageOffset: number
          ) => {
            const { data } = await fetch({
              variables: {
                input: {
                  limit,
                  offset: pageOffset,
                  ...(filter ? { filter } : {}),
                },
              },
            });
            setOffset(pageOffset);
            return data?.getCertificationTypes?.results ?? [];
          }}
          onPageChange={handlePageChange}
        />
      </>
      {children}
    </div>
  );
};

export const CertificationTypeList = ({
  onClickRow,
  showNewItemButton = true,
  filterIds = [],
}: CertificationTypeListProps) => (
  <PaginationProvider>
    <CertificationTypeListPage
      onClickRow={onClickRow}
      showNewItemButton={showNewItemButton}
      filterIds={filterIds}
    />
  </PaginationProvider>
);
