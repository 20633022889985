import * as React from 'react';
import { GET_MY_USER_PROFILE } from '../graphql/queries/my-profile';
import { useOrganisationAwareApollo } from '../hooks/useOrganisationAwareApollo';
import { UserProfile } from '../types/User';
import { ActiveOrganisationContext } from './ActiveOrganisationContext';
import { AuthenticatedUserContext } from './AuthenticatedUserContext';

interface UserProfileContextProps {
  userProfile?: UserProfile;
  isLoading?: boolean;
  missingOrInvalidCertifications?: [];
  refreshUserProfile: () => Promise<void>;
}

export const UserProfileContext = React.createContext<UserProfileContextProps>({
  userProfile: undefined,
  refreshUserProfile: () => Promise.resolve(),
});

export const UserProfileProvider: React.FunctionComponent<
  React.PropsWithChildren
> = (props) => {
  const [userProfile, setUserProfile] = React.useState<UserProfile | undefined>(
    undefined
  );

  const [isLoading, setIsLoading] = React.useState(true);

  const { activeOrganisation } = React.useContext(ActiveOrganisationContext);

  const { useLazyQuery } = useOrganisationAwareApollo();

  const { user, retrieveUserAndSetAsCurrent } = React.useContext(
    AuthenticatedUserContext
  );

  const [fetchUserProfile, { data }] = useLazyQuery(GET_MY_USER_PROFILE, {
    fetchPolicy: 'network-only',
  });

  const refreshUserProfile = async () => {
    setIsLoading(true);
    if (retrieveUserAndSetAsCurrent) {
      await retrieveUserAndSetAsCurrent();
    }
    const result = await fetchUserProfile({});
    if (result?.data?.getMyUserProfile) {
      setUserProfile(result?.data?.getMyUserProfile);
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    if (user.isAuthenticated) {
      refreshUserProfile();
    }
  }, [user.isAuthenticated, activeOrganisation?.id]);

  React.useEffect(() => {
    if (data?.getMyUserProfile?.personalDetails) {
      setUserProfile(data?.getMyUserProfile);
    }
  }, [JSON.stringify(data)]);

  return (
    <UserProfileContext.Provider
      value={{ userProfile, refreshUserProfile, isLoading }}
    >
      {props.children}
    </UserProfileContext.Provider>
  );
};
