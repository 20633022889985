import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';

import moment from 'moment';
import { ActiveBadge, DisabledBadge } from '../../../components/Badges/Badges';
import { Button } from '../../../components/Buttons/Button';
import { GET_TIMESHEET_CONFIGURATIONS_SUMMARY } from '../../../graphql/queries/timesheet-configurations';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import NoTimesheets from '../../../components/NoData/NoTimesheets';

interface TimesheetConfigurationListProps {
  children?: React.ReactElement;
  showNewItemButton?: boolean;
  filterIds?: string[];
  onClickRow?: (id: string, results: any[]) => void;
}

const headerMapping = {
  name: 'Name',
  email: 'Email',
  status: 'Status',
  updatedAt: 'Updated At',
} as Record<string, any>;

const TimesheetConfigurationsTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
  filterText,
  setFilterText = () => {},
  selectedFilterDimension,
  setSelectedFilterDimension = () => {},
  NoData,
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    columnsToFilter={['firstName', 'lastName', 'id', 'permittedGroups']}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    filterDimensions={[{ id: 'name', name: 'Name' }]}
    showFilterOptions
    filterText={filterText}
    setFilterText={setFilterText}
    selectedFilterDimension={selectedFilterDimension}
    setSelectedFilterDimension={setSelectedFilterDimension}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
    NoData={NoData}
  />
);

export const TimesheetConfigurationListPage = ({
  children,
  onClickRow,
  showNewItemButton,
  filterIds,
}: TimesheetConfigurationListProps) => {
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [filterText, setFilterText] = useState<string>();
  const [selectedFilterDimension, setSelectedFilterDimension] =
    useState<string>('name');

  const { limit, offset, setOffset } = usePagination();

  const [debouncedFilterText, setDebouncedSearchTerm] = useState(filterText);

  const filter = debouncedFilterText
    ? {
        filters: [
          {
            field: selectedFilterDimension,
            stringFilters: [{ ilike: debouncedFilterText }],
          },
        ],
      }
    : null;
  const [fetch, { data, loading }] = useLazyQuery(
    GET_TIMESHEET_CONFIGURATIONS_SUMMARY,
    {
      variables: { input: { limit, offset } },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    fetch({
      variables: { input: { limit, offset, ...(filter ? { filter } : {}) } },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, debouncedFilterText]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setOffset(0);
      setDebouncedSearchTerm(filterText);
    }, 600);
    return () => {
      clearTimeout(handler);
    };
  }, [filterText, setOffset]);

  const timesheetConfigurationResults = data?.getTimesheetConfigurations;

  const totalPages = Math.ceil(timesheetConfigurationResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  return (
    <div>
      <>
        {showNewItemButton ? (
          <div className="w-full flex justify-between items-center py-2">
            <h1 className="text-xl font-bold py-4">Configurations</h1>
            <Button
              text="New Timesheet"
              onClick={() => navigate(`/time/timesheets/configuration/new`)}
            />
          </div>
        ) : null}
        <TimesheetConfigurationsTable
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          totalResults={timesheetConfigurationResults?.count || 0}
          onClickRow={(id: any) => {
            if (onClickRow) {
              onClickRow(id, timesheetConfigurationResults?.results);
            } else {
              navigate(`/time/timesheets/configuration/${id}`);
            }
          }}
          filterText={filterText}
          setFilterText={setFilterText}
          selectedFilterDimension={selectedFilterDimension}
          setSelectedFilterDimension={setSelectedFilterDimension}
          NoData={() => <NoTimesheets loading={loading} />}
          data={timesheetConfigurationResults?.results
            ?.filter((result: any) => !filterIds?.includes(result.id))
            .map((result: any) => ({
              name: `${result.lastName}, ${result.firstName}`,
              status: result.enabled ? <ActiveBadge /> : <DisabledBadge />,
              ...result,
            }))}
          onPageChange={handlePageChange}
        />
      </>
      {children}
    </div>
  );
};

export const TimesheetConfigurationList = ({
  onClickRow,
  showNewItemButton = true,
  filterIds = [],
}: TimesheetConfigurationListProps) => (
  <PaginationProvider>
    <TimesheetConfigurationListPage
      showNewItemButton={showNewItemButton}
      onClickRow={onClickRow}
      filterIds={filterIds}
    />
  </PaginationProvider>
);
