export const LoadingSpinner = ({ spinnerStyle }: any) => {
  return (
    <div className="flex items-center justify-center ">
      <div
        style={spinnerStyle}
        className="w-5 h-5 border-b-2 border-dotted border-white rounded-full animate-spin"
      ></div>
    </div>
  );
};
