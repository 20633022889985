import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import * as Yup from 'yup';

import FormSubmit from '../../../components/Buttons/FormSubmit';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';

import { Button } from '../../../components/Buttons/Button';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { SAVE_CUSTOM_FIELD_GROUP } from '../../../graphql/mutations/custom-data';
import { GET_CUSTOM_FIELDS } from '../../../graphql/queries/custom-data';
import { Permission } from '../../../types/Permissions';
import { GET_GROUPS } from '../../../graphql/queries/users';
import MultiSelectTable from '../../../components/Inputs/MultiSelectTable';
import Switch from '../../../components/Inputs/Switch';
import { YellowBadge } from '../../../components/Badges/Badges';

export const SaveCustomFieldsGroup = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { useMutation, useQuery, useLazyQuery } = useOrganisationAwareApollo();
  const [saveCustomFieldGroup, { loading }] = useMutation(
    SAVE_CUSTOM_FIELD_GROUP
  );

  const { userProfile } = useContext(UserProfileContext);

  const canSaveCustomFields = userProfile?.permissions?.includes(
    Permission.UpdateCustomData
  );

  const isUpdate = !!id;

  const { data, refetch } = useQuery(GET_CUSTOM_FIELDS, {
    variables: { input: { id } },
    skip: !isUpdate,
  });

  useEffect(() => {
    if (isUpdate) {
      refetch({ id });
    }
  }, [id, refetch, isUpdate]);

  const [customFieldGroup] = data?.getCustomFieldGroups?.results || [];

  const [fetchGroups, { data: groupsData }] = useLazyQuery(GET_GROUPS, {
    variables: { input: { limit: 1000, offset: 0 } },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchGroups({ variables: { input: { limit: 1000, offset: 0 } } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const groupResults = groupsData?.getGroups?.results || [];

  const initialValues = customFieldGroup?.name
    ? customFieldGroup
    : {
        name: '', // unique identifier for the group
        custom_fields: [],
      };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Field Group Name is required'),
    custom_fields: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Field Name is required'),
      })
    ),
  });

  const onSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      await saveCustomFieldGroup({
        variables: {
          id: values?.id,
          input: {
            name: values.name,
            groupsAssigned: values.groupsAssigned,
            private: values.private,
            hiddenOnCreate: values.hiddenOnCreate,
            enabled: values.enabled,
            custom_fields: values.custom_fields?.map((field: any) => ({
              ...field,
              name: field.name,
              type: field.type ?? 'Text',
              isNew: undefined,
            })),
          },
        },
      });
      setSubmitting(false);
      navigate('/custom/fields');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ isValid, values, setFieldValue }) => (
        <Form className="space-y-6 mx-8 my-8">
          {isUpdate ? (
            <>
              <h1 className="text-3xl font-extrabold text-gray-900">
                {values?.name}
              </h1>
              <p className="mt-1 text-sm text-gray-500">ID: {id}</p>
            </>
          ) : (
            <h1 className="text-3xl font-extrabold text-gray-900">
              Create Field Group
            </h1>
          )}
          <div>
            <label
              htmlFor="name"
              className="block text-gray-700 font-bold mb-2"
            >
              Field Group Name
            </label>
            <Field
              id="name"
              name="name"
              type="text"
              disabled={!canSaveCustomFields}
              autoComplete="off"
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
            />
            <ErrorMessage
              name="name"
              component="div"
              className="text-red-500 text-sm mt-1"
            />
            <hr className="my-2" />
            <h1 className="text-lg font-extrabold text-gray-900">Fields</h1>
            <FieldArray name="custom_fields">
              {({ remove, push }) => (
                <>
                  <div className="flex flex-col gap-y-2">
                    {values.custom_fields.length > 0 &&
                      values.custom_fields.map((field: any, index: number) => (
                        <>
                          <div
                            className="ml-4 row flex w-full justify-start items-start gap-x-2"
                            key={index}
                          >
                            <div className="flex flex-col gap-y-2 gap-x-2 w-full">
                              <Field
                                name={`custom_fields.${index}.name`}
                                placeholder="Field Name"
                                disabled={!canSaveCustomFields}
                                type="text"
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                              />
                              <Field
                                name={`custom_fields.${index}.description`}
                                placeholder="Field Description"
                                disabled={!canSaveCustomFields}
                                type="textarea"
                                component="textarea"
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                              />
                            </div>

                            <div className="flex gap-x-2 w-full">
                              <Field
                                name={`custom_fields.${index}.type`}
                                as="select"
                                disabled={!canSaveCustomFields}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                              >
                                <option value="text">Text</option>
                                <option value="longText">Long Text</option>
                              </Field>
                            </div>
                            <div className="col">
                              {canSaveCustomFields ? (
                                <Button
                                  type="button"
                                  text="Remove"
                                  style={{ backgroundColor: 'black' }}
                                  onClick={() => remove(index)}
                                />
                              ) : null}
                            </div>
                          </div>
                          <ErrorMessage
                            name={`custom_fields.${index}.name`}
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </>
                      ))}
                  </div>
                  <hr className="my-2" />
                  <Button
                    type="button"
                    text="Add Field"
                    isDisabled={!canSaveCustomFields}
                    onClick={() => push({ id: v4(), name: '', isNew: true })}
                  />
                </>
              )}
            </FieldArray>
            <hr className="my-2" />
            <MultiSelectTable
              question={{
                id: '',
                title: 'Assign Fields to Groups',
                options: groupResults.map((group: any) => ({
                  name: group.name,
                  value: group.id,
                })),
              }}
              value={values?.groupsAssigned ?? []}
              handleChange={(value) => setFieldValue('groupsAssigned', value)}
            />
            <hr className="my-2" />
            <Switch
              text="Private"
              description="If private, these fields cannot be immediately seen or configured in a user's own profile."
              enabled={values?.private}
              handleChange={(value) => setFieldValue('private', value)}
            />
            <div className="my-4 text-yellow-700 bg-yellow-50 px-4 py-2 text-sm">
              Note: Please be aware that a user may still see evidence of the
              fields you are collecting in the app and may have an entitlement
              under GDPR Subject Access Requests to view data your organisation
              is storing about them.
            </div>
            <div className="my-4" />
            <Switch
              text="Hidden on create new user"
              description="Because fields are associated with groups - a user has no groups when they are first created so all fields will be shown by default. If switched on, these fields can only be set after a user has been created."
              enabled={values?.hiddenOnCreate}
              handleChange={(value) => setFieldValue('hiddenOnCreate', value)}
            />

            <div className="my-4" />
            <Switch
              text="Enabled"
              description=""
              enabled={values?.enabled}
              handleChange={(value) => setFieldValue('enabled', value)}
            />
            <div className="my-2" />
          </div>

          <div className="flex justify-end">
            <FormSubmit
              disabled={!isValid || !canSaveCustomFields}
              isLoading={loading}
              submitText="Save"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
