import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Table } from '../../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../../context/PaginationContext';

import moment from 'moment';
import { Button } from '../../../../components/Buttons/Button';

import * as Yup from 'yup';
import {
  GreenBadge,
  RedBadge,
  YellowBadge,
} from '../../../../components/Badges/Badges';
import FormCard from '../../../../components/FormCard/FormCard';
import { GENERATE_TIMESHEET_EXPORT } from '../../../../graphql/mutations/exports';
import { GET_EXPORT_CONFIGURATIONS_SUMMARY } from '../../../../graphql/queries/export-configurations';
import {
  GET_EXPORTS,
  GET_EXPORT_URL,
} from '../../../../graphql/queries/exports';
import { useOrganisationAwareApollo } from '../../../../hooks/useOrganisationAwareApollo';
import { FormConfig } from '../../../../types/Form';
import { exponentialBackoff } from '../../../../utils/utils';
import DropDown from '../../../../components/Inputs/Dropdown';
import { ProjectSelection } from '../../timesheet/project/search';
import { SEARCH_PROJECTS } from '../../../../graphql/queries/projects';
import Switch from '../../../../components/Inputs/Switch';

interface CreateExportProps {
  children?: React.ReactElement;
  showNewItemButton?: boolean;
  filterIds?: string[];
  onClickRow?: (id: string, results: any[]) => void;
}

const headerMapping = {
  location: 'File Name',
  email: 'Email',
  status: 'Status',
  startDate: 'Start Date',
  endDate: 'End Date',
  updatedAt: 'Updated',
  createdAt: 'Created',
  configure: 'Options',
} as Record<string, any>;

const ExportsTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    columnsToFilter={['firstName', 'lastName', 'id', 'createdAt']}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
      createdAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
      startDate: (text: string) =>
        moment.unix(parseInt(text) / 1000).format('LL'),
      endDate: (text: string) =>
        moment.unix(parseInt(text) / 1000).format('LL'),
    }}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
  />
);

export const CreateExportPage = ({
  children,
  showNewItemButton,
  filterIds,
}: CreateExportProps) => {
  const { id } = useParams();

  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();
  const [newItemTime, setNewItemTime] = useState<any>();
  const [generateTimesheetExport] = useMutation(GENERATE_TIMESHEET_EXPORT);
  const [exportResults, setExportResults] = useState({ count: 0, results: [] });
  const [selectedProject, setSelectedProject] = useState<any>(undefined);
  const { limit, offset, setOffset } = usePagination();
  const [fetchExportConfiguration, { data: exportConfigData }] = useLazyQuery(
    GET_EXPORT_CONFIGURATIONS_SUMMARY,
    {
      variables: { input: { limit, offset, id } },
      fetchPolicy: 'network-only',
    }
  );

  const [fetch] = useLazyQuery(GET_EXPORTS, {
    variables: { input: { limit, offset } },
    fetchPolicy: 'network-only',
  });

  const [fetchExportURL, { data: exportURL }] = useLazyQuery(GET_EXPORT_URL, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchExportConfiguration({ variables: { input: { limit, offset, id } } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    fetchAndSetResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset]);

  const [exportConfig] =
    exportConfigData?.getExportConfigurations?.results || [];
  const ExportResults = exportResults;

  const fetchAndSetResults = useCallback(async () => {
    const { data } = await fetch({
      variables: { exportConfigurationId: id, input: { limit, offset } },
    });
    setExportResults(data?.getExports);
  }, [exportConfig?.id, limit, offset]);

  const totalPages = Math.ceil(ExportResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const downloadPath = (path: string, filename: string) => {
    // Create a new link
    const anchor = document.createElement('a');
    anchor.href = path;
    anchor.download = filename;
    anchor.style.display = 'none';

    // Append to the DOM
    document.body.appendChild(anchor);

    // Trigger `click` event
    anchor.click();

    // Remove element from DOM
    document.body.removeChild(anchor);

    // Revoke the Blob URL to free up resources
    URL.revokeObjectURL(path);
  };

  useEffect(() => {
    if (exportURL?.getExportURL) {
      const url = exportURL?.getExportURL?.url;
      downloadPath(url, `export.csv`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(exportURL)]);

  const [showCreateNewExport, setShowCreateNewExport] = useState(false);

  const [format, setFormat] = useState('WEEKLY');
  const [_isSearching, setIsSearching] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  const [fetchProjects, { data: projectsData }] = useLazyQuery(
    SEARCH_PROJECTS,
    {
      variables: { searchTerm: debouncedSearchTerm },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 600); // 300ms debounce time
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    performSearch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const projectSearchResults = projectsData?.searchProjects?.results || [];

  const performSearch = async () => {
    setIsSearching(true);
    try {
      await fetchProjects({ variables: { searchTerm: debouncedSearchTerm } });
    } catch (err) {
      // do nothing
    } finally {
      setIsSearching(false);
    }
  };

  const [filterByProject, setFilterByProject] = useState(false);

  useEffect(() => {
    if (!filterByProject) {
      setSelectedProject(undefined);
    }
  }, [filterByProject]);

  const createNewExportFormConfig = {
    formSections: [
      {
        title: 'Create New Export',
        components: [
          <DropDown
            question={{
              id: 'format',
              title: 'Format',
              options: [
                { id: 'WEEKLY', name: 'Weekly', value: 'WEEKLY' },
                {
                  id: 'MONTHLY_CONSOLIDATED',
                  name: 'Monthly (Consolidated)',
                  value: 'MONTHLY_CONSOLIDATED',
                },
                {
                  id: 'MONTHLY_DETAILED',
                  name: 'Monthly (Detailed)',
                  value: 'MONTHLY_DETAILED',
                },
              ],
            }}
            value={format}
            handleChange={setFormat}
          />,
          <Switch
            enabled={filterByProject}
            handleChange={setFilterByProject}
            text={'Filter by project'}
          />,
          filterByProject ? (
            <ProjectSelection
              placeholder="Filter by project"
              setSearchTerm={setSearchTerm}
              selectedProject={selectedProject}
              projectSearchResults={projectSearchResults}
              setSelectedProject={setSelectedProject}
            />
          ) : null,
        ],
        fields: [
          ...(format === 'WEEKLY'
            ? [
                {
                  id: 'startDate',
                  type: 'datepicker',
                  title: 'Start Date',
                },
                {
                  id: 'endDate',
                  type: 'datepicker',
                  title: 'End Date',
                },
              ]
            : [
                {
                  id: 'monthSelection',
                  type: 'monthpicker',
                  title: 'Month Selection',
                },
              ]),
          {
            id: 'includeUnapproved',
            type: 'switch',
            title: 'Include Unapproved Entries',
          },
        ],
      },
    ],
  } as FormConfig;

  const initialValues = { startDate: null, endDate: null };

  const validationSchema = Yup.object().shape({
    startDate: Yup.string()
      .nullable()
      .test(
        'start-before-end',
        'Start Date must be before or the same as End Date',
        function (value) {
          const { endDate } = this.parent;
          if (!value || !endDate) return true;
          const startDate = moment(value);
          const end = moment(endDate);
          return startDate.isSameOrBefore(end);
        }
      ),
    endDate: Yup.string()
      .nullable()
      .test(
        'end-after-start',
        'End Date must be after or the same as Start Date',
        function (value) {
          const { startDate } = this.parent;
          if (!value || !startDate) return true;
          const endDate = moment(value);
          const start = moment(startDate);
          return endDate.isSameOrAfter(start);
        }
      ),
  });

  const onSubmitTimesheetExport = async (
    values: any,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setOffset(0);
    let timesheetExportResult;
    try {
      timesheetExportResult = await generateTimesheetExport({
        variables: {
          input: {
            ...values,
            format,
            // Month picker uses last day of previous month
            monthSelection: moment(values?.monthSelection)
              .add(1, 'day')
              .toISOString(),
            projectId: selectedProject?.id,
            exportConfigurationId: id,
          },
        },
      });
      setNewItemTime(Date.now());
      await fetchAndSetResults();
      if (setSubmitting) {
        setSubmitting(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowCreateNewExport(false);
    }

    // Check for 4 minutes with a backoff
    exponentialBackoff(() => fetchAndSetResults(), 240000);
  };

  return (
    <div>
      <>
        {showNewItemButton ? (
          <div className="w-full flex justify-between items-center py-2">
            <h1 className="text-xl font-bold py-4">
              Exports for {exportConfig?.name}
            </h1>
            <Button
              text="New Export "
              onClick={() => setShowCreateNewExport(true)}
            />
          </div>
        ) : null}
        {showCreateNewExport ? (
          <div className="mb-6">
            <FormCard
              initialValues={initialValues}
              onSubmit={onSubmitTimesheetExport}
              validationSchema={validationSchema}
              submitText="Create"
              onCancel={() => setShowCreateNewExport(false)}
              config={createNewExportFormConfig}
            />
          </div>
        ) : null}
        <ExportsTable
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          key={`${newItemTime}`}
          totalResults={ExportResults?.count || 0}
          data={
            ExportResults?.results.length > 0
              ? ExportResults?.results
                  ?.filter((result: any) => !filterIds?.includes(result.id))
                  .map((result: any) => ({
                    ...result,
                    status:
                      result.status === 'COMPLETE' ? (
                        <GreenBadge text={result.status} />
                      ) : result.status === 'FAILED' ? (
                        <RedBadge text={result.status} />
                      ) : (
                        <YellowBadge text={result.status} />
                      ),
                    configure: (
                      <div className="flex gap-x-2">
                        <Button
                          text="Download"
                          onClick={async () => {
                            await fetchExportURL({
                              variables: {
                                exportId: result?.id,
                              },
                            });
                          }}
                        />
                      </div>
                    ),
                  }))
              : [{}]
          }
          onPageChange={handlePageChange}
        />
      </>
      {children}
    </div>
  );
};

export const CreateExport = ({
  onClickRow,
  showNewItemButton = true,
  filterIds = [],
}: CreateExportProps) => (
  <PaginationProvider>
    <CreateExportPage
      showNewItemButton={showNewItemButton}
      onClickRow={onClickRow}
      filterIds={filterIds}
    />
  </PaginationProvider>
);
