import moment from 'moment';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Table } from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';

import { GET_MY_CONTRACT_SUBMISSIONS } from '../../../graphql/queries/contracts';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { GreenBadge, YellowBadge } from '../../../components/Badges/Badges';
import { URLDropdown } from '../../../components/Dropdown/URLDropdown';

interface SentContractsListForProfileProps {}

const headerMapping = {
  contractName: 'Contract Name',
  status: 'Status',
  projectName: 'Project Name',
  role: 'Role Name',
  signerEmail: 'Signer Email',
  options: 'Options',
  createdAt: 'Created At',
  updatedAt: 'Updated At',
} as Record<string, any>;

const SentContractsTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    columnsToFilter={['firstName', 'lastName', 'id', 'signerSlug', 'updatedAt']}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
      createdAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
  />
);

export const SentContractsListForProfilePage =
  ({}: SentContractsListForProfileProps) => {
    const navigate = useNavigate();
    const { useLazyQuery } = useOrganisationAwareApollo();

    const { limit, offset, setOffset } = usePagination();

    const handlePageChange = (pageNumber: number) => {
      setOffset((pageNumber - 1) * limit);
    };

    const [fetchContractSubmissions, { data: contractSubmissionsData }] =
      useLazyQuery(GET_MY_CONTRACT_SUBMISSIONS, {
        variables: {},
        fetchPolicy: 'network-only',
      });

    useEffect(() => {
      fetchContractSubmissions({ variables: { input: { limit, offset } } });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, offset]);

    const contractSubmissionsResults =
      contractSubmissionsData?.getMyContractSubmissions?.results || [];

    const totalPages = Math.ceil(
      contractSubmissionsData?.getMyContractSubmissions?.count / limit
    );

    return (
      <div className="bg-gray-50">
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="flex flex-col md:items-center md:flex-row w-full md:justify-between px-4 py-5 sm:px-6 flex-wrap">
            <div className="flex flex-col">
              <h1 className="text-3xl font-extrabold text-gray-900">
                Contracts
              </h1>
            </div>
          </div>
          <div className="border-t border-gray-200 px-4">
            <SentContractsTable
              currentPage={Math.floor(offset / limit) + 1}
              totalPages={totalPages}
              itemsPerPage={limit}
              totalResults={
                contractSubmissionsData?.getMyContractSubmissions?.count || 0
              }
              onClickRow={(id: any) => {
                const result = contractSubmissionsResults?.find(
                  (submission: any) => submission.id === id
                );
                if (!(result.status === 'COMPLETED')) {
                  navigate(`/profile/contracts/${result.signerSlug}`);
                }
              }}
              data={
                contractSubmissionsResults.length > 0
                  ? contractSubmissionsResults.map((result: any) => ({
                      id: result?.id,
                      projectName:
                        result?.assignmentRole?.assignment?.project?.name,
                      contractName: result?.contract?.name,
                      role: result?.assignmentRole?.role?.name,
                      signerSlug: result?.signerSlug,
                      status:
                        result.status === 'COMPLETED' ? (
                          <GreenBadge text="Completed" />
                        ) : (
                          <YellowBadge text={'Submitted'} />
                        ),
                      createdAt: result?.createdAt,
                      options:
                        result.status === 'COMPLETED' ? (
                          <URLDropdown
                            urls={result?.completedDocumentURLs?.map(
                              (url: any) => ({
                                url: url.url,
                                name: `Completed Document (${url.name})`,
                              })
                            )}
                          />
                        ) : null,
                      contract: undefined,
                      assignmentRole: undefined,
                    }))
                  : [{}]
              }
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    );
  };

export const SentContractsListForProfile =
  ({}: SentContractsListForProfileProps) => (
    <PaginationProvider>
      <SentContractsListForProfilePage />
    </PaginationProvider>
  );
