import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import { FieldArray, Form, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { RuleFieldSet } from './RuleFieldSet';

export const ColumnRuleForm = ({}: any) => {
  const [hasCompletedFirstLoad, setHasCompletedFirstLoad] = useState(false);

  const [collapsedColumns, setCollapsedColumns] = useState<{
    [key: number]: boolean;
  }>({});

  const { values, handleChange, handleBlur } = useFormikContext();

  const toggleCollapse = (index: number) => {
    setCollapsedColumns((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const formValues = values as Record<string, any>;

  React.useEffect(() => {
    if (!hasCompletedFirstLoad && formValues?.columns?.length > 0) {
      const initialCollapsedState = formValues?.columns?.reduce(
        (acc: any, _: any, index: number) => {
          acc[index] = true;
          return acc;
        },
        {}
      );

      setCollapsedColumns(initialCollapsedState);
      setHasCompletedFirstLoad(true);
    }
  }, [formValues?.columns]);

  return (
    <Form className="space-y-6">
      <FieldArray
        name="columns"
        render={(arrayHelpers) => (
          <div className="flex flex-col gap-y-2">
            {formValues.columns.map((column: any, index: any) => (
              <div
                key={index}
                className="border border-gray-300 bg-gray-50 rounded-lg shadow-sm"
              >
                <div
                  role="button"
                  className="p-4 px-8 flex justify-between items-center"
                >
                  {!collapsedColumns[index] ? (
                    <div>
                      <label
                        className="block text-gray-700 text-lg mb-2"
                        htmlFor={`columns[${index}].name`}
                      >
                        Column Name
                      </label>
                      <input
                        id={`columns[${index}].name`}
                        className="shadow appearance-none border rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                        name={`columns[${index}].name`}
                        placeholder="Enter Column Name"
                        value={column.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  ) : (
                    <h3 className="text-lg">
                      {formValues.columns[index].name}
                    </h3>
                  )}
                  <div
                    onClick={() => toggleCollapse(index)}
                    className="ml-4 py-1 px-2 bg-gray-50 text-gray-700 rounded"
                  >
                    {collapsedColumns[index] ? (
                      <EyeIcon className="h-5 w-5" aria-hidden="true" />
                    ) : (
                      <EyeSlashIcon className="h-5 w-5" aria-hidden="true" />
                    )}
                  </div>
                </div>
                {!collapsedColumns[index] && (
                  <div className="p-2 px-8 border-t">
                    <RuleFieldSet column={column} columnIndex={index} />
                    <button
                      type="button"
                      onClick={() => arrayHelpers.remove(index)}
                      className="mt-3 py-2 px-4 bg-red-500 text-white rounded-md hover:bg-red-600"
                    >
                      Remove Column
                    </button>
                  </div>
                )}
              </div>
            ))}
            <button
              type="button"
              onClick={() => arrayHelpers.push({ name: '', rules: [] })}
              className="my-4 py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              Add Column
            </button>
          </div>
        )}
      />
    </Form>
  );
};
