interface ValidationMessageParams {
  message: string;
}

const ValidationMessage = ({ message }: ValidationMessageParams) => {
  return (
    <div className="rounded-md bg-red-50 p-3 text-sm text-red-700">
      <p className="ml-2">{message}</p>
    </div>
  );
};

export default ValidationMessage;
