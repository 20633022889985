import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Buttons/Button';

import { GET_MY_BANKING_DETAILS } from '../../../graphql/queries/my-profile';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { ActiveBadge, DisabledBadge } from '../../../components/Badges/Badges';
import moment from 'moment';

export const BankingDetailPage = () => {
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [fetch, { data, loading, error }] = useLazyQuery(
    GET_MY_BANKING_DETAILS,
    {
      variables: {},
      fetchPolicy: 'network-only',
    }
  );
  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});
  const bankAccounts = data?.getMyBankingDetails?.results || [];

  useEffect(() => {
    fetch({ variables: {} });
  }, []);

  const toggleExpand = (index: number) => {
    setExpanded((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <div className="bg-gray-50">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="flex flex-col md:items-center md:flex-row w-full md:justify-between px-4 py-5 sm:px-6 flex-wrap">
          <div className="flex flex-row w-full justify-between">
            <h1 className="text-3xl font-extrabold text-gray-900">
              Bank Accounts
            </h1>
            <div>
              <Button
                onClick={() => navigate(`/profile/accounts/new`)}
                type="submit"
                text="Add Bank Account"
              />
            </div>
          </div>
        </div>
        {loading && (
          <div className="h-64 flex items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
          </div>
        )}
        {error && (
          <p className="px-4 py-5 sm:p-6 text-red-500">
            Error loading data. Please try again.
          </p>
        )}

        {bankAccounts.length > 0 ? (
          <div className="border-t border-gray-200">
            {bankAccounts.map((bankAccount: any, index: number) => (
              <div key={index} className="border-t border-gray-200">
                <div
                  className="px-4 py-4 cursor-pointer flex gap-x-2 items-center w-full justify-between"
                  onClick={() => toggleExpand(index)}
                >
                  <div>
                    <h2 className="text-lg font-medium text-gray-900">
                      {bankAccount.bankName}{' '}
                      {bankAccount?.enabled ? (
                        <ActiveBadge />
                      ) : (
                        <DisabledBadge />
                      )}
                    </h2>
                    <p className="mt-1 text-sm text-gray-500">
                      {bankAccount.currency || 'N/A'}
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      {bankAccount.accountNumber} / {bankAccount.sortCode}
                    </p>
                    <p className="mt-1 text-xs text-gray-500">
                      Created:{' '}
                      {moment.unix(bankAccount?.createdAt / 1000).fromNow()}
                    </p>
                    <p className="mt-1 text-xs text-gray-500">
                      Updated:{' '}
                      {moment.unix(bankAccount?.updatedAt / 1000).fromNow()}
                    </p>
                  </div>
                  <div>
                    <Button
                      onClick={() =>
                        navigate(`/profile/accounts/${bankAccount.id}/edit`)
                      }
                      type="submit"
                      text="Edit"
                    />
                  </div>
                </div>
                {expanded[index] && (
                  <div className="border-t border-gray-200">
                    <div className="px-4 py-2">
                      <h3 className="text-md font-medium text-gray-600">
                        Bank Name
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        {bankAccount.bankName || 'N/A'}
                      </p>
                    </div>
                    <div className="px-4 py-2">
                      <h3 className="text-md font-medium text-gray-600">
                        Name on Account
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        {bankAccount.nameOnAccount || 'N/A'}
                      </p>
                    </div>
                    <div className="px-4 py-2">
                      <h3 className="text-md font-medium text-gray-600">
                        Currency
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        {bankAccount.currency || 'N/A'}
                      </p>
                    </div>
                    <div className="px-4 py-2">
                      <h3 className="text-md font-medium text-gray-600">
                        Account Number
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        {bankAccount.accountNumber || 'N/A'}
                      </p>
                    </div>
                    <div className="px-4 py-2">
                      <h3 className="text-md font-medium text-gray-600">
                        Sort Code
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        {bankAccount.sortCode || 'N/A'}
                      </p>
                    </div>
                    <div className="px-4 py-2">
                      <h3 className="text-md font-medium text-gray-600">
                        IBAN
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        {bankAccount.iban || 'N/A'}
                      </p>
                    </div>
                    <div className="px-4 py-2">
                      <h3 className="text-md font-medium text-gray-600">BIC</h3>
                      <p className="mt-1 text-sm text-gray-500">
                        {bankAccount.bic || 'N/A'}
                      </p>
                    </div>
                    <div className="px-4 py-2">
                      <h3 className="text-md font-medium text-gray-600">
                        Type
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        {bankAccount.isBusinessAccount
                          ? 'Business'
                          : 'Personal'}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <p className="px-4 py-5 sm:p-6 ">No banking details available</p>
        )}
      </div>
    </div>
  );
};

export const BankingDetail = () => <BankingDetailPage />;
