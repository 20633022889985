import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
} from '@heroicons/react/24/solid';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

interface FormSectionProps {
  title?: string;
  description?: string;
  classNames?: string;
  children: React.ReactNode;
  handleSubmit?: (
    values: {},
    setSubmitting: (isSubmitting: boolean) => void
  ) => Promise<void>;
}

const FormSection = ({
  children,
  title,
  description,
  classNames,
}: FormSectionProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <div
      className={twMerge(
        'bg-black shadow sm:rounded-lg rounded justify-center items-start flex flex-col',
        classNames
      )}
    >
      <div className="flex items-center justify-between w-full px-2">
        {title || description ? (
          <div className="px-6 py-2 flex justify-center flex-col">
            {title ? (
              <h3 className="text-xl font-medium leading-6 text-white">
                {title}
              </h3>
            ) : null}
          </div>
        ) : (
          <div className="my-6" />
        )}
        {isCollapsed ? (
          <ArrowsPointingOutIcon
            onClick={() => setIsCollapsed(!isCollapsed)}
            className="w-5 h-5 text-white mr-6"
          />
        ) : (
          <ArrowsPointingInIcon
            onClick={() => setIsCollapsed(!isCollapsed)}
            className="w-5 h-5 text-white mr-6"
          />
        )}
      </div>
      {!isCollapsed ? (
        <div className="flex flex-col gap-y-4 px-6 py-4 w-full bg-white rounded-b-lg">
          {description ? (
            <p className="text-sm text-gray-500">{description}</p>
          ) : null}
          {children}
        </div>
      ) : null}
    </div>
  );
};

export default FormSection;
