import { DocusealForm } from '@docuseal/react';
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthenticatedUserContext } from '../../../context/AuthenticatedUserContext';
import { Button } from '../../../components/Buttons/Button';

export const SignContractPage = ({}) => {
  const { id } = useParams();
  const { user } = useContext(AuthenticatedUserContext);
  const navigate = useNavigate();

  return (
    <div className="bg-gray-50 pb-10">
      <div className="py-2 border-b">
        <Button
          style={{
            backgroundColor: 'transparent',
            color: 'gray',
            boxShadow: 'none',
            borderRadius: 0,
          }}
          onClick={() => navigate(-1)}
          type="submit"
          text={`< Back`}
        />
      </div>
      <DocusealForm
        src={`https://docuseal.co/d/${id}`}
        email={user.attributes.email}
        onComplete={(data) => console.log(data)}
      />
    </div>
  );
};
