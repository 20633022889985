import { twMerge } from 'tailwind-merge';

interface CardProps {
  classNames?: string;
  children?: React.ReactElement[] | React.ReactElement;
}

export const GridLayout = ({ children, classNames }: CardProps) => {
  return (
    <div
      className={twMerge(
        'grid items-center justify-center grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3',
        classNames
      )}
    >
      {children}
    </div>
  );
};
