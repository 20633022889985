import { gql } from '@apollo/client';

export const GET_EMPLOYEES_SUMMARY = gql`
  query getEmployees(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $internalIdFilter: String
  ) {
    getEmployees(
      input: $input
      activeOrganisation: $activeOrganisation
      internalIdFilter: $internalIdFilter
    ) {
      count
      results {
        id
        firstName
        lastName
        defaultRole {
          id
          name
        }
        internalId
        email
        status
        enabled
        updatedAt
      }
    }
  }
`;

export const GET_EMPLOYEES = gql`
  query getEmployees($input: GenericFindOptions, $activeOrganisation: String!) {
    getEmployees(input: $input, activeOrganisation: $activeOrganisation) {
      count
      results {
        id
        firstName
        lastName
        nationality
        countryOfResidence
        email
        jobTitle
        defaultRole {
          id
          name
        }
        personalProfile
        projectExperience
        type
        defaultRate {
          id
          currency
          key
          name
          rate
        }
        customData {
          key
          value
        }
        internalId
        status
        createdAt
        updatedAt
        missingOrInvalidCertifications {
          id
          name
          notes
        }
      }
    }
  }
`;
