import { XMarkIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import useLabourResources from '../../hooks/api/useLabourResources';
import { Button } from '../Buttons/Button';
import ComboBox from '../Inputs/ComboBox';

export const LabourSelection = ({
  setSelectedLabour,
  selectedLabour,
  labourType,
  placeholder = 'Search by labour name',
}: any) => {
  const [searchTerm, setSearchTerm] = useState('');

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [labours] = useLabourResources({
    isASearch: true,
    labourType,
    searchTerm: debouncedSearchTerm,
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 600);
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  return (
    <div className="flex w-full md:w-1/3 gap-x-4">
      <ComboBox
        classNames="w-full"
        testId="labour-selection"
        placeholder={placeholder}
        question={{
          id: 'Labour Search',
          options: labours?.map((labour: any) => ({
            id: labour?.id,
            name: `${labour?.firstName} ${labour?.lastName}`,
            detail: labour?.email ?? 'No Email Provided',
          })),
          onChange: (value) => setSearchTerm(value),
        }}
        handleChange={(id) => {
          setSelectedLabour(labours?.find((labour: any) => labour?.id === id));
        }}
        value={
          selectedLabour?.firstName
            ? `${selectedLabour?.firstName} ${selectedLabour?.lastName}`
            : ''
        }
        validation={undefined}
      />
      <Button
        className="shadow-none mt-1 -ml-3 px-2 bg-transparent text-black hover:bg-transparent"
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          setSelectedLabour(null);
        }}
        aria-label="Clear Labour"
        title="Clear Labour"
      >
        <XMarkIcon className="h-5 w-5" />
      </Button>
    </div>
  );
};
