export interface FormConfig {
  title: string;
  description: string;
  formSections?: FormSection[];
}

export interface FormSection {
  title?: string;
  description?: string;
  components?: React.ReactElement[];
  fields: {
    id: string;
    type: FieldType;
    title?: string;
    placeholder?: string;
    description?: string;
    required?: boolean;
    disabled?: boolean;
    initialValue?: unknown;
    valueTransformer?: any;
    inputTransformer?: any;
    dependsOnField?: string;
    dependsOnFunction?: (
      inputValue: unknown,
      value: unknown,
      setFieldValue: (key: string, value: any) => {}
    ) => boolean;
    refetch?: () => Promise<void>;
    options?: {
      name?: string;
      id?: string;
      detail?: string;
      assigned?: boolean;
      stage?: string;
      disabled?: boolean;
    }[];
  }[];
}

export enum FieldType {
  input = 'input',
  colour = 'colour',
  textarea = 'textarea',
  array = 'array',
  email = 'email',
  numeric = 'numeric',
  dropdown = 'dropdown',
  multiselect = 'multiselect',
  multiselecttable = 'multiselecttable',
  warningPlaceholder = 'warningPlaceholder',
  multiselectcombobox = 'multiselectcombobox',
  radiogroup = 'radiogroup',
  file = 'file',
  rangeslider = 'rangeslider',
  richtextinput = 'richtextinput',
  combobox = 'combobox',
  inputlist = 'inputlist',
  objectlist = 'objectlist',
  switch = 'switch',
  checkboxlist = 'checkboxlist',
  checkboxgroup = 'checkbox-group',
  inputtable = 'inputtable',
  daterangepicker = 'daterangepicker',
  datepicker = 'datepicker',
  monthpicker = 'monthpicker',
  upload = 'upload',
  chipsinput = 'chipsinput',
  addbutton = 'addbutton',
  currency = 'currency',
}
