import { gql } from '@apollo/client';

export const GET_EXPORTS_SUMMARY = gql`
  query getExports(
    $input: GenericFindOptions
    $exportConfigurationId: ID
    $activeOrganisation: String!
  ) {
    getExports(
      input: $input
      exportConfigurationId: $exportConfigurationId
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        location
        status
      }
    }
  }
`;

export const GET_EXPORTS = gql`
  query getExports(
    $input: GenericFindOptions
    $exportConfigurationId: ID
    $activeOrganisation: String!
  ) {
    getExports(
      input: $input
      exportConfigurationId: $exportConfigurationId
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        location
        startDate
        endDate
        status
        updatedAt
        createdAt
      }
    }
  }
`;

export const GET_CV_EXPORTS_FOR_LABOUR_RESOURCE = gql`
  query getCvExportsForLabourResource(
    $input: GenericFindOptions
    $labourResourceId: ID!
    $assignmentRoleId: ID
    $activeOrganisation: String!
  ) {
    getCvExportsForLabourResource(
      input: $input
      labourResourceId: $labourResourceId
      assignmentRoleId: $assignmentRoleId
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        labourResourceId
        assignmentRoleId
        assignmentRole {
          id
          role {
            id
            name
          }
        }
        location
        status
        updatedAt
        createdAt
      }
    }
  }
`;

export const GET_EXPORT_URL = gql`
  query getExportURL($exportId: String!, $activeOrganisation: String!) {
    getExportURL(exportId: $exportId, activeOrganisation: $activeOrganisation) {
      url
    }
  }
`;

export const GET_CV_EXPORT_URL = gql`
  query getCvExportURL($exportId: String!, $activeOrganisation: String!) {
    getCvExportURL(
      exportId: $exportId
      activeOrganisation: $activeOrganisation
    ) {
      url
    }
  }
`;
