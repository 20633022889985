import { useEffect, useState } from 'react';
import { XCircleIcon } from '@heroicons/react/24/outline';

export const ActiveBadge = ({ text = 'Active' }) => (
  <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-sm font-medium text-green-800">
    {text}
  </span>
);

export const DisabledBadge = ({ text = 'Disabled' }) => (
  <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-sm font-medium text-gray-800">
    {text}
  </span>
);

export const ApprovedBadge = () => (
  <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-sm font-medium text-green-800">
    Approved
  </span>
);

export const UnapprovedBadge = () => (
  <span className="inline-flex items-center rounded-full bg-yellow-300 px-3 py-0.5 text-sm font-medium text-gray-800">
    Unapproved
  </span>
);

export const NeedsSageId = () => (
  <span className="inline-flex items-center rounded-full bg-yellow-300 px-3 py-0.5 text-sm font-medium text-gray-800">
    Needs Sage Id
  </span>
);

export const GreenBadge = ({ text = 'Active' }) => (
  <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-sm font-medium text-green-800">
    {text}
  </span>
);

export const GeneratingBadge = () => {
  const [badgeText, setBadgeText] = useState({ main: 'Generating', sub: '' });
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setBadgeText({ main: 'Please Wait', sub: 'This can take a while' });
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <span className="flex flex-row rounded-full bg-yellow-100 px-3 py-0.5 text-sm font-medium text-yellow-800">
      <div className="inline-flex flex-col items-left">
        <span>{badgeText.main}</span>
        {badgeText.sub && <span className="text-xs">{badgeText.sub}</span>}
      </div>
      <div className="ml-2 flex items-center justify-center">
        <div className="w-5 h-5 border-b-2 border-dotted text-white border-yellow-800 rounded-full animate-spin"></div>
      </div>
    </span>
  );
};

export const YellowBadge = ({ text = 'Disabled' }) => (
  <span className="inline-flex items-center rounded-full bg-yellow-100 px-3 py-0.5 text-sm font-medium text-yellow-800">
    {text}
  </span>
);

export const OrangeBadge = ({ text = 'Disabled' }) => (
  <span className="inline-flex items-center rounded-full bg-orange-100 px-3 py-0.5 text-sm font-medium text-orange-800">
    {text}
  </span>
);

export const RedBadge = ({ text = 'Disabled' }) => (
  <span className="inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-sm font-medium text-red-800">
    {text}
  </span>
);

export const BlueBadge = ({
  text = 'Disabled',
  hasDeleteButton = false,
  onDelete = () => {},
}) => (
  <span className="inline-flex items-center rounded-full bg-blue-100 px-3 py-0.5 text-sm font-medium text-blue-800">
    {text}
    {hasDeleteButton && (
      <button onClick={onDelete} className="ml-2 text-red-500 h-7 w-7">
        <XCircleIcon className="text-blue-800" />
      </button>
    )}
  </span>
);

export const LightBlueBadge = ({ text = 'Disabled' }) => (
  <span className="inline-flex items-center rounded-full bg-blue-50 px-3 py-0.5 text-sm font-medium text-blue-800">
    {text}
  </span>
);

export const GrayBadge = ({ text = 'Disabled' }) => (
  <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-sm font-medium text-gray-800">
    {text}
  </span>
);

export const PulseOnline = () => (
  <span className="relative flex h-3 w-3">
    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
    <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
  </span>
);

export const Offline = () => (
  <span className="relative flex h-3 w-3">
    <span className="relative inline-flex rounded-full h-3 w-3 bg-gray-300"></span>
  </span>
);
