import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

interface ValidationMessageParams {
  message: string;
}

export default function WarningMessage({ message }: ValidationMessageParams) {
  return (
    <div className="flex gap-x-4 rounded-md bg-yellow-50 px-5 py-3 text-sm text-yellow-800">
      <ExclamationTriangleIcon
        className="h-5 w-5 text-yellow-800"
        aria-hidden="true"
      />
      <p>{message}</p>
    </div>
  );
}
