import { gql } from '@apollo/client';

export const SAVE_EMPLOYEE = gql`
  mutation saveEmployee(
    $id: ID
    $input: SaveEmployeeInput!
    $activeOrganisation: String!
  ) {
    saveEmployee(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;
