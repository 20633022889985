import { useContext, useEffect, useState } from 'react';
import { SEARCH_ASSESSMENT_TYPES } from '../../graphql/queries/assessments';
import { AssessmentSelection } from '../../pages/Projects/assignments/roles/assessments/AssessmentSelection';
import { useOrganisationAwareApollo } from '../../hooks/useOrganisationAwareApollo';
import { Button } from '../Buttons/Button';
import { Permission } from '../../types/Permissions';
import { UserProfileContext } from '../../context/UserProfileContext';

export function SendAssessmentInput({
  sendAssessment,
  isSending,
}: {
  sendAssessment: (assessmentTypeId: string) => void;
  isSending: boolean;
}) {
  const { userProfile } = useContext(UserProfileContext);
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAssessment, setSelectedAssessment] = useState<any>(undefined);

  const [fetchAssessments, { data }] = useLazyQuery(SEARCH_ASSESSMENT_TYPES, {
    variables: { searchTerm: debouncedSearchTerm },
    fetchPolicy: 'network-only',
  });

  const results = data?.searchAssessmentTypes?.results || [];

  const performSearch = async () => {
    try {
      await fetchAssessments({
        variables: {
          searchTerm: debouncedSearchTerm,
          input: { limit: 10, offset: 0 },
        },
      });
    } catch (err) {
      // do nothing
    } finally {
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 600); // 300ms debounce time
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    performSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return userProfile?.permissions?.includes(Permission.SendAssessments) ? (
    <div className="flex flex-row items-center w-full gap-x-2">
      <AssessmentSelection
        assessmentSearchResults={results}
        setSearchTerm={setSearchTerm}
        setSelectedAssessment={setSelectedAssessment}
        selectedAssessment={selectedAssessment}
      />
      <Button
        style={{ marginTop: '3px' }}
        text="Send Assessment"
        isLoading={isSending}
        isDisabled={!selectedAssessment}
        onClick={() => sendAssessment(selectedAssessment.id)}
      />
    </div>
  ) : null;
}
