import * as React from 'react';

interface NetworkContextProps {
  isOffline: boolean;
  setIsOffline: (isOffline: boolean) => void;
}

export const NetworkContext = React.createContext<NetworkContextProps>({
  isOffline: false,
  setIsOffline: () => {},
});

export const NetworkProvider: React.FunctionComponent<
  React.PropsWithChildren
> = (props) => {
  const [isOffline, setIsOffline] = React.useState(
    JSON.parse(sessionStorage.getItem('isOffline') || 'null')
  );

  React.useEffect(() => {
    sessionStorage.setItem('isOffline', JSON.stringify(isOffline));
  }, [isOffline]);

  return (
    <NetworkContext.Provider value={{ isOffline, setIsOffline }}>
      {props.children}
    </NetworkContext.Provider>
  );
};
