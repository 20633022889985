import { useNavigate } from 'react-router-dom';
import { usePagination } from '../../../context/PaginationContext';
import { useCallback } from 'react';
import { Table } from '../../../components/Tables/tables/Table';
import useCompaniesWithMissingOrInvalidCertifications, {
  CompanyCertificationFilter,
} from '../../../hooks/api/companies/useCompaniesWithMissingOrInvalidCertifications';

const headerMapping = {
  companyName: 'Company Name',
  owner: 'Company Owner',
  companyContact: 'Company Contact',
  companyEmail: 'Email',
  companyRegNumber: 'Reg Number',
} as Record<string, any>;

export function CompanyCertificationsTable({
  projectId,
  filter = CompanyCertificationFilter.MISSING,
}: {
  projectId: string;
  filter?: CompanyCertificationFilter;
}) {
  const navigate = useNavigate();
  const { limit, offset, setOffset } = usePagination();

  const [companies, { count }] = useCompaniesWithMissingOrInvalidCertifications(
    {
      projectId,
      filter,
      limit,
      offset,
    }
  );

  const totalPages = Math.ceil(count / limit);

  const onPageChange = useCallback(
    (pageNumber: number) => {
      setOffset((pageNumber - 1) * limit);
    },
    [limit, setOffset]
  );

  return (
    <Table
      currentPage={Math.floor(offset / limit) + 1}
      totalPages={totalPages}
      totalResults={count}
      itemsPerPage={limit}
      onClickRow={(id: any) => {
        navigate(`/projects/${projectId}/companies/${id}`);
      }}
      filterDimensions={[]}
      showFilterOptions={false}
      headerMapper={(text: string) => headerMapping[text]}
      onPageChange={onPageChange}
      data={companies?.map((result: any) => ({
        id: result.id,
        companyName: result.company.companyName,
        owner: result.company.owner
          ? `${result.company.owner.firstName} ${result.company.owner.lastName}`
          : '',
        companyContact: result.company.contactName,
        companyRegNumber: result.company.companyRegNumber,
        companyEmail: result.company.companyEmail,
      }))}
    />
  );
}
