import ReactCurrencyInput, {
  CurrencyInputOnChangeValues,
  formatValue,
} from 'react-currency-input-field';

interface Question {
  id: string;
  title: string;
  placeholder: string;
  name: string;
  description: string;
  defaultText: string;
  classNames: string;
  inputTransformer?: (value: any) => any;
}

interface CurrencyInputProps {
  question: Question;
  value: string;
  handleChange: (props: any) => void;
  validation: string;
  isDisabled?: boolean;
  rows?: number;
  currency?: string;
}

export const currencyToPrefix: Record<string, string> = {
  GBP: '£',
  EUR: '€',
  USD: '$',
};

export function formatCurrency({
  value,
  currency,
}: {
  value: string;
  currency: string;
}) {
  return formatValue({
    value,
    prefix: currency !== 'EUR' ? currencyToPrefix[currency] : '',
    suffix: currency === 'EUR' ? currencyToPrefix[currency] : '',
  });
}

export function CurrencyInput({
  question,
  currency = 'GBP',
  handleChange,
  value,
  validation,
  isDisabled,
}: CurrencyInputProps) {
  const {
    id,
    title,
    placeholder,
    name,
    classNames,
    description,
    inputTransformer = (value?: string) => value,
  } = question || {};

  return (
    <div className={classNames}>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {title}
      </label>
      {validation ? <p>{validation}</p> : null}
      <div className="flex items-center shadow-sm">
        <ReactCurrencyInput
          id={id}
          name={name}
          placeholder={placeholder}
          className="w-full px-3 mt-2 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
          decimalsLimit={2}
          fixedDecimalLength={2}
          onValueChange={(
            value: string | undefined,
            name: string | undefined,
            values: CurrencyInputOnChangeValues | undefined
          ) => {
            handleChange(inputTransformer(value));
          }}
          prefix={currency !== 'EUR' ? currencyToPrefix[currency] : undefined}
          suffix={currency === 'EUR' ? currencyToPrefix[currency] : undefined}
          value={value}
          disabled={isDisabled}
        />
      </div>
      {description ? (
        <p className="mt-2 text-sm text-gray-500">{description}</p>
      ) : null}
    </div>
  );
}
