import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/Buttons/Button';
import {
  ActiveBadge,
  DisabledBadge,
} from '../../../../components/Badges/Badges';

interface AssignmentRowProps {
  id: string;
  rowData?: {
    projectName?: string;
    projectId?: string;
    name?: string;
    roleName?: string;
    type?: string;
    enabled?: boolean;
  };
  filteredAndMappedActivityCodes: any[];
}

const SearchAssignmentRow: React.FC<AssignmentRowProps> = ({
  id,
  rowData = {},
}) => {
  const navigate = useNavigate();
  return (
    <div className="w-full flex flex-col 2xl:flex-row gap-y-4 gap-x-6 py-4 px-6 items-center justify-start border-b border-t-2">
      <div className="w-full">
        <h2 className="text-lg lg:text-sm">Name:</h2>
        <p className="text-sm">{rowData?.name}</p>
      </div>
      <div className="lg:hidden w-full">
        <h2 className="text-lg lg:text-sm">Project:</h2>
      </div>
      <div
        role="button"
        className={`${
          rowData?.enabled ? 'bg-green-100' : 'bg-gray-100'
        } rounded-lg hover:bg-gray-200 w-full flex items-center lg:justify-center px-2 py-2`}
        onClick={() => navigate(`/projects/${rowData.projectId}`)}
      >
        <p className="text-lg lg:text-sm">{rowData?.projectName}</p>
      </div>
      <div className="w-full">
        <h2 className="text-lg lg:text-sm">Type:</h2>
        <p className="text-sm">{rowData?.type}</p>
      </div>

      <div className="w-full">
        <h2 className="text-lg lg:text-sm">Role:</h2>
        <p className="text-sm">{rowData?.roleName}</p>
      </div>
      <div className="w-full flex gap-x-2 items-center">
        <h2 className="text-lg lg:text-sm">Status:</h2>
        <p>{rowData?.enabled ? <ActiveBadge /> : <DisabledBadge />}</p>
      </div>
      <div className="w-full">
        <Button
          text="View Assignment"
          onClick={() =>
            navigate(`/projects/${rowData.projectId}/assignments/${id}`)
          }
        />
      </div>
    </div>
  );
};

export default SearchAssignmentRow;
