import { gql } from '@apollo/client';

export const SAVE_USER_PROFILE = gql`
  mutation saveUserProfile(
    $id: ID!
    $input: SaveUserProfileInput!
    $activeOrganisation: String!
  ) {
    saveUserProfile(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const SAVE_GROUP = gql`
  mutation saveGroup(
    $id: ID
    $input: SaveGroupInput!
    $activeOrganisation: String!
  ) {
    saveGroup(id: $id, input: $input, activeOrganisation: $activeOrganisation) {
      success
      message
    }
  }
`;

export const RESEND_USER_SIGN_UP = gql`
  mutation resendUserSignUp($email: String!, $activeOrganisation: String!) {
    resendUserSignUp(email: $email, activeOrganisation: $activeOrganisation) {
      success
      message
    }
  }
`;

export const CREATE_LOGIN_FOR_USER = gql`
  mutation createLoginForUser(
    $email: String!
    $userProfileId: String!
    $activeOrganisation: String!
  ) {
    createLoginForUser(
      email: $email
      userProfileId: $userProfileId
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;
