import { Field, FieldArray } from 'formik';

export const RuleFieldSet = ({ column, columnIndex }: any) => {
  const moveRule = (
    fromIndex: number,
    toIndex: number,
    ruleArrayHelpers: any
  ) => {
    if (toIndex < 0 || toIndex >= column.rules.length) return; // Prevent moving out of bounds
    ruleArrayHelpers.swap(fromIndex, toIndex);
  };

  return (
    <FieldArray
      name={`columns[${columnIndex}].rules`}
      render={(ruleArrayHelpers) => (
        <div className="mt-4">
          {column.rules.map((rule: any, ruleIndex: any) => (
            <div
              key={ruleIndex}
              className="mb-6 p-4 border border-gray-300 bg-gray-50 rounded-lg shadow"
            >
              <div className="flex items-center justify-between">
                <h4 className="text-lg font-semibold mb-3">
                  Rule {ruleIndex + 1}
                </h4>
                {column.rules.length > 1 ? (
                  <div className="flex justify-end mb-2">
                    {ruleIndex > 0 ? (
                      <button
                        type="button"
                        onClick={() =>
                          moveRule(ruleIndex, ruleIndex - 1, ruleArrayHelpers)
                        }
                        className="mx-1 py-1 px-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                        disabled={ruleIndex === 0}
                      >
                        ↑
                      </button>
                    ) : null}
                    {ruleIndex < column.rules.length - 1 ? (
                      <button
                        type="button"
                        onClick={() =>
                          moveRule(ruleIndex, ruleIndex + 1, ruleArrayHelpers)
                        }
                        className="mx-1 py-1 px-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                        disabled={ruleIndex === column.rules.length - 1}
                      >
                        ↓
                      </button>
                    ) : null}
                  </div>
                ) : null}
              </div>
              <div className="mb-3">
                <label className="block text-gray-700 mb-1">
                  Condition Expression
                </label>
                <Field
                  className="w-full p-2 border border-gray-300 rounded-md"
                  name={`columns[${columnIndex}].rules[${ruleIndex}].conditionExpression`}
                  placeholder="Condition Expression"
                />
              </div>

              <div className="mb-3">
                <label className="block text-gray-700 mb-1">Source Field</label>
                <Field
                  className="w-full p-2 border border-gray-300 rounded-md"
                  name={`columns[${columnIndex}].rules[${ruleIndex}].sourceField`}
                  placeholder="Source Field"
                />
              </div>
              <FieldArray
                name={`columns[${columnIndex}].rules[${ruleIndex}].transformations`}
                render={(transformationArrayHelpers) => (
                  <div className="mb-3">
                    <label className="block text-gray-700 mb-1">
                      Transformations
                    </label>
                    {rule?.transformations?.map(
                      (transformation: any, transformationIndex: any) => (
                        <div
                          key={transformationIndex}
                          className="flex items-center mb-2"
                        >
                          <Field
                            as="select"
                            name={`columns[${columnIndex}].rules[${ruleIndex}].transformations[${transformationIndex}].type`}
                            className="mr-2 p-2 border border-gray-300 rounded-md"
                          >
                            <option value="value">Set Value</option>
                            <option value="prefix">Prefix</option>
                            <option value="suffix">Suffix</option>
                            <option value="multiply">Multiply</option>
                          </Field>
                          <Field
                            className="p-2 border border-gray-300 rounded-md"
                            name={`columns[${columnIndex}].rules[${ruleIndex}].transformations[${transformationIndex}].value`}
                            placeholder="Value"
                          />
                          <Field
                            className="ml-2 p-2 w-full border border-gray-300 rounded-md"
                            name={`columns[${columnIndex}].rules[${ruleIndex}].transformations[${transformationIndex}].sourceField`}
                            placeholder="Source Field"
                          />
                          <button
                            type="button"
                            onClick={() =>
                              transformationArrayHelpers.remove(
                                transformationIndex
                              )
                            }
                            className="ml-2 py-1 px-3 bg-red-500 text-white rounded-md hover:bg-red-600"
                          >
                            Remove
                          </button>
                        </div>
                      )
                    )}
                    <button
                      type="button"
                      onClick={() =>
                        transformationArrayHelpers.push({
                          type: 'prefix',
                          value: '',
                          sourceField: '',
                        })
                      }
                      className="py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                    >
                      Add Transformation
                    </button>
                  </div>
                )}
              />
              <hr />
              <button
                type="button"
                onClick={() => ruleArrayHelpers.remove(ruleIndex)}
                className="mt-3 py-2 px-4 bg-red-500 text-white rounded-md hover:bg-red-600"
              >
                Remove Rule
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() =>
              ruleArrayHelpers.push({
                conditionExpression: '',
                sourceField: '',
                transformations: [],
              })
            }
            className="py-2 px-4 bg-green-500 text-white rounded-md hover:bg-green-600"
          >
            Add Rule
          </button>
        </div>
      )}
    />
  );
};
