import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useContext, useEffect } from 'react';

import FormCard from '../../../components/FormCard/FormCard';

import { SAVE_GROUP } from '../../../graphql/mutations/users';
import { GET_GROUPS } from '../../../graphql/queries/users';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { FieldType, FormConfig } from '../../../types/Form';
import { ActiveOrganisationContext } from '../../../context/ActiveOrganisationContext';
import { Permission } from '../../../types/Permissions';

export const SaveGroup = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const { activeOrganisation } = useContext(ActiveOrganisationContext);

  const docusealEnabled = activeOrganisation?.docusealEnabled;

  const isUpdate = !!id;

  const [fetchGroups, { data }] = useLazyQuery(GET_GROUPS, {
    variables: { input: { id } },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchGroups({ variables: { input: { id } } });
  }, [id, isUpdate]);

  const [group] = data?.getGroups?.results || [];

  const [saveGroup] = useMutation(SAVE_GROUP);

  const initialValues = id
    ? {
        permissions: [],
        ...group,
      }
    : {
        permissions: [],
      };

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      await saveGroup({
        variables: {
          id: values?.id,
          input: {
            id: undefined,
            name: values?.name,
            permissions: values?.permissions,
            updatedAt: undefined,
            createdAt: undefined,
          },
        },
      });
      if (setSubmitting) {
        setSubmitting(false);
      }
      navigate(-1);
    } catch (error) {
      console.error(error);
    }
  };

  const groupConfig = {
    formSections: [
      {
        title: 'Group',
        fields: [
          {
            id: 'name',
            type: 'input',
            title: 'Name',
          },
          {
            id: 'permissions',
            type: 'checkbox-group',
            title: 'Project Permissions',
            options: [
              { name: 'Update Project', id: 'update_project' },
              { name: 'Update Teams', id: 'update_teams' },
              {
                name: 'Assign Labour to Project',
                id: 'assign_labour_to_project',
              },
              {
                name: 'Download Attachments',
                id: 'download_attachments',
              },
              { name: 'View Project Details', id: 'view_project_details' },
              {
                name: 'View Project Assignment Details',
                id: 'view_project_assignment_detail',
              },
              {
                name: 'View Project Assignment Rates',
                id: 'view_project_assignment_rates',
              },
              {
                name: 'Delete Assignment Role',
                id: 'delete_assignment_role',
              },
              {
                name: 'Generate Project Exports',
                id: 'generate_project_exports',
              },
              {
                name: 'Delete Project Team',
                id: 'delete_project_team',
              },
            ].map((permission) => ({
              name: permission.name,
              value: permission.id,
            })),
          },
          {
            id: 'permissions',
            type: 'checkbox-group',
            title: 'Assessment Permissions',
            options: [
              {
                name: 'View Assessments',
                id: 'view_assessments',
              },
              { name: 'Update Assessments', id: 'update_assessment_types' },
              { name: 'Send Assessments', id: 'send_assessments' },
              {
                name: 'Delete Assessment Attachments',
                id: 'delete_assessment_attachments',
              },
              {
                name: 'Delete Assessment Submissions',
                id: 'delete_assessment_submissions',
              },
            ].map((permission) => ({
              name: permission.name,
              value: permission.id,
            })),
          },
          {
            id: 'permissions',
            type: 'checkbox-group',
            title: 'Custom Data Permissions',
            options: [
              { name: 'Update Custom Data', id: 'update_custom_data' },
              { name: 'View Custom Data', id: 'view_custom_data' },
              { name: 'Delete Custom Data', id: 'delete_custom_data' },
            ].map((permission) => ({
              name: permission.name,
              value: permission.id,
            })),
          },
          {
            id: 'permissions',
            type: 'checkbox-group',
            title: 'User & Group Permissions',
            options: [
              { name: 'Assign User to Group', id: 'assign_user_to_group' },
              {
                name: 'Assign Project Filter to User',
                id: 'assign_project_filter_to_user',
              },
              { name: 'Update Group', id: 'update_groups' },
              { name: 'View Users', id: 'view_users' },
            ].map((permission) => ({
              name: permission.name,
              value: permission.id,
            })),
          },
          {
            id: 'permissions',
            type: 'checkbox-group',
            title: 'Timesheet Permissions',
            options: [
              {
                name: 'Update Timesheet Configuration',
                id: 'update_timesheet_configuration',
              },
              { name: 'Approve Timesheets', id: 'approve_timesheets' },
              { name: 'Unapprove Timesheets', id: 'unapprove_timesheets' },
              {
                name: 'View Timesheet Submissions',
                id: 'view_timesheet_submissions',
              },
              { name: 'View Timesheets', id: 'view_timesheets' },
              { name: 'View Activity Codes', id: 'view_activity_codes' },
              { name: 'Update Activity Codes', id: 'update_activity_codes' },
              { name: 'Delete Activity Codes', id: 'delete_activity_codes' },
            ].map((permission) => ({
              name: permission.name,
              value: permission.id,
            })),
          },
          {
            id: 'permissions',
            type: 'checkbox-group',
            title: 'Progress Permissions',
            options: [
              { name: 'View Progress Submissions', id: 'view_progress_module' },
              {
                name: 'View Progress Dashboard',
                id: 'view_progress_dashboard',
              },
              {
                name: 'Save Progress Submission',
                id: 'save_progress_submission',
              },
              {
                name: 'Configure Progress Module',
                id: 'configure_progress_module',
              },
              {
                name: 'Lock Progress Submission',
                id: 'lock_progress_submission',
              },
              {
                name: 'Approve Progress Submission',
                id: 'approve_progress_submission',
              },
              {
                name: 'Generate Progress Reports',
                id: 'generate_progress_reports',
              },
            ].map((permission) => ({
              name: permission.name,
              value: permission.id,
            })),
          },
          {
            id: 'permissions',
            type: 'checkbox-group',
            title: 'Labour Permissions',
            options: [
              { name: 'Update Labour Profiles', id: 'update_labour_profiles' },
              { name: 'View Labour Profiles', id: 'view_labour_profiles' },
              { name: 'View Skills', id: 'view_skills' },
              {
                name: 'Update Certifications',
                id: 'update_certification_types',
              },
              {
                name: 'Download Certification Attachments',
                id: 'download_certification_attachments',
              },
              {
                name: 'Delete Certification Attachments',
                id: 'delete_certification_attachments',
              },
              {
                name: 'Delete Certification Types',
                id: 'delete_certification_types',
              },
              { name: 'Delete Certifications', id: 'delete_certifications' },
              { name: 'Update Skill Types', id: 'update_skill_types' },
              { name: 'Update Skills', id: 'update_skills' },
            ].map((permission) => ({
              name: permission.name,
              value: permission.id,
            })),
          },
          {
            id: 'permissions',
            type: 'checkbox-group',
            title: 'Export Permissions',
            options: [
              {
                name: 'Update Export Configuration',
                id: 'update_export_configuration',
              },
            ].map((permission) => ({
              name: permission.name,
              value: permission.id,
            })),
          },
          {
            id: 'permissions',
            type: 'checkbox-group',
            title: 'Role Permissions',
            options: [
              { name: 'Update Roles', id: 'update_roles' },
              { name: 'View Roles', id: 'view_roles' },
              { name: 'View Role Rates', id: 'view_role_rates' },
            ].map((permission) => ({
              name: permission.name,
              value: permission.id,
            })),
          },
          {
            id: 'permissions',
            type: 'checkbox-group',
            title: 'Certifications Permissions',
            options: [
              { name: 'Delete Certifications', id: 'delete_certifications' },
            ].map((permission) => ({
              name: permission.name,
              value: permission.id,
            })),
          },
          {
            id: 'permissions',
            type: 'checkbox-group',
            title: 'Companies Permissions',
            options: [
              { name: 'Delete Company Profiles', id: 'delete_company_profile' },
            ].map((permission) => ({
              name: permission.name,
              value: permission.id,
            })),
          },
          {
            id: 'permissions',
            type: 'checkbox-group',
            title: 'Event Type Permissions',
            options: [
              { name: 'View Event Types', id: Permission.ViewEventTypes },
              { name: 'Update Event Types', id: Permission.UpdateEventTypes },
            ].map((permission) => ({
              name: permission.name,
              value: permission.id,
            })),
          },
        ],
      },
    ],
  } as FormConfig;

  // inserting Contract Permissions at second place in fields if docuseal is enabled
  if (docusealEnabled && groupConfig.formSections) {
    groupConfig.formSections[0].fields = [
      ...groupConfig.formSections[0].fields.slice(0, 2),
      {
        id: 'permissions',
        type: FieldType.checkboxgroup,
        title: 'Contract Permissions',
        options: [
          {
            name: 'View Contract Templates',
            id: 'view_contract_templates',
          },
          {
            name: 'Edit Contract Templates',
            id: 'edit_contract_templates',
          },
          { name: 'Send Contracts', id: 'send_contracts' },
          {
            name: 'View Contract Submissions',
            id: 'view_contract_submissions',
          },
        ].map((permission) => ({
          name: permission.name,
          value: permission.id,
        })),
      },
      ...groupConfig.formSections[0].fields.slice(2),
    ];
  }

  const GroupCreationSchema = Yup.object().shape({});

  return (
    <FormCard
      key={`${groupConfig?.title}`}
      config={groupConfig}
      validationSchema={GroupCreationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
};
