import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { CalendarDaysIcon, WifiIcon } from '@heroicons/react/24/outline';
import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  LockClosedIcon,
} from '@heroicons/react/24/solid';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { Offline, PulseOnline } from '../../../components/Badges/Badges';
import { Button } from '../../../components/Buttons/Button';
import { Modal } from '../../../components/Dialogue/ModalDialogue';
import TextArea from '../../../components/Inputs/TextArea';
import { NetworkContext } from '../../../context/NetworkContext';
import { SAVE_PROGRESS_ACTIVITY_SUBMISSION } from '../../../graphql/mutations/progress-activity-submissions';
import {
  GET_PROGRESS_ACTIVITY_SUBMISSIONS,
  GET_PROGRESS_ACTIVITY_SUBMISSIONS_NO_STATUS,
} from '../../../graphql/queries/progress-activity-submissions';
import {
  GET_PROJECT_TEAMS,
  SEARCH_PROJECTS,
} from '../../../graphql/queries/projects';
import { useIndexedDB } from '../../../hooks/useIndexedDB';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { ActivitySubmission } from './activity/save';
import { SaveProgressAttachment } from './attachments/save';
import { ConditionSubmission } from './conditions/save';
import { useOnlineOfflineModeState } from './hooks/useOnlineOfflineModeState';
import { useProgressDataRetrieval } from './hooks/useProgressDataRetrieval';
import { useProgressSubmissionState } from './hooks/useProgressSubmissionState';
import { IncidentSubmission } from './incidents/save';
import { PersonnelSubmission } from './personnel/save';
import { OutcomeStatus } from './status/OutcomeStatus';
import {
  getPathValue,
  markAllAsLocallySaved,
  mergeServerAndLocalData,
  prepareItems,
} from './utils/utils';
// @ts-ignore
import { default as ComponentDatePicker } from 'react-datepicker';
import { LoadingSpinner } from '../../../components/Loading/LoadingSpinner';
import { useToast } from '../../../context/ToastContext';

const ConnectivityChecker = () => {
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const checkConnectivity = async () => {
      try {
        const response = await fetch(
          `https://app.enggenius.tech/favicon.ico?${Date.now()}`,
          { mode: 'no-cors' }
        );
        setIsOnline(true);
      } catch (error) {
        setIsOnline(false);
      }
    };

    checkConnectivity();

    const intervalId = setInterval(checkConnectivity, 8000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      {!isOnline && (
        <div
          className="bg-white py-2 px-2 text-sm"
          style={{ color: 'red', fontWeight: 'bold' }}
        >
          <WifiIcon className={`w-8 h-8 text-red-500`} />
          Looks like you aren't connected to the internet. You may have trouble
          performing a sync.
          <p>Please check your connection.</p>
        </div>
      )}
    </div>
  );
};

export const SaveProgressSubmission = () => {
  const navigate = useNavigate();
  let { id, outcomeId, date } = useParams();

  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const { isOffline, setIsOffline } = useContext(NetworkContext);

  const { db, putItem, readItem, hasPendingSync, setHasPendingSync } =
    useIndexedDB({ objectStoreName: 'progress_submissions' });

  const {
    db: cachedProjectDataDb,
    putItem: updateLocalData,
    readItem: readLocalData,
  } = useIndexedDB({ objectStoreName: `cached_project_data` });

  const { retrieveAndCacheDataFromServer } = useProgressDataRetrieval({ id });

  const [fetchProjects, { data: projectsData }] = useLazyQuery(
    SEARCH_PROJECTS,
    {
      variables: { input: { id } },
      fetchPolicy: 'network-only',
    }
  );

  const [fetchProjectTeams, { data: projectTeamsData }] = useLazyQuery(
    GET_PROJECT_TEAMS,
    {
      variables: { projectId: id },
      fetchPolicy: 'network-only',
    }
  );

  const [statusReport, setStatusReport] = useState([]);

  const [saveProgressSubmission, { loading }] = useMutation(
    SAVE_PROGRESS_ACTIVITY_SUBMISSION
  );

  const [cachedProjects, setCachedProjects] = useState([]);
  const [cachedProjectTeams, setCachedProjectTeams] = useState([]);
  const [selectedProject] = isOffline
    ? cachedProjects?.filter((cachedProject: any) => cachedProject?.id === id)
    : projectsData?.searchProjects?.results || [];
  const projectTeamsDataResults = isOffline
    ? cachedProjectTeams?.filter(
        (cachedProjectTeam: any) => cachedProjectTeam?.projectId === id
      )
    : projectTeamsData?.getProjectTeams?.results || [];

  const progressSubmissionId = `${selectedProject?.id}_${outcomeId}_${date}`;

  const [
    fetchProgressSubmissions,
    { data: projectActivitySubmissions, loading: isLoadingSubmissions },
  ] = useLazyQuery(GET_PROGRESS_ACTIVITY_SUBMISSIONS_NO_STATUS, {
    fetchPolicy: 'network-only',
  });

  const [projectActivitySubmission] =
    projectActivitySubmissions?.getProgressSubmissions?.results || [];

  const {
    activities,
    setActivities,
    conditions,
    setConditions,
    incidents,
    setIncidents,
    personnel,
    setPersonnel,
    plan,
    setPlan,
    summary,
    setSummary,
  } = useProgressSubmissionState(projectActivitySubmission);

  const entityMappings = [
    {
      key: 'activities',
      serverKey: 'pae',
      localPath: 'progressSubmission.variables.input.activities',
      setState: setActivities,
    },
    {
      key: 'conditions',
      serverKey: 'pce',
      localPath: 'progressSubmission.variables.input.conditions',
      setState: setConditions,
    },
    {
      key: 'incidents',
      serverKey: 'pie',
      localPath: 'progressSubmission.variables.input.incidents',
      customMapping: (item: any) => ({
        ...item,
        id: item?.pit?.id,
        name: item?.pit?.name,
      }),
      setState: setIncidents,
    },
    {
      key: 'personnel',
      serverKey: 'ppe',
      localPath: 'progressSubmission.variables.input.personnel',
      setState: setPersonnel,
    },
  ];

  const {
    showUnsavedChangesModal,
    setShowUnsavedChangesModal,
    showLocalSyncModal,
    setShowLocalSyncModal,
    showOfflineModal,
    setShowOfflineModal,
    failedOnlineSync,
    setFailedOnlineSync,
    summaryHasChanged,
    setSummaryHasChanges,
    statusHasChanged,
    setStatusHasChanges,
    unsavedChangesOnCompletionNav,
    setUnsavedChangesOnCompletionNav,
  } = useOnlineOfflineModeState();

  const { addToast } = useToast();

  const teams = projectTeamsDataResults
    .map((team: any) => ({
      ...team,
      team: team?.ptar?.map((ptr: any) => ({
        id: ptr.assignment_role?.id,
        enabledOnTeam: !!ptr.enabled,
        name: `${ptr.assignment_role?.assignment?.lrs?.firstName} ${ptr.assignment_role?.assignment?.lrs?.lastName}`,
        detail: ptr.assignment_role?.role?.name,
      })),
      activityCount:
        activities?.filter(
          (activity: any) => activity?.projectTeamId === team?.id
        )?.length ?? 0,
      hasChanges: activities
        ?.filter((activity: any) => activity?.projectTeamId === team?.id)
        .some(
          (activity: any) =>
            activity.isNew || activity.hasChanged || activity.toBeDeleted
        ),
    }))
    .sort((a: any, b: any) => a.name.localeCompare(b.name));

  const activitiesHaveChanges = teams?.some((team: any) => team.hasChanges);
  const personnelHasChanges = personnel?.some((team: any) => team.hasChanged);
  const conditionsTabHasChanges = conditions?.some(
    (condition: any) =>
      condition.isNew || condition.hasChanged || condition.toBeDeleted
  );
  const incidentsTabHasChanges = incidents?.some(
    (incident: any) =>
      incident.isNew || incident.hasChanged || incident.toBeDeleted
  );

  const reportDivisions = [
    {
      name: `Activities${activitiesHaveChanges ? ' *' : ''}`,
    },
    {
      name: `Conditions${conditionsTabHasChanges ? ' *' : ''}`,
    },
    {
      name: `HSE${incidentsTabHasChanges ? ' *' : ''}`,
    },
    {
      name: `Personnel${personnelHasChanges ? ' *' : ''}`,
    },
    {
      name: `Status${statusHasChanged ? ' *' : ''}`,
    },
    {
      name: `Summary${summaryHasChanged ? ' *' : ''}`,
    },
    {
      name: 'Attachments',
    },
  ];

  const [reportDivision, setReportDivision] = useState(reportDivisions[0]);

  const unsavedChanges =
    activitiesHaveChanges ||
    conditionsTabHasChanges ||
    incidentsTabHasChanges ||
    summaryHasChanged ||
    personnelHasChanges ||
    statusHasChanged;

  const hasItemsSavedLocally =
    activities?.some((activity: any) => activity.savedLocally) ||
    conditions?.some((condition: any) => condition.savedLocally);

  const projectsDataLocalCacheId = `project_${id}`;
  const projectTeamsDataLocalCacheId = `project_teams_${id}`;

  const fetchProjectsAndTeamsFromServerAndSync = async () => {
    if (!isOffline) {
      const projects = await fetchProjects();
      const projectResults = projects?.data?.searchProjects?.results || [];
      updateLocalData({ id: projectsDataLocalCacheId, projectResults });

      const teams = await fetchProjectTeams();
      const teamsResults =
        teams?.data?.getProjectTeams?.results?.map((team: any) => ({
          ...team,
          projectId: id,
        })) || [];
      updateLocalData({ id: projectTeamsDataLocalCacheId, teamsResults });
    }
  };

  const fetchProjectsAndTeamsFromLocalCache = async () => {
    const projects = await readLocalData(projectsDataLocalCacheId);
    setCachedProjects(projects?.projectResults ?? []);

    const teams = await readLocalData(projectTeamsDataLocalCacheId);
    setCachedProjectTeams(teams?.teamsResults ?? []);
  };

  const alignStateWithSubmission = (
    submissionResult: any,
    localSubmission: any
  ) => {
    unstable_batchedUpdates(() => {
      entityMappings.forEach(
        ({ serverKey, localPath, customMapping, setState }) => {
          const serverData = submissionResult?.[serverKey] || [];
          const localData = getPathValue(localSubmission, localPath) || [];
          const processedServerData = customMapping
            ? serverData.map(customMapping)
            : serverData;
          const mergedData = mergeServerAndLocalData(
            processedServerData,
            localData
          );

          setState(mergedData);
        }
      );
      setStatusReport(submissionResult?.status ?? []);
      setPlan(
        submissionResult?.comments?.find(
          (comment: any) => comment.id === 'PLAN'
        )?.value
      );
      setSummary(
        submissionResult?.comments?.find(
          (comment: any) => comment.id === 'SUMMARY'
        )?.value
      );
    });
  };

  const syncProgressSubmissionFromIndexedDb = async () => {
    const localSubmission = await readItem(progressSubmissionId);
    alignStateWithSubmission(
      {
        pae: [],
        pce: [],
        pie: [],
        ppe: [],
        outcomeId,
        comments:
          localSubmission?.progressSubmission?.variables?.input?.comments,
      },
      localSubmission
    );
  };

  const submissionsQuery = GET_PROGRESS_ACTIVITY_SUBMISSIONS;

  const fetchServerSubmissions = async () => {
    const localSubmission = await readItem(progressSubmissionId);
    const result = await fetchProgressSubmissions({
      variables: { projectId: selectedProject?.id, outcomeId, date },
      query: submissionsQuery,
      fetchPolicy: 'network-only',
    });

    const [submissionResult] =
      result?.data?.getProgressSubmissions?.results || [];

    alignStateWithSubmission(submissionResult, localSubmission);
  };

  useEffect(() => {
    if (cachedProjectDataDb && !isOffline) {
      retrieveAndCacheDataFromServer();
    }
  }, [cachedProjectDataDb, id, outcomeId, date]);

  useEffect(() => {
    if (!isOffline && cachedProjectDataDb) {
      fetchProjectsAndTeamsFromServerAndSync();
    } else {
      if (cachedProjectDataDb) {
        fetchProjectsAndTeamsFromLocalCache();
      }
    }
  }, [id, isOffline, cachedProjectDataDb]);

  useEffect(() => {
    if (selectedProject?.id && date && db) {
      if (isOffline) {
        syncProgressSubmissionFromIndexedDb();
      } else {
        fetchServerSubmissions();
      }
    }
  }, [id, date, selectedProject?.id, db]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const activitiesToSave = prepareItems(activities, {
      nestedMappings: {
        pl: ['createdAt', 'updatedAt'],
        pat: ['pag', 'detail', 'createdAt', 'updatedAt'],
      },
    });

    const conditionsToSave = prepareItems(conditions, {
      nestedMappings: {
        plc: ['createdAt', 'updatedAt'],
      },
    });

    const incidentsToSave = prepareItems(incidents, {
      idKey: 'pit.id',
      excludeKeys: ['pit', 'toDateValue'],
    });

    const personnelToSave = prepareItems(personnel, {
      idKey: 'projectTeamId',
      excludeKeys: ['name', 'totalPersonHours'],
    });

    const progressSubmission = {
      variables: {
        input: {
          activities: activitiesToSave,
          conditions: conditionsToSave,
          incidents: incidentsToSave,
          personnel: personnelToSave,
          status: statusReport,
          projectId: id,
          date,
          outcomeId,
          comments: summaryHasChanged
            ? [
                {
                  id: 'SUMMARY',
                  value: summary,
                },
                {
                  id: 'PLAN',
                  value: plan,
                },
              ]
            : undefined,
        },
      },
    };

    if (!isOffline) {
      const localItem = await readItem(progressSubmissionId);
      await saveProgressSubmission(progressSubmission);
      const result = await fetchProgressSubmissions({
        variables: { projectId: selectedProject?.id, date, outcomeId },
        query: submissionsQuery,
        fetchPolicy: 'network-only',
      });
      handleSubmissionResult(result, localItem);
    } else {
      const {
        activities: locallySavedActivities,
        conditions: locallySavedConditions,
        incidents: locallySavedIncidents,
        personnel: locallySavedPersonnel,
      } = markAllAsLocallySaved({
        activities: { original: activities, toSave: activitiesToSave },
        conditions: { original: conditions, toSave: conditionsToSave },
        incidents: { original: incidents, toSave: incidentsToSave },
        personnel: { original: personnel, toSave: personnelToSave },
      });

      const progressSubmission = {
        variables: {
          input: {
            activities: locallySavedActivities,
            conditions: locallySavedConditions,
            incidents: locallySavedIncidents,
            personnel: locallySavedPersonnel,
            outcomeId,
            comments: [
              {
                id: 'SUMMARY',
                value: summary,
              },
              {
                id: 'PLAN',
                value: plan,
              },
            ],
            projectId: id,
            date,
          },
        },
      };

      try {
        unstable_batchedUpdates(() => {
          putItem({ id: progressSubmissionId, progressSubmission });
          setActivities(locallySavedActivities);
          setConditions(locallySavedConditions);
          setIncidents(locallySavedIncidents);
          setPersonnel(locallySavedPersonnel);
          setPlan(plan);
          setSummary(summary);
        });
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleSubmissionResult = (result: any, localItem: any) => {
    if (result?.data?.getProgressSubmissions?.results) {
      const [submissionResult] =
        result?.data?.getProgressSubmissions?.results ?? [];

      const incidentsDataToSync = mergeServerAndLocalData(
        submissionResult?.pie?.map((pie: any) => ({
          ...pie,
          id: pie?.pit?.id,
          name: pie?.pit?.name,
        })),
        incidents
      )?.map((item: any) => ({
        ...item,
        hasChanged: undefined,
        savedLocally: undefined,
      }));

      const activityIds = submissionResult?.pae?.map((item: any) => item.id);
      const conditionIds = submissionResult?.pce?.map((item: any) => item.id);

      const activitiesToSync = mergeServerAndLocalData(
        submissionResult?.pae,
        activities?.filter((item: any) => !item.toBeDeleted)
      )?.map((item: any) => ({
        ...item,
        ...(activityIds.includes(item.id)
          ? {
              isNew: false,
              hasChanged: undefined,
              savedLocally: undefined,
            }
          : {}),
      }));

      const conditionsToSync = mergeServerAndLocalData(
        submissionResult?.pce,
        conditions?.filter((item: any) => !item.toBeDeleted)
      )?.map((item: any) => ({
        ...item,
        ...(conditionIds.includes(item.id)
          ? {
              isNew: false,
              hasChanged: undefined,
              savedLocally: undefined,
            }
          : {}),
      }));

      const personnelDataToSync = mergeServerAndLocalData(
        submissionResult?.ppe,
        personnel
      )?.map((item: any) => ({
        ...item,
        hasChanged: undefined,
        savedLocally: undefined,
      }));

      unstable_batchedUpdates(() => {
        setActivities(activitiesToSync);
        setConditions(conditionsToSync);
        setIncidents(incidentsDataToSync);
        setPersonnel(personnelDataToSync);
        setPlan(
          submissionResult?.comments?.find(
            (comment: any) => comment.id === 'PLAN'
          )?.value
        );
        setStatusReport(submissionResult?.status ?? []);
        setSummary(
          submissionResult?.comments?.find(
            (comment: any) => comment.id === 'SUMMARY'
          )?.value
        );
        putItem({
          id: progressSubmissionId,
          progressSubmission: {
            ...localItem?.progressSubmission,
            variables: {
              input: {
                ...localItem?.progressSubmission?.variables?.input,
                activities: activitiesToSync,
                conditions: conditionsToSync,
                personnel: personnelDataToSync,
                incidents: incidentsDataToSync,
                comments: submissionResult?.comments,
                outcomeId,
              },
            },
          },
        });
        setStatusHasChanges(false);
        setSummaryHasChanges(false);
        setHasPendingSync(false);
      });

      addToast('Save successful', 'success');
    }
  };

  const syncLocalChanges = async () => {
    const localItem = await readItem(progressSubmissionId);
    const localItemInput = localItem?.progressSubmission?.variables?.input;
    const activitiesToSave = prepareItems(localItemInput?.activities, {
      nestedMappings: {
        pl: ['createdAt', 'updatedAt'],
        pat: ['pag', 'detail', 'createdAt', 'updatedAt'],
      },
    });
    const conditionsToSave = prepareItems(localItemInput?.conditions, {
      nestedMappings: {
        plc: ['createdAt', 'updatedAt'],
      },
    });

    const incidentsToSave = prepareItems(localItemInput?.incidents, {
      idKey: 'pit.id',
      excludeKeys: ['pit', 'toDateValue'],
    });
    const personnelToSave = prepareItems(personnel, {
      idKey: 'projectTeamId',
      excludeKeys: ['name', 'totalPersonHours'],
    });

    await saveProgressSubmission({
      ...localItem?.progressSubmission,
      variables: {
        input: {
          ...localItem?.progressSubmission?.variables?.input,
          activities: activitiesToSave,
          conditions: conditionsToSave,
          incidents: incidentsToSave,
          personnel: personnelToSave,
          comments: localItemInput?.comments,
          outcomeId,
        },
      },
    });
    const result = await fetchProgressSubmissions({
      variables: { projectId: selectedProject?.id, date, outcomeId },
      query: submissionsQuery,
      fetchPolicy: 'network-only',
    });

    handleSubmissionResult(result, localItem);
  };

  const goToPreviousDay = (id?: string, currentDate?: string) => {
    const previousDay = moment
      .utc(currentDate)
      .subtract(1, 'days')
      .format('YYYY-MM-DD');

    const navigation = `/progress/submissions/${id}/${outcomeId}/${previousDay}`;
    if (unsavedChanges) {
      setUnsavedChangesOnCompletionNav(navigation);
      setShowUnsavedChangesModal(true);
    } else {
      navigate(navigation);
    }
  };

  const goToNextDay = (id?: string, currentDate?: string) => {
    const nextDay = moment.utc(currentDate).add(1, 'days').format('YYYY-MM-DD');
    const navigation = `/progress/submissions/${id}/${outcomeId}/${nextDay}`;
    if (unsavedChanges) {
      setUnsavedChangesOnCompletionNav(navigation);
      setShowUnsavedChangesModal(true);
    } else {
      navigate(navigation);
    }
  };

  useEffect(() => {
    const handleOnline = () => {
      setIsOffline(false);
    };

    const handleOffline = () => {
      setIsOffline(true);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    if (!isOffline && (hasPendingSync || hasItemsSavedLocally)) {
      setShowLocalSyncModal(true);
    }
  }, [isOffline, hasPendingSync, hasItemsSavedLocally]);

  useEffect(() => {
    if (isOffline) {
      setShowOfflineModal(true);
    }
  }, [isOffline]);

  const [isOutcomeStatusExpanded, setIsOutcomeStatusExpanded] = useState(true);

  return (
    <>
      <div>
        <div className="w-full flex flex-col justify-between bg-gray-900 text-white rounded-t-lg pt-1 items-start ">
          <div className="flex w-full flex-col lg:flex-row justify-between border-b py-1 px-2">
            <div className="flex flex-col lg:flex-row gap-x-2 gap-y-2 items-center">
              <Button
                style={{
                  backgroundColor: 'transparent',
                  color: 'white',
                  boxShadow: 'none',
                  borderRadius: 0,
                }}
                onClick={() => {
                  if (unsavedChanges) {
                    setShowUnsavedChangesModal(true);
                    setUnsavedChangesOnCompletionNav(
                      `/progress/submissions/${id}/${outcomeId}`
                    );
                  } else {
                    navigate(`/progress/submissions/${id}/${outcomeId}`);
                  }
                }}
                type="submit"
                text={`< Go Back`}
              />
              <h2 className="text-md flex flex-col gap-y-2">
                <p className="text-md">
                  {selectedProject?.name}{' '}
                  <i className="text-white not-italic text-sm">
                    ({selectedProject?.internalId})
                  </i>
                </p>
              </h2>
              <i className="text-base flex items-center text-xs gap-x-2 not-italic">
                {isOffline ? (
                  <>
                    <Offline /> Offline
                  </>
                ) : (
                  <>
                    <PulseOnline /> Online
                  </>
                )}
              </i>
              <i className="text-base flex items-center text-xs gap-x-2 not-italic">
                {projectActivitySubmission?.locked ? (
                  <>
                    <LockClosedIcon className="w-5 h-5 text-white" /> Locked
                  </>
                ) : (
                  <></>
                )}
              </i>
            </div>

            {id ? (
              <div className="flex flex-col lg:flex-row items-center gap-x-2 gap-y-2">
                <p className="text-sm flex items-center gap-x-2">
                  <CalendarDaysIcon className="w-5 h-5 text-white" />
                  <ComponentDatePicker
                    selected={date ? moment(date).toDate() : moment().toDate()}
                    onChange={(date: any) => {
                      const navigation = `/progress/submissions/${id}/${outcomeId}/${moment
                        .utc(date)
                        .format('YYYY-MM-DD')}`;
                      navigate(navigation);
                    }}
                    role="button"
                    className={
                      'text-white text-center bg-gray-700 rounded-md px-1 py-1'
                    }
                    dateFormat={'dd MMM, yyyy'}
                  />
                </p>
                <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
                  <button
                    type="button"
                    onClick={() => goToPreviousDay(id, date)}
                    className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
                  >
                    <span className="sr-only">Previous day</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
                  <button
                    type="button"
                    onClick={() => goToNextDay(id, date)}
                    className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
                  >
                    <span className="sr-only">Next day</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
                {unsavedChanges && !projectActivitySubmission?.locked ? (
                  <div className="flex flex-col items-start items-center">
                    <Button
                      style={isOffline ? { background: '#3F51B5' } : {}}
                      isLoading={loading}
                      isDisabled={isLoadingSubmissions}
                      text={isOffline ? `Save Locally` : 'Save Changes'}
                      onClick={handleSubmit as any}
                    ></Button>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>

        {isOffline ? undefined : <ConnectivityChecker />}

        <div className="w-full gap-x-2 py-2 px-2 grid bg-white gap-y-2 grid-cols-4 md:flex-wrap md:flex md:flex-row">
          {reportDivisions.map((tab) => (
            <div className={`justify-center flex items-center`}>
              <Button
                style={{
                  ...(reportDivision.name?.replace(' *', '') ===
                  tab.name?.replace(' *', '')
                    ? {
                        backgroundColor: 'transparent',
                        color: 'green',
                        borderBottom: '2px solid green',
                      }
                    : { backgroundColor: 'transparent', color: 'black' }),
                  borderRadius: 0,
                  boxShadow: 'none',
                }}
                text={tab.name}
                onClick={() => setReportDivision(tab)}
              />
            </div>
          ))}
        </div>
        {isLoadingSubmissions || loading ? (
          <div className="w-full flex bg-white py-4 justify-center">
            <LoadingSpinner spinnerStyle={{ borderColor: 'black' }} />
          </div>
        ) : null}

        <Modal
          showModal={showOfflineModal}
          setShowModal={setShowOfflineModal}
          completeButtonText={'Continue'}
          onComplete={() => {
            setShowOfflineModal(false);
          }}
          component={
            <p className="text-sm">
              You are now offline. You can make local changes, but remember to
              save them so they can be synced with the server later.
            </p>
          }
        />
        <Modal
          showModal={showUnsavedChangesModal}
          setShowModal={setShowUnsavedChangesModal}
          completeButtonText={'Continue'}
          onComplete={() => {
            navigate(unsavedChangesOnCompletionNav);
            setShowUnsavedChangesModal(false);
            setUnsavedChangesOnCompletionNav('');
          }}
          component={
            <p className="text-sm">
              Looks like you have some unsaved changes. If you continue, you may
              lose them. Do you want to continue?
            </p>
          }
        />
        <Modal
          showModal={showLocalSyncModal}
          setShowModal={setShowLocalSyncModal}
          completeButtonText={failedOnlineSync ? 'Try again' : 'Sync'}
          onComplete={async () => {
            try {
              setShowLocalSyncModal(false);
              await syncLocalChanges();
              setFailedOnlineSync(false);
              addToast('Sync successful', 'success');
            } catch (err) {
              console.error(err);
              setFailedOnlineSync(true);
              setShowLocalSyncModal(true);
              addToast('Failed sync', 'error');
            }
          }}
          component={
            <p className="text-sm">
              {failedOnlineSync
                ? "Oh no, that wasn't supposed to happen. Are you sure you're online and have an internet connection? Try again?"
                : 'You have local changes that need to be synchronised with our server, do you want to do this now?'}
            </p>
          }
        />
        {reportDivision.name.includes('Activities') ? (
          <ActivitySubmission
            teams={teams}
            selectedProject={selectedProject}
            date={date}
            locked={projectActivitySubmission?.locked}
            activities={activities}
            setActivities={setActivities}
          />
        ) : null}
        {reportDivision.name.includes('Conditions') ? (
          <ConditionSubmission
            selectedProject={selectedProject}
            date={date}
            locked={projectActivitySubmission?.locked}
            conditions={conditions}
            setConditions={setConditions}
          />
        ) : null}
        {reportDivision.name?.includes('HSE') ? (
          <IncidentSubmission
            selectedProject={selectedProject}
            date={date}
            locked={projectActivitySubmission?.locked}
            incidents={incidents?.sort((a: any, b: any) =>
              a.name?.localeCompare(b.name)
            )}
            setIncidents={setIncidents}
          />
        ) : null}
        {reportDivision.name?.includes('Personnel') ? (
          <PersonnelSubmission
            selectedProject={selectedProject}
            date={date}
            locked={projectActivitySubmission?.locked}
            personnel={[...personnel]?.sort((a: any, b: any) =>
              a.name?.localeCompare(b.name)
            )}
            setPersonnel={setPersonnel}
          />
        ) : null}
        {reportDivision.name?.includes('Summary') ? (
          <div className="flex flex-col py-4 bg-gray-50 px-10 gap-y-2">
            <hr />
            <h3 className="text-sm">Today's Summary</h3>
            <TextArea
              rows={8}
              value={summary ?? ''}
              isDisabled={projectActivitySubmission?.locked}
              handleChange={(value) => {
                setSummary(value);
                setSummaryHasChanges(true);
              }}
              validation={''}
              question={{
                id: '',
                title: '',
                placeholder: '',
                name: '',
                description: '',
                defaultText: '',
                classNames: '',
              }}
            />
            <hr />
            <h3 className="text-sm">Next 24 Hours</h3>
            <TextArea
              rows={8}
              value={plan ?? ''}
              isDisabled={projectActivitySubmission?.locked}
              handleChange={(value) => {
                setPlan(value);
                setSummaryHasChanges(true);
              }}
              validation={''}
              question={{
                id: '',
                title: '',
                placeholder: '',
                name: '',
                description: '',
                defaultText: '',
                classNames: '',
              }}
            />
          </div>
        ) : null}
        {reportDivision.name?.includes('Status') ? (
          <div className="w-full">
            <div className=" flex flex-col items-end w-full px-2 py-2 bg-white">
              <Button
                text={
                  <div className="flex gap-x-2">
                    {!isOutcomeStatusExpanded ? (
                      <>
                        <ArrowsPointingOutIcon className="w-5 h-5" /> Expand
                      </>
                    ) : (
                      <>
                        <ArrowsPointingInIcon className="w-5 h-5" /> Collapse
                      </>
                    )}
                  </div>
                }
                onClick={() =>
                  setIsOutcomeStatusExpanded(!isOutcomeStatusExpanded)
                }
              />
            </div>
            <OutcomeStatus
              data={statusReport}
              locked={projectActivitySubmission?.locked}
              isExpanded={isOutcomeStatusExpanded}
              setHasChanged={setStatusHasChanges}
              setData={setStatusReport}
            />
          </div>
        ) : null}
        {reportDivision.name?.includes('Attachments') ? (
          !isOffline ? (
            <SaveProgressAttachment
              refetch={fetchProgressSubmissions}
              progressSubmissionId={projectActivitySubmission?.id}
              outcomeId={outcomeId}
              projectId={id}
              locked={projectActivitySubmission?.locked}
              date={date}
            />
          ) : (
            <div className="px-4 py-2 bg-white text-sm">
              Not available in offline mode
            </div>
          )
        ) : null}
      </div>
    </>
  );
};
