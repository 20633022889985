import { useState } from 'react';

export const useProgressSubmissionState = (progressSubmission: any) => {
  const [activities, setActivities] = useState(
    progressSubmission?.pae ?? ([] as any)
  );
  const [conditions, setConditions] = useState(
    progressSubmission?.pce ?? ([] as any)
  );
  const [incidents, setIncidents] = useState(
    progressSubmission?.pie ?? ([] as any)
  );
  const [personnel, setPersonnel] = useState(
    progressSubmission?.ppe ?? ([] as any)
  );
  const [summary, setSummary] = useState(
    progressSubmission?.comments?.find(
      (comment: any) => comment.id === 'SUMMARY'
    )?.value
  );
  const [plan, setPlan] = useState(
    progressSubmission?.comments?.find((comment: any) => comment.id === 'PLAN')
      ?.value
  );

  return {
    activities,
    setActivities,
    conditions,
    setConditions,
    incidents,
    setIncidents,
    personnel,
    setPersonnel,
    summary,
    setSummary,
    plan,
    setPlan,
  };
};
