import { gql } from '@apollo/client';

export const SEARCH_LABOUR_RESOURCES = gql`
  query searchLabourResources(
    $searchTerm: String
    $labourType: String
    $skillTypeIds: [ID]
    $certificationTypeIds: [ID]
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    searchLabourResources(
      searchTerm: $searchTerm
      labourType: $labourType
      skillTypeIds: $skillTypeIds
      certificationTypeIds: $certificationTypeIds
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        firstName
        lastName
        email
        type
        userProfile {
          certifications {
            id
            certificationType {
              id
              name
            }
            expiryDate
          }
          skills {
            skillType {
              id
              name
            }
            rating
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;
