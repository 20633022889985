import * as XLSX from 'xlsx';

export const useExcel = () => {
  const downloadExcel = (
    data: any,
    sheetTitle?: string,
    headerMapping?: any
  ) => {
    console.log(data);
    const worksheet = XLSX.utils.json_to_sheet(
      data.map((row: any) => {
        let newRow: any = {};
        Object.keys(row).forEach((key) => {
          newRow[headerMapping ? headerMapping[key] : key] = row[key];
        });
        return newRow;
      })
    );

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `${sheetTitle || 'data'}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return { downloadExcel };
};
