import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';

import moment from 'moment';
import { ActiveBadge, DisabledBadge } from '../../../components/Badges/Badges';
import { GET_MY_ASSIGNMENTS } from '../../../graphql/queries/my-profile';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';

interface MyAssignmentListProps {
  children?: React.ReactElement;
}

const headerMapping = {
  name: 'Assignee Name',
  status: 'Status',
  type: 'Type',
  updatedAt: 'Updated At',
} as Record<string, any>;

const MyAssignmentsTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
  filterText,
  setFilterText = () => {},
  selectedFilterDimension,
  setSelectedFilterDimension = () => {},
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    showFilterOptions
    filterDimensions={[
      { id: 'projectName', name: 'Project Name' },
      { id: 'roleName', name: 'Role Name' },
    ]}
    filterText={filterText}
    setFilterText={setFilterText}
    selectedFilterDimension={selectedFilterDimension}
    setSelectedFilterDimension={setSelectedFilterDimension}
    RowComponent={({ id, rowData }) => (
      <div
        key={`${id}_assigneeRow`}
        className={`border-b flex flex-col w-full justify-around items-center lg:flex-row border-gray-200 hover:bg-gray-100`}
      >
        <div
          key={`${id}_project`}
          className="py-3 px-6 text-sm text-left w-full"
        >
          <div className="flex flex-col flex-wrap gap-y-2 gap-x-2 mt-2">
            Project: {rowData?.project}
            {rowData?.projectStartAndEndDate ? (
              <p className="text-gray-500">{rowData?.projectStartAndEndDate}</p>
            ) : null}
          </div>
        </div>
        <div
          key={`${id}_roles`}
          className="py-3 px-6 text-sm text-left items-center gap-x-2 text-black w-full"
        >
          Roles:
          <div className="flex flex-wrap gap-y-2 gap-x-2 mt-2">
            {rowData?.activeRoles.length > 0
              ? rowData?.activeRoles.map((role: any) => (
                  <ActiveBadge text={role} />
                ))
              : null}
            {rowData?.activeRoles.length === 0 ? (
              <p className="text-gray-400">No Active Roles</p>
            ) : null}
            {rowData?.inactiveRoles.length > 0
              ? rowData?.inactiveRoles.map((role: any) => (
                  <DisabledBadge text={role} />
                ))
              : null}
          </div>
        </div>
      </div>
    )}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
  />
);

export const MyAssignmentListPage = ({ children }: MyAssignmentListProps) => {
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [filterText, setFilterText] = useState<string>();
  const [selectedFilterDimension, setSelectedFilterDimension] =
    useState<string>('projectName');

  const { limit, offset, setOffset } = usePagination();

  const [debouncedFilterText, setDebouncedSearchTerm] = useState(filterText);

  const filter = debouncedFilterText
    ? {
        filters: [
          {
            field: selectedFilterDimension,
            stringFilters: [{ ilike: debouncedFilterText }],
          },
        ],
      }
    : null;
  const [fetch, { data }] = useLazyQuery(GET_MY_ASSIGNMENTS, {
    variables: { input: { limit, offset } },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetch({
      variables: { input: { limit, offset, ...(filter ? { filter } : {}) } },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, debouncedFilterText]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setOffset(0);
      setDebouncedSearchTerm(filterText);
    }, 600);
    return () => {
      clearTimeout(handler);
    };
  }, [filterText]);

  const myAssignmentResults = data?.getMyAssignments;

  const totalPages = Math.ceil(myAssignmentResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  return (
    <div className="bg-gray-50">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="flex flex-col md:items-center md:flex-row w-full md:justify-between px-4 py-5 sm:px-6 flex-wrap">
          <div className="flex flex-col">
            <h1 className="text-3xl font-extrabold text-gray-900">
              My Assignments
            </h1>
          </div>
        </div>
        <div>
          <MyAssignmentsTable
            currentPage={Math.floor(offset / limit) + 1}
            totalPages={totalPages}
            itemsPerPage={limit}
            totalResults={myAssignmentResults?.count || 0}
            onClickRow={(id: any) => {
              const matchingAssignment = myAssignmentResults?.results.find(
                (assignment: any) => assignment.id === id
              );
              navigate(
                `/projects/${matchingAssignment?.project?.id}/assignments/${id}`
              );
            }}
            filterText={filterText}
            setFilterText={setFilterText}
            selectedFilterDimension={selectedFilterDimension}
            setSelectedFilterDimension={setSelectedFilterDimension}
            data={
              myAssignmentResults?.results.length > 0
                ? myAssignmentResults?.results.map((assignment: any) => ({
                    id: assignment.id,
                    name: `${assignment.lrs?.lastName}, ${assignment.lrs?.firstName}`,
                    project: `${assignment.project.name} - ${assignment.project.internalId}`,
                    projectStartAndEndDate: assignment?.project?.startDate
                      ? `${moment(
                          Number(assignment?.project?.startDate)
                        ).format('ll')} - ${moment(
                          Number(assignment?.project?.endDate)
                        ).format('ll')}`
                      : undefined,
                    type:
                      assignment.lrs?.type === 'EMPLOYEE'
                        ? 'Employee'
                        : 'Subcontractor',
                    activeRoles:
                      assignment?.assignmentRoleSummary.activeRoles.map(
                        (role: any) => role.name
                      ),
                    inactiveRoles:
                      assignment?.assignmentRoleSummary.inactiveRoles.map(
                        (role: any) => role.name
                      ),
                  }))
                : [{}]
            }
            onPageChange={handlePageChange}
          />

          {children}
        </div>
      </div>
    </div>
  );
};

export const MyAssignmentList = ({}: MyAssignmentListProps) => (
  <PaginationProvider>
    <MyAssignmentListPage />
  </PaginationProvider>
);
