import { gql } from '@apollo/client';

export const GET_ACTIVITY_CODES_SUMMARY = gql`
  query getActivityCodes(
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    getActivityCodes(input: $input, activeOrganisation: $activeOrganisation) {
      count
      results {
        id
        name
        code
        color
        enabled
        description
        updatedAt
      }
    }
  }
`;

export const GET_ACTIVITY_CODES = gql`
  query getActivityCodes(
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    getActivityCodes(input: $input, activeOrganisation: $activeOrganisation) {
      count
      results {
        id
        name
        code
        internalId
        color
        useResourceDefaultRate
        enabled
        description
        createdAt
        updatedAt
      }
    }
  }
`;
