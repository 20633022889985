import { useEffect, useRef, useState } from 'react';
import { Modal } from '../../../../components/Dialogue/ModalDialogue';
import TextArea from '../../../../components/Inputs/TextArea';
import { useIndexedDB } from '../../../../hooks/useIndexedDB';
import { IncidentEntry } from '../incidents/entry';

const mergeIncidentsAndTypes = (incidentTypes: any[], incidents: any[]) => {
  const merged = {};

  incidentTypes?.forEach((type) => {
    // @ts-ignore
    merged[type.id] = { ...type };
  });

  incidents?.forEach((incident) => {
    // @ts-ignore
    if (merged[incident.id]) {
      // @ts-ignore
      merged[incident.id] = { ...merged[incident.id], ...incident };
    } else {
      // @ts-ignore
      merged[incident.id] = { ...incident };
    }
  });

  return Object.values(merged);
};

export const IncidentSubmission = ({
  selectedProject,
  incidents,
  setIncidents,
  locked,
  date,
}: any) => {
  const bottomRef = useRef(null);

  const handleIncidentChange = (
    incidentId?: string,
    field?: string,
    value?: any
  ) => {
    if (!incidentId || !field) return;

    const updatedIncidents = incidents.map((incident: any) => {
      if (incident.id !== incidentId) return incident;

      const updatedIncident = {
        ...incident,
        [field]: value,
        savedLocally: false,
      };

      if (!incident.isNew && !incident.toBeDeleted) {
        updatedIncident.hasChanged = true;
        updatedIncident.original = incident;
      }

      return updatedIncident;
    });

    setIncidents(updatedIncidents);
  };

  const [modalIncidentId, setModalIncidentId] = useState<string | undefined>(
    undefined
  );

  const [commentBoxState, setCommentBoxState] = useState('');

  const { db, readItem: readLocalData } = useIndexedDB({
    objectStoreName: `cached_project_data`,
  });

  const fetchLocalIncidentTypes = async () => {
    const item = await readLocalData(`incidentTypes_${selectedProject?.id}`);
    const incidentTypeResults = item?.incidentTypeResults;
    setIncidents(mergeIncidentsAndTypes(incidentTypeResults, incidents));
  };

  useEffect(() => {
    if (modalIncidentId) {
      setCommentBoxState(
        incidents?.find((incident: any) => incident?.id === modalIncidentId)
          ?.comment
      );
    }
  }, [modalIncidentId]);

  useEffect(() => {
    if (db && selectedProject?.id) {
      fetchLocalIncidentTypes();
    }
  }, [db, selectedProject?.id, date, incidents?.length]);

  return (
    <>
      <div>
        <Modal
          showModal={!!modalIncidentId}
          setShowModal={setModalIncidentId}
          completeButtonText={'Complete'}
          title={`Comment #${modalIncidentId}`}
          onComplete={() => {
            if (!locked) {
              handleIncidentChange(modalIncidentId, 'comment', commentBoxState);
            }
            setModalIncidentId(undefined);
          }}
          component={
            <TextArea
              rows={8}
              value={commentBoxState ?? ''}
              handleChange={(value) => setCommentBoxState(value)}
              validation={''}
              isDisabled={locked}
              question={{
                id: '',
                title: '',
                placeholder: '',
                name: '',
                description: '',
                defaultText: '',
                classNames: '',
              }}
            />
          }
        />
        <hr />
        <div className="bg-white px-4 pt-2 flex flex-col gap-y-4">
          <IncidentEntry
            date={date}
            locked={locked}
            projectId={selectedProject?.id}
            handleIncidentChange={handleIncidentChange}
            incidents={incidents}
            setModalIncidentId={setModalIncidentId}
          />
        </div>

        <div ref={bottomRef} />
      </div>
    </>
  );
};
