import { gql } from '@apollo/client';

export const SAVE_PROJECT = gql`
  mutation saveProject(
    $id: ID
    $input: SaveProjectInput!
    $activeOrganisation: String!
  ) {
    saveProject(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const SAVE_PROJECT_OUTCOME = gql`
  mutation saveProjectOutcome(
    $id: ID
    $input: OutcomeInput!
    $activeOrganisation: String!
  ) {
    saveProjectOutcome(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const SAVE_PROJECT_TEAM = gql`
  mutation saveProjectTeam(
    $id: ID
    $input: SaveProjectTeamInput!
    $activeOrganisation: String!
  ) {
    saveProjectTeam(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const SAVE_PROJECT_ACTIVITY_TYPE = gql`
  mutation saveProjectActivityType(
    $id: ID
    $input: SaveProjectActivityTypeInput!
    $activeOrganisation: String!
  ) {
    saveProjectActivityType(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const SAVE_PROJECT_ACTIVITY_GROUP = gql`
  mutation saveProjectActivityGroup(
    $id: ID
    $input: SaveProjectActivityGroupInput!
    $activeOrganisation: String!
  ) {
    saveProjectActivityGroup(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const SAVE_PROJECT_LOCATION_ASSETS = gql`
  mutation saveProjectLocationAssets(
    $input: ProjectLocationAssetInput!
    $activeOrganisation: String!
  ) {
    saveProjectLocationAssets(
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const SAVE_PROJECT_LOCATIONS = gql`
  mutation saveProjectLocations(
    $input: ProjectLocationInput!
    $activeOrganisation: String!
  ) {
    saveProjectLocations(
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const SAVE_PROJECT_ACTIVITIES = gql`
  mutation saveProjectActivities(
    $input: ProjectActivityBulkInput!
    $activeOrganisation: String!
  ) {
    saveProjectActivities(
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const SAVE_PROJECT_COMPANY = gql`
  mutation saveProjectCompany(
    $id: ID
    $input: SaveProjectCompanyInput!
    $activeOrganisation: String!
  ) {
    saveProjectCompany(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const DELETE_PROJECT_TEAM = gql`
  mutation deleteProjectTeam($id: ID!, $activeOrganisation: String!) {
    deleteProjectTeam(id: $id, activeOrganisation: $activeOrganisation) {
      success
      message
    }
  }
`;
