import { gql } from '@apollo/client';

export const SAVE_ROLE = gql`
  mutation saveRole(
    $id: ID
    $input: SaveRoleInput!
    $activeOrganisation: String!
  ) {
    saveRole(id: $id, input: $input, activeOrganisation: $activeOrganisation) {
      success
      message
    }
  }
`;
