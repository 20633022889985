import React from 'react';
import { InternalIdFilter } from './employees/list';
import { Question } from '../../types/QuestionTypes';

interface RadioGroupParams {
  value: any;
  question: Question;
  handleChange: (value: any) => void;
}

interface SettingsDialogProps {
  title: string;
  setPendingValue: (filter: InternalIdFilter) => void;
  pendingValue: InternalIdFilter;
}

const RadioGroup = ({ value, question, handleChange }: RadioGroupParams) => {
  const { title, options } = question;
  return (
    <div className="pt-4">
      <label className="px-4 block text-base font-medium text-gray-700">
        {title ? title : null}
      </label>
      <div className="my-2 flex-col sm-flex-row items-start">
        {options?.map((option: any) => (
          <label
            key={option.name}
            className="inline-flex gap-x-1 px-4 items-center cursor-pointer"
          >
            <input
              type="radio"
              name={title}
              value={value}
              onChange={() => handleChange(option.name)}
              checked={value === option.name}
              className="text-primary-500 focus:ring-primary-500"
            />
            <span>{option.name}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

export const SettingsDialog: React.FC<SettingsDialogProps> = ({
  title,
  setPendingValue,
  pendingValue,
}) => {
  return (
    <RadioGroup
      question={{
        id: `${title}`,
        title: `${title}`,
        options: [
          {
            id: '1',
            name: 'Configured',
            value: InternalIdFilter.CONFIGURED,
          },
          {
            id: '2',
            name: 'Not Configured',
            value: InternalIdFilter.NOT_CONFIGURED,
          },
          {
            id: '3',
            name: `Show all`,
            value: InternalIdFilter.SHOW_ALL,
          },
        ],
        classNames: 'w-full',
      }}
      value={
        pendingValue === InternalIdFilter.CONFIGURED
          ? 'Configured'
          : pendingValue === InternalIdFilter.NOT_CONFIGURED
          ? 'Not Configured'
          : `Show all`
      }
      handleChange={(value: any) => {
        setPendingValue(
          value === 'Configured'
            ? InternalIdFilter.CONFIGURED
            : value === 'Not Configured'
            ? InternalIdFilter.NOT_CONFIGURED
            : InternalIdFilter.SHOW_ALL
        );
      }}
    />
  );
};
