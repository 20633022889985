import { GridLayout } from '../../../components/Grid/GridLayout';
import { Card } from '../../../components/Layout/Card';
import { ModuleItemSummary } from '../../../components/ModuleItemSummary/ModuleItemSummary';
// @ts-ignore

import {
  ChartPieIcon,
  ClockIcon,
  UserIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { TableCellsIcon } from '@heroicons/react/24/solid';
import { pdf } from '@react-pdf/renderer';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  BlueBadge,
  GeneratingBadge,
  GreenBadge,
  RedBadge,
  YellowBadge,
} from '../../../components/Badges/Badges';
import { Button } from '../../../components/Buttons/Button';
import DatePicker from '../../../components/Inputs/DatePicker';
import Map from '../../../components/Map/Map';
import NoProjects from '../../../components/NoData/NoProjects';
import PDFDocument from '../../../components/pdf/PDFDocument';
import { useExcel } from '../../../components/xlsx/useExcel';
import { ActiveOrganisationContext } from '../../../context/ActiveOrganisationContext';
import {
  GET_PROJECTS_COUNT,
  GET_PROJECT_ACTIVITY_TYPES,
  SEARCH_PROJECTS,
  SEARCH_PROJECT_OUTCOMES_DETAILED,
} from '../../../graphql/queries/projects';
import { GET_STATISTICS } from '../../../graphql/queries/statistics';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { ModuleItemCard } from '../../../types/ModuleItemSummary';
import { ProjectSelection } from '../../TimeTracking/timesheet/project/search';
import ActivityTypeChart from './components/ActivityTypeChart';
import { LocationProgressChart } from './components/LocationProgressChart';
import { OutcomeLocationsTable } from './components/OutcomePerLocation';
import ProgressByLocationChart from './components/ProgressByLocationChart';
// @ts-ignore
import { saveAs } from 'file-saver';

interface ProgressDashboardHomeProps {
  children?: React.ReactElement;
}

const Table = ({
  data,
  headings,
  labelColumn = false,
  labelColumnName,
}: any) => {
  return (
    <div className="overflow-x-auto relative">
      <table className="w-full text-sm text-left text-gray-700">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th scope="col" className="py-3 px-6">
              {headings[0]}
            </th>
            {labelColumn ? (
              <th scope="col" className="py-3 px-6">
                {labelColumnName}
              </th>
            ) : undefined}
            <th scope="col" className="py-3 px-6">
              {headings[1]}
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((location: any) => (
            <tr key={location.name} className="bg-white border-b ">
              <td className="py-4 px-6">
                <BlueBadge text={location.name} />
              </td>
              {labelColumn ? (
                <td className="py-4 px-6">{location.labelColumn}</td>
              ) : null}
              <td className="py-4 px-6">{location.hours?.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const DownloadToXLSXButton = ({ onClick }: any) => (
  <div
    role="button"
    onClick={onClick}
    className="flex  bg-white rounded-md px-4 py-2 rounded-md border items-center gap-x-2"
  >
    <TableCellsIcon className="w-5 h-5" />
    <h4 className="text-sm">Download as XLSX</h4>
  </div>
);

const RoleGroupTable = ({ data }: any) => {
  return (
    <div className="overflow-x-auto relative">
      <table className="w-full text-sm text-left text-gray-700">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th scope="col" className="py-3 px-6">
              Role Name
            </th>
            <th scope="col" className="py-3 px-6">
              Group Name
            </th>
            <th scope="col" className="py-3 px-6">
              Total Man Hours
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((role: any) =>
            role.groups.map((group: any) => (
              <tr
                key={`${role.roleId}-${group.groupId}`}
                className="bg-white border-b"
              >
                <td className="py-4 px-6">
                  <BlueBadge text={role.roleName} />
                </td>
                <td className="py-4 px-6">{group.groupName}</td>
                <td className="py-4 px-6">{group.hours.toFixed(2)}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

const OutcomeTable = ({ data, headings }: any) => {
  const [showMoreLocations, setShowMoreLocations] = useState<
    string | undefined
  >(undefined);
  return (
    <div className="overflow-x-auto relative">
      <table className="w-full text-sm text-left text-gray-700">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th scope="col" className="py-3 px-6">
              {headings[0]}
            </th>
            <th scope="col" className="py-3 px-6">
              {headings[1]}
            </th>
            <th scope="col" className="py-3 px-6">
              {headings[2]}
            </th>
            <th scope="col" className="py-3 px-6">
              {headings[3]}
            </th>
            <th scope="col" className="py-3 px-6">
              {headings[4]}
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((location: any) => {
            const remainingHours = Number(
              (location.estimatedHours - location.actualHours)?.toFixed(2)
            );
            const remainingPersonHours = Number(
              (
                location.estimatedPersonHours - location.actualPersonHours
              )?.toFixed(2)
            );

            const panelId = `${location.phaseName}-${location.activityGroupName}`;
            const locationsPreview =
              showMoreLocations && showMoreLocations === panelId
                ? location.locations
                : location.locations?.slice(0, 12);
            const isUnscoped = location.phaseName === 'Unscoped';
            return (
              <tr key={panelId} className="bg-white border-b ">
                <td className="py-4 px-6">
                  {isUnscoped ? (
                    <YellowBadge text="Unscoped Activities" />
                  ) : (
                    location.activityGroupName
                  )}
                </td>
                <td className="py-4 px-6">
                  {isUnscoped ? (
                    <YellowBadge text="Unscoped" />
                  ) : (
                    location.phaseName
                  )}
                </td>
                <td className="py-4 px-6">
                  <div className="grid grid-cols-1 items-center justify-center lg:grid-cols-3 xl:grid-cols-4 gap-x-2 gap-y-2">
                    {locationsPreview.map((location: any) => (
                      <BlueBadge text={location.name} />
                    ))}
                  </div>
                  {location.locations?.length > 12 && (
                    <div
                      role="button"
                      onClick={() =>
                        setShowMoreLocations(
                          showMoreLocations === panelId ? undefined : panelId
                        )
                      }
                      className="text-sm mt-4 text-gray-500 hover:text-blue-700"
                    >
                      {showMoreLocations === panelId
                        ? 'Less'
                        : `+ ${location?.locations?.length - 12} more...`}
                    </div>
                  )}
                </td>
                <td className="py-4 px-6">
                  <div className="grid grid-cols-1 xl:grid-cols-3 justify-center gap-y-2 gap-x-2">
                    {isUnscoped ? null : (
                      <div className="flex flex-col items-center gap-y-2">
                        <label className="text-xs">Estimate: </label>
                        {location.estimatedHours?.toFixed(2)}
                      </div>
                    )}
                    <div className="flex flex-col items-center gap-y-2">
                      <label className="text-xs">Actual: </label>
                      {location.actualHours?.toFixed(2)}
                    </div>
                    {isUnscoped ? null : (
                      <div className="flex flex-col items-center gap-y-2">
                        <label className="text-xs">Remaining: </label>
                        {remainingHours > 0 ? (
                          <GreenBadge text={`+${remainingHours}`} />
                        ) : (
                          <RedBadge text={`${remainingHours}`} />
                        )}
                      </div>
                    )}
                  </div>
                </td>
                <td className="py-4 px-6">
                  <div className="grid grid-cols-1 xl:grid-cols-3 justify-center gap-y-2 gap-x-2">
                    {isUnscoped ? null : (
                      <div className="flex flex-col items-center gap-y-2">
                        <label className="text-xs">Estimate: </label>
                        {location.estimatedPersonHours?.toFixed(2)}
                      </div>
                    )}
                    <div className="flex flex-col items-center gap-y-2">
                      <label className="text-xs">Actual: </label>
                      {location.actualPersonHours?.toFixed(2)}
                    </div>
                    {isUnscoped ? null : (
                      <div className="flex flex-col items-center gap-y-2">
                        <label className="text-xs">Remaining: </label>
                        {remainingPersonHours > 0 ? (
                          <GreenBadge text={`+${remainingPersonHours}`} />
                        ) : (
                          <RedBadge text={`${remainingPersonHours}`} />
                        )}
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

function sumUnscopedHours(data: any) {
  let totalActualHours = 0;
  let totalActualPersonHours = 0;

  if (data) {
    const performanceData = data;
    performanceData.forEach((item: any) => {
      if (item.outcomeId === 'Unscoped') {
        totalActualHours += item.actualHours;
        totalActualPersonHours += item.actualPersonHours;
      }
    });
  }

  return {
    totalActualHours,
    totalActualPersonHours,
  };
}

export const ProgressDashboardHome = (props: ProgressDashboardHomeProps) => {
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [startDate, setStartDate] = useState(
    moment().subtract(3, 'days').toDate()
  );
  const [endDate, setEndDate] = useState(moment().add(3, 'days').toDate());
  const [searchTerm, setSearchTerm] = useState('');

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  const [selectedProject, setSelectedProject] = useState<any>({});
  const [selectedOutcome, setSelectedOutcome] = useState<any>({});

  const { activeOrganisation } = useContext(ActiveOrganisationContext);

  const [chartRefs, setChartRefs] = useState<any[]>([]);

  const [isRenderingPdf, setIsRenderingPdf] = useState(false);
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

  const [fetchProjects, { loading: loadingProjects, data: projectsData }] =
    useLazyQuery(SEARCH_PROJECTS, {
      variables: { searchTerm: debouncedSearchTerm },
      fetchPolicy: 'network-only',
    });

  const [
    fetchProjectsCount,
    { data: projectsCountData, loading: loadingProjectsCount },
  ] = useLazyQuery(GET_PROJECTS_COUNT, {
    variables: {},
    fetchPolicy: 'network-only',
  });

  const [fetchOutcomes, { loading: loadingOutcomes, data: outcomesData }] =
    useLazyQuery(SEARCH_PROJECT_OUTCOMES_DETAILED, {
      variables: { searchTerm: '', projectId: selectedProject?.id },
      fetchPolicy: 'network-only',
    });

  const [
    fetchActivities,
    { loading: loadingActivities, data: activitiesData },
  ] = useLazyQuery(GET_PROJECT_ACTIVITY_TYPES, {
    variables: { projectId: selectedProject?.id },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 600); // 300ms debounce time
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    fetchProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (selectedProject?.id) {
      setSelectedOutcome({ name: '' });
      fetchOutcomes();
      fetchActivities();
    }
  }, [fetchOutcomes, selectedProject?.id]);

  useEffect(() => {
    fetchProjectsCount();
  }, [fetchProjectsCount]);

  const [fetch, { loading: loadingStatistics, data }] = useLazyQuery(
    GET_STATISTICS,
    {
      variables: {
        query: [
          {
            calculation: 'progress',
            startDate,
            endDate,
            projectId: selectedProject?.id,
            outcomeId: selectedOutcome?.id,
          },
        ],
      },
      fetchPolicy: 'network-only',
    }
  );

  const formatDate = (date: any) =>
    date.toISOString().split('T')[0].replace(/-/g, '_');

  const statistics = data?.getStatistics?.progress || {};

  useEffect(() => {
    if (selectedProject?.id) {
      fetch();
    }
  }, [selectedProject?.id, selectedOutcome.id, startDate, endDate, fetch]);

  const generatePdfReport = async () => {
    const charts = [];
    // @ts-ignore
    chartRefs?.[0]?.render();
    setIsGeneratingPdf(true);
    await new Promise((r) => setTimeout(r, 2000));

    for (let i = 0; i < chartRefs.length; i++) {
      const chartRef = chartRefs?.[i];
      // @ts-ignore
      const imageUrl = chartRef?.ref?.current?.toBase64Image();
      // @ts-ignore
      charts.push({
        image: imageUrl,
        title: chartRef?.title,
        table: dashboardWidgets?.find((widget) => widget.id === chartRef?.id)
          ?.table,
        imageStyle: {},
      });
    }
    chartRefs?.[0]?.complete();

    const blob = await pdf(
      <PDFDocument
        startDate={startDate}
        endDate={endDate}
        projectName={`${selectedProject?.name}${
          selectedOutcome?.name ? ` - ${selectedOutcome?.name}` : ''
        }`}
        activeOrganisation={activeOrganisation}
        charts={charts}
      />
    ).toBlob();

    saveAs(
      blob,
      `${activeOrganisation?.companyName}${moment(startDate).format(
        'MM_YYYY'
      )}-${moment(endDate).format('MM_YYYY')}.pdf`
    );

    setIsGeneratingPdf(false);
  };

  useEffect(() => {
    const externalChartRefs: any = [];
    if (dashboardWidgets.length > 0) {
      dashboardWidgets.forEach((widget) => {
        const ref = React.createRef();
        externalChartRefs.push({
          id: widget.id,
          title: widget.name,
          ref: ref,
          complete: () => {
            setIsRenderingPdf(false);
          },
          render: () => {
            setIsRenderingPdf(true);
          },
        });
      });
      setChartRefs(externalChartRefs);
    }
  }, []);

  const { downloadExcel } = useExcel();

  const dashboardWidgets = [
    {
      id: 'PROGRESS_BY_LOCATION',
      name: 'Progress By Location',
      table: {
        data: statistics?.progressByLocation?.flatMap((entry: any) =>
          entry.progressByLocation.map((location: any) => ({
            date: entry.date,
            location: location.name,
            progress: location.progress,
          }))
        ),
        headerMapping: {
          date: 'Date',
          location: 'Location',
          progress: 'Progress',
        },
      },
    },
    {
      id: 'PERFORMANCE_AGAINST_OUTCOME',
      name: 'Performance Against Scope',
      table: {
        data: (statistics?.performanceAgainstOutcome ?? [])
          ?.map((item: any) => {
            if (item.outcomeId === 'Unscoped') {
              const { totalActualHours, totalActualPersonHours } =
                sumUnscopedHours(
                  statistics?.performanceAgainstOutcomeByLocation
                );
              return {
                ...item,
                actualHours: totalActualHours,
                actualPersonHours: totalActualPersonHours,
              };
            }
            return item;
          })
          .map((entry: any) => ({
            ...entry,
            count: undefined,
            outcomeId: undefined,
            locations: entry.locations
              .map((location: any) => location.name)
              .join(', '),
          })),
        headerMapping: {
          phaseName: 'Phase Name',
          activityGroupName: 'Activity Group Name',
          estimatedHours: 'Estimated Hours',
          estimatedPersonHours: 'Estimated Man Hours',
          actualHours: 'Actual Hours',
          actualPersonHours: 'Actual Man Hours',
          locations: 'Locations',
        },
      },
    },
    {
      id: 'TOTAL_HOURS_PER_LOCATION',
      name: 'Total Hours (Duration) Per Location',
      table: {
        data: statistics?.totalHoursPerLocation ?? [],
        headerMapping: {
          name: 'Location',
          latitude: 'Latitude',
          longitude: 'Longitude',
          hours: 'Hours',
        },
      },
    },
    {
      id: 'TOTAL_MAN_HOURS_PER_ROLE',
      name: 'Total Man Hours Per Role',
      table: {
        data: statistics?.totalPersonHoursPerRole ?? [],
        headerMapping: {
          name: 'Role Name',
          hours: 'Hours',
        },
      },
    },
    {
      id: 'PERFORMANCE_AGAINST_SCOPE_BY_LOCATION',
      name: 'Performance Against Scope By Location',
      table: {
        data: (statistics?.performanceAgainstOutcomeByLocation ?? []).map(
          (entry: any) => ({
            ...entry,
            count: undefined,
            outcomeId: undefined,
            locationId: undefined,
          })
        ),
        headerMapping: {
          phaseName: 'Phase Name',
          activityGroupName: 'Activity Group Name',
          estimatedHours: 'Estimated Hours',
          estimatedPersonHours: 'Estimated Man Hours',
          actualHours: 'Actual Hours',
          actualPersonHours: 'Actual Man Hours',
          averageProgress: 'Average Progress',
          latitude: 'Latitude',
          longitude: 'Longitude',
          locationName: 'Location',
        },
      },
    },
    {
      id: 'TOTAL_MAN_HOURS_PER_ROLE_BY_ACTIVITY',
      name: 'Total Man Hours Per Role By Activity Category',
      table: {
        data: (statistics?.roleHoursPerGroup ?? []).flatMap((role: any) =>
          role.groups.map((group: any) => ({
            roleName: role.roleName,
            groupName: group.groupName,
            hours: group.hours,
          }))
        ),
        headerMapping: {
          roleName: 'Role Name',
          groupName: 'Activity Category',
          hours: 'Hours',
        },
      },
    },
    {
      id: 'TOTAL_HOURS_PER_ACTIVITY',
      name: 'Total Hours (Duration) Per Activity',
      table: {
        data: statistics?.totalHoursPerActivityType ?? [],
        headerMapping: {
          name: 'Activity Name',
          hours: 'Hours',
        },
      },
    },
    {
      id: 'TOTAL_MAN_HOURS_PER_ACTIVITY',
      name: 'Total Man Hours Per Activity',
      table: {
        data: statistics?.totalPersonHoursPerActivityType ?? [],
        headerMapping: {
          name: 'Activity Name',
          hours: 'Hours',
        },
      },
    },
    {
      id: 'TOTAL_MAN_HOURS_PER_TEAM',
      name: 'Total Man Hours Per Team',
      table: {
        data: statistics?.totalPersonHoursPerTeam ?? [],
        headerMapping: {
          name: 'Team Name',
          hours: 'Hours',
        },
      },
    },
  ];
  // @ts-ignore
  const clonedData = [...(statistics?.progressByLocation ?? [])].sort(
    // @ts-ignore
    (a, b) => new Date(b.date) - new Date(a.date)
  );
  // @ts-ignore
  const sortedData = clonedData.sort(
    // @ts-ignore
    (a, b) => new Date(a.date) - new Date(b.date)
  );

  const sortedAverageProgress = sortedData
    .map((date: any) => {
      const sumProgress = date.progressByLocation.reduce(
        (sum: any, current: any) => Number(sum) + Number(current.progress),
        0
      );
      const locationCount = date.progressByLocation.length;
      return sumProgress / locationCount || 0;
    })
    .filter((entry: any) => entry !== 0);

  const latestOverallProgress = sortedAverageProgress.pop();

  const mockProjectSummary = [
    {
      heading: statistics?.totalPersonHours?.toFixed(2),
      description: 'Total Man Hours',
      colour: '#fff',
      IconComponent: UserIcon,
    },
    {
      heading: statistics?.totalHours?.toFixed(2),
      description: 'Total Hours',
      colour: '#fff',
      IconComponent: ClockIcon,
    },
    {
      heading: latestOverallProgress
        ? `${latestOverallProgress?.toFixed(2)}%`
        : undefined,
      description: 'Latest Overall Progress',
      colour: '#fff',
      IconComponent: ChartPieIcon,
    },
  ] as ModuleItemCard[];

  const projectCount = projectsCountData?.getProjects?.count || 0;

  if (!projectCount) {
    return <NoProjects loading={loadingProjectsCount} />;
  }
  function findUnscopedActivityTypes(outcomeData: any, activityTypesData: any) {
    const originIds = new Set(
      outcomeData?.configuration?.phases?.flatMap((phase: any) =>
        phase.activityGroups.map((activity: any) => activity.originId)
      )
    );

    const unscopedActivityTypes =
      activityTypesData?.getProjectActivityTypes?.results
        ?.filter(
          (activityType: any) =>
            !activityType.pagtmat.some((pagtm: any) =>
              originIds.has(pagtm.pagtm.id)
            )
        )
        .map((activityType: any) => ({
          name: activityType.name,
          id: activityType.id,
        }));

    return unscopedActivityTypes;
  }

  const unscopedActivityTypes = selectedOutcome
    ? findUnscopedActivityTypes(selectedOutcome, activitiesData)
    : [];

  return (
    <div>
      {loadingProjectsCount ||
      loadingStatistics ||
      loadingProjects ||
      loadingOutcomes ? (
        <div className="fixed left-[50%] top-[50%] z-10 bg-gray-50 opacity-70 p-2 rounded-sm">
          <GeneratingBadge />
        </div>
      ) : null}

      <div className="my-2 border-b py-2">
        <div className="flex flex-row justify-end my-2 items-end w-full gap-x-4">
          <DatePicker
            id={'startDate'}
            label="Start Date"
            title="Start Date"
            validation=""
            handleChange={setStartDate}
            value={startDate}
          />
          <DatePicker
            id={'endDate'}
            label="End Date"
            title="End Date"
            validation=""
            handleChange={setEndDate}
            value={endDate}
          />
        </div>
        <div className="flex flex-row items-end w-full gap-x-4">
          <ProjectSelection
            setSearchTerm={setSearchTerm}
            selectedProject={selectedProject}
            projectSearchResults={projectsData?.searchProjects?.results || []}
            setSelectedProject={setSelectedProject}
          />
          <div className="flex w-full items-center">
            <ProjectSelection
              placeholder="Filter by scope"
              setSearchTerm={() => {}}
              selectedProject={selectedOutcome}
              projectSearchResults={
                outcomesData?.searchProjectOutcomes?.results || []
              }
              setSelectedProject={setSelectedOutcome}
            />
            <XCircleIcon
              className="w-5 h-5 mt-1 self-center"
              onClick={() => setSelectedOutcome({ name: '' })}
            />
          </div>
          <div className="w-48 flex justify-end">
            <Button
              disabled={isGeneratingPdf}
              onClick={async () => await generatePdfReport()}
              text={isGeneratingPdf ? 'Generating' : 'Generate PDF'}
            />
          </div>
        </div>
        {outcomesData?.searchProjectOutcomes?.results.length === 0 &&
        selectedProject?.id ? (
          <p className="text-sm p-2 align-middle">
            It looks like there are no scopes for this project.{' '}
            <Link
              to={`/projects/${selectedProject?.id}/outcomes/new`}
              target="_blank"
              className="font-extrabold underline"
            >
              Click here
            </Link>{' '}
            to create one.
          </p>
        ) : null}
      </div>

      <GridLayout>
        <Card classNames={'col-span-3 md:col-span-3'}>
          <ModuleItemSummary moduleItemCards={mockProjectSummary} />
        </Card>

        <Card classNames={'col-span-3 lg:col-span-3'}>
          <div className="flex items-center gap-x-2 w-full justify-between">
            <h3 className="text-sm my-2">Progress By Location</h3>
            <DownloadToXLSXButton
              onClick={() =>
                downloadExcel(
                  dashboardWidgets?.find(
                    (widget) => widget.id === 'PROGRESS_BY_LOCATION'
                  )?.table?.data,
                  'progress_by_location.csv'
                )
              }
            />
          </div>
          <ProgressByLocationChart
            chartId={'PROGRESS_BY_LOCATION'}
            // @ts-ignore
            chartRefs={chartRefs}
            data={statistics?.progressByLocation ?? []}
          />
        </Card>
        <Card classNames={'col-span-3 lg:col-span-3'}>
          <h3 className="text-sm my-2">Total Hours (Duration) Per Location</h3>
          <hr />
          <Map
            markers={statistics?.totalHoursPerLocation?.map((entry: any) => ({
              ...entry,
              detail: `${entry?.hours?.toFixed(2) ?? 0} Hours`,
            }))}
          />
          <Table
            data={statistics?.totalHoursPerLocation}
            headings={['Location Name', 'Total Hours']}
          />
        </Card>
        <Card classNames={'col-span-3 lg:col-span-3'}>
          <div className="flex items-center gap-x-2 w-full justify-between mb-2">
            <h3 className="text-sm my-2">Total Man Hours Per Role</h3>
            <DownloadToXLSXButton
              onClick={() =>
                downloadExcel(
                  statistics?.totalPersonHoursPerRole ?? [],
                  `man_hours_per_role${formatDate(startDate)}_${formatDate(
                    endDate
                  )}.csv`
                )
              }
            />
          </div>
          <hr />
          <Table
            data={statistics?.totalPersonHoursPerRole}
            headings={['Role Name', 'Total Man Hours']}
          />
        </Card>

        <Card classNames={'col-span-3 md:col-span-3'}>
          <div className="flex items-center gap-x-2 w-full justify-between mb-2">
            <h3 className="text-sm my-2">Performance Against Scope</h3>
            <DownloadToXLSXButton
              onClick={() =>
                downloadExcel(
                  dashboardWidgets?.find(
                    (widget) => widget.id === 'PERFORMANCE_AGAINST_OUTCOME'
                  )?.table?.data,
                  'performance_against_scope.csv'
                )
              }
            />
          </div>
          <hr />
          <OutcomeTable
            data={
              statistics?.performanceAgainstOutcome
                ? [
                    ...statistics?.performanceAgainstOutcome?.map(
                      (item: any) => {
                        if (item.outcomeId === 'Unscoped') {
                          const { totalActualHours, totalActualPersonHours } =
                            sumUnscopedHours(
                              statistics?.performanceAgainstOutcomeByLocation
                            );
                          return {
                            ...item,
                            actualHours: totalActualHours,
                            actualPersonHours: totalActualPersonHours,
                          };
                        }
                        return item;
                      }
                    ),
                  ]
                : []
            }
            headings={[
              'Activity Group',
              'Phase(s)',
              'Locations',
              'Hours (Duration)',
              'Man Hours',
            ]}
          />
        </Card>
        <Card classNames={'col-span-3 md:col-span-3'}>
          <h3 className="text-sm my-2">Location Progress by Activity Group</h3>
          <hr />
          <LocationProgressChart
            data={statistics?.performanceAgainstOutcomeByLocation}
          />
        </Card>
        <Card classNames={'col-span-3 md:col-span-3'}>
          <div className="flex items-center gap-x-2 w-full justify-between mb-2">
            <h3 className="text-sm my-2">
              Performance Against Scope By Location
            </h3>
            <DownloadToXLSXButton
              onClick={() =>
                downloadExcel(
                  (statistics?.performanceAgainstOutcomeByLocation ?? []).map(
                    (entry: any) => ({ ...entry, count: undefined })
                  ),
                  `performance_against_scope_by_location_${formatDate(
                    startDate
                  )}_${formatDate(endDate)}.csv`
                )
              }
            />
          </div>
          <hr />
          <OutcomeLocationsTable
            data={statistics?.performanceAgainstOutcomeByLocation}
            headings={['Location, Phase & Activity', 'Hours & Man Hours']}
          />
        </Card>
        <Card classNames={'col-span-3 md:col-span-2'}>
          <div className="flex items-center gap-x-2 w-full justify-between mb-2">
            <h3 className="text-sm my-2">
              Total Man Hours Per Role By Activity Category
            </h3>
            <DownloadToXLSXButton
              onClick={() =>
                downloadExcel(
                  (statistics?.roleHoursPerGroup ?? []).flatMap((role: any) =>
                    role.groups.map((group: any) => ({
                      roleName: role.roleName,
                      groupName: group.groupName,
                      hours: group.hours,
                    }))
                  ),
                  `man_hours_per_role_by_cat_${formatDate(
                    startDate
                  )}_${formatDate(endDate)}.csv`
                )
              }
            />
          </div>
          <RoleGroupTable data={statistics?.roleHoursPerGroup} />
        </Card>

        <Card classNames={'col-span-3 md:col-span-1'}>
          <div className="flex items-center gap-x-2 w-full justify-between mb-2">
            <h3 className="text-sm my-2">
              Total Hours (Duration) Per Activity
            </h3>
            <DownloadToXLSXButton
              onClick={() =>
                downloadExcel(
                  statistics?.totalHoursPerActivityType ?? [],
                  `hours_duration_activity_${formatDate(
                    startDate
                  )}_${formatDate(endDate)}.csv`
                )
              }
            />
          </div>
          <hr />
          <Table
            data={statistics?.totalHoursPerActivityType}
            headings={['Activity Name', 'Total Hours']}
          />
        </Card>
        <Card classNames={'col-span-3 md:col-span-3'}>
          <div className="flex items-center gap-x-2 w-full justify-between mb-2">
            <h3 className="text-sm my-2">Total Man Hours Per Activity</h3>
            <DownloadToXLSXButton
              onClick={() =>
                downloadExcel(
                  statistics?.activityTypePersonHoursPerDay ?? [],
                  `man_hours_activity_${formatDate(startDate)}_${formatDate(
                    endDate
                  )}.csv`
                )
              }
            />
          </div>
          <div className="h-[500px] relative w-full">
            <ActivityTypeChart
              data={statistics?.activityTypePersonHoursPerDay ?? []}
            />
          </div>
          <Table
            data={statistics?.totalPersonHoursPerActivityType?.map(
              (item: any) => {
                if (selectedOutcome?.id) {
                  if (
                    unscopedActivityTypes?.find(
                      (unscopedItem: any) => unscopedItem.name === item.name
                    )
                  ) {
                    return {
                      ...item,
                      labelColumn: <YellowBadge text="Not in Scope" />,
                    };
                  }
                  return {
                    ...item,
                    labelColumn: <BlueBadge text="In Scope" />,
                  };
                }
                return item;
              }
            )}
            headings={['Activity Name', 'Total Man Hours']}
            labelColumn={selectedOutcome?.id}
            labelColumnName={'Scope'}
          />
        </Card>
        <Card classNames={'col-span-3 md:col-span-3'}>
          <div className="flex items-center gap-x-2 w-full justify-between mb-2">
            <h3 className="text-sm my-2">Total Man Hours Per Team</h3>
            <DownloadToXLSXButton
              onClick={() =>
                downloadExcel(
                  statistics?.totalPersonHoursPerTeam ?? [],
                  `man_hours_team_${formatDate(startDate)}_${formatDate(
                    endDate
                  )}.csv`
                )
              }
            />
          </div>
          <hr />
          <Table
            data={statistics?.totalPersonHoursPerTeam}
            headings={['Team Name', 'Total Man Hours']}
          />
        </Card>
      </GridLayout>
    </div>
  );
};
