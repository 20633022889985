import { gql } from '@apollo/client';

export const GET_SKILL_TYPES_SUMMARY = gql`
  query getSkillTypes(
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    getSkillTypes(input: $input, activeOrganisation: $activeOrganisation) {
      count
      results {
        id
        name
        enabled
        updatedAt
      }
    }
  }
`;

export const GET_SKILL_TYPES = gql`
  query getSkillTypes(
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    getSkillTypes(input: $input, activeOrganisation: $activeOrganisation) {
      count
      results {
        id
        name
        enabled
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_SKILLS = gql`
  query getSkills(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $labourResourceId: ID
  ) {
    getSkills(
      input: $input
      activeOrganisation: $activeOrganisation
      labourResourceId: $labourResourceId
    ) {
      count
      results {
        id
        skillType {
          id
          name
        }
        rating
        notes
        createdAt
        updatedAt
      }
    }
  }
`;

export const SEARCH_SKILL_TYPES = gql`
  query searchSkillTypes(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $searchTerm: String!
  ) {
    searchSkillTypes(
      input: $input
      activeOrganisation: $activeOrganisation
      searchTerm: $searchTerm
    ) {
      count
      results {
        id
        name
        enabled
        createdAt
        updatedAt
      }
    }
  }
`;
