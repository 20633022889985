import { useEffect, useState } from 'react';

type ItemType = {
  id: string;
  [key: string]: any;
};

interface UseIndexedDB {
  objectStoreName: string;
}

export const useIndexedDB = ({ objectStoreName }: UseIndexedDB) => {
  const [db, setDb] = useState<IDBDatabase | null>(null);

  const [hasPendingSync, setHasPendingSync] = useState(false);

  useEffect(() => {
    const request = window.indexedDB.open('engOS_DB', 1);

    request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      const db = (event.target as IDBOpenDBRequest).result;
      const objectStores = ['progress_submissions', 'cached_project_data'];
      objectStores.forEach((store) =>
        db.createObjectStore(store, { keyPath: 'id' })
      );
    };

    request.onsuccess = (event: Event) => {
      setDb((event.target as IDBOpenDBRequest).result);
    };

    request.onerror = (event: Event) => {
      console.error(
        'IndexedDB error:',
        (event.target as IDBOpenDBRequest).error?.code
      );
    };
  }, [objectStoreName]);

  const putItem = async (item: ItemType) => {
    if (!db) {
      console.error('Database not initialized', { item });
      return;
    }
    const transaction = db.transaction([objectStoreName], 'readwrite');
    const store = transaction.objectStore(objectStoreName);
    store.put(item);
    setHasPendingSync(true);
  };

  const purgeItem = (id: string) => {
    if (!db) {
      console.error('Database not initialized');
      return;
    }
    const transaction = db.transaction([objectStoreName], 'readwrite');
    const store = transaction.objectStore(objectStoreName);
    store.delete(id);
  };

  const readItem = (id: string): Promise<ItemType | undefined> => {
    return new Promise((resolve, reject) => {
      if (!db) {
        reject('Database not initialized');
        return;
      }
      const transaction = db.transaction([objectStoreName], 'readonly');
      const store = transaction.objectStore(objectStoreName);
      const request = store.get(id);

      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  };

  const bulkPutItems = (items: ItemType[]) => {
    if (!db) {
      console.error('Database not initialized');
      return;
    }
    const transaction = db.transaction([objectStoreName], 'readwrite');
    const store = transaction.objectStore(objectStoreName);
    items.forEach((item) => store.put(item));
    setHasPendingSync(true);
    return new Promise<void>((resolve, reject) => {
      transaction.oncomplete = () => resolve();
      transaction.onerror = () => reject(transaction.error);
    });
  };

  return {
    db,
    putItem,
    readItem,
    bulkPutItems,
    hasPendingSync,
    purgeItem,
    setHasPendingSync,
  };
};
