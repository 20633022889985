import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';
import { Table } from '../../../components/Tables/tables/Table';
import {
  GreenBadge,
  RedBadge,
  YellowBadge,
} from '../../../components/Badges/Badges';
import moment from 'moment';
import useCertifications from '../../../hooks/api/certifications/useCertifications';
import { LoadingSpinner } from '../../../components/Loading/LoadingSpinner';
import NoData from '../../../components/NoData/NoData';
import { useNavigate } from 'react-router-dom';

const headerMapping = {
  name: 'Certification Name',
  expiryDate: 'Expiry',
  expiry: 'Expiry Date',
  updatedAt: 'Updated At',
} as Record<string, any>;

function CompanyCertificationsTable({ company }: { company: any }) {
  const navigate = useNavigate();
  const { limit, offset, setOffset } = usePagination();

  const [certifications, { count, loading }] = useCertifications({
    companyId: company.id,
    limit,
    offset,
  });

  const totalPages = Math.ceil(count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Table
      currentPage={Math.floor(offset / limit) + 1}
      totalPages={totalPages}
      itemsPerPage={limit}
      totalResults={count || 0}
      headerMapper={(text: string) => headerMapping[text]}
      onPageChange={handlePageChange}
      onClickRow={(id) => {
        navigate(`/companies/${company.id}/certifications/${id}/edit`);
      }}
      data={certifications?.map((result: any) => {
        let expiryDate;

        if (!result?.expiryDate) {
          expiryDate = <YellowBadge text={'No date provided'} />;
        }
        const date = moment.unix(parseInt(result?.expiryDate) / 1000);
        const now = moment();
        const tenWeeksFromNow = moment().add(10, 'weeks');

        if (date.isBefore(now)) {
          expiryDate = <RedBadge text="Expired" />;
        } else if (date.isBefore(tenWeeksFromNow)) {
          expiryDate = <YellowBadge text={date.fromNow()} />;
        } else {
          expiryDate = <GreenBadge text={date.fromNow()} />;
        }

        return {
          id: result?.id,
          name: result?.certificationType?.name,
          expiryDate,
          expiry: result?.expiryDate
            ? moment.unix(parseInt(result?.expiryDate) / 1000).format('LL')
            : ((<YellowBadge text={'No date provided'} />) as any),
          updatedAt: moment.unix(parseInt(result?.updatedAt) / 1000).fromNow(),
        };
      })}
      NoData={() => (
        <NoData loading={loading}>
          It looks like this company has no certifications.
        </NoData>
      )}
    />
  );
}

export function CompanyCertifications({ company }: { company: any }) {
  return (
    <PaginationProvider>
      <CompanyCertificationsTable company={company} />
    </PaginationProvider>
  );
}
