import { Bar } from 'react-chartjs-2';
import { useMemo } from 'react';

const ActivityTypeChart = ({ data }) => {
  const chartData = useMemo(() => {
    // Sort data by date
    const sortedData = [...data].sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );

    // Extract unique dates and activity types
    const uniqueDates = [...new Set(sortedData.map((item) => item.date))];
    const activityTypes = [
      ...new Set(sortedData.map((item) => item.activityTypeId)),
    ];

    // Create a dataset for each activity type
    const datasets = activityTypes.map((typeId) => {
      const typeData = sortedData.filter(
        (item) => item.activityTypeId === typeId
      );
      const color = `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(
        Math.random() * 255
      )}, ${Math.floor(Math.random() * 255)})`;

      // Ensure each dataset has data for every date
      const typeHours = uniqueDates.map((date) => {
        const dateData = typeData.find((item) => item.date === date);
        return dateData ? dateData.totalHours : 0;
      });

      return {
        label: typeData[0].activityTypeName,
        data: typeHours,
        backgroundColor: color,
        borderColor: color,
      };
    });

    return {
      labels: uniqueDates,
      datasets,
    };
  }, [data]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default ActivityTypeChart;
