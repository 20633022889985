import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useEffect } from 'react';

import FormCard from '../../../components/FormCard/FormCard';

import { SAVE_MY_BANK_ACCOUNT } from '../../../graphql/mutations/bank-accounts';
import { GET_MY_BANKING_DETAILS } from '../../../graphql/queries/my-profile';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { Currency } from '../../../types/Currency';
import { FormConfig } from '../../../types/Form';
import { useToast } from '../../../context/ToastContext';

export const SaveMyBankAccount = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const { addToast } = useToast();

  const isUpdate = !!id;

  const [fetchBankAccounts, { data }] = useLazyQuery(GET_MY_BANKING_DETAILS, {
    variables: { input: { id } },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchBankAccounts({ variables: { input: { id } } });
  }, [id, isUpdate]);

  const [bankaccount] = data?.getMyBankingDetails?.results || [];

  const [saveMyBankAccount] = useMutation(SAVE_MY_BANK_ACCOUNT);

  const initialValues = id
    ? {
        ...bankaccount,
      }
    : {};

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      await saveMyBankAccount({
        variables: {
          id: values?.id,
          input: {
            ...values,
            id: undefined,
            updatedAt: undefined,
            createdAt: undefined,
          },
        },
      });
      addToast('Bank account details updated', 'success');
      if (setSubmitting) {
        setSubmitting(false);
      }
      navigate(-1);
    } catch (error) {
      console.error(error);
      addToast('Failed to update bank account details', 'error');
    }
  };
  const bankaccountConfig = {
    formSections: [
      {
        title: 'Bank Information',
        description:
          'Details about the bank account. Please be aware that any new bank accounts or changes to existing accounts will need to be verified by your organisation and will be initially disabled as an active bank account. Please notify your organisation of any changes.',
        fields: [
          {
            id: 'bankName',
            type: 'input',
            description: 'Name of the banking institution',
            title: 'Bank Name',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'nameOnAccount',
            type: 'input',
            description: 'This is the name of the account holder',
            title: 'Name on account',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'accountNumber',
            type: 'input',
            title: 'Account Number',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'sortCode',
            type: 'input',
            title: 'Sort Code',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'iban',
            type: 'input',
            title: 'IBAN',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'bic',
            type: 'input',
            title: 'BIC',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'currency',
            type: 'dropdown',
            title: 'Currency',
            options: Object.keys(Currency)
              .filter((e: any) => isNaN(e))
              .map((entry) => ({ name: entry })) as {
              name?: string;
            }[],
          },
          {
            id: 'isBusinessAccount',
            type: 'switch',
            title: 'Is Business Account',
          },
        ],
      },
    ],
  } as FormConfig;

  const BankAccountCreationSchema = Yup.object().shape({
    currency: Yup.mixed<Currency>()
      // @ts-ignore
      .oneOf(Object.keys(Currency))
      .required('Currency is required'),
    bankName: Yup.string()
      .min(2, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required(),
    accountNumber: Yup.string()
      .min(2, 'Too Short!')
      .max(400, 'Too Long!')
      .nullable(),
    sortCode: Yup.string()
      .min(2, 'Too Short!')
      .max(400, 'Too Long!')
      .nullable(),
    iban: Yup.string().min(2, 'Too Short!').max(400, 'Too Long!').nullable(),
    bic: Yup.string().min(2, 'Too Short!').max(400, 'Too Long!').nullable(),
    isBusinessAccount: Yup.boolean().nullable(),
  });

  return (
    <FormCard
      key={`${bankaccountConfig?.title}`}
      config={bankaccountConfig}
      validationSchema={BankAccountCreationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
};
