import { useState } from 'react';
import { Question } from '../../types/QuestionTypes';
import ValidationMessage from '../Validation/ValidationMessage';

interface DropDownParams {
  question: Question;
  handleChange: (value: string[]) => void;
  value: string[];
  validation?: any;
  classNames?: string;
  styles?: Record<string, any>;
  isDisabled?: boolean;
  setSearchTerm?: (searchTerm: string) => void;
  searchTerm?: string;
}

const MultiSelectTable = ({
  question,
  handleChange,
  value,
  validation,
  classNames,
  styles,
  isDisabled,
  setSearchTerm,
  searchTerm,
}: DropDownParams) => {
  if (!Array.isArray(value)) {
    console.warn('Value prop must be an array');
    value = [];
  }

  const [filter, setFilter] = useState('');
  const { title, options, description } = question;

  const filteredOptions = options?.filter((option) =>
    option.name.toLowerCase().includes(filter.toLowerCase())
  );

  const toggleValue = (selectedValue: string) => {
    if (value.includes(selectedValue)) {
      handleChange(value.filter((item) => item !== selectedValue));
    } else {
      handleChange([...value, selectedValue]);
    }
  };

  const toggleAll = (checked: boolean) => {
    if (checked) {
      // @ts-ignore
      handleChange(filteredOptions.map((option) => option.value));
    } else {
      handleChange([]);
    }
  };

  return (
    <div className={classNames}>
      <label
        htmlFor="multi-select-table"
        className="block text-sm font-medium text-gray-700"
      >
        {title}
      </label>
      {validation && <ValidationMessage message={validation} />}
      <input
        type="text"
        placeholder="Filter options..."
        className="mt-1 block w-full px-3 py-2 border border-gray-300 text-sm rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500"
        value={searchTerm || filter}
        onChange={(e) =>
          setSearchTerm
            ? setSearchTerm(e.target.value)
            : setFilter(e.target.value)
        }
      />
      {description && (
        <div className="py-3 text-left text-xs font-medium text-gray-500">
          {description}
        </div>
      )}
      <div style={{ ...styles?.table, maxHeight: '300px', overflowY: 'auto' }}>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox h-4 w-4 text-primary-600"
                    checked={value.length === filteredOptions?.length}
                    onChange={(e) => toggleAll(e.target.checked)}
                  />
                  <span className="ml-2">{'Select All'}</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredOptions?.map((option, index) => (
              <tr key={index}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox h-4 w-4 text-primary-600"
                      // @ts-ignore
                      checked={value.includes(option.value)}
                      // @ts-ignore
                      onChange={() => toggleValue(option.value)}
                      disabled={isDisabled}
                    />
                    <span className="ml-2">{option.name}</span>
                  </label>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {option.detail || '-'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MultiSelectTable;
