import { useEffect } from 'react';
import { useOrganisationAwareApollo } from '../../useOrganisationAwareApollo';
import { GET_PROJECT } from '../../../graphql/queries/projects';
import { ApiHookMeta } from '../types';

export default function useProjectDetails(id?: string): [any, ApiHookMeta] {
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [fetchProject, { data, loading, error, ...result }] = useLazyQuery(
    GET_PROJECT,
    {
      variables: { input: { id } },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (id) {
      fetchProject({ variables: { input: { id } } });
    }
  }, [fetchProject, id]);

  const project = data?.getProjectDetails;

  return [
    project,
    {
      count: 1,
      loading,
      error,
      ...result,
    },
  ];
}
