import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Buttons/Button';

import { GET_MY_COMPANY_DETAILS } from '../../../graphql/queries/my-profile';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import moment from 'moment';

const companyTypeToLabel: Record<string, string> = {
  LIMITED: 'Limited',
  UMBRELLA: 'Umbrella',
};

export const CompanyProfileDetailPage = () => {
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [fetch, { data, loading, error }] = useLazyQuery(
    GET_MY_COMPANY_DETAILS,
    {
      variables: {},
      fetchPolicy: 'network-only',
    }
  );

  const [companyProfile] = data?.getMyCompanyDetails?.results || [];

  const sections = [
    {
      title: 'Company Name',
      content: companyProfile?.companyName || 'N/A',
    },
    {
      title: 'Company Number',
      content: companyProfile?.companyRegNumber || 'N/A',
    },
    {
      title: 'Company Email',
      content: companyProfile?.companyEmail || 'N/A',
    },
    {
      title: 'Company Address',
      content: companyProfile?.companyAddress || 'N/A',
    },
    {
      title: 'Company Phone Number',
      content: companyProfile?.companyPhoneNumber || 'N/A',
    },
    {
      title: 'Company Type',
      content: companyTypeToLabel[companyProfile?.companyType] || 'N/A',
    },
    {
      title: 'VAT Registration Number',
      content: companyProfile?.vatRegistrationNumber || 'N/A',
    },
    {
      title: 'Contact Name',
      content: companyProfile?.contactName || 'N/A',
    },
  ];

  useEffect(() => {
    fetch({ variables: {} });
  }, [fetch]);

  return (
    <div className="bg-gray-50">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="flex flex-col md:items-center md:flex-row w-full md:justify-between px-4 py-5 sm:px-6 flex-wrap">
          <div className="flex flex-col">
            <h1 className="text-3xl font-extrabold text-gray-900">
              Company Details
            </h1>
            <p className="mt-1 text-sm text-gray-500">
              Updated: {moment.unix(companyProfile?.updatedAt / 1000).fromNow()}
            </p>
          </div>
          <div className="mt-2 md:mt-0">
            <Button
              onClick={() => navigate(`/profile/company/edit`)}
              type="submit"
              text="Edit"
            />
          </div>
        </div>
        {loading && (
          <div className="h-64 flex items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
          </div>
        )}
        {error && (
          <p className="px-4 py-5 sm:p-6 text-red-500">
            Error loading data. Please try again.
          </p>
        )}

        {companyProfile ? (
          <div className="border-t border-gray-200">
            {sections.map((section, index) => (
              <div key={index} className="px-4 py-4">
                <h2 className="text-lg font-medium text-gray-900">
                  {section.title}
                </h2>
                <p className="mt-1 text-sm text-gray-500">{section.content}</p>
              </div>
            ))}
          </div>
        ) : (
          <p className="px-4 py-4">No company data available</p>
        )}
      </div>
    </div>
  );
};

export const CompanyProfileDetail = () => <CompanyProfileDetailPage />;
