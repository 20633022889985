import { ErrorMessage, Field, Formik } from 'formik';

import * as Yup from 'yup';

import { CheckCircleIcon } from '@heroicons/react/20/solid';
import {
  ExclamationCircleIcon,
  InformationCircleIcon,
  PencilIcon,
} from '@heroicons/react/24/outline';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/Buttons/Button';
import Switch from '../../../../components/Inputs/Switch';
import TextArea from '../../../../components/Inputs/TextArea';
import MissingCertifications from '../../../../components/MissingCertifications';
import { ModalDialogue } from '../../../../components/Modal/ModalDialogue';
import RateConfigurationByKey, {
  TableValue,
} from '../../../../components/RateConfiguration/RateConfigurationByKey';
import ValidationMessage from '../../../../components/Validation/ValidationMessage';
import { ActiveOrganisationContext } from '../../../../context/ActiveOrganisationContext';
import { UserProfileContext } from '../../../../context/UserProfileContext';
import {
  DELETE_ASSIGNMENT_ROLE,
  SAVE_ASSIGNMENT_ROLE,
  SAVE_ASSIGNMENT_ROLE_EXEMPTION,
} from '../../../../graphql/mutations/assignments';
import { GET_ACTIVITY_CODES } from '../../../../graphql/queries/activity-codes';
import { GET_PROJECT_ASSIGNMENT_ROLES } from '../../../../graphql/queries/assignments';
import { GET_CUSTOM_OPTIONS } from '../../../../graphql/queries/custom-data';
import { useOrganisationAwareApollo } from '../../../../hooks/useOrganisationAwareApollo';
import { Currency } from '../../../../types/Currency';
import { Permission } from '../../../../types/Permissions';
import { YellowBadge } from '../../../../components/Badges/Badges';
import DatePicker from '../../../../components/Inputs/DatePicker';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { Modal } from '../../../../components/Dialogue/ModalDialogue';
import { useToast } from '../../../../context/ToastContext';
import CvExportList from '../../../Labour/cv/CvExportList';
import { CvExportSection } from './exports/CvExportSection';

interface AssignmentRowProps {
  assignment: {
    project: {
      startDate: string;
      endDate: string;
    };
  };
  id?: string;
  rowData?: {
    role?: string;
    projectId?: string;
    labourType?: string;
    customData?: Array<{ key: string; value: any }>;
    createdAt?: number;
    updatedAt?: number;
    enabled?: boolean;
  };
  refetch: () => void;
}

interface InitialValues {
  enabled: boolean;
  rates: TableValue[];
  [key: string]: any;
}

function concatenateValues(obj: any) {
  let result = '';

  function recurse(obj: any) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          recurse(obj[key]); // Recursively call the function if the property is an object.
        } else {
          result += obj[key]; // Concatenate if it's a primitive value.
        }
      }
    }
  }

  recurse(obj);
  return result;
}

const AssignmentRow: React.FC<AssignmentRowProps> = ({
  assignment,
  id,
  rowData = {},
  refetch,
}) => {
  const navigate = useNavigate();
  const { useLazyQuery, useMutation, useQuery } = useOrganisationAwareApollo();

  const { activeOrganisation } = useContext(ActiveOrganisationContext);

  const financeModuleEnabled = activeOrganisation?.financeModuleEnabled;

  const [saveAssignmentRole, { loading: isSaving }] =
    useMutation(SAVE_ASSIGNMENT_ROLE);

  const [deleteAssignmentRole] = useMutation(DELETE_ASSIGNMENT_ROLE);

  const [saveAssignmentRoleExemption] = useMutation(
    SAVE_ASSIGNMENT_ROLE_EXEMPTION
  );

  const [fetchActivityCodes, { data: activityCodes }] = useLazyQuery(
    GET_ACTIVITY_CODES,
    {
      variables: {},
      fetchPolicy: 'network-only',
    }
  );

  const [fetchCustomOptions, { data: customOptionsData }] = useLazyQuery(
    GET_CUSTOM_OPTIONS,
    {
      variables: { assignedToAssignmentRole: true },
      fetchPolicy: 'network-only',
    }
  );

  const { data: assignmentRoleData, refetch: fetchAssignmentRole } = useQuery(
    GET_PROJECT_ASSIGNMENT_ROLES,
    {
      variables: { input: { id }, projectId: rowData?.projectId },
      fetchPolicy: 'network-only',
    }
  );

  const archived =
    assignmentRoleData?.getAssignmentRoles?.results[0]?.archivedAt;

  const [showArchiveConfirmationModal, setShowArchiveConfirmationModal] =
    useState(false);

  const activityCodesResults = activityCodes?.getActivityCodes?.results || [];
  const filteredAndMappedActivityCodes =
    activityCodesResults.filter(
      (code: any) => code.enabled && !code.useResourceDefaultRate
    ) || [];

  const currencies = Object.values(Currency).filter((e: any) => isNaN(e));

  const [activeOption, setActiveOption] = useState<string | null>(null);

  const fieldsSelected = activeOption === 'fields';
  const ratesSelected = activeOption === 'rates';
  const exportsSelected = activeOption === 'exports';

  const [showEnableModal, setShowEnableModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showExemptionModal, setShowExemptionModel] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [exemptionSelection, setExemptionSelection] = useState<any>(null);

  const { addToast } = useToast();

  useEffect(() => {
    if (id) {
      fetchAssignmentRole();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, ratesSelected, fieldsSelected]);

  useEffect(() => {
    if (ratesSelected) {
      fetchActivityCodes();
    }
  }, [fetchActivityCodes, ratesSelected]);

  useEffect(() => {
    fetchCustomOptions({ variables: { assignedToAssignmentRole: true } });
  }, [fetchCustomOptions, fieldsSelected]);

  const { userProfile } = useContext(UserProfileContext);

  const canViewContracts = userProfile?.permissions.includes(
    Permission.ViewContractTemplates
  );

  const canViewAssessments = userProfile?.permissions.includes(
    Permission.ViewAssessments
  );

  const [assignmentRole] =
    assignmentRoleData?.getAssignmentRoles?.results || [];

  const customOptionsSets =
    customOptionsData?.getCustomOptionSets?.results || [];

  const inputs = customOptionsSets.map((optionSet: any) => ({
    label: optionSet.name,
    name: `custom:${optionSet.id}`,
    type: 'select',
    options: [
      { value: '', name: '' },
      ...optionSet.custom_options.map((option: any) => ({
        value: option.id,
        name: option.name,
      })),
    ],
  }));

  const initialValues: InitialValues = {
    enabled: assignmentRole?.enabled,
    rates:
      assignmentRole?.rates?.map((itemRate: any) => ({
        columnId: itemRate.currency,
        name:
          itemRate.name ??
          filteredAndMappedActivityCodes.find(
            (code: any) => code.id === itemRate.key
          )?.name,
        rowId: itemRate.key,
        value: parseFloat(itemRate.rate),
      })) || [],
    startDate: assignmentRole?.startDate
      ? new Date(+assignmentRole?.startDate).getTime()
      : new Date(+assignment?.project?.startDate).getTime(),
    endDate: assignmentRole?.endDate
      ? new Date(+assignmentRole?.endDate).getTime()
      : new Date(+assignment?.project?.endDate).getTime(),
    description: assignmentRole?.description,
    ...inputs.reduce((acc: any, input: any) => {
      const customOption = assignmentRole?.customData?.find(
        (data: any) => `custom:${data.key}` === input.name
      );
      return {
        ...acc,
        [input.name]: customOption ? customOption.value : '',
      };
    }, {}),
  };

  const validationSchema = Yup.object().shape({
    enabled: Yup.boolean().nullable(),
    rates: Yup.array().of(
      Yup.object().shape({
        columnId: Yup.string(),
        rowId: Yup.string(),
        name: Yup.string(),
        value: Yup.string(),
      })
    ),
    ...inputs.reduce(
      (acc: any, input: any) => ({
        ...acc,
        [input.name]: Yup.string(),
      }),
      {}
    ),
  });

  const handleSave = async (values: any) => {
    if (fieldsSelected) {
      const customData = Object.keys(values)
        .filter((key) => key.startsWith('custom:'))
        .map((key) => ({
          key: key.replace('custom:', ''),
          value: values[key],
        }));
      await saveAssignmentRole({
        variables: {
          id,
          input: {
            customData,
          },
        },
      });
      await fetchCustomOptions();
      await fetchAssignmentRole();
    } else if (ratesSelected) {
      const rates = values.rates
        .map((rate: TableValue) => ({
          id: `${rate.columnId}_${rate.rowId}`,
          name: rate.name,
          currency: rate.columnId,
          key: rate.rowId,
          rate: parseFloat(rate.value as string),
        }))
        .filter((rate: any) =>
          filteredAndMappedActivityCodes
            .map((code: any) => code.id)
            .includes(rate.key)
        );
      await saveAssignmentRole({
        variables: {
          id,
          input: {
            rates,
          },
        },
      });
      await fetchAssignmentRole();
    }
    setActiveOption(null);
  };

  const handleEnableChange = async (enabled: boolean) => {
    await saveAssignmentRole({
      variables: {
        id,
        input: {
          enabled,
        },
      },
    });
    await fetchAssignmentRole();
  };

  const handleArchive = async () => {
    await saveAssignmentRole({
      variables: {
        id,
        input: {
          archivedAt: archived ? false : true,
        },
      },
    });
    fetchAssignmentRole();
    refetch();
  };

  const getOptionSetNameByValue = (optionSetId: any) => {
    const matchingOptionSet =
      customOptionsData?.getCustomOptionSets?.results.find(
        (optionSet: any) => optionSet.id === optionSetId
      );

    return matchingOptionSet ? matchingOptionSet.name : null;
  };

  const mapCustomOptionName = (optionId: any) => {
    for (let optionSet of customOptionsData?.getCustomOptionSets?.results ||
      []) {
      const matchingOption = optionSet.custom_options.find(
        (option: any) => option.id === optionId
      );
      if (matchingOption) {
        return matchingOption.name;
      }
    }
    return optionId; // default to the option value if no match found
  };

  const customSections = (assignmentRole?.customData || []).map(
    (customItem: any) => ({
      title: getOptionSetNameByValue(customItem.key),
      content: mapCustomOptionName(customItem.value),
    })
  );

  const [showCertExemptionInfo, setShowCertExemptionInfo] = useState(null);

  const [exemptionReason, setExemptionReason] = useState('');

  const [exemptionExpiry, setExemptionExpiry] = useState();

  return (
    <>
      <Formik
        enableReinitialize
        validateOnChange
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={() => {}}
      >
        {({ setFieldValue, values, isValid, errors }) => (
          <>
            <Modal
              showModal={showArchiveConfirmationModal}
              completeButtonText={archived ? 'Unarchive' : 'Archive'}
              setShowModal={setShowArchiveConfirmationModal}
              title={`Confirm ${archived ? 'Unarchive' : 'Archive'} Role`}
              onComplete={async () => {
                await handleArchive();
                setShowArchiveConfirmationModal(false);
              }}
            >
              <div className="px-4 py-4">
                {archived
                  ? 'Un-archive this role?'
                  : 'Are you sure you want to archive this role? You can un-archive it later if needed'}
              </div>
            </Modal>
            <Modal
              completeButtonText="Save"
              showModal={showEditModal}
              setShowModal={setShowEditModal}
              title={'Edit Assignment'}
              onComplete={async () => {
                const result = await saveAssignmentRole({
                  variables: {
                    id,
                    input: {
                      startDate: values?.startDate
                        ? new Date(values.startDate).toISOString()
                        : null,
                      endDate: values?.endDate
                        ? new Date(values.endDate).toISOString()
                        : null,
                      description: values?.description,
                    },
                  },
                });

                if (result.data.saveAssignmentRole?.success) {
                  addToast('Assignment role succesfully updated', 'success');
                } else {
                  addToast(
                    'There was an error updating this assignment role',
                    'error'
                  );
                }

                setShowEditModal(false);
                await fetchAssignmentRole();
              }}
            >
              <div className="flex flex-col w-full p-4">
                <p>Role: {assignmentRole?.role?.name}</p>
                <hr className="my-4" />
                <div className="flex flex-col gap-4">
                  {!assignmentRole?.startDate && !assignmentRole?.endDate ? (
                    <p className="pb-4 text-sm text-gray-500">
                      This assignment role does not have a start or end date, so
                      the start and end dates of the project have been used.
                    </p>
                  ) : null}
                  <div className="flex flex-col gap-4 md:flex-row">
                    <DatePicker
                      portalId="headlessui-portal"
                      id="startDate"
                      title="Start Date"
                      handleChange={(date: any) =>
                        setFieldValue('startDate', date)
                      }
                      inputTransformer={(value: Date) =>
                        new Date(value).getTime()
                      }
                      value={values.startDate}
                      label="Start Date"
                    />
                    <DatePicker
                      portalId="headlessui-portal"
                      id="endDate"
                      title="End Date"
                      handleChange={(date: any) =>
                        setFieldValue('endDate', date)
                      }
                      inputTransformer={(value: Date) =>
                        new Date(value).getTime()
                      }
                      value={values.endDate}
                      label="End Date"
                    />
                  </div>

                  <div className="flex flex-col gap-4 md:flex-row">
                    <TextArea
                      handleChange={(value) =>
                        setFieldValue('description', value)
                      }
                      question={{
                        id: 'description',
                        title: 'Roles and Responsibilities',
                        placeholder: 'Add Roles and Responsibilities here...',
                        name: 'description',
                        classNames: 'w-full',
                      }}
                      value={values.description}
                    />
                  </div>
                </div>
              </div>
            </Modal>
            <div className="flex flex-col gap-x-2 items-center my-2 shadow-sm justify-evenly border-b-2 border-t-2">
              <div className="text-xl text-black w-full font-medium px-4 py-3 border-b flex justify-between border-dashed">
                <h2>{rowData?.role}</h2>
                <div className="flex gap-x-4">
                  <Button
                    className={'bg-green-600 hover:bg-black'}
                    disabled={archived}
                    onClick={() => setShowEditModal(true)}
                  >
                    Edit
                  </Button>
                  <Button
                    text={archived ? 'Unarchive' : 'Archive'}
                    className={
                      archived
                        ? 'bg-gray-800 hover:bg-black'
                        : 'bg-orange-400 hover:bg-orange-500'
                    }
                    onClick={() => setShowArchiveConfirmationModal(true)}
                  />
                  {!archived ? (
                    <Switch
                      text="Enabled"
                      enabled={values.enabled}
                      handleChange={() => {
                        setShowEnableModal(true);
                      }}
                    />
                  ) : null}
                </div>
              </div>
              <div className="flex px-4 bg-gray-50 flex-col 2xl:flex-row gap-x-2 2xl:items-center justify-between w-full">
                <div className="flex flex-col py-4">
                  {assignmentRole?.createdAt ? (
                    <p className="mt-1 text-xs text-gray-500">
                      Created:{' '}
                      {moment.unix(assignmentRole?.createdAt / 1000).fromNow()}
                    </p>
                  ) : null}
                  {assignmentRole?.updatedAt ? (
                    <p className="mt-1 text-xs text-gray-500">
                      Last Updated:{' '}
                      {moment.unix(assignmentRole?.updatedAt / 1000).fromNow()}
                    </p>
                  ) : null}
                  {!assignmentRole?.rates ||
                  assignmentRole?.rates?.length === 0 ? (
                    <p className="mt-2 flex gap-x-2">
                      <div>⚠️</div>No Rates Configured for this Role
                    </p>
                  ) : (
                    <p className="mt-2 flex gap-x-2">
                      <CheckCircleIcon
                        color="green"
                        className="h-5 w-5"
                        aria-hidden="true"
                      />{' '}
                      Rates Set
                    </p>
                  )}
                  <hr className="my-2" />
                  <div className="flex flex-row gap-2">
                    <p className="text-gray-500">
                      Start Date:{' '}
                      {assignmentRole?.startDate
                        ? moment(Number(assignmentRole?.startDate)).format('ll')
                        : 'Not Provided'}
                    </p>
                    <p className="text-gray-500">-</p>
                    <p className="text-gray-500">
                      End Date:{' '}
                      {assignmentRole?.endDate
                        ? moment(Number(assignmentRole?.endDate)).format('ll')
                        : 'Not Provided'}
                    </p>
                  </div>
                  <hr className="my-2" />
                  {assignmentRole?.description ? (
                    <>
                      <p className="text-gray-500">
                        {assignmentRole?.description}
                      </p>
                      <hr className="my-2" />
                    </>
                  ) : null}
                  {!archived ? (
                    <p>
                      Certification Status:
                      {assignmentRole?.missingOrInvalidCertifications &&
                      assignmentRole?.missingOrInvalidCertifications.length >
                        0 ? (
                        <div className="mt-2 text-sm text-red-500 rounded-sm">
                          <MissingCertifications
                            missingOrInvalidCertifications={
                              assignmentRole?.missingOrInvalidCertifications
                            }
                            onClickCert={(certification) => {
                              setShowExemptionModel(true);
                              setExemptionSelection({
                                assignmentRole: { ...assignmentRole },
                                certification: { ...certification },
                              });
                            }}
                          >
                            {assignmentRole?.assignment?.lrs?.firstName} has
                            missing, incomplete, expired or soon to be expiring
                            certifications required for this role, please add /
                            update these certifications.
                            <p className="mt-1">
                              You should consult your organisation's policies to
                              determine if this person can be enabled on this
                              project.
                            </p>
                            <br />
                          </MissingCertifications>
                        </div>
                      ) : (
                        <p className="mt-1 text-sm">
                          User may have the required certifications, but you
                          should{' '}
                          <a
                            className="text-green-600 font-semibold"
                            href={`/labour/${
                              assignmentRole?.assignment?.lrs?.type ===
                              'SUBCONTRACTOR'
                                ? 'subcontractors'
                                : 'employees'
                            }/${assignmentRole?.assignment?.lrs?.id}`}
                          >
                            check their profile
                          </a>{' '}
                          to be sure this is up to date and required information
                          has been provided.
                        </p>
                      )}
                      {assignmentRole?.certificationExemptions?.length > 0 ? (
                        <p
                          className={`bg-blue-50 text-blue-900 text-sm px-4 py-4 rounded-lg my-2`}
                        >
                          Exemptions have been made for this assignment for the
                          following certifications:
                          <div className="mt-1" />
                          <br />
                          {assignmentRole?.certificationExemptions?.map(
                            (exemption: any) => (
                              <li
                                className="flex ml-2 bg-white py-2 flex-col rounded-xl my-1 gap-x-2 border border-blue-200"
                                role="button"
                                onClick={() =>
                                  setShowCertExemptionInfo(
                                    showCertExemptionInfo === exemption.id
                                      ? null
                                      : exemption.id
                                  )
                                }
                              >
                                <div className="flex gap-x-2">
                                  <InformationCircleIcon className="ml-2 w-5 text-blue-400" />
                                  {exemption.certType?.name}
                                  {!exemption.expiryDate ? (
                                    ''
                                  ) : moment
                                      .unix(Number(exemption.expiryDate) / 1000)
                                      .isBefore(moment.now()) ? (
                                    <ExclamationTriangleIcon className="w-5 text-orange-700" />
                                  ) : (
                                    ''
                                  )}
                                </div>

                                {showCertExemptionInfo === exemption.id ? (
                                  <div className="text-sm mx-2 mt-4 mb-2 relative">
                                    <PencilIcon
                                      onClick={() => {
                                        setExemptionSelection({
                                          assignmentRole: assignmentRole,
                                          certification: {
                                            id: exemption?.certType?.id,
                                            name: exemption?.certType?.name,
                                          },
                                        });
                                        setExemptionReason(exemption.comment);
                                        setShowExemptionModel(true);
                                        // @ts-ignore
                                        setExemptionExpiry(
                                          // @ts-ignore
                                          exemption.expiryDate
                                            ? moment
                                                .unix(
                                                  Number(exemption.expiryDate) /
                                                    1000
                                                )
                                                .toDate()
                                            : undefined
                                        );
                                      }}
                                      role="button"
                                      className="w-5 absolute right-5 top-0"
                                    />
                                    <p>Reason: {exemption.comment}</p>
                                    <p>Updated By: {exemption.createdBy}</p>
                                    <p>
                                      Expiry:{' '}
                                      {!exemption.expiryDate ? (
                                        <YellowBadge text="None" />
                                      ) : (
                                        moment
                                          .unix(
                                            Number(exemption.expiryDate) / 1000
                                          )
                                          .fromNow()
                                      )}
                                    </p>
                                  </div>
                                ) : null}
                              </li>
                            )
                          )}
                        </p>
                      ) : null}
                    </p>
                  ) : null}

                  {customSections &&
                  customSections.filter((section: any) => section.content)
                    .length > 0 ? (
                    <div className="flex flex-col flex-wrap lg:flex-row gap-x-6 gap-y-4 py-4">
                      {customSections.map((section: any, index: number) =>
                        section?.content ? (
                          <div key={`custom-${index}`} className="">
                            <h2 className="text-lg lg:text-base font-medium text-gray-900">
                              {section.title}
                            </h2>
                            <p className="mt-1 lg:text-xs text-gray-500">
                              {section.content}
                            </p>
                          </div>
                        ) : null
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="w-full px-4">
                {fieldsSelected && (
                  <div className="py-4">
                    {inputs.map((input: any) => (
                      <div
                        key={input.name}
                        className="w-full flex flex-row items-center my-4 gap-x-4"
                      >
                        <label
                          htmlFor={input.name}
                          className="block text-gray-700 font-bold w-32"
                        >
                          {input.label}:
                        </label>
                        <Field
                          as="select"
                          name={input.name}
                          className="flex-grow px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                        >
                          {input.options.map((option: any) => (
                            <option key={option.name} value={option.value}>
                              {option.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name={input.name}
                          component="div"
                          className="text-red-500 text-sm mt-1"
                        />
                      </div>
                    ))}
                  </div>
                )}
                {ratesSelected && (
                  <div className="py-4">
                    <Button
                      text="Fetch Latest Rates for Role"
                      isDisabled={archived}
                      onClick={() => {
                        setFieldValue(
                          'rates',
                          assignmentRole?.role?.defaultRates?.map(
                            (itemRate: any) => ({
                              columnId: itemRate.currency,
                              name:
                                itemRate.name ??
                                filteredAndMappedActivityCodes.find(
                                  (code: any) => code.id === itemRate.key
                                )?.name,
                              rowId: itemRate.key,
                              value: parseFloat(itemRate.rate ?? 0),
                            })
                          ) || []
                        );
                      }}
                    />
                    {
                      <RateConfigurationByKey
                        value={values.rates}
                        handleChange={(newRates: TableValue[]) =>
                          setFieldValue('rates', newRates)
                        }
                        question={{
                          tableOptions: filteredAndMappedActivityCodes?.map(
                            (key: any) => ({
                              heading: {
                                id: key.id,
                                name: key.name,
                                colour: key?.colour,
                              },
                              options: currencies.map((entry) => ({
                                heading: {
                                  id: Object.entries(Currency).find(
                                    (item: any) => item[1] === entry
                                  )?.[0],
                                  name: Object.values(Currency).find(
                                    (currency) => currency === entry
                                  ),
                                },
                              })) as {
                                heading?: {
                                  id: string;
                                  name: string;
                                };
                              }[],
                            })
                          ),
                        }}
                      />
                    }
                    <p className="mt-6 -mb-2">
                      Please be advised that any amendments to previously agreed
                      rates may require a contractual change / updated contract.
                    </p>
                  </div>
                )}
                {exportsSelected && (
                  <CvExportSection assignmentRole={assignmentRole} />
                )}
              </div>
              {Object.entries(errors).map(([_key, value], index) => {
                // Determine if 'value' is an object, and if so, concatenate its values.
                const message =
                  typeof value === 'object' && value !== null
                    ? concatenateValues(value)
                    : value;

                return (
                  <ValidationMessage message={String(message)} key={_key} />
                );
              })}
              {
                <div className="flex flex-col-reverse w-full px-4 py-4 gap-y-4 lg:flex-row gap-x-6">
                  {customOptionsSets && customOptionsSets.length > 0 ? (
                    ratesSelected ? null : (
                      <Button
                        isLoading={isSaving}
                        isDisabled={
                          !isValid || isSaving || (fieldsSelected && archived)
                        }
                        style={
                          fieldsSelected
                            ? {
                                ...(!isValid
                                  ? { backgroundColor: 'gray' }
                                  : {}),
                              }
                            : { backgroundColor: 'black' }
                        }
                        text={fieldsSelected ? 'Save Fields' : 'Fields'}
                        onClick={() => {
                          setActiveOption(fieldsSelected ? null : 'fields');
                          if (fieldsSelected) {
                            handleSave(values);
                          }
                        }}
                      />
                    )
                  ) : null}
                  {rowData?.labourType === 'SUBCONTRACTOR' &&
                  canViewContracts &&
                  assignmentRole?.assignment?.lrs?.email ? (
                    <Button
                      isLoading={isSaving}
                      style={{ backgroundColor: 'black' }}
                      text={'Contracts'}
                      onClick={() => {
                        navigate(
                          `/projects/${rowData.projectId}/assignments/roles/${id}/contracts`
                        );
                      }}
                    />
                  ) : null}

                  {canViewAssessments &&
                  assignmentRole?.assignment?.lrs?.email ? (
                    <Button
                      isLoading={isSaving}
                      style={{ backgroundColor: 'black' }}
                      text={'Assessments'}
                      onClick={() => {
                        navigate(
                          `/projects/${rowData.projectId}/assignments/roles/${id}/assessments`
                        );
                      }}
                    />
                  ) : null}

                  {fieldsSelected ? null : financeModuleEnabled ? (
                    <Button
                      isLoading={isSaving}
                      isDisabled={
                        !userProfile?.permissions?.includes(
                          Permission.ViewProjectAssignmentRates
                        ) ||
                        !isValid ||
                        isSaving ||
                        (ratesSelected && archived)
                      }
                      style={
                        ratesSelected
                          ? { ...(!isValid ? { backgroundColor: 'gray' } : {}) }
                          : !userProfile?.permissions?.includes(
                              Permission.ViewProjectAssignmentRates
                            )
                          ? { backgroundColor: 'gray' }
                          : { backgroundColor: 'black' }
                      }
                      text={ratesSelected ? 'Save Rates' : 'Rates'}
                      onClick={() => {
                        setActiveOption(ratesSelected ? null : 'rates');
                        if (ratesSelected) {
                          handleSave(values);
                        }
                      }}
                    />
                  ) : null}
                  {!activeOption ? (
                    <Button
                      isLoading={isSaving}
                      isDisabled={
                        !userProfile?.permissions?.includes(
                          Permission.ViewLabourProfiles
                        )
                      }
                      className="bg-black"
                      onClick={() => {
                        setActiveOption('exports');
                      }}
                    >
                      CVs
                    </Button>
                  ) : null}
                  {activeOption && (
                    <Button
                      style={{ backgroundColor: 'black' }}
                      text={'Cancel'}
                      onClick={() => {
                        setActiveOption(null);
                      }}
                    />
                  )}
                  <Modal
                    showModal={showDeleteModal}
                    completeButtonText="Permanently Delete"
                    completeButtonClassName="bg-red-500 hover:bg-red-600"
                    setShowModal={setShowDeleteModal}
                    title={`Permanently Delete Role?`}
                    onComplete={async () => {
                      try {
                        const { data } = await deleteAssignmentRole({
                          variables: {
                            id: id,
                          },
                        });
                        if (data?.deleteAssignmentRole?.success) {
                          addToast(
                            'Assignment role succesfully deleted',
                            'success'
                          );
                        }
                      } catch (error) {
                        addToast('Error deleting assignment role', 'error');
                        console.error('Error:', error);
                      }
                      setShowDeleteModal(false);
                      refetch();
                    }}
                    classNames="outline-red-700 outline outline-2 bg-red-50"
                  >
                    <div className="flex items-center gap-x-4 px-4 py-4 bg-red-100 rounded-lg mt-4 text-red-800">
                      <ExclamationCircleIcon className="w-24 h-24" />
                      <div>
                        Are you sure you want to permanently delete this role?
                        This action cannot be undone and should only be used if
                        adding the role was a genuine mistake.
                      </div>
                    </div>
                  </Modal>
                  {userProfile?.permissions?.includes(
                    Permission.DeleteAssignmentRole
                  ) && !assignmentRole?.hasRelatedEntities ? (
                    <div className="w-full flex justify-end">
                      <Button
                        className="bg-red-500 hover:bg-red-600 hover:border-red-700 hover:outline hover:outline-red-700 hover:outline-2 hover:shadow-[0_0_10px_4px_rgba(255,0,0,0.8)]"
                        children={
                          <div className="flex gap-x-2">
                            <ExclamationCircleIcon className="w-5 h-5" />
                            Delete Permanently
                          </div>
                        }
                        onClick={() => {
                          setShowDeleteModal(true);
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              }
              <ModalDialogue
                submitText="Save Exemption"
                setShowModal={setShowExemptionModel}
                onSubmit={async () => {
                  setShowExemptionModel(false);
                  await saveAssignmentRoleExemption({
                    variables: {
                      input: {
                        assignmentRoleId:
                          exemptionSelection?.assignmentRole?.id,
                        certificationTypeId:
                          exemptionSelection?.certification.id,
                        comment: exemptionReason,
                        expiryDate: exemptionExpiry,
                      },
                    },
                  });
                  setExemptionReason('');
                  setExemptionExpiry(undefined);
                  await fetchAssignmentRole();
                }}
                showModal={showExemptionModal ? true : false}
                Title={'Create an exemption for this assignee and role?'}
                modalConfirmationText={
                  <div className="flex flex-col">
                    <p>Role: {exemptionSelection?.assignmentRole.role?.name}</p>
                    <p>
                      Certification: {exemptionSelection?.certification?.name}
                    </p>
                    <hr className="my-4" />
                    <div className="z-99">
                      <DatePicker
                        portalId="headlessui-portal"
                        id="expiryDate"
                        validation=""
                        title="Expiry Date"
                        handleChange={setExemptionExpiry}
                        value={exemptionExpiry}
                        label="Expiry"
                      />
                    </div>
                    <hr className="my-4" />
                    <TextArea
                      validation=""
                      question={{
                        id: '',
                        title: 'Reason for exemption:',
                        name: '',
                        description: '',
                        defaultText: '',
                        classNames: '',
                        placeholder: '',
                      }}
                      handleChange={setExemptionReason}
                      value={exemptionReason}
                    />
                  </div>
                }
              />
              <ModalDialogue
                submitText="Yep, I know what I'm doing"
                setShowModal={setShowEnableModal}
                onSubmit={() => {
                  setShowEnableModal(false);
                  const isEnabled = !values.enabled;
                  handleEnableChange(isEnabled);
                  setFieldValue('enabled', isEnabled);
                }}
                showModal={showEnableModal}
                Title={
                  !values.enabled
                    ? 'Are you sure you want to enable this role?'
                    : 'Are you sure you want to disable this role?'
                }
                modalConfirmationText={
                  !values.enabled ? (
                    <>
                      Your organisation may require you to complete some
                      additional information and actions before you enable this
                      role:
                      {!assignmentRole?.rates ||
                      assignmentRole?.rates?.length === 0 ? (
                        <p className="mt-2 flex gap-x-2">
                          <div>⚠️</div>Warning: No Rates Configured for this
                          Role.
                        </p>
                      ) : null}
                      {!assignmentRole?.assignment?.lrs?.internalId ? (
                        <p className="mt-2 flex gap-x-2">
                          <div>⚠️</div>Warning: No Internal ID has been
                          configured for this individual.
                        </p>
                      ) : null}
                      {customOptionsSets && customOptionsSets.length > 0 ? (
                        <p className="mt-2 flex gap-x-2">
                          <div>⚠️</div>Ensure all Custom Fields required by your
                          organisation are set.
                        </p>
                      ) : null}
                      <p className="mt-2 flex gap-x-2">
                        <div>⚠️</div>Ensure all required assessments are
                        completed.
                      </p>
                      {assignmentRole?.missingOrInvalidCertifications?.length >
                      0 ? (
                        <p className="mt-2 flex gap-x-2">
                          <div>⚠️</div>Assignee is missing required
                          certifications.
                        </p>
                      ) : null}
                      {assignmentRole?.assignment?.lrs?.type ===
                      'SUBCONTRACTOR' ? (
                        <p className="mt-2 flex gap-x-2">
                          <div>⚠️</div>Ensure all required contracts are
                          submitted, signed and completed.
                        </p>
                      ) : null}
                    </>
                  ) : (
                    <></>
                  )
                }
              />
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

export default AssignmentRow;
