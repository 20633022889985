import { gql } from '@apollo/client';

export const SAVE_TIMESHEET_SUBMISSION = gql`
  mutation saveTimesheetSubmission(
    $input: SaveTimesheetSubmissionInput!
    $activeOrganisation: String!
  ) {
    saveTimesheetSubmission(
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const APPROVE_TIMESHEET = gql`
  mutation approveTimesheet(
    $id: ID!
    $input: ApproveTimesheetInput!
    $activeOrganisation: String!
  ) {
    approveTimesheet(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;
