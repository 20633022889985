import { useContext } from 'react';
import { UserProfileContext } from '../../context/UserProfileContext';
import NoData from './NoData';
import LinkButton from '../Buttons/LinkButton';
import { Permission } from '../../types/Permissions';

export default function NoProjects({
  filterText,
  loading,
}: {
  filterText?: string;
  loading?: boolean;
}) {
  const { userProfile } = useContext(UserProfileContext);

  return (
    <NoData loading={loading}>
      {filterText
        ? 'It looks like there are no projects that match your query!'
        : 'It looks like there are no projects for this organisation!'}
      {userProfile?.permissions?.includes(Permission.UpdateProject) ? (
        <LinkButton to={`/projects/new`} target="_blank">
          Add a Project
        </LinkButton>
      ) : null}
    </NoData>
  );
}
