import { useContext, useEffect, useState } from 'react';
import { GET_SKILLS } from '../../../graphql/queries/skills';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { Table } from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';
import moment from 'moment';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Button } from '../../../components/Buttons/Button';
import { Permission } from '../../../types/Permissions';
import { useNavigate } from 'react-router-dom';
import RatingChips from '../../../components/Rating/RatingChips';

const headerMapping = {
  name: 'Skill Name',
  rating: 'Rating',
  updatedAt: 'Updated At',
} as Record<string, any>;

export const SkillDetailPage = ({ labourResourceId, labourPath }: any) => {
  const { useLazyQuery } = useOrganisationAwareApollo();
  const [fetch, { data, loading, error }] = useLazyQuery(GET_SKILLS, {
    variables: { labourResourceId },
    fetchPolicy: 'network-only',
  });

  const skilldetail = data?.getSkills?.results || [];

  const [filterText, setFilterText] = useState<string>();
  const [selectedFilterDimension, setSelectedFilterDimension] =
    useState<string>('name');

  const { limit, offset, setOffset } = usePagination();

  const [debouncedFilterText, setDebouncedSearchTerm] = useState(filterText);

  const filter = debouncedFilterText
    ? {
        filters: [
          {
            field: selectedFilterDimension,
            stringFilters: [{ ilike: debouncedFilterText }],
          },
        ],
      }
    : null;
  const totalPages = Math.ceil(data?.getSkills?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  useEffect(() => {
    fetch({
      variables: {
        labourResourceId,
        input: { limit, offset, ...(filter ? { filter } : {}) },
      },
    });
  }, [labourResourceId, limit, offset, debouncedFilterText]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setOffset(0);
      setDebouncedSearchTerm(filterText);
    }, 600);
    return () => {
      clearTimeout(handler);
    };
  }, [filterText]);

  const { userProfile } = useContext(UserProfileContext);

  const navigate = useNavigate();

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      {userProfile?.permissions.includes(Permission.UpdateSkills) ? (
        <div className="flex w-full py-2 px-4 justify-end">
          <Button
            onClick={() =>
              navigate(`/labour/${labourPath}/${labourResourceId}/skills/new`)
            }
            type="submit"
            text="Add Skill"
          />
        </div>
      ) : null}
      {loading && (
        <div className="h-64 flex items-center justify-center">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
        </div>
      )}
      {error && (
        <p className="px-4 py-5 sm:p-6 text-red-500">
          Error loading data. Please try again.
        </p>
      )}
      {skilldetail ? (
        <div className="border-t py-2 border-gray-200">
          <Table
            currentPage={Math.floor(offset / limit) + 1}
            totalPages={totalPages}
            totalResults={data?.getSkills?.count || 0}
            itemsPerPage={limit}
            onClickRow={(id) => {
              navigate(
                `/labour/${labourPath}/${labourResourceId}/skills/${id}/edit`
              );
            }}
            filterDimensions={[{ id: 'name', name: 'Name' }]}
            showFilterOptions
            filterText={filterText}
            setFilterText={setFilterText}
            selectedFilterDimension={selectedFilterDimension}
            setSelectedFilterDimension={setSelectedFilterDimension}
            columnsToFilter={['firstName', 'lastName', 'id', 'status']}
            columnValueMapper={{
              updatedAt: (text: string) =>
                moment.unix(parseInt(text) / 1000).fromNow(),
            }}
            headerMapper={(text: string) => headerMapping[text]}
            onPageChange={handlePageChange}
            data={
              data?.getSkills?.results.length > 0
                ? data?.getSkills?.results?.map((result: any) => ({
                    id: result?.id,
                    name: result?.skillType?.name,
                    rating: <RatingChips rating={result.rating} />,
                  }))
                : []
            }
          />
        </div>
      ) : (
        <p className="px-4 py-5 sm:p-6 ">No skill available</p>
      )}
    </div>
  );
};

export const SkillDetail = ({ labourResourceId, labourPath }: any) => (
  <PaginationProvider>
    <SkillDetailPage
      labourResourceId={labourResourceId}
      labourPath={labourPath}
    />
  </PaginationProvider>
);
