import { useEffect } from 'react';
import { useOrganisationAwareApollo } from '../useOrganisationAwareApollo';
import { ApiHookMeta } from './types';
import { GET_ASSESSMENT_TYPES } from '../../graphql/queries/assessments';

export default function useAssessmentTypes({
  id,
  limit,
  offset,
}: {
  id?: string;
  limit?: number;
  offset?: number;
}): [any, ApiHookMeta] {
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [fetch, { data, loading, error, ...result }] = useLazyQuery(
    GET_ASSESSMENT_TYPES,
    {
      variables: { input: { id, limit, offset } },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    fetch({ variables: { input: { id } } });
  }, [fetch, id]);

  const assessmentTypes = data?.getAssessmentTypes?.results || [];
  const count = data?.getAssessmentTypes?.count || 0;

  return [
    assessmentTypes,
    {
      count,
      loading,
      error,
      ...result,
    },
  ];
}

export function useAssessmentType(id?: string) {
  const [assessmentTypes, meta] = useAssessmentTypes({ id });

  const [assessmentType] = assessmentTypes;

  return [assessmentType, meta];
}
