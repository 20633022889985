import { Formik } from 'formik';

import Switch from '../Inputs/Switch';
import { FieldType, FormConfig } from '../../types/Form';

import DropDown from '../Inputs/Dropdown';
import EmailInput from '../Inputs/EmailInput';
import NumericInput from '../Inputs/NumericInput';
import TextArea from '../Inputs/TextArea';
import TextInput from '../Inputs/TextInput';
import CheckboxList from '../Inputs/CheckboxList';
import ComboBox from '../Inputs/ComboBox';

import FormSection from '../FormSection/FormSection';

import DateRangePicker from '../Inputs/DateRangePicker';

import RadioGroup from '../Inputs/RadioGroup';
import RichTextInput from '../Inputs/RichTextInput';
import DatePicker from '../Inputs/DatePicker';
import FormSubmit from '../Buttons/FormSubmit';
import MultiSelectDropDown from '../Inputs/MultiSelect';
import CheckBoxGroup from '../Inputs/CheckboxGroup';
import InputList from '../Inputs/InputList';
import ColourInput from '../Inputs/ColourInput';
import RangeSlider from '../Inputs/RangeSlider';
import { FileInput } from '../Inputs/FileInput';
import MultiSelectComboBox from '../Inputs/MultiSelectComboBox';
import ArrayInput from '../Inputs/ArrayInput';
import ObjectList from '../Inputs/ObjectList';
import MonthPicker from '../Inputs/MonthPicker';
import { useState } from 'react';
import { Modal } from '../Dialogue/ModalDialogue';
import { isEmptyOrUndefined } from '../../utils/utils';
import MultiSelectTable from '../Inputs/MultiSelectTable';
import ChipsInput from '../Inputs/ChipsInput';
import AddButton from '../Buttons/AddButton';
import { CurrencyInput } from '../Inputs/CurrencyInput';

export function RenderFormFields(
  fields: any,
  values: any,
  errors: any,
  handleChange: (
    field: string
  ) => (e: string | React.ChangeEvent<any> | boolean) => void,
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void,
  components?: React.ReactElement[],
  uneditableKeys?: string[]
) {
  const items = [];

  if (components && components.length > 0) {
    components.forEach((component) => items.push(component));
  }

  for (const fieldsKey in fields) {
    const q = fields[fieldsKey];

    const inputValue = q.id
      ?.split('.')
      // @ts-ignore
      ?.reduce((p, c) => (p && p[c]) || null, values);

    const error = q?.id
      ?.split('.')
      // @ts-ignore
      .reduce((p, c) => (p && p[c]) || null, errors);

    if (q.dependsOnField) {
      const dependsOnValue = q.dependsOnField
        .split('.')
        // @ts-ignore
        ?.reduce((p, c) => (p && p[c]) || null, values);
      if (!q.dependsOnFunction(inputValue, dependsOnValue, setFieldValue)) {
        continue;
      }
    }

    switch (q.type) {
      case FieldType.textarea:
        items.push(
          <TextArea
            isDisabled={uneditableKeys?.includes(q.id)}
            key={q.id}
            question={q}
            handleChange={handleChange(q.id)}
            value={inputValue}
            validation={error}
          />
        );
        break;
      case FieldType.input:
        items.push(
          <TextInput
            inputTransformer={q.inputTransformer}
            isDisabled={uneditableKeys?.includes(q.id) || q.disabled}
            key={q.id}
            question={q}
            handleChange={handleChange(q.id)}
            value={inputValue}
            validation={error}
          />
        );
        break;
      case FieldType.rangeslider:
        items.push(
          <RangeSlider
            isDisabled={uneditableKeys?.includes(q.id) || q.disabled}
            key={q.id}
            question={q}
            handleChange={(options) => setFieldValue(q.id, options)}
            value={inputValue}
            validation={error}
          />
        );
        break;
      case FieldType.colour:
        items.push(
          <ColourInput
            inputTransformer={q.inputTransformer}
            isDisabled={uneditableKeys?.includes(q.id) || q.disabled}
            key={q.id}
            question={q}
            handleChange={handleChange(q.id)}
            value={inputValue}
            validation={error}
          />
        );
        break;
      case FieldType.checkboxgroup:
        items.push(
          <CheckBoxGroup
            inputTransformer={q.inputTransformer}
            isDisabled={uneditableKeys?.includes(q.id)}
            key={q.id}
            question={q}
            handleChange={(options: any) => setFieldValue(q.id, options)}
            value={inputValue}
            validation={error}
          />
        );
        break;
      case FieldType.array:
        items.push(
          <ArrayInput
            inputTransformer={q.inputTransformer}
            isDisabled={uneditableKeys?.includes(q.id)}
            key={q.id}
            question={q}
            handleChange={(options: any) => setFieldValue(q.id, options)}
            value={inputValue}
            validation={error}
          />
        );
        break;
      case FieldType.chipsinput:
        items.push(
          <ChipsInput
            inputTransformer={q.inputTransformer}
            isDisabled={uneditableKeys?.includes(q.id)}
            key={q.id}
            question={q}
            handleChange={(options: string[]) => setFieldValue(q.id, options)}
            value={inputValue}
            validation={error}
          />
        );
        break;
      case FieldType.richtextinput:
        items.push(
          <RichTextInput
            inputTransformer={q.inputTransformer}
            isDisabled={uneditableKeys?.includes(q.id)}
            key={q.id}
            question={q}
            handleChange={handleChange(q.id)}
            value={inputValue}
            validation={error}
          />
        );
        break;
      case FieldType.numeric:
        items.push(
          <NumericInput
            isDisabled={uneditableKeys?.includes(q.id)}
            key={q.id}
            question={q}
            handleChange={handleChange(q.id)}
            value={inputValue}
            validation={error}
          />
        );
        break;
      case FieldType.currency:
        items.push(
          <CurrencyInput
            isDisabled={uneditableKeys?.includes(q.id)}
            key={q.id}
            question={q}
            currency={q.currencyField ? values[q.currencyField] : q.currency}
            handleChange={handleChange(q.id)}
            value={inputValue}
            validation={error}
          />
        );
        break;
      case FieldType.email:
        items.push(
          <EmailInput
            inputTransformer={q.inputTransformer}
            isDisabled={uneditableKeys?.includes(q.id)}
            key={q.id}
            question={q}
            handleChange={handleChange(q.id)}
            value={inputValue}
            validation={error}
          />
        );
        break;
      case FieldType.dropdown:
        items.push(
          <DropDown
            isDisabled={uneditableKeys?.includes(q.id)}
            key={q.id}
            question={q}
            handleChange={handleChange(q.id)}
            value={inputValue}
            validation={error}
          />
        );
        break;
      case FieldType.multiselect:
        // @ts-ignore
        items.push(
          <MultiSelectDropDown
            isDisabled={uneditableKeys?.includes(q.id)}
            key={q.id}
            question={q}
            styles={q.styles}
            handleChange={(options) => setFieldValue(q.id, options)}
            value={inputValue}
            validation={error}
          />
        );
        break;
      case FieldType.multiselecttable:
        // @ts-ignore
        items.push(
          <MultiSelectTable
            isDisabled={uneditableKeys?.includes(q.id)}
            key={q.id}
            question={q}
            styles={q.styles}
            handleChange={(options) => setFieldValue(q.id, options)}
            value={inputValue}
            validation={error}
            setSearchTerm={q.setSearchTerm}
            searchTerm={q.searchTerm}
          />
        );
        break;
      case FieldType.warningPlaceholder:
        // @ts-ignore
        items.push(
          <>
            <p className="text-sm">{q.title}</p>
            <div className="bg-yellow-50 text-yellow-800 px-4 py-2 rounded-sm text-sm">
              {q.description}
            </div>
          </>
        );
        break;
      case FieldType.multiselectcombobox:
        // @ts-ignore
        items.push(
          <MultiSelectComboBox
            isDisabled={uneditableKeys?.includes(q.id)}
            key={q.id}
            question={q}
            handleChange={(options) => setFieldValue(q.id, options)}
            value={inputValue}
            validation={error}
          />
        );
        break;
      case FieldType.file:
        items.push(
          <FileInput
            id={q.id}
            key={q.id}
            label={q.title}
            filetypes={q.limits}
            filetypesDescription={q.description}
            setFieldValue={setFieldValue}
            clearForm={() => setFieldValue(q.id, undefined)}
          />
        );
        break;
      case FieldType.radiogroup:
        items.push(
          <RadioGroup
            isDisabled={uneditableKeys?.includes(q.id)}
            key={q.id}
            question={q}
            handleChange={handleChange(q.id)}
            value={inputValue}
            validation={error}
          />
        );
        break;
      case FieldType.combobox:
        items.push(
          <ComboBox
            inputTransformer={q.inputTransformer}
            valueTransformer={q.valueTransformer}
            isDisabled={uneditableKeys?.includes(q.id)}
            key={q.id}
            question={q}
            placeholder={q.placeholder}
            handleChange={(options) => setFieldValue(q.id, options)}
            value={inputValue}
            validation={error}
          />
        );
        break;
      case FieldType.inputlist:
        items.push(
          <InputList
            question={q}
            isDisabled={uneditableKeys?.includes(q.id)}
            key={q.id}
            handleChange={(options) => setFieldValue(q.id, options)}
            value={inputValue}
            validation={error}
          />
        );
        break;
      case FieldType.objectlist:
        items.push(
          <ObjectList
            question={q}
            isDisabled={uneditableKeys?.includes(q.id)}
            key={q.id}
            handleChange={(options) => setFieldValue(q.id, options)}
            value={inputValue}
            validation={error}
          />
        );
        break;
      case FieldType.switch:
        items.push(
          <Switch
            key={q.id}
            isDisabled={uneditableKeys?.includes(q.id)}
            handleChange={(checked) => setFieldValue(q.id, checked)}
            text={q.title}
            description={q.description}
            enabled={Boolean(inputValue)}
          />
        );
        break;
      case FieldType.checkboxlist:
        items.push(
          <CheckboxList
            key={q.id}
            isDisabled={uneditableKeys?.includes(q.id)}
            options={q.options}
            handleChange={(options) => setFieldValue(q.id, options)}
            text={q.title}
            validation={error}
            value={inputValue}
          />
        );
        break;
      case FieldType.daterangepicker:
        items.push(
          <DateRangePicker
            key={q.id}
            label={q.text}
            value={inputValue}
            handleChange={(item) => setFieldValue(q.id, item)}
            showFooter={true}
            showShortcuts={true}
          />
        );
        break;
      case FieldType.datepicker:
        items.push(
          <DatePicker
            id={q.id}
            inputTransformer={q.inputTransformer}
            key={q.id}
            title={q.title}
            value={inputValue}
            handleChange={(item: any) => setFieldValue(q.id, item)}
            validation={error}
          />
        );
        break;
      case FieldType.monthpicker:
        items.push(
          <MonthPicker
            id={q.id}
            inputTransformer={q.inputTransformer}
            key={q.id}
            label={q.text}
            title={q.title}
            value={inputValue}
            handleChange={(item: any) => setFieldValue(q.id, item)}
            validation={error}
          />
        );
        break;
      case FieldType.addbutton:
        items.push(
          <AddButton
            key={q.id}
            question={q}
            handleChange={handleChange(q.id)}
            value={inputValue}
            validation={error}
          />
        );
        break;
      default:
        console.log('Unknown field type: ' + q.type);
        break;
    }
  }
  return items;
}

export interface FormCardParams {
  config: FormConfig;
  classNames?: string;
  submitText?: string;
  initialValues?: unknown;
  validationSchema?: any;
  isDisabled?: boolean;
  validateOnMount?: boolean;
  uneditableKeys?: string[];
  onCancel?: () => void | Promise<void>;
  onSubmit?: (
    values: {},
    setSubmitting: (isSubmitting: boolean) => void,
    setFieldValue?: (id: any, value: any) => void
  ) => Promise<void>;
  onDelete?: () => void | Promise<void>;
  renderConfirmationModal?: ({ values }: { values: any }) => React.ReactElement;
}

const FormCard = ({
  config,
  classNames,
  renderConfirmationModal,
  onCancel,
  onSubmit = () => Promise.resolve(),
  validationSchema = () => ({}),
  validateOnMount = false,
  initialValues,
  isDisabled = false,
  submitText = 'Submit',
  uneditableKeys,
  onDelete,
}: FormCardParams) => {
  const { title, description, formSections } = config;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  return (
    <>
      <div className={classNames}>
        <div className="md:grid md:gap-2">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {title}
            </h3>
            <p className="mt-1 text-sm text-gray-500">{description}</p>
          </div>
          <div className="md:mt-0 md:col-span-2">
            <div className="space-y-4">
              <Formik
                validateOnMount={validateOnMount}
                enableReinitialize
                validationSchema={validationSchema}
                // @ts-ignore
                initialValues={initialValues}
                onSubmit={async (values, { setSubmitting, setFieldValue }) => {
                  await onSubmit(values, setSubmitting, setFieldValue);
                }}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  setFieldValue,
                  isValid,
                  handleSubmit,
                  isSubmitting,
                  validateForm,
                }) => {
                  return (
                    <>
                      {renderConfirmationModal ? (
                        <Modal
                          showModal={showConfirmationModal}
                          setShowModal={setShowConfirmationModal}
                          title={'Confirm Details'}
                          onComplete={async () => {
                            await handleSubmit();
                            setShowConfirmationModal(false);
                          }}
                        >
                          <div className="px-4 py-4">
                            {renderConfirmationModal({ values })}
                          </div>
                        </Modal>
                      ) : null}
                      <>
                        {formSections?.map((section) => (
                          <FormSection
                            title={section.title}
                            description={section.description}
                            key={`${section?.title}`}
                          >
                            {RenderFormFields(
                              section.fields,
                              values,
                              errors,
                              // @ts-ignore
                              handleChange,
                              setFieldValue,
                              section.components,
                              uneditableKeys
                            )}
                          </FormSection>
                        ))}
                      </>

                      <FormSubmit
                        onCancel={onCancel}
                        disabled={!isValid || isDisabled}
                        isLoading={isSubmitting}
                        submitText={submitText}
                        onDelete={onDelete}
                        onSubmit={async () => {
                          if (renderConfirmationModal) {
                            const formikErrors = await validateForm();

                            if (isEmptyOrUndefined(formikErrors)) {
                              setShowConfirmationModal(true);
                            }
                          } else {
                            await handleSubmit();
                          }
                        }}
                      />
                    </>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormCard;
