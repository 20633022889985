import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from '../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../context/PaginationContext';
import { GET_PROJECTS_SUMMARY } from '../../graphql/queries/projects';
import { useOrganisationAwareApollo } from '../../hooks/useOrganisationAwareApollo';
import moment from 'moment';
import { Button } from '../../components/Buttons/Button';
import {
  ActiveBadge,
  BlueBadge,
  DisabledBadge,
  YellowBadge,
} from '../../components/Badges/Badges';
import { UserProfileContext } from '../../context/UserProfileContext';
import { Permission } from '../../types/Permissions';
import NoProjects from '../../components/NoData/NoProjects';
import { ProjectStatus } from '../../types/Project';
import { statusToBadge } from './view';
import TextInput from '../../components/Inputs/TextInput';
import DropDown from '../../components/Inputs/Dropdown';

interface ProjectListProps {
  children?: React.ReactElement;
}

const headerMapping = {
  name: 'Project Name',
  internalId: 'Internal ID',
  status: 'Status',
  updatedAt: 'Updated At',
} as Record<string, any>;

const tabs: { name: string; value: ProjectStatus | 'STATUS_NOT_SET' }[] = [
  {
    name: 'No Status',
    value: 'STATUS_NOT_SET',
  },
  {
    name: 'Not Started',
    value: ProjectStatus.NOT_STARTED,
  },
  {
    name: 'In Progress',
    value: ProjectStatus.IN_PROGRESS,
  },
  {
    name: 'Complete',
    value: ProjectStatus.COMPLETE,
  },
];

const filterDimensions = [
  { id: 'name', name: 'Name' },
  { id: 'internalId', name: 'Internal ID' },
];

const ProjectsTable = ({
  data = [{}],
  NoData,
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
  fetchPageOfDataForExport,
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    NoData={NoData}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    fetchPageOfDataForExport={fetchPageOfDataForExport}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
  />
);

export const ProjectListPage = (props: ProjectListProps) => {
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [filterText, setFilterText] = useState<string>();
  const [selectedFilterDimension, setSelectedFilterDimension] =
    useState<string>('name');

  const { limit, offset, setOffset } = usePagination();

  const [debouncedFilterText, setDebouncedSearchTerm] = useState(filterText);

  const [projectStatus, setProjectStatus] = useState<
    ProjectStatus | 'STATUS_NOT_SET' | undefined
  >(undefined);

  const filter = debouncedFilterText
    ? {
        filters: [
          {
            field: selectedFilterDimension,
            stringFilters: [{ ilike: debouncedFilterText }],
          },
        ],
      }
    : null;

  const [fetch, { data, loading }] = useLazyQuery(GET_PROJECTS_SUMMARY, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetch({
      variables: {
        projectStatus,
        input: { limit, offset, ...(filter ? { filter } : {}) },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, debouncedFilterText, projectStatus]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setOffset(0);
      setDebouncedSearchTerm(filterText);
    }, 600);
    return () => {
      clearTimeout(handler);
    };
  }, [filterText, setOffset]);

  useEffect(() => {
    setOffset(0);
  }, [projectStatus]);

  const projectResults = data?.getProjects;

  const totalPages = Math.ceil(projectResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };
  const { userProfile } = useContext(UserProfileContext);
  return (
    <div>
      <div className="w-full flex justify-between items-center py-2">
        <h1 className="text-xl font-bold py-4">Projects</h1>
        {userProfile?.permissions?.includes(Permission.UpdateProject) ? (
          <Button
            text="New Project"
            onClick={() => navigate(`/projects/new`)}
          />
        ) : null}
      </div>
      <div className="bg-white px-4 py-2 gap-x-2 flex flex-col rounded-t-lg">
        <div className="flex gap-x-2 w-full items-center">
          <div className="w-full">
            <TextInput
              key="filter-selection"
              question={{
                id: 'Search',
                placeholder: `Filter by ${
                  filterDimensions?.find(
                    (dimension) => dimension.id === selectedFilterDimension
                  )?.name
                }`,
              }}
              handleChange={setFilterText}
              value={filterText}
            />
          </div>
          <div className="mt-2 w-52">
            <DropDown
              key={'filter_dimensions'}
              // @ts-ignore
              question={{
                options: filterDimensions?.map((opt: any) => ({
                  id: opt?.id,
                  name: opt?.name,
                  value: opt?.id,
                })),
              }}
              handleChange={(selectedValue) => {
                setSelectedFilterDimension(selectedValue);
              }}
              value={selectedFilterDimension}
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row w-full">
          {tabs.map(({ name, value }) => (
            <div
              className={`${
                projectStatus === value ? 'bg-black text-white' : ''
              } justify-center flex items-center`}
            >
              <Button
                style={{
                  borderRadius: 0,
                  backgroundColor: 'transparent',
                  color: projectStatus === value ? 'white' : 'black',
                }}
                text={name}
                onClick={() =>
                  projectStatus === value
                    ? setProjectStatus(undefined)
                    : setProjectStatus(value)
                }
              />
            </div>
          ))}
        </div>
      </div>

      <ProjectsTable
        currentPage={Math.floor(offset / limit) + 1}
        totalPages={totalPages}
        itemsPerPage={limit}
        totalResults={projectResults?.count || 0}
        onClickRow={(id: any) => navigate(`/projects/${id}`)}
        data={projectResults?.results.map((result: any) => ({
          ...result,
          name: (
            <div className="flex items-center">
              {result.colour ? (
                <div
                  className="w-6 h-6 mr-4 border rounded-md"
                  style={{ backgroundColor: result.colour }}
                />
              ) : (
                <div className="md:w-6 md:h-6 md:mr-4 border rounded-md bg-transparent" />
              )}
              <div>{result.name}</div>
            </div>
          ),
          internalId: result?.internalId ? (
            <BlueBadge text={result?.internalId} />
          ) : (
            <YellowBadge text={'Not Configured'} />
          ),
          status: (
            <div className="flex gap-x-2">
              {result.enabled ? <ActiveBadge /> : <DisabledBadge />}
              {result.status && statusToBadge[result.status as ProjectStatus]}
            </div>
          ),
        }))}
        NoData={() => (
          <NoProjects loading={loading} filterText={debouncedFilterText} />
        )}
        fetchPageOfDataForExport={async (limit: number, offset: number) => {
          const { data } = await fetch({
            variables: {
              projectStatus,
              input: { limit, offset, ...(filter ? { filter } : {}) },
            },
          });
          return data?.getProjects?.results ?? [];
        }}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export const ProjectList = () => (
  <PaginationProvider>
    <ProjectListPage />
  </PaginationProvider>
);
