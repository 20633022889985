import ComboBox from '../../../../../components/Inputs/ComboBox';

export const ContractSelection = ({
  contractSearchResults,
  setSearchTerm,
  setSelectedContract,
  selectedContract,
}: any) => {
  return (
    <div className="w-full md:w-1/3">
      <ComboBox
        testId="contract-selection"
        placeholder="Search by contract name"
        question={{
          id: 'Contract Search',
          options: contractSearchResults.map((contract: any) => ({
            id: contract.id,
            name: contract.name,
            detail: contract.internalId,
          })),
          onChange: (value) => setSearchTerm(value),
        }}
        handleChange={(id) => {
          setSelectedContract(
            contractSearchResults?.find((contract: any) => contract.id === id)
          );
        }}
        value={selectedContract?.name}
        validation={undefined}
      />
    </div>
  );
};
