import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Buttons/Button';
import { Table } from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';
import { UserProfileContext } from '../../../context/UserProfileContext';

import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { Permission } from '../../../types/Permissions';
import { GET_ASSESSMENT_TYPES_SUMMARY } from '../../../graphql/queries/assessments';
import { BlueBadge } from '../../../components/Badges/Badges';
import NoAssessments from '../../../components/NoData/NoAssessments';

interface AssessmentTypeListProps {
  showNewItemButton?: boolean;
  children?: React.ReactElement;
  filterIds?: string[];
  onClickRow?: (id: string, results: any[]) => void;
}

const headerMapping = {
  name: 'Name',
  email: 'Email',
  required: 'Required For',
  updatedAt: 'Updated At',
  dashboard: 'Dashboard',
} as Record<string, any>;

const AssessmentTypesTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
  filterText,
  setFilterText = () => {},
  selectedFilterDimension,
  setSelectedFilterDimension = () => {},
  NoData,
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    columnsToFilter={['firstName', 'lastName', 'id', 'status']}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    filterDimensions={[{ id: 'name', name: 'Name' }]}
    showFilterOptions
    filterText={filterText}
    setFilterText={setFilterText}
    selectedFilterDimension={selectedFilterDimension}
    setSelectedFilterDimension={setSelectedFilterDimension}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
    NoData={NoData}
  />
);

export const AssessmentTypeListPage = ({
  children,
  showNewItemButton,
  onClickRow,
  filterIds = [],
}: AssessmentTypeListProps) => {
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [filterText, setFilterText] = useState<string>();
  const [selectedFilterDimension, setSelectedFilterDimension] =
    useState<string>('name');

  const { limit, offset, setOffset } = usePagination();

  const [debouncedFilterText, setDebouncedSearchTerm] = useState(filterText);

  const filter = debouncedFilterText
    ? {
        filters: [
          {
            field: selectedFilterDimension,
            stringFilters: [{ ilike: debouncedFilterText }],
          },
        ],
      }
    : null;
  const [fetch, { data, loading }] = useLazyQuery(
    GET_ASSESSMENT_TYPES_SUMMARY,
    {
      variables: { input: { limit, offset } },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    fetch({
      variables: { input: { limit, offset, ...(filter ? { filter } : {}) } },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, debouncedFilterText]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setOffset(0);
      setDebouncedSearchTerm(filterText);
    }, 600);
    return () => {
      clearTimeout(handler);
    };
  }, [filterText, setOffset]);

  const assessmentTypeResults = data?.getAssessmentTypes;

  const totalPages = Math.ceil(assessmentTypeResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const { userProfile } = useContext(UserProfileContext);

  return (
    <div>
      <>
        {showNewItemButton ? (
          <div className="w-full flex justify-between items-center py-2">
            <h1 className="text-xl font-bold py-4">Assessment Types</h1>
            {userProfile?.permissions.includes(
              Permission.UpdateLabourProfiles
            ) ? (
              <Button
                text="New Assessment Type"
                onClick={() => navigate(`/labour/assessments/new`)}
              />
            ) : null}
          </div>
        ) : null}
        <p className="text-sm py-2 text-gray-600">
          Create assessments you can administer to your labour pool.
        </p>
        <AssessmentTypesTable
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          totalResults={assessmentTypeResults?.count || 0}
          onClickRow={(id: any) => {
            if (onClickRow) {
              onClickRow(id, assessmentTypeResults?.results);
            } else {
              navigate(`/labour/assessments/${id}/edit`);
            }
          }}
          filterText={filterText}
          setFilterText={setFilterText}
          selectedFilterDimension={selectedFilterDimension}
          setSelectedFilterDimension={setSelectedFilterDimension}
          NoData={() => (
            <NoAssessments loading={loading} filterText={debouncedFilterText} />
          )}
          data={assessmentTypeResults?.results
            .filter((result: any) => !filterIds?.includes(result.id))
            .map((result: any) => ({
              id: result?.id,
              name: result?.name,

              required: (
                <div className="flex flex-row flex-wrap gap-x-2 items-center justify-left">
                  {result?.requiredForEmployees ? (
                    <BlueBadge text="Employees" />
                  ) : null}
                  {result?.requiredForSubcontractors ? (
                    <BlueBadge text="Subcontractors" />
                  ) : null}
                  {result?.requiredForCompanies ? (
                    <BlueBadge text="Companies" />
                  ) : null}
                  {result?.requiredForSubcontractors ||
                  result?.requiredForSubcontractors ? (
                    result?.requiredForRoles?.length &&
                    result?.requiredForRoles?.length > 0 ? (
                      <BlueBadge
                        text={`Roles: ${result?.requiredForRoles?.length}`}
                      />
                    ) : (
                      <BlueBadge text={`Roles: All`} />
                    )
                  ) : null}
                </div>
              ),
              updatedAt: result?.updatedAt,
              dashboard: (
                <Button
                  style={{ background: 'black' }}
                  text="View Status"
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation();
                    navigate(`/labour/assessments/${result.id}/dashboard`);
                  }}
                />
              ),
            }))}
          onPageChange={handlePageChange}
        />
      </>
      {children}
    </div>
  );
};

export const AssessmentTypeList = ({
  onClickRow,
  showNewItemButton = true,
  filterIds = [],
}: AssessmentTypeListProps) => (
  <PaginationProvider>
    <AssessmentTypeListPage
      onClickRow={onClickRow}
      showNewItemButton={showNewItemButton}
      filterIds={filterIds}
    />
  </PaginationProvider>
);
