import { twMerge } from 'tailwind-merge';
import { ModuleItemCard } from '../../types/ModuleItemSummary';
import { ColourSummaryCard } from './ColourSummaryCard';

interface ModuleItemSummaryProps {
  moduleItemCards?: ModuleItemCard[];
  classNames?: string;
}

export const ModuleItemSummary = ({
  moduleItemCards,
  classNames,
}: ModuleItemSummaryProps) => {
  return (
    <div
      className={twMerge('flex flex-col md:flex-row gap-4 my-0', classNames)}
    >
      {moduleItemCards?.map((moduleItem) => (
        <ColourSummaryCard
          IconComponent={moduleItem.IconComponent}
          isExpandable={moduleItem.isExpandable}
          isActive={moduleItem.isActive}
          colour={moduleItem.colour}
          hoverColour={moduleItem.hoverColour}
          heading={moduleItem.heading}
          description={moduleItem.description}
          classNames={moduleItem.classNames}
          onClick={moduleItem.onClick}
        />
      ))}
    </div>
  );
};
