import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';

export default function MissingCertifications({
  children,
  className,
  missingOrInvalidCertifications,
  onClickCert,
}: {
  children: React.ReactNode;
  className?: string;
  missingOrInvalidCertifications?: any[];
  onClickCert?: (certification?: any) => void;
}) {
  return missingOrInvalidCertifications &&
    missingOrInvalidCertifications?.length > 0 ? (
    <div
      className={twMerge(
        'bg-red-50 border-b border-b-red-200 px-6 py-4 text-xs text-red-500 rounded-lg',
        className
      )}
    >
      <p className="mb-2">{children}</p>
      {missingOrInvalidCertifications?.map((certification: any) => {
        const allowsExemption = onClickCert && certification.allowsExemption;
        return (
          <li
            role={allowsExemption ? 'button' : undefined}
            onClick={
              allowsExemption ? () => onClickCert(certification) : undefined
            }
            className={twMerge(
              'flex my-1 rounded-sm',
              'items-center flex-row gap-x-2',
              onClickCert ? 'py-2 px-2' : '',
              allowsExemption ? 'bg-white rounded-xl border border-red-200' : ''
            )}
          >
            <ExclamationCircleIcon className="w-5" />
            {certification.name}{' '}
            {certification.notes ? `- ${certification?.notes}` : ''}
            {onClickCert && !certification.allowsExemption ? (
              <strong>
                This certification type does not allow exemptions.
              </strong>
            ) : null}
          </li>
        );
      })}
    </div>
  ) : null;
}
