import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Buttons/Button';

import { GET_MY_ASSESSMENT_SUBMISSIONS } from '../../../graphql/queries/my-profile';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import {
  ActiveBadge,
  DisabledBadge,
  RedBadge,
  YellowBadge,
} from '../../../components/Badges/Badges';
import moment from 'moment';
import { Table } from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';

const headerMapping = {
  name: 'Name',
  createdAt: 'Created At',
  project: 'Project',
  role: 'Role',
  outcome: 'Outcome',
  updatedAt: 'Updated At',
} as Record<string, any>;

const SentAssessmentsTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    columnsToFilter={['id']}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
      createdAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
  />
);

export const AssessmentSubmissionPage = () => {
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const { limit, offset, setOffset } = usePagination();
  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const [fetch, { data, loading, error }] = useLazyQuery(
    GET_MY_ASSESSMENT_SUBMISSIONS,
    {
      variables: {},
      fetchPolicy: 'network-only',
    }
  );

  const assessmentSubmissions = data?.getMyAssessmentSubmissions?.results || [];

  useEffect(() => {
    fetch({ variables: { input: { limit, offset } } });
  }, [limit, offset]);

  const totalPages = Math.ceil(data?.getMyAssessmentSubmissions?.count / limit);

  return (
    <div className="bg-gray-50">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="flex flex-col md:items-center md:flex-row w-full md:justify-between px-4 py-5 sm:px-6 flex-wrap">
          <div className="flex flex-row w-full justify-between">
            <h1 className="text-3xl font-extrabold text-gray-900">
              Assessments
            </h1>
          </div>
        </div>
        {loading && (
          <div className="h-64 flex items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
          </div>
        )}
        {error && (
          <p className="px-4 py-5 sm:p-6 text-red-500">
            Error loading data. Please try again.
          </p>
        )}
        <div className="px-2">
          <SentAssessmentsTable
            currentPage={Math.floor(offset / limit) + 1}
            totalPages={totalPages}
            itemsPerPage={limit}
            totalResults={data?.getMyAssessmentSubmissions?.count || 0}
            onClickRow={(id: any) => {
              navigate(`/profile/assessments/${id}`);
            }}
            data={
              assessmentSubmissions.length > 0
                ? assessmentSubmissions.map((result: any) => ({
                    id: result?.id,
                    name: result?.assessmentType?.name,
                    project: result?.assignmentRole?.assignment?.project?.name,
                    role: result?.assignmentRole?.role?.name,
                    outcome:
                      !result.responses && !result?.outcome ? (
                        <RedBadge text="Not Started Yet" />
                      ) : !result?.outcome ? (
                        <YellowBadge text="No Outcome Decided Yet" />
                      ) : (
                        <strong>{result?.outcome}</strong>
                      ),
                    createdAt: result?.createdAt,
                    updatedAt: result?.updatedAt,
                  }))
                : [{}]
            }
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export const MyAssessmentSubmission = () => (
  <PaginationProvider>
    <AssessmentSubmissionPage />
  </PaginationProvider>
);
