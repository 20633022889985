import { gql } from '@apollo/client';

export const SAVE_NEXT_OF_KIN = gql`
  mutation saveNextOfKin(
    $id: ID
    $input: SaveNextOfKinInput!
    $activeOrganisation: String!
  ) {
    saveNextOfKin(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const SAVE_MY_NEXT_OF_KIN = gql`
  mutation saveMyNextOfKin(
    $id: ID
    $input: SaveNextOfKinInput!
    $activeOrganisation: String!
  ) {
    saveMyNextOfKin(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;
