import { Link, useLocation } from 'react-router-dom';
import { useBasePath } from '../../hooks/useBasePath';
import { childRoutes } from '../../pages/routes';
import { joinClassNames } from '../../utils/utils';
import { useContext } from 'react';
import { UserProfileContext } from '../../context/UserProfileContext';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { NetworkContext } from '../../context/NetworkContext';
import { ActiveOrganisationContext } from '../../context/ActiveOrganisationContext';

interface TopBarProps {
  children?: React.ReactElement;
}

export const TopBar = ({}: TopBarProps) => {
  const { pathname } = useLocation();
  const { userProfile } = useContext(UserProfileContext);
  const { isOffline } = useContext(NetworkContext);

  const filteredChildRoutes = childRoutes
    .filter((route) => route.childOf && pathname.includes(route?.childOf))
    .filter((route) => (isOffline && route.isAvailableOffline) || !isOffline)
    .filter(
      (route) =>
        !route.permissionsRequired ||
        route.permissionsRequired?.every((permission) =>
          userProfile?.permissions?.includes(permission)
        )
    )
    .filter(
      (route) =>
        !route.labourType ||
        userProfile?.personalDetails?.type === route.labourType
    );
  const basePath = useBasePath();

  const thisRoute = childRoutes.find((route) => route.path === basePath);

  const { activeOrganisation } = useContext(ActiveOrganisationContext);

  return (
    <>
      {isOffline ? null : (
        <ul className="hidden md:flex py-6 2xl:py-2 flex-row justify-center gap-y-2 lg:gap-y-6 md:justify-start flex-wrap w-full items-center">
          {filteredChildRoutes
            .filter(
              (route) =>
                !route.docusealEnabledRequired ||
                (route.docusealEnabledRequired &&
                  activeOrganisation?.docusealEnabled)
            )
            .map((item) => (
              <li
                key={item.name}
                className="mt-2 md:mt-0 xl:border-l xl:border-dashed px-4"
              >
                <Link
                  style={
                    pathname === item.path ||
                    item.path === thisRoute?.matchRoute
                      ? { color: 'black', borderColor: 'rgba(0,0,0,0.2)' }
                      : {}
                  }
                  className={joinClassNames(
                    'text-gray-600 border-transparent hover:text-black hover:border-gray-400',
                    'group flex items-center gap-x-2 font-extrabold text-xs'
                  )}
                  to={item.path}
                >
                  {item.icon ? (
                    <item.icon
                      className={joinClassNames(
                        pathname === item.path ||
                          item.path === thisRoute?.matchRoute
                          ? 'text-gray-600'
                          : 'text-gray-200 group-hover:text-black',
                        'h-4 w-4 shrink-0'
                      )}
                      aria-hidden="true"
                    />
                  ) : null}
                  {item.name}
                </Link>
              </li>
            ))}
        </ul>
      )}
      {isOffline ? null : (
        <div className="md:hidden mt-4">
          <Menu as="div" className="relative inline-block text-left">
            <div className="flex items-center gap-x-2">
              Viewing:
              <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-base font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                {
                  filteredChildRoutes.find(
                    (item) =>
                      pathname === item.path ||
                      item.path === thisRoute?.matchRoute
                  )?.name
                }
                <ChevronDownIcon
                  className="-mr-1 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {filteredChildRoutes
                    .filter(
                      (route) =>
                        !route.docusealEnabledRequired ||
                        (route.docusealEnabledRequired &&
                          activeOrganisation?.docusealEnabled)
                    )
                    .map((item) => (
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            style={
                              pathname === item.path ||
                              item.path === thisRoute?.matchRoute
                                ? {
                                    color: 'black',
                                    borderColor: 'rgba(0,0,0,0.2)',
                                  }
                                : {}
                            }
                            className={joinClassNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                            to={item.path}
                          >
                            {item.name}
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      )}
    </>
  );
};
