import { gql } from '@apollo/client';

export const SAVE_ASSESSMENT_TYPE = gql`
  mutation saveAssessmentType(
    $id: ID
    $input: SaveAssessmentTypeInput!
    $activeOrganisation: String!
  ) {
    saveAssessmentType(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const SAVE_ASSESSMENT_SUBMISSION = gql`
  mutation saveAssessmentSubmission(
    $id: ID
    $input: SaveAssessmentSubmission!
    $activeOrganisation: String!
  ) {
    saveAssessmentSubmission(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const SAVE_MY_ASSESSMENT_SUBMISSION = gql`
  mutation saveMyAssessmentSubmission(
    $id: ID!
    $input: SaveMyAssessmentSubmission!
    $activeOrganisation: String!
  ) {
    saveMyAssessmentSubmission(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const SEND_ALL_REQUIRED_ASSESSMENTS = gql`
  mutation sendAllRequiredAssessments(
    $assessmentTypeId: ID!
    $activeOrganisation: String!
  ) {
    sendAllRequiredAssessments(
      assessmentTypeId: $assessmentTypeId
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const DELETE_ASSESSMENT_SUBMISSION = gql`
  mutation deleteAssessmentSubmission($activeOrganisation: String!, $id: ID) {
    deleteAssessmentSubmission(
      activeOrganisation: $activeOrganisation
      id: $id
    ) {
      id
      success
      message
    }
  }
`;
