import { isLocalhost } from './utils/utils';

const awsConfig = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION || 'eu-west-2',
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    signUpVerificationMethod: 'link',
  },
  oauth: {
    domain: `auth.${process.env.REACT_APP_DOMAIN}`,
    scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: `${window.location.origin},https://portal.${process.env.REACT_APP_DOMAIN}/`,
    redirectSignOut: `${window.location.origin}/,${process.env.REACT_APP_LOGOUT_URL}`,
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
  },

  Analytics: {
    disabled: true,
  },
  API: {
    graphql_endpoint: isLocalhost
      ? 'http://localhost:4001/graphql'
      : `https://pwms-api.${process.env.REACT_APP_DOMAIN}/graphql`,
  },
};

export default awsConfig;
