interface CardProps {
  heading?: string;
  description?: string;
  children?: any;
  classNames?: string;
}

export const Card = ({
  heading,
  description,
  children,
  classNames,
}: CardProps) => {
  return (
    <div
      className={`border-b rounded-lg shadow-sm border-gray-200 bg-white px-4 py-5 sm:px-6 ${classNames}`}
    >
      {heading ? (
        <h3 className="text-base font-medium leading-6 text-gray-900">
          {heading}
        </h3>
      ) : null}
      {description ? (
        <h3 className="text-sm leading-6 text-gray-500">{description}</h3>
      ) : null}
      {children}
    </div>
  );
};
