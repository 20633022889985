import { useEffect } from 'react';
import { useOrganisationAwareApollo } from '../useOrganisationAwareApollo';
import { GET_ROLES } from '../../graphql/queries/roles';
import { ApiHookMeta } from './types';

export default function useRoles({
  limit = 1000,
  offset = 0,
}: {
  limit?: number;
  offset?: number;
}): [any, ApiHookMeta] {
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [fetchRoles, { data, loading, error, ...result }] = useLazyQuery(
    GET_ROLES,
    {
      variables: { input: { limit, offset } },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  const roles = data?.getRoles?.results || [];
  const count = data?.getRoles?.count || 0;

  return [
    roles,
    {
      count,
      loading,
      error,
      ...result,
    },
  ];
}
