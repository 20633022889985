import { gql } from '@apollo/client';

export const SAVE_BANK_ACCOUNT = gql`
  mutation saveBankAccount(
    $id: ID
    $labourResourceId: ID!
    $input: SaveBankAccountInput!
    $activeOrganisation: String!
  ) {
    saveBankAccount(
      id: $id
      input: $input
      labourResourceId: $labourResourceId
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const SAVE_MY_BANK_ACCOUNT = gql`
  mutation saveMyBankAccount(
    $id: ID
    $input: SaveBankAccountInput!
    $activeOrganisation: String!
  ) {
    saveMyBankAccount(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;
