import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useContext, useEffect, useState } from 'react';

import FormCard from '../../components/FormCard/FormCard';

import moment from 'moment';
import Avatar from 'react-avatar';
import { YellowBadge } from '../../components/Badges/Badges';
import { UserProfileContext } from '../../context/UserProfileContext';
import { SAVE_USER_PROFILE } from '../../graphql/mutations/users';
import { SEARCH_PROJECTS } from '../../graphql/queries/projects';
import { GET_USERS, SEARCH_GROUPS } from '../../graphql/queries/users';
import { useOrganisationAwareApollo } from '../../hooks/useOrganisationAwareApollo';
import { FormConfig } from '../../types/Form';
import { Permission } from '../../types/Permissions';
import useDebounce from '../../hooks/useDebounce';

export const SaveUser = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { useLazyQuery, useMutation, useQuery } = useOrganisationAwareApollo();

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 600);

  const [groupSearchTerm, setGroupSearchTerm] = useState('');
  const debouncedGroupSearchTerm = useDebounce(groupSearchTerm, 600);

  const { userProfile } = useContext(UserProfileContext);

  const isUpdate = !!id;

  const [fetchUsers, { data }] = useLazyQuery(GET_USERS, {
    variables: { input: { id } },
    fetchPolicy: 'network-only',
  });

  const { data: searchProjectsData } = useQuery(SEARCH_PROJECTS, {
    variables: {
      searchTerm: debouncedSearchTerm,
      input: { limit: 1000, offset: 0 },
    },
    fetchPolicy: 'network-only',
  });

  const { data: searchGroupsData } = useQuery(SEARCH_GROUPS, {
    variables: {
      searchTerm: debouncedGroupSearchTerm,
      input: { limit: 1000, offset: 0 },
    },
    fetchPolicy: 'network-only',
  });

  const groupResults = searchGroupsData?.searchGroups?.results || [];
  const projectResults = searchProjectsData?.searchProjects?.results || [];

  useEffect(() => {
    fetchUsers({ variables: { input: { id } } });
  }, [id, isUpdate]);

  const [user] = data?.getUsers?.results || [];

  const [saveUser] = useMutation(SAVE_USER_PROFILE);

  const initialValues = id
    ? {
        ...user,
        projectFilter: user?.projectFilter ?? [],
        groups: user?.user_groups?.map((group: any) => group.id),
      }
    : {};

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      await saveUser({
        variables: {
          id: values?.id,
          input: {
            groups: values?.groups,
            projectFilter: values?.projectFilter,
            enabled: values?.enabled,
          },
        },
      });
      if (setSubmitting) {
        setSubmitting(false);
      }
      navigate(-1);
    } catch (error) {
      console.error(error);
    }
  };

  const userConfig = {
    formSections: [
      {
        title: 'User',
        components: [
          <hr />,
          <div className="flex gap-x-2">
            <Avatar
              name={`${user?.labourResource?.firstName} ${user?.labourResource?.lastName}`}
              size="35"
              round
            />
            <h1 className="text-3xl font-extrabold text-gray-900">
              {user?.labourResource?.firstName} {user?.labourResource?.lastName}
            </h1>
          </div>,
          <div className="text-gray-700 text-sm">
            Last Logged In:{' '}
            {user?.lastActivityTime
              ? moment.unix(user?.lastActivityTime / 1000).fromNow()
              : 'Never'}
          </div>,
          <div className="text-gray-700 text-sm">
            Email: {user?.email ?? <YellowBadge text="No Email Provided" />}
          </div>,
          <hr />,
        ],
        fields: user?.hasUserLogin
          ? [
              {
                id: 'groups',
                type: 'multiselecttable',
                title: 'Groups',
                options: groupResults.map((group: any) => ({
                  name: group.name,
                  value: group.id,
                })),
                setSearchTerm: setGroupSearchTerm,
                searchTerm: groupSearchTerm,
              },
              ...(userProfile?.permissions.includes(
                Permission.AssignProjectFilterToUser
              )
                ? userProfile?.personalDetails?.email !== user?.email
                  ? [
                      {
                        id: 'projectFilter',
                        type: 'multiselecttable',
                        title: 'Project Filter',
                        description:
                          'Select projects to filter this user to. Please note that some user permissions may still allow users to see beyond this filter.',
                        options: projectResults.map((project: any) => ({
                          name: project.name,
                          value: project.id,
                        })),
                        setSearchTerm,
                        searchTerm,
                      },
                    ]
                  : [
                      {
                        id: 'projectFilter',
                        type: 'warningPlaceholder',
                        title: 'Project Filter',
                        description: `You cannot modify your own project filter.`,
                      },
                    ]
                : []),
              {
                id: 'enabled',
                type: 'switch',
                title: 'Enabled',
              },
            ]
          : [],
      },
    ],
  } as FormConfig;

  const UserCreationSchema = Yup.object().shape({});

  return (
    <FormCard
      key={`${userConfig?.title}`}
      config={userConfig}
      isDisabled={!user?.hasUserLogin}
      validationSchema={UserCreationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
};
