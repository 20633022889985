import ValidationMessage from '../Validation/ValidationMessage';

const ColourInput = (props) => {
  const { question, handleChange, value, validation, isDisabled } = props;
  const {
    id,
    title,
    placeholder,
    name,
    classNames,
    description,
    inputTransformer = (text) => text,
  } = question;

  return (
    <div className={classNames}>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {title}
      </label>
      {validation ? <ValidationMessage message={validation} /> : null}
      <input
        type="color"
        id={id}
        name={name}
        value={value || ''}
        disabled={isDisabled}
        onChange={(event) => handleChange(inputTransformer(event.target.value))}
        autoComplete={name}
        className="w-full mt-2 h-10"
        placeholder={placeholder}
      />
      {description ? (
        <p className="mt-2 text-sm text-gray-500">{description}</p>
      ) : null}
    </div>
  );
};

export default ColourInput;
