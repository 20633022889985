import moment from 'moment';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../components/Buttons/Button';
import { Table } from '../../../components/Tables/tables/Table';
import { GET_SUBCONTRACTORS } from '../../../graphql/queries/subcontractors';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { Currency } from '../../../types/Currency';
import { ProjectAssignmentList } from '../../Projects/assignments/list';
import { CompanyProfile } from './company/profile';
import { NextOfKinDetail } from '../nextOfKin/profile';
import { BankingDetails } from './banking/profile';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';
import { SkillDetail } from '../skills/profile';
import { CertificationDetail } from '../certifications/profile';
import Avatar from 'react-avatar';
import { GET_CUSTOM_FIELDS } from '../../../graphql/queries/custom-data';
import NotificationIcon from '../../../components/Notification/NotificationIcon';
import { useTabs } from '../../../hooks/useTabs';
import { LoadingSpinner } from '../../../components/Loading/LoadingSpinner';
import CvExportList from '../cv/CvExportList';

const Tabs = [
  'Summary',
  'Company',
  'Banking',
  'Next of Kin',
  'Assignments',
  'Skills',
  'Certifications',
  'CV',
];

export const SubcontractorPage = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [activeTab, setActiveTab] = useTabs(Tabs);

  const [fetch, { data, loading, error }] = useLazyQuery(GET_SUBCONTRACTORS, {
    variables: { input: { id } },
    fetchPolicy: 'network-only',
  });

  const [fetchCustomFields, { data: customFieldsData }] = useLazyQuery(
    GET_CUSTOM_FIELDS,
    {
      variables: { labourResourceId: id, onlyEnabled: true },
      fetchPolicy: 'network-only',
    }
  );

  const [subcontractor] = data?.getSubcontractors?.results || [];

  const tabHasNotification: Record<string, boolean> = {
    Company: !subcontractor?.company,
    Certifications: subcontractor?.missingOrInvalidCertifications?.length,
  };

  const headerMapping = {
    currency: 'Currency',
    key: 'Key',
    rate: 'Rate',
    updatedAt: 'Updated At',
  } as Record<string, any>;

  const currencies = Object.entries(Currency).filter((e: any) => isNaN(e));

  const sections = [
    {
      title: 'Email',
      content: subcontractor?.email || 'Not Configured',
    },
    {
      title: 'Internal Id',
      content: subcontractor?.internalId ?? 'Empty',
    },
    {
      title: 'Job Title',
      content: subcontractor?.jobTitle || 'Not Configured',
    },
    {
      title: 'Default Role',
      content: subcontractor?.defaultRole.name || 'Not Configured',
    },
    {
      title: 'Personal Profile',
      content: subcontractor?.personalProfile || 'Not Configured',
    },
    {
      title: 'Project Experience',
      content: subcontractor?.projectExperience ? (
        <div
          dangerouslySetInnerHTML={{
            __html: subcontractor?.projectExperience,
          }}
        />
      ) : (
        'Not Configured'
      ),
    },
    {
      title: 'Nationality',
      content: subcontractor?.nationality ?? 'Empty',
    },
    {
      title: 'Country of Residence',
      content: subcontractor?.countryOfResidence ?? 'Empty',
    },
    ...currencies.map(([key, currencySymbol]) => {
      const ratesForThisCurrency = subcontractor?.defaultRate?.filter(
        (rate: any) => rate.currency === key
      );
      return {
        title: ratesForThisCurrency?.length > 0 ? `Default ${key} Rates` : null,
        content:
          ratesForThisCurrency?.length > 0 ? (
            <Table
              data={ratesForThisCurrency.map((rate: any) => ({
                key: rate.name,
                rate: `${currencySymbol}${new Intl.NumberFormat('en-GB').format(
                  rate.rate
                )}`,
              }))}
              headerMapper={(text: string) => headerMapping[text]}
              onClickRow={() => {}}
              onPageChange={() => {}}
              showPagination={false}
              totalPages={1}
              totalResults={1}
              currentPage={0}
              itemsPerPage={subcontractor.defaultRate?.length}
            />
          ) : null,
      };
    }),
  ];

  const { userProfile } = useContext(UserProfileContext);

  const getOptionSetNameByValue = (customOptionId: any) => {
    const fieldGroups = customFieldsData?.getCustomFieldGroups?.results;
    if (!fieldGroups) {
      return null;
    }
    for (const fieldGroup of fieldGroups) {
      const foundOption = fieldGroup.custom_fields?.find(
        (option: any) => option.id === customOptionId
      );
      if (foundOption) {
        return foundOption.name;
      }
    }

    return null;
  };

  const customSections = (subcontractor?.customData || [])
    .map((customItem: any) => ({
      title: getOptionSetNameByValue(customItem.key),
      content: customItem.value,
    }))
    ?.filter((item: any) => item.title);

  useEffect(() => {
    fetch({ variables: { input: { id } } });
    fetchCustomFields();
  }, [fetch, fetchCustomFields, id]);

  return (
    <div>
      <div className="flex w-full border-b mb-2">
        <Button
          style={{
            backgroundColor: 'transparent',
            color: 'black',
            boxShadow: 'none',
            borderRadius: 0,
          }}
          onClick={() => navigate(-1)}
          type="submit"
          text={`< Go Back`}
        />
      </div>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="w-full px-2 bg-gray-800 py-2 flex flex-col md:flex-row">
          {Tabs.filter(
            (tab) =>
              tab !== 'Skills' ||
              (tab === 'Skills' &&
                userProfile?.permissions?.includes(Permission.ViewSkills))
          ).map((tab) => (
            <div
              className={`${
                activeTab === tab ? 'bg-black' : ''
              } justify-center flex items-center`}
            >
              <Button
                style={{ borderRadius: 0, backgroundColor: 'transparent' }}
                className={
                  tabHasNotification[tab] ? 'flex relative gap-y-2' : ''
                }
                onClick={() => {
                  setActiveTab(tab);
                }}
              >
                {tab}
                {tabHasNotification[tab] ? <NotificationIcon /> : null}
              </Button>
            </div>
          ))}
        </div>
        <div className="flex flex-col md:items-center md:flex-row w-full md:justify-between px-4 py-5 sm:px-6 flex-wrap">
          <div className="flex flex-col">
            <div className="flex gap-x-2">
              <Avatar
                name={`${subcontractor?.firstName} ${subcontractor?.lastName}`}
                size="35"
                round
              />
              <h1 className="text-3xl font-extrabold text-gray-900">
                {subcontractor?.firstName} {subcontractor?.lastName}
              </h1>
            </div>
            <p className="mt-4 text-sm text-gray-500">
              Created: {moment.unix(subcontractor?.createdAt / 1000).fromNow()}
            </p>
            <p className="mt-1 text-sm text-gray-500">
              Updated: {moment.unix(subcontractor?.updatedAt / 1000).fromNow()}
            </p>
          </div>
          {userProfile?.permissions.includes(
            Permission.UpdateLabourProfiles
          ) ? (
            <div className="mt-2 md:mt-0">
              {['Summary', 'Next of Kin', 'CV'].includes(activeTab) ||
              (activeTab === 'Company' &&
                (subcontractor?.isOwnerOfCompany ||
                  !subcontractor?.company)) ? (
                <Button
                  onClick={() => {
                    if (activeTab === 'Summary') {
                      navigate(`/labour/subcontractors/${id}/edit`);
                    }
                    if (activeTab === 'Next of Kin') {
                      navigate(`/labour/subcontractors/${id}/nextOfKin/edit`);
                    }
                    if (activeTab === 'Company') {
                      navigate(`/labour/subcontractors/${id}/company/edit`);
                    }
                  }}
                  type="submit"
                  text="Edit"
                />
              ) : null}
            </div>
          ) : null}
        </div>

        {loading && (
          <div className="h-64 flex items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
          </div>
        )}
        {error && (
          <p className="px-4 py-5 sm:p-6 text-red-500">
            Error loading data. Please try again.
          </p>
        )}
        {activeTab === 'Summary' ? (
          subcontractor ? (
            <div className="border-t border-gray-200">
              {sections.map((section, index) =>
                section.title || section.content ? (
                  <div key={index} className="px-4 py-4">
                    <h2 className="text-lg font-medium text-gray-900">
                      {section.title}
                    </h2>
                    <p className="mt-1 text-sm text-gray-500">
                      {section.title === 'Personal Profile' ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: section.content,
                          }}
                        />
                      ) : (
                        section.content
                      )}
                    </p>
                  </div>
                ) : null
              )}
              {customSections.map((section: any, index: number) => (
                <div key={`custom-${index}`} className="px-4 py-4">
                  <h2 className="text-lg font-medium text-gray-900">
                    {section.title}
                  </h2>
                  <p className="mt-1 text-sm text-gray-500">
                    {section.content}
                  </p>
                </div>
              ))}
            </div>
          ) : (
            <p>No data available</p>
          )
        ) : null}
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            {activeTab === 'Assignments' ? (
              <ProjectAssignmentList assigneeId={id} />
            ) : null}
            {activeTab === 'Company' ? (
              <CompanyProfile labourResource={subcontractor} />
            ) : null}
            {activeTab === 'Next of Kin' ? (
              <NextOfKinDetail labourResourceId={id} />
            ) : null}
            {activeTab === 'Banking' ? (
              <BankingDetails labourResourceId={id} />
            ) : null}
            {activeTab === 'Skills' ? (
              <SkillDetail
                labourResourceId={id}
                labourPath={'subcontractors'}
              />
            ) : null}
            {activeTab === 'Certifications' ? (
              <CertificationDetail
                labour={subcontractor}
                labourResourceId={id}
                labourPath={'subcontractors'}
              />
            ) : null}
            {activeTab === 'CV' ? (
              <CvExportList labour={subcontractor} labourResourceId={id!} />
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export const Subcontractor = () => <SubcontractorPage />;
