import { PlusCircleIcon } from '@heroicons/react/24/outline';

const AddButton = (props: any) => {
  const { question } = props;
  const { id, title, classNames, description, onClick } = question;

  return (
    <div className={classNames}>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {title}
      </label>
      <PlusCircleIcon
        data-testid="add-button"
        className="h-8 w-8 my-1 cursor-pointer"
        aria-hidden="true"
        onClick={onClick}
      />
      {description ? (
        <p className="text-sm text-gray-500">{description}</p>
      ) : null}
    </div>
  );
};

export default AddButton;
