import moment from 'moment';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../../components/Buttons/Button';
import { Table } from '../../../../components/Tables/tables/Table';
import { PaginationProvider } from '../../../../context/PaginationContext';
import { SAVE_ASSIGNMENT_ROLE } from '../../../../graphql/mutations/assignments';
import { useOrganisationAwareApollo } from '../../../../hooks/useOrganisationAwareApollo';
import { RoleList } from '../../../Labour/roles/list';
import { useToast } from '../../../../context/ToastContext';
import Switch from '../../../../components/Inputs/Switch';
import { useAssignment } from '../../../../hooks/api/useAssignments';
import useProject from '../../../../hooks/api/projects/useProject';

const headerMapping = {
  name: 'Name',
  email: 'Email',
  type: 'Type',
  internalId: 'Internal ID',
  status: 'Status',
  updatedAt: 'Updated At',
} as Record<string, any>;

const AssignmentRolesTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    showPagination={false}
    columnsToFilter={['firstName', 'lastName', 'id']}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
  />
);

export const AssignRoleToAssigneePage = () => {
  let { assignmentId, projectId } = useParams();
  const navigate = useNavigate();

  const { addToast } = useToast();

  const { useMutation } = useOrganisationAwareApollo();

  const [sendAssessments, setSendAssessments] = useState(false);

  const [saveAssignmentRole, { loading: isSaving }] =
    useMutation(SAVE_ASSIGNMENT_ROLE);

  const [assignmentRoles, setAssignmentRoles] = useState<any>([]);

  const [assignment] = useAssignment({
    id: assignmentId!,
    projectId: projectId!,
  });

  const [project] = useProject(projectId);

  const saveAllRoles = async () => {
    try {
      // merge the two arrays into one
      const allAssignmentRoles = [
        ...assignmentRoles.map((assignmentRole: any) => ({
          ...assignmentRole,
        })),
      ];

      // create a promise for each assignmentrole
      const assignmentRolePromises = allAssignmentRoles.map((role) =>
        saveAssignmentRole({
          variables: {
            input: {
              assignmentId: assignment.id,
              roleId: role.id,
              sendAssessments,
            },
          },
        })
      );

      await Promise.all(assignmentRolePromises);
      navigate(`/projects/${projectId}/assignments/${assignmentId}`);
      addToast('Roles saved successfully', 'success');
    } catch (error) {
      console.error(error);
      addToast('Failed to save roles', 'error');
    }
  };

  const assigneeName = `${assignment?.lrs?.lastName}, ${assignment?.lrs?.firstName}`;

  return (
    <div className="bg-gray-50">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="py-2 border-b">
          {assignment?.project?.name ? (
            <Button
              style={{
                backgroundColor: 'transparent',
                color: 'gray',
                boxShadow: 'none',
                borderRadius: 0,
              }}
              onClick={() => navigate(`/projects/${projectId}`)}
              type="submit"
              text={`< Back to Project: ${assignment?.project?.name}`}
            />
          ) : null}
        </div>
        <div className="flex flex-col md:items-center md:flex-row w-full md:justify-between px-4 py-5 sm:px-6 flex-wrap">
          <div className="flex flex-col">
            <h1 className="text-3xl font-extrabold text-gray-900">
              Assign Role to {assigneeName}
            </h1>
            <h2 className="text-lg mt-1 font-base text-gray-500">
              Project: {project?.name} {project?.internalId}
            </h2>
          </div>
        </div>
        <div>
          {assignmentRoles.length > 0 ? (
            <p className="text-sm px-4 text-gray-800 pb-2 border-b">
              Note: You can click on a row to remove roles.
            </p>
          ) : null}
          {assignmentRoles.length > 0 ? (
            <div className="mb-2 px-2">
              <h4 className="my-2 px-4 text-sm text-gray-600">
                Assigning {assignmentRoles.length} Role
                {assignmentRoles.length === 1 ? '' : 's'}
              </h4>
              <AssignmentRolesTable
                totalPages={null}
                itemsPerPage={0}
                onClickRow={(id: any) => {
                  setAssignmentRoles(
                    assignmentRoles.filter(
                      (subcontractorAssignmentRole: any) =>
                        subcontractorAssignmentRole.id !== id
                    )
                  );
                }}
                data={
                  assignmentRoles.length > 0
                    ? assignmentRoles?.map((result: any) => ({
                        name: `${result.lastName}, ${result.firstName}`,
                        ...result,
                      }))
                    : [{}]
                }
              />
            </div>
          ) : null}
        </div>

        {assignmentRoles.length > 0 ? (
          <div className="flex w-full items-center gap-x-4">
            <div className="mt-4 mx-2 mb-4">
              <Button
                isLoading={isSaving}
                text="Save Roles"
                onClick={async () => {
                  await saveAllRoles();
                }}
              />
            </div>
            <Switch
              text="Send all required assessments"
              enabled={sendAssessments}
              handleChange={() => setSendAssessments((prev) => !prev)}
            />
          </div>
        ) : null}

        <div className="my-4 border-t py-2 px-4">
          <p className="text-sm px-4 text-gray-600">
            Select roles from the list below to assign to the resource on this
            assignment.
          </p>
          <hr className="my-2 px-10" />

          <>
            <h4 className="my-2 px-4">Roles</h4>
            <RoleList
              filterIds={assignmentRoles.map(
                (assignmentrole: any) => assignmentrole.id
              )}
              showNewItemButton={false}
              onClickRow={(id, results) => {
                if (
                  !assignmentRoles.find(
                    (assignmentrole: any) => assignmentrole.id === id
                  )
                ) {
                  setAssignmentRoles((assignmentroles: any) => [
                    ...assignmentroles,
                    results?.find((result) => result.id === id),
                  ]);
                }
              }}
            />
          </>
        </div>
      </div>
    </div>
  );
};

export const AssignRoleToAssignee = () => (
  <PaginationProvider>
    <AssignRoleToAssigneePage />
  </PaginationProvider>
);
