import { gql } from '@apollo/client';

export const SAVE_NOTIFICATIONS_CONFIG = gql`
  mutation saveNotificationsConfig(
    $activeOrganisation: String!
    $input: SaveNotificationsConfigInput!
  ) {
    saveNotificationsConfig(
      activeOrganisation: $activeOrganisation
      input: $input
    ) {
      success
      message
    }
  }
`;
