import ValidationMessage from '../Validation/ValidationMessage';

import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';

import { Question } from '../../types/QuestionTypes';
import { joinClassNames } from '../../utils/utils';

interface DropDownParams {
  question: Question;
  handleChange: (value: string) => void;
  value: any;
  validation?: any;
  classNames?: string;
  isDisabled?: boolean;
}

const DropDown = ({
  question,
  handleChange,
  value,
  validation,
  classNames,
  isDisabled,
}: DropDownParams) => {
  const { id, title, options, label, description } = question;

  return (
    <div className={classNames}>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {title ? title : null}
      </label>
      {validation ? <ValidationMessage message={validation} /> : null}
      <Menu as="div" className="relative inline-block text-left my-2">
        {label ? (
          <label
            htmlFor="name"
            className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs text-gray-900"
          >
            {label}
          </label>
        ) : null}
        <div>
          <Menu.Button
            id={id}
            data-testid={'keyDropdownButton'}
            disabled={isDisabled}
            className="inline-flex disabled:bg-gray-100 disabled:opacity-90 justify-center rounded-md border border-gray-300 items-center bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-gray-100"
          >
            {options?.find((option) => option.name === value)?.colour ? (
              <div
                className="mr-2 border"
                style={{
                  width: 20,
                  height: 20,
                  backgroundColor:
                    options?.find((option) => option.name === value)?.colour ||
                    'white',
                }}
              ></div>
            ) : null}
            {options?.find((option) => option.value === value)?.name ||
              value ||
              'Empty'}
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            style={{ zIndex: 99999, maxHeight: '200px' }}
            className="absolute transform -translate-y-full -top-2 left-0 mt-0 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-y-scroll"
          >
            <div className="py-1">
              {/* @ts-ignore */}
              {options?.map((option) => (
                <Menu.Item
                  key={option.name}
                  // @ts-ignore
                  onClick={
                    isDisabled
                      ? () => null
                      : () => handleChange(option.value ?? option.name)
                  }
                >
                  {({ active }) => (
                    <div
                      className={joinClassNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'flex px-4 py-2 text-sm'
                      )}
                    >
                      {option.colour ? (
                        <div
                          className="mr-2 border"
                          style={{
                            width: 20,
                            height: 20,
                            backgroundColor: option.colour || 'white',
                          }}
                        ></div>
                      ) : null}
                      <p className="flex items-center">
                        {option.name ?? 'Empty'}
                        {option.detail ? (
                          <p className="ml-1 text-gray-400 text-xs font-light">
                            ({option.detail})
                          </p>
                        ) : null}
                      </p>
                    </div>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      {description ? (
        <p className="mt-2 text-sm text-gray-500">{description}</p>
      ) : null}
    </div>
  );
};

export default DropDown;
