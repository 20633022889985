import { gql } from '@apollo/client';

export const GET_NEXT_OF_KIN_DETAILS = gql`
  query getNextOfKinDetails(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $labourResourceId: ID
  ) {
    getNextOfKinDetails(
      input: $input
      activeOrganisation: $activeOrganisation
      labourResourceId: $labourResourceId
    ) {
      count
      results {
        id
        name
        relationship
        address
        contactNumber
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_BANK_ACCOUNTS = gql`
  query getBankAccounts(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $labourResourceId: ID
  ) {
    getBankAccounts(
      input: $input
      activeOrganisation: $activeOrganisation
      labourResourceId: $labourResourceId
    ) {
      count
      results {
        id
        bankName
        nameOnAccount
        accountNumber
        sortCode
        iban
        bic
        currency
        enabled
        isBusinessAccount
        createdAt
        updatedAt
      }
    }
  }
`;
