import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Fragment, useContext } from 'react';
import { ActiveOrganisationContext } from '../../context/ActiveOrganisationContext';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function OrganisationPicker({ pickerAlign = 'right' }) {
  const { organisations, activeOrganisation, setActiveOrganisation } =
    useContext(ActiveOrganisationContext);

  return (
    <Menu
      as="div"
      className="self-center relative w-12 xl:w-24 inline-block text-left"
    >
      <div>
        {activeOrganisation ? (
          <Menu.Button
            id="organisation-picker"
            aria-label="Organisation Picker"
            className="inline-flex w-full justify-center gap-x-1.5 rounded-md py-2 text-sm font-semibold text-gray-900"
          >
            <div className="flex items-center h-10 w-10 sm:h-12 sm:w-12 overflow-hidden flex-shrink-0 hover:scale-105">
              <img
                className="rounded-sm"
                src={activeOrganisation.logoUrl}
                alt={`Company logo for ${activeOrganisation.companyName}`}
              />
            </div>
            {organisations && organisations.length > 1 && (
              <ChevronDownIcon
                data-testid={'chevron-down'}
                className="h-5 w-5 mr-2 text-gray-400 self-center"
                aria-hidden="true"
              />
            )}
          </Menu.Button>
        ) : null}
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute ${pickerAlign}-0 z-10 mt-2 min-w-max max-w-32 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <div className="py-1">
            {organisations &&
              organisations.map((org) => {
                return (
                  <div key={org.companyName}>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'group flex items-center px-4 py-2 text-sm'
                          )}
                          onClick={() =>
                            setActiveOrganisation && setActiveOrganisation(org)
                          }
                        >
                          <div className="flex items-center h-14 w-14 overflow-hidden flex-shrink-0 hover:scale-105">
                            <img
                              className="rounded-sm"
                              src={org.logoUrl}
                              alt={`Company logo`}
                            />
                          </div>
                          <p className="pl-4 w-32 text-xs text-left">
                            {org.companyName}
                          </p>
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                );
              })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
