import { gql } from '@apollo/client';

export const SAVE_COMPANY_DETAILS = gql`
  mutation saveCompanyDetails(
    $id: ID
    $companyId: ID
    $labourResourceId: ID
    $input: CompanyProfileInput!
    $activeOrganisation: String!
  ) {
    saveCompanyDetails(
      id: $id
      companyId: $companyId
      labourResourceId: $labourResourceId
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const SAVE_MY_COMPANY_DETAILS = gql`
  mutation saveMyCompanyDetails(
    $input: CompanyProfileInput!
    $activeOrganisation: String!
  ) {
    saveMyCompanyDetails(
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const DELETE_COMPANY_PROFILE = gql`
  mutation deleteCompanyProfile($id: ID, $activeOrganisation: String!) {
    deleteCompanyProfile(id: $id, activeOrganisation: $activeOrganisation) {
      success
      message
    }
  }
`;

export const ASSIGN_LABOUR_TO_COMPANY = gql`
  mutation assignLabourToCompany(
    $companyId: ID!
    $labourResourceIds: [ID]!
    $activeOrganisation: String!
  ) {
    assignLabourToCompany(
      companyId: $companyId
      labourResourceIds: $labourResourceIds
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const UNASSIGN_LABOUR_FROM_COMPANY = gql`
  mutation unassignLabourFromCompany(
    $companyId: ID!
    $labourResourceIds: [ID]!
    $activeOrganisation: String!
  ) {
    unassignLabourFromCompany(
      companyId: $companyId
      labourResourceIds: $labourResourceIds
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const ASSIGN_COMPANIES_TO_PROJECT = gql`
  mutation assignCompaniesToProject(
    $activeOrganisation: String!
    $projectId: ID!
    $companyIds: [ID]!
    $sendAssessments: Boolean
  ) {
    assignCompaniesToProject(
      activeOrganisation: $activeOrganisation
      projectId: $projectId
      companyIds: $companyIds
      sendAssessments: $sendAssessments
    ) {
      success
      message
    }
  }
`;
