import { useNavigate } from 'react-router-dom';
import { usePagination } from '../../../context/PaginationContext';
import { useCallback, useEffect, useMemo } from 'react';
import { Table } from '../../../components/Tables/tables/Table';
import moment from 'moment';
import { ApiHookMeta } from '../../../hooks/api/types';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { GET_MISSING_RATES_FOR_PROJECT } from '../../../graphql/queries/projects';

function useMissingRates({
  projectId,
  labourResourceType,
  limit,
  offset,
}: {
  certificationTypeId?: string;
  projectId: string;
  labourResourceType: 'EMPLOYEE' | 'SUBCONTRACTOR';
  limit?: number;
  offset?: number;
}): [any, ApiHookMeta] {
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [fetch, { data, loading, error, ...result }] = useLazyQuery(
    GET_MISSING_RATES_FOR_PROJECT,
    {
      variables: {
        input: { limit, offset },
        projectId,
        labourResourceType,
      },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  const results = data?.getMissingRatesForProject?.results || [];
  const count = data?.getMissingRatesForProject?.count || 0;

  return [
    results,
    {
      count,
      loading,
      error,
      ...result,
    },
  ];
}

export function MissingRatesTable({
  projectId,
  labourResourceType,
}: {
  projectId: string;
  labourResourceType: 'EMPLOYEE' | 'SUBCONTRACTOR';
}) {
  const navigate = useNavigate();
  const { limit, offset, setOffset } = usePagination();

  const [assignmentsMissingRates, { count }] = useMissingRates({
    projectId,
    labourResourceType,
    limit,
    offset,
  });

  const headerMapping = useMemo(
    () =>
      ({
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        roles: 'Roles',
        updatedAt: 'Updated',
      } as Record<string, any>),
    []
  );

  const totalPages = Math.ceil(count / limit);

  const onPageChange = useCallback(
    (pageNumber: number) => {
      setOffset((pageNumber - 1) * limit);
    },
    [limit, setOffset]
  );

  return (
    <Table
      currentPage={Math.floor(offset / limit) + 1}
      totalPages={totalPages}
      totalResults={count}
      itemsPerPage={limit}
      onClickRow={(id: any) => {
        navigate(`/projects/${projectId}/assignments/${id}`);
      }}
      filterDimensions={[]}
      showFilterOptions={false}
      headerMapper={(text: string) => headerMapping[text]}
      onPageChange={onPageChange}
      data={assignmentsMissingRates.map(
        ({
          id,
          lrs: { id: lrsId, firstName, lastName, email, updatedAt },
        }: any) => ({
          id,
          lrsId,
          firstName,
          lastName,
          email,
          type: null,
          updatedAt: moment.unix(parseInt(updatedAt) / 1000).fromNow(),
        })
      )}
    />
  );
}
