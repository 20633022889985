export type Question = {
  id: string;
  title?: string;
  label?: string;
  description?: string;
  options?: Option[];
  tableOptions?: TableOption[];
  onChange?: (text: string) => void;
  placeholder?: string;
  name?: string;
  classNames?: string;
};

type Heading = {
  id: string;
  name: string;
  colour?: string;
};

type RowOption = {
  heading: Heading;
  option: Option;
};

export type TableOption = {
  heading: Heading;
  options: RowOption[];
};

export type Option = {
  id: string;
  name: string;
  value?: string;
  detail?: string;
  colour?: string;
};

export interface Choice {
  id: string;
  name: string;
}

export enum QuestionType {
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  FREE_TEXT = 'FREE_TEXT',
}

export interface QuestionBuilderQuestion {
  id: string;
  question: string;
  type?: QuestionType;
  choices: Choice[];
}
