import { useEffect } from 'react';
import { useOrganisationAwareApollo } from '../../useOrganisationAwareApollo';
import { ApiHookMeta } from '../types';
import { GET_PROJECT_COMPANIES } from '../../../graphql/queries/companies';

export default function useProjectCompanies({
  id,
  companyId,
  projectId,
  filter,
  limit,
  offset,
}: {
  id?: string;
  companyId?: string;
  projectId?: string;
  filter?: any;
  limit?: number;
  offset?: number;
}): [any, ApiHookMeta] {
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [fetch, { data, loading, error, ...result }] = useLazyQuery(
    GET_PROJECT_COMPANIES,
    {
      variables: { companyId, projectId, input: { id, limit, offset } },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    fetch({
      variables: {
        companyId,
        projectId,
        input: {
          id,
          limit,
          offset,
          ...(filter ? { filter } : {}),
        },
      },
    });
  }, [limit, offset, filter, fetch, id, projectId, companyId]);

  const projectCompanies = data?.getProjectCompanies?.results || [];
  const count = data?.getProjectCompanies?.count || 0;

  return [
    projectCompanies,
    {
      count,
      loading,
      error,
      ...result,
    },
  ];
}

export function useProjectCompany({
  companyId,
  projectId,
}: {
  companyId: string;
  projectId: string;
}) {
  const [projectCompanies, meta] = useProjectCompanies({
    companyId,
    projectId,
  });

  const [projectCompany] = projectCompanies;

  return [projectCompany, meta];
}
