import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/Buttons/Button';

export const BackToTimesheetsButton = () => {
  const navigate = useNavigate();

  return (
    <Button
      style={{
        backgroundColor: 'transparent',
        color: 'white',
        boxShadow: 'none',
        borderRadius: 0,
      }}
      onClick={() => navigate(`/time/timesheets`)}
      type="submit"
      text={`< Back to Timesheets`}
    />
  );
};
