import ComboBox from '../Inputs/ComboBox';

export const SearchBox = ({
  placeholder = 'Search',
  results = [],
  setSearchTerm = () => {},
  setSelected = () => {},
  selected,
  parentClassNames,
  comboOptionClassNames,
}: any) => {
  return (
    <div className="w-full">
      <ComboBox
        testId="search"
        placeholder={placeholder}
        question={{
          id: 'Search',
          options: results?.map((result: any) => ({
            id: result.id,
            name: result.name,
            detail: result.detail,
          })),
          onChange: (value) => setSearchTerm(value),
        }}
        parentClassNames={parentClassNames}
        comboOptionClassNames={comboOptionClassNames}
        handleChange={setSelected}
        value={selected?.name ?? ''}
        validation={undefined}
      />
    </div>
  );
};
