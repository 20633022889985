import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useEffect, useState } from 'react';

import FormCard from '../../../components/FormCard/FormCard';

import moment from 'moment';
import { RedBadge, YellowBadge } from '../../../components/Badges/Badges';
import { Button } from '../../../components/Buttons/Button';
import { SAVE_MY_ASSESSMENT_SUBMISSION } from '../../../graphql/mutations/assessments';
import {
  GET_MY_ASSESSMENT_ATTACHMENTS,
  SEARCH_ASSESSMENT_TYPES,
} from '../../../graphql/queries/assessments';
import { GET_MY_ASSESSMENT_SUBMISSIONS } from '../../../graphql/queries/my-profile';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { FormConfig } from '../../../types/Form';
import { saveFileToS3 } from '../../../utils/utils';
import { SAVE_ATTACHMENT } from '../../../graphql/mutations/attachments';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';
import { Table } from '../../../components/Tables/tables/Table';
import { GET_MY_ASSESSMENT_ATTACHMENT_URL } from '../../../graphql/queries/attachments';

const Tabs = ['Your Submission', 'Start Over', 'Attachments'];

const headerMapping = {
  name: 'File Name',
  id: 'ID',
  download: 'Download',
  updatedAt: 'Updated At',
} as Record<string, any>;

export const SaveMyAssessmentPage = () => {
  let { id } = useParams();

  const navigate = useNavigate();
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const isUpdate = !!id;

  const [fetchAssessmentSubmissions, { data, refetch }] = useLazyQuery(
    GET_MY_ASSESSMENT_SUBMISSIONS,
    {
      variables: { input: { id } },
      fetchPolicy: 'network-only',
    }
  );

  const [getAssessmentAttachmentURL] = useLazyQuery(
    GET_MY_ASSESSMENT_ATTACHMENT_URL
  );
  const [searchAssessmentTypes, { data: assessmenttypesData }] = useLazyQuery(
    SEARCH_ASSESSMENT_TYPES,
    { fetchPolicy: 'network-only' }
  );

  const [saveAttachment] = useMutation(SAVE_ATTACHMENT);

  useEffect(() => {
    if (isUpdate) {
      fetchAssessmentSubmissions({ variables: { input: { id } } });
    }
  }, [id, refetch, isUpdate, fetchAssessmentSubmissions]);

  const [assessment] = data?.getMyAssessmentSubmissions?.results || [];

  const [saveMyAssessment] = useMutation(SAVE_MY_ASSESSMENT_SUBMISSION);

  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  const [fetchAttachmentsForThisAssessment, { data: attachmentsData }] =
    useLazyQuery(GET_MY_ASSESSMENT_ATTACHMENTS);

  const { limit, offset, setOffset } = usePagination();

  const totalPages = Math.ceil(
    attachmentsData?.getMyAssessmentAttachments?.count / limit
  );

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  useEffect(() => {
    if (assessment) {
      fetchAttachmentsForThisAssessment({
        variables: { assessmentId: assessment.id, input: { limit, offset } },
        fetchPolicy: 'network-only',
      });
    }
  }, [assessment, fetchAttachmentsForThisAssessment, limit, offset]);

  const performAssessmentTypeSearch = async () => {
    try {
      await searchAssessmentTypes({
        variables: {
          searchTerm: debouncedSearchTerm,
        },
        fetchPolicy: 'network-only',
      });
    } catch (err) {
      // do nothing
    } finally {
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 600); // 300ms debounce time
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length > 1) {
      performAssessmentTypeSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const initialValues = id
    ? {
        id,
        assessmentType: assessment?.assessmentType?.id,
        expiryDate: Number(assessment?.expiryDate),
      }
    : {};

  const validationSchema = Yup.object().shape({});

  const assessmentQuestions = assessment?.assessmentType?.questions;

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      const { data } = await saveMyAssessment({
        variables: {
          id: values?.id,
          input: {
            responses: Object.entries(values)
              .filter(([key]) =>
                assessmentQuestions
                  .map((question: any) => question.id)
                  .includes(key)
              )
              .map(([key, value]) => ({
                question: assessmentQuestions.find(
                  (question: any) => question.id === key
                )?.question,
                answer: value,
              })),
          },
        },
      });
      setSubmitting(false);
      if (data.saveMyAssessmentSubmission.success) {
        navigate(-1);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const assessmentConfig = {
    title: '',
    description: '',
    formSections: [
      {
        title: id ? assessment?.assessmentType?.name : 'Assessment',
        fields: [
          ...(!id
            ? [
                {
                  title: 'Assessment Type',
                  id: 'assessmentType',
                  type: id ? 'input' : 'combobox',
                  disabled: !!id,
                  placeholder: 'Start typing the assessment name',
                  // @ts-ignore
                  onChange: (value: string) => {
                    setSearchTerm(value);
                  },
                  valueTransformer: (id: string) =>
                    assessmenttypesData?.searchAssessmentTypes?.results?.find(
                      (result: any) => result.id === id
                    )?.name,
                  options:
                    assessmenttypesData?.searchAssessmentTypes?.results.map(
                      (assessment: any) => ({
                        id: assessment.id,
                        name: assessment.name,
                      })
                    ),
                  required: true,
                },
              ]
            : []),
          ...(assessmentQuestions?.length > 0
            ? assessmentQuestions.map((question: any) => ({
                id: question.id,
                title: question.question,
                type:
                  !question.type || question.type === 'SINGLE_CHOICE'
                    ? 'radiogroup'
                    : 'textarea',
                options: question?.choices.map((choice: any) => ({
                  id: choice.id,
                  name: choice.name,
                  value: choice.name,
                })),
              }))
            : []),
        ],
      },
    ],
  } as FormConfig;

  const downloadAttachment = async (id: string, fileName: string) => {
    const { data } = await getAssessmentAttachmentURL({
      variables: {
        input: { id },
        ownerId: assessment.id,
        ownerType: 'assessment',
      },
    });
    if (data.getMyAssessmentAttachmentURL.presignedUrl) {
      // Create a new link
      const anchor = document.createElement('a');
      anchor.href = data.getMyAssessmentAttachmentURL.presignedUrl;
      anchor.download = fileName;
      anchor.style.display = 'none';

      // Append to the DOM
      document.body.appendChild(anchor);

      // Trigger `click` event
      anchor.click();

      // Remove element from DOM
      document.body.removeChild(anchor);

      // Revoke the Blob URL to free up resources
      URL.revokeObjectURL(data.getMyAssessmentAttachmentURL.presignedUrl);
    }
  };

  const attachmentConfig = {
    formSections: [
      {
        title: 'Attachments',
        components: [
          <Table
            onClickRow={() => {}}
            currentPage={Math.floor(offset / limit) + 1 || 0}
            totalPages={totalPages}
            totalResults={
              attachmentsData?.getMyAssessmentAttachments?.count || 0
            }
            itemsPerPage={limit}
            columnsToFilter={[]}
            columnValueMapper={{
              updatedAt: (text: string) =>
                moment.unix(parseInt(text) / 1000).fromNow(),
            }}
            headerMapper={(text: string) => headerMapping[text]}
            onPageChange={handlePageChange}
            data={
              attachmentsData?.getMyAssessmentAttachments?.results.length > 0
                ? attachmentsData?.getMyAssessmentAttachments?.results?.map(
                    (result: any) => ({
                      id: result?.id,
                      name: result?.fileName,
                      updatedAt: result?.updatedAt,
                      download: (
                        <Button
                          text={'Download'}
                          onClick={async () =>
                            await downloadAttachment(
                              result.id,
                              result?.fileName
                            )
                          }
                        />
                      ),
                    })
                  )
                : []
            }
          />,
        ],
        fields: [
          {
            title: 'Upload Attachment',
            id: 'file',
            type: 'file',
            limits: 'application/msword, application/pdf',
            description: 'PDF or DOCX',
          },
        ],
      },
    ],
  } as unknown as FormConfig;

  const [activeTab, setActiveTab] = useState(Tabs[0]);

  const onSaveAttachment = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void,
    setFieldValue?: (field: any, values: any) => void
  ) => {
    let newAttachmentId;
    if (values?.file?.name) {
      const { data } = await saveAttachment({
        variables: {
          input: {
            name: values.importName,
            id: null,
            fileName: values.file.name,
          },
        },
      });

      const presignedUrl = data?.saveAttachment?.presignedUrl;

      await saveFileToS3(values.file, presignedUrl);
      newAttachmentId = data?.saveAttachment?.id;
      await saveMyAssessment({
        variables: {
          id: values?.id,
          input: {
            newAttachmentId,
          },
        },
      });
    }
    setSubmitting(false);
    if (setFieldValue) {
      setFieldValue('file', undefined);
    }
    await fetchAttachmentsForThisAssessment({
      variables: { assessmentId: assessment.id, input: { limit, offset } },
      fetchPolicy: 'network-only',
    });
  };

  return (
    <>
      <div className="bg-white  px-6 py-4 flex flex-col items-start gap-y-2">
        <div className="flex w-full border-b mb-2 -mt-2">
          <Button
            style={{
              backgroundColor: 'transparent',
              color: 'black',
              boxShadow: 'none',
              borderRadius: 0,
            }}
            onClick={() => navigate(-1)}
            type="submit"
            text={`< Go Back`}
          />
        </div>
        <div className="flex flex-col gap-y-1">
          <h1>
            <strong className="text-2xl text-gray-800">
              {assessment?.assessmentType?.name}
            </strong>
          </h1>
          {assessment?.assignmentRole ? (
            <h2 className="text-lg text-gray-800">
              Role: {assessment?.assignmentRole?.role?.name}
            </h2>
          ) : null}
          {assessment?.company ? (
            <h2 className="text-lg text-gray-800">
              Company: {assessment?.company?.companyName}
            </h2>
          ) : null}
          <h2 className="text-lg text-gray-800">
            Project:{' '}
            {assessment?.project?.name ||
              assessment?.assignmentRole?.assignment?.project?.name}
          </h2>

          <div className="flex text-gray-500 flex-col gap-y-1 py-2 my-2 border-dashed border-t border-b">
            <h2 className="text-sm">
              Created:{' '}
              <strong className="text-sm text-gray-800">
                {moment.unix(parseInt(assessment?.createdAt) / 1000).fromNow()}
              </strong>
            </h2>
            <h2 className="text-sm">
              Updated:{' '}
              <strong className="text-sm text-gray-800">
                {moment.unix(parseInt(assessment?.updatedAt) / 1000).fromNow()}
              </strong>
            </h2>
          </div>
          <h1>
            Outcome:{' '}
            {!assessment?.responses && !assessment?.outcome ? (
              <RedBadge text="Not Started Yet" />
            ) : !assessment?.outcome ? (
              <YellowBadge text="No Outcome Decided Yet" />
            ) : (
              <strong>{assessment?.outcome}</strong>
            )}
          </h1>
        </div>
      </div>
      <div className="w-full px-2 bg-gray-800 py-2 flex flex-col md:flex-row">
        {Tabs.map((tab) => (
          <div
            className={`${
              activeTab === tab ? 'bg-black' : ''
            } justify-center flex items-center`}
          >
            <Button
              style={{ borderRadius: 0, backgroundColor: 'transparent' }}
              text={
                (!assessment?.responses ||
                  assessment?.responses?.length === 0) &&
                tab === 'Start Over'
                  ? 'Begin'
                  : tab
              }
              onClick={() => setActiveTab(tab)}
            />
          </div>
        ))}
      </div>
      <div className="my-2 bg-white rounded-lg shadow-sm">
        <div className="px-6 py-4 bg-black text-lg text-white rounded-t-md flex justify-center flex-col">
          Assessment Information
        </div>
        <div className="px-4 py-4 bg-white">
          <div
            dangerouslySetInnerHTML={{
              __html: assessment?.assessmentType?.assessmentInformation,
            }}
          />
          <p className="py-2 text-sm">
            Please ensure you check all sections and attachments for this
            assessment.
          </p>
        </div>
      </div>
      {activeTab === 'Start Over' ? (
        <FormCard
          key={`${assessmentConfig?.title}`}
          config={assessmentConfig}
          isDisabled={assessment?.outcome}
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={onSubmit}
        />
      ) : null}
      {activeTab === 'Your Submission' ? (
        <div className="bg-white flex flex-col px-6 py-4 rounded-md shadow-md">
          {assessment?.responses?.length > 0 ? (
            assessment?.responses?.map((response: any) => (
              <div className="flex flex-col gap-y-1 py-2 border-t border-b border-dashed">
                <h3 className="text-lg text-gray-600">{response?.question}</h3>
                <h4 className="text-lg flex items-center gap-x-2">
                  <strong className="text-gray-700 text-sm">Answered:</strong>{' '}
                  {response?.answer ?? '(empty)'}
                </h4>
              </div>
            ))
          ) : (
            <p>Nothing submitted yet.</p>
          )}
        </div>
      ) : null}
      {activeTab === 'Attachments' ? (
        <FormCard
          key={`${attachmentConfig?.title}`}
          config={attachmentConfig}
          validationSchema={Yup.object().shape({})}
          initialValues={{ id }}
          submitText="Upload"
          onSubmit={onSaveAttachment}
        />
      ) : null}
    </>
  );
};

export const SaveMyAssessment = () => (
  <PaginationProvider>
    <SaveMyAssessmentPage />
  </PaginationProvider>
);
