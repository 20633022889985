import { gql } from '@apollo/client';

export const GET_TIMESHEET_SUBMISSION = gql`
  query getTimesheetSubmissions(
    $input: GenericFindOptions
    $weekId: String
    $onlySubmitted: Boolean
    $configurationId: String
    $activeOrganisation: String!
  ) {
    getTimesheetSubmissions(
      input: $input
      weekId: $weekId
      onlySubmitted: $onlySubmitted
      configurationId: $configurationId
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        lrs {
          id
          firstName
          lastName
          email
        }
        project {
          id
          name
          internalId
        }
        weekId
        weekCommencingDate
        submitted
        approved
        approvedBy
        amendmentsRequested
        amendmentsReason
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_MY_TIMESHEET_SUBMISSION = gql`
  query getTimesheetSubmissionByWeekIdAndConfig(
    $input: GenericFindOptions
    $weekId: String
    $projectId: String
    $configurationId: String
    $activeOrganisation: String!
  ) {
    getTimesheetSubmissionByWeekIdAndConfig(
      input: $input
      weekId: $weekId
      projectId: $projectId
      configurationId: $configurationId
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        lrs {
          id
          firstName
          lastName
          email
        }
        project {
          id
          name
          internalId
        }
        weekId
        weekCommencingDate
        submitted
        approved
        approvedBy
        amendmentsRequested
        amendmentsReason
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_UNSUBMITTED_TIMESHEETS = gql`
  query getUnsubmittedTimesheets(
    $input: GenericFindOptions
    $weekId: String!
    $configurationId: String!
    $activeOrganisation: String!
  ) {
    getUnsubmittedTimesheets(
      input: $input
      weekId: $weekId
      configurationId: $configurationId
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        type
        name
        internalId
      }
    }
  }
`;
