import React from 'react';
import { twMerge } from 'tailwind-merge';
import { LoadingSpinner } from '../Loading/LoadingSpinner';

type NoDataProps = {
  children: React.ReactNode;
  className?: string;
  loading?: boolean;
};

export default function NoData({ children, className, loading }: NoDataProps) {
  return loading ? (
    <LoadingSpinner />
  ) : (
    <div
      style={{ borderColor: 'rgba(1,1,1,0.1)' }}
      className={twMerge(
        'flex flex-col rounded-lg bg-white p-8 justify-center items-center gap-y-4',
        className
      )}
    >
      {children}
    </div>
  );
}
