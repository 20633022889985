import { useState } from 'react';
import { BlueBadge } from '../Badges/Badges';
import ValidationMessage from '../Validation/ValidationMessage';
import { twMerge } from 'tailwind-merge';

const ChipsInput = (props: any) => {
  const {
    id,
    question,
    handleChange,
    value,
    isDisabled,
    validation,
    inputTransformer = (text: string) => text,
  } = props;
  const { title, placeholder, classNames, description } = question;

  const [inputValue, setInputValue] = useState(String);

  const items = Array.isArray(value) ? value : [];

  const addInput = () => {
    if (inputValue) {
      const updatedItems = [...items, inputTransformer(inputValue)];
      setInputValue('');
      handleChange(updatedItems);
    }
  };

  const removeInput = (index: number) => {
    const updatedItems = items.filter((_, i) => i !== index);
    handleChange(updatedItems);
  };

  return (
    <div className={classNames}>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {title}
      </label>
      <input
        id={id}
        name={id}
        type="text"
        value={inputValue}
        disabled={isDisabled}
        onChange={(event) => setInputValue(event.target.value)}
        className="w-96 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
        placeholder={placeholder}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            addInput();
          }
        }}
      />
      {description && (
        <p className="text-sm py-2 text-gray-500">{description}</p>
      )}
      <div
        className={twMerge('flex items-center flex-wrap gap-1 gap-y-2 py-2')}
      >
        {items?.map((value: string, index: number) => (
          <div
            className="flex items-center gap-x-2 justify-center"
            key={`${value}-${index}`}
          >
            <BlueBadge
              text={value}
              hasDeleteButton
              onDelete={() => removeInput(index)}
            />
          </div>
        ))}
      </div>
      {validation ? (
        <div className="pt-4">
          <ValidationMessage message={validation} />
        </div>
      ) : null}
    </div>
  );
};

export default ChipsInput;
