import { GridLayout } from '../../components/Grid/GridLayout';
import { Card } from '../../components/Layout/Card';
import { ModuleItemSummary } from '../../components/ModuleItemSummary/ModuleItemSummary';
// @ts-ignore

import {
  CheckBadgeIcon,
  FolderIcon,
  ClockIcon,
  NoSymbolIcon,
} from '@heroicons/react/24/outline';
import { useContext, useEffect, useState } from 'react';
import { GET_STATISTICS } from '../../graphql/queries/statistics';
import { useOrganisationAwareApollo } from '../../hooks/useOrganisationAwareApollo';
import { ModuleItemCard } from '../../types/ModuleItemSummary';
import { Chart, Tooltip } from 'chart.js';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Bar } from 'react-chartjs-2';
import { UserProfileContext } from '../../context/UserProfileContext';
import { ActiveOrganisationContext } from '../../context/ActiveOrganisationContext';
import { Permission } from '../../types/Permissions';
import ProjectRolesChart from './charts/ProjectRolesChart';
import NoProjects from '../../components/NoData/NoProjects';
import { useNavigate } from 'react-router-dom';
import { GET_PROJECT_MANAGERS } from '../../graphql/queries/projects';

Chart.register(annotationPlugin);

// @ts-ignore
Tooltip.positioners.mouse = function (_items, evtPos) {
  if (!evtPos) {
    return;
  }
  return evtPos;
};

interface ProjectHomeProps {
  children?: React.ReactElement;
}

const overrideTickFont = {
  ticks: {
    font: {
      family: 'Poppins',
      size: 12,
    },
  },
};

export const baseBarColours = [
  '#4e79a7',
  '#f28e2b',
  '#76b7b2',
  '#59a14f',
  '#edc948',
  '#b07aa1',
  '#ff9da7',
  '#9c755f',
  '#bab0ac',
];

export const ProjectHome = ({ children }: ProjectHomeProps) => {
  const { useLazyQuery, useQuery } = useOrganisationAwareApollo();

  const { userProfile, isLoading } = useContext(UserProfileContext);
  const { activeOrganisation } = useContext(ActiveOrganisationContext);

  const [moduleItemsActive, setModuleItemsActive] = useState<{
    activeModuleActive: boolean;
    disabledModuleActive: boolean;
  }>({
    activeModuleActive: false,
    disabledModuleActive: false,
  });

  const [fetch, { data, loading }] = useLazyQuery(GET_STATISTICS, {
    variables: {
      query: [
        {
          calculation: 'projects',
        },
      ],
    },
    fetchPolicy: 'network-only',
  });

  const { data: projectManagers } = useQuery(GET_PROJECT_MANAGERS, {
    variables: {
      activeOrganisation,
    },
  });

  useEffect(() => {
    fetch();
  }, [fetch]);

  const statistics = data?.getStatistics?.projects || {};

  const barColours = statistics.activeProjects?.map(
    (activeProject: any, index: number) => {
      if (activeProject.colour) {
        return activeProject.colour;
      }
      return baseBarColours[index % baseBarColours.length];
    }
  );

  const navigate = useNavigate();

  const activeStatistics = [
    {
      heading: String(statistics?.activeStatistics?.completedCount),
      description: 'Completed',
      colour: '#DCFCE7',
      IconComponent: CheckBadgeIcon,
      classNames: `${
        statistics?.activeStatistics?.completedCount === 0 ? 'hidden' : ''
      }`,
    },
    {
      heading: String(statistics?.activeStatistics?.inProgressCount),
      description: 'In Progress',
      colour: '#DCFCE7',
      IconComponent: ClockIcon,
      classNames: `${
        statistics?.activeStatistics?.inProgressCount === 0 ? 'hidden' : ''
      }`,
    },
    {
      heading: String(statistics?.activeStatistics?.notStartedCount),
      description: 'Not Started',
      colour: '#DCFCE7',
      IconComponent: NoSymbolIcon,
      classNames: `${
        statistics?.activeStatistics?.notStartedCount === 0 ? 'hidden' : ''
      }`,
    },
  ] as ModuleItemCard[];

  const disabledStatistics: ModuleItemCard[] = [
    {
      heading: String(statistics?.disabledStatistics?.completedCount),
      description: 'Completed',
      colour: '#F3E8FF',
      IconComponent: CheckBadgeIcon,
      classNames: `${
        statistics?.disabledStatistics?.completedCount === 0 ? 'hidden' : ''
      }`,
    },
    {
      heading: String(statistics?.disabledStatistics?.inProgressCount),
      description: 'In Progress',
      colour: '#F3E8FF',
      IconComponent: ClockIcon,
      classNames: `${
        statistics?.disabledStatistics?.inProgressCount === 0 ? 'hidden' : ''
      }`,
    },
    {
      heading: String(statistics?.disabledStatistics?.notStartedCount),
      description: 'Not Started',
      colour: '#F3E8FF',
      IconComponent: NoSymbolIcon,
      classNames: `${
        statistics?.disabledStatistics?.notStartedCount === 0 ? 'hidden' : ''
      }`,
    },
  ] as ModuleItemCard[];

  const mockProjectSummary = [
    {
      heading: statistics?.activeCount,
      description: 'Active Projects',
      colour: '#DCFCE7',
      hoverColour: '#C3EED3',
      IconComponent: FolderIcon,
      isActive: moduleItemsActive.activeModuleActive,
      isExpandable: true,
      classNames: `cursor-pointer transition-all ${
        moduleItemsActive.activeModuleActive ? 'border border-green-500' : ''
      }`,
      onClick: () => {
        setModuleItemsActive({
          ...moduleItemsActive,
          activeModuleActive: !moduleItemsActive.activeModuleActive,
        });
      },
    },
    {
      heading: statistics?.disabledCount,
      description: 'Disabled Projects',
      colour: '#F3E8FF',
      hoverColour: '#E9D5FF',
      IconComponent: NoSymbolIcon,
      isActive: moduleItemsActive.disabledModuleActive,
      isExpandable: true,
      classNames: `cursor-pointer transition-all ${
        moduleItemsActive.disabledModuleActive ? 'border border-purple-500' : ''
      }`,
      onClick: () => {
        setModuleItemsActive({
          ...moduleItemsActive,
          disabledModuleActive: !moduleItemsActive.disabledModuleActive,
        });
      },
    },
  ] as ModuleItemCard[];

  const currentDate = new Date();

  return (
    <div className="px-4">
      {userProfile?.personalDetails ? null : isLoading ? null : (
        <h1 className="text-3xl mt-2 mb-4 tracking-tight text-center text-red-600">
          Sorry! It looks like you don't have permission to access this
          organisation 😔
        </h1>
      )}

      <hr className="mb-4" />
      {userProfile?.personalDetails ? (
        <>
          <div className="flex flex-col w-full md:flex-row bg-white border-l-4 border-green-800 border-b-2 border-b-green-50 border-r-2 border-r-green-50 shadow-sm rounded-md px-6 py-2 pb-2 gap-x-4 gap-y-2 items-center mb-4">
            <img
              src={activeOrganisation?.logoUrl}
              className="mr-4 my-2"
              width={45}
              alt={`${activeOrganisation?.companyName} logo`}
            />
            <div>
              <i className="not-italic flex flex-wrap tracking-tight items-center text-sm">
                Welcome{' '}
                <strong className="mx-1">
                  {userProfile?.personalDetails?.firstName ?? ''}
                </strong>{' '}
                <strong className="mr-2">👋,</strong>
                <div className="inline sm:flex items-center">
                  <p>to the</p>
                  <img
                    src={'/logo.png'}
                    className="w-28 h-auto mt-1 mx-1"
                    alt="logo"
                  />
                  <p>engOS Platform.</p>
                </div>
              </i>
              <hr className="my-2 " />

              {userProfile?.personalDetails?.type !== 'EMPLOYEE' && (
                <p className="text-xs mt-2 w-full">
                  <strong>Note:</strong> Please ensure you keep your next of
                  kin, company details and bank details up to date. You can
                  update these from the{' '}
                  <a className="text-green-600 font-bold" href="/profile">
                    profile screen.
                  </a>{' '}
                  Please also promptly notify{' '}
                  <strong>{activeOrganisation?.companyName}</strong> of any
                  changes.
                </p>
              )}
            </div>
          </div>

          <hr className="mt-4 mb-4" />
        </>
      ) : null}
      {userProfile?.permissions?.includes(Permission.ViewProjectDetails) ? (
        statistics.activeCount || statistics.disabledCount ? (
          <GridLayout>
            <Card classNames={'col-span-6'}>
              <ModuleItemSummary moduleItemCards={mockProjectSummary} />
            </Card>
            <div className="grid grid-cols-2 col-span-6">
              {moduleItemsActive.activeModuleActive ? (
                <Card classNames="mt-[-1.5rem]">
                  <ModuleItemSummary
                    moduleItemCards={activeStatistics}
                    classNames="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3"
                  />
                </Card>
              ) : (
                <div />
              )}
              {moduleItemsActive.disabledModuleActive ? (
                <Card classNames="mt-[-1.5rem]">
                  <ModuleItemSummary
                    moduleItemCards={disabledStatistics}
                    classNames="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3"
                  />
                </Card>
              ) : (
                <div />
              )}
            </div>
            <Card classNames={'col-span-6'}>
              <div style={{ height: 750 }} className="pb-10">
                <p className="text-lg font-semibold">Active Projects</p>
                <div className="my-2" />
                <Bar
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    indexAxis: 'y',
                    plugins: {
                      annotation: {
                        // @ts-ignore
                        annotations: {
                          currentDateLine: {
                            type: 'line',
                            xMin: currentDate,
                            xMax: currentDate,
                            borderDash: [2, 5],
                            borderColor: 'black',
                            borderWidth: 2,
                            label: {
                              content: 'Today',
                              // @ts-ignore
                              enabled: true,
                              position: 'start',
                            },
                          },
                        },
                      },
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        // @ts-ignore
                        position: 'mouse',
                        callbacks: {
                          label: function (context) {
                            let labels = [];
                            if (context.raw) {
                              // @ts-ignore
                              const raw = context.raw.x;
                              const startDate = raw[0].toLocaleDateString();
                              const endDate = raw[1].toLocaleDateString();
                              labels.push(`${startDate} - ${endDate}`);

                              // @ts-ignore
                              if (context.raw.projectId) {
                                const projectManagersList =
                                  projectManagers?.getProjectManagers?.results
                                    .find(
                                      (project: any) =>
                                        project.projectId ===
                                        // @ts-ignore
                                        context.raw.projectId
                                    )
                                    ?.managers.map(
                                      (manager: any) =>
                                        `${manager.firstName} ${manager.lastName}`
                                    )
                                    .join(', ');
                                if (projectManagersList) {
                                  labels.push(
                                    `Project Manager${
                                      projectManagersList.split(',').length > 1
                                        ? 's'
                                        : ''
                                    }: ${projectManagersList}`
                                  );
                                }
                              }
                            }
                            return labels;
                          },
                        },
                      },
                    },
                    onHover: (event, chartElement) => {
                      if (event?.native?.target) {
                        // @ts-ignore
                        event.native.target.style.cursor = chartElement.length
                          ? 'pointer'
                          : 'default';
                      }
                    },
                    onClick: function (_event, chartElements) {
                      if (!chartElements || chartElements.length === 0) {
                        console.warn('No chart element clicked');
                        return;
                      }
                      if (chartElements.length > 0) {
                        const { index } = chartElements[0];
                        navigate(
                          `/projects/${statistics?.activeProjects?.[index]?.id}`
                        );
                      }
                    },
                    scales: {
                      y: { ...overrideTickFont },
                      x: {
                        // @ts-ignore
                        type: 'time',
                        stacked: true,
                        grid: {
                          display: false,
                        },
                        time: {
                          parser: 'timestamp',
                          unit: 'month',
                          displayFormats: {
                            month: 'MMM yy',
                          },
                        },
                        ...overrideTickFont,

                        min:
                          statistics?.activeProjects?.reduce(
                            (min: any, project: any) => {
                              const startDate = new Date(
                                parseInt(project.startDate)
                              ).getTime();
                              return startDate < min ? startDate : min;
                            },
                            Infinity
                          ) -
                          7 * 24 * 60 * 60 * 1000, // Subtract one week from the earliest start date

                        max:
                          statistics?.activeProjects?.reduce(
                            (max: any, project: any) => {
                              const endDate = new Date(
                                parseInt(project.endDate)
                              ).getTime();
                              return endDate > max ? endDate : max;
                            },
                            -Infinity
                          ) +
                          7 * 24 * 60 * 60 * 1000,
                      },
                    },
                  }}
                  data={{
                    labels: statistics?.activeProjects?.map(
                      (project: any) => project.name
                    ),
                    datasets: [
                      {
                        label: 'Active Projects',
                        data: statistics?.activeProjects?.map(
                          (project: any) => ({
                            x: [
                              new Date(parseInt(project.startDate)),
                              new Date(parseInt(project.endDate)),
                            ],
                            y: project.name,
                            projectId: project.id,
                          })
                        ),
                        backgroundColor: barColours,
                        borderWidth: 1,
                      },
                    ],
                  }}
                />
              </div>
            </Card>
            <Card classNames={'col-span-6'}>
              <h3 className="my-2">
                Roles and Number Assigned by Active Project
              </h3>
              <div className="h-[600px]">
                <ProjectRolesChart data={data} />
              </div>
            </Card>
          </GridLayout>
        ) : (
          <NoProjects loading={loading} />
        )
      ) : null}

      {children}
    </div>
  );
};
