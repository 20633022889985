import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

export const Modal = ({
  showModal,
  setShowModal = () => {},
  onComplete = () => Promise.resolve(true),
  children,
  component,
  title,
  completeButtonText = 'Save',
  cancelButtonText = 'Cancel',
  disableCompleteButton = false,
  isCompleting,
  completeButtonClassName,
  classNames,
}: any) => {
  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        style={{ zIndex: 9999 }}
        initialFocus={cancelButtonRef}
        onClose={setShowModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center  justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={twMerge(
                  'relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full md:max-w-[600px] sm:p-6',
                  classNames
                )}
              >
                {showModal ? (
                  <>
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-semibold leading-6 text-gray-900"
                        >
                          {title}
                        </Dialog.Title>
                      </div>
                    </div>
                    <div className="w-full">{component || children}</div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        disabled={disableCompleteButton}
                        type="button"
                        className={twMerge(
                          `inline-flex w-full justify-center rounded-md ${
                            disableCompleteButton
                              ? 'bg-gray-400'
                              : 'bg-green-600'
                          } px-3 py-2 text-sm font-semibold text-white shadow-sm ${
                            disableCompleteButton
                              ? 'hover:bg-gray-400'
                              : 'hover:bg-red-500'
                          } sm:ml-3 sm:w-auto`,
                          completeButtonClassName
                        )}
                        onClick={async () => {
                          await onComplete();
                        }}
                      >
                        {isCompleting ? (
                          <div className="border-dashed border-2 rounded-full animate-spin w-5 h-5" />
                        ) : (
                          completeButtonText
                        )}
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => setShowModal(false)}
                        ref={cancelButtonRef}
                      >
                        {cancelButtonText || 'Cancel'}
                      </button>
                    </div>
                  </>
                ) : null}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
