import { createContext, useContext, useState } from 'react';
import { useOrganisationAwareApollo } from '../../hooks/useOrganisationAwareApollo';
import { GET_EVENT_TYPES } from '../../graphql/queries/event-types';
import { UpdatedBankDetails } from './event-types/UpdatedBankDetails';
import { Permission } from '../../types/Permissions';
import { UserProfileContext } from '../../context/UserProfileContext';
import { Modal } from '../../components/Dialogue/ModalDialogue';
import { CertificationsMissingOrInvalid } from './event-types/CertificationsMissingOrInvalid';

export enum EventType {
  updatedBankDetails = 'updatedBankDetails',
  certificationsMissingOrInvalid = 'certificationsMissingOrInvalid',
}

export type EventTypeType = {
  name: EventType;
  config: {
    enabled?: boolean;
    emails?: string[];
    sendToAssignee?: boolean;
    sendToProjectManagers?: boolean;
    sendToCompanyOwner?: boolean;
  };
};

type FormStateContextType = {
  isDirty: boolean;
  setIsDirty: (dirty: boolean) => void;
};

const eventTypeToComponent: Record<EventType, any> = {
  updatedBankDetails: UpdatedBankDetails,
  certificationsMissingOrInvalid: CertificationsMissingOrInvalid,
};

export const FormStateContext = createContext<FormStateContextType>({
  isDirty: false,
  setIsDirty: () => {},
});

export const FormStateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isDirty, setIsDirty] = useState(false);

  return (
    <FormStateContext.Provider value={{ isDirty, setIsDirty }}>
      {children}
    </FormStateContext.Provider>
  );
};

const NotificationSettingsWithFormState = () => {
  const { useQuery } = useOrganisationAwareApollo();

  const [activeTile, setActiveTile] = useState('');

  const [pendingTile, setPendingTile] = useState('');

  const { isDirty, setIsDirty } = useContext(FormStateContext);

  const [showCloseWithoutSavingModal, setShowCloseWithoutSavingModal] =
    useState(false);

  const handleCloseOrChangeTile = (eventName: string) => {
    if (isDirty) {
      setPendingTile(eventName);
      setShowCloseWithoutSavingModal(true);
    } else {
      setActiveTile((prevTile) => (prevTile === eventName ? '' : eventName));
    }
  };

  const handleConfirmCloseWithoutSaving = () => {
    setIsDirty(false);
    setShowCloseWithoutSavingModal(false);

    if (pendingTile && pendingTile !== activeTile) {
      setActiveTile(pendingTile);
    } else {
      setActiveTile('');
    }
    setPendingTile('');
  };

  const { data, refetch } = useQuery(GET_EVENT_TYPES, {
    variables: {},
    fetchPolicy: 'network-only',
  });
  const eventTypes = data?.getEventTypes.results || [];

  const { userProfile } = useContext(UserProfileContext);

  const hasViewEventTypesPermission = userProfile?.permissions.includes(
    Permission.ViewEventTypes || Permission.UpdateEventTypes
  );

  return (
    <>
      <Modal
        showModal={showCloseWithoutSavingModal}
        setShowModal={setShowCloseWithoutSavingModal}
        completeButtonText={'Yes, close without saving'}
        cancelButtonText={'No, go back'}
        onComplete={handleConfirmCloseWithoutSaving}
        component={
          <p className="text-sm">
            Are you sure you want to close this tab without saving changes?
          </p>
        }
      />
      <div className="bg-gray-50">
        <div className="bg-white shadow overflow-hidden rounded-lg">
          <div className="flex flex-col gap-y-2 w-full px-4 py-5 sm:px-6 flex-wrap">
            <h1 className="text-3xl font-extrabold text-gray-900">
              Notification Settings
            </h1>
            <h2>Changes made here may take up to five minutes to be applied</h2>
          </div>
          {!data && (
            <div className="h-64 flex items-center justify-center">
              <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
            </div>
          )}

          {hasViewEventTypesPermission && eventTypes.length > 0 ? (
            <div className="flex flex-col gap-4 pb-4 px-4">
              {Object.values(EventType).map(
                (type: EventType, index: number) => {
                  const eventType = eventTypes.find(
                    (et: EventTypeType) => et.name === type
                  );

                  const Component = eventTypeToComponent[type];

                  return (
                    <Component
                      enabled={eventType.enabled}
                      config={eventType.config}
                      key={`${eventType.name}_${index}`}
                      onClick={() => handleCloseOrChangeTile(eventType.name)}
                      isActive={activeTile === eventType.name}
                      setActiveTile={setActiveTile}
                      refetchEventTypes={refetch}
                    />
                  );
                }
              )}
            </div>
          ) : !hasViewEventTypesPermission ? (
            <p className="py-2 px-4 sm:px-6 text-sm text-red-600">
              It looks like you do not have permission to view notifications
            </p>
          ) : null}
        </div>
      </div>
    </>
  );
};

export const NotificationSettings = () => (
  <FormStateProvider>
    <NotificationSettingsWithFormState />
  </FormStateProvider>
);
