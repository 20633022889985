import AppWrapper from '../App';

import {
  BanknotesIcon,
  BuildingOffice2Icon as BuildingIconOutline,
  CalendarIcon,
  ChartBarIcon,
  ClockIcon,
  Cog8ToothIcon,
  FolderIcon,
  UserCircleIcon,
  UserGroupIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';

import {
  AcademicCapIcon,
  BuildingOffice2Icon,
  BellIcon,
  ChartPieIcon,
  CloudIcon,
  CurrencyPoundIcon,
  HeartIcon,
  PaperAirplaneIcon,
  PhoneArrowDownLeftIcon,
  QuestionMarkCircleIcon,
  ClockIcon as SolidClockIcon,
  FolderIcon as SolidFolderIcon,
  MagnifyingGlassIcon as SolidMagnifyingGlassIcon,
  UserGroupIcon as SolidUserGroupIcon,
  StarIcon,
  TableCellsIcon,
  UserCircleIcon as UserCircleSolidIcon,
  UserGroupIcon as UserGroupSolidIcon,
  UserIcon as UserSolidIcon,
  VariableIcon,
  DocumentTextIcon,
} from '@heroicons/react/24/solid';

import {
  BriefcaseIcon,
  ClipboardDocumentIcon,
  DocumentIcon,
  InformationCircleIcon,
} from '@heroicons/react/20/solid';
import { Navigate } from 'react-router-dom';
import { ContractsBuilder } from './Contracts/builder';
import { ContractList } from './Contracts/list';
import { SaveContract } from './Contracts/save';
import { CustomOptionList } from './CustomData/custom-options/list';
import { SaveCustomOptionsSet } from './CustomData/custom-options/save';
import { CustomDataHome } from './CustomData/home';
import { ActivityCodeList } from './Finance/activity-codes/list';
import { SaveActivityCode } from './Finance/activity-codes/save';
import { FinanceHome } from './Finance/home';
import { EmployeeList } from './Labour/employees/list';
import { SaveEmployee } from './Labour/employees/save';
import { Employee } from './Labour/employees/view';
import { LabourHome } from './Labour/home';
import { SaveNextOfKin } from './Labour/nextOfKin/save';
import { RoleList } from './Labour/roles/list';
import { SaveRole } from './Labour/roles/save';
import { Role } from './Labour/roles/view';
import { SubcontractorList } from './Labour/subcontractors/list';
import { SaveSubcontractor } from './Labour/subcontractors/save';
import { Subcontractor } from './Labour/subcontractors/view';
import { MyAssignmentList } from './Profile/assignments/list';
import { BankingDetail } from './Profile/banking/view';
import { ViewCompanyProfile } from './Profile/company/ViewCompanyProfile';
import { SentContractsListForProfile } from './Profile/contracts/list';
import { SignContractPage } from './Profile/contracts/sign';
import { ProfileHome } from './Profile/home';
import { NextOfKinDetail } from './Profile/nextOfKin/view';
import { PersonalProfile } from './Profile/personal/view';
import { AssignToProject } from './Projects/assignments/assign';
import { AssignRoleToAssignee } from './Projects/assignments/roles/assign';
import { SentContractsList } from './Projects/assignments/roles/contracts/list';
import { AssignmentSearch } from './Projects/assignments/search';
import { Assignment } from './Projects/assignments/view';
import { ProjectHome } from './Projects/home';
import { ProjectList } from './Projects/list';
import { SaveProject } from './Projects/save';
import { SaveProjectTeam } from './Projects/teams/save';
import { Project } from './Projects/view';
import { SettingsHome } from './Settings/home';
import { TimesheetConfigurationList } from './TimeTracking/configurations/list';
import { SaveTimesheetConfiguration } from './TimeTracking/configurations/save';
import { ExportList } from './TimeTracking/exports/list';
import { CreateExport } from './TimeTracking/exports/run/list';
import { ExportConfigurationPage } from './TimeTracking/exports/save';
import { TimeTrackingHome } from './TimeTracking/home';
import { TimesheetSubmissionList } from './TimeTracking/submissions/list';
import { TimesheetList } from './TimeTracking/timesheet/list';
import { ReviewTimesheet } from './TimeTracking/timesheet/review';
import { SaveTimesheet } from './TimeTracking/timesheet/save';
import { GroupList } from './Users/groups/list';
import { UsersHome } from './Users/home';
import { UserList } from './Users/list';

import { Permission } from '../types/Permissions';
import { AssessmentTypeList } from './Labour/assessments/list';
import { SaveAssessmentType } from './Labour/assessments/save';
import { ExpiringCertificationsList } from './Labour/certifications/expiring/list';
import { CertificationTypeList } from './Labour/certifications/list';
import { SaveCertification } from './Labour/certifications/profile/save';
import { SaveCertificationType } from './Labour/certifications/save';
import { SkillTypeList } from './Labour/skills/list';
import { SaveSkill } from './Labour/skills/profile/save';
import { SaveSkillType } from './Labour/skills/save';
import { SaveBankAccount } from './Labour/subcontractors/banking/save';
import { SaveCompanyViaLabourResource } from './Labour/subcontractors/company/save';
import { SaveMyAssessment } from './Profile/assessments/save';
import { MyAssessmentSubmission } from './Profile/assessments/view';
import { SaveMyBankAccount } from './Profile/banking/save';
import { SaveMyCertification } from './Profile/certifications/save';
import { MyCertification } from './Profile/certifications/view';
import { SaveMyCompany } from './Profile/company/save';
import { SaveMyNextOfKin } from './Profile/nextOfKin/save';
import { SaveMyPersonalDetails } from './Profile/personal/save';
import { ActivityCategoryBulkSave } from './Progress/categories/bulk-save';
import { ProgressActivityCategoriesList } from './Progress/categories/list';
import { SaveProgressActivityCategory } from './Progress/categories/save';
import { ProgressConditionTypesList } from './Progress/conditions/list';
import { SaveProgressConditionType } from './Progress/conditions/save';
import { ProgressDashboardHome } from './Progress/dashboard/home';
import { ProgressHome } from './Progress/home';
import { IncidentBulkSave } from './Progress/hseq/bulk-save';
import { ProgressIncidentTypesList } from './Progress/hseq/list';
import { SaveProgressIncidentType } from './Progress/hseq/save';
import { CreateProgressExport } from './Progress/submissions/exports/run/list';
import { ProgressSubmissionsList } from './Progress/submissions/list';
import { SaveProgressSubmission } from './Progress/submissions/save';
import { SaveProjectActivityType } from './Projects/activities/save';
import { SaveProjectActivityGroup } from './Projects/activity-groups/save';
import { SentAssessmentsList } from './Projects/assignments/roles/assessments/list';
import { SaveAssessmentSubmission } from './Projects/assignments/roles/assessments/save';
import { LocationBulkSave } from './Projects/locations/bulk-save';
import { Location } from './Projects/locations/view';
import { OutcomeView } from './Projects/outcomes/save';
import { AssignToTeam } from './Projects/teams/assign';
import { Team } from './Projects/teams/view';
import { SaveGroup } from './Users/groups/save';
import { SaveUser } from './Users/save';
import { ActivityBulkSave } from './Projects/activities/bulk-save';
import { AssessmentDashboard } from './Labour/assessments/AssessmentDashboard';
import { CreateProjectExport } from './Projects/exports/list';
import { SaveProjectCertification } from './Projects/certifications/SaveProjectCertification';
import { CertificationTypeDashboard } from './Labour/certifications/CertificationTypeDashboard';
import { CustomFieldList } from './CustomData/custom-fields/list';
import { SaveCustomFieldsGroup } from './CustomData/custom-fields/save';
import { CompaniesList } from './Companies/list';
import { CompaniesHome } from './Companies/home';
import { CompanyPage } from './Companies/view';
import { CreateProgressDataExport } from './Progress/submissions/exports/data/list';
import { AssignPeopleToCompanyPage } from './Companies/assign/AssignPeopleToCompanyPage';
import { SaveCompany } from './Companies/save';
import { AssignCompaniesToProjectPage } from './Projects/companies/assign/AssignCompaniesToProjectPage';
import { ViewProjectCompany } from './Projects/companies/ViewProjectCompany';
import { TimeTrackingDashboard } from './TimeTracking/dashboard';
import { SaveCompanyCertification } from './Companies/certifications/SaveCompanyCertification';
import { AssignLabourToProjectForCompany } from './Projects/companies/assign/AssignLabourToProjectForCompany';
import { NotificationSettings } from './Settings/NotificationSettings';
import { SaveCompanyAssessmentSubmission } from './Projects/companies/assessments/SaveCompanyAssessmentSubmission';

export const mainRoutes = [
  {
    path: '*',
    hidden: true,
    name: 'Projects',
    icon: FolderIcon,
    permissionsRequired: [Permission.ViewProjectDetails],
    element: <Navigate to="/projects" />,
  },
  {
    path: '/',
    hidden: true,
    name: 'Projects',
    icon: FolderIcon,
    permissionsRequired: [Permission.ViewProjectDetails],
    element: <Navigate to="/projects" />,
  },
  {
    path: '/projects',
    name: 'Projects',
    icon: FolderIcon,
    element: <ProjectHome />,
  },
  {
    path: '/labour',
    name: 'Labour',
    icon: UserGroupIcon,
    permissionsRequired: [Permission.ViewLabourProfiles],
    element: <LabourHome />,
  },
  {
    path: '/time',
    name: 'Time Tracking',
    icon: ClockIcon,
    moduleTogglesRequired: ['finance'],
    permissionsRequired: [Permission.ViewTimesheets],
    element: <TimeTrackingHome />,
  },
  {
    path: '/progress',
    name: 'Progress',
    icon: CalendarIcon,
    isAvailableOffline: true,
    moduleTogglesRequired: ['progress'],
    permissionsRequired: [Permission.ViewProgressModule],
    element: <ProgressHome />,
  },
  {
    path: '/finance',
    name: 'Finance',
    icon: BanknotesIcon,
    moduleTogglesRequired: ['finance'],
    permissionsRequired: [Permission.ViewActivityCodes],
    element: <FinanceHome />,
  },
  // {
  //     path: "/analytics",
  //     name: "Analytics",
  //     icon: ChartPieIcon,
  //     element: <AnalyticsHome />,
  // },
  {
    path: '/users',
    name: 'User Management',
    icon: UsersIcon,
    permissionsRequired: [Permission.ViewUsers],
    element: <UsersHome />,
  },
  {
    path: '/companies',
    name: 'Companies',
    icon: BuildingIconOutline,
    permissionsRequired: [Permission.ViewLabourProfiles],
    element: <CompaniesHome />,
  },
  {
    path: '/custom',
    name: 'Custom Data',
    icon: VariableIcon,
    permissionsRequired: [Permission.ViewCustomData],
    element: <CustomDataHome />,
  },
  {
    path: '/profile',
    name: 'Your Profile',
    icon: UserCircleIcon,
    element: <ProfileHome />,
  },
  {
    path: '/settings',
    name: 'Settings',
    icon: Cog8ToothIcon,
    permissionsSomeRequired: [
      Permission.ViewEventTypes,
      Permission.UpdateEventTypes,
    ],
    element: <SettingsHome />,
  },
];

export const childRoutes = [
  {
    childOf: '/projects',
    path: '/projects',
    name: 'Summary',
    icon: InformationCircleIcon,
    element: <ProjectHome />,
  },
  {
    childOf: '/projects',
    path: '/projects/list',
    name: 'Projects',
    permissionsRequired: [Permission.ViewProjectDetails],
    icon: SolidFolderIcon,
    element: <ProjectList />,
  },
  {
    childOf: '/projects',
    path: '/projects/assignments/search',
    name: 'Assignment Search',
    permissionsRequired: [Permission.ViewLabourProfiles],
    icon: SolidMagnifyingGlassIcon,
    element: <AssignmentSearch />,
  },
  {
    childOf: '/projects',
    path: '/projects/exports',
    name: 'Exports',
    permissionsRequired: [Permission.ViewLabourProfiles],
    icon: TableCellsIcon,
    element: <CreateProjectExport />,
  },
  {
    childOf: '/progress',
    path: '/progress/submissions',
    name: 'Submissions',
    permissionsRequired: [Permission.ViewProgressModule],
    icon: PaperAirplaneIcon,
    element: <ProgressSubmissionsList />,
  },
  {
    childOf: '/progress',
    path: '/progress/dashboard',
    name: 'Analytics',
    permissionsRequired: [Permission.ViewProgressDashboard],
    icon: ChartBarIcon,
    element: <ProgressDashboardHome />,
  },
  {
    childOf: '/progress',
    path: '/progress/categories',
    name: 'Categories',
    permissionsRequired: [Permission.ConfigureProgressModule],
    icon: ChartPieIcon,
    element: <ProgressActivityCategoriesList />,
  },
  {
    matchRoute: '/progress/categories',
    path: '/progress/categories/:id',

    name: 'Progress Project Submission',
    element: <SaveProgressActivityCategory />,
  },
  {
    matchRoute: '/progress/categories',
    path: '/progress/categories/new',

    name: 'Progress Project Submission',
    element: <SaveProgressActivityCategory />,
  },
  {
    matchRoute: '/progress/categories',
    path: '/progress/categories/bulk',

    name: 'Progress Project Submission',
    element: <ActivityCategoryBulkSave />,
  },
  {
    childOf: '/progress',
    path: '/progress/conditions',
    name: 'Conditions',
    permissionsRequired: [Permission.ConfigureProgressModule],
    icon: CloudIcon,
    element: <ProgressConditionTypesList />,
  },
  {
    matchRoute: '/projects/conditions',
    path: '/progress/conditions/:id',
    isAvailableOffline: true,
    name: 'Progress Project Submission',
    element: <SaveProgressConditionType />,
  },
  {
    matchRoute: '/projects/conditions',
    path: '/progress/conditions/new',
    isAvailableOffline: true,
    name: 'Progress Project Submission',
    element: <SaveProgressConditionType />,
  },
  {
    childOf: '/progress',
    path: '/progress/hseq',
    name: 'HSEQ',
    permissionsRequired: [Permission.ConfigureProgressModule],
    icon: HeartIcon,
    element: <ProgressIncidentTypesList />,
  },
  {
    childOf: '/progress',
    path: '/progress/exports',
    name: 'Exports',
    icon: TableCellsIcon,
    element: <CreateProgressDataExport />,
  },
  {
    matchRoute: '/projects/hseq',
    path: '/progress/hseq/:id',
    isAvailableOffline: true,
    name: 'Progress Project Submission',
    element: <SaveProgressIncidentType />,
  },
  {
    matchRoute: '/progress/hseq',
    path: '/progress/hseq/bulk',
    name: 'Progress Project Submission',
    element: <IncidentBulkSave />,
  },
  {
    matchRoute: '/projects/hseq',
    path: '/progress/hseq/new',
    isAvailableOffline: true,
    name: 'Progress Project Submission',
    element: <SaveProgressIncidentType />,
  },

  {
    matchRoute: '/projects/submissions',
    path: '/progress/submissions/:id/:outcomeId',
    name: 'Submissions',
    isAvailableOffline: true,
    permissionsRequired: [Permission.ViewProgressModule],
    icon: PaperAirplaneIcon,
    element: <ProgressSubmissionsList />,
  },
  {
    matchRoute: '/projects/submissions',
    path: '/progress/submissions/:id/:outcomeId/:date',
    isAvailableOffline: true,
    name: 'Progress Project Submission',
    element: <SaveProgressSubmission />,
  },
  {
    matchRoute: '/projects/submissions',
    path: '/progress/submissions/:id/:outcomeId/:date/exports',
    isAvailableOffline: false,
    name: 'Progress Project Submission',
    element: <CreateProgressExport />,
  },
  {
    matchRoute: '/projects/list',
    path: '/projects/new',
    name: 'Create Project',
    permissionsRequired: [Permission.UpdateProject],
    element: <SaveProject />,
  },
  {
    matchRoute: '/projects/list',
    path: '/projects/:id',
    name: 'Project',
    element: <Project />,
  },
  {
    matchRoute: '/projects/list',
    path: '/projects/:id/assignments/new',
    name: 'Assign To Project',
    permissionsRequired: [Permission.AssignLabourToProject],
    element: <AssignToProject />,
  },
  {
    matchRoute: '/projects/list',
    path: '/projects/:id/companies/assign',
    name: 'Assign Companies To Project',
    permissionsRequired: [Permission.UpdateProject],
    element: <AssignCompaniesToProjectPage />,
  },
  {
    matchRoute: '/projects/list',
    path: '/projects/:id/companies/:companyId/assign',
    name: 'Assign To Project',
    permissionsRequired: [Permission.UpdateProject],
    element: <AssignLabourToProjectForCompany />,
  },
  {
    matchRoute: '/projects/list',
    path: '/projects/:id/certifications/new',
    name: 'Add Required Certification',
    permissionsRequired: [Permission.UpdateProject],
    element: <SaveProjectCertification />,
  },
  {
    matchRoute: '/projects/list',
    path: '/projects/:projectId/assignments/:assignmentId',
    name: 'Assignee',
    element: <Assignment />,
  },
  {
    matchRoute: '/projects/list',
    path: '/projects/:projectId/companies/:companyId',
    name: 'Company',
    element: <ViewProjectCompany />,
  },
  {
    matchRoute: '/projects/list',
    path: '/projects/:projectId/companies/:companyId/assessments/:assessmentId',
    name: 'Sent Assessments',
    element: <SaveCompanyAssessmentSubmission />,
  },
  {
    matchRoute: '/projects/list',
    path: '/projects/:projectId/teams/:teamId',
    name: 'Team',
    element: <Team />,
  },
  {
    matchRoute: '/projects/list',
    path: '/projects/:projectId/teams/:teamId/assign',
    name: 'Assign To Project',
    permissionsRequired: [Permission.AssignLabourToProject],
    element: <AssignToTeam />,
  },
  {
    matchRoute: '/projects/list',
    path: '/projects/:projectId/outcomes/:outcomeId',
    name: 'Outcome',
    element: <OutcomeView />,
  },
  {
    matchRoute: '/projects/list',
    path: '/projects/:projectId/outcomes/new',
    name: 'Outcome',
    element: <OutcomeView />,
  },
  {
    matchRoute: '/projects/list',
    path: '/projects/:projectId/locations/:locationId',
    name: 'Location',
    element: <Location />,
  },
  {
    matchRoute: '/projects/list',
    path: '/projects/:projectId/locations/bulk',
    name: 'Location',
    element: <LocationBulkSave />,
  },
  {
    matchRoute: '/projects/list',
    path: '/projects/:projectId/activities/bulk',
    name: 'Activity',
    element: <ActivityBulkSave />,
  },
  {
    matchRoute: '/projects/list',
    path: '/projects/:projectId/assignments/:assignmentId/roles',
    name: 'Assignee',
    element: <AssignRoleToAssignee />,
  },
  {
    matchRoute: '/projects/list',
    path: '/projects/:projectId/assignments/roles/:assignmentRoleId/contracts',
    name: 'Sent Contracts',
    element: <SentContractsList />,
  },
  {
    matchRoute: '/projects/list',
    path: '/projects/:projectId/assignments/roles/:assignmentRoleId/assessments',
    name: 'Sent Assessments',
    element: <SentAssessmentsList />,
  },
  {
    matchRoute: '/projects/list',
    path: '/projects/:projectId/assignments/roles/:assignmentRoleId/assessments/:id',
    name: 'Sent Assessments',
    element: <SaveAssessmentSubmission />,
  },
  {
    matchRoute: '/projects/list',
    path: '/projects/:id/edit',
    name: 'Project',
    permissionsRequired: [Permission.UpdateProject],
    element: <SaveProject />,
  },
  {
    matchRoute: '/projects/list',
    path: '/projects/:projectId/teams/:id/edit',
    name: 'Project',
    permissionsRequired: [Permission.UpdateProject],
    element: <SaveProjectTeam />,
  },
  {
    matchRoute: '/projects/list',
    path: '/projects/:projectId/teams/new',
    name: 'Project',
    permissionsRequired: [Permission.UpdateProject],
    element: <SaveProjectTeam />,
  },
  {
    matchRoute: '/projects/list',
    path: '/projects/:projectId/activities/new',
    name: 'Project',
    permissionsRequired: [Permission.UpdateProject],
    element: <SaveProjectActivityType />,
  },
  {
    matchRoute: '/projects/list',
    path: '/projects/:projectId/activities/:id',
    name: 'Project',
    permissionsRequired: [Permission.UpdateProject],
    element: <SaveProjectActivityType />,
  },
  {
    matchRoute: '/projects/list',
    path: '/projects/:projectId/activities/groups/new',
    name: 'Project',
    permissionsRequired: [Permission.UpdateProject],
    element: <SaveProjectActivityGroup />,
  },
  {
    matchRoute: '/projects/list',
    path: '/projects/:projectId/activities/groups/:id',
    name: 'Project',
    permissionsRequired: [Permission.UpdateProject],
    element: <SaveProjectActivityGroup />,
  },
  {
    childOf: '/labour',
    path: '/labour',
    name: 'Summary',
    icon: InformationCircleIcon,
    element: <LabourHome />,
  },

  {
    childOf: '/labour',
    path: '/labour/roles/list',
    name: 'Roles',
    icon: BriefcaseIcon,
    element: <RoleList />,
  },
  {
    matchRoute: '/labour/roles/list',
    path: '/labour/roles/new',
    name: 'Create Role',
    permissionsRequired: [Permission.UpdateRoles],
    element: <SaveRole />,
  },
  {
    matchRoute: '/labour/roles/list',
    path: '/labour/roles/:id',
    name: 'Role',
    element: <Role />,
  },
  {
    matchRoute: '/labour/roles/list',
    path: '/labour/roles/:id/edit',
    name: 'Role',
    permissionsRequired: [Permission.UpdateRoles],
    element: <SaveRole />,
  },
  {
    childOf: '/finance',
    path: '/finance/codes',
    name: 'Activity Codes',
    icon: ClipboardDocumentIcon,
    element: <ActivityCodeList />,
  },
  {
    matchRoute: '/finance/codes',
    path: '/finance/codes/new',
    name: 'Save Activity Code',
    permissionsRequired: [Permission.UpdateActivityCodes],
    element: <SaveActivityCode />,
  },
  {
    matchRoute: '/finance/codes',
    path: '/finance/codes/:id',
    name: 'Save Activity Code',
    permissionsRequired: [Permission.UpdateActivityCodes],
    element: <SaveActivityCode />,
  },
  {
    childOf: '/users',
    path: '/users/list',
    name: 'Users',
    icon: UserSolidIcon,
    element: <UserList />,
  },
  {
    matchRoute: '/users/list',
    path: '/users/:id',
    name: 'Users',
    icon: UserSolidIcon,
    permissionsRequired: [Permission.AssignUserToGroup],
    element: <SaveUser />,
  },
  {
    childOf: '/users',
    path: '/users/groups/list',
    name: 'Groups',
    icon: UserGroupSolidIcon,
    element: <GroupList />,
  },
  {
    matchRoute: '/users/groups/list',
    path: '/users/groups/:id',
    name: 'Groups',
    permissionsRequired: [Permission.UpdateGroups],
    icon: UserSolidIcon,
    element: <SaveGroup />,
  },
  {
    matchRoute: '/users/groups/list',
    path: '/users/groups/new',
    name: 'Groups',
    permissionsRequired: [Permission.UpdateGroups],
    icon: UserSolidIcon,
    element: <SaveGroup />,
  },
  {
    childOf: '/labour',
    path: '/labour/employees/list',
    name: 'Employees',
    icon: SolidUserGroupIcon,
    element: <EmployeeList />,
  },
  {
    matchRoute: '/labour/employees/list',
    path: '/labour/employees/new',
    name: 'Create Employee',
    permissionsRequired: [Permission.UpdateLabourProfiles],
    element: <SaveEmployee />,
  },
  {
    matchRoute: '/labour/employees/list',
    path: '/labour/employees/:id',
    name: 'Employee',
    element: <Employee />,
  },
  {
    matchRoute: '/labour/employees/list',
    path: '/labour/employees/:id/edit',
    name: 'Employee',
    permissionsRequired: [Permission.UpdateLabourProfiles],
    element: <SaveEmployee />,
  },
  {
    matchRoute: '/labour/employees/list',
    path: '/labour/employees/:id/nextOfKin/edit',
    name: 'Next Of Kin',
    permissionsRequired: [Permission.UpdateLabourProfiles],
    element: <SaveNextOfKin />,
  },
  {
    matchRoute: '/labour/subcontractors/list',
    path: '/labour/subcontractors/:id/nextOfKin/edit',
    name: 'Next Of Kin',
    permissionsRequired: [Permission.UpdateLabourProfiles],
    element: <SaveNextOfKin />,
  },
  {
    matchRoute: '/labour/subcontractors/list',
    path: '/labour/subcontractors/:id/company/edit',
    name: 'Company',
    permissionsRequired: [Permission.UpdateLabourProfiles],
    element: <SaveCompanyViaLabourResource />,
  },
  {
    matchRoute: '/labour/subcontractors/list',
    path: '/labour/subcontractors/:labourResourceId/bank-accounts/:id/edit',
    name: 'Bank Account',
    permissionsRequired: [Permission.UpdateLabourProfiles],
    element: <SaveBankAccount />,
  },
  {
    matchRoute: '/labour/subcontractors/list',
    path: '/labour/subcontractors/:labourResourceId/bank-accounts/new',
    name: 'Bank Account',
    permissionsRequired: [Permission.UpdateLabourProfiles],
    element: <SaveBankAccount />,
  },
  {
    matchRoute: '/labour/subcontractors/list',
    path: '/labour/subcontractors/:labourResourceId/skills/:id/edit',
    name: 'Skills',
    permissionsRequired: [Permission.UpdateLabourProfiles],
    element: <SaveSkill />,
  },
  {
    matchRoute: '/labour/employees/list',
    path: '/labour/employees/:labourResourceId/skills/:id/edit',
    name: 'Skills',
    permissionsRequired: [Permission.UpdateLabourProfiles],
    element: <SaveSkill />,
  },
  {
    matchRoute: '/labour/subcontractors/list',
    path: '/labour/subcontractors/:labourResourceId/skills/new',
    name: 'Skills',
    permissionsRequired: [Permission.UpdateLabourProfiles],
    element: <SaveSkill />,
  },
  {
    matchRoute: '/labour/employees/list',
    path: '/labour/employees/:labourResourceId/skills/new',
    name: 'Skills',
    permissionsRequired: [Permission.UpdateLabourProfiles],
    element: <SaveSkill />,
  },

  {
    matchRoute: '/labour/subcontractors/list',
    path: '/labour/subcontractors/:labourResourceId/certifications/:id/edit',
    name: 'certifications',
    permissionsRequired: [Permission.UpdateLabourProfiles],
    element: <SaveCertification />,
  },
  {
    matchRoute: '/labour/employees/list',
    path: '/labour/employees/:labourResourceId/certifications/:id/edit',
    name: 'certifications',
    permissionsRequired: [Permission.UpdateLabourProfiles],
    element: <SaveCertification />,
  },
  {
    matchRoute: '/labour/subcontractors/list',
    path: '/labour/subcontractors/:labourResourceId/certifications/new',
    name: 'certifications',
    permissionsRequired: [Permission.UpdateLabourProfiles],
    element: <SaveCertification />,
  },
  {
    matchRoute: '/labour/employees/list',
    path: '/labour/employees/:labourResourceId/certifications/new',
    name: 'certifications',
    permissionsRequired: [Permission.UpdateLabourProfiles],
    element: <SaveCertification />,
  },

  {
    childOf: '/labour',
    path: '/labour/subcontractors/list',
    name: 'Subcontractors',
    icon: BuildingOffice2Icon,
    element: <SubcontractorList />,
  },
  {
    matchRoute: '/labour/subcontractors/list',
    path: '/labour/subcontractors/new',
    name: 'Create Subcontractor',
    permissionsRequired: [Permission.UpdateLabourProfiles],
    element: <SaveSubcontractor />,
  },
  {
    matchRoute: '/labour/subcontractors/list',
    path: '/labour/subcontractors/:id',
    name: 'Subcontractor',
    element: <Subcontractor />,
  },
  {
    matchRoute: '/labour/subcontractors/list',
    path: '/labour/subcontractors/:id/edit',
    name: 'Projects',
    permissionsRequired: [Permission.UpdateLabourProfiles],
    element: <SaveSubcontractor />,
  },

  {
    childOf: '/labour',
    path: '/labour/contracts',
    name: 'Contracts',
    icon: DocumentIcon,
    docusealEnabledRequired: true,
    element: <ContractList />,
  },
  {
    matchRoute: '/labour/contracts',
    path: '/labour/contracts/new',
    name: 'Contracts',
    icon: DocumentIcon,
    permissionsRequired: [Permission.EditContractTemplates],
    element: <SaveContract />,
  },
  {
    matchRoute: '/labour/contracts',
    path: '/labour/contracts/:id',
    name: 'Contracts',
    icon: DocumentIcon,
    permissionsRequired: [Permission.EditContractTemplates],
    element: <SaveContract />,
  },
  {
    matchRoute: '/labour/contracts',
    path: '/labour/contracts/:id/build',
    name: 'Contracts',
    icon: DocumentIcon,
    permissionsRequired: [Permission.EditContractTemplates],
    element: <ContractsBuilder />,
  },
  {
    childOf: '/labour',
    path: '/labour/certifications',
    name: 'Certifications',
    icon: AcademicCapIcon,
    element: <CertificationTypeList />,
  },
  {
    matchRoute: '/labour/certifications',
    path: '/labour/certifications/expiring',
    name: 'Certifications',
    icon: AcademicCapIcon,
    element: <ExpiringCertificationsList />,
  },
  {
    matchRoute: '/labour/certifications',
    path: '/labour/certifications/:id',
    name: 'Certifications',
    icon: AcademicCapIcon,
    element: <SaveCertificationType />,
  },
  {
    matchRoute: '/labour/certifications',
    path: '/labour/certifications/:id/dashboard',
    name: 'Certifications',
    icon: AcademicCapIcon,
    element: <CertificationTypeDashboard />,
  },
  {
    matchRoute: '/labour/certifications',
    path: '/labour/certifications/new',
    name: 'Certifications',
    icon: AcademicCapIcon,
    element: <SaveCertificationType />,
  },
  {
    childOf: '/labour',
    path: '/labour/skills',
    name: 'Skills',
    icon: StarIcon,
    element: <SkillTypeList />,
  },
  {
    matchRoute: '/labour/skills',
    path: '/labour/skills/:id',
    name: 'Skills',
    icon: StarIcon,
    element: <SaveSkillType />,
  },
  {
    matchRoute: '/labour/skills',
    path: '/labour/skills/new',
    name: 'Skills',
    icon: StarIcon,
    element: <SaveSkillType />,
  },
  {
    childOf: '/labour',
    path: '/labour/assessments',
    name: 'Assessments',
    icon: QuestionMarkCircleIcon,
    element: <AssessmentTypeList />,
  },
  {
    matchRoute: '/labour/assessments',
    path: '/labour/assessments/:id/dashboard',
    name: 'Assessments',
    icon: QuestionMarkCircleIcon,
    element: <AssessmentDashboard />,
  },
  {
    matchRoute: '/labour/assessments',
    path: '/labour/assessments/:id/edit',
    name: 'Assessments',
    icon: QuestionMarkCircleIcon,
    element: <SaveAssessmentType />,
  },
  {
    matchRoute: '/labour/assessments',
    path: '/labour/assessments/new',
    name: 'Assessments',
    icon: QuestionMarkCircleIcon,
    element: <SaveAssessmentType />,
  },
  {
    childOf: '/custom',
    path: '/custom/options',
    name: 'Custom Options',
    icon: VariableIcon,
    element: <CustomOptionList />,
  },
  {
    matchRoute: '/custom/options',
    path: '/custom/options/new',
    name: 'Custom Options',
    permissionsRequired: [Permission.UpdateCustomData],
    element: <SaveCustomOptionsSet />,
  },
  {
    matchRoute: '/custom/options',
    path: '/custom/options/:id',
    name: 'Custom Options',
    permissionsRequired: [Permission.UpdateCustomData],
    element: <SaveCustomOptionsSet />,
  },

  {
    childOf: '/custom',
    path: '/custom/fields',
    name: 'Custom Fields',
    icon: DocumentTextIcon,
    element: <CustomFieldList />,
  },
  {
    matchRoute: '/custom/fields',
    path: '/custom/fields/new',
    name: 'Custom Fields',
    permissionsRequired: [Permission.UpdateCustomData],
    element: <SaveCustomFieldsGroup />,
  },
  {
    matchRoute: '/custom/fields',
    path: '/custom/fields/:id',
    name: 'Custom fields',
    permissionsRequired: [Permission.UpdateCustomData],
    element: <SaveCustomFieldsGroup />,
  },

  {
    childOf: '/time',
    path: '/time/timesheets',
    name: 'Timesheets',
    icon: SolidClockIcon,
    element: <TimesheetList />,
  },
  {
    childOf: '/time',
    path: '/time/timesheets/submissions',
    name: 'Submissions',
    icon: PaperAirplaneIcon,
    permissionsRequired: [Permission.ViewTimesheetSubmissions],
    element: <TimesheetSubmissionList />,
  },
  {
    matchRoute: '/time/timesheets',
    path: '/time/timesheets/submissions/configuration/:configurationId',
    name: 'Submissions',
    icon: PaperAirplaneIcon,
    permissionsRequired: [Permission.ViewTimesheetSubmissions],
    element: <TimesheetSubmissionList />,
  },
  {
    matchRoute: '/time/timesheets',
    path: '/time/timesheets/:configurationId/',
    name: 'Timesheets',
    element: <SaveTimesheet />,
  },

  {
    matchRoute: '/time/timesheets',
    path: '/time/timesheets/:configurationId/:weekId',
    name: 'Timesheets',
    element: <SaveTimesheet />,
  },
  {
    matchRoute: '/time/timesheets',
    path: '/time/timesheets/submissions/:submissionId',
    name: 'Timesheets',
    permissionsRequired: [Permission.ApproveTimesheets],
    element: <ReviewTimesheet />,
  },
  {
    childOf: '/time',
    path: '/time/timesheets/configuration',
    name: 'Configurations',
    icon: Cog8ToothIcon,
    permissionsRequired: [Permission.UpdateTimesheetConfiguration],
    element: <TimesheetConfigurationList />,
  },
  {
    matchRoute: '/time/timesheets/configuration',
    path: '/time/timesheets/configuration/new',
    name: 'Configurations',
    permissionsRequired: [Permission.UpdateTimesheetConfiguration],
    element: <SaveTimesheetConfiguration />,
  },
  {
    childOf: '/time',
    path: '/time/timesheets/exports',
    name: 'Exports',
    icon: TableCellsIcon,
    permissionsRequired: [Permission.UpdateExportConfiguration],
    element: <ExportList />,
  },
  {
    childOf: '/time',
    path: '/time/timesheets/dashboard',
    name: 'Dashboard',
    icon: TableCellsIcon,
    moduleTogglesRequired: ['finance'],
    permissionsRequired: [Permission.ViewRoleRates],
    element: <TimeTrackingDashboard />,
  },
  {
    matchRoute: '/time/timesheets/configuration',
    path: '/time/timesheets/configuration/:id',
    name: 'Configurations',
    permissionsRequired: [Permission.UpdateTimesheetConfiguration],
    element: <SaveTimesheetConfiguration />,
  },
  {
    matchRoute: '/time/timesheets/exports',
    path: '/time/timesheets/exports/configuration/:id',
    name: 'Exports',
    permissionsRequired: [Permission.UpdateExportConfiguration],
    element: <ExportConfigurationPage />,
  },
  {
    matchRoute: '/time/timesheets/exports',
    path: '/time/timesheets/exports/configuration/:id/run',
    name: 'Exports',
    element: <CreateExport />,
  },
  {
    matchRoute: '/time/timesheets/exports',
    path: '/time/timesheets/exports/configuration/new',
    name: 'Exports',
    permissionsRequired: [Permission.UpdateExportConfiguration],
    element: <ExportConfigurationPage />,
  },
  {
    childOf: '/profile',
    path: '/profile/personal',
    name: 'Personal',
    icon: UserCircleSolidIcon,
    element: <PersonalProfile />,
  },
  {
    childOf: '/profile',
    path: '/profile/assignments',
    name: 'Assignments',
    icon: BriefcaseIcon,
    element: <MyAssignmentList />,
  },
  {
    childOf: '/profile',
    path: '/profile/company',
    labourType: 'SUBCONTRACTOR',
    name: 'Company',
    icon: BuildingIconOutline,
    element: <ViewCompanyProfile />,
  },
  {
    matchRoute: '/profile/company',
    path: '/profile/company/edit',
    name: 'Company',
    icon: BuildingIconOutline,
    element: <SaveMyCompany />,
  },
  {
    matchRoute: '/profile/company',
    path: '/profile/company/edit',
    name: 'Company',
    element: <SaveMyCompany />,
  },
  {
    matchRoute: '/profile/nextOfKin',
    path: '/profile/nextOfKin/edit',
    name: 'Next Of Kin',
    element: <SaveMyNextOfKin />,
  },
  {
    matchRoute: '/profile/personal',
    path: '/profile/personal/edit',
    name: 'Personal',
    element: <SaveMyPersonalDetails />,
  },
  {
    childOf: '/profile',
    path: '/profile/accounts',
    labourType: 'SUBCONTRACTOR',
    name: 'Bank Accounts',
    icon: CurrencyPoundIcon,
    element: <BankingDetail />,
  },
  {
    matchRoute: '/profile/accounts',
    path: '/profile/accounts/:id/edit',
    name: 'Bank Account',
    element: <SaveMyBankAccount />,
  },
  {
    matchRoute: '/profile/accounts',
    path: '/profile/accounts/new',
    name: 'Bank Account',
    element: <SaveMyBankAccount />,
  },
  {
    childOf: '/profile',
    path: '/profile/nextOfKin',
    name: 'Next of Kin',
    icon: PhoneArrowDownLeftIcon,
    element: <NextOfKinDetail />,
  },
  {
    childOf: '/profile',
    path: '/profile/contracts',
    labourType: 'SUBCONTRACTOR',
    name: 'Contracts',
    icon: DocumentIcon,
    element: <SentContractsListForProfile />,
  },
  {
    childOf: '/profile',
    path: '/profile/certifications',
    name: 'Certifications',
    icon: AcademicCapIcon,
    element: <MyCertification />,
  },
  {
    matchRoute: '/profile/certifications',
    path: '/profile/certifications/new',
    name: 'Certifications',
    icon: AcademicCapIcon,
    element: <SaveMyCertification />,
  },
  {
    matchRoute: '/profile/certifications',
    path: '/profile/certifications/:id',
    name: 'Certifications',
    icon: AcademicCapIcon,
    element: <SaveMyCertification />,
  },
  {
    childOf: '/profile',
    path: '/profile/assessments',
    name: 'Assessments',
    icon: QuestionMarkCircleIcon,
    element: <MyAssessmentSubmission />,
  },
  {
    matchRoute: '/profile/assessments',
    path: '/profile/assessments/new',
    name: 'Assessments',
    icon: AcademicCapIcon,
    element: <SaveMyAssessment />,
  },
  {
    matchRoute: '/profile/assessments',
    path: '/profile/assessments/:id',
    name: 'Assessments',
    icon: AcademicCapIcon,
    element: <SaveMyAssessment />,
  },
  {
    matchRoute: '/profile/contracts',
    path: '/profile/contracts/:id',
    labourType: 'SUBCONTRACTOR',
    name: 'Contracts',
    element: <SignContractPage />,
  },

  {
    path: '/settings',
    name: 'Settings',
    element: <SettingsHome />,
  },
  {
    childOf: '/settings',
    path: '/settings/notifications',
    name: 'Notifications',
    icon: BellIcon,
    element: <NotificationSettings />,
  },
  {
    childOf: '/companies',
    path: '/companies/list',
    name: 'Companies',
    icon: BuildingOffice2Icon,
    element: <CompaniesList />,
  },
  {
    matchRoute: '/companies/list',
    path: '/companies/:id',
    name: 'Companies',
    element: <CompanyPage />,
  },
  {
    matchRoute: '/companies/list',
    path: '/companies/:companyId/edit',
    name: 'Companies',
    element: <SaveCompany />,
  },
  {
    matchRoute: '/companies/list',
    path: '/companies/new',
    name: 'Companies',
    element: <SaveCompany />,
  },
  {
    matchRoute: '/companies/list',
    path: '/companies/:id/assign',
    name: 'Companies',
    element: <AssignPeopleToCompanyPage />,
  },
  {
    matchRoute: '/companies/list',
    path: '/companies/:companyId/certifications/:id/edit',
    name: 'certifications',
    element: <SaveCompanyCertification />,
  },
  {
    matchRoute: '/companies/list',
    path: '/companies/:companyId/certifications/new',
    name: 'certifications',
    element: <SaveCompanyCertification />,
  },
];

export const routes = [
  {
    path: '/',
    element: <AppWrapper />,
    children: [...mainRoutes, ...childRoutes],
  },
];
