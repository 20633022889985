import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';

import moment from 'moment';
import { Button } from '../../../components/Buttons/Button';
import { GET_PROJECT_OUTCOMES } from '../../../graphql/queries/projects';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import NoData from '../../../components/NoData/NoData';
import LinkButton from '../../../components/Buttons/LinkButton';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';

interface ProjectOutcomeListProps {
  children?: React.ReactElement;
  projectId?: string;
  assigneeId?: string;
}

const headerMapping = {
  name: 'Assignee Name',
  status: 'Status',
  type: 'Type',
  updatedAt: 'Updated At',
} as Record<string, any>;

const ProjectOutcomesTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
  filterText,
  filterDimensions = [],
  setFilterText = () => {},
  selectedFilterDimension,
  setSelectedFilterDimension = () => {},
  NoData,
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    filterDimensions={filterDimensions}
    showFilterOptions
    filterText={filterText}
    setFilterText={setFilterText}
    selectedFilterDimension={selectedFilterDimension}
    setSelectedFilterDimension={setSelectedFilterDimension}
    RowComponent={({ id, rowData }) => (
      <div
        key={id}
        className={`border-b flex flex-col w-full justify-around items-center lg:flex-row border-gray-200 hover:bg-gray-100`}
      >
        {rowData?.name ? (
          <div key={id} className="py-3 px-6 text-sm text-left w-full">
            <div className="flex flex-col flex-wrap gap-y-2 gap-x-2 mt-2">
              <p>{rowData?.name}</p>
            </div>
          </div>
        ) : null}
        <div
          key={id}
          className="py-3 px-6 text-sm flex items-center justify-end  gap-x-2 text-black w-full"
        >
          <Button text="View Scope" onClick={() => onClickRow(rowData?.id)} />
        </div>
      </div>
    )}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
    NoData={NoData}
  />
);

export const ProjectOutcomeListPage = ({
  children,
  projectId,
  assigneeId,
}: ProjectOutcomeListProps) => {
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [filterText, setFilterText] = useState<string>();
  const [selectedFilterDimension, setSelectedFilterDimension] =
    useState<string>('name');

  const { limit, offset, setOffset } = usePagination();

  const [debouncedFilterText, setDebouncedSearchTerm] = useState(filterText);

  const filter = debouncedFilterText
    ? {
        filters: [
          {
            field: selectedFilterDimension,
            stringFilters: [{ ilike: debouncedFilterText }],
          },
        ],
      }
    : null;
  const [fetch, { data, loading }] = useLazyQuery(GET_PROJECT_OUTCOMES, {
    variables: { projectId, assigneeId, input: { limit, offset } },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetch({
      variables: {
        projectId,
        assigneeId,
        input: { limit, offset, ...(filter ? { filter } : {}) },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, projectId, assigneeId, debouncedFilterText]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setOffset(0);
      setDebouncedSearchTerm(filterText);
    }, 600);
    return () => {
      clearTimeout(handler);
    };
  }, [filterText, setOffset]);

  const projectOutcomeResults = data?.getProjectOutcomes;

  const totalPages = Math.ceil(projectOutcomeResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const { userProfile } = useContext(UserProfileContext);

  return (
    <div>
      <>
        {userProfile?.permissions?.includes(Permission.UpdateProject) ? (
          <div className="w-full flex flex-col px-8 justify-between items-end py-2">
            <Button
              text="New Scope"
              onClick={() => navigate(`/projects/${projectId}/outcomes/new`)}
            />
          </div>
        ) : null}
        <p className="text-sm px-4 text-gray-600">
          Scopes of work can be used for reporting purposes in daily progress
          reporting and for performing analysis of project efficiency.
        </p>
        <ProjectOutcomesTable
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          totalResults={projectOutcomeResults?.count || 0}
          onClickRow={(id: any) => {
            navigate(`/projects/${projectId}/outcomes/${id}`);
          }}
          filterDimensions={[{ id: 'name', name: 'Name' }]}
          NoData={() => (
            <NoData loading={loading}>
              It looks like there are no scopes for this project yet!
              <LinkButton
                to={`/projects/${projectId}/outcomes/new`}
                target="_blank"
              >
                Add a Project Scope
              </LinkButton>
            </NoData>
          )}
          data={projectOutcomeResults?.results?.map((outcome: any) => ({
            id: outcome.id,
            name: outcome.name,
          }))}
          filterText={filterText}
          setFilterText={setFilterText}
          selectedFilterDimension={selectedFilterDimension}
          setSelectedFilterDimension={setSelectedFilterDimension}
          onPageChange={handlePageChange}
        />
      </>
      {children}
    </div>
  );
};

export const ProjectOutcomeList = ({
  projectId,
  assigneeId,
}: ProjectOutcomeListProps) => (
  <PaginationProvider>
    <ProjectOutcomeListPage projectId={projectId} assigneeId={assigneeId} />
  </PaginationProvider>
);
