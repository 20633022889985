import { useEffect } from 'react';
import { useOrganisationAwareApollo } from '../useOrganisationAwareApollo';
import { ApiHookMeta } from './types';
import { GET_ASSESSMENTS } from '../../graphql/queries/assessments';

export enum AssessmentSubmissionsTableFilter {
  NOT_STARTED = 'NOT_STARTED',
  AWAITING_OUTCOME = 'AWAITING_OUTCOME',
}

export default function useAssessmentSubmissions({
  assessmentTypeId,
  projectId,
  filter,
  unsent,
  limit,
  offset,
  sortByColumn,
  sortDirection,
}: {
  assessmentTypeId?: string;
  projectId?: string;
  filter?: AssessmentSubmissionsTableFilter;
  unsent?: boolean;
  limit?: number;
  offset?: number;
  sortByColumn?: string;
  sortDirection?: string;
}): [any, ApiHookMeta, any?] {
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [fetch, { data, loading, error, ...result }] = useLazyQuery(
    GET_ASSESSMENTS,
    {
      variables: { assessmentTypeId },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    fetch({
      variables: {
        assessmentTypeId,
        filter,
        projectId,
        unsent,
        input: {
          limit,
          offset,
          ...(sortByColumn && sortDirection
            ? { order: [[sortByColumn, sortDirection]] }
            : {}),
        },
      },
    });
  }, [
    assessmentTypeId,
    fetch,
    filter,
    limit,
    offset,
    projectId,
    sortByColumn,
    sortDirection,
    unsent,
  ]);

  const assessmentSubmissions = data?.getAssessmentSubmissions?.results || [];
  const count = data?.getAssessmentSubmissions?.count || 0;

  return [
    assessmentSubmissions,
    {
      count,
      loading,
      error,
      ...result,
    },
    async (variables: any) => await fetch(variables),
  ];
}
