import { gql } from '@apollo/client';

export const SAVE_CONTRACT = gql`
  mutation saveContract(
    $id: ID
    $input: SaveContractInput!
    $activeOrganisation: String!
  ) {
    saveContract(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const CREATE_CONTRACT_SUBMISSION = gql`
  mutation createContractSubmission(
    $input: ContractToSignInput!
    $activeOrganisation: String!
  ) {
    createContractSubmission(
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;
