import { useContext } from 'react';
import { UserProfileContext } from '../../context/UserProfileContext';
import NoData from './NoData';
import LinkButton from '../Buttons/LinkButton';
import { Permission } from '../../types/Permissions';

export default function NoContracts({ loading }: { loading?: boolean }) {
  const { userProfile } = useContext(UserProfileContext);

  return (
    <NoData loading={loading}>
      It looks like there are no contracts configured for this organisation!
      {userProfile?.permissions.includes(Permission.EditContractTemplates) ||
      userProfile?.permissions.includes(Permission.CreateContractTemplates) ? (
        <LinkButton to="/labour/contracts/new" target="_blank">
          Add a Contract
        </LinkButton>
      ) : null}
    </NoData>
  );
}
