import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface CompaniesHomeProps {
  children?: React.ReactElement;
}

export const CompaniesHome = ({ children }: CompaniesHomeProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/companies/list');
  }, []);
  return <div>{children}</div>;
};
