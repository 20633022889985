import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from '../../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../../context/PaginationContext';
import { useOrganisationAwareApollo } from '../../../../hooks/useOrganisationAwareApollo';
import moment from 'moment';
import Avatar from 'react-avatar';
import { BlueBadge, YellowBadge } from '../../../../components/Badges/Badges';
import NoData from '../../../../components/NoData/NoData';
import { GET_LABOUR_ASSIGNABLE_TO_PROJECT_FOR_COMPANY } from '../../../../graphql/queries/companies';
import useDebounce from '../../../../hooks/useDebounce';

interface LabourListProps {
  companyId: string;
  projectId: string;
  filterIds?: string[];
  onClickRow?: (id: string, results: any[]) => void;
}

const headerMapping = {
  name: 'Name',
  email: 'Email',
  internalId: 'Internal Id',
  updatedAt: 'Updated At',
} as Record<string, any>;

const PaginatedAssignableLabourList = ({
  companyId,
  projectId,
  onClickRow,
  filterIds = [],
}: LabourListProps) => {
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [filterText, setFilterText] = useState<string>();
  const [selectedFilterDimension, setSelectedFilterDimension] =
    useState<string>('lastName');

  const { limit, offset, setOffset } = usePagination();

  const debouncedFilterText = useDebounce(filterText);

  const filter = debouncedFilterText
    ? {
        filters: [
          {
            field: selectedFilterDimension,
            stringFilters: [{ ilike: debouncedFilterText }],
          },
        ],
      }
    : null;

  const [fetch, { data, loading }] = useLazyQuery(
    GET_LABOUR_ASSIGNABLE_TO_PROJECT_FOR_COMPANY,
    {
      variables: { companyId, projectId, input: { limit, offset } },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    fetch({
      variables: {
        companyId,
        input: { limit, offset, ...(filter ? { filter } : {}) },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, limit, offset, debouncedFilterText]);

  useEffect(() => {
    setOffset(0);
  }, [debouncedFilterText, setOffset]);

  const labourResults = data?.getLabourAssignableToProjectForCompany;
  const count = labourResults?.count;
  const results = labourResults?.results;

  const totalPages = Math.ceil(count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  return (
    <Table
      currentPage={Math.floor(offset / limit) + 1}
      totalPages={totalPages}
      totalResults={count || 0}
      itemsPerPage={limit}
      onClickRow={(id: any) => {
        if (onClickRow) {
          onClickRow(id, results);
        } else {
          navigate(`/labour/subcontractors/${id}`);
        }
      }}
      columnsToFilter={['firstName', 'lastName', 'id', 'status']}
      filterDimensions={[
        { id: 'firstName', name: 'First Name' },
        { id: 'lastName', name: 'Last Name' },
        { id: 'email', name: 'Email' },
      ]}
      showFilterOptions
      filterText={filterText}
      setFilterText={setFilterText}
      selectedFilterDimension={selectedFilterDimension}
      setSelectedFilterDimension={setSelectedFilterDimension}
      headerMapper={(text: string) => headerMapping[text]}
      onPageChange={handlePageChange}
      data={results
        ?.filter((result: any) => !filterIds?.includes(result.id))
        .map((result: any) => ({
          id: result?.id,
          name: (
            <div className="flex gap-x-2 items-center">
              <Avatar
                name={`${result?.firstName} ${result?.lastName}`}
                size="30"
                round
              />
              {`${result.lastName}, ${result.firstName}`}
            </div>
          ),
          email: result?.email || 'Not Configured',
          internalId: result?.internalId ? (
            <BlueBadge text={result?.internalId} />
          ) : (
            <YellowBadge text={'Not Configured'} />
          ),
          updatedAt: moment.unix(parseInt(result.updatedAt) / 1000).fromNow(),
          status: undefined,
        }))}
      NoData={() => (
        <NoData loading={loading}>
          It looks like there are no subcontractors that are assignable to this
          project for this company!
        </NoData>
      )}
    />
  );
};

export const AssignableLabourList = (props: LabourListProps) => (
  <PaginationProvider>
    <PaginatedAssignableLabourList {...props} />
  </PaginationProvider>
);
