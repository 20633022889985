// @ts-ignore
import { default as ComponentDatePicker } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ValidationMessage from '../Validation/ValidationMessage';
import { useEffect, useState } from 'react';
import { XCircleIcon } from '@heroicons/react/24/outline';

export interface DatePickerProps {
  id: string;
  label?: string; // TODO Remove this, it is not used
  value: any;
  title: string;
  validation?: string;
  isMonthPicker?: boolean;
  handleChange: (date: any) => void;
  inputTransformer?: (date: any) => any;
  portalId?: string; // Used for making it display correctly in modals
}

const DatePicker = ({
  id,
  value,
  handleChange,
  title,
  inputTransformer,
  validation,
  isMonthPicker,
  portalId,
}: DatePickerProps) => {
  const calculatedStartDate = value ? new Date(value) : new Date();
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    calculatedStartDate || null
  );

  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  return (
    <div data-testid="datepicker">
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {title}
      </label>
      {validation ? <ValidationMessage message={validation} /> : null}
      <div className="flex items-center gap-x-2 mt-2 ">
        <ComponentDatePicker
          id={id}
          portalId={portalId}
          selected={selectedDate}
          // @ts-ignore
          onSelect={(date) => setSelectedDate(date)}
          value={value}
          // @ts-ignore
          onChange={(date) => {
            if (inputTransformer) {
              handleChange(inputTransformer(date));
            } else {
              handleChange(date);
            }
          }}
          showMonthYearPicker={isMonthPicker}
          dateFormat={isMonthPicker ? 'MM-yyyy' : 'dd-MM-yyyy'}
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
        />
        {value ? (
          <button className="text-xs" onClick={() => handleChange(undefined)}>
            <XCircleIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default DatePicker;
