import { Button } from '../Buttons/Button';
import NumericInput from '../Inputs/NumericInput';

export interface TableValue {
  columnId: string;
  rowId: string;
  name?: string;
  value?: Number | string;
}

interface RateConfigurationByKeyParams {
  question?: any;
  handleChange?: (value: TableValue[]) => void;
  value?: TableValue[];
  validation?: any;
  classNames?: string;
  isDisabled?: boolean;
}

const RateConfigurationByKey = ({
  question = {},
  handleChange,
  value = [],
  validation,
  classNames,
}: RateConfigurationByKeyParams) => {
  const { id, title, tableOptions, description } = question ?? {};

  const headingOptions = tableOptions?.[0]?.options;

  const mapOptionsToValue = (tableOptions: any[]): TableValue[] => {
    const tableValues = [] as TableValue[];
    headingOptions?.forEach(({ heading: columnHeading }: any) => {
      tableValues.push(
        ...tableOptions.map((tableOption) => ({
          columnId: columnHeading.id,
          name: tableOption.heading.name,
          rowId: tableOption.heading.id,
          value: undefined,
        }))
      );
    });
    return tableValues;
  };

  return (
    <div className={classNames}>
      <label className="block text-sm my-2 font-medium text-gray-700">
        {title ? title : null}
      </label>

      <div className="flex flex-col sm:-mx-6 md:mx-0">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl- pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
              >
                Key
              </th>
              {headingOptions?.map((tableOption: any) => (
                <th
                  key={tableOption?.heading?.id}
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-center font-semibold text-gray-900 sm:pl-6 md:pl-0"
                >
                  {tableOption?.heading?.id}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableOptions?.map((tableOption: any) => (
              <tr
                key={tableOption?.heading?.id}
                className="border-b border-gray-200"
              >
                <td className="py-4 pl-4 pr-3 pt-7 text-sm sm:pl-6 md:pl-0 flex items-center">
                  <div
                    className="mr-2 rounded-full"
                    style={{
                      width: 20,
                      height: 20,
                      backgroundColor: tableOption.heading.colour,
                    }}
                  ></div>
                  <div className="font-medium text-gray-900">
                    {tableOption?.heading?.name}
                  </div>
                </td>
                {headingOptions?.map((headingOption: any) => {
                  const thisValue = value?.find(
                    (inputOption) =>
                      inputOption.columnId === headingOption?.heading?.id &&
                      inputOption.rowId === tableOption.heading.id
                  )?.value;
                  return (
                    <td
                      key={`${tableOption?.heading?.id}_${headingOption?.heading?.id}`}
                      className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell"
                    >
                      <NumericInput
                        value={thisValue}
                        handleChange={(item: any) => {
                          const notThisOption = (thisOption: TableValue) =>
                            !(
                              thisOption.columnId ===
                                headingOption?.heading?.id &&
                              thisOption.rowId === tableOption.heading.id
                            );
                          const update = [
                            ...value?.filter(notThisOption),
                            {
                              rowId: tableOption.heading.id,
                              name: tableOption.heading.name,
                              columnId: headingOption?.heading?.id,
                              value: item,
                            },
                          ];
                          if (handleChange) {
                            handleChange(update);
                          }
                        }}
                        question={{
                          placeholder: headingOption.heading.name,
                          id: `${tableOption.heading.id}_${headingOption?.heading?.id}`,
                        }}
                      />
                      <div className="my-2">
                        <Button
                          text={`Copy to all ${headingOption?.heading?.id}`}
                          onClick={() => {
                            const notThisColumn = (thisOption: TableValue) =>
                              !(
                                thisOption.columnId ===
                                headingOption?.heading?.id
                              );
                            const thisColumn = (thisOption: TableValue) =>
                              thisOption.columnId ===
                              headingOption?.heading?.id;
                            const optionsAsValues = mapOptionsToValue(
                              JSON.parse(JSON.stringify(tableOptions))
                            );
                            const currentValues = JSON.parse(
                              JSON.stringify(value)
                            ) as TableValue[];

                            const currentValuesNotThisColumn =
                              currentValues.filter(notThisColumn);
                            const newValuesForThisColumn = optionsAsValues
                              .filter(thisColumn)
                              .map((valueEntry) =>
                                valueEntry.columnId ===
                                headingOption?.heading?.id
                                  ? { ...valueEntry, value: thisValue }
                                  : valueEntry
                              );

                            if (handleChange) {
                              handleChange(
                                currentValuesNotThisColumn.concat(
                                  newValuesForThisColumn
                                )
                              );
                            }
                          }}
                        />
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {description ? (
        <p className="mt-2 text-sm text-gray-500">{description}</p>
      ) : null}
    </div>
  );
};

export default RateConfigurationByKey;
