import { gql } from '@apollo/client';

export const GET_ASSESSMENT_TYPES_SUMMARY = gql`
  query getAssessmentTypes(
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    getAssessmentTypes(input: $input, activeOrganisation: $activeOrganisation) {
      count
      results {
        id
        name
        requiredForCompanies
        requiredForSubcontractors
        requiredForEmployees
        requiredForRoles
        enabled
        updatedAt
      }
    }
  }
`;

export const GET_ASSESSMENT_TYPES = gql`
  query getAssessmentTypes(
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    getAssessmentTypes(input: $input, activeOrganisation: $activeOrganisation) {
      count
      results {
        id
        name
        outcomes
        notifierEmail
        assessmentInformation
        questions {
          id
          question
          type
          choices {
            id
            name
          }
        }
        requiredForCompanies
        requiredForSubcontractors
        requiredForEmployees
        requiredForRoles
        enabled
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_ASSESSMENT_TYPES_COUNT = gql`
  query getAssessmentTypes(
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    getAssessmentTypes(input: $input, activeOrganisation: $activeOrganisation) {
      count
    }
  }
`;

export const SEARCH_ASSESSMENT_TYPES = gql`
  query searchAssessmentTypes(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $searchTerm: String!
  ) {
    searchAssessmentTypes(
      input: $input
      activeOrganisation: $activeOrganisation
      searchTerm: $searchTerm
    ) {
      count
      results {
        id
        name
        outcomes
        questions {
          id
          question
          choices {
            id
            name
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_ASSESSMENTS = gql`
  query getAssessmentSubmissions(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $labourResourceId: ID
    $assignmentRoleId: ID
    $assessmentTypeId: ID
    $filter: String
    $projectId: ID
    $unsent: Boolean
  ) {
    getAssessmentSubmissions(
      input: $input
      activeOrganisation: $activeOrganisation
      labourResourceId: $labourResourceId
      assignmentRoleId: $assignmentRoleId
      assessmentTypeId: $assessmentTypeId
      filter: $filter
      projectId: $projectId
      unsent: $unsent
    ) {
      count
      results {
        id
        assessmentType {
          id
          name
          outcomes
          questions {
            id
            question
            type
            choices {
              id
              name
            }
          }
        }
        assignmentRole {
          id
          assignment {
            id
            project {
              id
              name
              internalId
            }
            lrs {
              id
              firstName
              lastName
              email
            }
          }
          role {
            name
          }
        }
        outcome
        responses {
          question
          answer
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_ASSESSMENT_ATTACHMENTS = gql`
  query getAssessmentAttachments(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $assessmentId: ID
    $assessmentTypeId: ID
  ) {
    getAssessmentAttachments(
      input: $input
      activeOrganisation: $activeOrganisation
      assessmentId: $assessmentId
      assessmentTypeId: $assessmentTypeId
    ) {
      count
      results {
        id
        fileName
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_MY_ASSESSMENT_ATTACHMENTS = gql`
  query getMyAssessmentAttachments(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $assessmentId: ID!
  ) {
    getMyAssessmentAttachments(
      input: $input
      activeOrganisation: $activeOrganisation
      assessmentId: $assessmentId
    ) {
      count
      results {
        id
        fileName
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_ASSIGNMENTS_WITH_MISSING_OR_INCOMPLETE_ASSESSMENTS = gql`
  query getAssignmentsWithMissingOrIncompleteAssessments(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $assessmentTypeId: ID
    $filter: String
    $projectId: ID
  ) {
    getAssignmentsWithMissingOrIncompleteAssessments(
      input: $input
      activeOrganisation: $activeOrganisation
      assessmentTypeId: $assessmentTypeId
      filter: $filter
      projectId: $projectId
    ) {
      count
      results {
        id
        lrs {
          id
          type
          firstName
          lastName
          email
        }
        updatedAt
      }
    }
  }
`;

export const GET_ASSESSMENT_SUBMISSIONS_FOR_COMPANY = gql`
  query getAssessmentSubmissionsForCompany(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $companyId: String
    $projectId: String
  ) {
    getAssessmentSubmissionsForCompany(
      input: $input
      activeOrganisation: $activeOrganisation
      companyId: $companyId
      projectId: $projectId
    ) {
      count
      results {
        id
        assessmentType {
          id
          name
          outcomes
          questions {
            id
            question
            type
            choices {
              id
              name
            }
          }
        }
        company {
          id
          companyName
        }
        project {
          id
          name
        }
        outcome
        responses {
          question
          answer
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_ASSESSMENT_SUBMISSIONS_FOR_COMPANY_DASHBOARD = gql`
  query getAssessmentSubmissionsForCompanyDashboard(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $assessmentTypeId: String!
    $projectId: String
    $filter: String
    $unsent: Boolean
  ) {
    getAssessmentSubmissionsForCompanyDashboard(
      input: $input
      activeOrganisation: $activeOrganisation
      assessmentTypeId: $assessmentTypeId
      projectId: $projectId
      filter: $filter
      unsent: $unsent
    ) {
      count
      results {
        id
        assessmentType {
          id
          name
          outcomes
          questions {
            id
            question
            type
            choices {
              id
              name
            }
          }
        }
        company {
          id
          companyName
        }
        project {
          id
          name
          internalId
        }
        outcome
        responses {
          question
          answer
        }
        createdAt
        updatedAt
      }
    }
  }
`;
