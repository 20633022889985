import { useNavigate } from 'react-router-dom';
import { Button } from './Button';

interface FormSubmitParams {
  submitText: string;
  disabled?: boolean;
  onSubmit?: () => void;
  isLoading: boolean;
  showCancel?: boolean;
  onCancel?: () => void;
  onDelete?: () => void;
}

const FormSubmit = ({
  submitText,
  onSubmit,
  showCancel = true,
  isLoading,
  disabled,
  onCancel,
  onDelete,
}: FormSubmitParams) => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-end gap-x-2 col-span-2">
      {onDelete ? (
        <Button
          isLoading={isLoading}
          isDisabled={isLoading}
          style={{ backgroundColor: '#b3063c' }}
          onClick={() => onDelete()}
          text={'Delete'}
        />
      ) : null}
      {showCancel ? (
        <Button
          isLoading={isLoading}
          isDisabled={isLoading}
          style={{ backgroundColor: 'black' }}
          onClick={onCancel ? onCancel : () => navigate(-1)}
          text={'Cancel'}
        />
      ) : null}

      <Button
        isLoading={isLoading}
        isDisabled={isLoading || disabled}
        type="submit"
        onClick={onSubmit}
        text={submitText}
      />
    </div>
  );
};

export default FormSubmit;
