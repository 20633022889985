import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../components/Buttons/Button';
import Switch from '../../../components/Inputs/Switch';
import { SAVE_TEAM_ASSIGNMENT } from '../../../graphql/mutations/assignments';
import { GET_PROJECT_TEAMS } from '../../../graphql/queries/projects';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';
import { DeletionDialogue } from '../../../components/Deletion/DeletionDialogue';
import { DELETE_PROJECT_TEAM } from '../../../graphql/mutations/projects';
import { useToast } from '../../../context/ToastContext';

export const TeamPage = () => {
  let { projectId, teamId } = useParams();
  const navigate = useNavigate();
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const [fetch, { data, loading, error }] = useLazyQuery(GET_PROJECT_TEAMS, {
    variables: { input: { id: teamId }, projectId },
    fetchPolicy: 'network-only',
  });

  const [showDeleteTeamModal, setShowDeleteTeamModal] = useState(false);

  const [team] = data?.getProjectTeams?.results || [];

  const [saveAssignment] = useMutation(SAVE_TEAM_ASSIGNMENT);

  const [deleteProjectTeam] = useMutation(DELETE_PROJECT_TEAM);

  const [roleSections, setRoleSections] = useState([]);

  const { addToast } = useToast();

  const fetchAndSetRoleSections = async () => {
    const { data: fetchedData } = await fetch({
      variables: { input: { id: teamId }, projectId },
    });
    const [team] = fetchedData?.getProjectTeams?.results || [];
    setRoleSections(
      team?.ptar.map((ptarItem: any) => ({
        id: ptarItem.id,
        enabled: ptarItem?.enabled,
        title: `${ptarItem.assignment_role.assignment.lrs.firstName} ${ptarItem.assignment_role.assignment.lrs.lastName}`,
        content: ptarItem.assignment_role.role.name,
      }))
    );
  };

  const handleDelete = async () => {
    try {
      const result = await deleteProjectTeam({
        variables: { id: teamId },
      });
      navigate(`/projects/${projectId}`);
      if (!result.data.deleteProjectTeam.success) {
        addToast('Error deleting team', 'error');
      } else {
        addToast('Team deleted', 'success');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAndSetRoleSections();
  }, [teamId]);

  const Tabs = ['Team Members'];

  const [activeTab, setActiveTab] = useState(Tabs[0]);

  const { userProfile } = useContext(UserProfileContext);

  return (
    <div className="bg-gray-50">
      <DeletionDialogue
        deleteTitle={'Delete Team'}
        showDeleteModal={showDeleteTeamModal}
        setShowDeleteModal={setShowDeleteTeamModal}
        onDelete={async () => {
          await handleDelete();
          setShowDeleteTeamModal(false);
        }}
        deletionConfirmationText="Are you sure you want to delete this team? You will not be able to restore it once deleted."
      />
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="py-2 border-b">
          {projectId ? (
            <Button
              style={{
                backgroundColor: 'transparent',
                color: 'gray',
                boxShadow: 'none',
                borderRadius: 0,
              }}
              onClick={() => navigate(`/projects/${projectId}`)}
              type="submit"
              text={`< Back to Project`}
            />
          ) : null}
        </div>
        <div className="flex flex-col md:items-center md:flex-row w-full md:justify-between px-4 py-5 sm:px-6 flex-wrap">
          <div className="flex flex-col">
            <div className="flex gap-x-2">
              <h1 className="text-3xl font-extrabold text-gray-900">
                {team?.name}
              </h1>
            </div>
            <p className="mt-1 text-sm text-gray-500">
              Created: {moment.unix(team?.createdAt / 1000).fromNow()}
            </p>
            <p className="mt-1 text-sm text-gray-500">
              Updated: {moment.unix(team?.updatedAt / 1000).fromNow()}
            </p>
          </div>
          <div className="flex gap-x-2">
            <Button
              onClick={() => setShowDeleteTeamModal(!showDeleteTeamModal)}
              isDisabled={
                !(
                  userProfile?.permissions?.includes(
                    Permission.UpdateProject
                  ) ||
                  userProfile?.permissions?.includes(Permission.UpdateTeams)
                )
              }
              className="bg-red-500 hover:bg-red-600"
              type="submit"
              text="Delete"
            />
            <Button
              onClick={() =>
                navigate(`/projects/${projectId}/teams/${team.id}/edit`)
              }
              isDisabled={
                !(
                  userProfile?.permissions?.includes(
                    Permission.UpdateProject
                  ) ||
                  userProfile?.permissions?.includes(Permission.UpdateTeams)
                )
              }
              type="submit"
              text="Edit"
            />
            {activeTab === 'Team Members' ? (
              <Button
                onClick={() =>
                  navigate(`/projects/${projectId}/teams/${team.id}/assign`)
                }
                isDisabled={
                  !(
                    userProfile?.permissions?.includes(
                      Permission.UpdateProject
                    ) ||
                    userProfile?.permissions?.includes(Permission.UpdateTeams)
                  )
                }
                type="submit"
                text="Assign new team member"
              />
            ) : null}
          </div>
        </div>
        <div className="w-full px-2 bg-gray-800 py-2 flex flex-row">
          {Tabs.map((tab) => (
            <div
              className={`${
                activeTab === tab ? 'bg-black' : ''
              } justify-center flex items-center`}
            >
              <Button
                style={{ borderRadius: 0, backgroundColor: 'transparent' }}
                text={tab}
                onClick={() => setActiveTab(tab)}
              />
            </div>
          ))}
        </div>
        {roleSections?.map((section: any) => (
          <div key={section?.id} className="px-4 py-1 sm:px-6">
            <hr className="my-2" />
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {section.title}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {section.content}
            </p>
            <hr className="my-2" />
            <Switch
              text={section?.enabled ? 'Active' : 'Inactive'}
              isDisabled={
                !(
                  userProfile?.permissions?.includes(
                    Permission.UpdateProject
                  ) ||
                  userProfile?.permissions?.includes(Permission.UpdateTeams)
                )
              }
              handleChange={async () => {
                await saveAssignment({
                  variables: {
                    id: section?.id,
                    input: { enabled: !section?.enabled },
                  },
                });
                await fetchAndSetRoleSections();
              }}
              enabled={section?.enabled}
            />
            <hr className="my-2" />
          </div>
        ))}
        {loading && (
          <div className="h-64 flex items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
          </div>
        )}
        {error && (
          <p className="px-4 py-5 sm:p-6 text-red-500">
            Error loading data. Please try again.
          </p>
        )}
      </div>
    </div>
  );
};

export const Team = () => <TeamPage />;
