import moment from 'moment';
import Avatar from 'react-avatar';
import { useLabourResource } from '../../../hooks/api/useLabourResources';
import MissingCertifications from '../../../components/MissingCertifications';

interface LabourHeadingProps {
  id?: string;
  labourType?: 'subcontractors' | 'employees';
  showMissingCertifications?: boolean;
  showCreatedUpdateDate?: boolean;
}

export const LabourHeading = ({
  id,
  labourType,
  showCreatedUpdateDate = true,
  showMissingCertifications,
}: LabourHeadingProps) => {
  const [labour] = useLabourResource({ id, labourType });

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="flex flex-col md:items-center md:flex-row w-full md:justify-between px-4 py-5 sm:px-6 flex-wrap">
        <div className="flex flex-col">
          <div className="flex gap-x-2">
            <Avatar
              name={`${labour?.firstName} ${labour?.lastName}`}
              size="35"
              round
            />
            <h1 className="text-2xl font-extrabold text-gray-900">
              {labour?.firstName} {labour?.lastName}
            </h1>
          </div>
          {showCreatedUpdateDate ? (
            <p className="mt-1 text-sm text-gray-500">
              Created: {moment.unix(labour?.createdAt / 1000).fromNow()}
            </p>
          ) : null}
          {showCreatedUpdateDate ? (
            <p className="mt-1 text-sm text-gray-500">
              Updated: {moment.unix(labour?.updatedAt / 1000).fromNow()}
            </p>
          ) : null}
          {showMissingCertifications ? (
            <MissingCertifications
              className="mt-4"
              missingOrInvalidCertifications={
                labour?.missingOrInvalidCertifications
              }
            >
              {labour?.firstName} has missing, incomplete, expired or soon to be
              expiring certifications required for their role, please add /
              update these certifications:
            </MissingCertifications>
          ) : null}
        </div>
      </div>
    </div>
  );
};
