import { gql } from '@apollo/client';

export const GET_CUSTOM_OPTIONS_SUMMARY = gql`
  query getCustomOptionSets(
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    getCustomOptionSets(
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        name
        enabled
        updatedAt
      }
    }
  }
`;

export const GET_CUSTOM_OPTIONS = gql`
  query getCustomOptionSets(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $assignedToProject: Boolean
    $assignedToAssignment: Boolean
    $assignedToAssignmentRole: Boolean
  ) {
    getCustomOptionSets(
      input: $input
      activeOrganisation: $activeOrganisation
      assignedToProject: $assignedToProject
      assignedToAssignment: $assignedToAssignment
      assignedToAssignmentRole: $assignedToAssignmentRole
    ) {
      count
      results {
        id
        name
        assignedToProject
        assignedToAssignment
        assignedToAssignmentRole
        custom_options {
          id
          name
          value
        }
        enabled
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_CUSTOM_FIELDS_SUMMARY = gql`
  query getCustomFieldGroups(
    $input: GenericFindOptions
    $isFetchingForCreatingUser: Boolean
    $activeOrganisation: String!
  ) {
    getCustomFieldGroups(
      input: $input
      isFetchingForCreatingUser: $isFetchingForCreatingUser
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        name
        enabled
        updatedAt
      }
    }
  }
`;

export const GET_CUSTOM_FIELDS = gql`
  query getCustomFieldGroups(
    $input: GenericFindOptions
    $labourResourceId: ID
    $isFetchingForCreatingUser: Boolean
    $onlyEnabled: Boolean
    $activeOrganisation: String!
  ) {
    getCustomFieldGroups(
      input: $input
      labourResourceId: $labourResourceId
      isFetchingForCreatingUser: $isFetchingForCreatingUser
      onlyEnabled: $onlyEnabled
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        name
        custom_fields {
          id
          name
          type
          description
        }
        groupsAssigned
        private
        hiddenOnCreate
        enabled
        createdAt
        updatedAt
      }
    }
  }
`;
