import { gql } from '@apollo/client';

export const GET_PROJECT_ASSIGNMENTS = gql`
  query getAssignments(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $projectId: ID
    $assigneeId: ID
    $companyId: ID
  ) {
    getAssignments(
      input: $input
      activeOrganisation: $activeOrganisation
      projectId: $projectId
      assigneeId: $assigneeId
      companyId: $companyId
    ) {
      count
      results {
        id
        enabled
        hasArchivedRoles
        project {
          id
          name
          internalId
          startDate
          endDate
        }
        lrs {
          id
          email
          firstName
          lastName
          type
          company {
            companyName
          }
          userProfile {
            cps {
              companyName
            }
          }
        }
        customData {
          key
          value
        }
        missingOrInvalidCertifications {
          id
          name
          notes
        }
        assignmentRoleSummary {
          activeRoles {
            id
            name
          }
          inactiveRoles {
            id
            name
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PROJECT_ASSIGNMENT_ROLES = gql`
  query getAssignmentRoles(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $assignmentId: ID
    $projectId: ID
    $enabledOnly: Boolean
  ) {
    getAssignmentRoles(
      input: $input
      activeOrganisation: $activeOrganisation
      assignmentId: $assignmentId
      projectId: $projectId
      enabledOnly: $enabledOnly
    ) {
      count
      results {
        id
        enabled
        archivedAt
        hasRelatedEntities
        startDate
        endDate
        description
        missingOrInvalidCertifications {
          id
          name
          notes
          allowsExemption
        }
        certificationExemptions {
          id
          certType {
            id
            name
          }
          comment
          createdBy
          expiryDate
        }
        role {
          id
          name
          defaultRates {
            id
            currency
            key
            name
            rate
          }
        }
        assignment {
          lrs {
            id
            email
            firstName
            lastName
            type
            internalId
            jobTitle
            defaultRole {
              id
              name
            }
            personalProfile
            projectExperience
          }
          project {
            id
            name
            internalId
          }
        }
        customData {
          key
          value
        }
        rates {
          id
          currency
          key
          name
          rate
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PROJECT_ASSIGNMENT_ROLES_SUMMARY = gql`
  query getAssignmentRoles(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $assignmentId: ID
    $archivedOnly: Boolean
  ) {
    getAssignmentRoles(
      input: $input
      activeOrganisation: $activeOrganisation
      assignmentId: $assignmentId
      archivedOnly: $archivedOnly
    ) {
      count
      results {
        id
        enabled
        archivedAt
        role {
          id
          name
        }
        assignment {
          lrs {
            id
            firstName
            lastName
            type
          }
          project {
            id
            name
            internalId
            startDate
            endDate
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const SEARCH_PROJECT_ASSIGNMENT_ROLES = gql`
  query searchAssignmentRoles(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $searchTerm: String!
    $assignmentStatus: String
  ) {
    searchAssignmentRoles(
      input: $input
      activeOrganisation: $activeOrganisation
      searchTerm: $searchTerm
      assignmentStatus: $assignmentStatus
    ) {
      count
      results {
        assignment {
          id
          project {
            id
            name
            internalId
            startDate
            endDate
            enabled
          }
          lrs {
            id
            firstName
            lastName
            email
            type
          }
          enabled
          status
          createdAt
          updatedAt
        }
        createdAt
        enabled
        id
        role {
          id
          name
          enabled
          createdAt
          updatedAt
        }
        updatedAt
      }
    }
  }
`;
