import { gql } from '@apollo/client';

export const SAVE_TIMESHEET_CONFIGURATION = gql`
  mutation saveTimesheetConfiguration(
    $id: ID
    $input: SaveTimesheetConfigurationInput!
    $activeOrganisation: String!
  ) {
    saveTimesheetConfiguration(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;
