import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';
import { GET_EMPLOYEES_SUMMARY } from '../../../graphql/queries/employees';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import moment from 'moment';
import { Button } from '../../../components/Buttons/Button';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';
import Avatar from 'react-avatar';
import { BlueBadge, YellowBadge } from '../../../components/Badges/Badges';
import NoData from '../../../components/NoData/NoData';
import LinkButton from '../../../components/Buttons/LinkButton';
import { Modal } from '../../../components/Dialogue/ModalDialogue';
import { SettingsDialog } from '../SettingsDialog';

interface EmployeeListProps {
  showNewItemButton?: boolean;
  children?: React.ReactElement;
  filterIds?: string[];
  onClickRow?: (id: string, results: any[]) => void;
}

export enum InternalIdFilter {
  CONFIGURED = 'Configured',
  NOT_CONFIGURED = 'Not Configured',
  SHOW_ALL = 'Show All',
}

const headerMapping = {
  name: 'Name',
  email: 'Email',
  internalId: 'Internal Id',
  updatedAt: 'Updated At',
} as Record<string, any>;

const EmployeesTable = ({
  data = [{}],
  NoData,
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
  filterText,
  setFilterText = () => {},
  selectedFilterDimension,
  fetchPageOfDataForExport,
  setSelectedFilterDimension = () => {},
  showSettingsButton = false,
  onSettingsClick = () => {},
}: any) => (
  <Table
    currentPage={currentPage}
    NoData={NoData}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    columnsToFilter={['firstName', 'lastName', 'id', 'status']}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    fetchPageOfDataForExport={fetchPageOfDataForExport}
    headerMapper={(text: string) => headerMapping[text]}
    filterDimensions={[
      { id: 'firstName', name: 'First Name' },
      { id: 'lastName', name: 'Last Name' },
      { id: 'email', name: 'Email' },
    ]}
    showFilterOptions
    showSettingsButton={showSettingsButton}
    onSettingsClick={onSettingsClick}
    filterText={filterText}
    setFilterText={setFilterText}
    selectedFilterDimension={selectedFilterDimension}
    setSelectedFilterDimension={setSelectedFilterDimension}
    onPageChange={onPageChange}
    data={data}
  />
);

export const EmployeeListPage = ({
  children,
  showNewItemButton,
  onClickRow,
  filterIds = [],
}: EmployeeListProps) => {
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [pendingInternalIdFilter, setPendingInternalIdFilter] =
    useState<InternalIdFilter>(InternalIdFilter.SHOW_ALL);

  const [internalIdFilter, setinternalIdFilter] = useState<InternalIdFilter>(
    InternalIdFilter.SHOW_ALL
  );

  const [showSettingsModal, setShowSettingsModal] = useState(false);

  const [filterText, setFilterText] = useState<string>();
  const [selectedFilterDimension, setSelectedFilterDimension] =
    useState<string>('lastName');

  const { limit, offset, setOffset } = usePagination();

  const [debouncedFilterText, setDebouncedSearchTerm] = useState(filterText);

  const filter = debouncedFilterText
    ? {
        filters: [
          {
            field: selectedFilterDimension,
            stringFilters: [{ ilike: debouncedFilterText }],
          },
        ],
      }
    : null;

  const [fetch, { data, loading }] = useLazyQuery(GET_EMPLOYEES_SUMMARY, {
    variables: { input: { limit, offset } },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetch({
      variables: {
        input: { limit, offset, ...(filter ? { filter } : {}) },
        internalIdFilter,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, debouncedFilterText, internalIdFilter]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setOffset(0);
      setDebouncedSearchTerm(filterText);
    }, 600);
    return () => {
      clearTimeout(handler);
    };
  }, [filterText]);

  const employeeResults = data?.getEmployees;

  const totalPages = Math.ceil(employeeResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const { userProfile } = useContext(UserProfileContext);

  return (
    <div>
      <>
        <Modal
          title="Advanced Settings"
          showModal={showSettingsModal}
          setShowModal={setShowSettingsModal}
          completeButtonText={'Apply'}
          onComplete={() => {
            setinternalIdFilter(pendingInternalIdFilter);
            setShowSettingsModal(false);
          }}
          component={
            <div className="flex flex-col">
              <SettingsDialog
                title="Internal Ids"
                setPendingValue={setPendingInternalIdFilter}
                pendingValue={pendingInternalIdFilter}
              />
            </div>
          }
        />
        {showNewItemButton ? (
          <div className="w-full flex justify-between items-center py-2">
            <h1 className="text-xl font-bold py-4">Employees</h1>
            {userProfile?.permissions.includes(
              Permission.UpdateLabourProfiles
            ) ? (
              <Button
                text="New Employee"
                onClick={() => navigate(`/labour/employees/new`)}
              />
            ) : null}
          </div>
        ) : null}

        <EmployeesTable
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          totalResults={employeeResults?.count || 0}
          onClickRow={(id: any) => {
            if (onClickRow) {
              onClickRow(id, employeeResults?.results);
            } else {
              navigate(`/labour/employees/${id}`);
            }
          }}
          NoData={() => (
            <NoData loading={loading}>
              It looks like there are no employees in this organisation!
              {userProfile?.permissions.includes(
                Permission.UpdateLabourProfiles
              ) ? (
                <LinkButton to="/labour/employees/new" target="_blank">
                  Add an Employee
                </LinkButton>
              ) : null}
            </NoData>
          )}
          filterText={filterText}
          setFilterText={setFilterText}
          selectedFilterDimension={selectedFilterDimension}
          setSelectedFilterDimension={setSelectedFilterDimension}
          data={employeeResults?.results
            .filter((result: any) => !filterIds?.includes(result.id))
            .map((result: any) => ({
              name: (
                <div className="flex gap-x-2 items-center">
                  <Avatar
                    name={`${result?.firstName} ${result?.lastName}`}
                    size="30"
                    round
                  />
                  {`${result.lastName}, ${result.firstName}`}
                </div>
              ),
              ...result,
              email: result?.email || 'Not Configured',
              internalId: result?.internalId ? (
                <BlueBadge text={result?.internalId} />
              ) : (
                <YellowBadge text={'Not Configured'} />
              ),
              status: undefined,
            }))}
          fetchPageOfDataForExport={async (
            limit: number,
            pageOffset: number
          ) => {
            const { data } = await fetch({
              variables: {
                input: {
                  limit,
                  offset: pageOffset,
                  ...(filter ? { filter } : {}),
                },
              },
            });
            setOffset(pageOffset);
            return data?.getEmployees?.results ?? [];
          }}
          onPageChange={handlePageChange}
          showSettingsButton
          onSettingsClick={() => setShowSettingsModal(true)}
        />
      </>
      {children}
    </div>
  );
};

export const EmployeeList = ({
  onClickRow,
  showNewItemButton = true,
  filterIds = [],
}: EmployeeListProps) => (
  <PaginationProvider>
    <EmployeeListPage
      onClickRow={onClickRow}
      showNewItemButton={showNewItemButton}
      filterIds={filterIds}
    />
  </PaginationProvider>
);
