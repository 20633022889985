import { useEffect } from 'react';
import { GET_ASSIGNMENTS_WITH_MISSING_OR_INCOMPLETE_ASSESSMENTS } from '../../../graphql/queries/assessments';
import { useOrganisationAwareApollo } from '../../useOrganisationAwareApollo';
import { ApiHookMeta } from '../types';

export enum IncompleteAssessmentFilter {
  NOT_STARTED = 'NOT_STARTED',
  AWAITING_OUTCOME = 'AWAITING_OUTCOME',
  MISSING = 'MISSING',
}

export default function useAssignmentsWithMissingOrIncompleteAssessments({
  assessmentTypeId,
  projectId,
  filter,
  limit,
  offset,
  sortByColumn,
  sortDirection,
}: {
  assessmentTypeId?: string;
  projectId?: string;
  filter?: IncompleteAssessmentFilter;
  offset?: number;
  limit?: number;
  sortByColumn?: string;
  sortDirection?: string;
}): [any, ApiHookMeta] {
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [fetch, { data, loading, error, ...meta }] = useLazyQuery(
    GET_ASSIGNMENTS_WITH_MISSING_OR_INCOMPLETE_ASSESSMENTS,
    {
      variables: { assessmentTypeId },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    fetch({
      variables: {
        assessmentTypeId,
        filter,
        projectId,
        input: {
          limit,
          offset,
          ...(sortByColumn && sortDirection
            ? { order: [[sortByColumn, sortDirection]] }
            : {}),
        },
      },
    });
  }, [
    assessmentTypeId,
    fetch,
    filter,
    limit,
    offset,
    projectId,
    sortByColumn,
    sortDirection,
  ]);

  const results =
    data?.getAssignmentsWithMissingOrIncompleteAssessments?.results || [];
  const count =
    data?.getAssignmentsWithMissingOrIncompleteAssessments?.count || 0;

  return [
    results,
    {
      count,
      loading,
      error,
      ...meta,
    },
  ];
}
