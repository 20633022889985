import { ClockIcon } from '@heroicons/react/24/outline';
import {
  BlueBadge,
  GreenBadge,
  OrangeBadge,
  RedBadge,
  YellowBadge,
} from '../../../../components/Badges/Badges';

export const ConditionInfo = ({ condition }: any) => {
  const conditionIdPart = condition?.id?.split('-')?.slice(-1)[0];

  return (
    <div className="not-italic flex flex-col gap-y-2 lg:flex-row items-center justify-between w-full text-gray-500 text-base">
      <div className="flex flex-col gap-y-2 lg:flex-row items-center gap-x-2">
        <ClockIcon className="w-4 h-4" />
        {condition?.isNew && (
          <>
            <span> </span>
            <YellowBadge text="Unsaved" />
          </>
        )}
        {condition?.hasChanged && (
          <>
            <span> </span>
            <OrangeBadge text="Modified" />
          </>
        )}
        {condition?.toBeDeleted && (
          <>
            <span> </span>
            <RedBadge text="Marked for Deletion" />
          </>
        )}
        {condition?.savedLocally && (
          <>
            <BlueBadge text="Ready to Sync" />
          </>
        )}
        {!condition?.toBeDeleted &&
          !condition?.hasChanged &&
          !condition?.isNew && (
            <>
              <span> </span>
              <GreenBadge text="Saved" />
            </>
          )}
        <i className="not-italic text-gray-500 text-sm">
          <span> </span>#{conditionIdPart}
        </i>

        <i className="not-italic flex flex-col md:flex-row gap-x-1 items-center text-black text-sm lg:text-base">
          {condition.conditionReport?.length > 0 ? (
            <>
              <span> </span>
              <GreenBadge text="Conditions Recorded" />
            </>
          ) : (
            <>
              <span> </span>
              <YellowBadge text="No Conditions Recorded" />
            </>
          )}
          {condition.plc?.id ? (
            ` @ ${condition?.plc?.name}`
          ) : (
            <>
              <span> </span>
              <YellowBadge text="No Location" />
            </>
          )}
        </i>
      </div>
      <i className="not-italic text-gray-900 text-lg">
        {condition.time ? `${condition.time}` : <YellowBadge text="No Time" />}
      </i>
    </div>
  );
};
