import { gql } from '@apollo/client';

export const SEARCH_PROGRESS_ACTIVITY_CATEGORIES = gql`
  query searchProgressActivityCategories(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $searchTerm: String!
  ) {
    searchProgressActivityCategories(
      input: $input
      activeOrganisation: $activeOrganisation
      searchTerm: $searchTerm
    ) {
      count
      results {
        id
        name
      }
    }
  }
`;

export const GET_PROGRESS_ACTIVITY_CATEGORIES = gql`
  query getProgressActivityCategories(
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    getProgressActivityCategories(
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PROGRESS_INCIDENT_TYPES = gql`
  query getProgressIncidentTypes(
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    getProgressIncidentTypes(
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PROGRESS_CONDITION_TYPES = gql`
  query getProgressConditionTypes(
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    getProgressConditionTypes(
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        name
        options
        dataType
        unit
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PROGRESS_ACTIVITY_SUBMISSIONS_SUMMARY = gql`
  query getProgressSubmissions(
    $input: GenericFindOptions
    $date: String
    $projectId: String
    $startDate: String
    $endDate: String
    $outcomeId: String
    $activeOrganisation: String!
  ) {
    getProgressSubmissions(
      input: $input
      date: $date
      projectId: $projectId
      startDate: $startDate
      endDate: $endDate
      outcomeId: $outcomeId
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        date
        locked
        approved
        updatedAt
        createdAt
      }
    }
  }
`;

export const GET_PROGRESS_ACTIVITY_SUBMISSIONS = gql`
  query getProgressSubmissions(
    $input: GenericFindOptions
    $date: String
    $projectId: String
    $startDate: String
    $endDate: String
    $outcomeId: String
    $activeOrganisation: String!
  ) {
    getProgressSubmissions(
      input: $input
      date: $date
      projectId: $projectId
      startDate: $startDate
      endDate: $endDate
      outcomeId: $outcomeId
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        date
        updatedAt
        createdAt
        locked
        approved
        status {
          groupId
          groupLabel
          rows {
            rowId
            rowLabel
            rowCategory
            progressByColumn {
              id
              originId
              name
              progress
              isNA
              isLocked
            }
          }
          manualProgress {
            originId
            columnId
            phaseId
            progress
            isNA
            isLocked
          }
        }
        comments {
          id
          value
        }
        ppe {
          id
          comment
          name
          peoplePerDay
          hoursInWorkingDay
          projectTeamId
          totalPersonHours
        }
        pce {
          id
          time
          comment
          conditionReport {
            id
            name
            value
          }
          plc {
            id
            name
          }
        }
        pie {
          id
          value
          toDateValue
          comment
          pit {
            id
            name
          }
        }
        pae {
          id
          start
          finish
          endsAtMidnight
          comment
          projectTeamId
          assignmentRolesList {
            id
            name
            enabled
          }
          pat {
            id
            name
          }
          pl {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_PROGRESS_ACTIVITY_SUBMISSIONS_NO_STATUS = gql`
  query getProgressSubmissions(
    $input: GenericFindOptions
    $date: String
    $projectId: String
    $startDate: String
    $endDate: String
    $outcomeId: String
    $activeOrganisation: String!
  ) {
    getProgressSubmissions(
      input: $input
      date: $date
      projectId: $projectId
      startDate: $startDate
      endDate: $endDate
      outcomeId: $outcomeId
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        date
        updatedAt
        createdAt
        locked
        approved
        comments {
          id
          value
        }
        ppe {
          id
          comment
          name
          peoplePerDay
          hoursInWorkingDay
          projectTeamId
          totalPersonHours
        }
        pce {
          id
          time
          comment
          conditionReport {
            id
            name
            value
          }
          plc {
            id
            name
          }
        }
        pie {
          id
          value
          toDateValue
          comment
          pit {
            id
            name
          }
        }
        pae {
          id
          start
          finish
          endsAtMidnight
          comment
          projectTeamId
          assignmentRolesList {
            id
            name
            enabled
          }
          pat {
            id
            name
          }
          pl {
            id
            name
          }
        }
      }
    }
  }
`;

export const SEARCH_PROGRESS_ACTIVITY_TYPES = gql`
  query searchProgressActivityTypes(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $projectId: String
    $searchTerm: String!
  ) {
    searchProgressActivityTypes(
      input: $input
      activeOrganisation: $activeOrganisation
      searchTerm: $searchTerm
      projectId: $projectId
    ) {
      count
      results {
        id
        name
        pag {
          id
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const SEARCH_PROGRESS_CONDITION_TYPES = gql`
  query searchProgressConditionTypes(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $projectId: String
    $searchTerm: String!
  ) {
    searchProgressConditionTypes(
      input: $input
      activeOrganisation: $activeOrganisation
      searchTerm: $searchTerm
      projectId: $projectId
    ) {
      count
      results {
        id
        name
        unit
        dataType
        options
        createdAt
        updatedAt
      }
    }
  }
`;

export const SEARCH_PROGRESS_INCIDENT_TYPES = gql`
  query searchProgressIncidentTypes(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $projectId: String
    $searchTerm: String!
  ) {
    searchProgressIncidentTypes(
      input: $input
      activeOrganisation: $activeOrganisation
      searchTerm: $searchTerm
      projectId: $projectId
    ) {
      count
      results {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PROGRESS_SUBMISSION_ATTACHMENTS = gql`
  query getProgressSubmissionAttachments(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $progressSubmissionId: ID!
  ) {
    getProgressSubmissionAttachments(
      input: $input
      activeOrganisation: $activeOrganisation
      progressSubmissionId: $progressSubmissionId
    ) {
      count
      results {
        id
        fileName
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PROGRESS_SIGNING_SUBMISSIONS = gql`
  query getProgressSigningSubmissions(
    $projectId: String
    $outcomeId: String
    $date: String
    $exportId: String
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    getProgressSigningSubmissions(
      input: $input
      exportId: $exportId
      date: $date
      outcomeId: $outcomeId
      projectId: $projectId
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        status
        completedDocumentURLs {
          name
          url
        }
        signers {
          name
          title
          email
        }
        createdAt
        updatedAt
      }
    }
  }
`;
