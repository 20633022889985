import ComboBox from '../../../../components/Inputs/ComboBox';

export const ActivitySelection = ({
  id,
  activitySearchResults = [],
  setSearchTerm,
  setSelectedActivity,
  selectedActivity,
  isDisabled,
}: any) => {
  return (
    <div className="w-full">
      <ComboBox
        testId="activity-selection"
        placeholder="Search by activity name"
        isDisabled={isDisabled}
        question={{
          id,
          options: activitySearchResults?.map((activity: any) => ({
            id: activity.id,
            name: activity.name,
            detail: activity.detail,
          })),
          onChange: (value) => setSearchTerm(value),
        }}
        handleChange={(id) => {
          setSelectedActivity(
            activitySearchResults?.find((activity: any) => activity.id === id)
          );
        }}
        value={selectedActivity?.name ?? ''}
        validation={undefined}
      />
    </div>
  );
};
