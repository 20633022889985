import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { GET_MY_COMPANY_DETAILS } from '../../../graphql/queries/my-profile';
import { GET_ASSESSMENT_SUBMISSIONS_FOR_COMPANY } from '../../../graphql/queries/assessments';
import NoData from '../../../components/NoData/NoData';
import moment from 'moment';
import { RedBadge, YellowBadge } from '../../../components/Badges/Badges';
import { Table } from '../../../components/Tables/tables/Table';
import { useNavigate } from 'react-router-dom';

const headerMapping = {
  name: 'Name',
  project: 'Project',
  outcome: 'Outcome',
  createdAt: 'Created At',
  updatedAt: 'Updated At',
} as Record<string, any>;

function CompanyAssessmentsTable({ companyId }: { companyId: string }) {
  const navigate = useNavigate();
  const { useQuery } = useOrganisationAwareApollo();
  const { limit, offset, setOffset } = usePagination();

  const { data, loading } = useQuery(GET_ASSESSMENT_SUBMISSIONS_FOR_COMPANY, {
    variables: {
      input: { limit, offset },
      companyId,
    },
    fetchPolicy: 'network-only',
  });

  const count = data?.getAssessmentSubmissionsForCompany?.count;
  const results = data?.getAssessmentSubmissionsForCompany?.results;

  const totalPages = Math.ceil(count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  return (
    <div>
      <h2 className="text-lg font-bold px-4 sm:px-6 py-4">Assessments</h2>
      <Table
        currentPage={Math.floor(offset / limit) + 1}
        totalPages={totalPages}
        itemsPerPage={limit}
        totalResults={count || 0}
        headerMapper={(text: string) => headerMapping[text]}
        onClickRow={(id: any) => {
          navigate(`/profile/assessments/${id}`);
        }}
        data={results?.map((result: any) => ({
          id: result?.id,
          name: result?.assessmentType?.name,
          project: result?.project?.name,
          outcome:
            !result.responses && !result?.outcome ? (
              <RedBadge text="Not Started Yet" />
            ) : !result?.outcome ? (
              <YellowBadge text="No Outcome Decided Yet" />
            ) : (
              <strong>{result?.outcome}</strong>
            ),
          createdAt:
            result?.createdAt &&
            moment.unix(parseInt(result?.createdAt) / 1000).fromNow(),
          updatedAt:
            result?.updatedAt &&
            moment.unix(parseInt(result?.updatedAt) / 1000).fromNow(),
        }))}
        NoData={() => (
          <NoData loading={loading}>
            It looks like there are no assessments associated with this company.
          </NoData>
        )}
        onPageChange={handlePageChange}
      />
    </div>
  );
}

export function CompanyAssessmentsPage() {
  const { useQuery } = useOrganisationAwareApollo();

  const { data, loading, error } = useQuery(GET_MY_COMPANY_DETAILS, {
    variables: {},
    fetchPolicy: 'network-only',
  });

  const [companyProfile] = data?.getMyCompanyDetails?.results || [];

  return (
    <div className="bg-gray-50">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="flex flex-col w-full border-b px-4 py-5 sm:px-6">
          <h1 className="text-3xl font-extrabold text-gray-900">
            {companyProfile?.companyName}
          </h1>
          <p className="mt-1 text-sm text-gray-500">
            Updated: {moment.unix(companyProfile?.updatedAt / 1000).fromNow()}
          </p>
        </div>
        {loading && (
          <div className="h-64 flex items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
          </div>
        )}
        {error && (
          <p className="px-4 py-5 sm:p-6 text-red-500">
            Error loading data. Please try again.
          </p>
        )}

        {companyProfile ? (
          <PaginationProvider>
            <CompanyAssessmentsTable companyId={companyProfile.id} />
          </PaginationProvider>
        ) : null}
      </div>
    </div>
  );
}
