import { gql } from '@apollo/client';

export const SAVE_CERTIFICATION_TYPE = gql`
  mutation saveCertificationType(
    $id: ID
    $input: SaveCertificationTypeInput!
    $activeOrganisation: String!
  ) {
    saveCertificationType(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const SAVE_CERTIFICATION = gql`
  mutation saveCertification(
    $id: ID
    $input: SaveCertificationInput!
    $activeOrganisation: String!
  ) {
    saveCertification(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const SAVE_MY_CERTIFICATION = gql`
  mutation saveMyCertification(
    $id: ID
    $input: SaveMyCertificationInput!
    $activeOrganisation: String!
  ) {
    saveMyCertification(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const SAVE_PROJECT_CERTIFICATION = gql`
  mutation saveProjectCertification(
    $input: ProjectCertificationInput!
    $activeOrganisation: String!
  ) {
    saveProjectCertification(
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const DELETE_CERTIFICATION = gql`
  mutation deleteCertification($id: ID!, $activeOrganisation: String!) {
    deleteCertification(id: $id, activeOrganisation: $activeOrganisation) {
      success
      message
    }
  }
`;

export const DELETE_CERTIFICATION_TYPE = gql`
  mutation deleteCertificationType($activeOrganisation: String!, $id: ID) {
    deleteCertificationType(activeOrganisation: $activeOrganisation, id: $id) {
      id
      success
      message
    }
  }
`;
