import { gql } from '@apollo/client';

export const GENERATE_TIMESHEET_EXPORT = gql`
  mutation generateTimesheetExport(
    $input: GenerateTimesheetExportInput!
    $activeOrganisation: String!
  ) {
    generateTimesheetExport(
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      id
      success
      message
    }
  }
`;

export const GENERATE_PROGRESS_EXPORT = gql`
  mutation generateProgressExport(
    $input: GenerateProgressExportInput!
    $activeOrganisation: String!
  ) {
    generateProgressExport(
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      id
      success
      message
    }
  }
`;

export const GENERATE_PROGRESS_ACTIVITY_EXPORT = gql`
  mutation generateProgressActivityExport(
    $input: GenerateProgressActivityExportInput!
    $activeOrganisation: String!
  ) {
    generateProgressActivityExport(
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      id
      success
      message
    }
  }
`;

export const GENERATE_PROJECT_EXPORT = gql`
  mutation generateProjectExport(
    $input: GenerateProjectExportInput!
    $activeOrganisation: String!
  ) {
    generateProjectExport(
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      id
      success
      message
    }
  }
`;

export const GENERATE_CV_EXPORT = gql`
  mutation generateCvExport(
    $input: GenerateCvExportInput!
    $activeOrganisation: String!
  ) {
    generateCvExport(input: $input, activeOrganisation: $activeOrganisation) {
      id
      success
      message
    }
  }
`;
