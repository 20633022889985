import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { OrangeBadge } from '../../../../components/Badges/Badges';
import { Button } from '../../../../components/Buttons/Button';
import { Modal } from '../../../../components/Dialogue/ModalDialogue';
import TextArea from '../../../../components/Inputs/TextArea';
import { ActivityEntry } from '../activity/entry';
import { useLocation, useNavigate } from 'react-router-dom';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { unstable_batchedUpdates } from 'react-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const ActivitySubmission = ({
  selectedProject,
  activities,
  setActivities,
  locked,
  teams,
}: any) => {
  const query = useQuery();
  const navigate = useNavigate();

  useEffect(() => {
    const teamParam = query.get('team');
    if (teams?.length > 0) {
      let foundTeam;

      if (teamParam) {
        foundTeam = teams.find(
          (team: any) => team.id === teamParam || team.name === teamParam
        );
      }

      setSelectedTeam(foundTeam || teams[0]);

      if (!foundTeam) {
        navigate(`?team=${teams[0].id}`, { replace: true });
      }
    }
  }, [teams, query.get('team')]);

  const handleAddActivity = () => {
    const latestTeamActivities = filteredActivities?.filter(
      (item: any) => item.projectTeamId === selectedTeam?.id
    );
    const newActivity = {
      pat: null,
      pl: latestTeamActivities?.[latestTeamActivities?.length - 1]?.pl,
      isNew: true,
      start:
        latestTeamActivities && latestTeamActivities.length > 0
          ? latestTeamActivities?.[latestTeamActivities?.length - 1]?.finish
          : '00:00',
      finish: '',
      comment: '',
      projectTeamId: selectedTeam?.id,
      id: uuidv4(),
      assignmentRolesList: [],
      createdAt: moment().valueOf(),
    };

    setActivities(activities ? [...activities, newActivity] : [newActivity]);
  };

  const bottomRef = useRef(null);

  const [selectedTeam, setSelectedTeam] = useState(teams?.[0]);

  const filteredActivities = useMemo(() => {
    return activities
      ?.filter((activity: any) => activity.projectTeamId === selectedTeam?.id)
      .sort((a: any, b: any) => a.start - b.start);
  }, [activities, selectedTeam]);

  useEffect(() => {
    if (
      bottomRef.current &&
      filteredActivities?.find((activity: any) => !activity.pat?.id)
    ) {
      // @ts-ignore
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [filteredActivities?.length]);

  const handleRemoveActivity = useCallback((activityId: string) => {
    unstable_batchedUpdates(() => {
      setActivities((prevActivities: any) =>
        prevActivities
          .map((activity: any) => {
            if (activity.id === activityId) {
              if (activity.isNew) return null;
              return { ...activity, toBeDeleted: !activity.toBeDeleted };
            }
            return activity;
          })
          .filter(Boolean)
      );
    });
  }, []);

  const handleRevertActivityChange = (activityId: any) => {
    const updatedActivities = activities.map((activity: any) => {
      if (activity.id === activityId) {
        return {
          ...activity?.original,
        };
      }
      return activity;
    });
    setActivities(updatedActivities);
  };

  const handleActivityChange = useCallback(
    (activityId: string | undefined, field: string, value: any) => {
      unstable_batchedUpdates(() => {
        setActivities((prevActivities: any) =>
          prevActivities.map((activity: any) => {
            if (activity.id === activityId) {
              const updatedActivity = {
                ...activity,
                [field]: value,
                savedLocally: false,
              };

              if (!activity.isNew && !activity.toBeDeleted) {
                updatedActivity.hasChanged = true;
                updatedActivity.original = activity;
              }

              return updatedActivity;
            }
            return activity;
          })
        );
      });
    },
    []
  );

  const handleRoleCountChange = useCallback(
    (activityId: string, roleId: string, value: any) => {
      unstable_batchedUpdates(() => {
        setActivities((prevActivities: any) =>
          prevActivities.map((activity: any) => {
            if (activity.id === activityId) {
              const existingRole = activity.assignmentRolesList?.find(
                (aRole: any) => aRole.id === roleId
              );

              const updatedRoles = existingRole
                ? activity.assignmentRolesList.map((aRole: any) =>
                    aRole.id === roleId ? { ...aRole, enabled: value } : aRole
                  )
                : [
                    ...activity.assignmentRolesList,
                    {
                      id: roleId,
                      name: selectedTeam?.roles?.find(
                        (r: any) => r.id === roleId
                      )?.name,
                      enabled: value,
                    },
                  ];

              return {
                ...activity,
                savedLocally: false,
                ...(activity.isNew || activity.toBeDeleted
                  ? {}
                  : { hasChanged: true, original: activity }),
                assignmentRolesList: updatedRoles,
              };
            }
            return activity;
          })
        );
      });
    },
    [selectedTeam]
  );

  const [modalActivityId, setModalActivityId] = useState<string | undefined>(
    undefined
  );

  const [commentBoxState, setCommentBoxState] = useState('');

  useEffect(() => {
    if (modalActivityId) {
      setCommentBoxState(
        filteredActivities?.find(
          (activity: any) => activity?.id === modalActivityId
        )?.comment
      );
    }
  }, [modalActivityId]);

  return (
    <>
      <div>
        <Modal
          showModal={!!modalActivityId}
          setShowModal={setModalActivityId}
          completeButtonText={'Complete'}
          title={`Comment #${modalActivityId}`}
          onComplete={() => {
            if (!locked) {
              handleActivityChange(modalActivityId, 'comment', commentBoxState);
            }
            setModalActivityId(undefined);
          }}
          component={
            <TextArea
              rows={8}
              value={commentBoxState ?? ''}
              handleChange={(value) => setCommentBoxState(value)}
              validation={''}
              isDisabled={locked}
              question={{
                id: '',
                title: '',
                placeholder: '',
                name: '',
                description: '',
                defaultText: '',
                classNames: '',
              }}
            />
          }
        />
        <div className="w-full bg-white py-2 px-2 grid gap-x-2 gap-y-2 grid-cols-4 md:flex-wrap md:flex md:flex-row">
          {teams
            .sort((a: any, b: any) => {
              const nameA = a.name.toString().toLowerCase();
              const nameB = b.name.toString().toLowerCase();

              return nameA.localeCompare(nameB, undefined, {
                numeric: true,
                sensitivity: 'base',
              });
            })
            .map((tab: any) => (
              <div
                key={`${tab.name}_team`}
                className={`${
                  selectedTeam?.id === tab?.id ? 'bg-black' : 'bg-gray-600'
                } justify-center flex items-center rounded-lg`}
              >
                <Button
                  style={{ backgroundColor: 'transparent' }}
                  text={
                    <div className="text-xs">
                      {tab.name}{' '}
                      <i className="not-italic text-xs">
                        ({tab.activityCount})
                      </i>
                      {tab.hasChanges ? (
                        <>
                          <span className="ml-2"></span>
                          <OrangeBadge text="Modified" />
                        </>
                      ) : null}
                    </div>
                  }
                  onClick={() => {
                    setSelectedTeam(tab);
                    navigate(`?team=${tab.id}`, { replace: true });
                  }}
                />
              </div>
            ))}
        </div>

        <hr />

        {teams?.length > 0 ? (
          <div className="bg-white px-4 pt-2 flex flex-col gap-y-2">
            <h4 className="text-sm py-2">{selectedTeam?.name}'s Activities</h4>
            {filteredActivities?.length > 0 ? (
              filteredActivities?.map((activity: any) => (
                <ActivityEntry
                  key={activity.id}
                  projectId={selectedProject?.id}
                  activities={activities}
                  handleActivityChange={handleActivityChange}
                  handleRoleCountChange={handleRoleCountChange}
                  handleRemoveActivity={handleRemoveActivity}
                  handleRevertActivityChange={handleRevertActivityChange}
                  selectedTeam={selectedTeam}
                  activity={activity}
                  locked={locked}
                  setModalActivityId={setModalActivityId}
                />
              ))
            ) : (
              <p className="py-6">
                <ExclamationTriangleIcon className="w-10 h-10 text-orange-300" />
                No Activities Recorded
              </p>
            )}
          </div>
        ) : (
          <div className="bg-white px-4 pt-2">
            <p className="py-6">
              <ExclamationTriangleIcon className="w-10 h-10 text-orange-300" />
              No Teams Configured for this Project!
            </p>
          </div>
        )}
        <div ref={bottomRef} />
      </div>
      {teams.length > 0 && !locked ? (
        <div className="border-b w-full bg-white flex justify-end sticky bottom-0 py-4 px-4">
          <Button text={'Add Activity'} onClick={handleAddActivity}></Button>
        </div>
      ) : null}
    </>
  );
};
