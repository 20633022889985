import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import pattern from 'patternomaly';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Legend,
  Tooltip
);

const aggregateData = (data) => {
  const aggregation = {};

  data?.forEach((location) => {
    if (aggregation[location.locationId]) {
      aggregation[location.locationId].estimatedHours +=
        location.estimatedHours;
      aggregation[location.locationId].estimatedManHours +=
        location.estimatedPersonHours;
      aggregation[location.locationId].actualHours += location.actualHours;
      aggregation[location.locationId].actualManHours +=
        location.actualManHours;
      aggregation[location.locationId].completionHours +=
        location.completionHours;
      aggregation[location.locationId].completionManHours +=
        location.completionManHours;
      aggregation[location.locationId].count += 1;
    } else {
      aggregation[location.locationId] = {
        locationName: location.locationName,
        estimatedHours: location.estimatedHours,
        estimatedManHours: location.estimatedPersonHours,
        actualHours: location.actualHours,
        actualManHours: location.actualManHours,
        completionHours: location.completionHours,
        completionManHours: location.completionManHours,
        count: 1,
      };
    }
  });

  const aggregatedArray = Object.keys(aggregation).map((key) => ({
    locationId: key,
    ...aggregation[key],
  }));

  return aggregatedArray.sort((a, b) =>
    a.locationName.localeCompare(b.locationName)
  );
};

const HoursChart = ({ data }) => {
  const aggregatedData = aggregateData(data);

  const bluePattern = pattern.draw(
    'line-horizontal',
    'rgba(54, 162, 235, 0.8)'
  );
  const redPattern = pattern.draw('line-horizontal', 'rgba(255, 99, 132, 0.8)');
  const yellowPattern = pattern.draw(
    'line-horizontal',
    'rgba(255, 206, 86, 0.8)'
  );

  const chartData = {
    labels: aggregatedData.map((location) => location.locationName),
    datasets: [
      {
        type: 'bar',
        label: 'Original Estimate (Hours)',
        data: aggregatedData.map((location) => location.estimatedHours),
        backgroundColor: 'rgba(54, 162, 235, 0.8)',
      },
      {
        type: 'bar',
        label: 'Original Estimate (Man Hours)',
        data: aggregatedData.map((location) => location.estimatedManHours),
        backgroundColor: bluePattern,
      },
      {
        type: 'bar',
        label: 'Actual Hours So Far (Hours)',
        data: aggregatedData.map((location) => location.actualHours),
        backgroundColor: 'rgba(255, 99, 132, 0.8)',
      },
      {
        type: 'bar',
        label: 'Actual Hours So Far (Man Hours)',
        data: aggregatedData.map((location) => location.actualManHours),
        backgroundColor: redPattern,
      },
      {
        type: 'bar',
        label: 'Expected Total (Hours)',
        data: aggregatedData.map((location) => location.completionHours),
        backgroundColor: 'rgba(255, 206, 86, 0.8)',
      },
      {
        type: 'bar',
        label: 'Expected Total (Man Hours)',
        data: aggregatedData.map((location) => location.completionManHours),
        backgroundColor: yellowPattern,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
      },
      y1: {
        type: 'linear',
        display: false,
        position: 'right',
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          callback: function (value) {
            return value + '%';
          },
        },
      },
    },
  };

  return <Bar options={options} data={chartData} />;
};

export default HoursChart;
