import { gql } from '@apollo/client';

export const SAVE_CUSTOM_OPTION_SET = gql`
  mutation saveCustomOptionSet(
    $id: ID
    $input: SaveCustomOptionSetInput!
    $activeOrganisation: String!
  ) {
    saveCustomOptionSet(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const SAVE_CUSTOM_FIELD_GROUP = gql`
  mutation saveCustomFieldGroup(
    $id: ID
    $input: SaveCustomFieldGroupInput!
    $activeOrganisation: String!
  ) {
    saveCustomFieldGroup(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const DELETE_CUSTOM_OPTION_SET = gql`
  mutation deleteCustomOptionSet($id: ID, $activeOrganisation: String!) {
    deleteCustomOptionSet(id: $id, activeOrganisation: $activeOrganisation) {
      success
      message
    }
  }
`;
