import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useEffect } from 'react';

import { GET_CUSTOM_OPTIONS } from '../../../graphql/queries/custom-data';

import FormCard from '../../../components/FormCard/FormCard';
import { SAVE_TIMESHEET_CONFIGURATION } from '../../../graphql/mutations/timesheet-configurations';
import { GET_ACTIVITY_CODES_SUMMARY } from '../../../graphql/queries/activity-codes';
import { GET_TIMESHEET_CONFIGURATIONS } from '../../../graphql/queries/timesheet-configurations';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { FormConfig } from '../../../types/Form';
import { GET_GROUPS } from '../../../graphql/queries/users';

export const SaveTimesheetConfiguration = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const isUpdate = !!id;

  const [fetch, { data: activityCodesData }] = useLazyQuery(
    GET_ACTIVITY_CODES_SUMMARY,
    {
      variables: { input: {} },
      fetchPolicy: 'network-only',
    }
  );

  const [fetchCustomOptions, { data: customOptionsData }] = useLazyQuery(
    GET_CUSTOM_OPTIONS,
    {
      variables: {},
      fetchPolicy: 'network-only',
    }
  );

  const [fetchTimesheetConfigurations, { data }] = useLazyQuery(
    GET_TIMESHEET_CONFIGURATIONS,
    {
      variables: { input: { id } },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    fetch({ variables: { input: {} } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    fetchTimesheetConfigurations({ variables: { input: { id } } });
    fetchCustomOptions();
  }, [fetchCustomOptions, fetchTimesheetConfigurations, id, isUpdate]);

  const [TimesheetConfiguration] =
    data?.getTimesheetConfigurations?.results || [];

  const activityCodeResults =
    activityCodesData?.getActivityCodes?.results || [];
  const customOptionsSets =
    customOptionsData?.getCustomOptionSets?.results || [];

  const [fetchGroups, { data: groupsData }] = useLazyQuery(GET_GROUPS, {
    variables: { input: { limit: 1000, offset: 0 } },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchGroups({ variables: { input: { limit: 1000, offset: 0 } } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const groupResults = groupsData?.getGroups?.results || [];

  const [saveTimesheetConfiguration] = useMutation(
    SAVE_TIMESHEET_CONFIGURATION
  );

  const initialValues = id
    ? {
        ...TimesheetConfiguration,
      }
    : { name: undefined, description: undefined };

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const customData: { key: string; value: any }[] = [];
    const filteredValues = Object.keys(values).reduce(
      (acc: Record<string, string>, key) => {
        if (key.startsWith('custom:')) {
          customData.push({
            key: key?.replace('custom:', ''),
            value: values[key],
          });
        } else {
          acc[key] = values[key];
        }
        return acc;
      },
      {}
    );

    try {
      await saveTimesheetConfiguration({
        variables: {
          id: values?.id,
          input: {
            ...filteredValues,
            id: undefined,
            updatedAt: undefined,
            createdAt: undefined,
          },
        },
      });
      if (setSubmitting) {
        setSubmitting(false);
      }

      navigate(`/time/timesheets/configuration/`);
    } catch (error) {
      console.error(error);
    }
  };

  const TimesheetConfigurationConfig = {
    formSections: [
      {
        title: 'Timesheet Configuration',
        description: 'Basic details about the Timesheet Configuration',
        fields: [
          {
            id: 'name',
            type: 'input',
            title: 'Timesheet Name',
            placeholder: 'SOME TIMESHEET NAME',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'notifierEmail',
            type: 'email',
            title: 'Notifier Email',
            required: true,
          },
          {
            id: 'enabled',
            type: 'switch',
            title: 'Enabled?',
          },
          {
            id: 'description',
            type: 'textarea',
            title: 'Description',
            placeholder: 'SOME TIMESHEET NAME',
            inputTransformer: (text: string) => text,
          },
        ],
      },
      {
        fields: [
          {
            id: 'type',
            type: 'dropdown',
            title: 'Type',
            description:
              'Choose between whether the timesheet is one completed by someone on behalf of project assignees, or by an individual against many projects.',
            options: [
              { name: 'Managed', id: 'MANAGED' },
              { name: 'Self-reported', id: 'SELF_REPORTED' },
            ].map((granularity: any) => ({
              name: granularity.name,
              value: granularity.id,
            })),
          },
          {
            id: 'granularity',
            type: 'dropdown',
            title: 'Granularity',
            options: [
              { name: 'Daily', id: 'DAILY' },
              { name: 'Percentage of Day', id: 'Day_Percentage' },
              { name: 'Hourly', id: 'Hourly' },
            ].map((granularity: any) => ({
              name: granularity.name,
              value: granularity.id,
            })),
          },

          // {
          //   id: 'multiProject',
          //   type: 'switch',
          //   title: 'Track time against multiple projects',
          //   description: 'Timesheet supports multiple project entries.',
          // },
          // {
          //   id: 'assignOnBehalf',
          //   type: 'switch',
          //   title: 'Complete on Behalf of Assignees',
          //   description: 'A timesheet on behalf of all assignees of a project.',
          // },
          // {
          //   id: 'trackOwnTime',
          //   type: 'switch',
          //   title: 'Track Own Time',
          //   description: 'Can the user track their own time.',
          // },
        ],
      },
      {
        fields: [
          {
            id: 'activityCodes',
            type: 'multiselect',
            title: 'Activity Codes',
            options: activityCodeResults.map((activityCode: any) => ({
              name: activityCode.name,
              value: activityCode.id,
            })),
            description:
              activityCodeResults.length > 0 ? (
                'Select activity codes available for selection in this timesheet.'
              ) : (
                <p>
                  It looks like there aren't any activity codes configured for
                  this organisation.{' '}
                  <Link
                    to={`/finance/codes/new`}
                    target="_blank"
                    className="underline hover:text-black"
                  >
                    <strong>Click here</strong>
                  </Link>{' '}
                  to create one.
                </p>
              ),
          },
          {
            id: 'permittedGroups',
            type: 'multiselect',
            title: 'Groups Who Can Complete',
            options: groupResults.map((group: any) => ({
              name: group.name,
              value: group.id,
            })),
            description: 'Select groups allowed to complete this timesheet.',
          },
          {
            id: 'customDataToApply',
            type: 'multiselect',
            title: 'Custom Datasets',
            options: customOptionsSets.map((customOption: any) => ({
              name: customOption.name,
              value: customOption.id,
            })),
            description: 'Select custom data to apply to timesheet entries.',
          },
        ],
      },
    ],
  } as FormConfig;

  const TimesheetConfigurationCreationSchema = Yup.object().shape({
    notifierEmail: Yup.string().email().required(),
    name: Yup.string()
      .min(3, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required('All timesheets need a name'),
  });

  return (
    <FormCard
      key={`${TimesheetConfigurationConfig?.title}`}
      config={TimesheetConfigurationConfig}
      validationSchema={TimesheetConfigurationCreationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
};
