import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useTabs = (tabs: any[]) => {
  const navigate = useNavigate();
  const location = useLocation();

  const query = new URLSearchParams(location.search);

  const tabFromUrl = query.get('tab') || tabs[0];

  useEffect(() => {
    if (!tabs.includes(tabFromUrl)) {
      navigate(`${location.pathname}?tab=${tabs[0]}`, { replace: true });
    }
  }, [tabFromUrl, location.pathname, navigate, tabs]);

  const setActiveTabUrl = (newTab: any) => {
    navigate(`${location.pathname}?tab=${newTab}`, { replace: true });
  };

  return [tabFromUrl, setActiveTabUrl];
};
