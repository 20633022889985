import moment from 'moment';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../components/Buttons/Button';
import { UserProfileContext } from '../../context/UserProfileContext';
import { Permission } from '../../types/Permissions';
import { useTabs } from '../../hooks/useTabs';
import { CompanyDetails } from './view/CompanyDetails';
import { Tabs } from '../../components/Tabs/Tabs';
import { CompanyLabour } from './view/CompanyLabour';
import { useCompany } from '../../hooks/api/companies/useCompany';
import { ActiveOrganisationContext } from '../../context/ActiveOrganisationContext';
import { DELETE_COMPANY_PROFILE } from '../../graphql/mutations/company-details';
import { useMutation } from '@apollo/client';
import { useToast } from '../../context/ToastContext';
import { DeletionDialogue } from '../../components/Deletion/DeletionDialogue';
import { CompanyCertifications } from './view/CompanyCertifications';
import MissingCertifications from '../../components/MissingCertifications';

const tabs = ['Summary', 'Labour', 'Certifications'];

export const CompanyPage = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useTabs(tabs);

  const [company, { loading, error }] = useCompany(id!);

  const [showDeleteCompanyModal, setShowDeleteCompanyModal] = useState(false);

  const [deleteCompanyProfile] = useMutation(DELETE_COMPANY_PROFILE);

  const { activeOrganisation } = useContext(ActiveOrganisationContext);

  const { userProfile } = useContext(UserProfileContext);

  const { addToast } = useToast();

  const hasDeletePermission = userProfile?.permissions?.includes(
    Permission.DeleteCompanyProfile
  );

  const hasOwnerOrAssignees = company?.hasAssignees || company?.owner?.id;

  const handleDelete = async () => {
    try {
      const result = await deleteCompanyProfile({
        variables: { id, activeOrganisation: activeOrganisation?.id },
      });
      navigate('/companies');
      if (!result.data.deleteCompanyProfile.success) {
        addToast('Error deleting company', 'error');
      } else {
        addToast('Company deleted', 'success');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <DeletionDialogue
        deleteTitle={'Delete Company'}
        showDeleteModal={showDeleteCompanyModal}
        setShowDeleteModal={setShowDeleteCompanyModal}
        onDelete={async () => {
          await handleDelete();
          setShowDeleteCompanyModal(false);
        }}
        deletionConfirmationText="Are you sure you want to delete this company? You will not be able to restore it once deleted."
      />
      <div className="flex w-full border-b mb-2">
        <Button
          style={{
            backgroundColor: 'transparent',
            color: 'black',
            boxShadow: 'none',
            borderRadius: 0,
          }}
          onClick={() => navigate(-1)}
          type="submit"
          text={`< Go Back`}
        />
      </div>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <Tabs activeTab={activeTab} tabs={tabs} setActiveTab={setActiveTab} />
        <div className="flex flex-col md:items-center md:flex-row w-full md:justify-between px-4 py-5 sm:px-6 flex-wrap">
          <div className="flex flex-col">
            <h1 className="text-3xl font-extrabold text-gray-900">
              {company?.companyName}
            </h1>
            <p className="mt-1 text-sm text-gray-500">
              Created: {moment.unix(company?.createdAt / 1000).fromNow()}
            </p>
            <p className="mt-1 text-sm text-gray-500">
              Updated: {moment.unix(company?.updatedAt / 1000).fromNow()}
            </p>
          </div>
          {userProfile?.permissions.includes(
            Permission.UpdateLabourProfiles
          ) ? (
            <div>
              {activeTab === 'Summary' ? (
                <div className="flex gap-4 py-2">
                  <Button
                    onClick={() => navigate(`/companies/${id}/edit`)}
                    type="submit"
                    text="Edit"
                  />
                  {hasDeletePermission ? (
                    <Button
                      disabled={hasOwnerOrAssignees}
                      onClick={() => setShowDeleteCompanyModal(true)}
                      type="submit"
                      text="Delete Company"
                      className="bg-red-500 hover:bg-red-600"
                    />
                  ) : null}
                </div>
              ) : null}
              {activeTab === 'Labour' ? (
                <Button
                  onClick={() => navigate(`/companies/${id}/assign`)}
                  type="submit"
                  text="Assign Subcontractors"
                />
              ) : null}
              {activeTab === 'Certifications' ? (
                <Button
                  onClick={() =>
                    navigate(`/companies/${id}/certifications/new`)
                  }
                  type="submit"
                  text="Add Certification"
                />
              ) : null}
            </div>
          ) : null}
        </div>

        {loading && (
          <div className="h-64 flex items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
          </div>
        )}
        {error && (
          <p className="px-4 py-5 sm:p-6 text-red-500">
            Error loading data. Please try again.
          </p>
        )}
        <MissingCertifications
          missingOrInvalidCertifications={
            company?.missingOrInvalidCertifications
          }
        >
          {company?.companyName} has missing, incomplete, expired or soon to be
          expiring certifications required for projects they are assigned to,
          please add / update these certifications:
        </MissingCertifications>
        {company ? (
          <>
            {activeTab === 'Summary' ? (
              <CompanyDetails company={company} />
            ) : null}
            {activeTab === 'Labour' ? (
              <CompanyLabour company={company} />
            ) : null}
            {activeTab === 'Certifications' ? (
              <CompanyCertifications company={company} />
            ) : null}
          </>
        ) : (
          <p className="px-4 py-4">No company data available</p>
        )}
      </div>
    </div>
  );
};

export const Company = () => <CompanyPage />;
