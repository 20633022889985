import ComboBox from '../../../../components/Inputs/ComboBox';

export const LocationSelection = ({
  id,
  locationSearchResults = [],
  setSearchTerm,
  setSelectedLocation,
  selectedLocation,
  isDisabled,
}: any) => {
  return (
    <div className="w-full">
      <ComboBox
        testId="location-selection"
        placeholder="Search by location name"
        isDisabled={isDisabled}
        question={{
          id,
          options: locationSearchResults?.map((location: any) => ({
            id: location.id,
            name: location.name,
            detail: location.internalId,
          })),
          onChange: (value) => setSearchTerm(value),
        }}
        handleChange={(id) => {
          setSelectedLocation(
            locationSearchResults?.find((location: any) => location.id === id)
          );
        }}
        value={selectedLocation?.name ?? ''}
        validation={undefined}
      />
    </div>
  );
};
