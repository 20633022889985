import React, { useEffect } from 'react';
import { useToast } from '../../context/ToastContext';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

export const ToastContainer: React.FC = () => {
  const { toasts, removeToast } = useToast();

  useEffect(() => {
    toasts.forEach((toast) => {
      const timer = setTimeout(() => removeToast(toast.id), 3000);
      return () => clearTimeout(timer);
    });
  }, [toasts, removeToast]);

  return (
    <div className="fixed top-[82px] w-full right-5 z-50 flex flex-col items-end space-y-2">
      {toasts.map((toast) => (
        <div
          key={toast.id}
          className={`toast text-center flex gap-x-3 tracking-tight toast-${
            toast.type
          }  px-5 py-3 text-sm border-l-4 shadow-lg rounded-md transform transition-all duration-500 ease-out ${
            toast.type === 'success'
              ? 'bg-green-200 border-green-500 text-green-900'
              : 'bg-red-100 border-red-500 text-red-800'
          } scale-100`}
          style={{ animation: 'toast-in 0.5s' }}
        >
          <InformationCircleIcon className="w-5 h-5" /> {toast.message}
        </div>
      ))}
    </div>
  );
};
