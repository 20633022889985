import {
  CancelDrop,
  CollisionDetection,
  DndContext,
  DragOverlay,
  DropAnimation,
  KeyboardCoordinateGetter,
  KeyboardSensor,
  MeasuringStrategy,
  Modifiers,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  closestCenter,
  defaultDropAnimationSideEffects,
  getFirstCollision,
  pointerWithin,
  rectIntersection,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  AnimateLayoutChanges,
  SortableContext,
  SortingStrategy,
  arrayMove,
  defaultAnimateLayoutChanges,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal, unstable_batchedUpdates } from 'react-dom';
import { v4 } from 'uuid';
import { coordinateGetter as multipleContainersCoordinateGetter } from './coords';

import { SEARCH_PROJECT_ACTIVITY_GROUPS } from '../../graphql/queries/projects';
import { useOrganisationAwareApollo } from '../../hooks/useOrganisationAwareApollo';
import { ActivityGroup, Outcome, Phase } from '../../types/Outcomes';
import { Button } from '../Buttons/Button';
import TextInput from '../Inputs/TextInput';
import { Container } from './Container';
import { Item, SortableItem } from './Item';
import { SearchBox } from './search';
import {
  getColor,
  mapOutcomeToPhaseActivityGroupNames,
  mapPhaseActivityGroupNamesToOutcome,
} from './utils/utils';

const defaultInitializer = (index: number) => index;

export function createRange<T = number>(
  length: number,
  initializer: (index: number) => any = defaultInitializer
): T[] {
  return [...new Array(length)].map((_, index) => initializer(index));
}

const animateLayoutChanges: AnimateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true });

function DroppableContainer({
  children,
  columns = 1,
  disabled,
  id,
  items,
  style,
  color,
  ...props
}: any & {
  disabled?: boolean;
  id: UniqueIdentifier;
  items: UniqueIdentifier[];
  style?: React.CSSProperties;
}) {
  const {
    active,
    attributes,
    isDragging,
    listeners,
    over,
    setNodeRef,
    transition,
    transform,
  } = useSortable({
    id,
    data: {
      type: 'container',
      children: items,
    },
    animateLayoutChanges,
  });
  const isOverContainer = over
    ? (id === over.id && active?.data.current?.type !== 'container') ||
      items?.activityGroups?.includes(over.id)
    : false;

  return (
    <Container
      ref={disabled ? undefined : setNodeRef}
      style={{
        ...style,
        background: getColor(id),
        transition,
        transform: CSS.Translate.toString(transform),
        opacity: isDragging ? 0.5 : undefined,
        marginRight: 10,
        marginLeft: 10,
      }}
      hover={isOverContainer}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      columns={columns}
      {...props}
    >
      <div className="px-4 py-2">{children}</div>
    </Container>
  );
}

const dropAnimation: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
};

type Items = Record<UniqueIdentifier, Phase>;

interface Props {
  adjustScale?: boolean;
  cancelDrop?: CancelDrop;
  columns?: number;
  containerStyle?: React.CSSProperties;
  coordinateGetter?: KeyboardCoordinateGetter;
  getItemStyles?(args: {
    value: UniqueIdentifier;
    index: number;
    overIndex: number;
    isDragging: boolean;
    containerId: UniqueIdentifier;
    isSorting: boolean;
    isDragOverlay: boolean;
  }): React.CSSProperties;
  wrapperStyle?(args: { index: number }): React.CSSProperties;
  itemCount?: number;
  items?: Items;
  handle?: boolean;
  renderItem?: any;
  strategy?: SortingStrategy;
  modifiers?: Modifiers;
  minimal?: boolean;
  trashable?: boolean;
  scrollable?: boolean;
  vertical?: boolean;
  outcome: Outcome;
  projectId: string;
  setOutcome: (value: Outcome) => void;
  availableActivityGroups?: ActivityGroup[];
  children: any;
}

export const TRASH_ID = 'void';
const PLACEHOLDER_ID = 'placeholder';
const empty: UniqueIdentifier[] = [];

export const OutcomeBuilder = ({
  adjustScale = false,
  cancelDrop,
  columns,
  handle = true,
  containerStyle,
  coordinateGetter = multipleContainersCoordinateGetter,
  getItemStyles = () => ({}),
  wrapperStyle = () => ({}),
  minimal = false,
  modifiers,
  renderItem,
  strategy = verticalListSortingStrategy,
  trashable = false,
  vertical = false,
  scrollable,
  outcome,
  setOutcome,
  projectId,
  children,
}: Props) => {
  const [items, setItems] = useState<Items>(() =>
    mapOutcomeToPhaseActivityGroupNames(outcome)
  );
  const [containers, setContainers] = useState(
    Object.keys(items) as UniqueIdentifier[]
  );

  const { useLazyQuery } = useOrganisationAwareApollo();

  const [searchProjectActivityGroups, { data: pagResults }] = useLazyQuery(
    SEARCH_PROJECT_ACTIVITY_GROUPS,
    { fetchPolicy: 'network-only' }
  );

  const [searchTerm, setSearchTerm] = useState('');

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  const performSearch = async () => {
    try {
      await searchProjectActivityGroups({
        variables: {
          searchTerm: debouncedSearchTerm,
          projectId,
        },
        fetchPolicy: 'network-only',
      });
    } catch (err) {
      // do nothing
    } finally {
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 600); // 300ms debounce time
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    performSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (Object.keys(items).length > 0) {
      setOutcome(
        mapPhaseActivityGroupNamesToOutcome(
          items,
          outcome,
          containers as string[]
        )
      );
    } else {
      setOutcome({ ...outcome, configuration: { phases: [], locations: [] } });
    }
  }, [items, containers]);

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const lastOverId = useRef<UniqueIdentifier | null>(null);
  const recentlyMovedToNewContainer = useRef(false);
  const isSortingContainer = activeId ? containers.includes(activeId) : false;

  const [newPhaseName, setNewPhaseName] = useState<string | undefined>('');

  function handleAddPhase(phase: Phase) {
    const phaseUuid = v4();
    unstable_batchedUpdates(() => {
      setContainers((containers) => [...containers, phaseUuid]);
      setItems((items) => ({
        ...items,
        [phaseUuid]: { ...phase },
      }));
      setNewPhaseName(undefined);
    });
  }

  function handleAddActivityGroup(
    phaseId: string,
    activityGroup: ActivityGroup
  ) {
    unstable_batchedUpdates(() => {
      setItems((items) => {
        const activityGroupUuid = v4();
        const activityGroupToAdd = {
          ...activityGroup,
          id: activityGroupUuid,
          originId: activityGroup.id,
        };
        const existingPhase = items[phaseId];
        return {
          ...items,
          [phaseId]: {
            ...existingPhase,
            activityGroups: existingPhase.activityGroups
              ? [...existingPhase.activityGroups, activityGroupToAdd]
              : [activityGroupToAdd],
          },
        };
      });
    });
  }

  const collisionDetectionStrategy: CollisionDetection = useCallback(
    (args) => {
      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) => container.id in items
          ),
        });
      }

      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, 'id');

      if (overId != null) {
        if (overId === TRASH_ID) {
          // If the intersecting droppable is the trash, return early
          // Remove this if you're not using trashable functionality in your app
          return intersections;
        }

        if (overId in items) {
          const containerItems = items[overId];

          // If a container is matched and it contains items (columns 'A', 'B', 'C')
          if ((containerItems?.activityGroups?.length ?? 0) > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) =>
                  container.id !== overId &&
                  containerItems.activityGroups?.find(
                    (item) => item.id === container.id
                  )
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{ id: overId }];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, items]
  );
  const [clonedItems, setClonedItems] = useState<Items | null>(null);
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    })
  );
  const findContainer = (id?: UniqueIdentifier) => {
    if (id && id in items) {
      return id;
    }

    return Object.keys(items).find((key) =>
      items[key]?.activityGroups?.find((item) => item.id === id)
    );
  };

  const getIndex = (id?: UniqueIdentifier) => {
    const container = findContainer(id);

    if (!container) {
      return -1;
    }

    const index = items[container]?.activityGroups?.findIndex(
      (item) => item.id === id
    );

    return index;
  };

  const onDragCancel = () => {
    if (clonedItems) {
      // Reset items to their original state in case items have been
      // Dragged across containers
      setItems(clonedItems);
    }

    setActiveId(null);
    setClonedItems(null);
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={({ active }) => {
        setActiveId(active.id);
        setClonedItems(items);
      }}
      onDragOver={({ active, over }) => {
        const overId = over?.id;

        if (overId == null || overId === TRASH_ID || active.id in items) {
          return;
        }

        const overContainer = findContainer(overId);
        const activeContainer = findContainer(active.id);

        if (!overContainer || !activeContainer) {
          return;
        }

        if (activeContainer !== overContainer) {
          setItems((items) => {
            const activeItems = items[activeContainer];
            const overItems = items[overContainer];
            const overIndex =
              overItems?.activityGroups?.findIndex(
                (item) => item.id === overId
              ) ?? -1;
            const activeIndex =
              activeItems?.activityGroups?.findIndex(
                (item) => item.id === active.id
              ) ?? -1;

            let newIndex: number;

            if (overId in items) {
              newIndex = (overItems?.activityGroups?.length ?? 0) + 1;
            } else {
              const isBelowOverItem =
                over &&
                active.rect.current.translated &&
                active.rect.current.translated.top >
                  over.rect.top + over.rect.height;

              const modifier = isBelowOverItem ? 1 : 0;

              newIndex =
                overIndex >= 0
                  ? overIndex + modifier
                  : (overItems?.activityGroups?.length ?? 0) + 1;
            }

            recentlyMovedToNewContainer.current = true;

            return {
              ...items,
              [activeContainer]: {
                ...items[activeContainer],
                activityGroups: items[activeContainer]?.activityGroups?.filter(
                  (item) => item.id !== active.id
                ),
              },
              [overContainer]: {
                ...items[overContainer],
                activityGroups: items[overContainer].activityGroups
                  ? // @ts-ignore
                    [
                      // @ts-ignore
                      ...items?.[overContainer]?.activityGroups?.slice(
                        0,
                        newIndex
                      ),
                      items?.[activeContainer]?.activityGroups?.[activeIndex],
                      // @ts-ignore
                      ...items[overContainer]?.activityGroups?.slice(
                        newIndex,
                        items[overContainer]?.activityGroups?.length
                      ),
                    ]
                  : [],
              },
            } as Items;
          });
        }
      }}
      onDragEnd={({ active, over }) => {
        if (active.id in items && over?.id) {
          setContainers((containers) => {
            const activeIndex = containers.indexOf(active.id);
            const overIndex = containers.indexOf(over.id);

            return arrayMove(containers, activeIndex, overIndex);
          });
        }

        const activeContainer = findContainer(active.id);

        if (!activeContainer) {
          setActiveId(null);
          return;
        }

        const overId = over?.id;

        if (overId == null) {
          setActiveId(null);
          return;
        }

        if (overId === TRASH_ID) {
          setItems((items) => ({
            ...items,
            [activeContainer]: {
              ...items[activeContainer],
              activityGroups: items[activeContainer]?.activityGroups?.filter(
                (item) => item.id !== activeId
              ),
            },
          }));
          setActiveId(null);
          return;
        }

        if (overId === PLACEHOLDER_ID) {
          const newContainerId = getNextContainerId();

          unstable_batchedUpdates(() => {
            setContainers((containers) => [...containers, newContainerId]);
            setItems((items) => ({
              ...items,
              [activeContainer]: {
                ...items[activeContainer],
                activityGroups: items?.[
                  activeContainer
                ]?.activityGroups?.filter((item) => item.id !== activeId),
              },
              [newContainerId]: {
                ...items[activeContainer],
                activityGroups: items?.[
                  activeContainer
                ]?.activityGroups?.filter((item) => item.id === activeId),
              },
            }));
            setActiveId(null);
          });
          return;
        }

        const overContainer = findContainer(overId);

        if (overContainer) {
          const activeIndex =
            items[activeContainer]?.activityGroups?.findIndex(
              (item) => item.id === active.id
            ) ?? -1;
          const overIndex =
            items[overContainer]?.activityGroups?.findIndex(
              (item) => item.id === overId
            ) ?? -1;

          if (activeIndex !== overIndex) {
            setItems((items) => ({
              ...items,
              [overContainer]: {
                ...items[overContainer],
                activityGroups: arrayMove(
                  items?.[overContainer]?.activityGroups ?? [],
                  activeIndex,
                  overIndex
                ),
              },
            }));
          }
        }

        setActiveId(null);
      }}
      cancelDrop={cancelDrop}
      onDragCancel={onDragCancel}
      modifiers={modifiers}
    >
      <div className="min-h-screen mb-[200px]">
        <div className="px-10 mt-2 flex flex-col gap-y-2">
          <div className="flex gap-x-2 w-full items-center justify-end">
            <TextInput
              question={{}}
              handleChange={setNewPhaseName}
              value={newPhaseName}
            />
            <div className="mt-2">
              <Button
                isDisabled={!newPhaseName}
                onClick={() => {
                  if (newPhaseName) {
                    handleAddPhase({
                      id: v4(),
                      name: newPhaseName,
                      activityGroups: [],
                    });
                  }
                }}
                text={'Add new Phase'}
              />
            </div>
          </div>
        </div>
        <div
          className="flex flex-col lg:grid"
          style={{
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: '10px',
            width: '100%',
            padding: 20,
            gridAutoFlow: 'auto',
          }}
        >
          <>
            <SortableContext
              items={[...containers, PLACEHOLDER_ID]}
              strategy={verticalListSortingStrategy}
            >
              {containers.map((containerId, containerIndex) => (
                <DroppableContainer
                  key={containerId}
                  id={containerId}
                  label={`#${containerIndex + 1} - ${items[containerId].name}`}
                  columns={columns}
                  items={items[containerId]}
                  scrollable={scrollable}
                  style={containerStyle}
                  unstyled={minimal}
                  onRemove={() => handleRemove(containerId)}
                >
                  <SortableContext
                    items={items?.[containerId].activityGroups ?? []}
                    strategy={strategy}
                  >
                    {items?.[containerId]?.activityGroups?.map(
                      (value, index) => {
                        const handleRemove = () => {
                          const currentContainer = items?.[containerId];
                          setItems((items) => ({
                            ...items,
                            [containerId]: {
                              ...currentContainer,
                              activityGroups:
                                currentContainer?.activityGroups?.filter(
                                  (group) => group.id !== value.id
                                ) ?? [],
                            },
                          }));
                        };
                        return (
                          <div className="relative">
                            <SortableItem
                              disabled={isSortingContainer}
                              key={value.id}
                              id={value.id}
                              index={index}
                              handle={handle}
                              label={`#${index + 1} - ${value.name}`}
                              style={getItemStyles}
                              wrapperStyle={wrapperStyle}
                              renderItem={renderItem}
                              onRemove={handleRemove}
                              containerId={containerId}
                              getIndex={(id: string) => getIndex(id) ?? 0}
                            />
                          </div>
                        );
                      }
                    )}
                    <hr
                      style={{ opacity: 0.2 }}
                      className="border-black my-4"
                    />

                    <div className="flex">
                      <SearchBox
                        placeholder="Add a Project Activity Group"
                        searchTerm={searchTerm}
                        parentClassNames={''}
                        setSearchTerm={setSearchTerm}
                        comboOptionClassNames={'max-w-[300px] fixed'}
                        setSelected={(id: string) =>
                          handleAddActivityGroup(
                            `${containerId}`,
                            pagResults?.searchProjectActivityGroups?.results?.find(
                              (group: any) => group?.id === id
                            )
                          )
                        }
                        results={
                          pagResults?.searchProjectActivityGroups?.results ?? []
                        }
                      />
                    </div>
                  </SortableContext>
                </DroppableContainer>
              ))}
            </SortableContext>
          </>
        </div>

        {createPortal(
          <DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation}>
            {activeId
              ? containers.includes(activeId)
                ? renderContainerDragOverlay(activeId)
                : renderSortableItemDragOverlay(activeId)
              : null}
          </DragOverlay>,
          document.body
        )}
        {children}
      </div>
    </DndContext>
  );

  function renderSortableItemDragOverlay(id: UniqueIdentifier) {
    return (
      <Item
        value={id}
        handle={handle}
        label={
          items[findContainer(id) ?? '']?.activityGroups?.find(
            (item) => item.id === id
          )?.name
        }
        style={getItemStyles({
          containerId: findContainer(id) as UniqueIdentifier,
          overIndex: -1,
          index: getIndex(id) ?? 0,
          value: id,
          isSorting: true,
          isDragging: true,
          isDragOverlay: true,
        })}
        wrapperStyle={wrapperStyle({ index: 0 })}
        renderItem={renderItem}
        dragOverlay
      />
    );
  }

  function renderContainerDragOverlay(containerId: UniqueIdentifier) {
    return (
      <Container
        label={items[containerId].name}
        columns={columns}
        style={{
          height: '100%',
        }}
        shadow
        unstyled={false}
      >
        {items[containerId]?.activityGroups?.map((item, index) => (
          <Item
            key={item.id}
            value={item.id}
            handle={handle}
            style={getItemStyles({
              containerId,
              overIndex: -1,
              index: getIndex(item.id) ?? 0,
              value: item.id,
              isDragging: false,
              isSorting: false,
              isDragOverlay: false,
            })}
            color={getColor(`${containerId}`)}
            wrapperStyle={wrapperStyle({ index })}
            renderItem={renderItem}
          />
        ))}
      </Container>
    );
  }

  function handleRemove(containerID: UniqueIdentifier) {
    setContainers((containers) =>
      containers.filter((id) => id !== containerID)
    );
  }

  function getNextContainerId() {
    const containerIds = Object.keys(items);
    const lastContainerId = containerIds[containerIds.length - 1];

    return String.fromCharCode(lastContainerId.charCodeAt(0) + 1);
  }
};
