import { useNavigate } from 'react-router-dom';
import { AssessmentSubmissionsTableFilter } from '../../../hooks/api/useAssessmentSubmissions';
import { usePagination } from '../../../context/PaginationContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Table } from '../../../components/Tables/tables/Table';
import moment from 'moment';
import { RedBadge, YellowBadge } from '../../../components/Badges/Badges';
import NoData from '../../../components/NoData/NoData';
import { GET_ASSESSMENT_SUBMISSIONS_FOR_COMPANY_DASHBOARD } from '../../../graphql/queries/assessments';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';

export const CompanyAssessmentSubmissionsTable = ({
  activeFilter,
  assessmentTypeId,
  projectId,
  unsent,
}: {
  activeFilter?: AssessmentSubmissionsTableFilter;
  assessmentTypeId: string;
  projectId?: string;
  unsent?: boolean;
}) => {
  const { useQuery } = useOrganisationAwareApollo();
  const navigate = useNavigate();
  const { limit, offset, setOffset } = usePagination();
  const [sortByColumn, setSortByColumn] = useState<string>('updatedAt');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('DESC');

  const { data, loading, refetch } = useQuery(
    GET_ASSESSMENT_SUBMISSIONS_FOR_COMPANY_DASHBOARD,
    {
      variables: {
        input: { limit, offset },
        assessmentTypeId,
        projectId,
        filter: activeFilter,
        unsent,
      },
      fetchPolicy: 'network-only',
    }
  );

  const assessmentSubmissions =
    data?.getAssessmentSubmissionsForCompanyDashboard?.results;
  const count = data?.getAssessmentSubmissionsForCompanyDashboard?.count;

  const headerMapping = useMemo(
    () =>
      ({
        companyName: 'Company Name',
        project: 'Project',
        updatedAt: {
          text: 'Updated',
          sorted: ['createdAt', 'updatedAt'].includes(sortByColumn),
          sortDirection,
          onClick: () => {
            setSortByColumn(unsent ? 'createdAt' : 'updatedAt');
            setSortDirection(sortDirection === 'DESC' ? 'ASC' : 'DESC');
          },
        },
        outcome: 'Outcome',
      } as Record<string, any>),
    [sortByColumn, sortDirection, unsent]
  );

  const onPageChange = useCallback(
    (pageNumber: number) => {
      setOffset((pageNumber - 1) * limit);
    },
    [limit, setOffset]
  );

  useEffect(() => {
    onPageChange(1);
  }, [onPageChange, projectId]);

  const totalResults = count;
  const totalPages = Math.ceil(totalResults / limit);

  return (
    <Table
      currentPage={Math.floor(offset / limit) + 1}
      totalPages={totalPages}
      totalResults={totalResults}
      itemsPerPage={limit}
      onClickRow={(id) => {
        const submission = assessmentSubmissions.find(
          (submission: any) => submission.id === id
        );

        const company = submission.company;
        const projectId = submission.project.id;

        if (unsent) {
          navigate(
            `/projects/${projectId}/companies/${company.id}?tab=Assessments`
          );
        } else {
          navigate(
            `/projects/${projectId}/companies/${company.id}/assessments/${id}`
          );
        }
      }}
      headerMapper={(text: string) => headerMapping[text]}
      onPageChange={onPageChange}
      fetchPageOfDataForExport={async (limit: number, pageOffset: number) => {
        const { data } = await refetch({
          variables: {
            input: { limit, offset: pageOffset },
            ...(projectId ? { projectId } : {}),
            ...(assessmentTypeId ? { assessmentTypeId } : {}),
            unsent,
            filter: activeFilter,
          },
          fetchPolicy: 'network-only',
        });
        setOffset(pageOffset);
        return data?.getAssessmentSubmissionsForCompanyDashboard?.results?.map(
          (submission: any) => {
            const assessmentType = submission?.assessmentType || {};
            const assignment = submission.assignmentRole?.assignment || {};
            const project = assignment?.project || {};
            const labourResource = assignment?.lrs || {};
            const role = submission.assignmentRole?.role || {};

            return {
              id: submission?.id,
              assessmentTypeId: assessmentType?.id || 'N/A',
              assessmentTypeName: assessmentType?.name || 'N/A',
              projectId: project?.id || 'N/A',
              projectName: project?.name || 'N/A',
              projectInternalId: project?.internalId || 'N/A',
              labourResourceId: labourResource?.id || 'N/A',
              firstName: labourResource?.firstName || '',
              lastName: labourResource?.lastName || '',
              email: labourResource?.email || '',
              roleName: role?.name || '',
              outcome: submission?.outcome || '',
              createdAt: submission?.createdAt
                ? new Date(parseInt(submission.createdAt)).toISOString()
                : 'N/A',
              updatedAt: submission?.updatedAt
                ? new Date(parseInt(submission.updatedAt)).toISOString()
                : 'N/A',
            };
          }
        );
      }}
      data={assessmentSubmissions?.map((submission: any) => ({
        id: submission.id,
        companyName: submission.company?.companyName,
        project: `${submission.project.name} (${submission.project.internalId})`,
        updatedAt: unsent
          ? 'Never'
          : moment.unix(parseInt(submission.updatedAt) / 1000).fromNow(),
        outcome: submission.outcome ? (
          unsent ? (
            <RedBadge text="Unsent" />
          ) : (
            <strong>{submission.outcome}</strong>
          )
        ) : submission.responses ? (
          <YellowBadge text="No Outcome Decided Yet" />
        ) : (
          <RedBadge text="Not Started Yet" />
        ),
      }))}
      NoData={() => (
        <NoData loading={loading}>
          It looks like there are no people that match these filters!
        </NoData>
      )}
    />
  );
};
