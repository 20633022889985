import Avatar from 'react-avatar';
import { useNavigate } from 'react-router-dom';
import NoData from '../../../../components/NoData/NoData';
import { useCompanyByLabourResource } from '../../../../hooks/api/companies/useCompany';
import { YellowBadge } from '../../../../components/Badges/Badges';
import MissingCertifications from '../../../../components/MissingCertifications';

const companyTypeToLabel: Record<string, string> = {
  LIMITED: 'Limited',
  UMBRELLA: 'Umbrella',
};

type CompanyProfileProps = {
  labourResource?: any;
};

export const CompanyProfile = ({ labourResource }: CompanyProfileProps) => {
  const [companyProfile, { loading, error }] = useCompanyByLabourResource(
    labourResource?.id
  );

  console.log(companyProfile);

  const navigate = useNavigate();

  const sections = [
    {
      title: 'Company Record Owner',
      content:
        companyProfile?.owner?.id && companyProfile?.companyName ? (
          <div
            role="button"
            onClick={() =>
              navigate(
                `/labour/${companyProfile?.owner?.type?.toLowerCase()}s/${
                  companyProfile?.owner?.id
                }`
              )
            }
            className="px-3 flex gap-x-2 text-black items-center border rounded-lg text-lg py-4"
          >
            <Avatar
              name={`${companyProfile?.owner?.firstName} ${companyProfile?.owner?.lastName}`}
              size="35"
              round
            />
            {companyProfile?.owner?.firstName} {companyProfile?.owner?.lastName}
          </div>
        ) : (
          <YellowBadge text="No Owner" />
        ),
    },
    {
      title: 'Company Name',
      content: companyProfile?.companyName || 'N/A',
    },

    {
      title: 'Company Number',
      content: companyProfile?.companyRegNumber || 'N/A',
    },
    {
      title: 'Company Email',
      content: companyProfile?.companyEmail || 'N/A',
    },
    {
      title: 'Company Address',
      content: companyProfile?.companyAddress || 'N/A',
    },
    {
      title: 'Company Phone Number',
      content: companyProfile?.companyPhoneNumber || 'N/A',
    },
    {
      title: 'Company Type',
      content: companyTypeToLabel[companyProfile?.companyType] || 'N/A',
    },
    {
      title: 'VAT Registration Number',
      content: companyProfile?.vatRegistrationNumber || 'N/A',
    },
    {
      title: 'Contact Name',
      content: companyProfile?.contactName || 'N/A',
    },
  ];

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      {loading && (
        <div className="h-64 flex items-center justify-center">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
        </div>
      )}
      {error && (
        <p className="px-4 py-5 sm:p-6 text-red-500">
          Error loading data. Please try again.
        </p>
      )}
      <MissingCertifications
        missingOrInvalidCertifications={
          companyProfile?.missingOrInvalidCertifications
        }
      >
        {companyProfile?.companyName} has missing, incomplete, expired or soon
        to be expiring certifications required for projects they are assigned
        to, please add / update these certifications:
      </MissingCertifications>
      {companyProfile ? (
        <div className="border-t border-gray-200">
          {sections.map((section, index) => (
            <div key={index} className="px-4 py-4">
              <h2 className="text-lg font-medium text-gray-900">
                {section.title}
              </h2>
              <p className="mt-1 text-sm text-gray-500">
                {section.content ?? '(empty)'}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <NoData>
          <p>No company data available for this subcontractor.</p>
        </NoData>
      )}
    </div>
  );
};
