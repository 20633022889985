import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../components/Buttons/Button';
import { DataImportGrid } from '../../../components/DataImportGrid/DataImportGrid';

import { GET_PROGRESS_ACTIVITY_CATEGORIES } from '../../../graphql/queries/progress-activity-submissions';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { SAVE_PROGRESS_ACTIVITY_CATEGORIES } from '../../../graphql/mutations/progress-activity-submissions';

export const ActivityCategoryPage = () => {
  let { projectId } = useParams();
  const navigate = useNavigate();
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const [fetch, { data, loading, error }] = useLazyQuery(
    GET_PROGRESS_ACTIVITY_CATEGORIES,
    {
      variables: { input: { limit: 2000 } },
      fetchPolicy: 'network-only',
    }
  );

  const activitycategorys = data?.getProgressActivityCategories?.results || [];

  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetch({ variables: { projectId } });
  }, [projectId]);

  const Tabs = [] as any[];

  const [activeTab, setActiveTab] = useState(Tabs[0]);

  const [saveProjectActivityCategories, { loading: saving }] = useMutation(
    SAVE_PROGRESS_ACTIVITY_CATEGORIES
  );

  useEffect(() => {
    setRows(activitycategorys);
  }, [activitycategorys]);

  return (
    <div className="bg-gray-50">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="py-2 border-b">
          <Button
            style={{
              backgroundColor: 'transparent',
              color: 'gray',
              boxShadow: 'none',
              borderRadius: 0,
            }}
            onClick={() => navigate(-1)}
            type="submit"
            text={`< Go Back`}
          />
        </div>

        <div className="w-full px-2 bg-gray-800 py-2 flex flex-row">
          {Tabs.map((tab) => (
            <div
              className={`${
                activeTab === tab ? 'bg-black' : ''
              } justify-center flex items-center`}
            >
              <Button
                style={{ borderRadius: 0, backgroundColor: 'transparent' }}
                text={tab}
                onClick={() => setActiveTab(tab)}
              />
            </div>
          ))}
        </div>
        {loading && (
          <div className="h-64 flex items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
          </div>
        )}
        {error && (
          <p className="px-4 py-5 sm:p-6 text-red-500">
            Error loading data. Please try again.
          </p>
        )}
        <div className="flex justify-end py-2 px-4">
          <Button
            isLoading={false}
            text={'Save'}
            onClick={async () => {
              await saveProjectActivityCategories({
                variables: {
                  input: {
                    items: rows
                      ?.filter((row: any) => row.name || row.toBeDeleted)
                      ?.map((item: any) => ({
                        id: item.isNew ? undefined : item.id,
                        name: item.name?.trim(),
                        toBeDeleted: item.toBeDeleted,
                      })),
                  },
                },
              });
              await fetch();
            }}
          />
        </div>
        <DataImportGrid
          // @ts-ignore
          rows={rows}
          setRows={setRows}
          config={{
            columns: [{ key: 'name', name: 'Name' }],
            exampleData: [,],
          }}
        />
      </div>
    </div>
  );
};

export const ActivityCategoryBulkSave = () => <ActivityCategoryPage />;
