import DatePicker from './DatePicker';

export interface MonthPickerProps {
  id: string;
  label: string;
  value: any;
  title: string;
  validation: string;
  handleChange: (date: any) => void;
  inputTransformer?: (date: any) => any;
}

const MonthPicker = (props: MonthPickerProps) => {
  return <DatePicker {...props} isMonthPicker />;
};

export default MonthPicker;
