import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';
import { GET_ROLES_SUMMARY } from '../../../graphql/queries/roles';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import moment from 'moment';
import { Button } from '../../../components/Buttons/Button';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';
import NoData from '../../../components/NoData/NoData';
import LinkButton from '../../../components/Buttons/LinkButton';

interface RoleListProps {
  showNewItemButton?: boolean;
  children?: React.ReactElement;
  filterIds?: string[];
  onClickRow?: (id: string, results: any[]) => void;
}

const headerMapping = {
  name: 'Name',
  updatedAt: 'Updated At',
} as Record<string, any>;

const RolesTable = ({
  data = [{}],
  NoData,
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
  filterText,
  setFilterText = () => {},
  selectedFilterDimension,
  fetchPageOfDataForExport,
  setSelectedFilterDimension = () => {},
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    NoData={NoData}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    columnsToFilter={['firstName', 'lastName', 'id']}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    filterDimensions={[{ id: 'name', name: 'Name' }]}
    showFilterOptions
    filterText={filterText}
    setFilterText={setFilterText}
    selectedFilterDimension={selectedFilterDimension}
    setSelectedFilterDimension={setSelectedFilterDimension}
    fetchPageOfDataForExport={fetchPageOfDataForExport}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
  />
);

export const RoleListPage = ({
  children,
  filterIds = [],
  onClickRow,
  showNewItemButton = true,
}: RoleListProps) => {
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [filterText, setFilterText] = useState<string>();
  const [selectedFilterDimension, setSelectedFilterDimension] =
    useState<string>('name');

  const { limit, offset, setOffset } = usePagination();

  const [debouncedFilterText, setDebouncedSearchTerm] = useState(filterText);

  const filter = debouncedFilterText
    ? {
        filters: [
          {
            field: selectedFilterDimension,
            stringFilters: [{ ilike: debouncedFilterText }],
          },
        ],
      }
    : null;

  const [fetch, { data, loading }] = useLazyQuery(GET_ROLES_SUMMARY, {
    variables: { input: { limit, offset } },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetch({
      variables: { input: { limit, offset, ...(filter ? { filter } : {}) } },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, debouncedFilterText]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setOffset(0);
      setDebouncedSearchTerm(filterText);
    }, 600);
    return () => {
      clearTimeout(handler);
    };
  }, [filterText, setOffset]);

  const roleResults = data?.getRoles;

  const totalPages = Math.ceil(roleResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const { userProfile } = useContext(UserProfileContext);

  return (
    <div>
      <>
        {showNewItemButton ? (
          <div className="w-full flex justify-between items-center py-2">
            <h1 className="text-xl font-bold py-4">Roles</h1>
            {userProfile?.permissions?.includes(Permission.UpdateRoles) ? (
              <Button
                text="New Role"
                onClick={() => navigate(`/labour/roles/new`)}
              />
            ) : null}
          </div>
        ) : null}
        <p className="text-sm py-2 text-gray-600">
          Roles can be assigned to subcontractors and employees. Roles contain
          rates against specific currencies.
        </p>

        <RolesTable
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          NoData={() => (
            <NoData loading={loading}>
              It looks like there are no roles configured for this organisation!
              {userProfile?.permissions.includes(Permission.UpdateRoles) ? (
                <LinkButton to="/labour/roles/new" target="_blank">
                  Add a Role
                </LinkButton>
              ) : null}
            </NoData>
          )}
          totalResults={roleResults?.count || 0}
          onClickRow={(id: any) => {
            if (onClickRow) {
              onClickRow(id, roleResults?.results);
            } else {
              navigate(`/labour/roles/${id}`);
            }
          }}
          filterText={filterText}
          setFilterText={setFilterText}
          selectedFilterDimension={selectedFilterDimension}
          setSelectedFilterDimension={setSelectedFilterDimension}
          data={
            roleResults?.results.length > 0
              ? roleResults?.results
                  .filter((result: any) => !filterIds?.includes(result.id))
                  .map((result: any) => ({ ...result }))
              : []
          }
          fetchPageOfDataForExport={async (
            pageLimit: number,
            pageOffset: number
          ) => {
            const { data } = await fetch({
              variables: {
                input: {
                  limit: pageLimit,
                  offset: pageOffset,
                  ...(filter ? { filter } : {}),
                },
              },
            });
            setOffset(pageOffset);
            return data?.getRoles?.results ?? [];
          }}
          onPageChange={handlePageChange}
        />
      </>
      {children}
    </div>
  );
};

export const RoleList = ({
  showNewItemButton,
  filterIds = [],
  onClickRow,
}: RoleListProps) => (
  <PaginationProvider>
    <RoleListPage
      showNewItemButton={showNewItemButton}
      filterIds={filterIds}
      onClickRow={onClickRow}
    />
  </PaginationProvider>
);
