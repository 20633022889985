import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../components/Buttons/Button';
import Switch from '../../../components/Inputs/Switch';
import { ModalDialogue } from '../../../components/Modal/ModalDialogue';
import { SAVE_PROJECT_COMPANY } from '../../../graphql/mutations/projects';
import { useProject } from '../../../hooks/api/projects/useProjects';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { ProjectCompanyAssignmentsList } from './ProjectCompanyAssignmentsList';
import MissingCertifications from '../../../components/MissingCertifications';
import { useTabs } from '../../../hooks/useTabs';
import { Tabs } from '../../../components/Tabs/Tabs';
import { ProjectCompanyAssessmentsList } from './assessments/ProjectCompanyAssessmentsList';
import { useProjectCompany } from '../../../hooks/api/companies/useProjectCompanies';

const tabs = ['Assignments', 'Assessments'];

export const ViewProjectCompany = () => {
  let { projectId, companyId } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useTabs(tabs);

  const [project] = useProject(projectId);
  const [projectCompany, { loading, error }] = useProjectCompany({
    companyId: companyId!,
    projectId: projectId!,
  });

  const company = projectCompany?.company;

  const { useMutation } = useOrganisationAwareApollo();

  const [saveProjectCompany] = useMutation(SAVE_PROJECT_COMPANY);

  const [enabled, setEnabled] = useState(projectCompany?.enabled);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setEnabled(projectCompany?.enabled);
  }, [projectCompany]);

  return (
    <div className="bg-gray-50">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="py-2 border-b">
          {project?.name ? (
            <Button
              style={{
                backgroundColor: 'transparent',
                color: 'gray',
                boxShadow: 'none',
                borderRadius: 0,
              }}
              onClick={() => navigate(`/projects/${projectId}`)}
              type="submit"
              text={`< Back to Project: ${project?.name}`}
            />
          ) : null}
        </div>
        <Tabs activeTab={activeTab} tabs={tabs} setActiveTab={setActiveTab} />
        <div className="flex flex-col md:items-center md:flex-row w-full md:justify-between px-4 py-5 sm:px-6 flex-wrap">
          <div className="flex flex-col">
            <a className="" href={`/companies/${company?.id}`}>
              <div className="flex gap-x-2">
                <h1 className="text-3xl font-extrabold text-gray-900">
                  {company?.companyName}
                </h1>
              </div>
            </a>
            <h2 className="text-lg mt-1 font-base text-gray-500">
              Assigned to Project: {project?.name} - {project?.internalId}
            </h2>
            <p className="mt-1 text-sm text-gray-500">
              Contact Name: {company?.contactName}
            </p>
            <p className="mt-1 text-sm text-gray-500">
              Phone Number: {company?.companyPhoneNumber}
            </p>
            <p className="mt-1 text-sm text-gray-500">
              Contact Email: {company?.companyEmail}
            </p>
            <p className="mt-1 text-sm text-gray-500">
              Created: {moment.unix(projectCompany?.createdAt / 1000).fromNow()}
            </p>
            <p className="mt-1 text-sm text-gray-500">
              Updated: {moment.unix(projectCompany?.updatedAt / 1000).fromNow()}
            </p>
          </div>
          <div className="flex flex-col gap-y-2 justify-between">
            <Switch
              text="Approved"
              enabled={enabled}
              isDisabled={company?.missingOrInvalidCertifications?.length > 0}
              handleChange={() => {
                setShowModal(true);
              }}
            />
            {enabled &&
              company?.missingOrInvalidCertifications?.length === 0 && (
                <Button
                  type="submit"
                  text="Assign Resource"
                  onClick={() =>
                    navigate(
                      `/projects/${projectId}/companies/${company.id}/assign`
                    )
                  }
                />
              )}
          </div>
        </div>

        {loading && (
          <div className="h-64 flex items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
          </div>
        )}
        {error && (
          <p className="px-4 py-5 sm:p-6 text-red-500">
            Error loading data. Please try again.
          </p>
        )}
        <ModalDialogue
          submitText="Yep, I know what I'm doing"
          setShowModal={setShowModal}
          onSubmit={() => {
            setShowModal(false);
            const isEnabled = !enabled;
            setEnabled(isEnabled);
            saveProjectCompany({
              variables: {
                id: projectCompany.id,
                input: { enabled: isEnabled },
              },
            });
          }}
          showModal={showModal}
          Title={
            !enabled
              ? 'Are you sure you want to enable this company?'
              : 'Are you sure you want to disable this company?'
          }
          modalConfirmationText={
            !enabled ? (
              <>
                Your organisation may require you to complete some additional
                checks and actions before you enable this company.
              </>
            ) : (
              <></>
            )
          }
        />
        <MissingCertifications
          missingOrInvalidCertifications={
            company?.missingOrInvalidCertifications
          }
        >
          {company?.companyName} has missing, incomplete, expired or soon to be
          expiring certifications required for projects they are assigned to,
          please add / update these certifications:
        </MissingCertifications>
        {project?.id && company?.id ? (
          <>
            {activeTab === 'Assignments' ? (
              <ProjectCompanyAssignmentsList
                projectId={project.id}
                company={company}
              />
            ) : null}
            {activeTab === 'Assessments' ? (
              <ProjectCompanyAssessmentsList
                projectId={project.id}
                companyId={company.id}
              />
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};
