import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface SettingsHomeProps {
  children?: React.ReactElement;
}

export const SettingsHome = ({ children }: SettingsHomeProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/settings/notifications');
  }, [navigate]);
  return <div>{children}</div>;
};
