import {
  ArrowTrendingUpIcon,
  CurrencyDollarIcon,
  CurrencyEuroIcon,
  CurrencyPoundIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import 'chart.js/auto';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  DateTimePicker,
  DateTimePickerSelectorType,
} from 'react-datetime-pickers';
import { GeneratingBadge } from '../../components/Badges/Badges';
import { Card } from '../../components/Layout/Card';
import { ModuleItemSummary } from '../../components/ModuleItemSummary/ModuleItemSummary';
import { GET_STATISTICS } from '../../graphql/queries/statistics';
import { useOrganisationAwareApollo } from '../../hooks/useOrganisationAwareApollo';

interface TimeTrackingDashboardProps {
  children?: React.ReactElement;
}

export function convertDateToWeekId(date: any) {
  const start = moment(date);

  const startYear = start.year();
  const startWeek = start.week();

  const formattedStartWeekId = `Y${startYear}-W${String(startWeek).padStart(
    2,
    '0'
  )}`;

  return formattedStartWeekId;
}

export const TimeTrackingDashboard = ({
  children,
}: TimeTrackingDashboardProps) => {
  const { useLazyQuery } = useOrganisationAwareApollo();

  moment.updateLocale('en', {
    week: {
      dow: 1,
    },
  });

  const [selectedDate, setSelectedDate] = useState(
    moment().startOf('isoWeek').toDate()
  );

  const minDate = moment().subtract(52, 'weeks').toDate();
  const maxDate = moment().toDate();

  const [fetch, { data, loading }] = useLazyQuery(GET_STATISTICS, {
    variables: {
      query: [
        {
          calculation: 'finance',
          startDate: convertDateToWeekId(selectedDate),
        },
      ],
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetch();
  }, [fetch, selectedDate]);

  const statistics = data?.getStatistics?.finance || {};

  const financeItems =
    statistics?.totalLabourCost?.map((item: any) => ({
      heading: `${Number(item.totalLabourCost)?.toFixed(2)} ${item.currency}`,
      description: `Total Labour Cost in ${item.currency}`,
      colour:
        item.currency === 'EUR'
          ? '#DCFCE7'
          : item.currency === 'GBP'
          ? '#CCCCFF'
          : item.currency === 'USD'
          ? '#CF9FFF'
          : '#E5E7EB',
      IconComponent:
        item.currency === 'EUR'
          ? CurrencyEuroIcon
          : item.currency === 'GBP'
          ? CurrencyPoundIcon
          : item.currency === 'USD'
          ? CurrencyDollarIcon
          : ArrowTrendingUpIcon,
    })) || [];

  const projectBreakdown = statistics?.projectBreakdown || [];
  const projectNames = [
    ...new Set(projectBreakdown.map((item: any) => item.projectName)),
  ];
  const currencies = [
    ...new Set(projectBreakdown.map((item: any) => item.currency)),
  ];

  const chartData = {
    labels: projectNames,
    datasets: currencies.map((currency) => ({
      label: `Total Labour Cost in ${currency}`,
      data: projectNames.map((projectName) => {
        const project = projectBreakdown.find(
          (item: any) =>
            item.projectName === projectName && item.currency === currency
        );
        return project ? project.totalLabourCost : 0;
      }),
      backgroundColor:
        currency === 'EUR'
          ? '#DCFCE7'
          : currency === 'GBP'
          ? '#CCCCFF'
          : currency === 'USD'
          ? '#CF9FFF'
          : '#E5E7EB',
    })),
  };

  const chartOptions = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="w-full">
      <div
        className={`toast text-center flex gap-x-3 mb-2 text-sm bg-blue-100 py-2 px-2 tracking-tight scale-100`}
        style={{ animation: 'toast-in 0.5s' }}
      >
        <InformationCircleIcon className="w-5 h-5" /> Please be aware these
        figures may not be up to date or accurate. This feature is still in
        beta.
      </div>
      <Card>
        <p className="text-sm text-gray-600">Select a Week:</p>
        <div className="border rounded-md">
          <DateTimePicker
            minDate={minDate}
            maxDate={maxDate}
            selector={DateTimePickerSelectorType.WEEK}
            onChange={(props: any) => {
              setSelectedDate(props);
            }}
            selected={selectedDate}
          />
        </div>
      </Card>
      {loading ? (
        <div className="flex w-full py-2 justify-center">
          <GeneratingBadge />
        </div>
      ) : (
        <div className="w-full">
          <Card>
            <ModuleItemSummary moduleItemCards={financeItems} />
          </Card>
          <Card classNames="mt-[1.5rem]">
            <div style={{ marginTop: '2rem' }}>
              <Bar data={chartData} options={chartOptions} />
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};
