import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import FormCard from '../../../../components/FormCard/FormCard';
import { SAVE_COMPANY_DETAILS } from '../../../../graphql/mutations/company-details';
import { useOrganisationAwareApollo } from '../../../../hooks/useOrganisationAwareApollo';
import { FormConfig } from '../../../../types/Form';
import { LabourHeading } from '../../heading/labour';
import { useToast } from '../../../../context/ToastContext';
import { useCompanyByLabourResource } from '../../../../hooks/api/companies/useCompany';

export const SaveCompanyViaLabourResource = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { useMutation } = useOrganisationAwareApollo();

  const { addToast } = useToast();

  const [company] = useCompanyByLabourResource(id!);

  const [saveCompany] = useMutation(SAVE_COMPANY_DETAILS);

  const initialValues = id
    ? {
        ...company,
      }
    : {};

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      const submitResult = await saveCompany({
        variables: {
          id, // why do we send labour resource id twice?
          labourResourceId: id,
          input: {
            ...values,
            id: undefined,
            profileId: undefined,
            owner: undefined,
            updatedAt: undefined,
            createdAt: undefined,
          },
        },
      });

      if (setSubmitting) {
        setSubmitting(false);
      }
      if (!submitResult.data.saveCompanyDetails.success) {
        addToast('Error saving company', 'error');
      } else {
        addToast('Company saved', 'success');
        navigate(-1);
      }
    } catch (error) {
      console.error(error);
      addToast('Error saving company', 'error');
    }
  };

  const companyConfig = {
    formSections: [
      {
        title: 'Company Information',
        description: 'Basic details about the company',
        fields: [
          {
            id: 'companyName',
            type: 'input',
            title: 'Company Name',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'companyEmail',
            type: 'input',
            title: 'Company Email',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'contactName',
            type: 'input',
            title: 'Contact Name',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'companyRegNumber',
            type: 'input',
            title: 'Company Registration Number',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'companyPhoneNumber',
            type: 'input',
            title: 'Company Phone Number',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'companyType',
            type: 'dropdown',
            title: 'Company Type',
            options: [
              {
                name: 'Limited',
                value: 'LIMITED',
              },
              { name: 'Umbrella', value: 'UMBRELLA' },
            ],
          },
          {
            id: 'companyAddress',
            type: 'textarea',
            title: 'Company Address',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'vatRegistrationNumber',
            type: 'input',
            title: 'VAT Registration Number',
            inputTransformer: (text: string) => text,
          },
        ],
      },
    ],
  } as FormConfig;

  const CompanyCreationSchema = Yup.object().shape({
    companyName: Yup.string()
      .min(3, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required('Company Name is required'),
    companyEmail: Yup.string()
      .email('Invalid email format')
      .typeError('Required field')
      .required('Company Email is required'),
    contactName: Yup.string()
      .min(2, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required('Contact Name is required'),
    companyRegNumber: Yup.string()
      .min(2, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required('Company Reg Number is required'),
    companyPhoneNumber: Yup.string()
      .min(2, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required('Company Phone Number is required'),
    companyType: Yup.string().nullable(),
    companyAddress: Yup.string()
      .min(2, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required('Company Address is required'),
    vatRegistrationNumber: Yup.string()
      .min(2, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required('VAT Registration is required'),
  });

  return (
    <>
      {!id ? null : (
        <LabourHeading
          id={id}
          labourType={
            location.pathname.includes('subcontractors')
              ? 'subcontractors'
              : 'employees'
          }
        />
      )}

      <FormCard
        key={`${companyConfig?.title}`}
        config={companyConfig}
        validationSchema={CompanyCreationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
    </>
  );
};
