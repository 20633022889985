import { gql } from '@apollo/client';
export const GET_STATISTICS = gql`
  query getStatistics(
    $query: [CalculationQueryRequest]
    $activeOrganisation: String
  ) {
    getStatistics(query: $query, activeOrganisation: $activeOrganisation) {
      finance {
        totalLabourCost {
          currency
          totalLabourCost
        }
        projectBreakdown {
          projectId
          projectName
          currency
          totalLabourCost
        }
      }
      projects {
        activeCount
        draftCount
        disabledCount
        activeProjects {
          id
          name
          colour
          startDate
          endDate
          assessmentsAwaitingOutcomeCount
          assessmentsNotStartedCount
          missingAssessmentCount
          missingCertificationCount
          expiredCertificationCount
          employeeMissingRatesCount
          subcontractorMissingRatesCount
          companiesMissingCertificationsCount
          companiesExpiredCertificationsCount
          companiesExpiringCertificationsCount
        }
        projectRoles {
          projectId
          projectName
          roles {
            roleName
            numberOfPeople
          }
        }
        activeStatistics {
          notStartedCount
          inProgressCount
          completedCount
        }
        disabledStatistics {
          notStartedCount
          inProgressCount
          completedCount
        }
      }

      labour {
        subcontractorCount
        employeeCount
        roleCount
        employeeStatusCount {
          enabled
          disabled
        }
        subcontractorStatusCount {
          enabled
          disabled
        }
      }

      progress {
        totalPersonHours
        totalHours
        latestOverallProgress
        progressByLocation {
          date
          progressByLocation {
            name
            latitude
            longitude
            progress
          }
        }
        totalHoursPerLocation {
          name
          latitude
          longitude
          hours
        }
        totalHoursPerActivityType {
          name
          hours
        }
        totalPersonHoursPerActivityType {
          name
          hours
        }
        totalPersonHoursPerTeam {
          name
          hours
        }
        totalPersonHoursPerRole {
          name
          hours
        }
        performanceAgainstOutcome {
          outcomeId
          phaseName
          activityGroupName
          estimatedHours
          estimatedPersonHours
          actualHours
          actualPersonHours
          count
          locations {
            name
          }
        }
        performanceAgainstOutcomeByLocation {
          outcomeId
          phaseName
          activityGroupName
          estimatedHours
          estimatedPersonHours
          actualHours
          actualPersonHours
          averageProgress
          count
          locationName
          latitude
          longitude
          locationId
        }
        roleHoursPerGroup {
          roleName
          groups {
            groupName
            hours
          }
        }
        activityTypePersonHoursPerDay {
          date
          activityTypeId
          activityTypeName
          totalHours
        }
      }
    }
  }
`;
