import { gql } from '@apollo/client';

export const SAVE_ATTACHMENT = gql`
  mutation saveAttachment(
    $activeOrganisation: String
    $input: SaveAttachmentInput
  ) {
    saveAttachment(activeOrganisation: $activeOrganisation, input: $input) {
      id
      success
      message
      presignedUrl
    }
  }
`;

export const DELETE_ASSESSMENT_ATTACHMENT = gql`
  mutation deleteAssessmentAttachment($activeOrganisation: String!, $id: ID) {
    deleteAssessmentAttachment(
      activeOrganisation: $activeOrganisation
      id: $id
    ) {
      id
      success
      message
    }
  }
`;

export const DELETE_PROGRESS_SUBMISSION_ATTACHMENT = gql`
  mutation deleteProgressSubmissionAttachment(
    $activeOrganisation: String!
    $id: ID
  ) {
    deleteProgressSubmissionAttachment(
      activeOrganisation: $activeOrganisation
      id: $id
    ) {
      id
      success
      message
    }
  }
`;

export const DELETE_CERTIFICATION_ATTACHMENT = gql`
  mutation deleteCertificationAttachment(
    $activeOrganisation: String!
    $id: ID
  ) {
    deleteCertificationAttachment(
      activeOrganisation: $activeOrganisation
      id: $id
    ) {
      id
      success
      message
    }
  }
`;
