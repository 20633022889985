import { useContext, useEffect, useState } from 'react';
import { GET_BANK_ACCOUNTS } from '../../../../graphql/queries/profiles';
import { useOrganisationAwareApollo } from '../../../../hooks/useOrganisationAwareApollo';

import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/Buttons/Button';
import {
  ActiveBadge,
  DisabledBadge,
} from '../../../../components/Badges/Badges';
import { UserProfileContext } from '../../../../context/UserProfileContext';
import { Permission } from '../../../../types/Permissions';
import moment from 'moment';

export const BankingDetailsPage = ({ labourResourceId }: any) => {
  const { useLazyQuery } = useOrganisationAwareApollo();
  const [fetch, { data, loading, error }] = useLazyQuery(GET_BANK_ACCOUNTS, {
    variables: { labourResourceId },
    fetchPolicy: 'network-only',
  });

  const navigate = useNavigate();

  const bankAccounts = data?.getBankAccounts?.results || [];
  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    fetch({ variables: { labourResourceId } });
  }, [labourResourceId]);

  const toggleExpand = (index: number) => {
    setExpanded((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const { userProfile } = useContext(UserProfileContext);

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      {loading && (
        <div className="h-64 flex items-center justify-center">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
        </div>
      )}
      {error && (
        <p className="px-4 py-5 sm:p-6 text-red-500">
          Error loading data. Please try again.
        </p>
      )}
      {userProfile?.permissions.includes(Permission.UpdateLabourProfiles) ? (
        <div className="flex w-full py-2 px-4 justify-end">
          <Button
            onClick={() =>
              navigate(
                `/labour/subcontractors/${labourResourceId}/bank-accounts/new`
              )
            }
            type="submit"
            text="Add Bank Account"
          />
        </div>
      ) : null}
      {bankAccounts.length > 0 ? (
        <div>
          {bankAccounts.map((bankAccount: any, index: number) => (
            <div key={index} className="border-t border-gray-200">
              <div
                className="px-4 py-4 cursor-pointer flex gap-x-2 items-center w-full justify-between"
                onClick={() => toggleExpand(index)}
              >
                <div>
                  <h2 className="text-lg font-medium text-gray-900">
                    {bankAccount.bankName}{' '}
                    {bankAccount?.enabled ? <ActiveBadge /> : <DisabledBadge />}
                  </h2>
                  <p className="mt-1 text-sm text-gray-500">
                    {bankAccount.currency || 'N/A'}
                  </p>
                  <p className="mt-1 text-sm text-gray-500">
                    {bankAccount.accountNumber} / {bankAccount.sortCode}
                  </p>
                  <p className="mt-1 text-xs text-gray-500">
                    Created:{' '}
                    {moment.unix(bankAccount?.createdAt / 1000).fromNow()}
                  </p>
                  <p className="mt-1 text-xs text-gray-500">
                    Updated:{' '}
                    {moment.unix(bankAccount?.updatedAt / 1000).fromNow()}
                  </p>
                </div>
                {userProfile?.permissions.includes(
                  Permission.UpdateLabourProfiles
                ) ? (
                  <div>
                    <Button
                      onClick={() =>
                        navigate(
                          `/labour/subcontractors/${labourResourceId}/bank-accounts/${bankAccount?.id}/edit`
                        )
                      }
                      type="submit"
                      text="Edit"
                    />
                  </div>
                ) : null}
              </div>
              {expanded[index] && (
                <div className="border-t border-gray-200">
                  <div className="px-4 py-2">
                    <h3 className="text-md font-medium text-gray-600">
                      Bank Name
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      {bankAccount.bankName || 'N/A'}
                    </p>
                  </div>
                  <div className="px-4 py-2">
                    <h3 className="text-md font-medium text-gray-600">
                      Name on Account
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      {bankAccount.nameOnAccount || 'N/A'}
                    </p>
                  </div>
                  <div className="px-4 py-2">
                    <h3 className="text-md font-medium text-gray-600">
                      Currency
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      {bankAccount.currency || 'N/A'}
                    </p>
                  </div>
                  <div className="px-4 py-2">
                    <h3 className="text-md font-medium text-gray-600">
                      Account Number
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      {bankAccount.accountNumber || 'N/A'}
                    </p>
                  </div>
                  <div className="px-4 py-2">
                    <h3 className="text-md font-medium text-gray-600">
                      Sort Code
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      {bankAccount.sortCode || 'N/A'}
                    </p>
                  </div>
                  <div className="px-4 py-2">
                    <h3 className="text-md font-medium text-gray-600">IBAN</h3>
                    <p className="mt-1 text-sm text-gray-500">
                      {bankAccount.iban || 'N/A'}
                    </p>
                  </div>
                  <div className="px-4 py-2">
                    <h3 className="text-md font-medium text-gray-600">BIC</h3>
                    <p className="mt-1 text-sm text-gray-500">
                      {bankAccount.bic || 'N/A'}
                    </p>
                  </div>
                  <div className="px-4 py-2">
                    <h3 className="text-md font-medium text-gray-600">Type</h3>
                    <p className="mt-1 text-sm text-gray-500">
                      {bankAccount.isBusinessAccount ? 'Business' : 'Personal'}
                    </p>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <p className="px-4 py-5 sm:p-6 ">No banking details available</p>
      )}
    </div>
  );
};

export const BankingDetails = ({ labourResourceId }: any) => (
  <BankingDetailsPage labourResourceId={labourResourceId} />
);
