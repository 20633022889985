import { useContext, useEffect, useState } from 'react';
import { Table } from '../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../context/PaginationContext';
import { useNavigate } from 'react-router-dom';
import { UserProfileContext } from '../../context/UserProfileContext';
import { Permission } from '../../types/Permissions';
import { Button } from '../../components/Buttons/Button';
import { BlueBadge, YellowBadge } from '../../components/Badges/Badges';
import useCompanies from '../../hooks/api/companies/useCompanies';
import NotificationIcon from '../../components/Notification/NotificationIcon';

interface CompanyListProps {
  showNewItemButton?: boolean;
  children?: React.ReactElement;
  filterIds?: string[];
  onClickRow?: (id: string, results: any[]) => void;
}

const headerMapping = {
  companyName: 'Company Name',
  owner: 'Company Owner',
  companyEmail: 'Email',
  contactName: 'Contact Name',
  complianceIssues: 'Compliance Warning',
  companyPhoneNumber: 'Phone Number',
  companyRegNumber: 'Reg Number',
} as Record<string, any>;

export const CompaniesListPage = ({
  filterIds = [],
  onClickRow,
}: CompanyListProps) => {
  const [filterText, setFilterText] = useState<string>();
  const [selectedFilterDimension, setSelectedFilterDimension] =
    useState<string>('companyName');

  const { limit, offset, setOffset } = usePagination();

  const [debouncedFilterText, setDebouncedSearchTerm] = useState(filterText);

  const filter = debouncedFilterText
    ? {
        filters: [
          {
            field: selectedFilterDimension,
            stringFilters: [{ ilike: debouncedFilterText }],
          },
        ],
      }
    : null;

  const [companies, { count }, fetch] = useCompanies({ filter, limit, offset });

  useEffect(() => {
    const handler = setTimeout(() => {
      setOffset(0);
      setDebouncedSearchTerm(filterText);
    }, 600);
    return () => {
      clearTimeout(handler);
    };
  }, [filterText, setOffset]);

  const totalPages = Math.ceil(count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };
  const mapHeader = (header: string) => headerMapping[header] || header;

  const navigate = useNavigate();

  const { userProfile } = useContext(UserProfileContext);

  return (
    <>
      <div className="w-full flex justify-end py-2 ">
        {userProfile?.permissions?.includes(Permission.UpdateLabourProfiles) ? (
          <Button
            text="New Company"
            onClick={() => navigate(`/companies/new`)}
          />
        ) : null}
      </div>
      <Table
        currentPage={Math.floor(offset / limit) + 1}
        totalPages={totalPages}
        itemsPerPage={limit}
        totalResults={count || 0}
        filterText={filterText}
        setFilterText={setFilterText}
        showFilterOptions={true}
        filterDimensions={[
          { id: 'companyName', name: 'Company Name' },
          { id: 'companyRegNumber', name: 'Reg Number' },
        ]}
        columnsToFilter={['id']}
        headerMapper={mapHeader}
        onClickRow={(id: any) => {
          onClickRow ? onClickRow(id, companies) : navigate(`/companies/${id}`);
        }}
        selectedFilterDimension={selectedFilterDimension}
        setSelectedFilterDimension={setSelectedFilterDimension}
        data={
          companies.length > 0
            ? companies
                ?.filter((result: any) => !filterIds?.includes(result.id))
                .map((result: any) => ({
                  id: result.id,
                  companyName: result.companyName,
                  companyRegNumber: result.companyRegNumber,
                  companyEmail: result.companyEmail,
                  contactName: result.contactName,
                  complianceIssues:
                    result?.missingOrInvalidCertifications?.length > 0 ? (
                      <NotificationIcon />
                    ) : (
                      ''
                    ),
                  owner: result?.profileId ? (
                    <BlueBadge text="Has Owner" />
                  ) : (
                    <YellowBadge text="No Owner" />
                  ),
                }))
            : [{}]
        }
        onPageChange={handlePageChange}
        fetchPageOfDataForExport={async (limit: number, pageOffset: number) => {
          const { data } = await fetch({
            variables: {
              input: {
                limit,
                offset: pageOffset,
                ...(filter ? { filter } : {}),
              },
            },
          });
          setOffset(pageOffset);
          return data?.getCompanyProfiles?.results ?? [];
        }}
      />
    </>
  );
};

export const CompaniesList = ({
  onClickRow,
  showNewItemButton = true,
  filterIds = [],
}: CompanyListProps) => (
  <PaginationProvider>
    <CompaniesListPage
      onClickRow={onClickRow}
      showNewItemButton={showNewItemButton}
      filterIds={filterIds}
    />
  </PaginationProvider>
);
