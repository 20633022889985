import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Buttons/Button';
import { Table } from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';
import { UserProfileContext } from '../../../context/UserProfileContext';

import { GET_SKILL_TYPES_SUMMARY } from '../../../graphql/queries/skills';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { Permission } from '../../../types/Permissions';
import { ActiveBadge, DisabledBadge } from '../../../components/Badges/Badges';

interface SkillTypeListProps {
  showNewItemButton?: boolean;
  children?: React.ReactElement;
  filterIds?: string[];
  onClickRow?: (id: string, results: any[]) => void;
}

const headerMapping = {
  name: 'Name',
  email: 'Email',
  enabled: 'Enabled',
  updatedAt: 'Updated At',
} as Record<string, any>;

const SkillTypesTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
  filterText,
  setFilterText = () => {},
  selectedFilterDimension,
  setSelectedFilterDimension = () => {},
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    columnsToFilter={['firstName', 'lastName', 'id', 'status']}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    filterDimensions={[{ id: 'name', name: 'Name' }]}
    showFilterOptions
    filterText={filterText}
    setFilterText={setFilterText}
    selectedFilterDimension={selectedFilterDimension}
    setSelectedFilterDimension={setSelectedFilterDimension}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
  />
);

export const SkillTypeListPage = ({
  children,
  showNewItemButton,
  onClickRow,
  filterIds = [],
}: SkillTypeListProps) => {
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [filterText, setFilterText] = useState<string>();
  const [selectedFilterDimension, setSelectedFilterDimension] =
    useState<string>('name');

  const { limit, offset, setOffset } = usePagination();

  const [debouncedFilterText, setDebouncedSearchTerm] = useState(filterText);

  const filter = debouncedFilterText
    ? {
        filters: [
          {
            field: selectedFilterDimension,
            stringFilters: [{ ilike: debouncedFilterText }],
          },
        ],
      }
    : null;

  const [fetch, { data }] = useLazyQuery(GET_SKILL_TYPES_SUMMARY, {
    variables: { input: { limit, offset } },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetch({
      variables: { input: { limit, offset, ...(filter ? { filter } : {}) } },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, debouncedFilterText]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setOffset(0);
      setDebouncedSearchTerm(filterText);
    }, 600);
    return () => {
      clearTimeout(handler);
    };
  }, [filterText]);

  const skilltypeResults = data?.getSkillTypes;

  const totalPages = Math.ceil(skilltypeResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const { userProfile } = useContext(UserProfileContext);

  return (
    <div>
      <>
        {showNewItemButton ? (
          <div className="w-full flex justify-between items-center py-2">
            <h1 className="text-xl font-bold py-4">Skill Types</h1>
            {userProfile?.permissions.includes(
              Permission.UpdateLabourProfiles
            ) ? (
              <Button
                text="New Skill Type"
                onClick={() => navigate(`/labour/skills/new`)}
              />
            ) : null}
          </div>
        ) : null}
        <p className="text-sm py-2 text-gray-600">
          Track skills across your labour pool here. Add skill types you are
          interested in tracking.
        </p>
        <SkillTypesTable
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          totalResults={skilltypeResults?.count || 0}
          onClickRow={(id: any) => {
            if (onClickRow) {
              onClickRow(id, skilltypeResults?.results);
            } else {
              navigate(`/labour/skills/${id}`);
            }
          }}
          filterText={filterText}
          setFilterText={setFilterText}
          selectedFilterDimension={selectedFilterDimension}
          setSelectedFilterDimension={setSelectedFilterDimension}
          data={
            skilltypeResults?.results.length > 0
              ? skilltypeResults?.results
                  .filter((result: any) => !filterIds?.includes(result.id))
                  .map((result: any) => ({
                    name: result?.name,
                    ...result,
                    enabled: result?.enabled ? (
                      <ActiveBadge />
                    ) : (
                      <DisabledBadge />
                    ),
                    status: undefined,
                  }))
              : [{}]
          }
          onPageChange={handlePageChange}
        />
      </>
      {children}
    </div>
  );
};

export const SkillTypeList = ({
  onClickRow,
  showNewItemButton = true,
  filterIds = [],
}: SkillTypeListProps) => (
  <PaginationProvider>
    <SkillTypeListPage
      onClickRow={onClickRow}
      showNewItemButton={showNewItemButton}
      filterIds={filterIds}
    />
  </PaginationProvider>
);
