import { useState } from 'react';
import Switch from '../../../../components/Inputs/Switch';

export const ActivityCodeDisplay = ({
  activityCodes,
  showOnDefault = true,
}: any) => {
  const [showActivityCodes, setShowActivityCodes] = useState(showOnDefault);
  if (activityCodes.length <= 1) return null;

  return (
    <div className="bg-gray-50 rounded-sm border-t border-b">
      <div className="py-3 px-4">
        <Switch
          text="Show Activity Codes"
          enabled={showActivityCodes}
          handleChange={() => {
            setShowActivityCodes(!showActivityCodes);
          }}
        />
      </div>
      {showActivityCodes ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4 px-6 py-4">
          {activityCodes
            .filter((key: any) => key.value)
            .map((code: any, index: any) => (
              <div key={index} className="flex flex-row gap-x-2 items-center">
                <div
                  className="w-8 h-8 rounded-lg flex items-center justify-center"
                  style={{ background: code?.color }}
                >
                  <p className="text-white text-xs">{code?.value}</p>
                </div>
                <p className="text-sm">{code.label}</p>
              </div>
            ))}
        </div>
      ) : null}
    </div>
  );
};
