import { v4 } from 'uuid';

const DaysOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

interface CreateInitialEntryProps {
  id?: string;
  assigneeId?: string;
  assigneeName?: string;
  projectId?: string;
  projectName?: string;
  roleName?: string;
  days?: any[];
  unsaved?: boolean;
  customData?: Record<string, string>;
  timesheetConfig?: any;
  disabled?: boolean;
}

export const createInitialEntry = ({
  id,
  assigneeId = '',
  assigneeName = '',
  projectId = '',
  projectName = '',
  roleName = '',
  days = [],
  unsaved,
  customData,
  timesheetConfig,
  disabled,
}: CreateInitialEntryProps) => ({
  id: id || v4(),
  assigneeId,
  assigneeName,
  projectId,
  roleName,
  projectName,
  unsaved,
  activityCodes: timesheetConfig.activityCodes,
  customData,
  disabled,
  days:
    days && days.length > 0
      ? days.map((day) => ({
          ...day,
          codings: day?.codings.reduce(
            (acc: any, { key, value }: any) => ({
              ...acc,
              [key]: Number(value),
            }),
            {}
          ),
        }))
      : DaysOfWeek.map((day) => ({
          day,
          codings: {},
          ...(timesheetConfig.granularity === 'DAILY'
            ? { selectedCode: null }
            : {}),
        })),
});
