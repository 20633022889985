import { Question } from '../../types/QuestionTypes';
import ValidationMessage from '../Validation/ValidationMessage';

interface RadioGroupParams {
  value: any;
  question: Question;
  handleChange: (value: any) => void;
  validation?: any;
  classNames?: string;
  isDisabled?: boolean;
}

const RadioGroup = ({
  value,
  question,
  handleChange,
  validation,
  classNames,
  isDisabled,
}: RadioGroupParams) => {
  const { id, title, options } = question;

  return (
    <div className={classNames}>
      <label htmlFor={id} className="block text-base font-medium text-gray-700">
        {title ? title : null}
      </label>
      {validation ? <ValidationMessage message={validation} /> : null}

      <div className="my-2 flex flex-col md:flex-row items-start md:space-x-5">
        {options?.map((option) => (
          <label
            key={option.name}
            className="inline-flex gap-x-1 px-2 items-center cursor-pointer"
          >
            <input
              type="radio"
              id={id}
              name={title}
              value={value}
              onChange={() => handleChange(option.name)}
              disabled={isDisabled}
              checked={value === option.name}
              className="form-radio text-primary-500 focus:ring-primary-500"
            />
            {option.colour ? (
              <div
                className="w-4 h-4 rounded-full mr-4"
                style={{ backgroundColor: option.colour }}
              ></div>
            ) : null}
            <span>{option.name}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default RadioGroup;
