import React, { useContext } from 'react';
import {
  PaginationProvider,
  usePagination,
} from '../../../../context/PaginationContext';
import { useOrganisationAwareApollo } from '../../../../hooks/useOrganisationAwareApollo';
import { GET_ASSESSMENT_SUBMISSIONS_FOR_COMPANY } from '../../../../graphql/queries/assessments';
import { Table } from '../../../../components/Tables/tables/Table';
import NoData from '../../../../components/NoData/NoData';
import moment from 'moment';
import { RedBadge, YellowBadge } from '../../../../components/Badges/Badges';
import { SendAssessmentInput } from '../../../../components/assessments/SendAssessment';
import { SAVE_ASSESSMENT_SUBMISSION } from '../../../../graphql/mutations/assessments';
import { UserProfileContext } from '../../../../context/UserProfileContext';
import { Permission } from '../../../../types/Permissions';
import { useNavigate } from 'react-router-dom';

export type ProjectCompanyAssessmentListProps = {
  companyId: string;
  projectId: string;
};

const headerMapping = {
  name: 'Name',
  outcome: 'Outcome',
  createdAt: 'Created At',
  updatedAt: 'Updated At',
} as Record<string, any>;

export function PaginatedProjectCompanyAssessmentsList({
  companyId,
  projectId,
}: ProjectCompanyAssessmentListProps) {
  const navigate = useNavigate();
  const { userProfile } = useContext(UserProfileContext);
  const { useMutation, useQuery } = useOrganisationAwareApollo();
  const { limit, offset, setOffset } = usePagination();

  const [sendAssessment, { loading: isSending }] = useMutation(
    SAVE_ASSESSMENT_SUBMISSION
  );

  const { data, loading, refetch } = useQuery(
    GET_ASSESSMENT_SUBMISSIONS_FOR_COMPANY,
    {
      variables: {
        input: { limit, offset },
        projectId,
        companyId,
      },
      fetchPolicy: 'network-only',
    }
  );

  const count = data?.getAssessmentSubmissionsForCompany?.count;
  const results = data?.getAssessmentSubmissionsForCompany?.results;

  const totalPages = Math.ceil(count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const handleSendAssessment = async (assessmentTypeId: string) => {
    await sendAssessment({
      variables: {
        input: { assessmentType: assessmentTypeId, projectId, companyId },
      },
    });
    await refetch();
  };

  return (
    <div className="flex flex-col gap-y-4 py-4">
      {userProfile?.permissions?.includes(Permission.SendAssessments) ? (
        <div className="flex flex-col px-4 sm:px-6 py-4 gap-y-4">
          <h1 className="text-lg font-bold">Send Assessment</h1>
          <SendAssessmentInput
            sendAssessment={handleSendAssessment}
            isSending={isSending}
          />
        </div>
      ) : null}
      <div>
        <h1 className="text-lg font-bold px-4 sm:px-6 py-4">
          Sent Assessments
        </h1>
        <Table
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          totalResults={count || 0}
          headerMapper={(text: string) => headerMapping[text]}
          onClickRow={(id) =>
            navigate(
              `/projects/${projectId}/companies/${companyId}/assessments/${id}`
            )
          }
          data={results?.map((result: any) => ({
            id: result?.id,
            name: result?.assessmentType?.name,
            outcome:
              !result.responses && !result?.outcome ? (
                <RedBadge text="Not Started Yet" />
              ) : !result?.outcome ? (
                <YellowBadge text="No Outcome Decided Yet" />
              ) : (
                <strong>{result?.outcome}</strong>
              ),
            createdAt:
              result?.createdAt &&
              moment.unix(parseInt(result?.createdAt) / 1000).fromNow(),
            updatedAt:
              result?.updatedAt &&
              moment.unix(parseInt(result?.updatedAt) / 1000).fromNow(),
          }))}
          NoData={() => (
            <NoData loading={loading}>
              It looks like there are no assessments associated with this
              project and company.
            </NoData>
          )}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export const ProjectCompanyAssessmentsList = (
  props: ProjectCompanyAssessmentListProps
) => (
  <PaginationProvider>
    <PaginatedProjectCompanyAssessmentsList {...props} />
  </PaginationProvider>
);
