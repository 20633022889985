import { useLazyQuery } from '@apollo/client';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { getMyOrganisations } from '../graphql/queries/organisations';
import { Organisation } from '../types/Organisation';
import { AuthenticatedUserContext } from './AuthenticatedUserContext';
import { NetworkContext } from './NetworkContext';

interface ActiveOrganisationProviderProps {
  organisations: Organisation[] | undefined;
  activeOrganisation: Organisation | undefined;
  setActiveOrganisation:
    | React.Dispatch<React.SetStateAction<Organisation | undefined>>
    | undefined;
}

export const ActiveOrganisationContext =
  createContext<ActiveOrganisationProviderProps>({
    organisations: undefined,
    activeOrganisation: { companyName: '', id: '' },
    setActiveOrganisation: undefined,
  });

export const ActiveOrganisationProvider = ({ children }: any) => {
  const [activeOrganisation, setActiveOrganisation] = useState<
    Organisation | undefined
  >(JSON.parse(localStorage.getItem('activeOrganisation') || 'null'));

  const { isOffline } = useContext(NetworkContext);

  const { user } = useContext(AuthenticatedUserContext);

  const [fetchMyOrganisations, { data }] = useLazyQuery(getMyOrganisations);

  const organisations = useMemo(
    () =>
      isOffline
        ? JSON.parse(localStorage.getItem('activeOrganisation') || 'null')
        : data?.getMyOrganisations?.results || [],
    [data?.getMyOrganisations?.results, isOffline]
  );

  useEffect(() => {
    if (user.isAuthenticated && !isOffline) {
      fetchMyOrganisations();
    }
  }, [user.isAuthenticated, isOffline, fetchMyOrganisations]);

  useEffect(() => {
    if (organisations && organisations.length > 0) {
      const persistedActiveOrganisation = JSON.parse(
        localStorage.getItem('activeOrganisation') || 'null'
      );
      const foundOrganisation = organisations.find(
        (org: Organisation) => org?.id === persistedActiveOrganisation?.id
      );
      if (foundOrganisation) {
        setActiveOrganisation(foundOrganisation);
        localStorage.setItem(
          'activeOrganisation',
          JSON.stringify(foundOrganisation)
        );
      } else {
        setActiveOrganisation(organisations[0]);
        localStorage.setItem(
          'activeOrganisation',
          JSON.stringify(organisations[0])
        );
      }
    }
  }, [organisations, user]);

  useEffect(() => {
    if (activeOrganisation) {
      localStorage.setItem(
        'activeOrganisation',
        JSON.stringify(activeOrganisation)
      );
    }
  }, [activeOrganisation]);

  if (organisations) {
    return (
      <ActiveOrganisationContext.Provider
        value={{ organisations, activeOrganisation, setActiveOrganisation }}
      >
        {children}
      </ActiveOrganisationContext.Provider>
    );
  }

  return null;
};
