import { useEffect } from 'react';
import { GET_NEXT_OF_KIN_DETAILS } from '../../../graphql/queries/profiles';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';

export const NextOfKinDetailPage = ({ labourResourceId }: any) => {
  const { useLazyQuery } = useOrganisationAwareApollo();
  const [fetch, { data, loading, error }] = useLazyQuery(
    GET_NEXT_OF_KIN_DETAILS,
    {
      variables: { labourResourceId },
      fetchPolicy: 'network-only',
    }
  );

  // Just use the first for now
  const [nextofkindetail] = data?.getNextOfKinDetails?.results || [];

  const sections = [
    { title: 'Name', content: nextofkindetail?.name },
    { title: 'Relationship', content: nextofkindetail?.relationship },
    { title: 'Address', content: nextofkindetail?.address },
    { title: 'Contact Number', content: nextofkindetail?.contactNumber },
  ];

  useEffect(() => {
    fetch({ variables: { labourResourceId } });
  }, [labourResourceId]);

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      {loading && (
        <div className="h-64 flex items-center justify-center">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
        </div>
      )}
      {error && (
        <p className="px-4 py-5 sm:p-6 text-red-500">
          Error loading data. Please try again.
        </p>
      )}
      {nextofkindetail ? (
        <div className="border-t border-gray-200">
          {sections.map((section, index) => (
            <div key={index} className="px-4 py-4">
              <h2 className="text-lg font-medium text-gray-900">
                {section.title}
              </h2>
              <p className="mt-1 text-sm text-gray-500">
                {section.content ?? '(empty)'}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <p className="px-4 py-5 sm:p-6 ">No next of kin data available</p>
      )}
    </div>
  );
};

export const NextOfKinDetail = ({ labourResourceId }: any) => (
  <NextOfKinDetailPage labourResourceId={labourResourceId} />
);
