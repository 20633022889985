import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useEffect } from 'react';

import FormCard from '../../components/FormCard/FormCard';

import { SAVE_CONTRACT } from '../../graphql/mutations/contracts';

import { useOrganisationAwareApollo } from '../../hooks/useOrganisationAwareApollo';

import { GET_CONTRACTS } from '../../graphql/queries/contracts';
import { FormConfig } from '../../types/Form';
import { useToast } from '../../context/ToastContext';

export const SaveContract = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { useQuery, useMutation } = useOrganisationAwareApollo();

  const { addToast } = useToast();

  const isUpdate = !!id;

  const { data, refetch } = useQuery(GET_CONTRACTS, {
    variables: { input: { id } },
    skip: !isUpdate,
  });

  useEffect(() => {
    if (isUpdate) {
      refetch({ id });
    }
  }, [id, refetch, isUpdate]);

  useEffect(() => {
    if (isUpdate) {
      refetch({ id });
    }
  }, [id, refetch, isUpdate]);

  const [contract] = data?.getContracts?.results || [];

  const [saveContract, { loading }] = useMutation(SAVE_CONTRACT);

  const initialValues = id
    ? {
        ...contract,
      }
    : {};

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      const { data } = await saveContract({
        variables: {
          id: values?.id,
          input: {
            ...values,

            id: undefined,
            updatedAt: undefined,
            createdAt: undefined,
          },
        },
      });
      addToast('Contract saved', 'success');
      setSubmitting(false);
      if (data.saveContract.success) {
        navigate(`/labour/contracts`);
      }
    } catch (error) {
      console.error(error);
      addToast('Error saving contract', 'error');
    }
  };

  const contractConfig = {
    title: '',
    description: '',
    formSections: [
      {
        title: 'Contract',
        description: 'Basic details about the Contract',
        fields: [
          {
            title: 'Name',
            id: 'name',
            type: 'input',
            disabled: contract?.name,
            required: true,
          },
          {
            title: 'Enabled',
            id: 'enabled',
            type: 'switch',
          },
        ],
      },
    ],
  } as FormConfig;

  return (
    <FormCard
      key={`${contractConfig?.title}`}
      config={contractConfig}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
};
