import { useTabs } from '../../../hooks/useTabs';
import { Tabs } from '../../../components/Tabs/Tabs';
import { CompanyProfileDetailPage } from './CompanyProfileDetailPage';
import { CompanyAssessmentsPage } from './CompanyAssessmentsPage';

export const ViewCompanyProfile = () => {
  const tabs = ['Company Details', 'Assessments'];

  const [activeTab, setActiveTab] = useTabs(tabs);

  return (
    <>
      <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      {activeTab === 'Company Details' ? <CompanyProfileDetailPage /> : null}
      {activeTab === 'Assessments' ? <CompanyAssessmentsPage /> : null}
    </>
  );
};
