export function createAssigneesArray(results = []) {
  // Initialize an empty array to hold the assignees
  const assignees: any[] = [];

  // Loop through each assignment in the results array
  results.forEach((assignment) => {
    // Loop through each active role in the assignment
    // @ts-ignore
    assignment.assignmentRoleSummary.activeRoles.forEach((role: any) => {
      assignees.push({
        id: role.id,
        // @ts-ignore
        name: `${assignment.lrs.firstName} ${assignment.lrs.lastName}`,
        roleName: role.name,
        entryId: role?.timesheetEntry?.id,
        days: role?.timesheetEntry?.days,
        customData: role?.timesheetEntry?.customData,
      });
    });
    // Loop through each active role in the assignment
    // @ts-ignore
    assignment.assignmentRoleSummary.inactiveRoles.forEach((role: any) => {
      assignees.push({
        id: role.id,
        // @ts-ignore
        name: `${assignment.lrs.firstName} ${assignment.lrs.lastName}`,
        roleName: role.name,
        disabled: true,
        entryId: role?.timesheetEntry?.id,
        days: role?.timesheetEntry?.days,
        customData: role?.timesheetEntry?.customData,
      });
    });
  });

  return assignees;
}
