import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { baseBarColours } from '../home';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ProjectRolesChart = ({ data }) => {
  const projectRoles = data?.getStatistics?.projects?.projectRoles || [];
  const activeProjects = data?.getStatistics?.projects?.activeProjects || [];

  const uniqueRoles = [
    ...new Set(
      projectRoles?.flatMap((pr) => pr.roles?.map((role) => role.roleName))
    ),
  ];

  const isSingleProject = projectRoles.length === 1;

  const chartData = {
    labels: uniqueRoles,
    datasets: isSingleProject
      ? projectRoles[0].roles.map((role, index) => ({
          label: role.roleName,
          data: uniqueRoles.map((roleName) =>
            role.roleName === roleName ? role.numberOfPeople : 0
          ),
          backgroundColor:
            activeProjects[index]?.colour ||
            baseBarColours[index % baseBarColours.length],
          borderColor:
            activeProjects[index]?.colour ||
            baseBarColours[index % baseBarColours.length],
          borderWidth: 1,
        }))
      : projectRoles.map((projectRole, projectIndex) => ({
          label: projectRole.projectName,
          data: uniqueRoles.map((roleName) => {
            const role = projectRole.roles?.find(
              (r) => r.roleName === roleName
            );
            return role ? role.numberOfPeople : 0;
          }),
          backgroundColor:
            activeProjects[projectIndex]?.colour ||
            baseBarColours[projectIndex % baseBarColours.length],
          borderColor:
            activeProjects[projectIndex]?.colour ||
            baseBarColours[projectIndex % baseBarColours.length],
          borderWidth: 1,
        })),
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        beginAtZero: true,
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default ProjectRolesChart;
