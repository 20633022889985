import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/solid';
import 'chart.js/auto';
import { useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';

const ProgressChart = ({
  chartId,
  chartRefs = [],
  data = [],
  itemsPerPage = 30,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [hiddenStatus, setHiddenStatus] = useState({});

  const { dates, datasets } = useMemo(() => {
    if (!data || !Array.isArray(data))
      return { locations: [], dates: [], datasets: [] };

    const clonedData = [...data].sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );
    const locations = Array.from(
      new Set(
        clonedData.flatMap(
          (date) => date.progressByLocation?.map((loc) => loc.name) || []
        )
      )
    );
    const dates = clonedData
      .map((date) => date.date)
      .sort((a, b) => new Date(a) - new Date(b));
    const datasets = locations.map((location, index) => ({
      label: location,
      data: clonedData.map((date) => {
        const entry = date.progressByLocation?.find(
          (loc) => loc.name === location
        );
        return entry ? entry.progress : 0;
      }),
      backgroundColor: `hsl(${(index * 360) / locations.length}, 70%, 50%)`,
    }));

    return { locations, dates, datasets };
  }, [data]);

  const toggleDataset = (index) => {
    setHiddenStatus((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const chartData = useMemo(() => {
    return {
      labels: dates,
      datasets: datasets.map((ds, index) => ({
        ...ds,
        hidden: !!hiddenStatus[index], // Apply hidden status
      })),
    };
  }, [dates, datasets, hiddenStatus]);

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Progress (%)',
        },
      },
      x: {
        title: {
          display: true,
          text: 'Date',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  const paginatedItems = useMemo(() => {
    const start = currentPage * itemsPerPage;
    return datasets.slice(start, start + itemsPerPage).map((item, index) => ({
      ...item,
      actualIndex: start + index, // Track the actual index
    }));
  }, [currentPage, itemsPerPage, datasets]);

  const handlePrev = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 0));
  };

  const handleNext = () => {
    setCurrentPage((prev) =>
      prev + 1 < Math.ceil(datasets.length / itemsPerPage) ? prev + 1 : prev
    );
  };

  return (
    <div>
      <div className="w-full" style={{ height: '400px' }}>
        <Bar
          key={`${chartId}_key`}
          ref={chartRefs?.find((chartRef) => chartId === chartRef.id)?.ref}
          data={chartData}
          options={options}
        />
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
        {paginatedItems.map((item) => (
          <div
            key={item.actualIndex}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <div
              style={{
                width: 20,
                height: 20,
                backgroundColor: item.backgroundColor,
                marginRight: 10,
              }}
              onClick={() => toggleDataset(item.actualIndex)}
            ></div>
            <span
              onClick={() => toggleDataset(item.actualIndex)}
              style={
                hiddenStatus[item.actualIndex]
                  ? { textDecoration: 'line-through' }
                  : {}
              }
            >
              {item.label}
            </span>
          </div>
        ))}
        <div className="flex gap-x-2 py-2">
          <button onClick={handlePrev}>
            <ArrowLeftIcon className="w-5 h-5" />
          </button>
          <button onClick={handleNext}>
            <ArrowRightIcon className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProgressChart;
