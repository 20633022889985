import { useEffect } from 'react';
import { useOrganisationAwareApollo } from '../../useOrganisationAwareApollo';
import { ApiHookMeta } from '../types';
import { GET_COMPANY_PROFILES } from '../../../graphql/queries/companies';

export default function useCompanies({
  id,
  labourResourceId,
  filter,
  limit,
  offset,
  skip,
}: {
  id?: string;
  labourResourceId?: string;
  filter?: any;
  limit?: number;
  offset?: number;
  skip?: boolean;
}): [any, ApiHookMeta, any?] {
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [fetch, { data, loading, error, ...result }] = useLazyQuery(
    GET_COMPANY_PROFILES,
    {
      variables: { input: { id, limit, offset } },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    fetch({
      variables: {
        labourResourceId,
        input: {
          id,
          limit,
          offset,
          ...(filter ? { filter } : {}),
        },
        skip,
      },
    });
  }, [limit, offset, filter, fetch, id, labourResourceId, skip]);

  const projects = data?.getCompanyProfiles?.results || [];
  const count = data?.getCompanyProfiles?.count || 0;

  return [
    projects,
    {
      count,
      loading,
      error,
      ...result,
    },
    async (variables: any) => await fetch(variables),
  ];
}
