import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../../../components/Buttons/Button';
import { Table } from '../../../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../../../context/PaginationContext';

import { UserProfileContext } from '../../../../../context/UserProfileContext';

import { SAVE_ASSESSMENT_SUBMISSION } from '../../../../../graphql/mutations/assessments';
import {
  GET_ASSESSMENTS,
  GET_ASSESSMENT_TYPES_COUNT,
  SEARCH_ASSESSMENT_TYPES,
} from '../../../../../graphql/queries/assessments';
import { GET_PROJECT_ASSIGNMENT_ROLES } from '../../../../../graphql/queries/assignments';
import { useOrganisationAwareApollo } from '../../../../../hooks/useOrganisationAwareApollo';
import { Permission } from '../../../../../types/Permissions';
import { AssessmentSelection } from './AssessmentSelection';
import { RedBadge, YellowBadge } from '../../../../../components/Badges/Badges';
import NoAssessments from '../../../../../components/NoData/NoAssessments';

const headerMapping = {
  assessmentName: 'Assessment Name',
  configure: 'Options',
  outcome: 'Outcome',
  createdAt: 'Created At',
  updatedAt: 'Updated At',
} as Record<string, any>;

const SentAssessmentsTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    columnsToFilter={['id']}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
      createdAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
  />
);

export const SentAssessmentsListPage = () => {
  const navigate = useNavigate();

  const { assignmentRoleId } = useParams();
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const { limit, offset, setOffset } = usePagination();
  const { userProfile } = useContext(UserProfileContext);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAssessment, setSelectedAssessment] = useState<any>(undefined);

  const [fetch, { data }] = useLazyQuery(GET_PROJECT_ASSIGNMENT_ROLES, {
    variables: { input: { id: assignmentRoleId } },
    fetchPolicy: 'network-only',
  });

  const [
    fetchAssessmentTypesCount,
    { data: assessmentTypesCountData, loading: loadingAssessmentTypesCount },
  ] = useLazyQuery(GET_ASSESSMENT_TYPES_COUNT, {
    variables: {},
    fetchPolicy: 'network-only',
  });

  const [fetchAssessments, { data: assessmentsData }] = useLazyQuery(
    SEARCH_ASSESSMENT_TYPES,
    {
      variables: { searchTerm: debouncedSearchTerm },
      fetchPolicy: 'network-only',
    }
  );

  const [assignmentData] = data?.getAssignmentRoles?.results || [];
  const assessmentSearchResults =
    assessmentsData?.searchAssessmentTypes?.results || [];
  const assessmentTypesCount =
    assessmentTypesCountData?.getAssessmentTypes?.count || 0;

  const [fetchAssessmentSubmissions, { data: assessmentSubmissionsData }] =
    useLazyQuery(GET_ASSESSMENTS, {
      variables: {
        labourResourceId: assignmentData?.assignment?.lrs?.id,
        assignmentRoleId,
      },
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    fetch({
      variables: { labourResourceId: assignmentData?.assignment?.lrs?.id },
    });
    fetchAssessmentSubmissions({
      variables: { assignmentRoleId, input: { limit, offset } },
    });
    fetchAssessmentTypesCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentRoleId, limit, offset]);

  const assessmentSubmissionsResults =
    assessmentSubmissionsData?.getAssessmentSubmissions?.results || [];

  const totalPages = Math.ceil(
    assessmentSubmissionsData?.getAssessmentSubmissions?.count / limit
  );

  const [sendAssessment, { loading: sending }] = useMutation(
    SAVE_ASSESSMENT_SUBMISSION
  );

  const performSearch = async () => {
    try {
      await fetchAssessments({
        variables: {
          searchTerm: debouncedSearchTerm,
          input: { limit, offset },
        },
      });
    } catch (err) {
      // do nothing
    } finally {
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 600); // 300ms debounce time
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    performSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, limit, offset]);

  return (
    <div className="bg-gray-50">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="py-2 border-b">
          <Button
            style={{
              backgroundColor: 'transparent',
              color: 'gray',
              boxShadow: 'none',
              borderRadius: 0,
            }}
            onClick={() => navigate(-1)}
            type="submit"
            text={`< Back`}
          />
        </div>

        <div className="flex flex-col md:items-center md:flex-row w-full md:justify-between px-4 py-4 sm:px-6 flex-wrap">
          <div className="flex flex-col">
            <h1 className="text-3xl font-extrabold text-gray-900">
              {assignmentData?.assignment?.lrs?.lastName},{' '}
              {assignmentData?.assignment?.lrs?.firstName}
            </h1>
            <p className="mt-1 text-lg text-gray-900">
              Role: {assignmentData?.role?.name}
            </p>
            <p className="mt-1 text-base text-gray-500">
              Project: {assignmentData?.assignment?.project?.name} (
              {assignmentData?.assignment?.project?.internalId})
            </p>
          </div>
        </div>

        <div className="border-t border-gray-200 px-4">
          {assessmentTypesCount > 0 ? (
            <>
              {userProfile?.permissions?.includes(
                Permission.SendAssessments
              ) ? (
                <h1 className="text-lg font-bold py-4">Send Assessment</h1>
              ) : null}
              <div className="flex flex-row items-center w-full gap-x-2">
                <AssessmentSelection
                  assessmentSearchResults={assessmentSearchResults}
                  setSearchTerm={setSearchTerm}
                  setSelectedAssessment={setSelectedAssessment}
                  selectedAssessment={selectedAssessment}
                />

                {userProfile?.permissions?.includes(
                  Permission.SendAssessments
                ) ? (
                  <Button
                    style={{ marginTop: '3px' }}
                    text="Send Assessment"
                    isLoading={sending}
                    isDisabled={!selectedAssessment}
                    onClick={async () => {
                      if (selectedAssessment?.id && assignmentRoleId) {
                        await sendAssessment({
                          variables: {
                            input: {
                              labourResourceId:
                                assignmentData?.assignment?.lrs?.id,
                              assessmentType: selectedAssessment?.id,
                              assignmentRoleId: assignmentRoleId,
                            },
                          },
                        });
                        await fetchAssessmentSubmissions();
                      }
                    }}
                  />
                ) : null}
              </div>
            </>
          ) : (
            <NoAssessments loading={loadingAssessmentTypesCount} />
          )}

          <hr className="my-4" />
          <div className="w-full flex justify-between items-center py-2">
            <h1 className="text-lg font-bold py-4">Sent Assessments</h1>
            <div className="flex flex-row gap-x-2"></div>
          </div>
          <SentAssessmentsTable
            currentPage={Math.floor(offset / limit) + 1}
            totalPages={totalPages}
            itemsPerPage={limit}
            totalResults={
              assessmentSubmissionsData?.getAssessmentSubmissions?.count || 0
            }
            onClickRow={(id: any) =>
              navigate(
                `/projects/${assignmentData?.assignment?.project?.id}/assignments/roles/${assignmentRoleId}/assessments/${id}`
              )
            }
            data={
              assessmentSubmissionsResults.length > 0
                ? assessmentSubmissionsResults.map((result: any) => ({
                    id: result?.id,
                    assessmentName: result?.assessmentType?.name,
                    outcome:
                      !result.responses && !result?.outcome ? (
                        <RedBadge text="Not Started Yet" />
                      ) : !result?.outcome ? (
                        <YellowBadge text="No Outcome Decided Yet" />
                      ) : (
                        <strong>{result?.outcome}</strong>
                      ),
                    createdAt: result?.createdAt,
                    updatedAt: result?.updatedAt,
                    assessmentType: undefined,
                    assignmentRole: undefined,
                  }))
                : [{}]
            }
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export const SentAssessmentsList = () => (
  <PaginationProvider>
    <SentAssessmentsListPage />
  </PaginationProvider>
);
