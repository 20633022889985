import {
  ArrowLeftIcon,
  PaperAirplaneIcon,
  PlayIcon,
} from '@heroicons/react/20/solid';
import {
  BookmarkIcon,
  CheckCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import { Button } from '../../../components/Buttons/Button';
import { useToast } from '../../../context/ToastContext';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

export const Navigation = ({
  currentPage,
  totalPages,
  isSaving,
  isValid,
  submitForm,
  handlePageChange,
  values,
  handleSubmit,
  navigate,
  isSubmitted,
  isApproved,
  canApproveTimesheet,
  canUnapproveTimesheet,
  handleApproveTimesheet,
  submissionId,
  refetch,
}: any) => {
  const { addToast } = useToast();

  return (
    <>
      {totalPages > 1 ? (
        <div className="flex items-center">
          <progress
            className="[&::-webkit-progress-bar]:rounded-lg [&::-webkit-progress-value]:rounded-lg  [&::-webkit-progress-bar]:bg-gray-300 [&::-webkit-progress-value]:bg-green-600 [&::-moz-progress-bar]:bg-green-600"
            value={currentPage}
            max={totalPages}
          ></progress>
          <span className="ml-2 text-sm">
            Page {currentPage} of {totalPages}
          </span>
        </div>
      ) : null}
      <nav aria-label="Section navigation">
        {isValid &&
        !isSubmitted &&
        values?.entries &&
        values.entries.length > 0 ? (
          <p className="text-sm text-gray-800 py-4 flex items-center gap-x-1">
            <InformationCircleIcon className="w-4 h-4" />{' '}
            <p>
              Remember that{' '}
              <strong className="text-orange-800">Save for Later</strong> will
              not submit the timesheet for approval.
            </p>
          </p>
        ) : null}

        <div className="flex w-full flex-wrap md:flex-nowrap items-center md:justify-end gap-y-2 gap-x-2">
          {!(currentPage <= 1) ? (
            <Button
              style={{ background: 'black' }}
              onClick={() => handlePageChange(currentPage - 1)}
              text={
                <div className="flex justify-center gap-x-2 items-center">
                  <ArrowLeftIcon
                    className="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                  {`Go Back`}
                </div>
              }
            />
          ) : null}

          {!(totalPages === 0 || currentPage === totalPages) ? (
            <>
              {isApproved ? null : (
                <Button
                  isLoading={isSaving}
                  isDisabled={isSaving || !isValid}
                  onClick={async () => {
                    await submitForm();
                    handlePageChange(currentPage + 1);
                  }}
                  text={
                    <div className="flex justify-center gap-x-2 items-center">
                      <BookmarkIcon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      {`Save & Continue`}
                    </div>
                  }
                />
              )}
              <Button
                isLoading={isSaving}
                style={{ backgroundColor: 'black' }}
                isDisabled={isSaving || !isValid}
                onClick={async () => {
                  handlePageChange(currentPage + 1);
                }}
                text={
                  <div className="flex justify-center gap-x-2 items-center">
                    <PlayIcon
                      className="h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                    {`Continue`}
                  </div>
                }
              />
            </>
          ) : values?.entries && values.entries.length > 0 ? (
            <>
              {isValid && !isApproved ? (
                <Button
                  isLoading={isSaving}
                  style={{ background: 'orange' }}
                  isDisabled={isSaving || !isValid}
                  onClick={async () => {
                    await submitForm();
                  }}
                  text={
                    <div className="flex justify-center gap-x-2 items-center">
                      <BookmarkIcon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      {isSubmitted ? `Save` : `Save for later`}
                    </div>
                  }
                />
              ) : null}

              {!isSubmitted ? (
                <Button
                  isLoading={isSaving}
                  isDisabled={isSaving || !isValid}
                  onClick={async () => {
                    await handleSubmit({ ...values }, true);
                    navigate(`/time/timesheets`);
                  }}
                  text={
                    <div className="flex justify-center gap-x-2 items-center">
                      <PaperAirplaneIcon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      {`Submit`}
                    </div>
                  }
                />
              ) : null}

              {submissionId &&
              isSubmitted &&
              !isApproved &&
              canApproveTimesheet ? (
                <Button
                  isLoading={isSaving}
                  isDisabled={isSaving || !isValid}
                  onClick={async () => {
                    try {
                      await handleApproveTimesheet({
                        variables: {
                          input: { approved: true },
                          id: submissionId,
                        },
                      });
                      await refetch();
                      addToast('Timesheet approved successfully', 'success');
                    } catch (err) {
                      addToast('Error approving timesheet', 'error');
                    }
                  }}
                  text={
                    <div className="flex justify-center gap-x-2 items-center">
                      <CheckCircleIcon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      {`Approve`}
                    </div>
                  }
                />
              ) : null}
              {submissionId &&
              isSubmitted &&
              isApproved &&
              canUnapproveTimesheet ? (
                <Button
                  isLoading={isSaving}
                  isDisabled={isSaving || !isValid}
                  style={{ backgroundColor: 'black' }}
                  onClick={async () => {
                    try {
                      await handleApproveTimesheet({
                        variables: {
                          input: { approved: false },
                          id: submissionId,
                        },
                      });
                      await refetch();
                      addToast('Timesheet unapproved successfully', 'success');
                    } catch (err) {
                      addToast('Error unapproving timesheet', 'error');
                    }
                  }}
                  text={
                    <div className="flex justify-center gap-x-2 items-center">
                      <XCircleIcon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      {`Unapprove`}
                    </div>
                  }
                />
              ) : null}
            </>
          ) : null}
        </div>
      </nav>
    </>
  );
};
