import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useEffect } from 'react';

import FormCard from '../../../components/FormCard/FormCard';

import { SAVE_PROGRESS_ACTIVITY_CATEGORY } from '../../../graphql/mutations/progress-activity-submissions';
import { GET_PROGRESS_ACTIVITY_CATEGORIES } from '../../../graphql/queries/progress-activity-submissions';
import { GET_PROJECT_ACTIVITY_TYPES } from '../../../graphql/queries/projects';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { FormConfig } from '../../../types/Form';
import { useToast } from '../../../context/ToastContext';

export const SaveProgressActivityCategory = () => {
  let { id, projectId } = useParams();
  const navigate = useNavigate();
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const { addToast } = useToast();

  const isUpdate = !!id;

  const [fetchProgressActivityCategorys, { data }] = useLazyQuery(
    GET_PROGRESS_ACTIVITY_CATEGORIES,
    {
      variables: { input: { id } },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (isUpdate) {
      fetchProgressActivityCategorys({
        variables: { input: { id }, projectId },
      });
    }
  }, [id, isUpdate]);

  const [ProgressActivityCategory] =
    data?.getProgressActivityCategories?.results || [];

  const [fetchProjectActivityTypes, { data: patData }] = useLazyQuery(
    GET_PROJECT_ACTIVITY_TYPES,
    {
      variables: { projectId, onlyUnused: true },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    fetchProjectActivityTypes({ variables: { projectId } });
  }, [projectId]);

  const [saveProgressActivityCategory] = useMutation(
    SAVE_PROGRESS_ACTIVITY_CATEGORY
  );

  const initialValues = id
    ? {
        id,
        name: ProgressActivityCategory?.name,
      }
    : { name: undefined, description: undefined };

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      await saveProgressActivityCategory({
        variables: {
          id: values?.id,
          input: {
            ...values,
            id: undefined,
            updatedAt: undefined,
            createdAt: undefined,
          },
        },
      });
      addToast('Activity saved', 'success');
      if (setSubmitting) {
        setSubmitting(false);
      }
      navigate(`/progress/categories`);
    } catch (error) {
      console.error(error);
      addToast('Error saving activity', 'error');
    }
  };

  const ProgressActivityCategoryConfig = {
    formSections: [
      {
        title: 'Activity Group',
        description: 'Basic details about the activity category',
        fields: [
          {
            id: 'name',
            type: 'input',
            title: 'Activity Category Name',
            placeholder: 'Some activity category name',
            inputTransformer: (text: string) => text,
          },
        ],
      },
    ],
  } as FormConfig;

  const ProgressActivityCategoryCreationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required(),
  });

  return (
    <FormCard
      key={`${ProgressActivityCategoryConfig?.title}`}
      config={ProgressActivityCategoryConfig}
      validationSchema={ProgressActivityCategoryCreationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
};
