import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_ACTIVITY_CODES } from '../../../graphql/queries/activity-codes';
import { GET_CUSTOM_OPTIONS } from '../../../graphql/queries/custom-data';
import { GET_TIMESHEET_CONFIGURATIONS } from '../../../graphql/queries/timesheet-configurations';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { PaginatedTimesheetForm } from './form';
import moment from 'moment';
import { GET_MY_TIMESHEET_SUBMISSION } from '../../../graphql/queries/timesheet-submissions';

export const SaveTimesheet: React.FC = () => {
  let { configurationId, weekId } = useParams();

  const navigate = useNavigate();

  const { useLazyQuery, useQuery } = useOrganisationAwareApollo();

  const isUpdate = !!configurationId;

  const [selectedProject, setSelectedProject] = useState<any>({});

  const { data: timesheetSubmissionResult, refetch: fetchTimesheetSubmission } =
    useQuery(GET_MY_TIMESHEET_SUBMISSION, {
      variables: {
        configurationId,
        weekId,
        ...(selectedProject?.id ? { projectId: selectedProject?.id } : {}),
      },
      fetchPolicy: 'network-only',
    });

  const [fetchTimesheetConfigurations, { data }] = useLazyQuery(
    GET_TIMESHEET_CONFIGURATIONS,
    {
      variables: { input: { id: configurationId } },
      fetchPolicy: 'network-only',
    }
  );

  const [fetchActivityCodes, { data: activityCodes }] = useLazyQuery(
    GET_ACTIVITY_CODES,
    {
      variables: {},
      fetchPolicy: 'network-only',
    }
  );

  const [fetchCustomOptions, { data: customOptions }] = useLazyQuery(
    GET_CUSTOM_OPTIONS,
    {
      variables: {},
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    fetchTimesheetSubmission();
  }, [
    selectedProject.id,
    configurationId,
    weekId,
    fetchTimesheetSubmission,
    timesheetSubmissionResult,
  ]);

  useEffect(() => {
    if (isUpdate) {
      fetchTimesheetConfigurations({ variables: { id: configurationId } });
      fetchActivityCodes();
      fetchCustomOptions();
    }
  }, [
    configurationId,
    fetchActivityCodes,
    fetchCustomOptions,
    fetchTimesheetConfigurations,
    isUpdate,
    weekId,
  ]);

  const [yearPart, weekPart] = weekId?.split('-') || [];
  const year = parseInt(yearPart?.replace('Y', ''));
  const week = parseInt(weekPart?.replace('W', ''));
  const [selectedDate, setSelectedDate] = useState(
    moment().year(year).week(week).startOf('week').toDate()
  );

  const [timesheetSubmission] =
    timesheetSubmissionResult?.getTimesheetSubmissionByWeekIdAndConfig
      ?.results || [];

  const activityCodeResults = activityCodes?.getActivityCodes?.results || [];
  const customOptionsResults =
    customOptions?.getCustomOptionSets?.results || [];

  const [timesheetConfig] = data?.getTimesheetConfigurations?.results || [];

  const filteredActivityCodes = activityCodeResults?.filter(
    (code: any) => code.enabled
  );

  const isValidWeekId = (id?: string) =>
    id ? /^Y\d{4}-W\d{1,2}$/.test(id) : false;

  const updateUrlWithWeekId = useCallback(
    (year: any, week: any) => {
      const newWeekId = `Y${year}-W${week.toString().padStart(2, '0')}`;
      navigate(`/time/timesheets/${configurationId}/${newWeekId}`);
    },
    [configurationId, navigate]
  );

  useEffect(() => {
    if (!isValidWeekId(weekId)) {
      const currentYear = moment().year();
      const currentWeek = moment().week();
      updateUrlWithWeekId(currentYear, currentWeek);
    }
  }, [updateUrlWithWeekId, weekId]);

  useEffect(() => {
    const year = moment(selectedDate).year();
    const week = moment(selectedDate).week();
    updateUrlWithWeekId(year, week);
  }, [selectedDate, updateUrlWithWeekId]);

  return (
    <div>
      <PaginatedTimesheetForm
        selectedDate={selectedDate}
        selectedProject={selectedProject}
        setSelectedProject={setSelectedProject}
        setSelectedDate={setSelectedDate}
        configurationId={configurationId || ''}
        weekId={weekId || timesheetSubmission?.weekId}
        labourResource={timesheetSubmission?.lrs}
        timesheetConfig={{
          name: timesheetConfig?.name,
          description: timesheetConfig?.description,
          granularity: timesheetConfig?.granularity,
          trackOwnTime: timesheetConfig?.type === 'SELF_REPORTED',
          activityCodes: timesheetConfig?.activityCodes
            ? [
                ...timesheetConfig?.activityCodes?.map((code: string) => {
                  const matchingCode = filteredActivityCodes?.find(
                    (activityCode: any) => activityCode.id === code
                  );
                  return {
                    id: matchingCode?.id,
                    value: matchingCode?.code,
                    label: matchingCode?.name,
                    color: matchingCode?.color,
                  };
                }),
              ]
            : [],
        }}
        timesheetApproval={timesheetSubmission}
        multiProject={timesheetConfig?.type === 'SELF_REPORTED'}
        customDataOptions={timesheetConfig?.customDataToApply?.map(
          (customDataId: string) => {
            const matchingCustomOption = customOptionsResults?.find(
              (customData: any) => customData.id === customDataId
            );
            return {
              id: matchingCustomOption?.id,
              name: matchingCustomOption?.name,
              options: matchingCustomOption?.custom_options ?? [],
            };
          }
        )}
        refetchSubmission={fetchTimesheetSubmission}
      />
    </div>
  );
};
