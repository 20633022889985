import ValidationMessage from '../Validation/ValidationMessage';

interface CheckboxOption {
  id: string;
  name: string;
  detail: string;
  assigned: boolean;
  disabled?: boolean;
}
interface BoxConfig {
  handleChange: (options: { id: string }[]) => void;
  text: string;
  value: { id: string }[];
  isDisabled?: boolean;
  validation: string;
  options?: CheckboxOption[];
}

const CheckboxList = ({
  handleChange,
  validation,
  value,
  options,
}: BoxConfig) => {
  return (
    <div className="space-y-5">
      {validation ? <ValidationMessage message={validation} /> : null}
      {(options || []).map((option) => (
        <div key={option.id} className="relative flex items-start">
          <div className="flex h-5 items-center">
            <input
              id={option.id}
              aria-describedby="comments-description"
              name={option.id}
              type="checkbox"
              disabled={option.disabled}
              checked={value?.some((anOption) => anOption.id === option.id)}
              onChange={(event: any) => {
                const valuesToSubmit = [
                  ...(value?.filter((anOption) => anOption.id !== option.id) ||
                    []),
                ];
                if (event.target.checked) {
                  valuesToSubmit.push({ id: option.id });
                }
                handleChange(valuesToSubmit);
              }}
              className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
            />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor="comments" className="font-medium text-gray-700">
              {option.name}
            </label>
            <p id="comments-description" className="text-gray-500">
              {option.detail}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CheckboxList;
