import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useEffect, useState } from 'react';

import FormCard from '../../components/FormCard/FormCard';
import { SAVE_PROJECT } from '../../graphql/mutations/projects';
import { GET_CUSTOM_OPTIONS } from '../../graphql/queries/custom-data';
import { useOrganisationAwareApollo } from '../../hooks/useOrganisationAwareApollo';
import { Currency } from '../../types/Currency';
import { FormConfig } from '../../types/Form';
import { useToast } from '../../context/ToastContext';
import useLabourResources from '../../hooks/api/useLabourResources';
import useProjectDetails from '../../hooks/api/projects/useProject';
import { ProjectStatus } from '../../types/Project';

export const SaveProject = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const isUpdate = !!id;

  const { addToast } = useToast();

  const [project] = useProjectDetails(id);
  const projectCustomData = project?.customData || [];

  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  const [showAddColourButton, setShowAddColourButton] = useState(
    project?.colour === null ? false : true
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 600);
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const [labourResources] = useLabourResources({
    limit: 2000,
    isASearch: true,
    searchTerm: debouncedSearchTerm,
  });

  const [fetchCustomOptions, { data: customOptionsData }] = useLazyQuery(
    GET_CUSTOM_OPTIONS,
    {
      variables: { assignedToProject: true },
    }
  );

  useEffect(() => {
    fetchCustomOptions();
  }, [fetchCustomOptions, id, isUpdate]);

  const customOptionsSets =
    customOptionsData?.getCustomOptionSets?.results || [];

  const [saveProject] = useMutation(SAVE_PROJECT);

  const initialValues = id
    ? {
        ...project,
        ...projectCustomData.reduce(
          (acc: Record<string, any>, customItem: any) => {
            acc[`custom:${customItem.key}`] = customItem.value;
            return acc;
          },
          {}
        ),
        managers: project?.managers.map(
          (manager: { id: string }) => manager.id
        ),
        startDate: new Date(+project?.startDate).getTime(),
        endDate: new Date(+project?.endDate).getTime(),
      }
    : { name: undefined, description: undefined };

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const customData: { key: string; value: any }[] = [];
    const filteredValues = Object.keys(values).reduce(
      (acc: Record<string, string>, key) => {
        if (key.startsWith('custom:')) {
          customData.push({
            key: key?.replace('custom:', ''),
            value: values[key],
          });
        } else {
          acc[key] = values[key];
        }
        return acc;
      },
      {}
    );

    try {
      await saveProject({
        variables: {
          id: values?.id,
          input: {
            ...filteredValues,
            id: undefined,
            updatedAt: undefined,
            createdAt: undefined,
            startDate: values?.startDate
              ? new Date(values.startDate).toISOString()
              : null,
            endDate: values?.endDate
              ? new Date(values.endDate).toISOString()
              : null,
            customData,
          },
        },
      });

      if (setSubmitting) {
        setSubmitting(false);
      }

      if (id) {
        navigate(`/projects/${id}`);
      } else {
        navigate(`/projects/list`);
      }

      addToast('Project saved successfully', 'success');
    } catch (error) {
      console.error(error);
      addToast('Failed to save project', 'error');
    }
  };

  const projectConfig = {
    formSections: [
      {
        title: 'Project',
        description: 'Basic details about the Project',
        fields: [
          {
            id: 'name',
            type: 'input',
            title: 'Project Name',
            placeholder: 'SOME PROJECT NAME',
            inputTransformer: (text: string) => text,
          },
          ...(!showAddColourButton || project?.colour
            ? [
                {
                  id: 'colour',
                  type: 'colour',
                  title: 'Project Colour',
                  classNames: 'w-32',
                  inputTransformer: (text: string) => text,
                },
              ]
            : [
                {
                  id: 'addButton',
                  type: 'addbutton',
                  title: 'Project Colour',
                  description: 'Click to select a colour for this project',
                  onClick: () => setShowAddColourButton(false),
                },
              ]),
          {
            id: 'internalId',
            type: 'input',
            title: 'Internal Project ID',
            placeholder: 'P00090',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'description',
            type: 'textarea',
            title: 'Project Description',
            placeholder: '',
            description:
              'Use this space to add a description or notes about the project.',
          },
          {
            id: 'startDate',
            type: 'datepicker',
            title: 'Start Date',
            inputTransformer: (value: Date) => new Date(value).getTime(),
          },
          {
            id: 'endDate',
            type: 'datepicker',
            title: 'Expected End Date',
            inputTransformer: (value: Date) => new Date(value).getTime(),
          },
          {
            id: 'defaultCurrency',
            type: 'dropdown',
            title: 'Default Currency',
            options: Object.keys(Currency)
              .filter((e: any) => isNaN(e))
              .map((entry) => ({ name: entry })) as {
              name?: string;
            }[],
            required: true,
          },
          {
            id: 'projectValue',
            type: 'currency',
            currencyField: 'defaultCurrency',
            title: 'Project Value',
            description: 'This is the total value of the project',
          },
          {
            id: 'managers',
            type: 'multiselecttable',
            title: 'Project Managers',
            options: labourResources
              .filter((labourResource: any) => !!labourResource.email)
              .map((labourResource: any) => ({
                name: `${labourResource.firstName} ${labourResource.lastName} (${labourResource.email})`,
                value: labourResource.id,
              })),
            required: false,
            setSearchTerm,
            searchTerm,
          },
          {
            id: 'status',
            type: 'dropdown',
            title: 'Project Status',
            options: [
              { name: 'Not Started', value: ProjectStatus.NOT_STARTED },
              { name: 'In Progress', value: ProjectStatus.IN_PROGRESS },
              { name: 'Complete', value: ProjectStatus.COMPLETE },
            ],
            required: false,
          },
          {
            id: 'enabled',
            type: 'switch',
            title: 'Enabled',
          },
        ],
      },
      ...(customOptionsSets.length > 0
        ? [
            {
              title: 'Additional Data',
              fields: [
                ...customOptionsSets?.map((optionSet: any) => ({
                  title: optionSet.name,
                  id: `custom:${optionSet.id}`,
                  type: 'dropdown',
                  options: [
                    { name: '(empty)', value: '' },
                    ...optionSet?.custom_options?.map((option: any) => ({
                      value: option.id,
                      name: option.name,
                    })),
                  ],
                })),
              ],
            },
          ]
        : []),
    ],
  } as FormConfig;

  const ProjectCreationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required(),
    internalId: Yup.string()
      .typeError('Required field')
      .required('Internal ID is a required field'),
    description: Yup.string().min(4, 'Too Short!').max(800, 'Too Long!'),
    enabled: Yup.bool().nullable(),

    defaultCurrency: Yup.mixed<Currency>()
      // @ts-ignore
      .oneOf(Object.keys(Currency))
      .required('Default Currency is required'),
    startDate: Yup.string().test(
      'start before end',
      'Start Date must be before or the same as End Date',
      function (value) {
        return !value || value <= this.parent.endDate;
      }
    ),
    endDate: Yup.string().test(
      'end after start',
      'End Date must be after or the same as Start Date',
      function (value) {
        return !value || value >= this.parent.startDate;
      }
    ),
    managers: Yup.array().of(Yup.string()),
  });

  return (
    <FormCard
      key={`${projectConfig?.title}`}
      config={projectConfig}
      validationSchema={ProjectCreationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
};
