export function mergeServerAndLocalData(serverData: any[], localData: any[]) {
  const mergedData = serverData?.map((serverItem) => {
    const localItem = localData?.find((local) => local.id === serverItem.id);
    return localItem && localItem.savedLocally ? localItem : serverItem;
  });

  localData?.forEach((localItem) => {
    if (!mergedData?.some((merged) => merged.id === localItem.id)) {
      mergedData?.push(localItem);
    }
  });

  return mergedData;
}

export const markAllAsLocallySaved = (entities: any) => {
  return Object.keys(entities).reduce((acc: any, key) => {
    acc[key] = markAsLocallySaved(entities[key].original, entities[key].toSave);
    return acc;
  }, {});
};

export function markAsLocallySaved(items: any[], itemsToSave: any[]) {
  const idsToSave = new Set(itemsToSave.map((item: any) => item.id));
  return items.map((item) => ({
    ...item,
    ...(idsToSave.has(item.id) ? { savedLocally: true } : {}),
  }));
}

export const prepareItems = (
  itemsToPrepare: any[],
  config: {
    idKey?: string;
    excludeKeys?: string[];
    nestedMappings?: Record<string, string[]>;
  }
) => {
  return itemsToPrepare
    ?.filter(
      (item) =>
        item.isNew || item.toBeDeleted || item.hasChanged || item.savedLocally
    )
    .map((item) => {
      let preparedItem = {
        ...item,
        isNew: undefined,
        hasChanged: undefined,
        original: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        savedLocally: undefined,
      };

      if (config.idKey && item[config.idKey]) {
        preparedItem.id = item[config.idKey];
      }

      config.excludeKeys?.forEach((key) => {
        preparedItem[key] = undefined;
      });

      Object.keys(config.nestedMappings || {}).forEach((key) => {
        if (item[key]) {
          preparedItem[key] = { ...item[key] };
          // @ts-ignore
          config.nestedMappings[key].forEach((nestedKey) => {
            preparedItem[key][nestedKey] = undefined;
          });
        }
      });

      return preparedItem;
    });
};

export const getPathValue = (obj: any, path: string) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};
