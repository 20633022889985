import { GridLayout } from '../../components/Grid/GridLayout';
import { Card } from '../../components/Layout/Card';
import { ModuleItemSummary } from '../../components/ModuleItemSummary/ModuleItemSummary';
// @ts-ignore

import {
  BriefcaseIcon,
  CheckBadgeIcon,
  NoSymbolIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { ModuleItemCard } from '../../types/ModuleItemSummary';
import { useOrganisationAwareApollo } from '../../hooks/useOrganisationAwareApollo';
import { GET_STATISTICS } from '../../graphql/queries/statistics';
import { useContext, useEffect, useState } from 'react';
import { LabourSearch } from './search';
import { UserProfileContext } from '../../context/UserProfileContext';
import { Permission } from '../../types/Permissions';
import NoData from '../../components/NoData/NoData';
import LinkButton from '../../components/Buttons/LinkButton';

interface LabourHomeProps {
  children?: React.ReactElement;
}

export const LabourHome = ({ children }: LabourHomeProps) => {
  const { useLazyQuery } = useOrganisationAwareApollo();
  const { userProfile } = useContext(UserProfileContext);

  const [moduleItemsActive, setModuleItemsActive] = useState<{
    employeesModuleActive: boolean;
    subcontractorsModuleActive: boolean;
  }>({
    employeesModuleActive: false,
    subcontractorsModuleActive: false,
  });

  const [fetch, { data, loading }] = useLazyQuery(GET_STATISTICS, {
    variables: {
      query: [
        {
          calculation: 'labour',
        },
      ],
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetch();
  }, [fetch]);

  const statistics = data?.getStatistics?.labour || {};

  const employeeStatuses = [
    {
      heading: String(statistics?.employeeStatusCount?.enabled),
      description: 'Enabled',
      colour: '#DCFCE7',
      IconComponent: CheckBadgeIcon,
      classNames: `${
        statistics?.employeeStatusCount?.enabled === 0 ? 'hidden' : ''
      }`,
    },
    {
      heading: String(statistics?.employeeStatusCount?.disabled),
      description: 'Disabled',
      IconComponent: NoSymbolIcon,
      classNames: `bg-red-100 ${
        statistics?.employeeStatusCount?.disabled === 0 ? 'hidden' : ''
      }`,
    },
  ] as ModuleItemCard[];

  const subcontractorStatuses = [
    {
      heading: String(statistics?.subcontractorStatusCount?.enabled),
      description: 'Enabled',
      colour: '#DCFCE7',
      IconComponent: CheckBadgeIcon,
      classNames: `${
        statistics?.subcontractorStatusCount?.enabled === 0 ? 'hidden' : ''
      }`,
    },
    {
      heading: String(statistics?.subcontractorStatusCount?.disabled),
      description: 'Disabled',
      IconComponent: NoSymbolIcon,
      classNames: `bg-red-100 ${
        statistics?.subcontractorStatusCount?.disabled === 0 ? 'hidden' : ''
      }`,
    },
  ] as ModuleItemCard[];

  const mockProjectSummary = [
    {
      heading: statistics?.employeeCount,
      description: 'Employees',
      colour: '#F3E8FF',
      IconComponent: UserIcon,
      isExpandable: true,
      hoverColour: '#E9D8FF',
      classNames: `cursor-pointer transition-all ${
        moduleItemsActive.employeesModuleActive
          ? 'border border-purple-500'
          : ''
      }`,
      isActive: moduleItemsActive.employeesModuleActive,
      onClick: () => {
        setModuleItemsActive({
          ...moduleItemsActive,
          employeesModuleActive: !moduleItemsActive.employeesModuleActive,
        });
      },
    },
    {
      heading: statistics?.subcontractorCount,
      description: 'Subcontractors',
      colour: '#F3E8FF',
      IconComponent: BriefcaseIcon,
      isExpandable: true,
      hoverColour: '#E9D8FF',
      classNames: `cursor-pointer transition-all ${
        moduleItemsActive.subcontractorsModuleActive
          ? 'border border-purple-500'
          : ''
      }`,
      isActive: moduleItemsActive.subcontractorsModuleActive,
      onClick: () => {
        setModuleItemsActive({
          ...moduleItemsActive,
          subcontractorsModuleActive:
            !moduleItemsActive.subcontractorsModuleActive,
        });
      },
    },
    {
      heading: statistics?.roleCount,
      description: 'Roles',
      colour: '#F3E8FF',
      IconComponent: BriefcaseIcon,
    },
  ] as ModuleItemCard[];

  if (
    !statistics.employeeCount &&
    !statistics.subcontractorCount &&
    !statistics.roleCount &&
    userProfile?.permissions.includes(Permission.ViewSkills) &&
    userProfile?.permissions.includes(Permission.ViewLabourProfiles)
  ) {
    return (
      <NoData loading={loading}>
        It looks like there are no employees or subcontractors in this
        organisation!
        {userProfile?.permissions.includes(Permission.UpdateLabourProfiles) ? (
          <div className="flex gap-x-4">
            <LinkButton to="/labour/employees/new" target="_blank">
              Add an Employee
            </LinkButton>
            <LinkButton to="/labour/subcontractors/new" target="_blank">
              Add a Subcontractor
            </LinkButton>
          </div>
        ) : null}
      </NoData>
    );
  }

  return (
    <div>
      {/* Medium screens and above */}
      <div className="hidden md:block">
        <GridLayout>
          <Card classNames={'col-start-1 col-span-3'}>
            <ModuleItemSummary moduleItemCards={mockProjectSummary} />
          </Card>
          <div className="grid grid-cols-3 col-span-4 gap-2">
            {moduleItemsActive.employeesModuleActive ? (
              <Card classNames="mt-[-1.5rem]">
                <ModuleItemSummary
                  moduleItemCards={employeeStatuses}
                  classNames="grid grid-cols-1 xl:grid-cols-2"
                />
              </Card>
            ) : (
              <div />
            )}
            {moduleItemsActive.subcontractorsModuleActive ? (
              <Card classNames="mt-[-1.5rem]">
                <ModuleItemSummary
                  moduleItemCards={subcontractorStatuses}
                  classNames="grid grid-cols-1 xl:grid-cols-2"
                />
              </Card>
            ) : (
              <div />
            )}
            <div />
          </div>
        </GridLayout>
      </div>
      {/* Small screens */}
      <div className="flex flex-col md:hidden">
        <GridLayout classNames="gap-0">
          <Card classNames={'col-span-3'}>
            <ModuleItemSummary moduleItemCards={[mockProjectSummary[0]]} />
          </Card>
          {moduleItemsActive.employeesModuleActive ? (
            <Card classNames="mt-[-1rem] col-span-3">
              <ModuleItemSummary moduleItemCards={employeeStatuses} />
            </Card>
          ) : (
            <div />
          )}
          <Card classNames={'col-span-3'}>
            <ModuleItemSummary moduleItemCards={[mockProjectSummary[1]]} />
          </Card>
          {moduleItemsActive.subcontractorsModuleActive ? (
            <Card classNames="mt-[-1rem] col-span-3">
              <ModuleItemSummary moduleItemCards={subcontractorStatuses} />
            </Card>
          ) : (
            <div />
          )}
          <Card classNames={'col-span-3'}>
            <ModuleItemSummary moduleItemCards={[mockProjectSummary[2]]} />
          </Card>
        </GridLayout>
      </div>
      {userProfile?.permissions.includes(Permission.ViewSkills) &&
      userProfile?.permissions.includes(Permission.ViewLabourProfiles) ? (
        <LabourSearch />
      ) : null}
    </div>
  );
};
