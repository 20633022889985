import moment from 'moment';

export const calculateDuration = (activity: any) => {
  const { start, finish, endsAtMidnight } = activity;
  if (start && (finish || endsAtMidnight)) {
    const startTime = moment(start, 'HH:mm');
    const finishTime = endsAtMidnight
      ? moment('24:00', 'HH:mm')
      : moment(finish, 'HH:mm');
    const duration = moment.duration(finishTime.diff(startTime));
    const hours = duration.hours();
    const minutes = duration.minutes();
    return { hours, minutes };
  }
  return null;
};

export const calculateDurationInDecimalHours = (activity: any) => {
  const { start, finish, endsAtMidnight } = activity;
  if (start && (finish || endsAtMidnight)) {
    const startTime = moment(start, 'HH:mm');
    const finishTime = endsAtMidnight
      ? moment('24:00', 'HH:mm')
      : moment(finish, 'HH:mm');
    const duration = moment.duration(finishTime.diff(startTime));
    const hours = duration.asHours();
    return hours;
  }
  return 0;
};

export const calculateTotalPersonHours = (activities: any[]) => {
  return activities.reduce((total: number, activity: any) => {
    const durationHours = parseFloat(
      calculateDurationInDecimalHours(activity).toFixed(10)
    );

    const totalPeople = activity.assignmentRolesList
      ? activity.assignmentRolesList.reduce(
          (sum: number, role: { enabled: boolean }) =>
            sum + (role.enabled ? 1 : 0),
          0
        )
      : 0;

    const personHours = parseFloat((durationHours * totalPeople).toFixed(10));

    return total + personHours;
  }, 0);
};
