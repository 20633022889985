import { useOrganisationAwareApollo } from '../useOrganisationAwareApollo';
import { ApiHookMeta } from './types';
import { GET_COMPANIES_WITH_EXPIRING_CERTIFICATIONS } from '../../graphql/queries/companies';

export default function useLabourWithExpiringCertifications({
  certificationTypeId,
  projectId,
  dateFilter,
  filter,
  limit,
  offset,
}: {
  certificationTypeId?: string;
  projectId?: string;
  dateFilter?: string;
  filter?: any;
  limit?: number;
  offset?: number;
}): [any, ApiHookMeta, any?] {
  const { useQuery } = useOrganisationAwareApollo();

  const { data, loading, error, ...result } = useQuery(
    GET_COMPANIES_WITH_EXPIRING_CERTIFICATIONS,
    {
      variables: {
        input: { limit, offset, filter },
        dateFilter,
        ...(certificationTypeId ? { certificationTypeId } : {}),
        ...(projectId ? { projectId } : {}),
      },
      fetchPolicy: 'network-only',
    }
  );

  const results = data?.getCompaniesWithExpiringCertifications?.results || [];
  const count = data?.getCompaniesWithExpiringCertifications?.count || 0;

  return [
    results,
    {
      count,
      loading,
      error,
      ...result,
    },
  ];
}
