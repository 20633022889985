import React from 'react';
import { Question } from '../../types/QuestionTypes';
import { Button } from '../Buttons/Button';
import ValidationMessage from '../Validation/ValidationMessage';

interface ObjectListValue {
  [key: string]: string;
}

interface ObjectListProps {
  question: Question;
  value: ObjectListValue[];
  handleChange: (value: ObjectListValue[]) => void;
  validation?: string;
  isDisabled?: boolean;
}

const ObjectList: React.FC<ObjectListProps> = ({
  question,
  value = [],
  handleChange,
  validation,
  isDisabled,
}) => {
  const handleObjectChange = (
    index: number,
    fieldId: string,
    fieldValue: string
  ) => {
    const updatedValues = value.map((item, idx) =>
      idx === index ? { ...item, [fieldId]: fieldValue } : item
    );
    handleChange(updatedValues);
  };

  const handleAddOption = () => {
    const newOption = question?.options?.reduce(
      (acc, option) => ({
        ...acc,
        [option.id]: '',
      }),
      {}
    );
    // @ts-ignore
    handleChange([...value, newOption]);
  };

  const handleRemoveOption = (index: number) => {
    const updatedValues = value.filter((_, idx) => idx !== index);
    handleChange(updatedValues);
  };

  const { title, description, options } = question;

  return (
    <div className="space-y-5 w-full">
      <label className="block text-sm font-medium text-gray-700">{title}</label>

      {value?.map((option, index) => (
        <div
          key={index}
          className="flex flex-col lg:flex-row gap-x-2 gap-y-2 space-y-1 w-full items-center"
        >
          {options?.map((opt) => (
            <div key={opt.id} className="flex flex-col justify-center w-full">
              <label
                htmlFor={`${opt.id}-${index}`}
                className="text-sm font-medium text-gray-700 mb-2"
              >
                {opt.name}
              </label>
              <input
                type="text"
                id={`${opt.id}-${index}`}
                value={option[opt.id]}
                disabled={isDisabled}
                onChange={(e) =>
                  handleObjectChange(index, opt.id, e.target.value)
                }
                placeholder={opt.name}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
              />
            </div>
          ))}

          <Button
            style={{ marginTop: 22 }}
            onClick={() => handleRemoveOption(index)}
            text={'Remove'}
          />
        </div>
      ))}
      {/* @ts-ignore */}
      {validation &&
        Array.isArray(validation) &&
        validation?.map((validationMessage) =>
          validationMessage
            ? Object.values(validationMessage).map((validationEntry: any) => (
                <ValidationMessage message={validationEntry} />
              ))
            : null
        )}
      <div className="mt-4 flex items-center">
        <Button onClick={handleAddOption} text={'Add Option'} />
      </div>
      {description && (
        <p className="mt-2 text-sm text-gray-500">{description}</p>
      )}
    </div>
  );
};

export default ObjectList;
