import { gql } from '@apollo/client';

export const SAVE_ACTIVITY_CODE = gql`
  mutation saveActivityCode(
    $id: ID
    $input: SaveActivityCodeInput!
    $activeOrganisation: String!
  ) {
    saveActivityCode(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const DELETE_ACTIVITY_CODE = gql`
  mutation deleteActivityCode($activeOrganisation: String!, $id: ID) {
    deleteActivityCode(activeOrganisation: $activeOrganisation, id: $id) {
      id
      success
      message
    }
  }
`;
