import useCompanies from './useCompanies';

export function useCompany(id?: string) {
  const [companies, meta] = useCompanies({ id, skip: !id });

  const [company] = companies;

  return [company, meta];
}

export function useCompanyByLabourResource(labourResourceId?: string) {
  const [companies, meta] = useCompanies({
    labourResourceId,
    skip: !labourResourceId,
  });

  const [company] = companies;

  return [company, meta];
}
