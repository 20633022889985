import { useEffect } from 'react';
import { useOrganisationAwareApollo } from '../useOrganisationAwareApollo';
import { ApiHookMeta } from './types';
import { GET_REQUIRED_CERTIFICATIONS } from '../../graphql/queries/certifications';

export default function useRequiredCertifications({
  certificationTypeId,
  projectId,
  limit,
  offset,
}: {
  certificationTypeId?: string;
  projectId?: string;
  limit?: number;
  offset?: number;
}): [any, ApiHookMeta, any?] {
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [fetch, { data, loading, error, ...result }] = useLazyQuery(
    GET_REQUIRED_CERTIFICATIONS,
    {
      variables: { certificationTypeId, projectId, input: { limit, offset } },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  const requiredCertifications = data?.getRequiredCertifications?.results || [];
  const count = data?.getRequiredCertifications?.count || 0;

  return [
    requiredCertifications,
    {
      count,
      loading,
      error,
      ...result,
    },
    async (variables: any) => await fetch(variables),
  ];
}
