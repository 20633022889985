// @ts-nocheck
import { useEffect, useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import { v4 } from 'uuid';
import { Button } from '../Buttons/Button';

const range = (start, end) => {
  let result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

const defaultParsePaste = (str) =>
  str.split(/\r\n|\n|\r/).map((row) => row.split('\t'));

export function DataImportGrid({ rows, setRows, config }) {
  const [topLeft, setTopLeft] = useState({});
  const [botRight, setBotRight] = useState({});

  const visibleRows = [
    ...(rows.filter((row) => !row.toBeDeleted).filter((row) => row.name) ?? []),
    {},
  ];
  const deletedRows = rows.filter((row) => row.toBeDeleted) ?? [];

  const handleCopy = (e) => {
    e.preventDefault();
    const text = range(topLeft.rowIdx, botRight.rowIdx + 1)
      .map((rowIdx) =>
        config.columns
          .slice(topLeft.colIdx, botRight.colIdx + 1)
          .map((col) => visibleRows?.[rowIdx]?.[col.key])
          .join('\t')
      )
      .join('\n');
    e.clipboardData.setData('text/plain', text);
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const newRows = [...visibleRows];
    const pasteData = defaultParsePaste(e.clipboardData.getData('text/plain'));

    pasteData.forEach((row, i) => {
      const rowData = {};
      config.columns
        .slice(topLeft.colIdx, topLeft.colIdx + row.length)
        .forEach((col, j) => {
          rowData[col.key] = row[j];
        });
      if (topLeft.rowIdx + i < newRows.length) {
        newRows[topLeft.rowIdx + i] = {
          ...newRows[topLeft.rowIdx + i],
          ...rowData,
        };
      } else {
        newRows.push(rowData);
      }
    });

    setRows([...newRows, ...deletedRows]);
  };

  useEffect(() => {
    document.addEventListener('copy', handleCopy);
    document.addEventListener('paste', handlePaste);
    return () => {
      document.removeEventListener('copy', handleCopy);
      document.removeEventListener('paste', handlePaste);
    };
  }, [rows, topLeft, botRight, setRows, config.columns]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Delete') {
        e.preventDefault();
        const newRows = visibleRows.map((row, idx) => {
          if (idx >= topLeft.rowIdx && idx <= botRight.rowIdx) {
            if (topLeft.colIdx === 0) {
              return { ...row, toBeDeleted: true };
            } else {
              const updatedRow = { ...row };
              for (
                let colIdx = topLeft.colIdx;
                colIdx <= botRight.colIdx;
                colIdx++
              ) {
                const columnKey = config.columns[colIdx].key;
                updatedRow[columnKey] = '';
              }
              return updatedRow;
            }
          }
          return row;
        });
        setRows([...newRows, ...deletedRows]);
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [topLeft, botRight, visibleRows, setRows, config.columns.length]);

  const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    let newRows = visibleRows.slice();
    const additionalRows = [];
    for (let i = fromRow; i <= toRow; i++) {
      const duplicateIndex = newRows.findIndex(
        (row, idx) => row.id === newRows?.[i]?.id
      );
      if (duplicateIndex !== -1) {
        if (Object.values(updated).filter((item) => item).length > 0) {
          newRows[i] = { ...newRows[i], ...updated };
        }
      } else {
        additionalRows.push({ id: v4(), ...updated, isNew: true });
      }
    }

    setRows([...newRows, ...additionalRows, ...deletedRows]);
  };

  const setSelection = (args) => {
    setTopLeft({
      rowIdx: args.topLeft.rowIdx,
      colIdx: args.topLeft.idx,
    });
    setBotRight({
      rowIdx: args.bottomRight.rowIdx,
      colIdx: args.bottomRight.idx,
    });
  };

  const handleDeleteRow = (rowId: any) => {
    setRows((currentRows) =>
      currentRows.map((row) =>
        row.id === rowId ? { ...row, toBeDeleted: true } : row
      )
    );
  };

  function DeleteButton({ onExecute }: any) {
    return (
      <div>
        <button
          onClick={onExecute}
          style={{
            cursor: 'pointer',
            background: 'black',
            color: 'white',
            borderRadius: 10,
            paddingLeft: 8,
            paddingRight: 8,
            paddingBottom: 4,
            paddingTop: 4,
            fontSize: 14,
          }}
        >
          Delete
        </button>
      </div>
    );
  }

  return (
    <div>
      <ReactDataGrid
        columns={[
          ...config.columns.map((column) => ({ ...column, editable: true })),
          {
            key: 'options',
            name: 'Options',
            formatter: ({ row }) =>
              row.name ? (
                <DeleteButton onExecute={() => handleDeleteRow(row.id)} />
              ) : null,
            width: 100,
          },
        ]}
        rowGetter={(i) => visibleRows?.[i]}
        rowsCount={visibleRows?.length}
        onGridRowsUpdated={onGridRowsUpdated}
        enableCellSelect
        minColumnWidth={30}
        cellRangeSelection={{
          onComplete: setSelection,
        }}
        onCellSelected={(s) => setSelection({ topLeft: s, bottomRight: s })}
      />
    </div>
  );
}

export default DataImportGrid;
