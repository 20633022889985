import { ClockIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ActiveBadge,
  DisabledBadge,
  GrayBadge,
  GreenBadge,
  YellowBadge,
} from '../../components/Badges/Badges';
import { Button } from '../../components/Buttons/Button';
import { UserProfileContext } from '../../context/UserProfileContext';
import { GET_CUSTOM_OPTIONS } from '../../graphql/queries/custom-data';
import { useOrganisationAwareApollo } from '../../hooks/useOrganisationAwareApollo';
import { Permission } from '../../types/Permissions';
import { ProjectAssignmentList } from './assignments/list';
import { ProjectLocationList } from './locations/list';
import { ProjectTeamList } from './teams/list';
import { ProjectOutcomeList } from './outcomes/list';
import { ProjectActivityList } from './activities/list';
import { ProjectActivityGroupsList } from './activity-groups/list';
import { ActiveOrganisationContext } from '../../context/ActiveOrganisationContext';
import { ProjectCertificationsList } from './certifications/ProjectCertificationsList';
import ProjectSummary from './summary/ProjectSummary';
import useProjectDetails from '../../hooks/api/projects/useProject';
import { ProjectStatus } from '../../types/Project';
import { useTabs } from '../../hooks/useTabs';
import { ProjectCompaniesList } from './companies/ProjectCompaniesList';

export const statusToBadge: Record<ProjectStatus, React.ReactElement> = {
  NOT_STARTED: <GrayBadge text="Not Started" />,
  IN_PROGRESS: <YellowBadge text="In Progress" />,
  COMPLETE: <GreenBadge text="Complete" />,
};

export const ProjectPage = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const { userProfile } = useContext(UserProfileContext);

  const { activeOrganisation } = useContext(ActiveOrganisationContext);

  const [moduleToggles, setModuleToggles] = useState<string[]>([]);

  const Tabs = [
    'Summary',
    'Assignees',
    'Companies',
    'Additional Data',
    'Certifications',
  ];

  if (moduleToggles.includes('progress')) {
    Tabs.push('Locations', 'Teams', 'Activities', 'Activity Groups', 'Scopes');
  }

  const [activeTab, setActiveTab] = useTabs(Tabs);

  useEffect(() => {
    setModuleToggles(
      activeOrganisation?.progressModuleEnabled ? ['progress'] : []
    );
  }, [activeOrganisation?.progressModuleEnabled]);

  const [project, { error, loading }] = useProjectDetails(id);

  const [fetchCustomOptions, { data: customOptionsData }] = useLazyQuery(
    GET_CUSTOM_OPTIONS,
    {
      variables: { assignedToProject: true },
      fetchPolicy: 'network-only',
    }
  );

  const getOptionSetNameByValue = (optionSetId: any) => {
    const matchingOptionSet =
      customOptionsData?.getCustomOptionSets?.results.find(
        (optionSet: any) => optionSet.id === optionSetId
      );

    return matchingOptionSet ? matchingOptionSet.name : null;
  };

  const mapCustomOptionName = (optionId: any) => {
    for (let optionSet of customOptionsData?.getCustomOptionSets?.results ||
      []) {
      const matchingOption = optionSet.custom_options.find(
        (option: any) => option.id === optionId
      );
      if (matchingOption) {
        return matchingOption.name;
      }
    }
    return optionId; // default to the option value if no match found
  };

  const customSections = (project?.customData || []).map((customItem: any) => ({
    title: getOptionSetNameByValue(customItem.key),
    content: mapCustomOptionName(customItem.value),
  }));

  useEffect(() => {
    fetchCustomOptions({ variables: { assignedToProject: true } });
  }, [fetchCustomOptions, id]);

  return (
    <div className="bg-gray-50">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="w-full px-2 bg-gray-800 py-2 flex flex-col md:flex-row">
          {Tabs.filter(
            (tab) =>
              tab !== 'Assignees' ||
              (tab === 'Assignees' &&
                userProfile?.permissions?.includes(
                  Permission.ViewLabourProfiles
                ))
          ).map((tab) => (
            <div
              className={`${
                activeTab === tab ? 'bg-black' : ''
              } justify-center flex items-center`}
            >
              <Button
                style={{ borderRadius: 0, backgroundColor: 'transparent' }}
                text={tab}
                onClick={() => setActiveTab(tab)}
              />
            </div>
          ))}
        </div>
        <div className="py-2 border-b">
          <Button
            style={{
              backgroundColor: 'transparent',
              color: 'gray',
              boxShadow: 'none',
              borderRadius: 0,
            }}
            onClick={() => navigate(`/projects/list`)}
            type="submit"
            text={`< Back to Projects`}
          />
        </div>
        <div className="flex flex-col md:items-center md:flex-row w-full md:justify-between px-4 py-5 sm:px-6 flex-wrap">
          <div className="flex flex-col">
            <h1 className="text-2xl font-extrabold flex gap-x-4 text-gray-900">
              {project?.colour ? (
                <span
                  className="w-8 h-8 rounded-md"
                  style={{ backgroundColor: project?.colour }}
                />
              ) : null}
              {project?.name}
              {project?.enabled ? <ActiveBadge /> : <DisabledBadge />}
              {project?.status &&
                statusToBadge[project.status as ProjectStatus]}
            </h1>
            {project?.startDate ? (
              <p className="text-gray-700 mt-1 flex gap-x-2 ">
                <ClockIcon className="h-6 w-6" aria-hidden="true" />{' '}
                {moment(Number(project?.startDate)).format('ll')} -{' '}
                {moment(Number(project?.endDate)).format('ll')}
              </p>
            ) : null}
            <h2 className="text-lg mt-1 font-base text-gray-500">
              {project?.internalId}
            </h2>
          </div>
          <div className="mt-2 md:mt-0">
            {userProfile?.permissions?.includes(Permission.UpdateProject) ? (
              <>
                {activeTab === 'Summary' ? (
                  <Button
                    onClick={() => navigate(`/projects/${id}/edit`)}
                    type="submit"
                    text="Edit"
                  />
                ) : null}
                {activeTab === 'Assignees' ? (
                  <Button
                    type="submit"
                    text="Assign Resource"
                    onClick={() => navigate(`/projects/${id}/assignments/new`)}
                  />
                ) : null}
                {activeTab === 'Companies' ? (
                  <Button
                    type="submit"
                    text="Assign Companies"
                    onClick={() => navigate(`/projects/${id}/companies/assign`)}
                  />
                ) : null}
                {activeTab === 'Certifications' ? (
                  <Button
                    type="submit"
                    text="Configure Required Certification"
                    onClick={() =>
                      navigate(`/projects/${id}/certifications/new`)
                    }
                  />
                ) : null}
              </>
            ) : null}
          </div>
        </div>

        {loading && (
          <div className="h-64 flex items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
          </div>
        )}
        {error && (
          <p className="px-4 py-5 sm:p-6 text-red-500">
            Error loading data. Please try again.
          </p>
        )}
        {project ? (
          <>
            {activeTab === 'Summary' ? (
              project ? (
                <ProjectSummary project={project} />
              ) : (
                <p>No project data available</p>
              )
            ) : null}
            {activeTab === 'Assignees' ? (
              <ProjectAssignmentList projectId={id} />
            ) : null}
            {activeTab === 'Companies' ? (
              <ProjectCompaniesList projectId={project.id} />
            ) : null}
            {activeTab === 'Certifications' ? (
              <ProjectCertificationsList projectId={id} />
            ) : null}
            {activeTab === 'Locations' ? (
              <ProjectLocationList projectId={id} />
            ) : null}
            {activeTab === 'Teams' ? <ProjectTeamList projectId={id} /> : null}
            {activeTab === 'Scopes' ? (
              <ProjectOutcomeList projectId={id} />
            ) : null}
            {activeTab === 'Activities' ? (
              <ProjectActivityList projectId={id} />
            ) : null}
            {activeTab === 'Activity Groups' ? (
              <ProjectActivityGroupsList projectId={id} />
            ) : null}
            {activeTab === 'Additional Data' ? (
              <div className="border-t border-gray-200">
                {customSections.map((section: any, index: number) => (
                  <div key={`custom-${index}`} className="px-4 py-4">
                    <h2 className="text-lg font-medium text-gray-900">
                      {section.title}
                    </h2>
                    <p className="mt-1 text-sm text-gray-500">
                      {section.content}
                    </p>
                  </div>
                ))}
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};

export const Project = () => <ProjectPage />;
