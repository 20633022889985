import moment from 'moment';
import NoData from '../../../components/NoData/NoData';
import { Table } from '../../../components/Tables/tables/Table';
import {
  ActiveBadge,
  BlueBadge,
  GeneratingBadge,
  GreenBadge,
  RedBadge,
} from '../../../components/Badges/Badges';
import { Button } from '../../../components/Buttons/Button';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { GET_CV_EXPORT_URL } from '../../../graphql/queries/exports';
import { useCallback, useEffect } from 'react';

type CvExportTableProps = {
  count: number;
  labour: {
    firstName: string;
    lastName: string;
  };
  assignmentRoleId?: string;
  exports: any[];
  offset: number;
  setOffset: (value: number) => void;
  limit: number;
  loading: boolean;
  showPagination?: boolean;
  noDataMessage?: string;
};

export function CvExportTable({
  count,
  labour,
  exports,
  offset,
  setOffset,
  limit,
  loading,
  showPagination = true,
  noDataMessage = 'It looks like there are no CV exports for this person',
}: CvExportTableProps) {
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [fetchExportURL, { data: exportURL }] = useLazyQuery(
    GET_CV_EXPORT_URL,
    {
      fetchPolicy: 'network-only',
    }
  );

  const handlePageChange = useCallback(
    (pageNumber: number) => {
      setOffset((pageNumber - 1) * limit);
    },
    [limit, setOffset]
  );

  const downloadPath = (path: string, filename: string) => {
    // Create a new link
    const anchor = document.createElement('a');
    anchor.href = path;
    anchor.download = filename;
    anchor.style.display = 'none';

    // Append to the DOM
    document.body.appendChild(anchor);

    // Trigger `click` event
    anchor.click();

    // Remove element from DOM
    document.body.removeChild(anchor);

    // Revoke the Blob URL to free up resources
    URL.revokeObjectURL(path);
  };

  useEffect(() => {
    if (exportURL?.getCvExportURL) {
      const url = exportURL?.getCvExportURL?.url;
      downloadPath(url, `${labour.firstName}_${labour.lastName}.pdf`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(exportURL)]);

  return (
    <Table
      currentPage={Math.floor(offset / limit) + 1}
      totalPages={Math.ceil(count / limit)}
      itemsPerPage={limit}
      totalResults={count || 0}
      RowComponent={({ id, rowData }: any) => (
        <tr>
          <td
            key={`${id}_row`}
            className={`border-b flex flex-col w-full justify-around items-center lg:flex-row border-gray-200 hover:bg-gray-100`}
          >
            {rowData?.id ? (
              <div
                key={`${id}_row_entry`}
                className="py-3 px-6 text-sm text-left w-full"
              >
                <div className="flex flex-col flex-wrap gap-y-2 gap-x-2 mt-2">
                  <p>{rowData?.id}</p>
                  {rowData?.assignmentRole ? (
                    <p>
                      Created for role:{' '}
                      <ActiveBadge text={rowData?.assignmentRole?.role?.name} />
                    </p>
                  ) : null}
                  <div className="text-xs flex flex-col">
                    Created:{' '}
                    {rowData.createdAt
                      ? moment
                          .unix(parseInt(rowData.createdAt) / 1000)
                          .fromNow()
                      : null}
                  </div>
                </div>
              </div>
            ) : null}

            <div
              key={id}
              className="py-3 px-6 text-sm flex flex-col gap-y-2 lg:flex-row items-center justify-end  gap-x-2 text-black w-full"
            >
              <div className="flex items-center gap-x-2">
                Status:
                {rowData.status === 'COMPLETE' ? (
                  <GreenBadge text={'Generated'} />
                ) : rowData.status === 'FAILED' ? (
                  <RedBadge text={rowData.status} />
                ) : rowData.status === 'SUBMITTED' ? (
                  <BlueBadge text={'Submitted'} />
                ) : (
                  <GeneratingBadge />
                )}
              </div>
              {rowData.status === 'COMPLETE' ||
              rowData.status === 'SUBMITTED' ? (
                <div className="flex gap-x-2">
                  <Button
                    text="Download"
                    onClick={async () => {
                      await fetchExportURL({
                        variables: {
                          exportId: rowData?.id,
                        },
                      });
                    }}
                  />
                </div>
              ) : null}
            </div>
          </td>
        </tr>
      )}
      data={exports}
      onPageChange={handlePageChange}
      showPagination={showPagination}
      NoData={() => <NoData loading={loading}>{noDataMessage}</NoData>}
    />
  );
}
