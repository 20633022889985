import moment from 'moment';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DisabledBadge,
  GreenBadge,
  YellowBadge,
} from '../../components/Badges/Badges';
import { Table } from '../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../context/PaginationContext';
import {
  GET_USERS_FOR_EXPORT,
  GET_USERS_SUMMARY,
} from '../../graphql/queries/users';
import { useOrganisationAwareApollo } from '../../hooks/useOrganisationAwareApollo';
import Avatar from 'react-avatar';
import { Button } from '../../components/Buttons/Button';
import { UserProfileContext } from '../../context/UserProfileContext';
import { Permission } from '../../types/Permissions';
import { useToast } from '../../context/ToastContext';
import { RESEND_USER_SIGN_UP } from '../../graphql/mutations/users';
import CreateLoginForUserModal from './CreateLoginForUserModal';

interface UserListProps {
  showNewItemButton?: boolean;
  children?: React.ReactElement;
  filterIds?: string[];
  onClickRow?: (id: string, results: any[]) => void;
}

const headerMapping = {
  name: 'Name',
  email: 'Email',
  groups: 'Groups',
  status: 'Status',
  lastActivityTime: 'Last Active',
  updatedAt: 'Updated At',
  actions: 'Actions',
} as Record<string, any>;

const UsersTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
  filterText,
  setFilterText = () => {},
  selectedFilterDimension,
  setSelectedFilterDimension = () => {},
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    columnsToFilter={['firstName', 'lastName', 'id']}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    filterDimensions={[
      { id: 'firstName', name: 'First Name' },
      { id: 'lastName', name: 'Last Name' },
      { id: 'email', name: 'Email' },
    ]}
    showFilterOptions
    filterText={filterText}
    setFilterText={setFilterText}
    selectedFilterDimension={selectedFilterDimension}
    setSelectedFilterDimension={setSelectedFilterDimension}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
  />
);

export const UserListPage = ({
  children,
  showNewItemButton,
  onClickRow,
  filterIds = [],
}: UserListProps) => {
  const navigate = useNavigate();
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();
  const { userProfile } = useContext(UserProfileContext);
  const { addToast } = useToast();

  const [showCreateLoginModal, setShowCreateLoginModal] = useState(false);
  const [actionUserProfile, setActionUserProfile] = useState<any | undefined>(
    undefined
  );

  const [filterText, setFilterText] = useState<string>();
  const [selectedFilterDimension, setSelectedFilterDimension] =
    useState<string>('email');

  const { limit, offset, setOffset } = usePagination();

  const [debouncedFilterText, setDebouncedSearchTerm] = useState(filterText);

  const filter = debouncedFilterText
    ? {
        filters: [
          {
            field: selectedFilterDimension,
            stringFilters: [{ ilike: debouncedFilterText }],
          },
        ],
      }
    : null;

  const canUpdateUsers = userProfile?.permissions.includes(
    Permission.UpdateLabourProfiles
  );

  const [resendUserSignUp] = useMutation(RESEND_USER_SIGN_UP);

  const handleResendUserSignUp = useCallback(
    async (email: string) => {
      const { data } = await resendUserSignUp({ variables: { email } });

      if (data?.resendUserSignUp.success) {
        addToast('Successfully resent user sign up email', 'success');
      } else {
        addToast('Failed to resend user sign up email', 'error');
      }
    },
    [addToast, resendUserSignUp]
  );

  const [fetch, { data }] = useLazyQuery(GET_USERS_SUMMARY, {
    variables: { input: { limit, offset } },
    fetchPolicy: 'network-only',
  });

  const [fetchExport] = useLazyQuery(GET_USERS_FOR_EXPORT, {
    variables: {},
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetch({
      variables: { input: { limit, offset, ...(filter ? { filter } : {}) } },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, debouncedFilterText]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setOffset(0);
      setDebouncedSearchTerm(filterText);
    }, 600);
    return () => {
      clearTimeout(handler);
    };
  }, [filterText, setOffset]);

  const userResults = data?.getUsers;

  const totalPages = Math.ceil(userResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const exportUsersToCSV = async () => {
    const { data } = await fetchExport();

    const users = data?.getUserGroupsExport.results;

    if (!users) {
      addToast('Unable to fetch users, non found', 'error');
      return;
    }

    try {
      const csvData = users?.map((user: any) => {
        return {
          email: user.email,
          enabled: user.enabled,
          groups: `"${user.user_groups
            .map((group: any) => group.name)
            .join(', ')}"`,
          permissions: `"${user.user_groups
            .map((group: any) => group.permissions)
            .join(', ')}"`,
          createdAt: moment(user.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        };
      });

      const csv = [
        Object.keys(csvData[0]).join(','),
        ...csvData.map((user: any) => Object.values(user).join(',')),
      ].join('\n');
      // write the CSV to a file
      const blob = new Blob([csv], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', 'users.csv');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      addToast('File created and downloaded', 'success');
    } catch (e) {
      console.error('error ', e);
      addToast(
        'unable to generate CSV - Please try again or contact support',
        'error'
      );
    }
  };

  return (
    <div>
      <CreateLoginForUserModal
        showModal={showCreateLoginModal}
        setShowModal={setShowCreateLoginModal}
        userProfile={actionUserProfile}
      />
      <>
        {showNewItemButton ? (
          <div className="w-full flex justify-between items-center py-2">
            <h1 className="text-xl font-bold py-4">Users </h1>
            {userProfile?.permissions.includes(Permission.ViewUsers) &&
            userResults?.results?.length > 0 ? (
              <Button
                onClick={async () => {
                  await exportUsersToCSV();
                }}
              >
                Export Users
              </Button>
            ) : null}
          </div>
        ) : null}
        <UsersTable
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          totalResults={userResults?.count || 0}
          onClickRow={(id: any) => {
            if (onClickRow) {
              onClickRow(id, userResults?.results);
            } else {
              navigate(`/users/${id}`);
            }
          }}
          filterText={filterText}
          setFilterText={setFilterText}
          selectedFilterDimension={selectedFilterDimension}
          setSelectedFilterDimension={setSelectedFilterDimension}
          data={
            userResults?.results.length > 0
              ? userResults?.results
                  .filter((result: any) => !filterIds?.includes(result.id))
                  .map((result: any) => ({
                    id: result.id,
                    name: (
                      <div className="flex flex-col gap-x-2 items-left gap-y-2">
                        <div className="flex gap-x-2 items-center">
                          <Avatar
                            name={`${result?.labourResource?.firstName} ${result?.labourResource?.lastName}`}
                            size="30"
                            round
                          />
                          {`${result?.labourResource?.lastName}, ${result?.labourResource?.firstName}`}
                        </div>
                        {result.email ?? (
                          <div>
                            <YellowBadge text="No Email Provided" />
                          </div>
                        )}
                      </div>
                    ),
                    groups: (
                      <div className="flex flex-col gap-y-2">
                        {result?.user_groups.map((group: any) => (
                          <div className="bg-blue-100 px-3 py-0.5 flex justify-center w-48 truncate text-sm rounded-full font-medium text-blue-800 text-center">
                            {group.name}
                          </div>
                        ))}
                      </div>
                    ),
                    lastActivityTime: result.lastActivityTime
                      ? moment
                          .unix(parseInt(result.lastActivityTime) / 1000)
                          .fromNow()
                      : 'Never',
                    updatedAt: result.updatedAt,
                    status: result.enabled ? (
                      <GreenBadge text="Enabled" />
                    ) : (
                      <DisabledBadge />
                    ),
                    actions: canUpdateUsers && (
                      <div className="flex flex-col gap-y-2">
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(
                              `/labour/${result?.labourResource?.type?.toLowerCase()}s/${
                                result?.labourResource?.id
                              }`
                            );
                          }}
                        >
                          View Labour Record
                        </Button>
                        {!result.lastActivityTime &&
                        result.email &&
                        result.hasUserLogin ? (
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleResendUserSignUp(result.email);
                            }}
                          >
                            Resend Signup Email
                          </Button>
                        ) : null}
                        {!result.hasUserLogin ? (
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              setActionUserProfile(result);
                              setShowCreateLoginModal(true);
                            }}
                          >
                            Create Login for User
                          </Button>
                        ) : null}
                      </div>
                    ),
                  }))
              : [{}]
          }
          onPageChange={handlePageChange}
        />
      </>
      {children}
    </div>
  );
};

export const UserList = ({
  onClickRow,
  showNewItemButton = true,
  filterIds = [],
}: UserListProps) => (
  <PaginationProvider>
    <UserListPage
      onClickRow={onClickRow}
      showNewItemButton={showNewItemButton}
      filterIds={filterIds}
    />
  </PaginationProvider>
);
