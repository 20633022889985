import { useNavigate } from 'react-router-dom';
import { usePagination } from '../../../context/PaginationContext';
import useLabourWithExpiringCertifications from '../../../hooks/api/useLabourWithExpiringCertifications';
import { useCallback, useMemo } from 'react';
import { Table } from '../../../components/Tables/tables/Table';
import moment from 'moment';

export function ExpiredCertificationsTable({
  projectId,
}: {
  projectId: string;
}) {
  const navigate = useNavigate();
  const { limit, offset, setOffset } = usePagination();

  const [expiringCertifications, { count }] =
    useLabourWithExpiringCertifications({
      projectId,
      dateFilter: 'EXPIRED',
      limit,
      offset,
    });

  const headerMapping = useMemo(
    () =>
      ({
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        type: 'Type',
        updatedAt: 'Updated',
      } as Record<string, any>),
    []
  );

  const totalPages = Math.ceil(count / limit);

  const onPageChange = useCallback(
    (pageNumber: number) => {
      setOffset((pageNumber - 1) * limit);
    },
    [limit, setOffset]
  );

  return (
    <Table
      currentPage={Math.floor(offset / limit) + 1}
      totalPages={totalPages}
      totalResults={count}
      itemsPerPage={limit}
      onClickRow={(id: any) => {
        const foundCertEntry = expiringCertifications?.find(
          (cert: any) => cert.id === id
        );

        navigate(
          `/labour/${foundCertEntry?.userProfile?.labourResource?.type?.toLowerCase()}s/${
            foundCertEntry?.userProfile?.labourResource?.id
          }`
        );
      }}
      filterDimensions={[]}
      showFilterOptions={false}
      headerMapper={(text: string) => headerMapping[text]}
      onPageChange={onPageChange}
      data={expiringCertifications.map(
        ({
          id,
          userProfile: {
            labourResource: {
              id: lrsId,
              firstName,
              lastName,
              email,
              type,
              updatedAt,
            },
          },
        }: any) => ({
          id,
          lrsId,
          firstName,
          lastName,
          email,
          type: type.charAt(0) + type.slice(1).toLowerCase(),
          updatedAt: moment.unix(parseInt(updatedAt) / 1000).fromNow(),
        })
      )}
    />
  );
}
