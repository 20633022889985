import { useEffect, useRef, useState } from 'react';
import { Modal } from '../../../../components/Dialogue/ModalDialogue';
import TextArea from '../../../../components/Inputs/TextArea';
import { useIndexedDB } from '../../../../hooks/useIndexedDB';
import { PersonnelEntry } from '../personnel/entry';

const mergePersonnelAndTypes = (personnelTypes: any[], personnel: any[]) => {
  const merged = {};

  personnelTypes?.forEach((type) => {
    // @ts-ignore
    merged[type.id] = { ...type };
  });

  personnel?.forEach((personnel) => {
    // @ts-ignore
    if (merged[personnel.id]) {
      // @ts-ignore
      merged[personnel.id] = { ...merged[personnel.id], ...personnel };
    } else {
      // @ts-ignore
      merged[personnel.id] = { ...personnel };
    }
  });

  return Object.values(merged);
};

export const PersonnelSubmission = ({
  selectedProject,
  personnel,
  setPersonnel,
  date,
  locked,
}: any) => {
  const bottomRef = useRef(null);

  const handlePersonnelChange = (
    personnelId?: string,
    field?: string,
    value?: any
  ) => {
    let found = false;
    const updatedPersonnel = personnel?.map((personnel: any) => {
      if (personnel.id === personnelId && field) {
        found = true;
        return {
          ...personnel,
          [field]: value,
          savedLocally: false,
          ...(!personnel.isNew && !personnel.toBeDeleted
            ? {
                hasChanged: true,
                original: personnel,
              }
            : {}),
        };
      }
      return personnel;
    });

    if (!found) {
      const newPersonnel = {
        id: personnelId,
        // @ts-ignore
        [field]: value,
        savedLocally: false,
        isNew: true,
        hasChanged: true,
      };
      updatedPersonnel.push(newPersonnel);
    }

    setPersonnel(updatedPersonnel);
  };

  const [modalPersonnelId, setModalPersonnelId] = useState<string | undefined>(
    undefined
  );

  const [commentBoxState, setCommentBoxState] = useState('');

  const { db, readItem: readLocalData } = useIndexedDB({
    objectStoreName: `cached_project_data`,
  });

  const fetchLocalPersonnelTypes = async () => {
    const item = await readLocalData(`personnelTypes_${selectedProject?.id}`);
    const personnelTypeResults = item?.personnelTypeResults;
    setPersonnel(mergePersonnelAndTypes(personnelTypeResults, personnel));
  };

  useEffect(() => {
    if (modalPersonnelId) {
      setCommentBoxState(
        personnel?.find((personnel: any) => personnel?.id === modalPersonnelId)
          ?.comment
      );
    }
  }, [modalPersonnelId]);

  useEffect(() => {
    if (db && selectedProject?.id) {
      fetchLocalPersonnelTypes();
    }
  }, [db, selectedProject?.id, date, personnel?.length]);

  return (
    <>
      <div>
        <Modal
          showModal={!!modalPersonnelId}
          setShowModal={setModalPersonnelId}
          completeButtonText={'Complete'}
          title={`Comment #${modalPersonnelId}`}
          onComplete={() => {
            if (!locked) {
              handlePersonnelChange(
                modalPersonnelId,
                'comment',
                commentBoxState
              );
            }
            setModalPersonnelId(undefined);
          }}
          component={
            <TextArea
              rows={8}
              value={commentBoxState ?? ''}
              handleChange={(value) => setCommentBoxState(value)}
              validation={''}
              isDisabled={locked}
              question={{
                id: '',
                title: '',
                placeholder: '',
                name: '',
                description: '',
                defaultText: '',
                classNames: '',
              }}
            />
          }
        />
        <hr />
        <div className="bg-white px-4 pt-2 flex flex-col gap-y-4">
          <PersonnelEntry
            date={date}
            locked={locked}
            projectId={selectedProject?.id}
            handlePersonnelChange={handlePersonnelChange}
            personnel={personnel}
            setModalPersonnelId={setModalPersonnelId}
          />
        </div>

        <div ref={bottomRef} />
      </div>
    </>
  );
};
