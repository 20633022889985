import moment from 'moment';
import { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { useNavigate } from 'react-router-dom';
import { Table } from '../../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../../context/PaginationContext';
import { GET_PROJECT_ASSIGNMENT_ROLES } from '../../../../graphql/queries/assignments';
import { useOrganisationAwareApollo } from '../../../../hooks/useOrganisationAwareApollo';

interface AssignmentRoleListProps {
  projectId: string;
  children?: React.ReactElement;
  showNewItemButton?: boolean;
  filterIds?: string[];
  onClickRow?: (id: string, results: any[]) => void;
}

const headerMapping = {
  name: 'Name',
  email: 'Email',
  updatedAt: 'Updated At',
} as Record<string, any>;

const AssignmentRolesTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
  filterText,
  setFilterText = () => {},
  selectedFilterDimension,
  setSelectedFilterDimension = () => {},
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    columnsToFilter={['firstName', 'lastName', 'id', 'status']}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    filterDimensions={[
      { id: 'firstName', name: 'First Name' },
      { id: 'lastName', name: 'Last Name' },
      { id: 'email', name: 'Email' },
    ]}
    showFilterOptions={false}
    filterText={filterText}
    setFilterText={setFilterText}
    selectedFilterDimension={selectedFilterDimension}
    setSelectedFilterDimension={setSelectedFilterDimension}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
  />
);

export const AssignmentRoleListPage = ({
  projectId,
  children,
  onClickRow,

  filterIds,
}: AssignmentRoleListProps) => {
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [filterText, setFilterText] = useState<string>();
  const [selectedFilterDimension, setSelectedFilterDimension] =
    useState<string>('lastName');

  const { limit, offset, setOffset } = usePagination();

  const [debouncedFilterText, setDebouncedSearchTerm] = useState(filterText);

  const filter = debouncedFilterText
    ? {
        filters: [
          {
            field: selectedFilterDimension,
            stringFilters: [{ ilike: debouncedFilterText }],
          },
        ],
      }
    : null;

  const [fetch, { data }] = useLazyQuery(GET_PROJECT_ASSIGNMENT_ROLES, {
    variables: { input: { limit, offset }, projectId, enabledOnly: true },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetch({
      variables: {
        input: { limit, offset, ...(filter ? { filter } : {}) },
        projectId,
        enabledOnly: true,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, debouncedFilterText, projectId]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setOffset(0);
      setDebouncedSearchTerm(filterText);
    }, 600);
    return () => {
      clearTimeout(handler);
    };
  }, [filterText]);

  const assignmentroleResults = data?.getAssignmentRoles;

  const totalPages = Math.ceil(assignmentroleResults?.count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  return (
    <div>
      <>
        <AssignmentRolesTable
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          totalResults={assignmentroleResults?.count || 0}
          onClickRow={(id: any) => {
            if (onClickRow) {
              onClickRow(id, assignmentroleResults?.results);
            }
          }}
          filterText={filterText}
          setFilterText={setFilterText}
          selectedFilterDimension={selectedFilterDimension}
          setSelectedFilterDimension={setSelectedFilterDimension}
          data={
            assignmentroleResults?.results.length > 0
              ? assignmentroleResults?.results
                  ?.filter((result: any) => !filterIds?.includes(result.id))
                  .map((result: any) => {
                    const assignmentRoleLrs = result?.assignment?.lrs;
                    const assignmentRoleDetails = result?.role;

                    return {
                      name: (
                        <div className="flex gap-x-2 items-center">
                          <Avatar
                            name={`${assignmentRoleLrs?.firstName} ${assignmentRoleLrs?.lastName}`}
                            size="30"
                            round
                          />
                          <div className="flex flex-col">
                            <div>{`${assignmentRoleLrs.lastName}, ${assignmentRoleLrs.firstName}`}</div>
                            <div className="text-sm text-gray-600">
                              {assignmentRoleDetails?.name}
                            </div>
                          </div>
                        </div>
                      ),
                      ...result,
                    };
                  })
              : [{}]
          }
          onPageChange={handlePageChange}
        />
      </>
      {children}
    </div>
  );
};

export const AssignmentRoleList = ({
  projectId,
  onClickRow,
  showNewItemButton = true,
  filterIds = [],
}: AssignmentRoleListProps) => (
  <PaginationProvider>
    <AssignmentRoleListPage
      projectId={projectId}
      showNewItemButton={showNewItemButton}
      onClickRow={onClickRow}
      filterIds={filterIds}
    />
  </PaginationProvider>
);
