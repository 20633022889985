import { useEffect, useState } from 'react';
import ValidationMessage from '../Validation/ValidationMessage';
import { Button } from '../Buttons/Button';

const ArrayInput = (props) => {
  const { question, handleChange, value, validation, isDisabled } = props;
  const {
    id,
    title,
    placeholder,
    name,
    classNames,
    description,
    valueTransformer,
    inputTransformer = (text) => text,
  } = question;

  useEffect(() => {
    setInputValues(value ?? []);
  }, [value]);

  const [inputValues, setInputValues] = useState(value ?? []);

  const handleInputChange = (index, newValue) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = inputTransformer(newValue);
    setInputValues(newInputValues);
    handleChange(newInputValues);
  };

  const addInput = () => {
    setInputValues([...inputValues, '']);
  };

  const removeInput = (index) => {
    const newInputValues = inputValues.filter((_, i) => i !== index);
    setInputValues(newInputValues);
    handleChange(newInputValues);
  };

  return (
    <div className={classNames}>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {title}
      </label>
      {validation && <ValidationMessage message={validation} />}
      <div className="ml-2 flex flex-col gap-y-2 mt-2">
        {inputValues?.map((value, index) => (
          <div
            className="flex items-center gap-x-2 justify-center"
            key={`${id}-${index}`}
          >
            <input
              type="text"
              id={`${id}-${index}`}
              name={name}
              value={valueTransformer ? valueTransformer(value) : value}
              disabled={isDisabled}
              onChange={(event) => handleInputChange(index, event.target.value)}
              autoComplete={name}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
              placeholder={placeholder}
            />
            <Button
              type="button"
              onClick={() => removeInput(index)}
              text={'Remove'}
            />
          </div>
        ))}
      </div>
      <div className="my-2">
        <Button type="button" onClick={addInput} text={'Add More'} />
      </div>
      {description && (
        <p className="mt-2 text-sm text-gray-500">{description}</p>
      )}
    </div>
  );
};

export default ArrayInput;
