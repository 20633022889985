import { gql } from '@apollo/client';

export const GET_MY_PERSONAL_DETAILS = gql`
  query getMyPersonalDetails($activeOrganisation: String!) {
    getMyPersonalDetails(activeOrganisation: $activeOrganisation) {
      id
      firstName
      lastName
      nationality
      countryOfResidence
      email
      updatedAt
      customData {
        key
        value
      }
    }
  }
`;

export const GET_MY_USER_PROFILE = gql`
  query getMyUserProfile($activeOrganisation: String!) {
    getMyUserProfile(activeOrganisation: $activeOrganisation) {
      personalDetails {
        firstName
        lastName
        email
        type
        updatedAt
      }
      missingOrInvalidCertifications {
        id
        name
        notes
      }
      projectFilter
      signedEULAVersion
      permissions
      groups
    }
  }
`;

export const GET_MY_NEXT_OF_KIN_DETAILS = gql`
  query getMyNextOfKinDetails($activeOrganisation: String!) {
    getMyNextOfKinDetails(activeOrganisation: $activeOrganisation) {
      name
      relationship
      address
      contactNumber
      createdAt
      updatedAt
    }
  }
`;

export const GET_MY_COMPANY_DETAILS = gql`
  query getMyCompanyDetails($activeOrganisation: String!) {
    getMyCompanyDetails(activeOrganisation: $activeOrganisation) {
      count
      results {
        id
        companyName
        companyRegNumber
        companyPhoneNumber
        vatRegistrationNumber
        companyAddress
        contactName
        companyEmail
        companyType
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_MY_BANKING_DETAILS = gql`
  query getMyBankingDetails(
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    getMyBankingDetails(
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        bankName
        nameOnAccount
        accountNumber
        sortCode
        iban
        bic
        currency
        enabled
        isBusinessAccount
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_MY_ASSIGNMENTS = gql`
  query getMyAssignments(
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    getMyAssignments(input: $input, activeOrganisation: $activeOrganisation) {
      count
      results {
        id
        enabled
        project {
          id
          name
          internalId
          startDate
          endDate
        }
        lrs {
          id
          firstName
          lastName
          type
        }
        assignmentRoleSummary {
          activeRoles {
            id
            name
          }
          inactiveRoles {
            id
            name
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_MY_CERTIFICATIONS = gql`
  query getMyCertifications(
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    getMyCertifications(
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        expiryDate
        issueDate
        certificationType {
          id
          name
          requiresExpiryDate
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_MY_ASSESSMENT_SUBMISSIONS = gql`
  query getMyAssessmentSubmissions(
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    getMyAssessmentSubmissions(
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        assessmentType {
          id
          name
          outcomes
          assessmentInformation
          questions {
            id
            question
            type
            choices {
              id
              name
            }
          }
        }
        assignmentRole {
          role {
            name
          }
          assignment {
            project {
              name
            }
          }
        }
        company {
          id
          companyName
        }
        project {
          id
          name
        }
        outcome
        responses {
          question
          answer
        }
        createdAt
        updatedAt
      }
    }
  }
`;
