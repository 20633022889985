import { gql } from '@apollo/client';

export const SAVE_PROGRESS_ACTIVITY_CATEGORY = gql`
  mutation saveProgressActivityCategory(
    $id: ID
    $input: SaveProgressActivityCategory!
    $activeOrganisation: String!
  ) {
    saveProgressActivityCategory(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const SAVE_PROGRESS_INCIDENT_TYPE = gql`
  mutation saveProgressIncidentType(
    $id: ID
    $input: SaveIncidentInput!
    $activeOrganisation: String!
  ) {
    saveProgressIncidentType(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const SAVE_PROGRESS_INCIDENT_TYPES = gql`
  mutation saveProgressIncidentTypes(
    $input: BulkItemInput!
    $activeOrganisation: String!
  ) {
    saveProgressIncidentTypes(
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const SAVE_PROGRESS_ACTIVITY_CATEGORIES = gql`
  mutation saveProgressActivityCategories(
    $input: BulkItemInput!
    $activeOrganisation: String!
  ) {
    saveProgressActivityCategories(
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const SAVE_PROGRESS_CONDITION_TYPE = gql`
  mutation saveProgressConditionType(
    $id: ID
    $input: SaveConditionInput!
    $activeOrganisation: String!
  ) {
    saveProgressConditionType(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const SAVE_PROGRESS_ACTIVITY_SUBMISSION = gql`
  mutation saveProgressSubmission(
    $input: SaveProgressSubmissionInput!
    $activeOrganisation: String!
  ) {
    saveProgressSubmission(
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;
