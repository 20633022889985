import { useState } from 'react';

export const useOnlineOfflineModeState = () => {
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [showLocalSyncModal, setShowLocalSyncModal] = useState(false);
  const [showOfflineModal, setShowOfflineModal] = useState(false);
  const [failedOnlineSync, setFailedOnlineSync] = useState(false);
  const [summaryHasChanged, setSummaryHasChanges] = useState(false);
  const [statusHasChanged, setStatusHasChanges] = useState(false);
  const [unsavedChangesOnCompletionNav, setUnsavedChangesOnCompletionNav] =
    useState('');

  return {
    showUnsavedChangesModal,
    setShowUnsavedChangesModal,
    showLocalSyncModal,
    setShowLocalSyncModal,
    showOfflineModal,
    setShowOfflineModal,
    failedOnlineSync,
    setFailedOnlineSync,
    summaryHasChanged,
    setSummaryHasChanges,
    statusHasChanged,
    setStatusHasChanges,
    unsavedChangesOnCompletionNav,
    setUnsavedChangesOnCompletionNav,
  };
};
