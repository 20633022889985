import { gql } from '@apollo/client';

export const SAVE_MY_PERSONAL_DETAILS = gql`
  mutation saveMyPersonalDetails(
    $input: SavePersonalDetailsInput!
    $activeOrganisation: String!
  ) {
    saveMyPersonalDetails(
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;
