import moment from 'moment';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Table } from '../../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../../context/PaginationContext';

import { GET_CONTRACT_SUBMISSIONS } from '../../../../graphql/queries/contracts';
import { useOrganisationAwareApollo } from '../../../../hooks/useOrganisationAwareApollo';
import { GreenBadge, YellowBadge } from '../../../../components/Badges/Badges';
import { URLDropdown } from '../../../../components/Dropdown/URLDropdown';

interface SentContractsListForAssigneeProps {
  projectId: string;
  labourResourceId: string;
}

const headerMapping = {
  contractName: 'Contract Name',
  status: 'Status',
  role: 'Role Name',
  signerEmail: 'Signer Email',
  organisationSignerName: 'Org Signer Name',
  organisationSignerEmail: 'Org Signer Email',
  configure: 'Options',
  createdAt: 'Created At',
  updatedAt: 'Updated At',
} as Record<string, any>;

const SentContractsTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    columnsToFilter={['firstName', 'lastName', 'id', 'signerSlug', 'updatedAt']}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
      createdAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
  />
);

export const SentContractsListForAssigneePage = ({
  projectId,
  labourResourceId,
}: SentContractsListForAssigneeProps) => {
  const { assignmentRoleId } = useParams();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const { limit, offset, setOffset } = usePagination();

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const [fetchContractSubmissions, { data: contractSubmissionsData }] =
    useLazyQuery(GET_CONTRACT_SUBMISSIONS, {
      variables: { projectId, labourResourceId },
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    fetchContractSubmissions({ variables: { projectId, labourResourceId } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentRoleId]);

  const contractSubmissionsResults =
    contractSubmissionsData?.getContractSubmissions?.results || [];

  return (
    <div className="bg-gray-50">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="border-t border-gray-200 px-4">
          <div className="w-full flex justify-between items-center py-2">
            <h1 className="text-lg font-bold py-4">Sent Contracts</h1>
            <div className="flex flex-row gap-x-2"></div>
          </div>
          <SentContractsTable
            currentPage={Math.floor(offset / limit) + 1}
            totalPages={0}
            itemsPerPage={limit}
            totalResults={0}
            onClickRow={(_id: any) => {}}
            data={
              contractSubmissionsResults.length > 0
                ? contractSubmissionsResults.map((result: any) => ({
                    contractName: result?.contract?.name,
                    role: result?.assignmentRole?.role?.name,
                    status:
                      result.status === 'COMPLETED' ? (
                        <GreenBadge text="Completed" />
                      ) : (
                        <YellowBadge text={'Submitted'} />
                      ),
                    createdAt: result?.createdAt,
                    organisationSignerName: result?.organisationSignerName,
                    organisationSignerEmail: result?.organisationSignerEmail,
                    options:
                      result.status === 'COMPLETED' ? (
                        <URLDropdown
                          urls={result?.completedDocumentURLs?.map(
                            (url: any) => ({
                              url: url.url,
                              name: `Completed Document (${url.name})`,
                            })
                          )}
                        />
                      ) : null,
                    contract: undefined,
                    assignmentRole: undefined,
                  }))
                : [{}]
            }
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export const SentContractsListForAssignee = ({
  labourResourceId,
  projectId,
}: SentContractsListForAssigneeProps) => (
  <PaginationProvider>
    <SentContractsListForAssigneePage
      labourResourceId={labourResourceId}
      projectId={projectId}
    />
  </PaginationProvider>
);
