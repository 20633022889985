interface RatingChipsProps {
  rating: number;
}

const RatingChips = ({ rating }: RatingChipsProps) => {
  const maxChips = 5;
  const ratingPerChip = 10 / maxChips;

  const chipColor = (index: number) => {
    const threshold = ratingPerChip * (index + 1);
    if (rating >= threshold) return 'bg-green-500';
    if (rating >= threshold - ratingPerChip / 2) return 'bg-yellow-500';
    return 'bg-gray-300';
  };

  return (
    <div className="flex space-x-1">
      {Array.from({ length: maxChips }, (_, index) => (
        <div
          key={index}
          className={`w-4 h-4 rounded-md ${chipColor(index)}`}
        ></div>
      ))}
    </div>
  );
};

export default RatingChips;
