import DropDown from '../../../../components/Inputs/Dropdown';

export const CustomDataOptionsInput = ({
  customDataOptions,
  setFieldValue,
  entryIndex,
  values,
  entry,
  disabled,
}: any) => {
  if (customDataOptions?.length === 0) return null;

  return (
    <div className="bg-gray-100 flex flex-col md:flex-row w-full justify-around border-t py-2 px-4">
      {customDataOptions?.map((option: any, optIndex: any) => (
        <div key={optIndex} className="flex items-center gap-x-4">
          <label
            htmlFor={option.name}
            className="w-full md:w-24 block text-gray-700 text-sm font-bold"
          >
            {option.name}:
          </label>
          <DropDown
            id={'customData'}
            // @ts-ignore
            question={{
              options: option.options?.map((opt: any) => ({
                id: opt.value,
                name: opt.name,
                value: opt.value,
              })),
            }}
            handleChange={(selectedValue) => {
              setFieldValue(
                `entries.${entryIndex}.customData.${option.name}`,
                selectedValue
              );
            }}
            value={values.entries?.[entryIndex]?.customData?.[option.name]}
            isDisabled={entry.disabled || disabled}
          />
        </div>
      ))}
    </div>
  );
};
