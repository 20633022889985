import { Switch as UISwitch } from '@headlessui/react';
import { joinClassNames } from '../../utils/utils';

interface BoxConfig {
  handleChange: (checked: boolean) => void;
  text: string;
  enabled: boolean;
  isDisabled?: boolean;
  description?: string;
}

const Switch = ({
  handleChange,
  text,
  enabled,
  isDisabled,
  description,
}: BoxConfig) => {
  return (
    <>
      <UISwitch.Group as="div" className="flex items-center">
        <UISwitch
          checked={enabled}
          disabled={isDisabled}
          onChange={(event: any) => {
            handleChange(event);
          }}
          className={joinClassNames(
            enabled ? 'bg-green-500' : 'bg-gray-200',
            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black'
          )}
        >
          <span
            aria-hidden="true"
            className={joinClassNames(
              enabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
            )}
          />
        </UISwitch>
        <UISwitch.Label as="span" className="ml-3">
          <span className="text-sm font-medium text-gray-900">{text}</span>
        </UISwitch.Label>
      </UISwitch.Group>
      {description && (
        <div className="text-left mt-1 text-xs font-medium text-gray-500">
          {description}
        </div>
      )}
    </>
  );
};

export default Switch;
