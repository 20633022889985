import { XMarkIcon } from '@heroicons/react/20/solid';

export const ClearProjectButton = ({
  values,
  setFieldValue,
  entry,
  disabled,
}: any) => {
  const handleClick = () => {
    const existingEntry = values?.entries.find(
      (theEntries: any) => theEntries.projectId === entry.projectId
    );

    if (existingEntry?.unsaved) {
      setFieldValue(
        'entries',
        values.entries.filter(
          (theEntries: any) => theEntries.projectId !== existingEntry?.projectId
        )
      );
    } else {
      const newEntries = values?.entries.map((theEntry: any) => {
        if (theEntry.id === entry.id) {
          return {
            ...theEntry,
            delete: !entry.delete,
          };
        }
        return theEntry;
      });

      setFieldValue('entries', newEntries);
    }
  };

  return (
    <div className="flex w-full justify-end border-b mb-2 pb-2">
      <div
        role="button"
        aria-disabled={disabled}
        className="bg-black flex disabled:bg-gray-500 items-center justify-center hover:bg-red-600 rounded-full"
        onClick={disabled ? () => {} : handleClick}
      >
        <XMarkIcon
          className="h-6 w-6 flex items-center justify-center text-white"
          aria-hidden="true"
        />
      </div>
    </div>
  );
};
