import { useNavigate } from 'react-router-dom';
import { usePagination } from '../../../context/PaginationContext';
import { useCallback, useMemo } from 'react';
import { Table } from '../../../components/Tables/tables/Table';
import moment from 'moment';
import useAssignmentsWithMissingOrIncompleteAssessments, {
  IncompleteAssessmentFilter,
} from '../../../hooks/api/assessments/useAssignmentsWithMissingOrIncompleteAssessments';

export function AssessmentsTable({
  projectId,
  filter,
}: {
  projectId: string;
  filter?: IncompleteAssessmentFilter;
}) {
  const navigate = useNavigate();
  const { limit, offset, setOffset } = usePagination();

  const [assignments, { count }] =
    useAssignmentsWithMissingOrIncompleteAssessments({
      projectId,
      filter,
      limit,
      offset,
    });

  const headerMapping = useMemo(
    () =>
      ({
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        updatedAt: 'Updated',
      } as Record<string, any>),
    []
  );

  const totalPages = Math.ceil(count / limit);

  const onPageChange = useCallback(
    (pageNumber: number) => {
      setOffset((pageNumber - 1) * limit);
    },
    [limit, setOffset]
  );

  return (
    <Table
      currentPage={Math.floor(offset / limit) + 1}
      totalPages={totalPages}
      totalResults={count}
      itemsPerPage={limit}
      onClickRow={(id: any) => {
        navigate(`/projects/${projectId}/assignments/${id}`);
      }}
      filterDimensions={[]}
      showFilterOptions={false}
      headerMapper={(text: string) => headerMapping[text]}
      onPageChange={onPageChange}
      data={assignments.map(
        ({
          id,
          lrs: { firstName, lastName, email, type },
          updatedAt,
        }: any) => ({
          id,
          firstName,
          lastName,
          email,
          type,
          updatedAt: moment.unix(parseInt(updatedAt) / 1000).fromNow(),
        })
      )}
    />
  );
}
