import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
// @ts-ignore
import L from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';

import 'leaflet/dist/leaflet.css';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

type Marker = {
  name: String;
  latitude: Number;
  longitude: Number;
  detail?: String;
};
interface MapProps {
  CustomPopupContent?: ({ marker }: any) => any;
  markers: Marker[];
  useClustering?: boolean;
}

function calculateZoomLevel(markers: Marker[]) {
  if (!markers || markers.length === 0) {
    return 13;
  }

  let minLat = markers[0].latitude,
    maxLat = markers[0].latitude,
    minLng = markers[0].longitude,
    maxLng = markers[0].longitude;

  markers.forEach((marker: Marker) => {
    if (marker.latitude < minLat) minLat = marker.latitude;
    if (marker.latitude > maxLat) maxLat = marker.latitude;
    if (marker.longitude < minLng) minLng = marker.longitude;
    if (marker.longitude > maxLng) maxLng = marker.longitude;
  });

  // @ts-ignore
  const latDiff = maxLat - minLat;
  // @ts-ignore
  const lngDiff = maxLng - minLng;

  let zoomLevel = 12;
  if (Math.max(latDiff, lngDiff) > 10) zoomLevel = 3;
  else if (Math.max(latDiff, lngDiff) > 5) zoomLevel = 5;
  else if (Math.max(latDiff, lngDiff) > 2) zoomLevel = 7;
  else if (Math.max(latDiff, lngDiff) > 1) zoomLevel = 9;
  else if (Math.max(latDiff, lngDiff) > 0.5) zoomLevel = 10;
  else if (Math.max(latDiff, lngDiff) > 0.1) zoomLevel = 11;

  return zoomLevel;
}

const PopupContent = ({ marker }: any) => (
  <>
    {marker.name}
    <p>{marker.detail}</p>
  </>
);

const Map = ({
  markers = [],
  CustomPopupContent = PopupContent,
  useClustering = true,
}: MapProps) => {
  const validMarkers = markers?.filter(
    (marker) => marker.latitude && marker.longitude
  );

  let centerLatitude = 51.505;
  let centerLongitude = -0.09;
  let zoomLevel = calculateZoomLevel(markers);

  if (validMarkers.length > 0) {
    centerLatitude =
      validMarkers.reduce((acc: any, cur: any) => acc + cur.latitude, 0) /
      validMarkers.length;
    centerLongitude =
      validMarkers.reduce((acc: any, cur: any) => acc + cur.longitude, 0) /
      validMarkers.length;
    zoomLevel = 4;
  }

  const MapClusterWrapper = ({ children }: any) =>
    useClustering ? (
      <MarkerClusterGroup chunkedLoading>{children}</MarkerClusterGroup>
    ) : (
      children
    );

  return validMarkers.length > 0 ? (
    // @ts-ignore
    <MapContainer
      key={markers.length}
      // @ts-ignore
      center={[centerLatitude, centerLongitude]}
      zoom={zoomLevel}
      style={{ height: '50vh', width: '100%', zIndex: 1 }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        // @ts-ignore
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <MapClusterWrapper>
        {validMarkers.map((marker: Marker) => (
          <Marker position={[marker.latitude, marker.longitude]}>
            <Popup>
              <CustomPopupContent marker={marker} />
            </Popup>
          </Marker>
        ))}
      </MapClusterWrapper>
    </MapContainer>
  ) : null;
};

export default Map;
