import { useOrganisationAwareApollo } from '../useOrganisationAwareApollo';
import { ApiHookMeta } from './types';
import { GET_PROJECT_ASSIGNMENTS } from '../../graphql/queries/assignments';

export default function useAssignments({
  assigneeId,
  companyId,
  projectId,
  id,
  limit,
  offset,
  filter,
}: {
  assigneeId?: string;
  projectId?: string;
  companyId?: string;
  id?: string;
  limit?: number;
  offset?: number;
  filter?: any;
}): [any, ApiHookMeta] {
  const { useQuery } = useOrganisationAwareApollo();

  const { data, loading, error, ...result } = useQuery(
    GET_PROJECT_ASSIGNMENTS,
    {
      variables: {
        assigneeId,
        companyId,
        projectId,
        input: { id, limit, offset, filter },
      },
      fetchPolicy: 'network-only',
    }
  );

  const results = data?.getAssignments?.results || [];
  const count = data?.getAssignments?.count || 0;

  return [
    results,
    {
      count,
      loading,
      error,
      ...result,
    },
  ];
}

export function useAssignment({
  id,
  projectId,
}: {
  id: string;
  projectId: string;
}) {
  const [assignments, meta] = useAssignments({ id, projectId });

  const [assignment] = assignments;

  return [assignment, meta];
}
