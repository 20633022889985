import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ValidationMessage from '../Validation/ValidationMessage';

const RichTextInput = (props) => {
  const { question, handleChange, value, validation, isDisabled } = props;
  const { id, title, classNames, description } = question;

  return (
    <div className={classNames}>
      <label
        htmlFor={id}
        className="block mb-2 text-sm font-medium text-gray-700"
      >
        {title}
      </label>
      {validation ? <ValidationMessage message={validation} /> : null}
      <CKEditor
        id={id}
        editor={ClassicEditor}
        config={{
          toolbar: [
            'heading',
            'bold',
            'italic',
            'bulletedList',
            'numberedList',
            'blockQuote',
            'link',
          ],
        }}
        disabled={isDisabled}
        data={value ?? ''}
        onChange={(_event, editor) => {
          const data = editor.getData();
          handleChange(data);
        }}
      />
      {description ? (
        <p className="mt-2 text-sm text-gray-500">{description}</p>
      ) : null}
    </div>
  );
};

export default RichTextInput;
