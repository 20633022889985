import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { useEffect } from 'react';

import FormCard from '../../../components/FormCard/FormCard';
import { SAVE_MY_NEXT_OF_KIN } from '../../../graphql/mutations/next-of-kin';

import { GET_MY_NEXT_OF_KIN_DETAILS } from '../../../graphql/queries/my-profile';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { FormConfig } from '../../../types/Form';
import { useToast } from '../../../context/ToastContext';

export const SaveMyNextOfKin = () => {
  const navigate = useNavigate();
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const { addToast } = useToast();

  const [fetchNextOfKins, { data }] = useLazyQuery(GET_MY_NEXT_OF_KIN_DETAILS, {
    variables: {},
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchNextOfKins({ variables: {} });
  }, []);

  const nextofkin = data?.getMyNextOfKinDetails || {};

  const [saveMyNextOfKin] = useMutation(SAVE_MY_NEXT_OF_KIN);

  const initialValues = {
    ...nextofkin,
  };

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      await saveMyNextOfKin({
        variables: {
          id: values?.id,
          input: {
            ...values,
            id: undefined,
            updatedAt: undefined,
            createdAt: undefined,
          },
        },
      });
      addToast('Next of Kin details updated', 'success');
      if (setSubmitting) {
        setSubmitting(false);
      }
      navigate(-1);
    } catch (error) {
      console.error(error);
      addToast('Failed to update Next of Kin details', 'error');
    }
  };

  const nextofkinConfig = {
    formSections: [
      {
        title: 'Next of Kin',
        description: 'Basic details about the Next Of Kin',
        fields: [
          {
            id: 'name',
            type: 'input',
            title: 'Next Of Kin Name',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'relationship',
            type: 'input',
            title: 'Relationship',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'address',
            type: 'textarea',
            title: 'Address',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'contactNumber',
            type: 'input',
            title: 'Contact Number',
            inputTransformer: (text: string) => text,
          },
        ],
      },
    ],
  } as FormConfig;

  const NextOfKinCreationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required(),
    relationship: Yup.string()
      .min(2, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required(),
    address: Yup.string()
      .min(2, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required(),
    contactNumber: Yup.string()
      .min(2, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required(),
  });

  return (
    // @ts-ignore
    <FormCard
      key={`${nextofkinConfig?.title}`}
      config={nextofkinConfig}
      validationSchema={NextOfKinCreationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
};
