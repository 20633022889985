import { gql } from '@apollo/client';

export const SAVE_ASSIGNMENT = gql`
  mutation saveAssignment(
    $id: ID
    $input: SaveAssignmentInput!
    $activeOrganisation: String!
  ) {
    saveAssignment(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const SAVE_ASSIGNMENT_ROLE = gql`
  mutation saveAssignmentRole(
    $id: ID
    $input: SaveAssignmentRoleInput!
    $activeOrganisation: String!
  ) {
    saveAssignmentRole(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
      id
    }
  }
`;

export const SEND_ASSESSMENTS_FOR_ASSIGNMENT = gql`
  mutation sendAssessmentsForAssignment(
    $id: ID!
    $activeOrganisation: String!
  ) {
    sendAssessmentsForAssignment(
      id: $id
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const SAVE_TEAM_ASSIGNMENT = gql`
  mutation saveProjectTeamAssignment(
    $id: ID
    $input: SaveProjectTeamAssignmentInput!
    $activeOrganisation: String!
  ) {
    saveProjectTeamAssignment(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const SAVE_ASSIGNMENT_ROLE_EXEMPTION = gql`
  mutation saveAssignmentRoleExemption(
    $id: ID
    $input: SaveAssignmentRoleExemptionInput!
    $activeOrganisation: String!
  ) {
    saveAssignmentRoleExemption(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;

export const DELETE_ASSIGNMENT_ROLE = gql`
  mutation deleteAssignmentRole($activeOrganisation: String!, $id: ID) {
    deleteAssignmentRole(activeOrganisation: $activeOrganisation, id: $id) {
      id
      success
      message
    }
  }
`;
