import { useEffect } from 'react';
import { GET_PROJECT_CERTIFICATIONS } from '../../../graphql/queries/certifications';
import { useOrganisationAwareApollo } from '../../useOrganisationAwareApollo';
import { ApiHookMeta } from '../types';

export default function useProjectCertifications({
  projectId,
  certificationTypeId,
  limit,
  offset,
}: {
  projectId?: string;
  certificationTypeId?: string;
  limit: number;
  offset: number;
}): [any, ApiHookMeta] {
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [fetchProjectCertifications, { data, loading, error, ...result }] =
    useLazyQuery(GET_PROJECT_CERTIFICATIONS, {
      variables: { projectId, certificationTypeId },
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    fetchProjectCertifications({
      variables: {
        projectId,
        input: {
          limit,
          offset,
        },
      },
    });
  }, [fetchProjectCertifications, limit, offset, projectId]);

  const projectCertifications = data?.getProjectCertifications?.results || [];
  const count = data?.getProjectCertifications?.count || 0;

  return [
    projectCertifications,
    {
      count,
      loading,
      error,
      ...result,
    },
  ];
}
