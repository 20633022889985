import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { useEffect } from 'react';

import FormCard from '../../../components/FormCard/FormCard';

import { SAVE_MY_PERSONAL_DETAILS } from '../../../graphql/mutations/personal';
import { GET_MY_PERSONAL_DETAILS } from '../../../graphql/queries/my-profile';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { FormConfig } from '../../../types/Form';
import { useToast } from '../../../context/ToastContext';
import { GET_CUSTOM_FIELDS } from '../../../graphql/queries/custom-data';

export const SaveMyPersonalDetails = () => {
  const navigate = useNavigate();
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const { addToast } = useToast();

  const [fetchPersonalDetailss, { data }] = useLazyQuery(
    GET_MY_PERSONAL_DETAILS,
    {
      variables: {},
      fetchPolicy: 'network-only',
    }
  );

  const personaldetails = data?.getMyPersonalDetails || {};

  const [fetchCustomFields, { data: customFieldsData }] = useLazyQuery(
    GET_CUSTOM_FIELDS,
    {
      variables: {
        labourResourceId: personaldetails?.id,
        onlyEnabled: true,
      },
      fetchPolicy: 'network-only',
    }
  );

  const customFields = (
    customFieldsData?.getCustomFieldGroups?.results || []
  )?.filter((fieldGroup: any) => !fieldGroup.private);

  useEffect(() => {
    fetchPersonalDetailss({ variables: {} });
    fetchCustomFields();
  }, []);

  const personalCustomData = personaldetails?.customData || [];

  const [saveMyPersonalDetails] = useMutation(SAVE_MY_PERSONAL_DETAILS);

  const initialValues = {
    ...personaldetails,
    ...personalCustomData.reduce(
      (acc: Record<string, any>, customItem: any) => {
        acc[`custom:${customItem.key}`] = customItem.value;
        return acc;
      },
      {}
    ),
  };

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const customData: { key: string; value: any }[] = [];
    const filteredValues = Object.keys(values).reduce(
      (acc: Record<string, string>, key) => {
        if (key.startsWith('custom:')) {
          customData.push({
            key: key?.replace('custom:', ''),
            value: values[key],
          });
        } else {
          acc[key] = values[key];
        }
        return acc;
      },
      {}
    );
    try {
      await saveMyPersonalDetails({
        variables: {
          id: filteredValues?.id,
          input: {
            ...filteredValues,
            customData,
            email: undefined,
            id: undefined,
            updatedAt: undefined,
            createdAt: undefined,
          },
        },
      });
      addToast('Personal details updated', 'success');
      if (setSubmitting) {
        setSubmitting(false);
      }
      navigate(-1);
    } catch (error) {
      console.error(error);
      addToast('Failed to update personal details', 'error');
    }
  };

  const personaldetailsConfig = {
    formSections: [
      {
        title: 'Personal Details',
        description: 'Your Personal Details',
        fields: [
          {
            id: 'firstName',
            type: 'input',
            title: 'Your First Name',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'lastName',
            type: 'input',
            title: 'Your Last Name',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'nationality',
            type: 'input',
            title: 'Nationality',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'countryOfResidence',
            type: 'input',
            title: 'Country of Residence',
            inputTransformer: (text: string) => text,
          },
        ],
      },
      ...(customFields?.length > 0
        ? customFields.map((field: any) => ({
            title: field.name,
            fields: field.custom_fields.map((customField: any) => ({
              title: customField.name,
              id: `custom:${customField.id}`,
              type: customField.type === 'longText' ? 'textarea' : 'input',
              description: customField.description,
            })),
          }))
        : []),
    ],
  } as FormConfig;

  const PersonalDetailsCreationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required(),
    lastName: Yup.string()
      .min(2, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required(),
  });

  return (
    // @ts-ignore
    <FormCard
      key={`${personaldetailsConfig?.title}`}
      config={personaldetailsConfig}
      validationSchema={PersonalDetailsCreationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
};
