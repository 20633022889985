import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';

import moment from 'moment';
import Avatar from 'react-avatar';
import {
  ActiveBadge,
  BlueBadge,
  DisabledBadge,
  OrangeBadge,
  YellowBadge,
} from '../../../components/Badges/Badges';
import { Button } from '../../../components/Buttons/Button';
import { ActiveOrganisationContext } from '../../../context/ActiveOrganisationContext';
import useAssignments from '../../../hooks/api/useAssignments';
import NotificationIcon from '../../../components/Notification/NotificationIcon';
import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import MissingCertifications from '../../../components/MissingCertifications';
import { LoadingSpinner } from '../../../components/Loading/LoadingSpinner';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

interface ProjectAssignmentListProps {
  children?: React.ReactElement;
  projectId?: string;
  assigneeId?: string;
}

const headerMapping = {
  name: 'Assignee Name',
  status: 'Status',
  type: 'Type',
  complianceIssues: 'Compliance Warning',
  updatedAt: 'Updated At',
} as Record<string, any>;

export const AssignmentRow = ({
  id,
  rowData,
  onClickRow,
}: {
  id: string;
  rowData: any;
  onClickRow: (id: string) => void;
}) => {
  const complianceIssues = rowData?.missingOrInvalidCertifications?.length > 0;

  const [complianceCheckOpen, setComplianceCheckOpen] = useState(false);

  return (
    <div key={`${id}_row`}>
      <div
        className={`border-t flex flex-col w-full justify-around items-center lg:flex-row border-gray-200 hover:bg-gray-100`}
      >
        {rowData?.name ? (
          <div key={id} className="py-3 px-6 text-sm text-left w-full">
            <div className="flex flex-col flex-wrap gap-y-1 gap-x-2 mt-2">
              <div className="flex gap-x-2 items-center">
                <Avatar name={rowData.name} size="30" round />
                {rowData.name}
              </div>
              <hr className="my-2" />
              <div className="flex items-center gap-x-2 mb-1">
                <label className="text-xs">Company:</label>
                <div>
                  {rowData?.company ? (
                    <BlueBadge text={rowData?.company} />
                  ) : (
                    <OrangeBadge text="No Company Defined" />
                  )}
                </div>
              </div>
              <div className="flex items-center gap-x-2">
                <label className="text-xs">Type:</label>{' '}
                <div>
                  <YellowBadge text={rowData?.type} />
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {rowData?.project ? (
          <div key={id} className="py-3 px-6 text-sm text-left w-full">
            <div className="flex flex-col flex-wrap gap-y-2 gap-x-2 mt-2">
              Project: {rowData?.project}
              {rowData?.projectStartAndEndDate ? (
                <p className="text-gray-500">
                  {rowData?.projectStartAndEndDate}
                </p>
              ) : null}
            </div>
          </div>
        ) : null}
        <div className="py-3 px-6 text-sm text-left items-center gap-x-2 text-black w-full">
          Roles:
          <div className="flex flex-wrap gap-y-2 gap-x-2 mt-2">
            {rowData?.activeRoles.length > 0
              ? rowData?.activeRoles.map((role: any) => (
                  <ActiveBadge key={role.id} text={role} />
                ))
              : null}
            {rowData?.activeRoles.length === 0 ? (
              <p className="text-gray-400">No Active Roles</p>
            ) : null}
            {rowData?.inactiveRoles.length > 0
              ? rowData?.inactiveRoles.map((role: any) => (
                  <DisabledBadge key={role.id} text={role} />
                ))
              : null}
          </div>
          <hr className="mt-2 " />
          <div
            role={complianceIssues ? 'button' : undefined}
            onClick={
              complianceIssues
                ? () => setComplianceCheckOpen(!complianceCheckOpen)
                : () => null
            }
            className="flex items-center gap-x-2 mt-4"
          >
            <label className="text-xs">Certifications:</label>{' '}
            <div>{rowData?.complianceIssues}</div>
          </div>
        </div>

        <div
          key={id}
          className="py-3 px-6 text-sm flex items-center justify-end  gap-x-2 text-black w-full"
        >
          <Button
            text="View Assignment"
            onClick={() => onClickRow(rowData?.id)}
          />
        </div>
      </div>
      {complianceCheckOpen && complianceIssues ? (
        <MissingCertifications
          key={`${id}_compliance`}
          missingOrInvalidCertifications={
            rowData?.missingOrInvalidCertifications
          }
        >
          {undefined}
        </MissingCertifications>
      ) : null}
    </div>
  );
};

const ProjectAssignmentsTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
  filterText,
  filterDimensions = [],
  setFilterText = () => {},
  selectedFilterDimension,
  setSelectedFilterDimension = () => {},
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
    }}
    filterDimensions={filterDimensions}
    showFilterOptions
    filterText={filterText}
    setFilterText={setFilterText}
    selectedFilterDimension={selectedFilterDimension}
    setSelectedFilterDimension={setSelectedFilterDimension}
    RowComponent={({ id, rowData }) => (
      <AssignmentRow id={id!} rowData={rowData} onClickRow={onClickRow} />
    )}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
  />
);

export const ProjectAssignmentListPage = ({
  children,
  projectId,
  assigneeId,
}: ProjectAssignmentListProps) => {
  const navigate = useNavigate();

  const [filterText, setFilterText] = useState<string>();
  const [selectedFilterDimension, setSelectedFilterDimension] =
    useState<string>(projectId ? 'lastName' : 'projectName');

  const { limit, offset, setOffset } = usePagination();

  const [debouncedFilterText, setDebouncedSearchTerm] = useState(filterText);

  const { activeOrganisation } = useContext(ActiveOrganisationContext);

  const filter = useMemo(
    () =>
      debouncedFilterText
        ? {
            filters: [
              {
                field: selectedFilterDimension,
                stringFilters: [{ ilike: debouncedFilterText }],
              },
            ],
          }
        : null,
    [debouncedFilterText, selectedFilterDimension]
  );

  const [assignments, { count, loading }] = useAssignments({
    assigneeId,
    projectId,
    limit,
    offset,
    filter,
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      setOffset(0);
      setDebouncedSearchTerm(filterText);
    }, 600);
    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText]);

  const totalPages = Math.ceil(count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  return (
    <div>
      <>
        <hr />
        {loading ? (
          <p className="px-4 py-2 text-md">Loading...</p>
        ) : (
          <p className="px-4 py-2 text-md">Total Assignees: {count ?? 0}</p>
        )}
        <hr />
        {loading ? (
          <div className="w-full flex py-2 justify-center">
            <LoadingSpinner spinnerStyle={{ borderColor: 'black' }} />
          </div>
        ) : null}
        <div className="text-sm mt-2 px-4 py-2">
          <div className="inline-flex gap-x-2">
            <InformationCircleIcon className="w-5 h-5" />
            <p>
              Certification warnings here also apply to disabled / not-yet
              enabled roles - however they do not apply to{' '}
              <strong>archived roles</strong>. Certification warnings will not
              contribute to overall compliance for a project unless the
              non-compliant role is enabled.
            </p>
          </div>
        </div>
        <ProjectAssignmentsTable
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          totalResults={count || 0}
          onClickRow={(id: any) => {
            const matchingAssignment = assignments.find(
              (assignment: any) => assignment.id === id
            );
            navigate(
              `/projects/${matchingAssignment?.project?.id}/assignments/${id}`
            );
          }}
          filterDimensions={
            projectId
              ? [
                  { id: 'lastName', name: 'Last Name' },
                  { id: 'companyName', name: 'Company Name' },
                  { id: 'firstName', name: 'First Name' },
                  { id: 'role', name: 'Role Name' },
                ]
              : [
                  { id: 'projectName', name: 'Project Name' },
                  { id: 'projectId', name: 'Project ID' },
                  { id: 'role', name: 'Role Name' },
                ]
          }
          data={
            assignments.length > 0
              ? assignments.map((assignment: any) => ({
                  id: assignment.id,
                  name: `${assignment.lrs?.lastName}, ${assignment.lrs?.firstName}`,
                  company:
                    assignment.lrs?.type === 'EMPLOYEE'
                      ? activeOrganisation?.companyName
                      : assignment?.lrs?.company?.companyName ??
                        assignment?.lrs?.userProfile?.cps?.[0]?.companyName,
                  project: projectId
                    ? undefined
                    : `${assignment.project.name} - ${assignment.project.internalId}`,
                  missingOrInvalidCertifications:
                    assignment?.missingOrInvalidCertifications,
                  complianceIssues:
                    assignment?.missingOrInvalidCertifications?.length > 0 ? (
                      <NotificationIcon />
                    ) : (
                      <CheckBadgeIcon className="w-5 text-green-500" />
                    ),
                  projectStartAndEndDate: (
                    <>
                      {assignment?.project?.startDate
                        ? moment(Number(assignment?.project?.startDate)).format(
                            'll'
                          )
                        : 'No Start Date'}
                      {' - '}
                      {assignment?.project?.endDate ? (
                        <>
                          {moment(Number(assignment?.project?.endDate)).format(
                            'll'
                          )}
                          {moment(
                            Number(assignment?.project?.endDate)
                          ).isBefore(moment(), 'day') && (
                            <i className="ml-2 not-italic">
                              <YellowBadge text="Project Ended" />
                            </i>
                          )}
                        </>
                      ) : (
                        'No End Date'
                      )}
                    </>
                  ),
                  type:
                    assignment.lrs?.type === 'EMPLOYEE'
                      ? 'Employee'
                      : 'Subcontractor',
                  activeRoles:
                    assignment?.assignmentRoleSummary.activeRoles.map(
                      (role: any) => role.name
                    ),
                  inactiveRoles:
                    assignment?.assignmentRoleSummary.inactiveRoles.map(
                      (role: any) => role.name
                    ),
                }))
              : [{}]
          }
          filterText={filterText}
          setFilterText={setFilterText}
          selectedFilterDimension={selectedFilterDimension}
          setSelectedFilterDimension={setSelectedFilterDimension}
          onPageChange={handlePageChange}
        />
      </>
      {children}
    </div>
  );
};

export const ProjectAssignmentList = ({
  projectId,
  assigneeId,
}: ProjectAssignmentListProps) => (
  <PaginationProvider>
    <ProjectAssignmentListPage projectId={projectId} assigneeId={assigneeId} />
  </PaginationProvider>
);
