import { gql } from '@apollo/client';

export const GET_CERTIFICATION_TYPES_SUMMARY = gql`
  query getCertificationTypes(
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    getCertificationTypes(
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        name
        enabled
        updatedAt
      }
    }
  }
`;

export const GET_CERTIFICATION_TYPES = gql`
  query getCertificationTypes(
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    getCertificationTypes(
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        name
        requiredForCompanies
        requiredForSubcontractors
        requiredForEmployees
        requiredForRoles
        requiresExpiryDate
        allowsExemption
        notifierEmail
        enabled
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_EXPIRING_CERTIFICATIONS = gql`
  query getExpiringCertifications(
    $input: GenericFindOptions
    $certificationTypeId: String
    $projectId: String
    $dateFilter: String
    $activeOrganisation: String!
  ) {
    getExpiringCertifications(
      input: $input
      certificationTypeId: $certificationTypeId
      projectId: $projectId
      dateFilter: $dateFilter
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        expiryDate
        issueDate
        userProfile {
          labourResource {
            id
            type
            firstName
            lastName
            email
            updatedAt
          }
        }
        certificationType {
          id
          name
        }
        createdAt
        updatedAt
        lastUpdatedBy
      }
    }
  }
`;

export const GET_REQUIRED_CERTIFICATIONS = gql`
  query getRequiredCertifications(
    $input: GenericFindOptions
    $certificationTypeId: String
    $projectId: String
    $activeOrganisation: String!
  ) {
    getRequiredCertifications(
      input: $input
      certificationTypeId: $certificationTypeId
      projectId: $projectId
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        firstName
        lastName
        email
        type
        updatedAt
      }
    }
  }
`;

export const GET_CERTIFICATIONS = gql`
  query getCertifications(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $labourResourceId: ID
    $companyId: ID
  ) {
    getCertifications(
      input: $input
      activeOrganisation: $activeOrganisation
      labourResourceId: $labourResourceId
      companyId: $companyId
    ) {
      count
      results {
        id
        expiryDate
        issueDate
        certificationType {
          id
          name
        }
        createdAt
        updatedAt
        lastUpdatedBy
      }
    }
  }
`;

export const SEARCH_CERTIFICATION_TYPES = gql`
  query searchCertificationTypes(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $searchTerm: String!
  ) {
    searchCertificationTypes(
      input: $input
      activeOrganisation: $activeOrganisation
      searchTerm: $searchTerm
    ) {
      count
      results {
        id
        name
        enabled
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_CERTIFICATION_ATTACHMENTS = gql`
  query getCertificationAttachments(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $certificationId: ID!
  ) {
    getCertificationAttachments(
      input: $input
      activeOrganisation: $activeOrganisation
      certificationId: $certificationId
    ) {
      count
      results {
        id
        fileName
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_MY_CERTIFICATION_ATTACHMENTS = gql`
  query getMyCertificationAttachments(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $certificationId: ID!
  ) {
    getMyCertificationAttachments(
      input: $input
      activeOrganisation: $activeOrganisation
      certificationId: $certificationId
    ) {
      count
      results {
        id
        fileName
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PROJECT_CERTIFICATIONS = gql`
  query getProjectCertifications(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $projectId: String!
  ) {
    getProjectCertifications(
      input: $input
      activeOrganisation: $activeOrganisation
      projectId: $projectId
    ) {
      count
      results {
        id
        certificationType {
          id
          name
        }
        project {
          name
          internalId
        }
        requiredForEmployees
        requiredForCompanies
        requiredForSubcontractors
        requiredForRoles
      }
    }
  }
`;
