import { useNavigate, useParams } from 'react-router-dom';
import { useToast } from '../../../../context/ToastContext';
import { useOrganisationAwareApollo } from '../../../../hooks/useOrganisationAwareApollo';
import { ASSIGN_COMPANIES_TO_PROJECT } from '../../../../graphql/mutations/company-details';
import { useState } from 'react';
import { Button } from '../../../../components/Buttons/Button';
import { Table } from '../../../../components/Tables/tables/Table';
import { BlueBadge, YellowBadge } from '../../../../components/Badges/Badges';
import { CompaniesList } from '../../../Companies/list';
import { useProject } from '../../../../hooks/api/projects/useProjects';
import Switch from '../../../../components/Inputs/Switch';

const headerMapping = {
  companyName: 'Company Name',
  owner: 'Company Owner',
  companyEmail: 'Email',
  contactName: 'Contact Name',
  companyPhoneNumber: 'Phone Number',
  companyRegNumber: 'Reg Number',
} as Record<string, string>;

export function AssignCompaniesToProjectPage() {
  let { id } = useParams();
  const navigate = useNavigate();
  const { useMutation } = useOrganisationAwareApollo();

  const { addToast } = useToast();

  const [saveAssignments, { loading: isSaving }] = useMutation(
    ASSIGN_COMPANIES_TO_PROJECT
  );

  const [project, { loading, error }] = useProject(id);

  const [assignments, setAssignments] = useState<any[]>([]);

  const [sendAssessments, setSendAssessments] = useState(false);

  const assignCompanyToProject = async () => {
    const companyIds = assignments.map(({ id }) => id);

    const { data } = await saveAssignments({
      variables: { projectId: id, companyIds, sendAssessments },
    });

    if (data.assignCompaniesToProject?.success) {
      navigate(`/projects/${id}?tab=Companies`);
      addToast('Company Assignments saved successfully', 'success');
    } else {
      addToast('Failed to save company assignments', 'error');
    }
  };

  return (
    <div>
      <div className="flex w-full border-b mb-2">
        <Button
          style={{
            backgroundColor: 'transparent',
            color: 'black',
            boxShadow: 'none',
            borderRadius: 0,
          }}
          onClick={() => navigate(-1)}
          type="submit"
          text={`< Go Back`}
        />
      </div>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="flex flex-col md:items-center md:flex-row w-full md:justify-between px-4 py-5 sm:px-6 flex-wrap">
          <div className="flex flex-col">
            <h1 className="text-3xl font-extrabold text-gray-900">
              Assign Companies to {project?.name}
            </h1>
          </div>
        </div>

        {loading && (
          <div className="h-64 flex items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
          </div>
        )}
        {error && (
          <p className="px-4 py-5 sm:p-6 text-red-500">
            Error loading data. Please try again.
          </p>
        )}
        {assignments.length > 0 ? (
          <div className="mb-2">
            <h4 className="my-2 px-4 text-sm text-gray-600">
              Assigning {assignments.length} Compan
              {assignments.length === 1 ? 'y' : 'ies'}
            </h4>
            <Table
              headerMapper={(text) => headerMapping[text]}
              onClickRow={(id: any) => {
                setAssignments(
                  assignments.filter((assignment: any) => assignment.id !== id)
                );
              }}
              data={assignments?.map((result: any) => ({
                id: result.id,
                companyName: result?.companyName,
                companyRegNumber: result?.companyRegNumber,
                companyEmail: result?.companyEmail,
                contactName: result?.contactName,
                companyPhoneNumber: result?.companyPhoneNumber,
                owner: result?.profileId ? (
                  <BlueBadge text="Has Owner" />
                ) : (
                  <YellowBadge text="No Owner" />
                ),
              }))}
            />
            <div className="mt-4 mx-2 w-full mb-4 flex gap-y-2 flex-col">
              <Switch
                text="Send all required assessments"
                enabled={sendAssessments}
                handleChange={() => setSendAssessments((prev) => !prev)}
              />
              <div>
                <Button
                  isLoading={isSaving}
                  text="Save Assignments"
                  onClick={async () => {
                    await assignCompanyToProject();
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}

        <div className="border-t border-gray-200 py-2 px-4">
          <p className="text-sm px-4 text-gray-600">
            Select companies from the list below to assign them to this project.
          </p>
          <CompaniesList
            filterIds={assignments.map((assignment: any) => assignment.id)}
            onClickRow={(id, results) => {
              if (
                !assignments.find((assignment: any) => assignment.id === id)
              ) {
                setAssignments([
                  ...assignments,
                  results?.find((result) => result.id === id),
                ]);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}
