import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useOrganisationAwareApollo } from '../../../../hooks/useOrganisationAwareApollo';
import {
  GET_ASSESSMENT_SUBMISSIONS_FOR_COMPANY,
  GET_ASSESSMENTS,
} from '../../../../graphql/queries/assessments';
import { useProjectCompany } from '../../../../hooks/api/companies/useProjectCompanies';
import { SAVE_ASSESSMENT_SUBMISSION } from '../../../../graphql/mutations/assessments';
import moment from 'moment';
import { FormConfig } from '../../../../types/Form';
import FormCard from '../../../../components/FormCard/FormCard';
import { Button } from '../../../../components/Buttons/Button';
import { AssessmentAttachmentsList } from '../../../../components/assessments/AssessmentAttachmentsList';
import { saveFileToS3 } from '../../../../utils/utils';
import { SAVE_ATTACHMENT } from '../../../../graphql/mutations/attachments';

export const SaveCompanyAssessmentSubmission = () => {
  let { assessmentId, companyId, projectId } = useParams();
  const navigate = useNavigate();
  const { useQuery, useMutation } = useOrganisationAwareApollo();

  const isUpdate = !!assessmentId;

  const { data } = useQuery(GET_ASSESSMENT_SUBMISSIONS_FOR_COMPANY, {
    variables: { input: { id: assessmentId } },
    skip: !isUpdate,
  });

  const [saveAssessmentSubmission] = useMutation(SAVE_ASSESSMENT_SUBMISSION);
  const [saveAttachment] = useMutation(SAVE_ATTACHMENT);

  const [projectCompany] = useProjectCompany({
    projectId: projectId!,
    companyId: companyId!,
  });

  const [assessmentSubmission] =
    data?.getAssessmentSubmissionsForCompany?.results || [];

  const submissionConfig = {
    formSections: [
      {
        title: assessmentSubmission?.assessmentType?.name,
        components: [
          <div className="bg-white flex flex-col px-6 border-b border-dashed py-4 rounded-md">
            <h1 className="text-3xl font-extrabold text-gray-900">
              {projectCompany?.company?.companyName}
            </h1>
            {projectCompany?.company?.owner && (
              <p className="mt-1 text-lg text-gray-900">
                Owner:{' '}
                {`${projectCompany?.company?.owner?.firstName} ${projectCompany?.company?.owner?.lastName}`}
              </p>
            )}
            <div className="flex text-gray-500 flex-col gap-y-1 py-2 my-2 border-dashed border-t border-b">
              <h2 className="text-sm">
                Created:{' '}
                <strong className="text-sm text-gray-800">
                  {moment
                    .unix(parseInt(assessmentSubmission?.createdAt) / 1000)
                    .fromNow()}
                </strong>
              </h2>
              <h2 className="text-sm">
                Updated:{' '}
                <strong className="text-sm text-gray-800">
                  {moment
                    .unix(parseInt(assessmentSubmission?.updatedAt) / 1000)
                    .fromNow()}
                </strong>
              </h2>
            </div>
          </div>,
          <div className="bg-white flex flex-col px-6 py-4 rounded-md shadow-md">
            {assessmentSubmission?.responses?.length > 0 ? (
              assessmentSubmission?.responses?.map((response: any) => (
                <div className="flex flex-col gap-y-1 py-2 border-t border-b border-dashed">
                  <h3 className="text-base text-gray-600">
                    {response?.question}
                  </h3>
                  <h4 className="text-base flex items-center gap-x-2">
                    <strong className="text-gray-700 text-sm">Answered:</strong>{' '}
                    {response?.answer ?? '(empty)'}
                  </h4>
                </div>
              ))
            ) : (
              <p>Nothing submitted yet.</p>
            )}
          </div>,
        ],
        fields: [
          {
            title: 'Outcome',
            id: 'outcome',
            type: 'dropdown',
            options: assessmentSubmission?.assessmentType?.outcomes?.map(
              (outcome: any) => ({ id: outcome, name: outcome })
            ),
          },
        ],
      },
    ],
  } as FormConfig;

  const initialValues = assessmentId ? assessmentSubmission : {};

  const validationSchema = Yup.object().shape({
    outcome: Yup.string().required('Outcome is required'),
  });

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    console.log(values);
    try {
      await saveAssessmentSubmission({
        variables: {
          id: values?.id,
          input: {
            companyId: projectCompany?.company?.id,
            outcome: values?.outcome,
          },
        },
      });
      setSubmitting(false);
      navigate(-1);
    } catch (error) {
      console.error(error);
    }
  };

  const onSaveAttachment = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void,
    setFieldValue?: (field: any, values: any) => void
  ) => {
    let newAttachmentId;
    if (values?.file?.name) {
      const { data } = await saveAttachment({
        variables: {
          input: {
            name: values.importName,
            id: null,
            fileName: values.file.name,
          },
        },
      });

      const presignedUrl = data?.saveAttachment?.presignedUrl;

      await saveFileToS3(values.file, presignedUrl);
      newAttachmentId = data?.saveAttachment?.id;
      await saveAssessmentSubmission({
        variables: {
          id: values?.id,
          input: {
            companyId: projectCompany?.company?.id,
            newAttachmentId,
          },
        },
      });
    }
    setSubmitting(false);
    if (setFieldValue) {
      setFieldValue('file', undefined);
    }
  };

  return (
    <>
      <div className="flex w-full border-b mb-2">
        <Button
          style={{
            backgroundColor: 'transparent',
            color: 'black',
            boxShadow: 'none',
            borderRadius: 0,
          }}
          onClick={() => navigate(-1)}
          type="submit"
          text={`< Go Back`}
        />
      </div>
      <FormCard
        key={`${submissionConfig?.title}`}
        config={submissionConfig}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
      {assessmentSubmission && (
        <AssessmentAttachmentsList
          assessmentSubmission={assessmentSubmission}
          onSaveAttachment={onSaveAttachment}
        />
      )}
    </>
  );
};
