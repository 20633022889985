import { gql } from '@apollo/client';

export const GET_CERTIFICATION_ATTACHMENT_URL = gql`
  query getCertificationAttachmentURL(
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    getCertificationAttachmentURL(
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      id
      success
      message
      presignedUrl
    }
  }
`;

export const GET_PROGRESS_SUBMISSION_ATTACHMENT_URL = gql`
  query getProgressSubmissionAttachmentURL(
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    getProgressSubmissionAttachmentURL(
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      id
      success
      message
      presignedUrl
    }
  }
`;

export const GET_MY_CERTIFICATION_ATTACHMENT_URL = gql`
  query getMyCertificationAttachmentURL(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $ownerId: String!
    $ownerType: String!
  ) {
    getMyCertificationAttachmentURL(
      input: $input
      activeOrganisation: $activeOrganisation
      ownerId: $ownerId
      ownerType: $ownerType
    ) {
      id
      success
      message
      presignedUrl
    }
  }
`;

export const GET_ASSESSMENT_ATTACHMENT_URL = gql`
  query getAssessmentAttachmentURL(
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    getAssessmentAttachmentURL(
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      id
      success
      message
      presignedUrl
    }
  }
`;

export const GET_MY_ASSESSMENT_ATTACHMENT_URL = gql`
  query getMyAssessmentAttachmentURL(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $ownerId: String!
    $ownerType: String!
  ) {
    getMyAssessmentAttachmentURL(
      input: $input
      activeOrganisation: $activeOrganisation
      ownerId: $ownerId
      ownerType: $ownerType
    ) {
      id
      success
      message
      presignedUrl
    }
  }
`;
