import { gql } from '@apollo/client';

export const GET_PROJECT_ASSIGNMENTS_WITH_TIMESHEET_ENTRIES = gql`
  query getAssignmentsWithTimesheetEntries(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $projectId: ID
    $weekId: ID
    $submissionId: String
    $showDisabled: Boolean
    $configurationId: ID
  ) {
    getAssignmentsWithTimesheetEntries(
      input: $input
      showDisabled: $showDisabled
      activeOrganisation: $activeOrganisation
      projectId: $projectId
      weekId: $weekId
      submissionId: $submissionId
      configurationId: $configurationId
    ) {
      count
      results {
        id
        enabled
        project {
          name
        }
        lrs {
          id
          firstName
          lastName
          type
        }
        customData {
          key
          value
        }
        assignmentRoleSummary {
          activeRoles {
            id
            name
            timesheetEntry {
              id
              days {
                day
                selectedCode
                codings {
                  key
                  value
                }
              }
              customData {
                key
                value
              }
            }

            customData {
              key
              value
            }
          }
          inactiveRoles {
            id
            name
            timesheetEntry {
              id
              days {
                day
                selectedCode
                codings {
                  key
                  value
                }
              }
            }
            customData {
              key
              value
            }
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_MY_PROJECT_TIMESHEET_ENTRIES = gql`
  query getMyProjectTimesheetEntries(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $weekId: ID
    $configurationId: ID
    $submissionId: String
  ) {
    getMyProjectTimesheetEntries(
      input: $input
      activeOrganisation: $activeOrganisation
      weekId: $weekId
      submissionId: $submissionId
      configurationId: $configurationId
    ) {
      count
      results {
        project {
          id
          name
          internalId
        }
        id
        days {
          day
          selectedCode
          codings {
            key
            value
          }
        }
        customData {
          key
          value
        }
      }
    }
  }
`;
