export enum DocusealRoles {
  SUBCONTRACTOR = 'Subcontractor',
  ORGANISATION = 'Organisation',
}

export enum DocusealFields {
  SUBCONTRACTOR_COMPANY_NAME = 'Company Name',
  SUBCONTRACTOR_COMPANY_NUMBER = 'Company Number',
  SUBCONTRACTOR_COMPANY_ADDRESS = 'Company Address',
  SUBCONTRACTOR_COMPANY_TYPE = 'Company Type',
  SUBCONTRACTOR_FIRST_NAME = 'First Name',
  SUBCONTRACTOR_LAST_NAME = 'Last Name',
  SUBCONTRACTOR_ROLE = 'Project Role',
  PROJECT_NAME = 'Project Name',
  PROJECT_START_DATE = 'Project Start Date',
  COMMENCEMENT_DATE = 'Commencement Date',
  CURRENCY_SYMBOL = 'Currency Symbol',
}

export const DocusealFieldMapping = {
  [DocusealRoles.SUBCONTRACTOR]: [
    {
      field: DocusealFields.SUBCONTRACTOR_COMPANY_NAME,
    },
    {
      field: DocusealFields.SUBCONTRACTOR_COMPANY_NUMBER,
    },
    {
      field: DocusealFields.SUBCONTRACTOR_COMPANY_ADDRESS,
    },
    {
      field: DocusealFields.SUBCONTRACTOR_COMPANY_TYPE,
    },
    {
      field: DocusealFields.SUBCONTRACTOR_FIRST_NAME,
    },
    {
      field: DocusealFields.SUBCONTRACTOR_LAST_NAME,
    },
    {
      field: DocusealFields.PROJECT_NAME,
    },
    {
      field: DocusealFields.PROJECT_START_DATE,
    },
    {
      field: DocusealFields.COMMENCEMENT_DATE,
    },
    {
      field: DocusealFields.SUBCONTRACTOR_ROLE,
    },
    {
      field: DocusealFields.CURRENCY_SYMBOL,
    },
  ],
};
