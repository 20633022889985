import { useEffect } from 'react';
import { useOrganisationAwareApollo } from '../../useOrganisationAwareApollo';
import { ApiHookMeta } from '../types';
import { GET_CERTIFICATION_TYPES } from '../../../graphql/queries/certifications';

export default function useCertificationTypes({
  id,
  limit,
  offset,
}: {
  id?: string;
  limit?: number;
  offset?: number;
}): [any, ApiHookMeta] {
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [fetch, { data, loading, error, ...result }] = useLazyQuery(
    GET_CERTIFICATION_TYPES,
    {
      variables: { input: { id, limit, offset } },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    fetch({
      variables: { input: { id, limit, offset } },
    });
  }, [fetch, id, limit, offset]);

  const certificationTypes = data?.getCertificationTypes?.results || [];
  const count = data?.getCertificationTypes?.count || 0;

  return [
    certificationTypes,
    {
      count,
      loading,
      error,
      ...result,
    },
  ];
}

export function useCertificationType(id?: string) {
  const [certificationTypes, meta] = useCertificationTypes({ id });

  const [certificationType] = certificationTypes;

  return [certificationType, meta];
}
