import React, { useCallback, useState } from 'react';
import { PaginationProvider } from '../../../context/PaginationContext';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectSelection } from '../../../components/ProjectSelection/ProjectSelection';
import { Button } from '../../../components/Buttons/Button';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { useAssessmentType } from '../../../hooks/api/useAssessmentTypes';
import { AssessmentSubmissionsTableFilter } from '../../../hooks/api/useAssessmentSubmissions';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { SEND_ALL_REQUIRED_ASSESSMENTS } from '../../../graphql/mutations/assessments';
import { useToast } from '../../../context/ToastContext';
import { AssessmentSubmissionsTable } from './AssessmentSubmissionsTable';
import { useTabs } from '../../../hooks/useTabs';
import { Tabs } from '../../../components/Tabs/Tabs';
import { CompanyAssessmentSubmissionsTable } from './CompanyAssessmentSubmissionsTable';

const filters: { label: string; value: AssessmentSubmissionsTableFilter }[] = [
  {
    label: 'Not Started',
    value: AssessmentSubmissionsTableFilter.NOT_STARTED,
  },
  {
    label: 'Awaiting Outcome',
    value: AssessmentSubmissionsTableFilter.AWAITING_OUTCOME,
  },
];

const tabs = ['Labour', 'Companies'];

export const AssessmentDashboard = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { useMutation } = useOrganisationAwareApollo();
  const { addToast } = useToast();
  const [sendAllRequiredAssessments, { loading: isSending }] = useMutation(
    SEND_ALL_REQUIRED_ASSESSMENTS
  );

  const [activeTab, setActiveTab] = useTabs(tabs);

  const [selectedProject, setSelectedProject] = useState<any>(null);
  const [unsentFilter, setUnsentFilter] = useState(false);
  const [activeFilter, setActiveFilter] = useState<
    AssessmentSubmissionsTableFilter | undefined
  >(undefined);

  const [assessmentType] = useAssessmentType(id);

  const handleSendAll = useCallback(async () => {
    const result = await sendAllRequiredAssessments({
      variables: { assessmentTypeId: id },
    });

    if (result.data?.sendAllRequiredAssessments?.success) {
      addToast('Successfully sent all required assessments', 'success');
      setUnsentFilter(false);
    } else {
      addToast('Failed to send all required assessments', 'error');
    }
  }, [addToast, id, sendAllRequiredAssessments]);

  if (!assessmentType) {
    return (
      <div className="h-64 flex items-center justify-center">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <PaginationProvider>
      <div>
        <div className="flex w-full border-b mb-2">
          <Button
            style={{
              backgroundColor: 'transparent',
              color: 'black',
              boxShadow: 'none',
              borderRadius: 0,
            }}
            onClick={() => navigate(-1)}
            type="submit"
            text={`< Go Back`}
          />
        </div>
        <header className="border-b border-gray-200 py-4">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {assessmentType.name}
          </h1>
        </header>
        <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
        <div
          className={`flex w-full h-full py-2 px-2 items-center bg-white ${
            unsentFilter ? 'justify-between' : ''
          }`}
        >
          <ProjectSelection
            selectedProject={selectedProject}
            setSelectedProject={setSelectedProject}
          />
          {unsentFilter && activeTab !== 'Companies' ? (
            <Button
              className="mt-1"
              onClick={handleSendAll}
              disabled={isSending}
            >
              Send All Required Assessments
            </Button>
          ) : null}
        </div>
        <div className="flex justify-between bg-white py-2 px-2 items-center">
          <div className="flex w-full h-full items-center gap-x-4">
            {!unsentFilter && (
              <>
                Filters:
                {filters.map((filter) => (
                  <Button
                    style={{
                      backgroundColor:
                        activeFilter === filter.value ? 'black' : 'transparent',
                      borderColor: 'black',
                      color: activeFilter === filter.value ? 'white' : 'black',
                    }}
                    text={filter.label}
                    onClick={() =>
                      activeFilter === filter.value
                        ? setActiveFilter(undefined)
                        : setActiveFilter(filter.value)
                    }
                  />
                ))}
                <Button
                  className="shadow-none bg-transparent -ml-3 px-2 text-black hover:bg-transparent"
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    setActiveFilter(undefined);
                  }}
                  aria-label="Clear Filter"
                  title="Clear Filter"
                >
                  <XMarkIcon className="h-5 w-5" />
                </Button>
              </>
            )}
          </div>
          <div className="flex flex-row">
            {[
              { text: 'Sent', unsent: false },
              { text: 'Unsent', unsent: true },
            ].map((tab) => (
              <div
                className={`${
                  unsentFilter === tab.unsent ? 'bg-black text-white' : ''
                } justify-center flex items-center`}
              >
                <Button
                  style={{
                    borderRadius: 0,
                    backgroundColor: 'transparent',
                    color: unsentFilter === tab.unsent ? 'white' : 'black',
                  }}
                  text={tab.text}
                  onClick={() => setUnsentFilter(tab.unsent)}
                />
              </div>
            ))}
          </div>
        </div>
        {activeTab === 'Labour' && (
          <AssessmentSubmissionsTable
            activeFilter={activeFilter}
            assessmentTypeId={assessmentType.id}
            projectId={selectedProject?.id}
            unsent={unsentFilter}
          />
        )}
        {activeTab === 'Companies' && (
          <CompanyAssessmentSubmissionsTable
            activeFilter={activeFilter}
            assessmentTypeId={assessmentType.id}
            projectId={selectedProject?.id}
            unsent={unsentFilter}
          />
        )}
      </div>
    </PaginationProvider>
  );
};
