import { useEffect, useState } from 'react';

const NumericInput = (props) => {
  const {
    question,
    handleChange,
    value,
    validation,
    isDisabled,
    min = 0,
    max = undefined,
    step = 'any',
  } = props;
  const {
    id,
    title,
    placeholder,
    name,
    classNames,
    description,
    inputTransformer = (number) => number,
  } = question || {};

  const increment = () => {
    const currentValue = Number(value) || 0;
    const stepValue = step === 'any' ? 1 : Number(step);
    const newValue = currentValue + stepValue;
    if (!max || newValue <= max) {
      handleChange(inputTransformer(String(newValue)));
    }
  };

  const decrement = () => {
    const currentValue = Number(value) || 0;
    const stepValue = step === 'any' ? 1 : Number(step);
    const newValue = currentValue - stepValue;
    if (newValue >= Number(min)) {
      handleChange(inputTransformer(String(newValue)));
    }
  };

  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <div className={classNames}>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {title}
      </label>
      {validation ? <p>{validation}</p> : null}
      <div className="flex items-center shadow-sm">
        <button
          type="button"
          className="bg-green-600 rounded-l-lg border border-gray-300 hover:bg-green-800 transition-all text-white p-2 px-2 xl:px-4 select-none"
          onClick={decrement}
          disabled={isDisabled}
        >
          -
        </button>
        <input
          type="number"
          id={id}
          key={id}
          name={name}
          min={min}
          max={max}
          step={step}
          value={internalValue === 0 ? 0 : internalValue || ''}
          disabled={isDisabled}
          onChange={(event) =>
            setInternalValue(inputTransformer(event.target.value))
          }
          onBlur={() => {
            handleChange(internalValue);
          }}
          autoComplete={name}
          className="w-full px-3 py-2 border text-center border-gray-300 border-r-0 border-l-0 placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
          placeholder={placeholder}
        />
        <button
          type="button"
          className="bg-green-600 rounded-r-lg border border-gray-300 hover:bg-green-800 transition-all text-white p-2 px-2 xl:px-4 select-none"
          onClick={increment}
          disabled={isDisabled}
        >
          +
        </button>
      </div>
      {description ? (
        <p className="mt-2 text-sm text-gray-500">{description}</p>
      ) : null}
    </div>
  );
};

export default NumericInput;
