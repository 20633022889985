import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface TimeTrackingHomeProps {
  children?: React.ReactElement;
}

export const TimeTrackingHome = ({ children }: TimeTrackingHomeProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/time/timesheets');
  }, []);
  return <div>{children}</div>;
};
