import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Buttons/Button';

import { GET_MY_PERSONAL_DETAILS } from '../../../graphql/queries/my-profile';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import moment from 'moment';
import { GET_CUSTOM_FIELDS } from '../../../graphql/queries/custom-data';

export const PersonalProfilePage = () => {
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [fetch, { data, loading, error }] = useLazyQuery(
    GET_MY_PERSONAL_DETAILS,
    {
      variables: {},
      fetchPolicy: 'network-only',
    }
  );

  const personalprofile = data?.getMyPersonalDetails;

  const sections = [
    {
      title: 'First Name',
      content: personalprofile?.firstName,
    },
    {
      title: 'Last Name',
      content: personalprofile?.lastName,
    },
    {
      title: 'Email',
      content: personalprofile?.email,
    },
    {
      title: 'Nationality',
      content: personalprofile?.nationality,
    },
    {
      title: 'Country of Residence',
      content: personalprofile?.countryOfResidence,
    },
  ];

  const [fetchCustomFields, { data: customFieldsData }] = useLazyQuery(
    GET_CUSTOM_FIELDS,
    {
      variables: { onlyEnabled: true },
      fetchPolicy: 'network-only',
    }
  );

  const getOptionSetNameByValue = (customOptionId: any) => {
    const fieldGroups = customFieldsData?.getCustomFieldGroups?.results;
    if (!fieldGroups) {
      return null;
    }
    for (const fieldGroup of fieldGroups) {
      const foundOption = fieldGroup.custom_fields?.find(
        (option: any) => option.id === customOptionId
      );
      if (foundOption) {
        return foundOption.name;
      }
    }

    return null;
  };

  const customSections = (personalprofile?.customData || [])
    .map((customItem: any) => ({
      title: getOptionSetNameByValue(customItem.key),
      content: customItem.value,
    }))
    ?.filter((item: any) => item.title);

  useEffect(() => {
    fetch({ variables: {} });
    fetchCustomFields();
  }, []);

  return (
    <div className="bg-gray-50">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="flex flex-col md:items-center md:flex-row w-full md:justify-between px-4 py-5 sm:px-6 flex-wrap">
          <div className="flex flex-col">
            <h1 className="text-3xl font-extrabold text-gray-900">
              Personal Details
            </h1>
            <p className="mt-1 text-sm text-gray-500">
              Updated:{' '}
              {moment.unix(personalprofile?.updatedAt / 1000).fromNow()}
            </p>
          </div>
          <div className="mt-2 md:mt-0">
            <Button
              onClick={() => navigate(`/profile/personal/edit`)}
              type="submit"
              text="Edit"
            />
          </div>
        </div>
        {loading && (
          <div className="h-64 flex items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
          </div>
        )}
        {error && (
          <p className="px-4 py-5 sm:p-6 text-red-500">
            Error loading data. Please try again.
          </p>
        )}

        {personalprofile ? (
          <div className="border-t border-gray-200">
            {sections.map((section, index) => (
              <div key={index} className="px-4 py-4">
                <h2 className="text-lg font-medium text-gray-900">
                  {section.title}
                </h2>
                <p className="mt-1 text-sm text-gray-500">{section.content}</p>
              </div>
            ))}
            {customSections.map((section: any, index: number) => (
              <div key={`custom-${index}`} className="px-4 py-4">
                <h2 className="text-lg font-medium text-gray-900">
                  {section.title}
                </h2>
                <p className="mt-1 text-sm text-gray-500">{section.content}</p>
              </div>
            ))}
          </div>
        ) : (
          <p className="px-4 py-4">No personal data available</p>
        )}
      </div>
    </div>
  );
};

export const PersonalProfile = () => <PersonalProfilePage />;
