import ValidationMessage from '../Validation/ValidationMessage';

import { Question } from '../../types/QuestionTypes';

import { Combobox as UIComboBox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { joinClassNames } from '../../utils/utils';

interface ComboBoxParams {
  question: Question;
  placeholder?: string;
  handleChange: (value: string) => void;
  value: any;
  validation?: any;
  classNames?: string;
  isDisabled?: boolean;
  valueTransformer?: (id: string) => string;
  inputTransformer?: (text: string) => string;
  testId?: string;
  comboOptionClassNames?: string;
  parentClassNames?: string;
}

const ComboBox = ({
  question,
  handleChange,
  value,
  validation,
  classNames,
  isDisabled,
  valueTransformer,
  inputTransformer = (text) => text,
  placeholder = 'Search',
  comboOptionClassNames = '',
  parentClassNames,
  testId = '',
}: ComboBoxParams) => {
  const [query, setQuery] = useState('');
  const {
    id,
    title,
    options,
    description,
    onChange: onQueryChange = () => {},
  } = question;
  const filteredOptions =
    query === ''
      ? options
      : options?.filter((option) => {
          return (
            option.name?.toLowerCase()?.includes(query?.toLowerCase()) ||
            option.detail?.toLowerCase()?.includes(query?.toLowerCase())
          );
        });

  useEffect(() => {
    onQueryChange(inputTransformer(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <div className={classNames}>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {title ? title : null}
      </label>
      {validation ? <ValidationMessage message={validation} /> : null}
      <UIComboBox
        data-testid={testId}
        disabled={isDisabled}
        as="div"
        value={valueTransformer ? valueTransformer(value) : value}
        onChange={(option) => (isDisabled ? () => {} : handleChange(option.id))}
      >
        <div className={parentClassNames ?? 'relative mt-1'}>
          <UIComboBox.Button className="inset-y-0 right-0 flex items-center rounded-r-md w-full focus:outline-none">
            <UIComboBox.Input
              key={`input_${isDisabled}`}
              id={id}
              // @ts-ignore
              disabled={isDisabled}
              onFocus={(event: any) => {
                // ugly hack https://github.com/tailwindlabs/headlessui/discussions/1292
                if (isDisabled) {
                  event.target.disabled = true;
                }
              }}
              data-value={valueTransformer ? valueTransformer(value) : value}
              placeholder={placeholder}
              autoComplete="off"
              className={`w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 ${
                isDisabled ? 'bg-gray-200 opacity-75' : null
              } shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500 sm:text-sm`}
              onChange={(event: any) =>
                isDisabled
                  ? null
                  : setQuery(inputTransformer(event.target.value))
              }
              displayValue={(selected: string) => inputTransformer(selected)}
            />

            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </UIComboBox.Button>

          {(filteredOptions || []).length > 0 && (
            <UIComboBox.Options
              className={joinClassNames(
                'absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
                comboOptionClassNames
              )}
            >
              {(filteredOptions || []).map((option) => (
                <UIComboBox.Option
                  key={option.id}
                  value={option}
                  className={({ active }: any) =>
                    joinClassNames(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-primary-600 text-gray-900' : 'text-gray-900'
                    )
                  }
                >
                  {({ active, selected }: any) => (
                    <>
                      <span
                        className={joinClassNames(
                          'block truncate',
                          selected && 'font-semibold'
                        )}
                      >
                        {option.name}
                      </span>
                      <span
                        className={joinClassNames(
                          'block truncate text-xs text-gray-500',
                          selected && 'font-semibold'
                        )}
                      >
                        {option.detail}
                      </span>

                      {selected && (
                        <span
                          className={joinClassNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-gray-900' : 'text-gray-900'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </UIComboBox.Option>
              ))}
            </UIComboBox.Options>
          )}
        </div>
      </UIComboBox>
      {description ? (
        <p className="mt-2 text-sm text-gray-500">{description}</p>
      ) : null}
    </div>
  );
};

export default ComboBox;
