import { Outcome, Phase } from '../../../types/Outcomes';

function hashString(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const character = str.charCodeAt(i);
    hash = (hash << 5) - hash + character;
    hash = hash & hash; // Convert to 32bit integer
  }
  return Math.abs(hash);
}

export function getColor(id: string) {
  const pastelColors = [
    '#ffd7d5', // pastel red
    '#f0ffaa', // pastel yellow
    '#d5dcff', // pastel blue
    '#d5ffdc', // pastel green
    '#ffd5fa', // pastel pink
    '#d5f6ff', // pastel cyan
    '#fffae6', // pastel orange
    '#e6e6ff', // pastel purple
    '#f9d5ff', // pastel lavender
    '#ffdfba', // pastel peach
  ];
  const index = hashString(String(id)) % pastelColors.length;
  return pastelColors[index];
}

export const mapOutcomeToPhaseActivityGroupNames = (
  outcome: Outcome
): Record<string, Phase> => {
  const phaseActivityGroupMapping: Record<string, Phase> = {};

  outcome?.configuration.phases?.forEach((phase) => {
    phaseActivityGroupMapping[phase.id] = { ...phase } as Phase;
  });

  return phaseActivityGroupMapping;
};

export const mapPhaseActivityGroupNamesToOutcome = (
  phaseActivityGroupMapping: Record<string, Phase>,
  originalOutcome: Outcome,
  containers: string[]
): Outcome => {
  let orderedPhases: Phase[] = [];

  containers?.forEach((containerId) => {
    const phase = phaseActivityGroupMapping[containerId];
    if (phase) {
      orderedPhases.push(phase);
    }
  });

  const outcome: Outcome = {
    id: originalOutcome.id,
    name: originalOutcome.name,
    configuration: {
      phases: orderedPhases,
      locations: originalOutcome.configuration?.locations ?? [],
    },
  };

  return outcome;
};
