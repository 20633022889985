import { useLocation, useParams } from 'react-router-dom';

export const useBasePath = () => {
  const location = useLocation();
  const params = useParams<Record<string, string>>();

  return Object.entries(params).reduce(
    (path, [key, value]) => path?.replace('/' + value, `/:${key}`),
    location.pathname
  );
};
