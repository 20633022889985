import ComboBox from '../../../../../components/Inputs/ComboBox';

export const AssessmentSelection = ({
  assessmentSearchResults,
  setSearchTerm,
  setSelectedAssessment,
  selectedAssessment,
}: any) => {
  return (
    <div className="w-full md:w-1/3">
      <ComboBox
        testId="assessment-selection"
        placeholder="Search by assessment name"
        question={{
          id: 'Assessment Search',
          options: assessmentSearchResults.map((assessment: any) => ({
            id: assessment.id,
            name: assessment.name,
            detail: assessment.internalId,
          })),
          onChange: (value) => setSearchTerm(value),
        }}
        handleChange={(id) => {
          setSelectedAssessment(
            assessmentSearchResults?.find(
              (assessment: any) => assessment.id === id
            )
          );
        }}
        value={selectedAssessment?.name}
        validation={undefined}
      />
    </div>
  );
};
