import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '../Buttons/Button';
import {
  Choice,
  QuestionBuilderQuestion,
  QuestionType,
} from '../../types/QuestionTypes';

interface QuestionBuilderProps {
  questions?: QuestionBuilderQuestion[];
  setQuestions: (questions: QuestionBuilderQuestion[]) => void;
}

const QuestionBuilder: React.FC<QuestionBuilderProps> = ({
  questions = [],
  setQuestions = () => {},
}: QuestionBuilderProps) => {
  const addQuestion = (type = QuestionType.SINGLE_CHOICE) => {
    const newQuestion: QuestionBuilderQuestion = {
      id: uuidv4(),
      question: '',
      type,
      choices: [],
    };
    setQuestions([...questions, newQuestion]);
  };

  const removeQuestion = (questionIndex: number) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(questionIndex, 1);
    setQuestions(updatedQuestions);
  };

  const removeChoice = (questionIndex: number, choiceIndex: number) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].choices.splice(choiceIndex, 1);
    setQuestions(updatedQuestions);
  };

  const updateQuestion = (index: number, question: string) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].question = question;
    setQuestions(updatedQuestions);
  };

  const addChoice = (questionIndex: number) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].choices.push({ id: uuidv4(), name: '' });
    setQuestions(updatedQuestions);
  };

  const updateChoice = (
    questionIndex: number,
    choiceIndex: number,
    choice: Choice
  ) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].choices[choiceIndex] = choice;
    setQuestions(updatedQuestions);
  };

  const moveChoiceUp = (questionIndex: number, choiceIndex: number) => {
    if (choiceIndex > 0) {
      const updatedQuestions = [...questions];
      const choices = updatedQuestions[questionIndex].choices;
      [choices[choiceIndex - 1], choices[choiceIndex]] = [
        choices[choiceIndex],
        choices[choiceIndex - 1],
      ];
      setQuestions(updatedQuestions);
    }
  };

  const moveChoiceDown = (questionIndex: number, choiceIndex: number) => {
    const updatedQuestions = [...questions];
    const choices = updatedQuestions[questionIndex].choices;
    if (choiceIndex < choices.length - 1) {
      [choices[choiceIndex], choices[choiceIndex + 1]] = [
        choices[choiceIndex + 1],
        choices[choiceIndex],
      ];
      setQuestions(updatedQuestions);
    }
  };

  const moveQuestionUp = (questionIndex: number) => {
    if (questionIndex > 0) {
      const updatedQuestions = [...questions];
      [updatedQuestions[questionIndex - 1], updatedQuestions[questionIndex]] = [
        updatedQuestions[questionIndex],
        updatedQuestions[questionIndex - 1],
      ];
      setQuestions(updatedQuestions);
    }
  };

  const moveQuestionDown = (questionIndex: number) => {
    if (questionIndex < questions.length - 1) {
      const updatedQuestions = [...questions];
      [updatedQuestions[questionIndex], updatedQuestions[questionIndex + 1]] = [
        updatedQuestions[questionIndex + 1],
        updatedQuestions[questionIndex],
      ];
      setQuestions(updatedQuestions);
    }
  };

  return (
    <div className="p-2">
      {questions.map((question, questionIndex) => (
        <div key={questionIndex} className="mb-4 border-t border-b py-4">
          <label className="block text-sm mb-2">
            {!question.type || question.type === QuestionType.SINGLE_CHOICE
              ? 'Single Choice'
              : 'Free Text'}{' '}
            Question:
          </label>
          <input
            type="text"
            value={question.question}
            onChange={(e) => updateQuestion(questionIndex, e.target.value)}
            placeholder="Question"
            className="border border-gray-300 rounded p-2 text-sm w-full"
          />
          {!question.type || question.type === QuestionType.SINGLE_CHOICE ? (
            <div className="mt-4 bg-gray-100 py-4 px-4">
              <label className="block text-sm mb-2">Choices:</label>
              {question.choices.map((choice, choiceIndex) => (
                <div
                  key={choiceIndex}
                  className="flex flex-row gap-4 justify-center items-center"
                >
                  <input
                    type="text"
                    value={choice.name}
                    onChange={(e) =>
                      updateChoice(questionIndex, choiceIndex, {
                        ...choice,
                        name: e.target.value,
                      })
                    }
                    placeholder={`Choice ${choiceIndex + 1}`}
                    className="border border-gray-300 rounded p-2 text-sm w-full"
                  />
                  <Button
                    text="Remove"
                    onClick={() => removeChoice(questionIndex, choiceIndex)}
                  />
                  <div className="flex flex-row my-2 gap-2">
                    <Button
                      text="↑"
                      onClick={() => moveChoiceUp(questionIndex, choiceIndex)}
                      isDisabled={choiceIndex === 0}
                    />
                    <Button
                      text="↓"
                      onClick={() => moveChoiceDown(questionIndex, choiceIndex)}
                      isDisabled={choiceIndex === question.choices.length - 1}
                    />
                  </div>
                </div>
              ))}

              <Button
                text="Add Choice"
                onClick={() => addChoice(questionIndex)}
              />
            </div>
          ) : null}
          <div className="flex flex-row my-2 gap-2">
            <Button
              text="↑"
              onClick={() => moveQuestionUp(questionIndex)}
              isDisabled={questionIndex === 0}
            />
            <Button
              text="↓"
              onClick={() => moveQuestionDown(questionIndex)}
              isDisabled={questionIndex === questions.length - 1}
            />
          </div>
          <Button
            text="Remove Question"
            onClick={() => removeQuestion(questionIndex)}
          />
        </div>
      ))}
      <div className="flex flex-row gap-4">
        <Button
          text="Add Single Choice Question"
          onClick={() => addQuestion(QuestionType.SINGLE_CHOICE)}
        />
        <Button
          text="Add Free Text Question"
          onClick={() => addQuestion(QuestionType.FREE_TEXT)}
        />
      </div>
    </div>
  );
};

export default QuestionBuilder;
