import moment from 'moment';

export function alignDateWithWeekId(weekId: any) {
  const year = parseInt(weekId?.split('-')?.[0]?.replace('Y', ''));
  const week = parseInt(weekId?.split('-')?.[1]?.replace('W', ''));
  const date = moment()
    .isoWeekYear(year)
    .isoWeek(week)
    .startOf('isoWeek')
    .toDate();
  return date;
}
