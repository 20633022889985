export const calculateAdjustedForecast = (
  estimatedHours: number,
  actualHours: number,
  progress: number
) => {
  if (progress <= 0) {
    return actualHours;
  }
  const rateOfCompletion = actualHours / progress;
  const forecastTotalHours = rateOfCompletion * 100;
  const forecastDifference = estimatedHours - forecastTotalHours;
  return forecastDifference;
};

export const calculateEstimatedCompletionTime = (
  estimatedHours: number,
  actualHours: number,
  progress: number
) => {
  if (isNaN(progress) || progress <= 0) {
    return estimatedHours + actualHours;
  }

  if (actualHours <= 0 || progress > 100) {
    return estimatedHours;
  }

  const rateOfCompletion = actualHours / (progress / 100);
  const totalHoursForCompletion = rateOfCompletion;

  return totalHoursForCompletion;
};
