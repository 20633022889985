import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useEffect } from 'react';

import FormCard from '../../../components/FormCard/FormCard';

import { SAVE_SKILL_TYPE } from '../../../graphql/mutations/skills';
import { GET_SKILL_TYPES } from '../../../graphql/queries/skills';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { FormConfig } from '../../../types/Form';
import { useToast } from '../../../context/ToastContext';

export const SaveSkillType = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { useQuery, useMutation } = useOrganisationAwareApollo();

  const { addToast } = useToast();

  const isUpdate = !!id;

  const { data, refetch } = useQuery(GET_SKILL_TYPES, {
    variables: { input: { id } },
    skip: !isUpdate,
  });

  useEffect(() => {
    if (isUpdate) {
      refetch({ id });
    }
  }, [id, refetch, isUpdate]);

  const [skillType] = data?.getSkillTypes?.results || [];

  const [saveSkillType] = useMutation(SAVE_SKILL_TYPE);
  const rateConfig = {
    formSections: [
      {
        title: 'Skill Type',
        fields: [
          {
            title: 'Name',
            id: 'name',
            type: 'input',
          },
          {
            title: 'Enabled',
            id: 'enabled',
            type: 'switch',
          },
        ],
      },
    ],
  } as FormConfig;

  const initialValues = id ? skillType : {};

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    console.log(values);
    try {
      await saveSkillType({
        variables: {
          id: values?.id,
          input: {
            ...values,
            id: undefined,
            updatedAt: undefined,
            createdAt: undefined,
          },
        },
      });
      addToast('Skill type saved', 'success');
      setSubmitting(false);
      navigate(`/labour/skills`);
    } catch (error) {
      console.error(error);
      addToast('Error saving skill type', 'error');
    }
  };

  return (
    <FormCard
      key={`${rateConfig?.title}`}
      config={rateConfig}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
};
