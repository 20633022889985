import { useContext } from 'react';
import { UserProfileContext } from '../../context/UserProfileContext';
import NoData from './NoData';
import LinkButton from '../Buttons/LinkButton';
import { Permission } from '../../types/Permissions';

export default function NoTimesheets({
  filterText,
  loading,
}: {
  filterText?: string;
  loading?: boolean;
}) {
  const { userProfile } = useContext(UserProfileContext);

  return (
    <NoData loading={loading}>
      {filterText
        ? 'It looks like there are no timesheets that match your query!'
        : 'It looks like there are no timesheets configured for this organisation!'}
      {userProfile?.permissions?.includes(
        Permission.UpdateTimesheetConfiguration
      ) ? (
        <LinkButton to={`/time/timesheets/configuration/new`} target="_blank">
          Add a Timesheet Configuration
        </LinkButton>
      ) : null}
    </NoData>
  );
}
