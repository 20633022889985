import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { useEffect } from 'react';

import FormCard from '../../../components/FormCard/FormCard';

import { SAVE_MY_COMPANY_DETAILS } from '../../../graphql/mutations/company-details';
import { GET_MY_COMPANY_DETAILS } from '../../../graphql/queries/my-profile';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { FormConfig } from '../../../types/Form';
import { useToast } from '../../../context/ToastContext';

export const SaveMyCompany = () => {
  const navigate = useNavigate();
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const { addToast } = useToast();

  const [fetchCompanys, { data }] = useLazyQuery(GET_MY_COMPANY_DETAILS, {
    variables: {},
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchCompanys({ variables: {} });
  }, [fetchCompanys]);

  const [company] = data?.getMyCompanyDetails?.results || [];

  const [saveMyCompany] = useMutation(SAVE_MY_COMPANY_DETAILS);

  const initialValues = { ...company };

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      await saveMyCompany({
        variables: {
          id: values?.id,
          input: {
            ...values,
            owner: undefined,
            id: undefined,
            updatedAt: undefined,
            createdAt: undefined,
          },
        },
      });
      addToast('Company details updated', 'success');
      if (setSubmitting) {
        setSubmitting(false);
      }
      navigate(-1);
    } catch (error) {
      console.error(error);
      addToast('Failed to update company details', 'error');
    }
  };
  const companyConfig = {
    formSections: [
      {
        title: 'Company Information',
        description: 'Basic details about the company',
        fields: [
          {
            id: 'companyName',
            type: 'input',
            title: 'Company Name',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'companyEmail',
            type: 'input',
            title: 'Company Email',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'contactName',
            type: 'input',
            title: 'Contact Name',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'companyRegNumber',
            type: 'input',
            title: 'Company Registration Number',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'companyPhoneNumber',
            type: 'input',
            title: 'Company Phone Number',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'companyType',
            type: 'dropdown',
            title: 'Company Type',
            options: [
              {
                name: 'Limited',
                value: 'LIMITED',
              },
              { name: 'Umbrella', value: 'UMBRELLA' },
            ],
          },
          {
            id: 'companyAddress',
            type: 'textarea',
            title: 'Company Address',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'vatRegistrationNumber',
            type: 'input',
            title: 'VAT Registration Number',
            inputTransformer: (text: string) => text,
          },
        ],
      },
    ],
  } as FormConfig;

  const CompanyCreationSchema = Yup.object().shape({
    companyName: Yup.string()
      .min(3, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required(),
    companyEmail: Yup.string()
      .email('Invalid email format')
      .typeError('Required field')
      .required(),
    contactName: Yup.string()
      .min(2, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required(),
    companyRegNumber: Yup.string()
      .min(2, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required(),
    companyPhoneNumber: Yup.string()
      .min(2, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required(),
    companyType: Yup.string().nullable(),
    companyAddress: Yup.string()
      .min(2, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required(),
    vatRegistrationNumber: Yup.string()
      .min(2, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required(),
  });

  return (
    <FormCard
      key={`${companyConfig?.title}`}
      config={companyConfig}
      validationSchema={CompanyCreationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
};
