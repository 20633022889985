import { useEffect } from 'react';
import { useOrganisationAwareApollo } from '../../useOrganisationAwareApollo';
import { ApiHookMeta } from '../types';
import { GET_COMPANIES_WITH_MISSING_OR_INVALID_CERTIFICATIONS } from '../../../graphql/queries/companies';

export enum CompanyCertificationFilter {
  EXPIRED = 'EXPIRED',
  EXPIRING = 'EXPIRING',
  MISSING = 'MISSING',
}

export default function useCompaniesWithMissingOrInvalidCertifications({
  projectId,
  filter,
  limit,
  offset,
}: {
  projectId: string;
  filter?: CompanyCertificationFilter;
  offset?: number;
  limit?: number;
}): [any, ApiHookMeta] {
  const { useLazyQuery } = useOrganisationAwareApollo();

  const [fetch, { data, loading, error, ...meta }] = useLazyQuery(
    GET_COMPANIES_WITH_MISSING_OR_INVALID_CERTIFICATIONS,
    {
      variables: { projectId },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    fetch({
      variables: {
        filter,
        projectId,
        input: {
          limit,
          offset,
        },
      },
    });
  }, [fetch, filter, limit, offset, projectId]);

  const results =
    data?.getCompaniesWithMissingOrInvalidCertifications?.results || [];
  const count =
    data?.getCompaniesWithMissingOrInvalidCertifications?.count || 0;

  return [
    results,
    {
      count,
      loading,
      error,
      ...meta,
    },
  ];
}
