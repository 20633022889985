import { Button } from '../../../../components/Buttons/Button';

import NumericInput from '../../../../components/Inputs/NumericInput';

export const PersonnelEntry = ({
  date,
  personnel,
  handlePersonnelChange,
  setModalPersonnelId,
  locked,
}: any) => {
  const PersonnelInput = ({ thePersonnel }: any) => {
    const personHoursToday =
      Number(thePersonnel.hoursInWorkingDay ?? 0) *
      Number(thePersonnel.peoplePerDay ?? 0);
    return (
      <>
        <div className={`flex flex-col mt-2`}>
          <div className="flex flex-col gap-y-2 lg:gap-y-0 lg:grid grid-cols-6 gap-x-2 py-0 text-sm items-center">
            <label
              style={
                thePersonnel.hasChanged && !thePersonnel.savedLocally
                  ? { borderColor: 'orange' }
                  : thePersonnel.savedLocally
                  ? { borderColor: 'rgb(63, 81, 181)' }
                  : {}
              }
              className="col-span-1 border-b-4 pb-1"
            >
              {thePersonnel?.name}:
            </label>
            <div className="flex flex-col">
              <NumericInput
                type="number"
                min="0"
                step={'.5'}
                isDisabled={locked}
                value={thePersonnel.hoursInWorkingDay ?? 0}
                pattern="[0-9]"
                handleChange={(value: any) =>
                  handlePersonnelChange(
                    thePersonnel.id,
                    'hoursInWorkingDay',
                    Number(value)
                  )
                }
                className="border p-1 rounded text-lg"
              />
            </div>
            <div className="flex flex-col">
              <NumericInput
                type="number"
                min="0"
                step={'1'}
                isDisabled={locked}
                value={thePersonnel.peoplePerDay ?? 0}
                pattern="[0-9]"
                handleChange={(value: any) =>
                  handlePersonnelChange(
                    thePersonnel.id,
                    'peoplePerDay',
                    Number(value)
                  )
                }
                className="border p-1 rounded text-lg"
              />
            </div>
            <div className="flex flex-col items-center">{personHoursToday}</div>
            <div className="flex flex-col items-center">
              {Number(thePersonnel.totalPersonHours ?? 0) + personHoursToday}
            </div>

            <Button
              style={thePersonnel.comment ? { background: 'orange' } : {}}
              text={
                locked
                  ? 'View Comment'
                  : thePersonnel?.comment
                  ? 'View/Edit Comment'
                  : `Add Comments`
              }
              onClick={() => setModalPersonnelId(thePersonnel.id)}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="rounded-sm">
      <div className="hidden lg:flex flex-col py-2 px-4 border-b bg-white gap-y-2 lg:gap-y-0 lg:grid grid-cols-6 gap-x-2 text-sm items-center">
        <p className="text-xs xl:text-sm">Team</p>
        <p className="text-center text-xs xl:text-sm">Hours in working day</p>
        <p className="text-center text-xs xl:text-sm">People per day</p>
        <p className="text-center text-xs xl:text-sm">Man hours per day</p>
        <p className="text-center text-xs xl:text-sm">
          Man-hours total (to {date})
        </p>
        <p className="text-xs xl:text-sm">Comments</p>
      </div>
      <div className="w-full border-b py-2 gap-2 px-4 bg-gray-50">
        <div className="w-full flex md:flex-col flex-wrap lg:grid lg:grid-cols-1 gap-x-10 gap-y-10 lg:gap-y-0 items-center justify-center">
          {personnel
            ?.sort((a: any, b: any) => {
              const nameA = a.name.toString().toLowerCase();
              const nameB = b.name.toString().toLowerCase();

              return nameA.localeCompare(nameB, undefined, {
                numeric: true,
                sensitivity: 'base',
              });
            })
            ?.map((team: any) => (
              <>
                <div className="flex flex-col">
                  <PersonnelInput thePersonnel={team} />
                </div>
              </>
            ))}
        </div>
      </div>
    </div>
  );
};
