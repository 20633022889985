import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useContext, useEffect } from 'react';

import { SAVE_ROLE } from '../../../graphql/mutations/roles';
import { GET_ROLES } from '../../../graphql/queries/roles';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import FormSubmit from '../../../components/Buttons/FormSubmit';
import RateConfigurationByKey from '../../../components/RateConfiguration/RateConfigurationByKey';
import { Currency } from '../../../types/Currency';
import { GET_ACTIVITY_CODES } from '../../../graphql/queries/activity-codes';
import { useToast } from '../../../context/ToastContext';
import { ActiveOrganisationContext } from '../../../context/ActiveOrganisationContext';

export const SaveRole = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { useQuery, useMutation } = useOrganisationAwareApollo();

  const { activeOrganisation } = useContext(ActiveOrganisationContext);

  const financeModuleEnabled = activeOrganisation?.financeModuleEnabled;

  const { addToast } = useToast();

  const isUpdate = !!id;

  const { data, refetch } = useQuery(GET_ROLES, {
    variables: { input: { id } },
    skip: !isUpdate,
  });

  const { data: activityCodes, refetch: fetchActivityCodes } = useQuery(
    GET_ACTIVITY_CODES,
    {
      variables: {},
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (isUpdate) {
      refetch({ id });
      fetchActivityCodes();
    }
  }, [id, refetch, isUpdate]);

  const [role] = data?.getRoles?.results || [];

  const activityCodesResults = activityCodes?.getActivityCodes?.results || [];
  const filteredAndMappedActivityCodes =
    activityCodesResults.filter(
      (code: any) => code.enabled && !code.useResourceDefaultRate
    ) || [];

  const inputs = [
    {
      label: 'Name',
      name: 'name',
      type: 'text',
      required: true,
    },
    {
      label: 'Description',
      name: 'description',
      type: 'textarea',
      required: true,
    },
    ...(activityCodesResults.length > 0 && financeModuleEnabled
      ? [
          {
            label: 'Default Rates',
            name: 'defaultRates',
            type: 'rateConfigurationByKey',
            required: true,
          },
        ]
      : []),
  ] as any;

  const [saveRole, { loading }] = useMutation(SAVE_ROLE);
  const currencies = Object.values(Currency).filter((e: any) => isNaN(e));

  const initialDefaultRates = currencies.reduce((acc: any, currency: any) => {
    const currencyActivities = filteredAndMappedActivityCodes.map(
      (activityCode: any) => ({
        columnId: currency,
        name: activityCode?.name,
        rowId: activityCode.key,
        value: 0,
      })
    );

    return [...acc, ...currencyActivities];
  }, []);

  const initialValues = id
    ? {
        ...role,
        defaultRates: role?.defaultRates?.map((itemRate: any) => ({
          columnId: itemRate.currency,
          name:
            itemRate.name ??
            filteredAndMappedActivityCodes.find(
              (code: any) => code.id === itemRate.key
            )?.name,
          rowId: itemRate.key,
          value: parseFloat(itemRate.rate ?? 0),
        })),
      }
    : {
        name: '',
        defaultRates: initialDefaultRates,
      };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });

  const onSubmit = async (
    values: Record<string, any>,
    { setSubmitting }: any
  ) => {
    try {
      await saveRole({
        variables: {
          id: values?.id,
          input: {
            ...values,
            defaultRates: values?.defaultRates
              ?.map((rateOption: any) => ({
                id: `${rateOption?.columnId}_${rateOption?.rowId}`,
                name: rateOption?.name,
                currency: rateOption?.columnId,
                key: rateOption?.rowId,
                rate: parseFloat(rateOption?.value),
              }))
              .filter((rate: any) =>
                filteredAndMappedActivityCodes
                  .map((code: any) => code.id)
                  .includes(rate.key)
              ),
            id: undefined,
            updatedAt: undefined,
            createdAt: undefined,
          },
        },
      });
      setSubmitting(false);
      if (id) {
        navigate(`/labour/roles/${id}`);
      } else {
        navigate(`/labour/roles/list`);
      }
      addToast('Role saved successfully', 'success');
    } catch (error) {
      console.error(error);
      addToast('Failed to save role', 'error');
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ isValid, setFieldValue, values }) => (
        <Form className="space-y-6 mx-8 my-8">
          <div className="flex flex-col">
            {isUpdate ? (
              <>
                <h1 className="text-3xl font-extrabold text-gray-900">
                  {values?.name}
                </h1>
                <p className="mt-1 text-sm text-gray-500">ID: {id}</p>
              </>
            ) : (
              <h1 className="text-3xl font-extrabold text-gray-900">
                Create Role
              </h1>
            )}
          </div>
          {inputs.map((input: any) => (
            <div key={input.name}>
              <label
                htmlFor={input.name}
                className="block text-gray-700 font-bold mb-2"
              >
                {input.label}
              </label>
              {input.type !== 'rateConfigurationByKey' ? (
                <Field
                  as={input.type === 'textarea' ? 'textarea' : 'input'}
                  id={input.name}
                  name={input.name}
                  type={input.type}
                  autoComplete="off"
                  required={input.required}
                  rows={input.type === 'textarea' ? 3 : undefined}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                />
              ) : input.type === 'rateConfigurationByKey' ? (
                <RateConfigurationByKey
                  value={values?.[input.name] ?? []}
                  handleChange={(item) => setFieldValue(input.name, item)}
                  question={{
                    tableOptions: filteredAndMappedActivityCodes?.map(
                      (key: any) => ({
                        heading: {
                          id: key.id,
                          name: key.name,
                          colour: key?.colour,
                        },
                        options: currencies.map((entry) => ({
                          heading: {
                            id: Object.entries(Currency).find(
                              (item: any) => item[1] === entry
                            )?.[0],
                            name: Object.values(Currency).find(
                              (currency) => currency === entry
                            ),
                          },
                        })) as {
                          heading?: {
                            id: string;
                            name: string;
                          };
                        }[],
                      })
                    ),
                  }}
                />
              ) : (
                <div className="my-5">
                  <label
                    htmlFor={input.name}
                    className="block text-gray-700 font-bold my-5"
                  >
                    {values?.[input.name]}
                  </label>
                </div>
              )}
              <ErrorMessage
                name={input.name}
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>
          ))}
          <div className="flex justify-end">
            <FormSubmit
              disabled={!isValid}
              isLoading={loading}
              submitText={isUpdate ? 'Save' : 'Create'}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
