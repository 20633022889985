import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import {
  DELETE_CERTIFICATION,
  SAVE_CERTIFICATION,
} from '../../../graphql/mutations/certifications';
import {
  GET_CERTIFICATION_ATTACHMENTS,
  SEARCH_CERTIFICATION_TYPES,
} from '../../../graphql/queries/certifications';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { useToast } from '../../../context/ToastContext';
import { GET_CERTIFICATION_ATTACHMENT_URL } from '../../../graphql/queries/attachments';
import {
  DELETE_CERTIFICATION_ATTACHMENT,
  SAVE_ATTACHMENT,
} from '../../../graphql/mutations/attachments';
import { saveFileToS3 } from '../../../utils/utils';
import { Permission } from '../../../types/Permissions';
import { FormConfig } from '../../../types/Form';
import { Button } from '../../../components/Buttons/Button';
import { DeletionDialogue } from '../../../components/Deletion/DeletionDialogue';
import FormCard from '../../../components/FormCard/FormCard';
import moment from 'moment';
import { Table } from '../../../components/Tables/tables/Table';
import { YellowBadge } from '../../../components/Badges/Badges';
import { useCompany } from '../../../hooks/api/companies/useCompany';
import { useCertification } from '../../../hooks/api/certifications/useCertifications';

const headerMapping = {
  name: 'File Name',
  id: 'ID',
  download: 'Download',
  updatedAt: 'Updated At',
} as Record<string, any>;

const SaveCertificationPage = () => {
  let { id, companyId } = useParams();

  const location = useLocation();
  const navigate = useNavigate();
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const [company] = useCompany(companyId);

  const isUpdate = !!id;

  const [deleteCertification] = useMutation(DELETE_CERTIFICATION);

  const [certification] = useCertification(id);

  const [searchCertificationTypes, { data: certificationtypesData }] =
    useLazyQuery(SEARCH_CERTIFICATION_TYPES);

  const { limit, offset, setOffset } = usePagination();

  const { userProfile } = useContext(UserProfileContext);

  const { addToast } = useToast();

  const [showDeleteAttachmentModal, setShowDeleteAttachmentModal] =
    useState(false);
  const [showDeleteCertificationModal, setShowDeleteCertificationModal] =
    useState(false);
  const [deletionFocus, setDeletionFocus] = useState('');

  const [getCertificationAttachmentURL, { data: certificationAttachmentURL }] =
    useLazyQuery(GET_CERTIFICATION_ATTACHMENT_URL);

  const [fetchAttachmentsForThisCertification, { data: attachmentsData }] =
    useLazyQuery(GET_CERTIFICATION_ATTACHMENTS);

  const [deleteCertificationAttachment] = useMutation(
    DELETE_CERTIFICATION_ATTACHMENT
  );

  useEffect(() => {
    if (certification) {
      searchCertificationTypes({
        variables: { searchTerm: certification?.certificationType?.name },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certification]);

  useEffect(() => {
    if (certification) {
      fetchAttachmentsForThisCertification({
        variables: {
          certificationId: certification.id,
          input: { limit, offset },
        },
        fetchPolicy: 'network-only',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certification, limit, offset]);

  const totalPages = Math.ceil(
    attachmentsData?.getCertificationAttachments?.count / limit
  );

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const [saveAttachment] = useMutation(SAVE_ATTACHMENT);
  const [saveCertification] = useMutation(SAVE_CERTIFICATION);

  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  const performCertificationTypeSearch = async () => {
    try {
      await searchCertificationTypes({
        variables: {
          searchTerm: debouncedSearchTerm,
        },
        fetchPolicy: 'network-only',
      });
    } catch (err) {
      // do nothing
    } finally {
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 600); // 300ms debounce time
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    performCertificationTypeSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (
      attachmentsData &&
      attachmentsData.getCertificationAttachments.results.length > 0
    ) {
      const firstAttachmentId =
        attachmentsData.getCertificationAttachments.results[0].id;
      getCertificationAttachmentURL({
        variables: { input: { id: firstAttachmentId } },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachmentsData]);

  const initialValues = id
    ? {
        id,
        certificationType: certification?.certificationType?.id,
        issueDate: certification?.issueDate
          ? Number(certification?.issueDate)
          : undefined,
        expiryDate: certification?.expiryDate
          ? Number(certification?.expiryDate)
          : undefined,
      }
    : {};

  const validationSchema = Yup.object().shape({
    expiryDate: Yup.string(),
    certificationType: Yup.string().required('Certification type is required'),
  });

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void,
    setFieldValue?: (id: any, value: any) => void
  ) => {
    try {
      let fileSaved;
      let newAttachmentId;
      if (values?.file?.name) {
        const { data } = await saveAttachment({
          variables: {
            input: {
              name: values.importName,
              id: null,
              fileName: values.file.name,
            },
          },
        });

        const presignedUrl = data?.saveAttachment?.presignedUrl;

        fileSaved = await saveFileToS3(values.file, presignedUrl);
        newAttachmentId = data?.saveAttachment?.id;
      }

      if (!values?.file?.name || (values?.file?.name && fileSaved)) {
        const { data } = await saveCertification({
          variables: {
            id: values?.id,
            input: {
              ...values,
              companyId,
              file: undefined,
              newAttachmentId,
              issueDate: values?.issueDate
                ? String(values?.issueDate)
                : undefined,
              expiryDate: values?.expiryDate
                ? String(values?.expiryDate)
                : undefined,
              id: undefined,
              updatedAt: undefined,
              createdAt: undefined,
            },
          },
        });

        setSubmitting(false);

        if (data.saveCertification.success) {
          addToast('Saving certification successful', 'success');
          if (!id || (id && !newAttachmentId)) {
            navigate(`/companies/${companyId}?tab=Certifications`);
          } else {
            await fetchAttachmentsForThisCertification({
              variables: { certificationId: id, input: { limit, offset } },
              fetchPolicy: 'network-only',
            });
            if (setFieldValue) {
              setFieldValue('file', undefined);
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const docs = [
    {
      uri: certificationAttachmentURL?.getCertificationAttachmentURL
        ?.presignedUrl,
      fileType:
        attachmentsData?.getCertificationAttachments?.results[0]?.fileName?.split(
          '.'
        )[1],
    },
  ];

  const certificationConfig = {
    title: '',
    description: '',
    formSections: [
      {
        title: id ? certification?.certificationType?.name : 'Certification',
        components:
          userProfile?.permissions.includes(
            Permission.DownloadCertificationAttachments
          ) &&
          certificationAttachmentURL?.getCertificationAttachmentURL
            ?.presignedUrl
            ? [
                <h3>Latest Certification Preview:</h3>,
                <DocViewer
                  style={{
                    height: '70vh',
                    marginTop: '20px',
                    marginBottom: '20px',
                  }}
                  documents={docs}
                  config={{
                    header: {
                      disableHeader: true,
                      disableFileName: true,
                      retainURLParams: true,
                    },

                    pdfZoom: {
                      defaultZoom: 0.6,
                      zoomJump: 0.2,
                    },
                  }}
                  pluginRenderers={DocViewerRenderers}
                  prefetchMethod="GET"
                />,
              ]
            : [],
        fields: [
          ...(!id
            ? [
                {
                  title: 'Certification Type',
                  id: 'certificationType',
                  type: id ? 'input' : 'combobox',
                  disabled: !!id,
                  placeholder: 'Start typing the certification name',
                  description: 'This must match an existing certification name',
                  // @ts-ignore
                  onChange: (value: string) => {
                    setSearchTerm(value);
                  },
                  valueTransformer: (id: string) =>
                    certificationtypesData?.searchCertificationTypes?.results?.find(
                      (result: any) => result.id === id
                    )?.name,
                  options:
                    certificationtypesData?.searchCertificationTypes?.results.map(
                      (certification: any) => ({
                        id: certification.id,
                        name: certification.name,
                      })
                    ),
                  required: true,
                },
              ]
            : []),
          {
            id: 'issueDate',
            type: 'datepicker',
            title: 'Issue Date',
            inputTransformer: (value: Date) => new Date(value).getTime(),
          },
          {
            id: 'expiryDate',
            type: 'datepicker',
            title: 'Expiry Date',
            inputTransformer: (value: Date) => new Date(value).getTime(),
          },
          {
            id: 'file',
            type: 'file',
            title: 'Upload Certification',
            limits: 'application/msword, application/pdf',
            description: 'PDF or DOCX',
          },
        ],
      },
    ],
  } as FormConfig;

  const downloadAttachment = async (id: string, fileName: string) => {
    const { data } = await getCertificationAttachmentURL({
      variables: { input: { id } },
    });
    if (data.getCertificationAttachmentURL.presignedUrl) {
      // Create a new link
      const anchor = document.createElement('a');
      anchor.href = data.getCertificationAttachmentURL.presignedUrl;
      anchor.download = fileName;
      anchor.style.display = 'none';

      // Append to the DOM
      document.body.appendChild(anchor);

      // Trigger `click` event
      anchor.click();

      // Remove element from DOM
      document.body.removeChild(anchor);

      // Revoke the Blob URL to free up resources
      URL.revokeObjectURL(data.getCertificationAttachmentURL.presignedUrl);
    }
  };

  const hasDeletePermission = userProfile?.permissions?.includes(
    Permission.DeleteCertifications
  );

  return (
    <>
      <div className="flex w-full border-b mb-2">
        <Button
          style={{
            backgroundColor: 'transparent',
            color: 'black',
            boxShadow: 'none',
            borderRadius: 0,
          }}
          onClick={() => navigate(-1)}
          type="submit"
          text={`< Go Back`}
        />
      </div>
      <div className="flex flex-col md:items-center md:flex-row w-full md:justify-between px-4 py-5 sm:px-6 flex-wrap">
        <div className="flex flex-col">
          <h1 className="text-3xl font-extrabold text-gray-900">
            {company?.companyName}
          </h1>
          <p className="mt-1 text-sm text-gray-500">
            Created: {moment.unix(company?.createdAt / 1000).fromNow()}
          </p>
          <p className="mt-1 text-sm text-gray-500">
            Updated: {moment.unix(company?.updatedAt / 1000).fromNow()}
          </p>
        </div>
      </div>
      <DeletionDialogue
        deleteTitle={'Delete Attachment'}
        showDeleteModal={showDeleteAttachmentModal}
        setShowDeleteModal={setShowDeleteAttachmentModal}
        onDelete={
          isUpdate
            ? async () => {
                await deleteCertificationAttachment({
                  variables: { id: deletionFocus },
                });
                await fetchAttachmentsForThisCertification({
                  variables: { certificationId: id, input: { limit, offset } },
                  fetchPolicy: 'network-only',
                });
                setShowDeleteAttachmentModal(false);
              }
            : undefined
        }
        deletionConfirmationText="Are you sure you want to delete this attachment? You will not be able to restore it once deleted."
      />

      <DeletionDialogue
        deleteTitle={'Delete Certification'}
        showDeleteModal={showDeleteCertificationModal}
        setShowDeleteModal={setShowDeleteCertificationModal}
        onDelete={
          isUpdate
            ? async () => {
                await deleteCertification({
                  variables: { id: id },
                });
                navigate(
                  location.pathname.split('/certifications')?.[0] +
                    `?tab=Certifications`
                );
              }
            : undefined
        }
        deletionConfirmationText="Are you sure you want to delete this certification? You will not be able to restore it once deleted."
      />
      <FormCard
        key={`${certificationConfig?.title}`}
        config={certificationConfig}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
        onDelete={
          isUpdate && hasDeletePermission
            ? () => setShowDeleteCertificationModal(true)
            : undefined
        }
      />
      {id ? (
        <div>
          <h1 className="my-2">Attachments</h1>
          <Table
            currentPage={Math.floor(offset / limit) + 1}
            totalPages={totalPages}
            totalResults={
              attachmentsData?.getCertificationAttachments?.count || 0
            }
            itemsPerPage={limit}
            columnsToFilter={[]}
            columnValueMapper={{
              updatedAt: (text: string) =>
                moment.unix(parseInt(text) / 1000).fromNow(),
            }}
            headerMapper={(text: string) => headerMapping[text]}
            onPageChange={handlePageChange}
            data={
              attachmentsData?.getCertificationAttachments?.results.length > 0
                ? attachmentsData?.getCertificationAttachments?.results?.map(
                    (result: any) => ({
                      id: result?.id,
                      name: result?.fileName,
                      updatedAt: result?.updatedAt,
                      download: userProfile?.permissions.includes(
                        Permission.DownloadCertificationAttachments
                      ) ? (
                        <div className="flex flex-row gap-x-2">
                          <Button
                            text={'Download'}
                            onClick={async () =>
                              await downloadAttachment(
                                result.id,
                                result?.fileName
                              )
                            }
                          />
                          {userProfile?.permissions?.includes(
                            Permission.DeleteCertificationAttachments
                          ) ? (
                            <Button
                              text={'Delete'}
                              style={{ backgroundColor: '#b3063c' }}
                              onClick={() => {
                                setDeletionFocus(result.id);
                                setShowDeleteAttachmentModal(true);
                              }}
                            />
                          ) : null}
                        </div>
                      ) : (
                        <YellowBadge text="Not Available" />
                      ),
                    })
                  )
                : []
            }
          />
        </div>
      ) : null}
    </>
  );
};

export const SaveCompanyCertification = () => (
  <PaginationProvider>
    <SaveCertificationPage />
  </PaginationProvider>
);
