import { gql } from '@apollo/client';

export const GET_SUBCONTRACTORS_SUMMARY = gql`
  query getSubcontractors(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $internalIdFilter: String
  ) {
    getSubcontractors(
      input: $input
      activeOrganisation: $activeOrganisation
      internalIdFilter: $internalIdFilter
    ) {
      count
      results {
        id
        firstName
        lastName
        defaultRole {
          id
          name
        }
        internalId
        company {
          companyName
        }
        email
        status
        enabled
        updatedAt
      }
    }
  }
`;

export const GET_SUBCONTRACTORS = gql`
  query getSubcontractors(
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    getSubcontractors(input: $input, activeOrganisation: $activeOrganisation) {
      count
      results {
        id
        firstName
        lastName
        email
        jobTitle
        defaultRole {
          id
          name
        }
        personalProfile
        projectExperience
        nationality
        countryOfResidence
        company {
          id
          companyName
        }
        defaultRate {
          id
          currency
          key
          name
          rate
        }
        customData {
          key
          value
        }
        type
        internalId
        status
        createdAt
        updatedAt
        isOwnerOfCompany
        missingOrInvalidCertifications {
          id
          name
          notes
        }
      }
    }
  }
`;
