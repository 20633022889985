import { useContext, useState } from 'react';
import { GET_LABOUR_ASSIGNED_TO_COMPANY } from '../../../graphql/queries/companies';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';
import { Table } from '../../../components/Tables/tables/Table';
import { useNavigate } from 'react-router-dom';
import { BlueBadge, YellowBadge } from '../../../components/Badges/Badges';
import Avatar from 'react-avatar';
import moment from 'moment';
import NoData from '../../../components/NoData/NoData';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';
import LinkButton from '../../../components/Buttons/LinkButton';
import { UNASSIGN_LABOUR_FROM_COMPANY } from '../../../graphql/mutations/company-details';
import { useToast } from '../../../context/ToastContext';
import { Button } from '../../../components/Buttons/Button';
import { Modal } from '../../../components/Dialogue/ModalDialogue';

const headerMapping = {
  name: 'Name',
  email: 'Email',
  internalId: 'Internal Id',
  updatedAt: 'Updated At',
  actions: 'Actions',
} as Record<string, any>;

function CompanyLabourTable({ company }: { company: any }) {
  const { userProfile } = useContext(UserProfileContext);
  const { useQuery, useMutation } = useOrganisationAwareApollo();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { limit, offset, setOffset } = usePagination();

  const [pendingUnassignment, setPendingUnassignment] =
    useState<any>(undefined);

  const [saveUnassignment, { loading: isSaving }] = useMutation(
    UNASSIGN_LABOUR_FROM_COMPANY
  );

  const { data, loading, refetch } = useQuery(GET_LABOUR_ASSIGNED_TO_COMPANY, {
    variables: { companyId: company.id, limit, offset },
    fetchPolicy: 'network-only',
  });

  const count = data?.getLabourAssignedToCompany?.count;
  const results = data?.getLabourAssignedToCompany?.results;

  const totalPages = Math.ceil(count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const unassignLabourFromCompany = async (id: string) => {
    const { data } = await saveUnassignment({
      variables: { companyId: company.id, labourResourceIds: [id] },
    });

    if (data.unassignLabourFromCompany?.success) {
      addToast('Labour successfully unassigned', 'success');
      refetch();
    } else {
      addToast('Failed to unassign labour', 'error');
    }
  };

  return (
    <>
      <Modal
        showModal={!!pendingUnassignment}
        setShowModal={setPendingUnassignment}
        title={'Confirm Unassignment'}
        onComplete={async () => {
          await unassignLabourFromCompany(pendingUnassignment!.id);
          setPendingUnassignment(undefined);
        }}
      >
        <div className="px-4 py-4">
          <div className="text-gray-500 flex flex-col gap-y-4">
            {pendingUnassignment && (
              <div>
                You are about to unassign {pendingUnassignment?.firstName}{' '}
                {pendingUnassignment?.lastName} ({pendingUnassignment?.email})
                from {company?.companyName}.
              </div>
            )}
            <div>
              Are you sure you want to unassign this person? You can reassign
              them later.
            </div>
          </div>
        </div>
      </Modal>
      <Table
        currentPage={Math.floor(offset / limit) + 1}
        totalPages={totalPages}
        itemsPerPage={limit}
        totalResults={count || 0}
        headerMapper={(text: string) => headerMapping[text]}
        onClickRow={(id: any) => {
          const labourResource = results.find(
            (result: any) => result.id === id
          );

          if (labourResource.type === 'SUBCONTRACTOR') {
            navigate(`/labour/subcontractors/${id}`);
          } else {
            navigate(`/labour/employees/${id}`);
          }
        }}
        onPageChange={handlePageChange}
        data={results?.map((result: any) => ({
          id: result?.id,
          name: (
            <div className="flex gap-x-2 items-center">
              <Avatar
                name={`${result?.firstName} ${result?.lastName}`}
                size="30"
                round
              />
              {`${result.lastName}, ${result.firstName}`}
            </div>
          ),
          email: result?.email || 'Not Configured',
          internalId: result?.internalId ? (
            <BlueBadge text={result?.internalId} />
          ) : (
            <YellowBadge text={'Not Configured'} />
          ),
          updatedAt: moment.unix(parseInt(result.updatedAt) / 1000).fromNow(),
          status: undefined,
          actions: (
            <Button
              className="bg-red-500 hover:bg-red-600"
              disabled={isSaving}
              onClick={(e) => {
                e.stopPropagation();
                setPendingUnassignment(result);
              }}
            >
              Unassign
            </Button>
          ),
        }))}
        NoData={() => (
          <NoData loading={loading}>
            It looks like there are no subcontractors assigned to this company!
            {userProfile?.permissions.includes(
              Permission.UpdateLabourProfiles
            ) ? (
              <LinkButton
                to={`/companies/${company.id}/assign`}
                target="_blank"
              >
                Assign Subcontractors
              </LinkButton>
            ) : null}
          </NoData>
        )}
      />
    </>
  );
}

export function CompanyLabour({ company }: { company: any }) {
  return (
    <PaginationProvider>
      <CompanyLabourTable company={company} />
    </PaginationProvider>
  );
}
