import moment from 'moment';
import { useCallback, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../components/Buttons/Button';
import { ProjectAssignmentRoleList } from './roles/list';
import { SentContractsListForAssignee } from './contracts/list';
import Avatar from 'react-avatar';
import { ActiveOrganisationContext } from '../../../context/ActiveOrganisationContext';
import { useAssignment } from '../../../hooks/api/useAssignments';
import * as Accordion from '@radix-ui/react-accordion';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

export const AssignmentPage = () => {
  let { projectId, assignmentId } = useParams();
  const navigate = useNavigate();

  const { activeOrganisation } = useContext(ActiveOrganisationContext);

  const docusealEnabled = activeOrganisation?.docusealEnabled;

  const [assignment, { loading, error, refetch }] = useAssignment({
    id: assignmentId!,
    projectId: projectId!,
  });

  const [refetchAssignmentRoles, setRefetchAssignmentRoles] = useState<
    () => void
  >(() => {});

  const [refetchArchivedAssignmentRoles, setRefetchArchivedAssignmentRoles] =
    useState<() => void>(() => {});

  const refetchAllAssignmentRoles = useCallback(() => {
    refetch();
    refetchAssignmentRoles?.();
    refetchArchivedAssignmentRoles?.();
  }, [refetch, refetchAssignmentRoles, refetchArchivedAssignmentRoles]);

  const hasArchivedRoles = assignment?.hasArchivedRoles;

  const roleSections = [
    {
      title: 'Assigned Roles',
      content: (
        <ProjectAssignmentRoleList
          assignment={assignment}
          archivedOnly={false}
          refetchAllAssignmentRoles={refetchAllAssignmentRoles}
          setRefetchAssignmentRoles={setRefetchAssignmentRoles}
          setRefetchArchivedAssignmentRoles={setRefetchArchivedAssignmentRoles}
        />
      ),
    },
    {
      title: 'Archived Roles',
      content: (
        <ProjectAssignmentRoleList
          assignment={assignment}
          archivedOnly={true}
          refetchAllAssignmentRoles={refetchAllAssignmentRoles}
          setRefetchAssignmentRoles={setRefetchAssignmentRoles}
          setRefetchArchivedAssignmentRoles={setRefetchArchivedAssignmentRoles}
        />
      ),
    },
  ] as { title?: string; content?: any }[];

  const type =
    assignment?.lrs?.type === 'SUBCONTRACTOR' ? 'Subcontractor' : 'Employee';
  const isSubcontractor = assignment?.lrs?.type === 'SUBCONTRACTOR';
  const Tabs = ['Roles'];

  if (isSubcontractor && docusealEnabled && assignment?.lrs?.email) {
    Tabs.push('Contracts');
  }

  const [activeTab, setActiveTab] = useState(Tabs[0]);

  const workerRecord = `/labour/${
    isSubcontractor
      ? `subcontractors/${assignment?.lrs?.id}`
      : `employees/${assignment?.lrs?.id}`
  }`;

  return (
    <div className="bg-gray-50">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="w-full px-2 bg-gray-800 py-2 flex flex-row">
          {Tabs.map((tab) => (
            <div
              className={`${
                activeTab === tab ? 'bg-black' : ''
              } justify-center flex items-center`}
            >
              <Button
                style={{ borderRadius: 0, backgroundColor: 'transparent' }}
                text={tab}
                onClick={() => setActiveTab(tab)}
              />
            </div>
          ))}
        </div>
        <div className="py-2 border-b">
          {assignment?.project?.name ? (
            <Button
              style={{
                backgroundColor: 'transparent',
                color: 'gray',
                boxShadow: 'none',
                borderRadius: 0,
              }}
              onClick={() => navigate(`/projects/${projectId}`)}
              type="submit"
              text={`< Back to Project: ${assignment?.project?.name}`}
            />
          ) : null}
        </div>
        <div className="flex flex-col md:items-center md:flex-row w-full md:justify-between px-4 py-5 sm:px-6 flex-wrap">
          <div className="flex flex-col">
            <a className="" href={workerRecord}>
              <div className="flex gap-x-2">
                <Avatar
                  name={`${assignment?.lrs?.firstName} ${assignment?.lrs?.lastName}`}
                  size="35"
                  round
                />
                <h1 className="text-3xl font-extrabold text-gray-900">
                  {assignment?.lrs?.firstName} {assignment?.lrs?.lastName}
                </h1>
              </div>
            </a>
            <h2 className="text-lg mt-1 font-base text-gray-500">
              Assigned to Project: {assignment?.project?.name} -{' '}
              {assignment?.project?.internalId}
            </h2>
            <p className="mt-1 text-sm text-gray-500">Type: {type}</p>
            <p className="mt-1 text-sm text-gray-500">
              Created: {moment.unix(assignment?.createdAt / 1000).fromNow()}
            </p>
            <p className="mt-1 text-sm text-gray-500">
              Updated: {moment.unix(assignment?.updatedAt / 1000).fromNow()}
            </p>
          </div>
          <div>
            {activeTab === 'Roles' ? (
              <Button
                onClick={() =>
                  navigate(
                    `/projects/${assignment?.project?.id}/assignments/${assignment.id}/roles`
                  )
                }
                type="submit"
                text="Assign new Role"
              />
            ) : null}
          </div>
        </div>

        {loading && (
          <div className="h-64 flex items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
          </div>
        )}
        {error && (
          <p className="px-4 py-5 sm:p-6 text-red-500">
            Error loading data. Please try again.
          </p>
        )}
        {activeTab === 'Roles' ? (
          <>
            {roleSections
              .filter((section) => section.title === 'Assigned Roles')
              .map((section, index) => (
                <div
                  key={`${index}_${section.title}`}
                  className="px-4 border-t border-gray-200"
                >
                  <h2 className="py-4 text-lg font-medium text-gray-900">
                    {section.title}
                  </h2>
                  <p className="mt-1 text-sm text-gray-500">
                    {section.content}
                  </p>
                </div>
              ))}
            {!!hasArchivedRoles
              ? roleSections
                  .filter((section) => section.title === 'Archived Roles')
                  .map((section, index) => (
                    <div
                      key={`${index}_${section.title}`}
                      className="px-4 m-4 rounded-lg border-t border-gray-200 bg-gray-100"
                    >
                      <Accordion.Root
                        type="single"
                        className="w-full"
                        collapsible
                      >
                        <Accordion.Item value="item-1" className="w-full">
                          <Accordion.Header className="w-full">
                            <Accordion.Trigger className="w-full">
                              <div className="flex items-center justify-between w-full">
                                <h2 className="py-4 text-lg font-medium text-gray-900">
                                  {section.title}
                                </h2>
                                <Accordion.Trigger className="group">
                                  <ChevronDownIcon
                                    style={{
                                      background: 'rgba(255,255,255,0.7)',
                                    }}
                                    className="group-data-[state=open]:rotate-180 h-10 w-10 flex-shrink-0 text-black rounded-full p-2 transition-transform duration-300"
                                  />
                                </Accordion.Trigger>
                              </div>
                            </Accordion.Trigger>
                          </Accordion.Header>
                          <Accordion.Content>
                            <div className="text-sm text-gray-500">
                              {section.content}
                            </div>
                          </Accordion.Content>
                        </Accordion.Item>
                      </Accordion.Root>
                    </div>
                  ))
              : null}
          </>
        ) : null}
        {activeTab === 'Contracts' ? (
          <SentContractsListForAssignee
            projectId={assignment?.project?.id}
            labourResourceId={assignment?.lrs?.id}
          />
        ) : null}
      </div>
    </div>
  );
};

export const Assignment = () => <AssignmentPage />;
