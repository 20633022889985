import { default as ComponentDatePicker } from 'react-tailwindcss-datepicker';
export interface DatePickerProps {
  label: string;
  value: any;
  handleChange: (date: any) => void;
  showShortcuts?: boolean;
  showFooter?: boolean;
}

const DateRangePicker = ({
  value,
  handleChange,
  ...props
}: DatePickerProps) => {
  return (
    <div>
      <ComponentDatePicker {...props} value={value} onChange={handleChange} />
    </div>
  );
};

export default DateRangePicker;
