import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from '../../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../../context/PaginationContext';
import {
  GreenBadge,
  RedBadge,
  YellowBadge,
} from '../../../../components/Badges/Badges';
import { Button } from '../../../../components/Buttons/Button';
import useLabourWithExpiringCertifications from '../../../../hooks/api/useLabourWithExpiringCertifications';
import NoData from '../../../../components/NoData/NoData';

interface ExpiringCertificationsListProps {
  certificationTypeId?: string;
  projectId?: string;
  showNewItemButton?: boolean;
  children?: React.ReactElement;
  filterIds?: string[];
  onClickRow?: (id: string, results: any[]) => void;
  showHeader?: boolean;
}

export const ExpiringCertificationsTable = ({
  data = [{}],
  currentPage = 1,
  totalPages = 0,
  itemsPerPage = 0,
  totalResults = 0,
  onPageChange = () => {},
  onClickRow,
  filterText,
  setFilterText = () => {},
  headerMapping,
  selectedFilterDimension,
  fetchPageOfDataForExport,
  setSelectedFilterDimension = () => {},
  loading,
}: any) => (
  <Table
    currentPage={currentPage}
    totalPages={totalPages}
    totalResults={totalResults}
    itemsPerPage={itemsPerPage}
    onClickRow={onClickRow}
    fetchPageOfDataForExport={fetchPageOfDataForExport}
    columnsToFilter={['firstName', 'lastName', 'id', 'status']}
    columnValueMapper={{
      updatedAt: (text: string) => moment.unix(parseInt(text) / 1000).fromNow(),
      expiry: (text: string) =>
        text
          ? moment.unix(parseInt(text) / 1000).format('LL')
          : ((<YellowBadge text={'No date provided'} />) as any),
      // @ts-ignore
      expiryDate: (text: string) => {
        if (!text) {
          return <YellowBadge text={'No date provided'} />;
        }
        const date = moment.unix(parseInt(text) / 1000);
        const now = moment();
        const tenWeeksFromNow = moment().add(10, 'weeks');

        if (date.isBefore(now)) {
          return <RedBadge text="Expired" />;
        } else if (date.isBefore(tenWeeksFromNow)) {
          return <YellowBadge text={date.fromNow()} />;
        } else {
          return <GreenBadge text={date.fromNow()} />;
        }
      },
    }}
    filterDimensions={[]}
    showFilterOptions={false}
    filterText={filterText}
    setFilterText={setFilterText}
    selectedFilterDimension={selectedFilterDimension}
    setSelectedFilterDimension={setSelectedFilterDimension}
    headerMapper={(text: string) => headerMapping[text]}
    onPageChange={onPageChange}
    data={data}
    NoData={() => (
      <NoData loading={loading}>
        It looks like there are no assignees that match that filter
      </NoData>
    )}
  />
);

export const ExpiringCertificationsListPage = ({
  certificationTypeId,
  projectId,
  children,
  onClickRow,
  filterIds = [],
  showHeader,
}: ExpiringCertificationsListProps) => {
  const navigate = useNavigate();

  const [filterText, setFilterText] = useState<string>();
  const [selectedFilterDimension, setSelectedFilterDimension] =
    useState<string>('name');

  const { limit, offset, setOffset } = usePagination();

  const [debouncedFilterText, setDebouncedSearchTerm] = useState(filterText);

  const filter = debouncedFilterText
    ? {
        filters: [
          {
            field: selectedFilterDimension,
            stringFilters: [{ ilike: debouncedFilterText }],
          },
        ],
      }
    : null;

  const tabs = [
    'Expired',
    'Invalid',
    'Next 30 days',
    'Next 3 months',
    'Next 6 months',
    'Next 12 months',
  ];

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const headerMapping = {
    name: 'Name',
    problem: 'Issue',
    labourResourceName: 'Labour Resource Name',
    type: 'Labour Resource Type',
    expiryDate: 'Expires',
    expiry: 'Expiry Date',
    updatedAt: 'Updated At',
  } as Record<string, any>;

  const [expiringCertifications, { count, loading }, fetch] =
    useLabourWithExpiringCertifications({
      certificationTypeId,
      projectId,
      ...(activeTab
        ? { dateFilter: activeTab?.replaceAll(' ', '_')?.toUpperCase() }
        : {}),
      limit,
      offset,
      ...(filter ? { filter } : {}),
    });

  useEffect(() => {
    const handler = setTimeout(() => {
      setOffset(0);
      setDebouncedSearchTerm(filterText);
    }, 600);
    return () => {
      clearTimeout(handler);
    };
  }, [filterText, setOffset]);

  const totalPages = Math.ceil(count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  return (
    <div>
      <>
        {showHeader ? (
          <div className="w-full flex justify-between items-center py-2">
            <h1 className="text-xl font-bold py-4">
              Expiring / Invalid Certifications
            </h1>
          </div>
        ) : null}
        <div className="flex bg-white w-full flex-row">
          {tabs.map((tab) => (
            <div
              className={`${
                activeTab === tab ? 'bg-black text-white' : ''
              } justify-center flex items-center`}
            >
              <Button
                style={{
                  borderRadius: 0,
                  backgroundColor: 'transparent',
                  color: activeTab === tab ? 'white' : 'black',
                }}
                text={
                  tab?.includes('Next')
                    ? `Expiring ${tab}`
                    : tab === 'Invalid'
                    ? 'Invalid / No Attachment'
                    : tab
                }
                onClick={() => {
                  setActiveTab(tab);
                  setOffset(0);
                }}
              />
            </div>
          ))}
        </div>
        <ExpiringCertificationsTable
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          loading={loading}
          headerMapping={headerMapping}
          totalResults={count || 0}
          onClickRow={(id: any) => {
            if (onClickRow) {
              onClickRow(id, expiringCertifications);
            } else {
              navigate(
                `/labour/${id?.split('_')?.[1]?.toLowerCase()}s/${
                  id?.split('_')?.[0]
                }`
              );
            }
          }}
          filterText={filterText}
          setFilterText={setFilterText}
          selectedFilterDimension={selectedFilterDimension}
          setSelectedFilterDimension={setSelectedFilterDimension}
          data={expiringCertifications
            .filter((result: any) => !filterIds?.includes(result.id))
            .map((result: any) => ({
              name: result?.certificationType?.name,
              id: `${result?.userProfile?.labourResource?.id}_${result?.userProfile?.labourResource?.type}`,
              labourResourceName: `${result?.userProfile?.labourResource?.lastName}, ${result?.userProfile?.labourResource?.firstName}`,
              type:
                result?.userProfile?.labourResource?.type === 'EMPLOYEE'
                  ? 'Employee'
                  : 'Subcontractor',
              ...(activeTab === 'Invalid'
                ? {
                    problem: result?.expiryDate
                      ? 'No Attachment'
                      : 'No Expiry Date',
                  }
                : {}),
              expiryDate: result?.expiryDate,
              expiry: result?.expiryDate,
              status: undefined,
            }))}
          fetchPageOfDataForExport={async (
            limit: number,
            pageOffset: number
          ) => {
            const { data } = await fetch({
              variables: {
                input: { limit, offset: pageOffset, filter },
                ...(activeTab
                  ? {
                      dateFilter: activeTab
                        ?.replaceAll(' ', '_')
                        ?.toUpperCase(),
                    }
                  : {}),
                ...(certificationTypeId ? { certificationTypeId } : {}),
              },
              fetchPolicy: 'network-only',
            });

            setOffset(pageOffset);

            return data?.getExpiringCertifications?.results?.map(
              (item: any) => {
                const userProfile = item.userProfile?.labourResource || {};
                const certificationType = item.certificationType || {};

                return {
                  id: item.id,
                  expiryDate: item.expiryDate
                    ? new Date(parseInt(item.expiryDate)).toISOString()
                    : null,
                  issueDate: item.issueDate
                    ? new Date(parseInt(item.issueDate)).toISOString()
                    : 'N/A',
                  firstName: userProfile.firstName || 'N/A',
                  lastName: userProfile.lastName || 'N/A',
                  email: userProfile.email || 'N/A',
                  resourceType: userProfile.type || 'N/A',
                  certificationName: certificationType.name || 'N/A',
                  certificationId: certificationType.id || 'N/A',
                  createdAt: item.createdAt
                    ? new Date(parseInt(item.createdAt)).toISOString()
                    : null,
                  updatedAt: item.updatedAt
                    ? new Date(parseInt(item.updatedAt)).toISOString()
                    : null,
                };
              }
            );
          }}
          onPageChange={handlePageChange}
        />
      </>
      {children}
    </div>
  );
};

export const ExpiringCertificationsList = ({
  onClickRow,
  showNewItemButton = true,
  filterIds = [],
  certificationTypeId,
  projectId,
  showHeader = true,
}: ExpiringCertificationsListProps) => (
  <PaginationProvider>
    <ExpiringCertificationsListPage
      certificationTypeId={certificationTypeId}
      projectId={projectId}
      onClickRow={onClickRow}
      showNewItemButton={showNewItemButton}
      filterIds={filterIds}
      showHeader={showHeader}
    />
  </PaginationProvider>
);
