import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';

import { Question } from '../../types/QuestionTypes';
import { joinClassNames } from '../../utils/utils';
import ValidationMessage from '../Validation/ValidationMessage';

interface DropDownParams {
  question: Question;
  handleChange: (value: string[]) => void;
  value: string[];
  validation?: any;
  classNames?: string;
  styles?: Record<string, any>;
  isDisabled?: boolean;
}

const MultiSelectDropDown = ({
  question,
  handleChange,
  value,
  validation,
  classNames,
  styles,
  isDisabled,
}: DropDownParams) => {
  if (!Array.isArray(value)) {
    console.warn('Value prop must be an array');
    value = [];
  }
  const { title, options, label, description } = question;

  // Toggles selection of a value
  const toggleValue = (selectedValue: string) => {
    if (value.includes(selectedValue)) {
      handleChange(value.filter((item) => item !== selectedValue));
    } else {
      handleChange([...value, selectedValue]);
    }
  };

  return (
    <div className={classNames}>
      <label
        htmlFor="multi-select-dropdown"
        className="block text-sm font-medium text-gray-700"
      >
        {title ? title : null}
      </label>
      {validation ? <ValidationMessage message={validation} /> : null}
      <Menu as="div" className="relative inline-block text-left my-2 z-99">
        {label ? (
          <label
            htmlFor="multi-select-dropdown"
            className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs text-gray-900"
          >
            {label}
          </label>
        ) : null}
        <div>
          <Menu.Button
            data-testid={'keyDropdownButton'}
            disabled={isDisabled}
            className="inline-flex disabled:bg-gray-100 disabled:opacity-90 justify-center rounded-md border border-gray-300 items-center bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-gray-100"
          >
            {value.length > 0
              ? // Map the value to the option's name using the options array
                value
                  .map(
                    (val) =>
                      options?.find((option) => option.value === val)?.name ||
                      val
                  )
                  .join(', ')
              : 'Select options'}
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            style={{ ...styles?.menuItems }}
            className="absolute transform -translate-y-full top-6 left-0 z-10 mt-0 h-96 overflow-y-scroll w-56 origin-bottom-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div className="py-1">
              {/* @ts-ignore */}
              {options?.map((option) => (
                <Menu.Item
                  key={option.name}
                  // @ts-ignore
                  onClick={
                    isDisabled
                      ? () => null
                      : () => toggleValue(option.value ?? option.name)
                  }
                >
                  {({ active }) => (
                    <div
                      className={joinClassNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'flex px-4 py-2 text-sm'
                      )}
                    >
                      <p className="flex items-center">
                        {/* @ts-ignore */}
                        {value.includes(option.value) ? '✓ ' : ''}{' '}
                        {/* Mark selected items */}
                        {option.name}
                        {option.detail ? (
                          <p className="ml-1 text-gray-400 text-xs font-light">
                            ({option.detail})
                          </p>
                        ) : null}
                      </p>
                    </div>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      {description ? (
        <p className="mt-2 text-sm text-gray-500">{description}</p>
      ) : null}
    </div>
  );
};

export default MultiSelectDropDown;
