import Avatar from 'react-avatar';
import { useNavigate } from 'react-router-dom';
import { YellowBadge } from '../../../components/Badges/Badges';
import MissingCertifications from '../../../components/MissingCertifications';

type CompanyDetailsProps = {
  company: any;
};

const companyTypeToLabel: Record<string, string> = {
  LIMITED: 'Limited',
  UMBRELLA: 'Umbrella',
};

export function CompanyDetails({ company }: CompanyDetailsProps) {
  const navigate = useNavigate();

  const sections = [
    {
      title: 'Company Record Owner',
      content: company?.owner?.id ? (
        <div
          role="button"
          onClick={() =>
            navigate(
              `/labour/${company?.owner?.type?.toLowerCase()}s/${
                company.owner?.id
              }`
            )
          }
          className="px-3 flex gap-x-2 text-black items-center border rounded-lg text-lg py-4"
        >
          <Avatar
            name={`${company?.owner?.firstName} ${company?.owner?.lastName}`}
            size="35"
            round
          />
          {company?.owner?.firstName} {company?.owner?.lastName}
        </div>
      ) : (
        <YellowBadge text="No Owner" />
      ),
    },
    {
      title: 'Company Name',
      content: company?.companyName || 'N/A',
    },

    {
      title: 'Company Number',
      content: company?.companyRegNumber || 'N/A',
    },
    {
      title: 'Company Email',
      content: company?.companyEmail || 'N/A',
    },
    {
      title: 'Company Address',
      content: company?.companyAddress || 'N/A',
    },
    {
      title: 'Company Phone Number',
      content: company?.companyPhoneNumber || 'N/A',
    },
    {
      title: 'Company Type',
      content: companyTypeToLabel[company?.companyType] || 'N/A',
    },
    {
      title: 'VAT Registration Number',
      content: company?.vatRegistrationNumber || 'N/A',
    },
    {
      title: 'Contact Name',
      content: company?.contactName || 'N/A',
    },
  ];

  return (
    <div className="border-t border-gray-200">
      {sections.map((section, index) => (
        <div key={index} className="px-4 py-4">
          <h2 className="text-lg font-medium text-gray-900">{section.title}</h2>
          <p className="mt-1 text-sm text-gray-500">{section.content}</p>
        </div>
      ))}
    </div>
  );
}
