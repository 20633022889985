import ValidationMessage from '../../../components/Validation/ValidationMessage';

export const renderErrorMessages = (errors: any) => {
  let errorComponents: any[] = [];

  // @ts-ignore
  const traverseErrors = (errorObject) => {
    if (typeof errorObject === 'string') {
      // Render a ValidationMessage for the string
      errorComponents.push(<ValidationMessage message={errorObject} />);
    } else if (Array.isArray(errorObject)) {
      // Traverse each item in the array
      errorObject.forEach((item) => traverseErrors(item));
    } else if (typeof errorObject === 'object' && errorObject !== null) {
      // Traverse each property in the object
      Object.values(errorObject).forEach((value) => traverseErrors(value));
    }
  };

  traverseErrors(errors);
  return errorComponents;
};
