import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface FinanceHomeProps {
  children?: React.ReactElement;
}

export const FinanceHome = ({ children }: FinanceHomeProps) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/finance/codes');
  }, []);
  return <div>{children}</div>;
};
