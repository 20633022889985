import { gql } from '@apollo/client';

export const SAVE_SUBCONTRACTOR = gql`
  mutation saveSubcontractor(
    $id: ID
    $input: SaveSubcontractorInput!
    $activeOrganisation: String!
  ) {
    saveSubcontractor(
      id: $id
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      success
      message
    }
  }
`;
