import { Link, LinkProps } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

interface LinkButtonProps extends LinkProps {
  className?: string;
}

export default function LinkButton({
  children,
  className,
  ...props
}: LinkButtonProps) {
  return (
    <Link
      className={twMerge(
        `capitalize transition-all justify-center py-2 px-4 border disabled:bg-gray-400 border-transparent hover:translate-y-0.5 hover:translate-x-0.5 shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`,
        className
      )}
      {...props}
    >
      {children}
    </Link>
  );
}
