import { twMerge } from 'tailwind-merge';

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  children?: React.ReactNode;
  className?: string;
  isLoading?: boolean;
  text?: any;
  type?: string;
  style?: React.CSSProperties;
  isDisabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void | Promise<void>;
}

export const Button = ({
  children,
  className,
  isLoading,
  onClick,
  text,
  isDisabled,
  type = 'button',
  style,
  ...props
}: ButtonProps) => (
  <button
    type={type as any}
    disabled={isLoading || isDisabled}
    style={{ ...style }}
    onClick={onClick}
    className={twMerge(
      `capitalize transition-all justify-center py-2 px-4 border disabled:bg-gray-400 border-transparent hover:translate-y-0.5 hover:translate-x-0.5 shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`,
      className
    )}
    {...props}
  >
    {isLoading ? (
      <div className="flex items-center justify-center ">
        <div className="w-5 h-5 border-b-2 border-dotted border-white rounded-full animate-spin"></div>
      </div>
    ) : children ? (
      children
    ) : text ? (
      text
    ) : (
      'Create'
    )}
  </button>
);
