import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface ProfileHomeProps {
  children?: React.ReactElement;
}

export const ProfileHome = ({ children }: ProfileHomeProps) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/profile/personal');
  }, []);
  return <div>{children}</div>;
};
